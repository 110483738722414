/**
 * @file 组件：题库编辑-表格
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import SingleFourOptions from './SingleFourOptions'
import { Form, Input, Select, Radio, Checkbox } from 'antd'

/**
 * Ant Design的选项元素组件
 * @const {Object} Option
 */
const { Option } = Select

/**
 * 题库编辑-表格
 * @class
 * @extedns React.Component
 */
class EditForm extends React.Component {

    /**
     * 得到表格数值
     * @type {Function}
     * @return {Promise} 表格数值
     */
    getValues = () => this.props.form.getFieldsValue()

    /**
     * 重设表格数值为初始
     * @type {Function}
     */
    resetFields = () => this.props.form.resetFields()

    /**
     * @typedef {Object} Option 
     * 
     * 得到表格数值
     * @type {Function}
     * @return {Array<Option>} 选项列表
     */
    typeOptions = () => this.props.category ? this.props.category.map((item, index) => <Option key={index+item+'类别'} value={index+1}>{item}</Option>) : <Option value={1}>暂无</Option>

    /**
     * 表格校验
     * @type {Function}
     * @return {Promise} 表格校验结果
     */
    onSubmit = () => this.props.form.validateFields()

    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator } = this.props.form,
        formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 },
            },
        }
        switch (this.props.type) {
            case 'single': {
                return (
                    <Form {...formItemLayout} onSubmit={this.onSubmit}>
                        <Form.Item label="类别">
                        {getFieldDecorator('type', {
                            rules: [
                            {
                                required: true,
                                message: '请选择题库类别！',
                            }
                            ],
                            initialValue: this.props.value.type
                        })(<Select placeholder='请选择'>
                            {this.typeOptions()}
                          </Select>)}
                        </Form.Item>
                        <Form.Item label="题干">
                        {getFieldDecorator('stem', {
                            rules: [
                            {
                                required: true,
                                message: '请输入题干！',
                            },
                            ],
                            initialValue: this.props.value.stem
                        })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                        <Form.Item label="正确答案">
                        {getFieldDecorator('result', {
                            rules: [
                            {
                                required: true,
                                message: '请选择正确答案！',
                            },
                            ],
                            initialValue: this.props.value.result
                        })(<Radio.Group>
                            <Radio value={'A'}>A</Radio>
                            <Radio value={'B'}>B</Radio>
                            <Radio value={'C'}>C</Radio>
                            <Radio value={'D'}>D</Radio>
                          </Radio.Group>)}
                        </Form.Item>
                        <Form.Item label="选项">
                        {getFieldDecorator('optionList', {
                            rules: [
                            {
                                required: true,
                                message: '请输入选项！'
                            },
                            ],
                            initialValue:  [this.props.value.optionA, this.props.value.optionB, this.props.value.optionC, this.props.value.optionD]
                        })(<SingleFourOptions />)}
                        </Form.Item>
                        <Form.Item label="解析">
                        {getFieldDecorator('analysis', {
                            rules: [
                            {
                                required: true,
                                message: '请输入解析！'
                            },
                            ],
                            initialValue: this.props.value.analysis
                        })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                    </Form>
                )
            }
            case 'multi': {
                return (
                    <Form {...formItemLayout} onSubmit={this.onSubmit}>
                        <Form.Item label="类别">
                        {getFieldDecorator('type', {
                            rules: [
                            {
                                required: true,
                                message: '请选择题库类别！',
                            }
                            ],
                            initialValue: this.props.value.type
                        })(<Select placeholder='请选择'>
                            {this.typeOptions()}
                          </Select>)}
                        </Form.Item>
                        <Form.Item label="题干">
                        {getFieldDecorator('stem', {
                            rules: [
                            {
                                required: true,
                                message: '请输入题干！',
                            },
                            ],
                            initialValue: this.props.value.stem
                        })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                        <Form.Item label="正确答案">
                        {getFieldDecorator('result', {
                            rules: [
                            {
                                required: true,
                                message: '请选择正确答案！',
                            },
                            ],
                            initialValue: Array.from(new Set(this.props.value.result))
                        })(<Checkbox.Group options={[{ label: 'A', value: 'A' },{ label: 'B', value: 'B' },{ label: 'C', value: 'C' },{ label: 'D', value: 'D' }]}/>)}
                        </Form.Item>
                        <Form.Item label="选项">
                        {getFieldDecorator('optionList', {
                            rules: [
                            {
                                required: true,
                                message: '请输入选项！'
                            },
                            ],
                            initialValue:  [this.props.value.optionA, this.props.value.optionB, this.props.value.optionC, this.props.value.optionD]
                        })(<SingleFourOptions />)}
                        </Form.Item>
                        <Form.Item label="解析">
                        {getFieldDecorator('analysis', {
                            rules: [
                            {
                                required: true,
                                message: '请输入解析！'
                            },
                            ],
                            initialValue: this.props.value.analysis
                        })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                    </Form>
                )
            }
            case 'fillin': {
                return (
                    <Form {...formItemLayout} onSubmit={this.onSubmit}>
                        <Form.Item label="类别">
                        {getFieldDecorator('type', {
                            rules: [
                            {
                                required: true,
                                message: '请选择题库类别！',
                            }
                            ],
                            initialValue: this.props.value.type
                        })(<Select placeholder='请选择'>
                            {this.typeOptions()}
                          </Select>)}
                        </Form.Item>
                        <Form.Item label="题干">
                        {getFieldDecorator('stem', {
                            rules: [
                            {
                                required: true,
                                message: '请输入题干！',
                            },
                            ],
                            initialValue: this.props.value.stem
                        })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                        <Form.Item label="正确答案">
                        {getFieldDecorator('fillResult', {
                            rules: [
                            {
                                required: true,
                                message: '请输入正确答案！',
                            },
                            ],
                            initialValue: this.props.value.fillResult
                        })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                        <Form.Item label="解析">
                        {getFieldDecorator('analysis', {
                            rules: [
                            {
                                required: true,
                                message: '请输入解析！'
                            },
                            ],
                            initialValue: this.props.value.analysis
                        })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                    </Form>
                )
            }
            default: {
                return ''
            }
        }
        
    }
}

/**
 * 表单创建
 * @const {Form} Edit
 */
const Edit = Form.create()(EditForm)

export default Edit