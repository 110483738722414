import React, { Component } from 'react'
import { Form, Radio, Input, InputNumber, Select, Icon } from 'antd'
import moment from 'moment'

const { TextArea } = Input;
const { Option } = Select;

class EditCheckup extends Component {
    getItemsValue = ()=>{
        const valus= this.props.form.getFieldsValue();
        return valus;
        // let returnValues
        // this.props.form.validateFields((err,value) => {
        //     if(err){
        //         returnValues = false
        //     }else{
        //         returnValues = value
        //         returnValues = this.props.form.getFieldsValue();
        //     }
        // })
        // return returnValues;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    //下拉选择
    handleChange = (value) => {
        console.log(`selected ${value}`);
    }
    //密码
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    render() {
        //console.log(this.props.record);
        //console.log(this.props.record.createDate);
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                <Form.Item label="体检单">
                    {getFieldDecorator('reportNo',{
                        initialValue:this.props.record.reportNo
                    })(
                        <Input disabled placeholder="体检单"/>
                    )}
                </Form.Item>
                <Form.Item label="姓名">
                    {getFieldDecorator('reportFullname',{
                        initialValue:this.props.record.reportFullname,
                        rules: [
                            { required: true, message: '请输入姓名！' }
                        ],
                    })(
                        <Input placeholder="姓名"/>
                    )}
                </Form.Item>
                <Form.Item label="手机号">
                    {getFieldDecorator('reportMobile',{
                        initialValue:this.props.record.reportMobile,
                        rules: [
                            { required: true, message: '请输入手机号！' },
                            { pattern: /^((13|14|15|17|18)[0-9]{9})$/,message: '请正确输入手机号！'}
                        ],
                    })(
                        <Input placeholder="手机号"/>
                    )}
                </Form.Item>
                <Form.Item label="身份证">
                    {getFieldDecorator('reportIdcard',{
                        initialValue:this.props.record.reportIdcard,
                        rules: [
                            { required: true, message: '请输入身份证号！' },
                            { pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,message: '请正确输入身份证号！'}
                        ],
                    })(
                        <Input placeholder="身份证"/>
                    )}
                </Form.Item>
                <Form.Item label="公司">
                    {getFieldDecorator('reportCompany',{
                        initialValue:this.props.record.reportCompany,
                        rules: [
                            { required: true, message: '请输入公司！' }
                        ],
                    })(
                        <Input placeholder="公司"/>
                    )}
                </Form.Item>
                <Form.Item label="体检类型">
                    {getFieldDecorator('tiJianLb',{
                        initialValue:this.props.record.tiJianLb,
                        rules: [
                            { required: true, message: '请选择体检类型！' }
                        ],
                    })(
                        // <Input placeholder="体检类型"/>
                        <Select onChange={this.handleChange} placeholder="体检类型">
                            <Option value={"常规体检"}>常规体检</Option>
                            <Option value={"职业病体检"}>职业病体检</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="查询密码" hasFeedback>
                    {getFieldDecorator('ciKaLbbm',{
                        initialValue:this.props.record.ciKaLbbm,
                        rules: [
                            { required: true, message: '请输入查询密码！' },
                            { validator: this.validateToNextPassword,}
                        ],
                    })(
                        <Input.Password />
                    )}
                </Form.Item>
                <Form.Item label="是否公开">
                    {getFieldDecorator('isExpose',{
                        initialValue:this.props.record.isExpose?"1":"0",
                        rules: [
                            { required: true, message: '请选择处理结果！' }
                        ],
                    })(
                        <Radio.Group>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const EditCheckupForm = Form.create()(EditCheckup);

export default EditCheckupForm;

// export default Form.create()(SoftwareOne); 