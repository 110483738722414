import React, { Component } from 'react'
import { Form, Input } from 'antd'
const { TextArea } = Input;

class Notebook extends Component {
    getItemsValue = ()=>{ 
        const valus= this.props.form.getFieldsValue();
        return valus;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        //const { record } = this.props.record;
        // console.log(this.props.record)
        return (
            <Form layout="inline">
                <Form.Item>
                    {getFieldDecorator('remark',{
                        initialValue:this.props.record ? this.props.record : '',
                        rules: [{max:200,message: '内容不可多于200个字符',}]
                    })(
                        // console.log(this.props.record)
                        <TextArea
                        placeholder="请填写备注"
                        className="custom"
                        style={{ height: 100,width:470 }}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const NotebookForm = Form.create()(Notebook);

export default NotebookForm;
// export default Form.create()(Notebook); 