/*
 * @file: 医生用户信息编辑
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react';
import {
    Row, Col, Radio, Upload, Icon, message, Form,
    Input, Button, Select, Tabs
} from 'antd'
import MedicalInformation from './components/UserDoc/MedicalInformation'//医生---医资信息
import ServicesDate from './components/UserDoc/ServicesDate'//医生---服务时间信息
import { getAreaOptions, getHaDoctorInfo, getFile, saveHaDoctorInfo, addHaDoctorInfo } from './../../Api/index'
import RadioGroup from 'antd/lib/radio/group';
const { Option } = Select
const { TabPane } = Tabs;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader));
    reader.readAsDataURL(img);
}
/**
 * @classdesc: 医生用户信息编辑
 */
class UserBasicJ extends Component {
    // 跳转过来  传的值
    constructor(props, context) {
        super(props, context)
        /**
         * @description: state自定义
         * @param {string} userId 获取到用户列表所传过来的userModel
         * @param {string} haDoctorId 获取健康顾问等列表传来haUserid
         * @param {string} tab tab页id
         * @param {object} haUser 基本信息
         * @param {boolean} upStatus 新增或编辑的判断值
         * @param {object} cardPositivePic 正面身份证
         * @param {object} cardBackPic 反面身份证
         * @param {object} parent 省
         * @param {object} city 市
         * @param {object} area 区
         * @param {string} parentId 省id
         * @param {string} cityId 市id
         * @param {string} areaId 区id
         * @param {object} medicaldata 医资信息数据
         * @param {object} servicesdata 服务时间数据
         * @param {object} submedicaldata 医资信息传过来的数据
         * @param {object} subbasicdata 基本信息传递数据
         * @param {object} subservicesdata 接受（服务时间）传过来的数据 
         */
        this.state = {
            userId: this.props.location.state.userId,
            haDoctorId: this.props.location.state.haDoctorId,
            tab: '1',
            haUser: [],
            upStatus: true,
            //身份证
            cardPositivePic: null,
            cardBackPic: null,
            //地址
            parent: [],
            city: [],
            area: [],
            parentId: '省',
            cityId: '市',
            areaId: '区',
            // 父传子的
            medicaldata: [],
            servicesdata: [
                { servicesType: '电话咨询', servicesDate: '上午', key: 1 },
                { servicesType: '电话咨询', servicesDate: '下午', key: 2 },
                { servicesType: '电话咨询', servicesDate: '晚上', key: 3 },
                { servicesType: '在线咨询', servicesDate: '上午', key: 4 },
                { servicesType: '在线咨询', servicesDate: '下午', key: 5 },
                { servicesType: '在线咨询', servicesDate: '晚上', key: 6 }
            ],
            // 子传进来的
            submedicaldata: [],
            subbasicdata: [],
            subservicesdata: [],
        }
    }


    // 生命周期
    componentWillMount() {
        this.usercidbol()
    }

    /**
     * @description: 获取编辑数据
     * @function: usercidbol
     */
    usercidbol = () => {
        if (this.state.userId) {
            this.basicdatalist(this)
            this.upstatus(this)
        } else {
            this.requestArea(this)
        }
    }
    /**
     * @description: 编辑和新增状态切换
     * @function: upstatus
     */
    upstatus = () => {
        this.setState({
            tab: '2',
            upStatus: false
        })
    }

    /**
     * @description: 基本信息
     * @param {object} _this this指向 
     * @function: basicdatalist
     */
    basicdatalist = (_this) => {
        let params = { userId: _this.state.userId }
        getHaDoctorInfo(params).then((res) => {
            if (res.data.state.name === '操作成功' && res.data.state.value === 0) {
                // 平台信息部分
                var userModel = res.data.result.userModel
                var haDoctorCheck = res.data.result.haDoctorCheck
                if (haDoctorCheck.certFrontId) {
                    _this.imgUrlUp(haDoctorCheck.certFrontId).then((val) => {
                        _this.setState({
                            cardPositivePic: val
                        })
                    })
                }
                if (haDoctorCheck.certBackId) {
                    _this.imgUrlUp(haDoctorCheck.certBackId).then((val) => {
                        _this.setState({
                            cardBackPic: val
                        })
                    })
                }
                // 医生信息部分
                let haDoctorInfo = [res.data.result.haDoctorInfo, res.data.result.haDoctorCheck]
                let SerConfig = res.data.result.haDoctorServiceConfig
                let Ser = _this.state.servicesdata
                let obj = [
                    { qProportion: SerConfig.telVoucher, fProportion: SerConfig.telMoney, open: SerConfig.isTelServiceMorning, cash: SerConfig.telServiceMorningFee },
                    { qProportion: SerConfig.telVoucher, fProportion: SerConfig.telMoney, open: SerConfig.isTelServiceAfternoon, cash: SerConfig.telServiceAfternoonFee },
                    { qProportion: SerConfig.telVoucher, fProportion: SerConfig.telMoney, open: SerConfig.isTelServiceNight, cash: SerConfig.telServiceNightFee },
                    { qProportion: SerConfig.netVoucher, fProportion: SerConfig.netMoney, open: SerConfig.isNetServiceMorning, cash: SerConfig.netServiceMorningFee },
                    { qProportion: SerConfig.netVoucher, fProportion: SerConfig.netMoney, open: SerConfig.isNetServiceAfternoon, cash: SerConfig.netServiceAfternoonFee },
                    { qProportion: SerConfig.netVoucher, fProportion: SerConfig.netMoney, open: SerConfig.isNetServiceNight, cash: SerConfig.netServiceNightFee },
                ]
                for (var i = 0; i < 6; i++) {
                    for (var e in Ser[i]) {
                        obj[i][e] = Ser[i][e]
                    }
                }

                // 获取id数组
                let idObj = {
                    haDoctorInfo: res.data.result.haDoctorInfo.id,
                    haDoctorServiceConfig: res.data.result.haDoctorServiceConfig.id,
                    haDoctorCheck: res.data.result.haDoctorCheck.id,
                    haUser: res.data.result.haUser.id
                }

                _this.setState({
                    medicaldata: haDoctorInfo,
                    servicesdata: obj,
                    idObj,
                    haUser: res.data.result.haUser
                })
                _this.requestArea(res.data.result.haUser)
            }
        })
    }
    /**
     * @description: 获取级联地址的数据
    * @param {object} result 详情数据 
     * @function: requestArea
     */
    requestArea = (result) => {
        let _this = this
        let data = result
        getAreaOptions().then(res => {
            if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                _this.setState({
                    parent: res.data.result
                })
            }
        })
        if (data.cityId) {
            getAreaOptions({ parentId: data.cityId }).then(res => {
                if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                    getAreaOptions({ parentId: res.data.result[0].provinceId }).then(res => {
                        if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                            _this.setState({
                                city: res.data.result,
                                cityId: data.cityId,

                            })
                        }
                    })

                    _this.setState({
                        parentId: res.data.result[0].provinceId,
                        area: res.data.result
                    })
                }
            })
        }

    }
    /**
    * @description: 地址   点击了省
    * @param {number} value 省id 
    * @function: handleProvinceChange
    */
    handleProvinceChange = value => {
        let _this = this
        this.props.form.setFields({ city: { value: '', errors: [new Error('forbid ha')] } })
        this.props.form.setFields({ area: { value: '', errors: [new Error('forbid ha')] } })
        getAreaOptions({ parentId: value }).then(res => {
            if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                _this.setState({
                    city: res.data.result,
                    area: []
                })
            }
        })
    };
    /**
     * @description: 地址   点击了市
     * @param {number} value 省id 
     * @function: onSecondCityChange
     */
    onSecondCityChange = value => {
        let _this = this
        this.props.form.setFields({ area: { value: '', errors: [new Error('forbid ha')] } })
        getAreaOptions({ parentId: value }).then(res => {
            if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                // let cityValue=[res.data.result.provinceName,res.data.result.cityName]
                // console.log(cityValue)
                _this.setState({
                    area: res.data.result,
                    // cityValue
                })
            }
        })
    };
    /**
    * @description: 身份证正面
    * @param {object} info 身份证数据 
    * @function: handleChange2
    */
    handleChange2 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ piczloading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                this.setState({
                    cardPositivePic: info.file.response.result,
                    piczloading: false
                })
            });
        }
    };
    /**
     * @description: 身份证反面
     * @param {object} info 身份证数据 
     * @function: handleChange3
     */
    handleChange3 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ picfloading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                this.setState({
                    cardBackPic: info.file.response.result,
                    picfloading: false
                })
            });
        }
    };
    /**
     * @description: id请求图片路径
     * @param {number} urlId 图片id 
     * @function: imgUrlUp
     */
    imgUrlUp = (urlId) => {
        let _this = this
        return new Promise((resolve, reject) => {
            // 获取图片详细情况
            getFile({ fileId: urlId }).then(res => {
                if (res.status === 200 && res.statusText === "OK") {
                    // res.data.result.createDate=_this.formatDate(res.data.result.createDate)
                    resolve(res.data.result)
                } else {
                    reject()
                }
            })

        })
    }
    /**
     * @description: 基本信息传递改总提交
     * @param {object} data  要保存的数据 
     * @function: Submit
     */
    Submit = (data) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let params = this.props.form.getFieldsValue()
                if (this.state.cardPositivePic) {
                    params["cardPositiveFileId"] = this.state.cardPositivePic.id
                }
                if (this.state.cardBackPic) {
                    params["cardBackFileId"] = this.state.cardBackPic.id
                }
                this.setState({
                    tab: '2',
                    subbasicdata: params
                })
            } else {
                message.error('请将带 * 的信息填写完整')
            }
        });
    }
    /**
     * @description: 接受（医资信息）传过来的
     * @param {object} item 接受（医资信息）传过来的数据 
     * @function: medical
     */
    medical(item) {
        this.setState({
            tab: item[0],
            submedicaldata: item[1]
        })
    }
    /**
     * @description: 接受（服务时间）传过来的
     * @param {object} item 接受（服务时间）传过来的数据 
     * @function: services
     */
    services(item) {
        this.setState({
            tab: item[0],
            subservicesdata: item[1]
        })
        if (item.length == 2) {
            // 处理接受过来的数据 配合后台数据类型
            let set = item[1];
            let haDoctorServiceConfig = {
                isTelServiceMorning: set.open0 == 0 ? false : true,
                isTelServiceAfternoon: set.open1 == 0 ? false : true,
                isTelServiceNight: set.open2 == 0 ? false : true,
                isNetServiceMorning: set.open3 == 0 ? false : true,
                isNetServiceAfternoon: set.open4 == 0 ? false : true,
                isNetServiceNight: set.open5 == 0 ? false : true,
                telServiceMorningFee: set.cash0,
                telServiceAfternoonFee: set.cash1,
                telServiceNightFee: set.cash2,
                netServiceMorningFee: set.cash3,
                netServiceAfternoonFee: set.cash4,
                netServiceNightFee: set.cash5,
                telMoney: set.fProportion0,
                telVoucher: set.qProportion0,
                netMoney: set.fProportion3,
                netVoucher: set.qProportion3,
            }

            // 调用提交后台方法
            this.submint(
                this.state.subbasicdata,
                this.state.submedicaldata,
                haDoctorServiceConfig
            );
        }
    }
    /**
     * @description: 提交
     * @param {object} basic 基本信息
     * @param {object} medical 医生资格信息
     * @param {object} services 服务时间
     * @function: submint
     */
    submint = (basic, medical, services) => {
        let _this = this
        let idObj = this.state.idObj
        // let cityValue=this.state.cityValue
        let params = {}
        // params[]
        // console.log(params)
        if (this.state.userId) {
            params['haDoctorInfo'] = medical.haDoctorInfo
            params['haDoctorCheck'] = medical.haDoctorCheck
            params['haDoctorServiceConfig'] = services
            params['haDoctorInfo']['isOccDoctor'] = undefined
            params['haUserId'] = this.state.userId
            params['haDoctorInfo']['id'] = idObj.haDoctorInfo
            params['haDoctorCheck']['id'] = idObj.haDoctorCheck
            params['haDoctorServiceConfig']['id'] = idObj.haDoctorServiceConfig
            saveHaDoctorInfo(params).then(res => {
                if (res.data.state.name === '操作成功' && res.data.state.value === 0) {
                    message.success('操作成功')
                    setTimeout(_this.jump, 500)
                }
            })
        } else {
            params['haUser'] = basic.haUser
            params['haDoctorInfo'] = medical.haDoctorInfo
            params['haDoctorCheck'] = medical.haDoctorCheck
            params['haDoctorServiceConfig'] = services
            params['haUser']['companyName'] = params.haDoctorInfo.hospitalName
            params['haDoctorInfo']['address'] = basic.address
            params['haUser']['fullname'] = basic.name
            params['haDoctorCheck']['fullname'] = basic.name
            params['haUser']['userMobile'] = basic.mobile
            params['haDoctorCheck']['certNo'] = basic.cardNo
            params['haUser']['cityId'] = basic.city
            params['haDoctorCheck']["certFrontId"] = basic.cardPositiveFileId
            params['haDoctorCheck']["certBackId"] = basic.cardBackFileId
            params['haDoctorInfo']['isOccDoctor'] = undefined
            addHaDoctorInfo(params).then(res => {
                if (res.data.state.name === '操作成功' && res.data.state.value === 0) {
                    message.success('操作成功')
                    setTimeout(_this.jump, 500)
                }

            })
        }

    }
    /**
     * @description: 返回上一级
     * @function: jump
     */
    jump = () => {
        this.props.history.goBack();
    }

    render() {
        let _this = this
        const haUser = this.state.haUser
        const haDoctorInfo = this.state.medicaldata[0] ? this.state.medicaldata[0] : []
        const haDoctorCheck = this.state.medicaldata[1] ? this.state.medicaldata[1] : []
        const formItemLayout = {
            labelCol: {
                sm: { span: 5 },
            },
            wrapperCol: {
                sm: { span: 15 },
            },
        };
        const { getFieldDecorator } = this.props.form
        const uploadButtonPicz = (
            <div className="practicePic">
                <Icon type={this.state.loading1 ? 'loading' : 'plus'} />
                <div className="ant-upload-text">正面</div>
            </div>
        );
        const uploadButtonPicf = (
            <div className="practicePic">
                <Icon type={this.state.loading2 ? 'loading' : 'plus'} />
                <div className="ant-upload-text">反面</div>
            </div>
        );
        const parent = this.state.parent
        const city = this.state.city
        const area = this.state.area
        return (
            <div className="docEditor">

                <Tabs activeKey={this.state.tab} type="card">
                    {
                        this.state.upStatus ?
                            <TabPane tab="基本信息" id='xinzeng' key="1">
                                <div>
                                    {/* 基础信息 */}
                                    <Form {...formItemLayout} className="mt20">
                                        <Form.Item label="姓名">
                                            {getFieldDecorator('name', {
                                                initialValue: haDoctorCheck.fullname || '',
                                                rules: [
                                                    { required: true, message: '不能为空' },
                                                    { min: 2, max: 6, message: '长度不在范围内' }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="性别">
                                            {getFieldDecorator('haUser.gender', {
                                                initialValue: haUser.gender + '',
                                                rules: [
                                                    { required: true, message: '不能为空' },
                                                ],
                                            })(
                                                <RadioGroup>
                                                    <Radio value="1">男</Radio>
                                                    <Radio value="0">女</Radio>
                                                </RadioGroup>
                                            )}
                                        </Form.Item>
                                        <Form.Item label="手机号">
                                            {getFieldDecorator('mobile', {
                                                initialValue: haUser.userMobile,
                                                rules: [
                                                    { required: true, message: '不能为空' },
                                                    {
                                                        pattern: new RegExp(/^1[3-9]\d{9}$/),
                                                        message: '格式不正确'
                                                    }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="所在地">
                                            <Row>
                                                {
                                                    getFieldDecorator('parent', {
                                                        initialValue: this.state.parentId + '',
                                                        rules: [
                                                            { required: true, message: '不能为空' },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{ width: 120, marginRight: '20px' }}
                                                            onChange={this.handleProvinceChange}
                                                        >
                                                            {parent.map((e, i) => (
                                                                <Option key={e.id} >{e.name}</Option>
                                                            ))}
                                                        </Select>
                                                    )
                                                }
                                                {
                                                    getFieldDecorator('city', {
                                                        initialValue: this.state.cityId + '',
                                                        rules: [
                                                            { required: true, message: '不能为空' },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{ width: 120, marginRight: '20px' }}
                                                            onChange={this.onSecondCityChange}
                                                        >
                                                            {city.map((e, i) => (
                                                                <Option key={e.id} >{e.name}</Option>
                                                            ))}
                                                        </Select>
                                                    )
                                                }
                                                {
                                                    getFieldDecorator('area', {
                                                        initialValue: this.state.areaId + '',
                                                        // rules: [
                                                        //     { required: true, message: '不能为空' },
                                                        // ],
                                                    })(
                                                        <Select
                                                            style={{ width: 120, marginRight: '20px' }}
                                                        >
                                                            {area.map((e, i) => (
                                                                <Option key={e.id} >{e.name}</Option>
                                                            ))}
                                                        </Select>
                                                    )
                                                }
                                            </Row>
                                        </Form.Item>
                                        <Form.Item label="详细地址">
                                            {getFieldDecorator('address', {
                                                initialValue: haDoctorInfo.address,
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="身份证号码">
                                            {getFieldDecorator('cardNo', {
                                                initialValue: haDoctorCheck.certNo,
                                                rules: [
                                                    { required: true, message: '不能为空' },
                                                    {
                                                        pattern: new RegExp(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/),
                                                        message: '格式不正确'
                                                    }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="身份证照片">
                                            <Row>
                                                <Col span={12} className="mt20">
                                                    {getFieldDecorator('cardPositiveFileId')(
                                                        <Upload
                                                            name='file'
                                                            showUploadList={false}
                                                            action='/v1/ajax/file/upload'
                                                            onChange={this.handleChange2}
                                                            listType="picture-card"

                                                        >
                                                            {
                                                                this.state.cardPositivePic ?
                                                                    <img src={this.state.cardPositivePic.fullOssurl} style={{ width: '100%' }} alt="" />
                                                                    : uploadButtonPicz
                                                            }

                                                        </Upload>
                                                    )}
                                                </Col>
                                                <Col span={12} className="mt20">
                                                    {getFieldDecorator('cardBackFileId')(
                                                        <Upload
                                                            name='file'
                                                            showUploadList={false}
                                                            action='/v1/ajax/file/upload'
                                                            onChange={this.handleChange3}
                                                            listType="picture-card"
                                                        >
                                                            {
                                                                this.state.cardBackPic ?
                                                                    <img src={this.state.cardBackPic.fullOssurl} style={{ width: '100%' }} alt="" />
                                                                    : uploadButtonPicf
                                                            }
                                                        </Upload>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Form>
                                    <div style={{ textAlign: 'center', width: '100%' }}>
                                        <Button type='primary' onClick={this.Submit.bind(this)}>下一步</Button>
                                    </div>
                                </div>

                            </TabPane>
                            : null
                    }

                    <TabPane tab="医资信息" key="2">
                        <MedicalInformation content={this.state.medicaldata} parentDelete={this.medical.bind(this)} />
                    </TabPane>
                    <TabPane tab="服务时间" key="3">
                        <ServicesDate content={this.state.servicesdata} parentDelete={this.services.bind(this)} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

const UserSearchForm = Form.create()(UserBasicJ);
export default UserSearchForm;