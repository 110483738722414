import React, { Component } from 'react'
import { Icon, Form, Select, Input, List, Tabs, Pagination, message, Modal} from 'antd'
import { homeQuestionResolve, homeQuestionList,homeQuestionDel } from '../../../Mock'
import { getAdminNotebookListData,delAdminNotebook,solveAdminNotebook} from './../../../Api'
import '../home.css'
import utils from '../../../Common/js/utils'
const ListItem = List.Item
const ListItemMeta = List.Item.Meta
export default class View extends React.Component {
    state={
        questionListInfo:[],
        pageInfo:{
            pageNum:1,
            pageSize:10
        }
    }
    componentDidMount(){
        this.props.onRef(this)
    }
    getQuestionList = (data) => {
        let params = {...this.state.pageInfo,...data}
        let _this = this
        params.status = 1
        getAdminNotebookListData(params).then(res => {
            if (res.data.state.value == 0) {
                this.setState({
                    questionListInfo: res.data.result.rows,
                    viewModal: true,
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.pageInfo.pageNum = current
                        _this.state.pageInfo.pageSize = size
                        this.getQuestionList()
                    }, (current, size) => {
                        _this.state.pageInfo.pageNum = current
                        _this.state.pageInfo.pageSize = size
                        this.getQuestionList()
                    }, res.data.result.pageSize)
                })
            }
        })
    }
    getQuestionListTotal = ()=>{
        return this.state.questionListInfo.length
    }
    componentWillMount(){
        this.getQuestionList()
    }
    componentWillReceiveProps(){
        this.getQuestionList()
    }
    handledelete=(id)=>{
        Modal.confirm({
            title:'提示',
            content:'确定要删除吗?',
            okType:'danger',
            onOk:()=>{
                delAdminNotebook({adminNotebookId:id}).then(res=>{
                    if(res.data.state.value == 0){
                        message.success('操作已完成!')
                        this.props.getQuestionListNumber()
                        this.getQuestionList()
                    }
                })
            }
        })

    }
    handlecheck=(id)=>{
        Modal.confirm({
            title:'提示',
            content:'确定解决了吗?',
            okType:'primary',
            onOk:()=>{
                solveAdminNotebook({adminNotebookId:id}).then(res=>{
                    if(res.data.state.value == 0){
                        message.success('操作已完成!')
                        this.getQuestionList()
                        this.props.getQuestionListNumber()
                    }
                })
            }
        })
    }
    render() {
        let _this = this
        let questionList = []
        this.state.questionListInfo.map((item, inedex) => {
            questionList.push(
                <ListItem
                    className='home-pb30'
                    key={item.id}
                    actions={[
                        <a onClick={() => this.handledelete(item.id)} className="red">
                            <Icon type='delete' className='mr10' />删除 </a>,
                        <a onClick={() => this.handlecheck(item.id)}>
                            <Icon type='check' className='mr10' /> 解决 </a>
                    ]}
                >
                    <ListItemMeta
                        title={<a><Icon type='message' className='mr10' /> {item.typeName} </a>}
                        description={'记录时间' + item.createDate}
                    />
                    {item.content}
                </ListItem>
            )
        })
        return (
            <div className="home">
                <List
                    className='max-height500-auto'
                    size='large'
                >
                    {questionList}
                </List>
                <Pagination {...this.state.pagination} pageSize={this.state.pageInfo.pageSize} className='mt10 text-align-center' />
            </div>)
    }
}