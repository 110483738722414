
/**
 * 业务API请求
 * （需要使用Mock拦截的请求）
 */
import ajax from '../Api/ajax';

export const getList = (data) => ajax('/api/getList', data, 'POST')
export const orderList = (data) => ajax('/api/orderList', data, 'POST')

export const agentOrderList = (params = { page: 0 }) => ajax('/api/agentOrderList', params, 'POST')
export const agentSalesmanList = (params = { page: 0 }) => ajax('/api/agentSalesmanList', params, 'POST')
export const agentManagement = (params = { page: 0 }) => ajax('/api/agentManagement', params, 'POST')
export const genCode = (params) => ajax('/api/genCode', params, 'POST')

// 活动设置
export const activityManagement = (params = { page: 0 }) => ajax('/api/activityManagement', params, 'POST')
export const dangerSearchList = (params = { page: 0 }) => ajax('/api/dangerSearchList', params, 'POST')
export const dangerSearch = (params = { page: 0 }) => ajax('/api/dangerSearch', params, 'POST')
export const dangerDel = (id) => ajax('/api/dangerDel', id, 'POST')
export const dangerEdit = (params = { page: 0 }) => ajax('/api/dangerEdit', params, 'POST')
export const dangerSubmit = (params = { page: 0 }) => ajax('/api/dangerSubmit', params, 'POST')

//首页Api
export const homeCheckList = (params) => ajax('/api/getHomeCheckList', params, 'POST')
export const getHomeCheckInfo = (params) => ajax('/api/getHomeCheckInfo', params, 'POST')
export const checkPass = (params) => ajax('/api/checkPass', params, 'POST')
export const checkDel = (params) => ajax('/api/checkDel', params, 'POST')
export const homeQuestionCheck = (params) => ajax('/api/homeQuestionCheck', params, 'POST')
export const homeQuestionList = (params) => ajax('/api/homeQuestionList', params, 'POST')
export const homeQuestionDel = (params) => ajax('/api/homeQuestionDel', params, 'POST')
export const homeQuestionResolve = (params) => ajax('/api/homeQuestionResolve', params, 'POST')
export const homeWarnInfo = (params) => ajax('/api/homeWarnInfo', params, 'POST')

//子管理员管理
export const userCreate = (params = { page: 0 }) => ajax('/api/userCreate', params, 'POST')
export const userEdit = (params = { page: 0 }) => ajax('/api/userEdit', params, 'POST')
export const userList = (params = { page: 0 }) => ajax('/api/userList', params, 'POST')
export const userDel = (params = { page: 0 }) => ajax('/api/userDel', params, 'POST')
export const userPassWord = (params = { page: 0 }) => ajax('/api/userPassWord', params, 'POST')
//权限管理
export const userRoleList = (params = { page: 0 }) => ajax('/api/userRoleList', params, 'POST')
export const userPermiss = (params = { page: 0 }) => ajax('/api/userPermiss', params, 'POST')
//行政区划管理
export const dmanageSearch = (params = { page: 0 }) => ajax('/api/dmanageSearch', params, 'POST')
export const dmanageAdd = (params = { page: 0 }) => ajax('/api/dmanageAdd', params, 'POST')
export const dmanageEdit = (params = { page: 0 }) => ajax('/api/dmanageEdit', params, 'POST')
export const dmanageDel = (params = { page: 0 }) => ajax('/api/dmanageDel', params, 'POST')



// 个人用户管理
// 打标签等共用的
export const userisAdminAdd = (params) => ajax('/api/userisAdminAdd', params, 'POST')//打标签
export const userBatchAdd = (params) => ajax('/api/userBatchAdd', params, 'POST')//批量打标签
export const userBatchRemove = (params) => ajax('/api/userBatchRemove', params, 'POST')//批量取消标签
// 邀请订单处理
export const closeOrder = (params) => ajax('/api/closeOrder', params, 'POST') //关闭订单  
export const invitationalJ = (params) => ajax('/api/invitationalJ', params, 'POST')
export const orderJ = (params) => ajax('/api/orderJ', params, 'POST')
export const cashJ = (params) => ajax('/api/cashJ', params, 'POST')
// 平台用户
export const getList2 = (params) => ajax('/api/getList2', params, 'POST')
export const basicP = (params) => ajax('/api/basicP', params, 'POST')
// 健康顾问
export const getList3 = (params) => ajax('/api/getList3', params, 'POST')
export const basicJ = (params) => ajax('/api/basicJ', params, 'POST')
export const consultingJ = (params) => ajax('/api/consultingJ', params, 'POST')
// 医生用户
export const getList4 = (params) => ajax('/api/getList4', params, 'POST')
export const prohibitEditor = (params) => ajax('/api/prohibitEditor', params, 'POST')
export const servicesDates = (params) => ajax('/api/servicesDates', params, 'POST')
export const doctorMessage = (params) => ajax('/api/doctorMessage', params, 'POST')
export const docAdded = (params) => ajax('/api/docAdded', params, 'POST')
export const docEditor = (params) => ajax('/api/docEditor', params, 'POST')
export const Subject = (params) => ajax('/api/Subject', params, 'POST')

// 文章
export const bookList = (params) => ajax('/api/BookList', params, 'POST')
export const IntegralList = (params) => ajax('/api/IntegralList', params, 'POST')
export const lookAll = (params) => ajax('/api/lookAll', params, 'POST')
export const typeList = (params) => ajax('/api/typeList', params, 'POST')


//体检报告
export const ReportList=(params={page:0})=>ajax('/api/ReportList',params,'POST')
export const SearchList=(params={page:0})=>ajax('/api/SearchList',params,'POST')
export const DelList=(params={page:0})=>ajax('/api/DelList',params,'POST')
export const ReportList1=(params={page:0})=>ajax('/api/ReportList1',params,'POST')
export const ReportEdit=(params={page:0})=>ajax('/api/ReportEdit',params,'POST')
export const ReportDel=(params={page:0})=>ajax('/api/ReportDel',params,'POST')
export const HandleEps=(params={page:0})=>ajax('/api/HandleEps',params,'POST')

//video
export  const videolist=(params={page:0})=>ajax('/api/videolist',params,'POST')


//每周答题
export const weekProList=(params={page:0})=>ajax('/api/weekProList',params,'POST')
export const specialList=(params={page:0})=>ajax('/api/specialList',params,'POST')
export const detailList=(params={page:0})=>ajax('/api/detailList',params,'POST')
export const ProblemList=(params={page:0})=>ajax('/api/ProblemList',params,'POST')
export const SaveProblemList=(params={page:0})=>ajax('/api/SaveProblemList',params,'POST')
export const selectProblem=(params={page:0})=>ajax('/api/selectProblem',params,'POST')
// 题库
export const SingleOptionQuestion = (params) => ajax('/api/singleOptionQuest', params, 'POST')
export const MultiOptionQuestion = (params) => ajax('/api/multiOptionQuest', params, 'POST')
export const FillInQuestion = (params) => ajax('/api/fillInQuest', params, 'POST')
//专项
export const SpecialDetailList=(params={page:0})=>ajax('/api/SpecialDetailList',params,'POST')
export const SpecialProblemList=(params={page:0})=>ajax('/api/SpecialProblemList',params,'POST')
export const SaveSpecialProblemList=(params={page:0})=>ajax('/api/SaveSpecialProblemList',params,'POST')
export const selectSpecialProblem=(params={page:0})=>ajax('/api/selectSpecialProblem',params,'POST')