/**
 * @file 页面：活动设置->编辑公告
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import Editor from './components/Editor'
import { Form, Button, Input } from 'antd'
import Preview from './components/Preview'
import BraftEditor from 'braft-editor'
import { statusMessage } from '../Training/Questions/features'
import { editPage, editPage2, saveSysNotice, saveHaNotice } from '../../Api'

/** 
 * @class
 * @extends React.Component
 */
class ActivityManagementEditForm extends React.Component {
    /**
     * 页面状态值存储区域
     * @type {Object}
     */
    state = {}

    /**
     * 页面载入时执行的函数
     * @type {Function}
     */
    componentDidMount() {
        if (this.props.location.state) {
            const pathList = this.props.location.pathname.split('/').reverse(), tableId = pathList[1], id = pathList[0], edit = tableId == 1 ? editPage2 : editPage
            edit({noticeId: id}).then(res => {
                this.setState({...res.data.result, tableId})
                if (res.data.result.sysFileList && res.data.result.sysFileList.length > 0) {
                    const attachmentLinks = this.attachmentLink(res.data.result.sysFileList)
                    this.setState({attachmentLinks})
                }
            }, reason => {
                statusMessage(reason)
            })
        }
        window.scrollTo(0, 0)
    }

    /**
     * 附件链接
     * @type {Function}
     * @param {Array.<String>} List 附件地址列表
     * @return {Array.<HTMLElement>}
     */
    attachmentLink = (List) => List.map(item => item.ossUrl).map(item => <a key={item} href={item}>{item.split('/').slice(-1)[0]}</a>)

    /**
     * 提交后事件
     * @type {Function}
     * @param {Event} e 提交按钮事件
     */
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
                const finalValues = {...values, id: this.state.id}, saveNotice = this.state.tableId == 1 ? saveHaNotice : saveSysNotice
                saveNotice(finalValues).then(res => {
                    console.log(res)
                    if(res.status === 200 && res.data.state.value == 0) {
                        this.props.history.push("/activity/management", {tableId: this.state.tableId == 1 ? '1' : '0', action: 'edit' })
                    }
                }, reason => {
                    statusMessage(reason)
                })
            }
        });
    }

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator } = this.props.form,
            formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 },
                },
            }

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="公告标题">
                    {getFieldDecorator('title', {
                        initialValue: this.state.title,
                        rules: [
                            {
                                required: true,
                                message: '请输入公告标题！',
                            },
                        ]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="附件" className={'attachmentRow'}>
                    {this.state.attachmentLinks ? this.state.attachmentLinks : '' }               
                </Form.Item>
                <Form.Item label="内容">
                    {getFieldDecorator('content', {
                        initialValue: BraftEditor.createEditorState(this.state.content),
                        rules: [
                            {
                                required: true,
                                message: '请输入内容！',
                            },
                        ]
                    })(<Editor />)}
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" className="mr10">
                        确定
                    </Button>
                    <Preview buttonText="预览"
                        content={this.props.form.getFieldValue('content').toHTML ? this.props.form.getFieldValue('content').toHTML() : this.props.form.getFieldValue('content')}
                        title={this.props.form.getFieldValue('title')}
                        date={new Date(this.state.createDate)}
                        attachment={this.props.form.getFieldValue('attachment')} />
                </div>
            </Form>

        )
    }
}

/**
 * 表单创建
 * @const {Form} ActivityManagementEdit
 */
const ActivityManagementEdit = Form.create()(ActivityManagementEditForm)
export default ActivityManagementEdit