/**
 * @description 权限编辑
 * @author yzx yangzhixin177@163.com
 */
import React, { Component } from 'react'
import { Tabs, Radio,Card,Icon,Table,Modal,Form,message,Button} from 'antd';
import {getRoleMenuList2,getRoleMenuList,saveRoleMenuList} from '../../../Api'
import './../../../Common/css/path.css'
/**
 * @description 权限编辑组件
 * @class PermissEdit
 * @extends {Component}
 */
export default class PermissEdit extends Component {
   
        state={
            isShow:false,
            rowSelection: undefined,
        }
        /**
         * @description 清空表格选中项
         * @function resetCheckedList
         * @memberof CategorymanageEdit
         */
        resetCheckedList = ()=>{
        this.setState({
            selectedRowKeys:[],
            selectedRows:null,
            selectedIds:[],
            selectItem:{},
        })
    }
        /**
         * @description 渲染警告内容的弹窗
         * @function renderModalSimple
         * @param {string} type 类型
         * @param {string} title 头文字
         * @param {string} content 内容
         * @returns {Object} Modal 返回一个弹框对象
         * @memberof CategorymanageEdit
         */
        renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({ 
            title:title?title:'警告',
            content:content?content:''
        })
    }
        /**
         * @description 点击编辑按钮处理
         * @function handelEdit
         * @memberof PermissEdit
         */
        handelEdit=() => {
            const data=this.state.data
            const selectKeys=[]
            for (let i = 0; i < data.length; i++) {
                if(data[i].isValid==true){
                    selectKeys.push(data[i].menuId)
                }
            }
            this.setState({
                isShow:true,
                rowSelection: {},
                selectedRowKeys:selectKeys
            })
        }
        /**
         * @description react周期函数，请求权限表
         * @function componentDidMount
         * @memberof PermissEdit
         */
        componentDidMount() {
        let _this=this
        const id = this.props.location.pathname ? this.props.location.pathname.split('/').reverse()[0] : 'edit'
        // console.log(this.props.location.state.name)
            if (id !== 'edit') {
            getRoleMenuList({roleId:id}).then((res)=>{
                _this.setState({
                    name:res.data.result.roleName,
                    remarks:res.data.result.remarks,
                    data:res.data.result.roleMenuList,
                    transId:id
                })
            })
            }
        }
        /**
         * @description 数据处理
         * @function handleData
         * @param {Array} Lists 选中的数组
         * @memberof PermissEdit
         */
        handleData=(Lists)=>{
            let data=this.state.data
            let newArr=[]
            for (var i=0;i<data.length;i++){
                data[i].isValid=null
                var obj={
                roleId:data[i].roleId,
                menuId:data[i].menuId,
                isValid:data[i].isValid
                }
                newArr.push(obj)
           }
            let finArr = data.filter(obj=>!Lists.some(obj1=>obj1.menuId==obj.menuId))
            return finArr    
        }
        /**
         * @description 监听表格选中变化事件
         * @function onSelectChange
         * @param {selectedRowKeys} 选中行的id数组
         * @param {selectedRows} 选中行的数组
         * @memberof PermissEdit
         */
        onSelectChange = (selectedRowKeys,selectedRows) => {
            // console.log('selectedRowKeys changed: ', selectedRows);
            var Lists=[]
            selectedRows.map((item) => {
              var obj={
                  roleId:item.roleId,
                  menuId:item.menuId,
                  isValid:true,
              }
              Lists.push(obj)
         })
            this.setState({ selectedRowKeys,selectedRows,Lists: Lists});
        };
        /**
         * @description 编辑取消事件
         * @function handelEditCancel
         * @memberof PermissEdit
         */
        handelEditCancel=()=>{
            this.setState({
            isShow:false,
            rowSelection:undefined,
            selectedRowKeys: [],
            selectedRows:[],
            Lists:[]
            })
        }
        /**
         * @description 点击保存事件
         * @function handleSubmit
         * @memberof PermissEdit
         */
        handleSubmit=()=>{
            let Lists=this.state.Lists
            let finLists=[]
            let id=this.state.transId
            let _this=this
            if(Lists){
                finLists=this.handleData(Lists).concat(Lists)
                // console.log(finLists)
                Modal.confirm({
                    title:'修改权限',
                    content: `确定要修改权限？`,
                    onOk:()=>{
                        saveRoleMenuList(finLists).then((res)=>{
                            if(res.data.state.value == 0){
                                _this.setState({
                                    isVisible:false
                                })
                                message.success('修改成功');
                                getRoleMenuList({roleId:id}).then((res)=>{
                                    _this.setState({
                                        name:res.data.result.roleName,
                                        remarks:res.data.result.remarks,
                                        data:res.data.result.roleMenuList,
                                        transId:id,
                                        isShow:false,
                                        selectedRowKeys: [],
                                        selectedRows:[],
                                        Lists:[]
                                    })
                                })
                            }
                            else{
                                this.renderModalSimple('warning','错误','修改失败!')
                            }
                        })
                    }
                })
                
            }else{
                this.renderModalSimple('warning','错误','请做出修改!')
         }
        }
      render() {
        const { selectedRowKeys,selectedRows} = this.state;
        const rowSelection1 = {
          selectedRowKeys,
          selectedRows,
          onChange: this.onSelectChange,
        }
        const isShow=this.state.isShow;
        const name=this.state.name;
        const remarks=this.state.remarks;
        const columns = [
            {
                title: '模块名称',
                key: 'menuName',
                dataIndex: 'menuName'
            },{
                title: '是否有权限',
                key: 'type',
                dataIndex: 'type',
                render(text,record){
                    if(record.isValid&&record.id){
                        return <span style={{color:'#339DFF'}}>√</span>
                    }else{
                        return <span style={{color:'#F5222D'}}>×</span>
                    }

                }
            }
        ]; 
        return (
          <Card>
              <div>
                <div>角色名称:{name}</div>
                <div>角色描述:{remarks}</div>
            </div>
            <div style={{height:40}} >
            <Button className={isShow==true?'unshow':'show'} style={{float:"right"}} type='primary' onClick={()=>this.handelEdit()}>批量修改权限</Button>
            <div className={isShow==true?'show':'unshow'} style={{float:"right"}}>
                    <Button type='primary' style={{marginRight:6}} onClick={()=>this.handleSubmit()}>
                        保存
                        </Button>
                    <Button onClick={()=>this.handelEditCancel()}>
                        取消
                        </Button>
            </div>
            </div>
           
                <Table 
                        bordered
                        columns={columns}
                        dataSource={this.state.data}
                        rowSelection={this.state.rowSelection?rowSelection1:undefined}
                        rowKey={record => record.menuId}
                        pagination={false}
                    /> 
          </Card>
        );
      }
} 
