import React, { Component } from 'react'
import { Card, Row, Col, Icon, Popover, Button, Modal, Form, Select, Input, List, Tabs, Pagination, Badge } from 'antd'
import ReactEcharts from 'echarts-for-react'
import { NavLink } from 'react-router-dom'
import utils from '../../../Common/js/utils'
import { homeQuestionCheck, homeQuestionList } from '../../../Mock'
import { getAdminNotebookListData } from './../../../Api'
import '../home.css'
const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea
const TabPane = Tabs.TabPane
const ListItem = List.Item
const ListItemMeta = List.Item.Meta
export default class View extends React.Component {
    state={
        questionListInfo:[],
        pageInfo:{
            pageNum:1,
            pageSize:10
        }
    }
    componentDidMount(){
        this.props.onRef(this)
    }
    getQuestionListTotal = ()=>{
        return this.state.questionListInfo.length
    }
    getQuestionList = (data) => {
        let params = {...this.state.pageInfo,...data}
        let _this = this
        params.status = 2
        getAdminNotebookListData(params).then(res => {
            if (res.data.state.value == 0) {
                this.setState({
                    questionListInfo: res.data.result.rows,
                    viewModal: true,
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.pageInfo.pageNum = current
                        _this.state.pageInfo.pageSize = size
                        this.getQuestionList()
                    }, (current, size) => {
                        _this.state.pageInfo.pageNum = current
                        _this.state.pageInfo.pageSize = size
                        this.getQuestionList()
                    }, res.data.result.pageSize)
                })
            }
        })
    }
    componentWillMount(){
        this.getQuestionList()
    }
    componentWillReceiveProps(){
        this.getQuestionList()
    }
    render() {
        let questionList =[]
        this.state.questionListInfo.map((item, inedex) => {
            questionList.push(
                <ListItem
                    className='home-pb30'
                    key={item.id}
                    actions={[
                        <div>
                            <Icon type='check' className='mr10 green'></Icon>
                            <span className='green'>解决时间{item.updateDate}</span>
                        </div>
                    ]}
                >
                    <ListItemMeta
                        title={<a><Icon type='message' className='mr10' /> {item.typeName} </a>}
                        description={'记录时间' + item.createDate}
                    />
                    {item.content}
                </ListItem>
            )
        })
        return (
            <div>
                <List
                    className='max-height500-auto'
                    size='large'
                >
                    {questionList}
                </List>
                <Pagination {...this.state.pagination} pageSize={this.state.pageInfo.pageSize} className='mt10 text-align-center' />
            </div>)
    }
}