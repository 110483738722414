import React, { Component } from 'react'
import {Card,Button,Form,Input,Select,Cascader,DatePicker,message} from 'antd'
import { viewCompany,editCompany,getAreaOptions } from './../../../../Api'
import moment from 'moment'
import { resolve } from 'dns'
moment.locale('zh-cn')
let timer
const { Option } = Select

class UnitBasic extends Component {
    state={
        display_none: 'none', //此状态机为display的取值
        display_block: 'block', //此状态机为display的取值
        cid:this.props.record,
        companyInfo:{},
        areaOptions:[]
    }
    requestList = ()=>{
        viewCompany(this.state.cid).then(res=>{
            if(res.status === 200 && res.data.state.value ===0){
                if(res.data.result.companyGovernModel){res.data.result.companyGovernModel.registerAddress2 = []}
                this.setState({
                    companyInfo:res.data.result
                })
            }else{
                message.warning('获取企业信息失败！')
            }
        })
    }
    componentWillMount=()=>{
        this.requestArea()
    }
    componentWillReceiveProps=()=>{
        this.setState({
            companyInfo:this.props.companyInfo()
        })
    }
    requestAreaList=async ()=>{
        let arr = []
        if(this.state.companyInfo.companyGovernModel.provinceId){
            arr.push(this.state.companyInfo.companyGovernModel.provinceId)
        }
        if(this.state.companyInfo.companyGovernModel.cityId){
            arr.push(this.state.companyInfo.companyGovernModel.cityId)
        }
        if(this.state.companyInfo.companyGovernModel.areaId){
            arr.push(this.state.companyInfo.companyGovernModel.areaId)
        }
        if(this.state.companyInfo.companyGovernModel.streetId){
            arr.push(this.state.companyInfo.companyGovernModel.streetId)
        }
        await this.requestArea({value:this.state.companyInfo.companyGovernModel.provinceId?this.state.companyInfo.companyGovernModel.provinceId:'none'})
        await this.requestArea({value:this.state.companyInfo.companyGovernModel.cityId?this.state.companyInfo.companyGovernModel.cityId:'none'})
        await this.requestArea({value:this.state.companyInfo.companyGovernModel.areaId?this.state.companyInfo.companyGovernModel.areaId:'none'})
        await this.requestArea({value:this.state.companyInfo.companyGovernModel.streetId?this.state.companyInfo.companyGovernModel.streetId:'none'})        
        await new Promise((resolve)=>{
            let copy = this.state.companyInfo
            copy.companyGovernModel.registerAddress2 = arr
            this.setState({
                companyInfo:copy,
                display_none: 'block',
                display_block: 'none',
            })
            resolve()
        })
    }
    //点击编辑
    switchTal = () => {
        if(this.state.companyInfo.companyGovernModel){
            this.requestAreaList()
        }else{
            this.setState({
                display_none: 'block',
                display_block: 'none',
            })
        }
    }
     //区域级联+异步加载
     requestArea = (itemValue)=>{
        let pid = itemValue&&itemValue.value?itemValue.value:''
        let promise
         if(pid == 'none'){
            return
         }else{
            getAreaOptions({parentId:pid}).then(res=>{
                promise =  new Promise((resolve)=>{
                    let induOptions = []
                    if(res.status === 200 && res.data.state.value === 0){
                            //格式化行业级联数据
                            res.data.result&&res.data.result.length&&res.data.result.map(item=>{
                                induOptions.push({
                                    value:item.id,
                                    key:item.id,
                                    pid:item.parentId,
                                    label:item.name,
                                    isLeaf:item.level==4?true:false,
                                })
                            })
                            //插入行业分类数据
                            if(induOptions.length){
                                this.restore(this.state.areaOptions,induOptions)
                            }
                            resolve()
                    }
                })
                
            })
            return promise
         }
    }
    //区域-loaddata异步获取级联数据
    getArea = (a)=>{
        this.requestArea(a[a.length-1])
    }
    //级联选择后自动赋值
    handleSetValue = (idArr,itemArr,type)=>{
        if(itemArr.length){
            let str = ''
            itemArr.map(item=>{
                str += item.label
            })
            this.props.form.setFieldsValue({[type]:str})
        }
    }
    //递归插入级联数据数据
    restore = (orignalData,listData)=>{
        if(orignalData.length){
            orignalData.map(item=>{
                if(item.children&&item.children.length){
                    this.restore(item.children,listData)
                }else{
                    if(item.value == listData[0].pid){
                        item.children = listData
                        return
                    }
                }
            })
            this.setState({
                areaOptions:orignalData
            })
            return
        }else{
            this.setState({
                areaOptions:listData
            })
            return
        }
    }
    //下拉
    handleSelectChange = value => {
        this.props.form.setFieldsValue({
            //note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
        });
    };
    //保存按钮
    handleSave = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                let params = {
                    cid:this.state.cid,
                    companyBaseModel:{},
                    companyPlatformModel:{}
                }
                //companyPlatformModel部分（cid\id必传）
                params.companyPlatformModel.id = this.state.companyInfo.companyPlatformModel.id//id
                params.companyPlatformModel.cid = this.state.cid //cid
                params.companyPlatformModel.regType = values.regType //用户类型
                //companyBaseModel部分（cid\id必传）
                params.companyBaseModel.id = this.state.companyInfo.companyBaseModel.id//id
                params.companyBaseModel.name = values.name//企业名称
                params.companyBaseModel.shortName = values.shortName//简称
                params.companyBaseModel.fatherDept = values.fatherDept//上属公司(或主管单位)
                params.companyBaseModel.unifiedSocialCc = values.unifiedSocialCc//统一社会信用代码(扩展)
                params.companyBaseModel.areaId = values.registerAddress2&&values.registerAddress2[values.registerAddress2.length-1]//行政区域编号
                params.companyBaseModel.registerPost = values.registerPost//企业注册地址邮编
                params.companyBaseModel.companyContactTel = values.companyContactTel//企业联系电话
                params.companyBaseModel.companyContactEmail = values.companyContactEmail//企业联系邮箱
                params.companyBaseModel.companyContactFax = values.companyContactFax//企业传真
                params.companyBaseModel.settingTime = values.settingTime?moment(values.settingTime).format('YYYY-MM-DD'):''//成立时间
                //地址暂时无法对接
                params.companyBaseModel.registerAddress = values.registerAddress
                params.companyBaseModel.companyPostalAdress = values.companyPostalAdress
                params.companyBaseModel.workAddress = values.workAddress
                editCompany(params).then((res)=>{
                    if(res.status===200){
                        this.props.form.resetFields();//取消先清空之前输入的数据
                        this.setState({ //跳转到查看页面
                            display_none: 'none',
                            display_block: 'block',
                        })
                        this.props.requestList().then(res=>{
                            this.setState({
                                companyInfo:res
                            })
                        })
                    }
                })
            }
        });

        // this.setState({
        //     display_none: 'none',
        //     display_block: 'block',
        // })
    };
    //取消按钮
    handleReset = () => {
        this.props.form.resetFields();//取消先清空之前输入的数据
        this.setState({ //跳转到查看页面
            display_none: 'none',
            display_block: 'block',
        })
    }
    //日期处理
    onChange = (date, dateString) => {
        console.log(date, dateString);
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        //电话号码
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '86',
        })(
            <Select style={{ width: 70 }}>
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>,
        );
        const {companyInfo} = this.state
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="基础信息" 
                extra={
                    <Button style={{display:this.state.display_block}} type="primary" onClick={this.switchTal}>编辑</Button>
                }>
                    <Form className="unitBasicView" style={{display:this.state.display_block}} layout="inline">
                        <Form.Item label="用户类型">
                            <div className="unitBasicData">{companyInfo.companyPlatformModel?companyInfo.companyPlatformModel.regTypeStr:''}</div>
                        </Form.Item>
                        <Form.Item label="企业名称">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.name:' '}</div>
                        </Form.Item>
                        <Form.Item label="简称">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.shortName:' '}</div>
                        </Form.Item>
                        <Form.Item label="上属公司(或主管单位)">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.fatherDept:' '}</div>
                        </Form.Item>
                        <Form.Item label="统一社会信用代码(扩展)">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.unifiedSocialCc:' '}</div>
                        </Form.Item>
                        {/* <Form.Item label="行政区域编号">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.areaId:' '}</div>
                        </Form.Item> */}
                        <Form.Item label="企业联系邮箱">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyContactEmail:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业注册地址">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.registerAddress:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业注册地址邮编">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.registerPost:' '}</div>
                        </Form.Item>
                        <Form.Item label="工作场所地址">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.workAddress:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业通讯地址">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyPostalAdress:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业联系电话">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyContactTel:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业传真">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyContactFax:' '}</div>
                        </Form.Item>
                        <Form.Item label="成立时间">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?(companyInfo.companyBaseModel.settingTime?moment(companyInfo.companyBaseModel.settingTime).format('YYYY-MM-DD'):' '):' '}</div>
                        </Form.Item>
                        <Form.Item className="kongForm"></Form.Item>
                    </Form>

                    <Form className="unitBasicEdit" style={{display:this.state.display_none}} layout="inline">
                        <Form.Item label="用户类型">
                            {getFieldDecorator('regType',{
                                initialValue:companyInfo.companyPlatformModel?companyInfo.companyPlatformModel.regType:'',
                            })(
                                <Select
                                    placeholder="用户类型"
                                    onChange={this.handleSelectChange}
                                >
                                    <Option value={1}>企业注册</Option>
                                    <Option value={2}>医疗机构注册</Option>
                                    <Option value={3}>其他机构注册</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="企业名称">
                            {getFieldDecorator('name',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.name:'',
                                rules:[
                                    {required:true,message:'请输入企业名称！'}
                                ]
                            })(
                                <Input placeholder="企业名称"/>
                            )}
                        </Form.Item>
                        <Form.Item label="简称">
                            {getFieldDecorator('shortName',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.shortName:'',
                            })(
                                <Input placeholder="简称"/>
                            )}
                        </Form.Item>
                        <Form.Item label="上属公司(或主管单位)">
                            {getFieldDecorator('fatherDept',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.fatherDept:'',
                            })(
                                <Input placeholder="上属公司(或主管单位)"/>
                            )}
                        </Form.Item>
                        <Form.Item label="统一社会信用代码(扩展)">
                            {getFieldDecorator('unifiedSocialCc',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.unifiedSocialCc:'',
                                rules:[
                                    {required:true,message:'请输入统一社会信用代码(扩展)！'}
                                ]
                            })(
                                <Input placeholder="统一社会信用代码(扩展)"/>
                            )}
                        </Form.Item>
                        {/* <Form.Item label="行政区域编号">
                            {getFieldDecorator('areaId',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.areaId:'',
                            })(
                                <Input placeholder="行政区域编号"/>
                            )}
                        </Form.Item> */}
                        <Form.Item label="企业联系邮箱">
                            {getFieldDecorator('companyContactEmail',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyContactEmail:'',
                                rules:[
                                    {required:true,message:'请输入企业联系邮箱！'},
                                    {type:'email',message:'请输入正确的邮箱格式！'}
                                ]
                            })(
                                <Input placeholder="企业联系邮箱"/>
                            )}
                        </Form.Item>
                        <Form.Item label="企业注册地址">
                            <Form.Item className="address">
                                {getFieldDecorator('registerAddress2',{
                                initialValue:companyInfo.companyGovernModel&&companyInfo.companyGovernModel.registerAddress2?companyInfo.companyGovernModel.registerAddress2:[],
                            })(
                                <Cascader
                                    placeholder="企业注册地址"
                                    options={this.state.areaOptions} 
                                    changeOnSelect
                                    onChange={(idarr,itemarr)=>this.handleSetValue(idarr,itemarr,'registerAddress')}
                                    loadData={this.getArea}
                                />
                                )}
                            </Form.Item>
                            <Form.Item className="addressed">
                                {getFieldDecorator('registerAddress',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.registerAddress:'',
                            })(
                                    <Input placeholder="企业注册地址"/>
                                )}
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label="企业注册地址邮编">
                            {getFieldDecorator('registerPost',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.registerPost:'',
                            })(
                                <Input placeholder="企业注册地址邮编"/>
                            )}
                        </Form.Item>
                        <Form.Item label="工作场所地址">
                            <Form.Item className="address">
                                {getFieldDecorator('workAddress2',{
                                initialValue:[],
                                //initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.workAddress:[],
                            })(
                                    <Cascader
                                        placeholder="工作场所地址"
                                        options={this.state.areaOptions} 
                                        changeOnSelect
                                        onChange={(idarr,itemarr)=>this.handleSetValue(idarr,itemarr,'workAddress')}
                                        loadData={this.getArea}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item className="addressed">
                                {getFieldDecorator('workAddress',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.workAddress:'',
                            })(
                                    <Input placeholder="工作场所地址"/>
                                )}
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label="企业通讯地址">
                            <Form.Item className="address">
                                {getFieldDecorator('companyPostalAdress2',{
                                    initialValue:[],                                
                                //initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyPostalAdress:[],
                            })(
                                    <Cascader
                                        placeholder="企业通讯地址"
                                        options={this.state.areaOptions} 
                                        onChange={(idarr,itemarr)=>this.handleSetValue(idarr,itemarr,'companyPostalAdress')}
                                        changeOnSelect
                                        loadData={this.getArea}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item className="addressed">
                                {getFieldDecorator('companyPostalAdress',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyPostalAdress:'',
                            })(
                                    <Input placeholder="企业通讯地址"/>
                                )}
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label="企业联系电话">
                            {getFieldDecorator('companyContactTel',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyContactTel:'',
                            })(
                                <Input addonBefore={prefixSelector} placeholder="企业联系电话"/>
                            )}
                        </Form.Item>
                        <Form.Item label="企业传真">
                            {getFieldDecorator('companyContactFax',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.companyContactFax:'',
                            })(
                                <Input placeholder="企业传真"/>
                            )}
                        </Form.Item>
                        <Form.Item label="成立时间">
                            {getFieldDecorator('settingTime',{
                                initialValue:companyInfo.companyBaseModel?(companyInfo.companyBaseModel.settingTime?moment(companyInfo.companyBaseModel.settingTime):null):null
                            })(
                                <DatePicker 
                                format="YYYY-MM-DD"
                                placeholder="成立时间"/>
                            )}
                        </Form.Item>
                        <Form.Item className="kongForm"></Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.handleSave}>
                                保 存
                            </Button>
                            <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                                取 消
                            </Button>
                        </Form.Item>
                    </Form>
                </Card> 
            </div>
        )
    }
}

const UnitBasicForm = Form.create()(UnitBasic);

export default UnitBasicForm;

//export default Form.create()(UnitBasic); 