import React, { Component } from 'react'
import {Card,Modal,Table,Pagination} from 'antd'
import {getSoftLogListData} from './../../../../Api/index'
import utils from './../../../../Common/js/utils'
import Notebook from './../Common/Notebook'//备注
let ids
class DoctorRecord extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        visibleNote:false,
        //corpSoftId: this.props.corpSoftId,//企业id
        DoctorBasic: this.props.DoctorBasic,
        dataList:[],//列表数据
        pageNum:1,
        pageSize:10,
    };
    componentWillMount() {
        this.requestAdjustList();
    }
    // componentWillReceiveProps(nextProps){
    //     if(nextProps.keys === "5"){
    //         ids = nextProps.DoctorBasic.corpSoftId
    //         this.requestAdjustList();
    //     }
    // }
    //列表数据接口请求
    requestAdjustList = () => {
        let _this = this
        getSoftLogListData({
            cid:this.state.cid,
            // corpSoftId:ids ? ids : this.props.healthBasic.corpSoftId,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize
        }).then((res)=>{
            console.log(res);
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total:res.data.result.total,
                    dataList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.requestAdjustList()
                    },(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.requestAdjustList()
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        })  
    }

    render() {
        //表格 表头
        const columns = [
            {title: '序号',dataIndex: 'key',align:'center'},
            {title: '电话券',dataIndex: 'tel',align:'center'},
            {title: '图文券',dataIndex: 'net',align:'center'},
            {title: '通用券',dataIndex: 'common',align:'center'},
            {title: '赠送时间',dataIndex: 'createDate',align:'center'},
            {title: '赠送人',dataIndex: 'creator',align:'center'}
        ];
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="赠送记录">
                    <Table bordered
                    columns={columns} 
                    dataSource={this.state.dataList}
                    pagination={false} />
                    <Pagination {...this.state.pagination}
                        className="fr margin10"
                    />
                </Card>

                {/* 备注弹出 */}
                <Modal
                visible={this.state.visibleNote}
                loading={this.state.loading}
                title="备注"
                onOk={this.handleOkNote}
                onCancel={this.handleCancelNote}
                okText="确定"
                cancelText="取消"
                >
                    <Notebook record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
            </div>
        )
    }
}

export default DoctorRecord;