import React, { Component } from 'react';
import { Form } from 'antd';
import './../index.css';
import { getVideoCreator } from "../../../../Api";
import { withRouter } from 'react-router-dom';
import STATUS from "../../../../Common/js/status";
import moment from "moment";
const FormItem = Form
/**
 * @description 视频详情查看
 * @class Addvideo
 * @extends {Component}
 * @property {Object} location.state.record -视频详细信息
 * */
class Viewvideo extends Component {
    state = {
        loading: false,
        record: {}
    };
    componentWillMount () {
        let record = this.props.location.state.record
        //获取详情数据
        // getVideoCreator(this.props.location.state.record.id).then(res=>{})
        this.setState({
            record: record
        })
    }
    render () {
        const { record } = this.state;
        return (
            <div className="add-video">
                <table className="add-video-table width-100" border={"2"}>
                    <tbody>
                        <tr>
                            <td>视频名称</td>
                            <td colSpan={3}>
                                <FormItem>
                                    {record.name}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td>类别</td>
                            <td colSpan={3}>
                                <FormItem>
                                    {STATUS.TRAIN_TYPE[record.type]}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td>视频地址</td>
                            <td colSpan={3}>
                                <FormItem>
                                    {record.url ? <a href={record.url} target="_blank">{record.url}</a> : null}
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>腾讯视频地址</td>
                            <td colSpan={3}>
                                <FormItem>
                                    {record.url ? <a href={record.txUrl} target="_blank">{record.txUrl}</a> : null}
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>来源</td>
                            <td colSpan={3}>
                                <FormItem>
                                    {record.source}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td>视频画面</td>
                            <td colSpan={3}>
                                <FormItem>
                                    {record.picList && record.picList[0] ? <img height={"300"} alt={""} src={record.picList[0].fullOssurl} /> : "暂无"}
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>视频简介</td>
                            <td colSpan={3}>
                                <FormItem>
                                    {record.description}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td>视频时长</td>
                            <td colSpan={3}>
                                <FormItem>
                                    {record.minutes}
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>编制人</td>
                            <td>
                                <FormItem>
                                    {record.compiler}
                                </FormItem>

                            </td>
                            <td>上架时间</td>
                            <td>
                                <FormItem>
                                    {!!record.shelvesTime ? moment(record.shelvesTime).format("YYYY-MM-DD") : ''}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td width={"150"}>观看数</td>
                            <td>
                                <FormItem>
                                    {record.hot}
                                </FormItem>

                            </td>
                            <td width={"150"}>收藏数</td>
                            <td>
                                <FormItem>
                                    {record.up}
                                </FormItem>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withRouter(Viewvideo)