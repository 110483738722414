import React, { Component } from 'react'
import { Tabs,message } from 'antd'
import {getHealthBasic,viewCompany} from './../../Api/index'
import OpenSoftware from './components/UserUnit/OpenSoftware'//软件是否开通

import UnitBasic from './components/UserUnit/UnitBasic'//基本信息-基础信息
import UnitBusiness from './components/UserUnit/UnitBusiness'//基本信息-经营信息
import UnitHealth from './components/UserUnit/UnitHealth'//基本信息-职业卫生相关信息
import UnitOther from './components/UserUnit/UnitOther'//基本信息-其他信息

import HealthBasic from './components/UserUnit/HealthBasic'//职业健康-基本信息
import HealthOrder from './components/UserUnit/HealthOrder'//职业健康-订单信息
import HealthRecord from './components/UserUnit/HealthRecord'//职业健康-调整记录

import GroupBasic from './components/UserUnit/GroupBasic'//职业健康集团版-基本信息
import GroupOrder from './components/UserUnit/GroupOrder'//职业健康集团版-订单信息
import GroupRecord from './components/UserUnit/GroupRecord'//职业健康集团版-调整记录

import RadiaBasic from './components/UserUnit/RadiaBasic'//放射检测-基本信息
import RadiaOrder from './components/UserUnit/RadiaOrder'//放射检测-订单信息
import RadiaRecord from './components/UserUnit/RadiaRecord'//放射检测-调整记录

import DoctorBasic from './components/UserUnit/DoctorBasic'//健康顾问-基本信息
import DoctorRecord from './components/UserUnit/DoctorRecord'//健康顾问-赠送记录

import AccountManage from './components/UserUnit/AccountManage'//登陆账号管理

import MedicalManage from './components/UserUnit/MedicalManage'//体检查询管理-企业授权列表
import MedicalCheckup from './components/UserUnit/MedicalCheckup'//体检查询管理-体检单列表

const { TabPane } = Tabs

class UserUnitBasic extends Component {
    state={
        record:this.props.match.params.id,
        key:"",
        healthBasic:[],//职业健康基础信息
        groupBasic:[],//职业健康集团版基础信息
        RadiaBasic:[],//放射检测
        DoctorBasic:[],//健康顾问
        companyInfo:[],//提供给基础信息
        companyInfoStatus:false
    }
    componentWillMount(){
        this.requestList()
    }
    //获取企业信息-提供给基础信息子组件
    requestList = ()=>{
        return viewCompany(this.state.record).then(res => {
            return new Promise((resolve, reject) => {
                if (res.status === 200 && res.data.state.value === 0) {
                    this.setState({
                        companyInfoStatus:true,
                        companyInfo: res.data.result
                    })
                    resolve(res.data.result)
                } else {
                    message.warning('获取企业信息失败！')
                    reject([])
                }
            })

        })
    }
    //子组件获取父组件数据接口
    getCompanyInfo = ()=>{
        if(!this.state.companyInfoStatus)return
        return this.state.companyInfo
    }
    //软件基本信息接口
    requestBasic = (types) => {
        getHealthBasic({cid:this.state.record,softType:types}).then((res)=>{
            //console.log(res,"-----------");
            this.setState({
                res:res
            })
        }).catch((error)=>{
            console.log(error);
        })  
    }
    //健康顾问软件基本信息

    //tab切换
    callback = (key) => {
        if(key === '1'){
            this.requestList()
        }else if(key === '2'){
            //软件基本信息接口
            this.requestBasic(1)
        }else if(key === '3'){
            //软件基本信息接口
            this.requestBasic(4)
        }else if(key === '4'){
            //软件基本信息接口
            this.requestBasic(2)
        }else if(key === '5'){
            //软件基本信息接口
            this.requestBasic(3)
            //this.HealthBasicAancal()
        }else{
            //软件基本信息接口
            // this.requestBasic()
        }
        this.setState({
            key:key
        })
    }

    fnRecord = () => {
        this.refs.Record.requestAdjustList() //调用子组件的requestAdjustList方法
    }
    fnOrder = () => {
        this.refs.Order.RequestOrderList() //调用子组件的RequestOrderList方法
    }
    render() {
        return (
            <div className="padding20">
                <Tabs onChange={this.callback} type="line" animated={false} defaultActiveKey="1">
                    <TabPane tab="基本信息" key="1">
                        <UnitBasic record={this.state.record} companyInfo={this.getCompanyInfo} requestList={this.requestList}/>
                        <UnitBusiness record={this.state.record} companyInfo={this.getCompanyInfo} requestList={this.requestList}/>
                        <UnitHealth record={this.state.record} companyInfo={this.getCompanyInfo} requestList={this.requestList}/>
                        <UnitOther record={this.state.record} companyInfo={this.getCompanyInfo} requestList={this.requestList}/>
                    </TabPane>
                    <TabPane tab="职业健康" key="2">
                        {
                            this.state.res ? 
                                (this.state.res.data.result ?
                                    <div>
                                        <HealthBasic record={this.state.record} healthBasic={this.state.res.data.result} 
                                        //向子组件传递父组件方法
                                        ref="child" onRef={this.onRef} requestBasic={this.requestBasic}
                                        fnRecord={this.fnRecord} fnOrder={this.fnOrder}/>
                                        <HealthOrder record={this.state.record} 
                                        ref="Order"/>
                                        <HealthRecord record={this.state.record} healthBasic={this.state.res.data.result} keys={this.state.key}
                                        ref="Record"/>
                                    </div>
                                : <OpenSoftware record={this.state.record} keys={this.state.key}
                                ref="child" onRef={this.onRef} requestBasic={this.requestBasic}/> )
                            :null
                        }
                    </TabPane>
                    <TabPane tab="职业健康集团版" key="3">
                        {
                            // console.log(this.state.res2)
                            this.state.res ? 
                                (this.state.res.data.result ?
                                    <div>
                                        <GroupBasic record={this.state.record} groupBasic={this.state.res.data.result} 
                                        //向子组件传递父组件方法
                                        ref="child" onRef={this.onRef} requestBasic={this.requestBasic}
                                        fnRecord={this.fnRecord} fnOrder={this.fnOrder}/>
                                        <GroupOrder record={this.state.record}
                                        ref="Order"/>
                                        <GroupRecord record={this.state.record} groupBasic={this.state.res.data.result}  keys={this.state.key}
                                        ref="Record"/>
                                    </div>
                                : <OpenSoftware record={this.state.record} keys={this.state.key}
                                ref="child" onRef={this.onRef} requestBasic={this.requestBasic}/> )
                            :null
                        }
                    </TabPane>
                    <TabPane tab="放射卫生&辐射安全管理系统" key="4">
                        {
                            this.state.res ? 
                                (this.state.res.data.result ?
                                    <div>
                                        <RadiaBasic record={this.state.record} RadiaBasic={this.state.res.data.result} 
                                        //向子组件传递父组件方法
                                        ref="child" onRef={this.onRef} requestBasic={this.requestBasic}
                                        fnRecord={this.fnRecord} fnOrder={this.fnOrder}/>
                                        <RadiaOrder record={this.state.record} 
                                        ref="Order"/>
                                        <RadiaRecord record={this.state.record} RadiaBasic={this.state.res.data.result} keys={this.state.key}
                                        ref="Record"/>
                                    </div>
                                : <OpenSoftware record={this.state.record} keys={this.state.key}
                                ref="child" onRef={this.onRef} requestBasic={this.requestBasic}/> )
                            :null
                        }
                    </TabPane>
                    <TabPane tab="健康顾问" key="5">
                        {
                            this.state.res ? 
                                (this.state.res.data.result ?
                                    <div>
                                        <DoctorBasic record={this.state.record} DoctorBasic={this.state.res.data.result} 
                                        //向子组件传递父组件方法
                                        ref="child" onRef={this.onRef} requestBasic={this.requestBasic}
                                        fnRecord={this.fnRecord} />
                                        <DoctorRecord record={this.state.record} DoctorBasic={this.state.res.data.result} keys={this.state.key}
                                        ref="Record"/>
                                    </div>
                                : <OpenSoftware record={this.state.record} keys={this.state.key}
                                ref="child" onRef={this.onRef} requestBasic={this.requestBasic}/> )
                            :null
                        }
                    </TabPane>
                    <TabPane tab="登陆账号管理" key="6">
                        <AccountManage record={this.state.record} />
                    </TabPane>
                    <TabPane tab="体检查询管理" key="7">
                        <MedicalManage record={this.state.record} />
                        <MedicalCheckup record={this.state.record} />
                    </TabPane>
                    <TabPane tab="代理商信息" key="8">
                        代理商信息
                    </TabPane>
                </Tabs>

            </div>
        )
    }
}

export default UserUnitBasic;