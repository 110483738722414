import React, { Component } from 'react'
import {Card,Modal} from 'antd'
import {getSoftTicket} from './../../../../Api/index'
// import moment from 'moment'

import imgUrl from './../../../../Common/images/jkgw.png'
import FreeAdmission from './../Common/FreeAdmission'//赠送健康顾问券
// import Adjust from './../Common/Adjust3'//调整软件属性
// let ids
class DoctorBasic extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        // visible: false,
        adjustvisible:false,
        DoctorBasic: this.props.DoctorBasic,
        OrderList:[],//列表数据
        pageNum:1,
        pageSize:10,
    };

    //调整属性弹出显示状态
    showModalAdjust = () => {
        this.setState({
            adjustvisible: true,
        });
    };
    //调整属性弹出框确定按钮操作
    adjustOk = () => {
        this.setState({ loading: true });
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, adjustvisible: false });
        this.getSoftAdjustRegister(formdata);
    };
    //调整属性弹出框取消按钮操作
    adjustCancel = () => {
        this.setState({ adjustvisible: false });
        this.formRef.resetValue(); 
    };
    // componentWillReceiveProps(nextProps){
    //     ids = nextProps.healthBasic
    //     //console.log(nextProps);
    // }
    //调整接口
    getSoftAdjustRegister = (data) => {
        //console.log(data);
        //console.log(ids,this.state.healthBasic.corpSoftId,"==============");
        getSoftTicket({
            cid:this.state.cid,
            //corpSoftId:ids ? ids.corpSoftId : this.state.healthBasic.corpSoftId,
            ticketCommon:data.ticketCommon,
            ticketTel:data.ticketTel,
            ticketNet:data.ticketNet
        }).then((res)=>{
            this.props.requestBasic(3);
            //this.props.fnRecord();
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    render() {
        const DoctorBasic = this.props.DoctorBasic;
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="基本信息">
                    <div className="HealthBasicPicture">
                        <img alt="职业健康企业版" src={imgUrl} />
                        <ul>
                            <li>
                                <div className="title">软件名称：</div>
                                <div className="neire">{DoctorBasic.softName ? DoctorBasic.softName : ''}</div>
                            </li>
                            <li>
                                <div className="title">健康券剩余数量：</div>
                                <div className="neire">{DoctorBasic.ticketOwn ? DoctorBasic.ticketOwn : 0}</div>
                            </li>
                            <li>
                                <div className="title">电话券剩余：</div>
                                <div className="neire">{DoctorBasic.ticketOwnTel ? DoctorBasic.ticketOwnTel : 0}</div>
                            </li>
                            <li>
                                <div className="title">图文券剩余：</div>
                                <div className="neire">{DoctorBasic.ticketOwnNet ? DoctorBasic.ticketOwnNet : 0}</div>
                            </li>
                            <li>
                                <div className="title">通用券剩余：</div>
                                <div className="neire">{DoctorBasic.ticketCommon ? DoctorBasic.ticketCommon : 0}</div>
                            </li>
                            <li>
                                <a className="red" onClick={()=>this.showModalAdjust(DoctorBasic)}>【赠送】</a>
                            </li>
                        </ul>
                    </div>
                </Card> 

                {/* 赠送弹出 */}
                <Modal
                width={620}
                visible={this.state.adjustvisible}
                loading={this.state.loading}
                title="赠送健康券"
                onOk={this.adjustOk}
                onCancel={this.adjustCancel}
                okText="赠送"
                cancelText="取消"
                >
                    <FreeAdmission DoctorBasic={DoctorBasic}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
            </div>
        )
    }
}

export default DoctorBasic;