import React, { Component } from 'react'
import {Card,Modal,Table,Pagination,message,Button,Form,RangePicker} from 'antd'
import {getReportItemList,getEditReportItem,getRemoveReportItem,batchExpose} from './../../../../Api/index'
import utils from './../../../../Common/js/utils'
import EditCheckup from './../Common/EditCheckup'//编辑
import SearchForm from './../Common/Search'
import Expose from './../Common/Expose'
import moment from 'moment'
const { confirm } = Modal;
const { FormItem } = Form
class MedicalCheckup extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        editvisily:false, //编辑 false隐藏 true显示
        exposeShow:false,
        //removevisily:false,//删除 false隐藏 true显示
        ReportList:[],//列表数据
        pageNum:1,
        pageSize:10,
        formList:[{
            name: "time",
            type: 'rangePicker',
            placeholder: '时间范围'
        }],
    obj:{
        fn:()=>{},
        buttonName:"标题",
            initialFn:()=>{}
    }
    };
    componentWillMount() {
        this.RequestItemList();
        this.setState({
            obj:{
                fn:()=>this.handleOpen("exposeShow"),
                buttonName:"一键设置",
            }
        });
    }
    handleOpen = (value)=>{
        this.setState({
            [value]:true
        });
    }
    optionalFn=(data)=>{
        let param = data
        if(param.time){
            param.reportStartDate = moment(param.time[0]).format("YYYY-MM-DD")
            param.reportEndDate = moment(param.time[1]).format("YYYY-MM-DD")
            delete param.time
        }
        batchExpose({cid:this.props.record,...param}).then(res=>{
            if(res.status === 200 && res.data.state.value ===0){
                message.success("操作成功")
                this.setState({
                    exposeShow:false
                });
                this.formRef.reset(); 
            }else{
                message.warning("操作失败，请稍后再试")
            }

        })
    }
    //设置 弹出框 取消按钮 操作
    exposeCancel = () => {
        this.setState({ exposeShow: false });
        this.formRef.reset(); 
    };
    editSet = ()=>{
        this.formRef.submit(); 
    }
    
    //列表数据请求接口
    RequestItemList = (data) => {
        let _this = this
        getReportItemList({
            cid:this.state.cid,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            ...data
        }).then((res)=>{
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total:res.data.result.total,
                    ReportList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestItemList()
                    },(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestItemList()
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //////////////////////////////////////////////// 编辑
    //编辑 弹出内容 显示状态
    editshowModal = (record) => {
        this.setState({
            editvisily: true,
            record:record
        });
    };
    //编辑 弹出框 确定按钮 操作
    editOk = () => {
        let formdata = this.formRef.getItemsValue();
        if(
            formdata.reportFullname && 
            formdata.reportMobile && formdata.reportIdcard &&
            formdata.reportCompany && formdata.tiJianLb && formdata.ciKaLbbm && formdata.isExpose
        ){
            this.setState({ loading: false, editvisily: false });
            this.EditReportItemRequest(formdata);
            this.formRef.resetValue(); 
        }else{
            message.error("请完善必填项！")
            this.setState({ loading: true, editvisily: true });
        }
    };
    //编辑 弹出框 取消按钮 操作
    editCancel = () => {
        this.setState({ editvisily: false });
        this.formRef.resetValue(); 
    };
    //编辑 请求接口
    EditReportItemRequest = (data) => {
        getEditReportItem({
            id:this.state.record.id,
            reportNo:data.reportNo,
            reportFullname:data.reportFullname,
            reportMobile:data.reportMobile,
            reportIdcard:data.reportIdcard,
            reportCompany:data.reportCompany,
            tiJianLb:data.tiJianLb,
            isExpose:data.isExpose==1 ? true :false
        }).then((res)=>{
            if(res.status === 200){
                message.success(res.data.content);
                this.RequestItemList();
            }
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //////////////////////////////////////////////// 删除
    removeshowModal = (record) => {
        let _this=this;
        confirm({
            title: '删除',
            content: '是否确定要删除？',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk(){
                //请求删除接口
                getRemoveReportItem({id:record.id}).then((res)=>{
                    if(res.status === 200){
                        _this.RequestItemList();
                        message.success(res.data.content);
                    }
                }).catch((error)=>{
                    console.log(error);
                }) 
            },
            onCancel(){}
        });
    }
    
    render() {
        //表格 表头
        const columns = [
            {title: '序号',dataIndex: 'key',align:'center'},
            {title: '体检单',dataIndex: 'reportNo',align:'center'},
            {title: '姓名',dataIndex: 'reportFullname',align:'center'},
            {title: '手机号',dataIndex: 'reportMobile',align:'center'},
            {title: '身份证',dataIndex: 'reportIdcard',align:'center'},
            {title: '公司',dataIndex: 'reportCompany',align:'center'},
            {title: '体检类型',dataIndex: 'tiJianLb',align:'center'},
            {title: '查询密码',dataIndex: 'ciKaLbbm',align:'center'},
            {title: '体检时间',dataIndex: 'reportDate',align:'center'},
            {title: '创建时间',dataIndex: 'createDate',align:'center'},
            {title: '是否公开',dataIndex: 'isExpose',align:'center',
                render: (text,record) => {
                    return text?"是":"否"
                }
            },
            {title: '操作',dataIndex: 'operation',align:'center',
                render: (text,record) => {
                    return (
                        <div>
                            <a className="blue mlr5" onClick={() => this.editshowModal(record)}>编辑</a>
                            <a className="red mlr5" onClick={() => this.removeshowModal(record)}>删除</a>
                        </div>
                    )
                }
            }
        ];
        return (
            <div className="mb10">
                <Card 
                className="checkup"
                title="体检单">
                    <SearchForm className="search-box" optionalObj={this.state.obj} isCheck={true} formList={this.state.formList} formSearchApi={(data) => { if(data.time){data.reportStartDate = moment(data.time[0]).format('YYYY-MM-DD HH:MM:SS'); data.reportEndDate = moment(data.time[1]).format('YYYY-MM-DD HH:MM:SS');delete data.time;}this.RequestItemList(data)}} />
                     
                     <Table bordered columns={columns} dataSource={this.state.ReportList} pagination={false} />
                     <Pagination {...this.state.pagination} className="fr margin10" />
                </Card>

                {/* 编辑 弹出页面 */}
                <Modal
                width={620}
                visible={this.state.editvisily}
                loading={this.state.loading}
                title="编辑"
                onOk={this.editOk}
                onCancel={this.editCancel}
                okText="确定"
                cancelText="取消"
                >
                    <EditCheckup record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
                <Modal
                width={620}
                visible={this.state.exposeShow}
                loading={this.state.loading}
                title="一键设置"
                onOk={this.editSet}
                onCancel={this.exposeCancel}
                okText="确定"
                cancelText="取消"
                >
                    <Expose 
                    handelPost={(data)=>this.optionalFn(data)}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
            </div>
        )
    }
}

export default MedicalCheckup;