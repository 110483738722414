/**
 * @file 组件：选择代理区域
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { TreeSelect } from 'antd'
import { getAreaOptions } from '../../../Api/index'

/**
 * 组件：选择代理区域
 * @class
 * @extends React.Component
 */
class ProxyArea extends React.Component {
  /**
   * 组件状态值存储区域
   * @type {Function}
   */
  state = {
    treeData: [
    ],
    loadedNodes: []
  };

  /**
   * 组件载入时执行的函数
   * @type {Function}
   */
  componentDidMount() {
    this.onLoadData({ props: { id: "0" } })
  }

  /**
   * 组件prop和state变化时会执行的hook
   * @type {Function}
   * @param {Object} prevProps 组件变化之前的prop 
   */
  componentDidUpdate(prevProps) {
    const promises = []
    if (this.props.value && typeof (prevProps.value) == "undefined") {
      this.props.value.forEach(place => {
        let originalPlace = place.slice(0, 6)
        for (let i = 2; i <= 4; i += 2) {
          let currentPlace = originalPlace.slice(0, i) + '0'.repeat(12 - i)
          promises.push(this.onLoadData({ props: { id: currentPlace } }))
        }
      });
      Promise.all(promises).then(value => {})
    }
  }

  /**
   * 代理商区域读取数据
   * @type {Function}
   * @param {Object} treeNode 当前节点 
   */ 
  onLoadData = treeNode => new Promise(resolve => {
      const { id } = treeNode.props
      let result
      this.setState(state => {
        result = state.loadedNodes.indexOf(id) + 1
        if (result) {
          resolve(false)
        }
        return result ? {} : { loadedNodes: state.loadedNodes.concat(id) }
      })
      getAreaOptions({ parentId: id }).then(res => {
        result = res.data.result.map(item => ({
            id: item.id,
            value: item.id,
            key: item.id,
            pId: item.parentId,
            title: item.name,
            isLeaf: item.level == 3,
            selectable: item.level == 3
          }))
        result = this.state.treeData.concat(result)
        resolve(result)
      })
    }).then(result => {
      if (result) {
        this.setState({
          treeData: result
        })
      }
    })

  /**
   * 数值变化时进行操作 
   * @type {Function}
   * @param {String} value 当前值
   */ 
  handleChange = value => {
    const {onChange} = this.props
    if (onChange) {
        onChange(value)
    }
  }

  /**
   * 渲染最终值
   * @type {Function}
   * @return {HTMLElement}
   */
  render = () => (
      <TreeSelect
        treeDataSimpleMode
        value={this.props.value}
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="请选择"
        allowClear
        multiple
        loadData={this.onLoadData}
        treeData={this.state.treeData}
        onChange={this.handleChange}
      />
    )
}

export default ProxyArea