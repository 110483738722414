import React, { Component } from 'react'
import { Form, Radio, Input, Checkbox } from 'antd'

const { TextArea } = Input;

class closeOrder extends Component {
    getItemsValue = ()=>{ 
        const valus= this.props.form.getFieldsValue();
        return valus;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                <Form.Item><div className="warning">关闭后用户将无法继续咨询</div></Form.Item>
                <Form.Item>
                    {getFieldDecorator('isRefund')(
                        <Checkbox.Group style={{ width: '100%' }}>
                            <Checkbox value={true}>已退款</Checkbox>
                      </Checkbox.Group>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('remarks',{
                        rules: [{max:200,message: '内容不可多于200个字符',}]
                    })(
                        <TextArea
                        placeholder="关闭订单原因"
                        style={{ height: 75}}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const closeOrderForm = Form.create()(closeOrder);

export default closeOrderForm;