const menuList = [
    {
        title:'首页',
        key:'/home',
        icon:'home',
        children: [
            {
                title:'待认证用户',
                key:'/home/check',
                sideHide:true
            },
            {
                title:'意见处理',
                key:'/home/suggestion',
                sideHide:true
            },
            {
                title:'法规反馈',
                key:'/home/legal',
                sideHide:true
            },
            {
                title:'试用申请',
                key:'/home/application',
                sideHide:true
            },

        ]
    },
    {
        title:'活动设置',
        key:'/activity',
        icon:'flag',
        children:[
            {
                title:'管理公告',
                key:'/activity/management',
                icon:'',
                children: [
                    {
                        title: '发布',
                        key: '/activity/management/add',
                        icon: '',
                        sideHide: true
                    },
                    {
                        title: '编辑',
                        key: '/activity/management/edit',
                        icon: '',
                        sideHide: true
                    }
                ]
            }
        ]
    },
    {
        title:'用户管理',
        key:'/user',
        icon:'layout',
        children:[
            {
                title:'企业用户列表',
                key:'/user/unit',
                icon:'',
            },
            {
                title:'个人用户列表',
                key:'/user/all',
                icon:'',
                children:[
                    {
                        title:'平台用户',
                        key:'/user/all/list2',
                        icon:'',
                        children: [
                            {
                                title: '基本信息',
                                key: '/user/all/list2/basicp',
                                icon: '',
                                sideHide: true
                            },
                        ]
                    },
                    {
                        title:'健康顾问用户',
                        key:'/user/all/list3',
                        icon:'',
                        children: [
                            {
                                title: '基本信息',
                                key: '/user/all/list3/basicp',
                                icon: '',
                                sideHide: true
                            },
                            {
                                title: '健康顾问详情',
                                key: '/user/all/list3/basicj',
                                icon: '',
                                sideHide: true
                            },
                        ]
                    },
                    {
                        title:'医生用户',
                        key:'/user/all/list4',
                        icon:'',
                        children: [
                            {
                                title: '基本信息',
                                key: '/user/all/list4/basicp',
                                icon: '',
                                sideHide: true
                            },
                            {
                                title: '医生详情',
                                key: '/user/all/list4/basicy',
                                icon: '',
                                sideHide: true
                            },
                            {
                                title: '新增',
                                key: '/user/all/list4/addDoceditor',
                                icon: '',
                                sideHide: true
                            },
                            {
                                title: '编辑',
                                key: '/user/all/list4/editDoceditor',
                                icon: '',
                                sideHide: true
                            },
                        ]
                    }]
            },
        ]
    },
    {
        title:'订单管理',
        key:'/order',
        icon:'fund',
        children:[
            {
                title:'平台订单',
                key:'/order/list',
                icon:''
            },
            {
                title:'实施版订单',
                key:'/order/verorder',
                icon:''
            },
            {
                title:'健康顾问订单',
                key:'/order/health',
                icon:''
            }]
    },
    {
        title:'代理商',
        key:'/agent',
        icon:'fund',
        children:[
            {
                title:'总订单列表',
                key:'/agent/orderlist',
                icon:''
            },
            {
                title:'业务员管理',
                key:'/agent/salesmanlist',
                icon:''
            },
            {
                title:'代理商管理',
                key:'/agent/management',
                icon:'',
                children: [
                    {
                        title:'代理商新增',
                        key:'/agent/management/add',
                        sideHide:true
                    },
                    {
                        title:'代理商编辑',
                        key:'/agent/management/edit',
                        sideHide:true
                    },
                    {
                        title:'代理商详情',
                        key:'/agent/management/detail',
                        sideHide:true
                    }
                ]
            }]
    },
    {
        title:'数据维护',
        key:'/data',
        icon:'fund',
        children:[
            {
                title:'体检报告',
                key:'/data/list',
                icon:''
            },
            {
                title:'法规标准库',
                key:'/data/legalManagement',
                icon:''
            },
            {
                title:'健康小课堂',
                key:'/data/list2',
                icon:''
            },
            {
                title:'防护用品查询',
                key:'/data/list3',
                icon:''
            },
            {
                title:'警示标识管理',
                key:'/data/list4',
                icon:''
            },
            {
                title:'体检项目管理',
                key:'/data/list5',
                icon:''
            },
            {
                title:'危害因素限制查询',
                key:'/data/list6',
                icon:''
            },
            {
                title:'化学品类别查询',
                key:'/data/list7',
                icon:''
            },
            {
                title:'行政区划管理',
                icon:'',
                key:'/data/list8'
            },
            {
                title:'行业管理',
                icon:'',
                key:'/data/list9'
            },
        ]
    },
    {
        title:'在线培训',
        key:'/training',
        icon:'snippets',
        children:[
            {
                title:'文章',
                icon:'',
                key:'/training/article',
                children: [
                    {
                        title:'新增文章',
                        key:'/training/article/addNote',
                        sideHide:true
                    },
                    {
                        title:'编辑文章',
                        key:'/training/article/editNote',
                        sideHide:true
                    },
                    {
                        title:'查看文章',
                        key:'/training/article/lookNote',
                        sideHide:true
                    }
                ]
            },
            {
                title:'视频',
                icon:'',
                key:'/training/video',
                children: [
                    {
                        title:'新增视频',
                        key:'/training/video/add',
                        sideHide:true
                    },
                    {
                        title:'查看视频',
                        key:'/training/video/view',
                        sideHide:true
                    },
                    {
                        title:'编辑视频',
                        key:'/training/video/edit',
                        sideHide:true
                    }
                ]
            },
            {
                title:'题库管理',
                icon:'',
                key:'/training/questions'
            },
            {
                title:'分类答题',
                icon:'',
                key:'/training/category',
                children: [
                    {
                        title:'编辑每周答题',
                        key:'/training/category/edit',
                        sideHide:true
                    },
                    {
                        title:'编辑专项答题',
                        key:'/training/category/editTwo',
                        sideHide:true
                    },
                    {
                        title:'查看专项答题',
                        key:'/training/category/see',
                        sideHide:true
                    }
                ]
            },
            {
                title:'积分管理',
                icon:'',
                key:'/training/point'
            }
        ]
    },
    {
        title:'系统管理',
        key:'/system',
        icon:'snippets',
        children:[
            {
                title:'子管理员',
                icon:'',
                key:'/system/list'
            },
            {
                title:'权限管理',
                icon:'',
                key:'/system/list1',
                children: [
                    {
                        title:'权限编辑',
                        key:'/system/list1/edit',
                        sideHide:true
                    },
                ]
            },
            {
                title:'系统日志',
                icon:'',
                key:'/system/list2'
            }
        ]
    }
];
export default menuList;