/**
 * @description 子管理员
 * @author yzx yangzhixin177@163.com
 */
import React from 'react'
import {Card, Button, Form, Input,Divider, Select,Modal,Radio,Table,Icon,message,Pagination} from 'antd'
import {addAdmin,delAdmin,getAdminListData,getAdminModel,saveAdmin,savePassword,getRoleList} from '../../Api'
import utils from '../../Common/js/utils'
import moment from 'moment'
import md5 from 'md5'
const FormItem = Form.Item;
const Option = Select.Option;
/**
 * @description 组件-子管理员
 * @export
 * @class ManagerList
 * @extends {React.Component}
 */
export default class ManagerList extends React.Component{
    state = {
        params:{
            pageNum:1,
            pageSize:10
        },
        selectedIds:[0],
    }

    /**
     * @description 清空表格选中
     * @function resetCheckedList
     * @memberof ManagerList
     */
    resetCheckedList = ()=>{
        this.setState({
            selectedRowKeys:[],
            selectedRows:null,
            selectedIds:[],
            selectItem:{},
        })
    }
    /**
     * @description 点击行事件
     * @function onRowClick
     * @param {Object} record 当前行对象 
     * @param {Number} index 当前行下标
     * @memberof ManagerList
     */
    onRowClick = (record,index)=>{
        let selectKey = [index];
        this.setState({
            selectedRowKeys:selectKey,
            selectedItem: record
        })
    } 
    /**
     * @description 请求子管理员列表
     * @function requestList
     * @memberof ManagerList
     */
    requestList = ()=>{
        let _this = this
        getAdminListData({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize }).then((res)=>{
            // console.log(res,"list");
            if(res.status === 200){
                // console.log(res.data.result.rows)
                // console.log(res.data.result.total)
                utils.tableListKey(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        // console.log(size,"size")
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList()
                    }, (current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList()
                    }, res.data.result.pageSize)
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    /**
     * @description react生命周期，在渲染前调用
     * @function componentWillMount
     * @memberof ManagerList
     */
    componentWillMount =()=>{
        this.requestList()
    }
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof ManagerList
     */
    renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({ 
            title:title?title:'警告',
            content:content?content:''
        })
    }
    /**
     * @description 删除用户
     * @function handleDelete
     * @param {Object} record 当前行对象 
     * @memberof ManagerList
     */
    handleDelete=(record)=>{
        // console.log(record.id)
        Modal.confirm({
            title:'删除提示',
            content: `您确定要删除此用户吗？`,
            onOk:()=>{
                delAdmin({adminId:record.id}).then((res)=>{
                    if(res.data.state.value==0){
                        this.setState({
                            isVisible:false
                        })
                        message.success('删除成功');
                        this.requestList();
                    }else{
                        this.renderModalSimple('warning','错误','删除失败，请重新删除!')
                    }
                })
            }
        })
    }
    /**
     * @description 点击-弹出修改密码框
     * @function handlePassword
     * @param {Object} record 当前行对象 
     * @memberof ManagerList
     */
    handlePassword=(record)=>{
        this.setState({
            isPsdVisible:true,
            record
        })
        // console.log(type) 
    }

    /**
     * @description 点击“提交”事件-密码修改提交
     * @function handlePsdSubmit
     * @memberof ManagerList
     */
    handlePsdSubmit = ()=>{
        let data = this.PsdForm.props.form.getFieldsValue();
        let record=this.state.record
        let md5_password = md5(data.password);
        this.PsdForm.props.form.validateFields((err)=>{
            if(!err){
        savePassword({adminId:record.id,password:md5_password}).then((res)=>{
            if(res.data.state.value==0){
                this.setState({
                    isPsdVisible:false
                })
                message.success('修改密码成功')
                this.requestList()
                this.PsdForm.resetValue()
            }else{
                this.renderModalSimple('warning','错误','修改密码失败')
            }
        })
    }
    }).catch((e) => {})
    }
    /**
     * @description 点击-弹出编辑弹框
     * @function handleEdit
     * @param {string} type 类型
     * @param {Object} record 当前行对象
     * @memberof ManagerList
     */
    handleEdit=(record)=>{
        this.setState({
            title:'编辑',
            isRoleEditVisible:true,
            roleInfo:record,
            dataScope:record.dataScope
        })
        // console.log(record.id)
        // console.log(type) 
    }

    /**
     * @description 点击-弹出新增角色弹框
     * @function handleRole
     * @param {string} type 类型
     * @memberof ManagerList
     */
    handleRole = ()=>{
        this.setState({
            title:"新增",
            isRoleVisible:true,
        })
    }
     /**
     * @description 角色新增弹框-点确定后事件处理
     * @function handleRoleSubmit
     * @memberof ManagerList
     */
    handleRoleSubmit = ()=>{
        let data = this.RoleForm.props.form.getFieldsValue();
        // console.log(data)
        let md5_password = md5(data.password);
        let sex=(data.sex==1?'男':'女')
        this.RoleForm.props.form.validateFields((err)=>{
            if(!err){
            addAdmin({username:data.username,password:md5_password,dataScope:data.dataScope,nickName:data.nickName,sex:sex,mobile:data.mobile,email:data.email}).then((res)=>{
                if(res.data.state.value==0){
                    this.setState({
                        isRoleVisible:false
                    })
                    message.success('新增成功')
                    this.requestList()
                    this.RoleForm.resetValue()
                }else{
                    this.renderModalSimple('warning','错误','新增失败') 
                }
            })
        }
        }).catch((e) => {})
    }
     /**
     * @description 角色编辑弹框-点确定后事件处理
     * @function handleRoleEditSubmit
     * @memberof ManagerList
     */
    handleRoleEditSubmit = ()=>{
        let data1 = this.RoleEditForm.props.form.getFieldsValue();
        let dataScope =0
        
        if(isNaN(data1.dataScope)==true){//表示dataScope是字符
            dataScope=this.state.dataScope
        }
        else if(isNaN(data1.dataScope)==false){
           dataScope=data1.dataScope
        }
        let roleInfo=this.state.roleInfo;
        this.RoleEditForm.props.form.validateFields((err)=>{
            if(!err){
            saveAdmin({id:roleInfo.id,username:data1.username,dataScope:dataScope,nickName:data1.nickName,sex:data1.sex,mobile:data1.mobile,email:data1.email}).then((res)=>{
                if(res.data.state.value==0){
                    this.setState({
                        isRoleEditVisible:false
                    })
                    message.success('编辑成功')
                    this.requestList()
                    this.RoleEditForm.resetValue()
                }else{
                    this.renderModalSimple('warning','错误','编辑失败') 
                }
            })
        }
        }).catch((e) => {})
    }
    render(){
        const columns = [
            {
                title: '',
                key:'key',
                dataIndex: 'key'
            }, {
                title: '账号',
                key: 'username',
                dataIndex: 'username'
            },{
                title: '角色',
                key: 'roleName',
                dataIndex: 'roleName',
            },{
                title: '姓名',
                key: 'nickName',
                dataIndex: 'nickName',
            },{
                title: '注册时间',
                key: 'createDate',
                dataIndex: 'createDate',
                render(text) {
                    if(text){
                        return <span title={moment(text).format('YYYY-MM-DD')}>{moment(text).format('YYYY-MM-DD')}</span>
                    }else{
                        return '-'
                    }
                },
            }
            ,{
                title: '操作',
                dataIndex: 'opera',
                    render: (type,record) => {
                        if (record.roleName=='超级管理员账号') {
                            return<div>
                            <a title="编辑" onClick={()=>this.handleEdit(record)}> <Icon type="edit"style={{paddingLeft:7,fontSize:20,color:"#52c41a"}}/></a>
                            <a title="修改密码" onClick={()=>this.handlePassword(record)}><Icon type="reload"style={{paddingLeft:7,fontSize:20}}/></a></div>
                        }
                        return<div>
                        <a title="编辑" onClick={()=>this.handleEdit(record)}> <Icon type="edit"style={{paddingLeft:7,fontSize:20,color:"#52c41a"}}/></a>
                        <a title="修改密码" onClick={()=>this.handlePassword(record)}><Icon type="reload"style={{paddingLeft:7,fontSize:20}}/></a>
                        <a title="删除" onClick={()=>this.handleDelete(record)}><Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{paddingLeft:7,fontSize:20}}/></a></div>
                        
                    }  
                }
        ]; 
        return (
            <div>
                <Table
                        bordered
                        columns={columns}
                        dataSource={this.state.dataSource}
                        onRow={(record,index) => {
                            return {
                                onClick:()=>{
                                    this.onRowClick(record,index);
                                }
                            };
                        }}
                        rowKey={(record,index)=> index}
                        pagination={this.state.pagination}
                        footer={()=>{
                            return <a title="新增" onClick={()=>this.handleRole()}><Icon type="plus-circle" theme={"filled"} style={{fontSize:25}}/></a>
                        }}
                    /> 

                <Modal
                    title={this.state.title}
                    visible={this.state.isRoleVisible}
                    onOk={()=>{this.handleRoleSubmit()}}
                    onCancel={()=>{
                        this.RoleForm.props.form.resetFields();
                        this.setState({
                            isRoleVisible:false,
                            roleInfo:''
                        })
                    }}
                >
                    <RoleForm roleInfo={this.state.roleInfo} type={this.state.type} wrappedComponentRef={(inst) => this.RoleForm = inst }/>
                </Modal>
                <Modal
                    title={this.state.title}
                    visible={this.state.isRoleEditVisible}
                    onOk={()=>{this.handleRoleEditSubmit()}}
                    onCancel={()=>{
                        this.RoleEditForm.props.form.resetFields();
                        this.setState({
                            isRoleEditVisible:false,
                            roleInfo:''
                        })
                    }}
                >
                    <RoleEditForm roleInfo={this.state.roleInfo} type={this.state.type}  wrappedComponentRef={(inst) => this.RoleEditForm = inst }/>
                </Modal>
                <Modal
                    title='修改密码'
                    visible={this.state.isPsdVisible}
                    onOk={()=>{this.handlePsdSubmit()}}
                    onCancel={()=>{
                        this.PsdForm.props.form.resetFields();
                        this.setState({
                            isPsdVisible:false,
                            roleInfo:''
                        })
                    }}
                >
                    <PsdForm wrappedComponentRef={(inst) => this.PsdForm = inst }/>
                </Modal>
            </div>
           
        );
    }
}
/**
 * @description 组件-角色新增弹框
 * @class RoleForm
 * @extends {React.Component}
 */
class RoleForm extends React.Component{
    //重置
    state={
        items:[]
    }
    resetValue = ()=>{
        this.props.form.resetFields()
    }
    //确认密码校验一致
    handleCfmPwd =(rules,value,callback)=>{
        let loginpass = this.props.form.getFieldValue('password');
        if(loginpass && loginpass !== value){
            callback(new Error('两次密码输入不一致'))
        }else{
            // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
            callback();
        }
    }
    addItem = () => {
        getRoleList().then((res)=>{
            if(res.data.state.value == 0){
                var roleData=res.data.result
                roleData.splice(0,1)
                this.setState({
                    items:roleData
                })
      };
    })
}
    render(){
        const { items } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        return (
            <Form layout="horizontal">
                <FormItem label="账号" {...formItemLayout}>
                    {
                        getFieldDecorator('username',{
                            initialValue:'',rules: [{ required: true, message: '请输入账号'},]
                        })(
                            <Input type="text"/>
                        )
                    }
                </FormItem>
                <FormItem label="密码" {...formItemLayout}>
                    {
                        getFieldDecorator('password',{
                            initialValue:'',rules: [{ required: true, message: '请输入密码'},{pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: '必须6位以上字母和数字组合' }]
                        })(
                            <Input type="password" maxLength={20}/>
                        )
                        }
                </FormItem>
                <FormItem label="确认密码" {...formItemLayout}>
                    {
                        getFieldDecorator('repassword',{
                            initialValue:'', rules: [{ required: true, message: '请输入密码' },{
                                    validator:(rules,value,callback)=>
                                        {this.handleCfmPwd(rules,value,callback)}
                                }],
                                validateFirst:true
                        })(
                            <Input type="password" maxLength={20}/>
                        )
                        }
                </FormItem>
                <FormItem label="请选择角色" {...formItemLayout}>
                {getFieldDecorator('dataScope',{rules: [{ required: true, message: '请选择角色'},]})(
                <Select placeholder="请选择" onFocus={this.addItem}
                  >
                    
                    {items.map(item => (
                    <Option key={item.id} value={item.id}>
                        {item.roleName}
                    </Option>
        ))}
                </Select>)
                }
                </FormItem>
                <FormItem label="姓名" {...formItemLayout}>
                    {
                        getFieldDecorator('nickName',{
                            initialValue:'',rules: [{ required: true, message: '请输入姓名'},]
                        })(
                            <Input type="text"/>
                        )
                        }
                </FormItem>
                <FormItem label="性别" {...formItemLayout}>
                    {
                        getFieldDecorator('sex',{
                            initialValue:1
                        })(
                            <Radio.Group>
                            <Radio value={'男'}>男</Radio>
                            <Radio value={'女'}>女</Radio>
                            </Radio.Group>
                        )
                        }
                </FormItem>
                <FormItem label="联系方式" {...formItemLayout}>
                    {
                        getFieldDecorator('mobile',{
                            initialValue:''
                        })(
                            <Input type="text"/>
                        )
                        }
                </FormItem>
                <FormItem label="邮箱" {...formItemLayout}>
                    {
                        getFieldDecorator('email',{
                            initialValue:''
                        })(
                            <Input type="text"/>
                        )
                        }
                </FormItem>
            </Form>
        );
    }
}
RoleForm = Form.create({})(RoleForm);

/**
 * @description 组件-角色编辑弹框
 * @class RoleEditForm
 * @extends {React.Component}
 */
class RoleEditForm extends React.Component{
    //重置
    state={
        itemsTwo:[]
    }
   
    resetValue = ()=>{
        this.props.form.resetFields()
    }

    addItem = () => {
        getRoleList().then((res)=>{
            if(res.data.state.value == 0){
                var roleData=res.data.result
                roleData.splice(0,1)
                this.setState({
                    itemsTwo:roleData
                })
      };
    })
}
    render(){
        const { itemsTwo } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        const roleInfo = this.props.roleInfo || {};
        return (
            <Form layout="horizontal">
                <FormItem label="账号" {...formItemLayout}>
                    {
                        getFieldDecorator('username',{
                            initialValue:roleInfo.username,rules: [{ required: true, message: '请输入账号'},]
                        })(
                            <Input type="text"/>
                        )
                    }
                </FormItem>
                <FormItem label="请选择角色" {...formItemLayout}>
                {getFieldDecorator('dataScope',{initialValue:roleInfo.roleName,rules: [{ required: true, message: '请选择角色'},]})(
                <Select placeholder="请选择" onFocus={this.addItem}
                  >
                    
                    {itemsTwo.map(item => (
                    <Option key={item.id} value={item.id}>
                        {item.roleName}
                    </Option>
        ))}
                </Select>)
                }
                </FormItem>
                <FormItem label="姓名" {...formItemLayout}>
                    {
                        getFieldDecorator('nickName',{
                            initialValue:roleInfo.nickName,rules: [{ required: true, message: '请输入姓名'},]
                        })(
                            <Input type="text"/>
                        )
                        }
                </FormItem>
                <FormItem label="性别" {...formItemLayout}>
                    {
                        getFieldDecorator('sex',{
                            initialValue:roleInfo.sex=='男'?1:2
                        })(
                            <Radio.Group>
                            <Radio value={1}>男</Radio>
                            <Radio value={2}>女</Radio>
                            </Radio.Group>
                        )
                        }
                </FormItem>
                <FormItem label="联系方式" {...formItemLayout}>
                    {
                        getFieldDecorator('mobile',{
                            initialValue:roleInfo.mobile
                        })(
                            <Input type="text"/>
                        )
                        }
                </FormItem>
                <FormItem label="邮箱" {...formItemLayout}>
                    {
                        getFieldDecorator('email',{
                            initialValue:roleInfo.email
                        })(
                            <Input type="text"/>
                        )
                        }
                </FormItem>
            </Form>
        );
    }
}
RoleEditForm = Form.create({})(RoleEditForm);

/**
 * @description 组件-密码修改弹框
 * @class PsdForm
 * @extends {React.Component}
 */
class PsdForm extends React.Component{
    //重置
    resetValue = ()=>{
        this.props.form.resetFields()
    }
    //确认密码校验一致
    handleCfmPwd =(rules,value,callback)=>{
        let loginpass = this.props.form.getFieldValue('password');
        if(loginpass && loginpass !== value){
            callback(new Error('两次密码输入不一致'))
        }else{
            // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
            callback();
        }
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        // const PsdInfo = this.props.PsdInfo || {};
        return (
            <Form layout="horizontal">
                <FormItem label="密码" {...formItemLayout}>
                    {
                        getFieldDecorator('password',{
                            initialValue:'',rules: [{ required: true, message: '请输入密码'},{pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: '必须6位以上字母和数字组合' }]
                        })(
                            <Input type="password" maxLength={20}/>
                        )
                        }
                </FormItem>
                <FormItem label="确认密码" {...formItemLayout}>
                    {
                        getFieldDecorator('repassword',{
                            initialValue:'', rules: [{ required: true, message: '请再次输入密码' },{
                                    validator:(rules,value,callback)=>
                                        {this.handleCfmPwd(rules,value,callback)}
                                }],
                                validateFirst:true
                        })(
                            <Input type="password" maxLength={20}/>
                        )
                        }
                </FormItem>
            </Form>
        );
    }
}
PsdForm = Form.create({})(PsdForm);