/**
 * @file 组件：销售员选择
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { TreeSelect } from 'antd'
import { getAgentBusinessList } from '../../../Api'

/**
 * 组件：销售员选择
 * @class
 * @extends React.Component
 */
class SalesmanSelection extends React.Component {
  /**
   * 组件状态
   * @type {Object}
   */
  state = {
    treeData: [
    ],
    loadedNodes: []
  }

  /**
   * 组件载入函数
   * @type {Function}
   */
  componentDidMount() {
    this.onLoadData({ props: { eventKey: 'tree-0' } })
  }
  
  /**
   * 销售员读取数据 
   * @type {Function}
   * @param {Object} treeNode 节点
   */ 
  onLoadData = treeNode => new Promise(resolve => {
      const { eventKey } = treeNode.props;
      if (!(this.state.loadedNodes.indexOf(eventKey) + 1)) {
        this.setState(state => {
          return { loadedNodes: state.loadedNodes.concat(eventKey) }
        })
        getAgentBusinessList({ pid: eventKey.split('-').slice(-1)[0] }).then(res => {
          let result = this.state.treeData.concat(res.data.result.map(item => {
            return {
              id: `${eventKey}-${item.id}`,
              value: `${eventKey}-${item.id}`,
              key: `${eventKey}-${item.id}`,
              pId: eventKey,
              title: item.name,
              isLeaf: item.type == 2
            }
          }))
          resolve(result)
        })
      } else {
        resolve(false)
      }
    }).then(result => {
      if (result) {
        this.setState({ treeData: result })
      }
    })

  /**
   * 数值变化时进行操作 
   * @type {Function}
   * @param {String} value 当前值
   */ 
  handleChange = value => {
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  /**
   * 渲染最终值
   * @type {Function}
   * @return {HTMLElement}
   */
  render = () => (
      <TreeSelect
        // showSearch
        treeDataSimpleMode
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder={this.props.placeholder || "请选择"}
        value={this.props.value}
        allowClear
        // multiple
        // treeDefaultExpandedKeys={["tree-0"]}
        // treeDefaultExpandAll
        loadData={this.onLoadData}
        treeData={this.state.treeData}
        onChange={this.handleChange}
      />
    )

}

export default SalesmanSelection