/*
 * @file: 文章列表信息的组件
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import utils from '../../../Common/js/utils'    //表格分页等     
import { Icon, Form, Input, Button, Table, Pagination, Modal, message, Cascader } from 'antd'
import { getLegalTypeInit, getLegalType, artivleGetList, artivleDel, artivleGetLawListByModel, artivleAddLaw, artivleEdit, getUrl } from '../../../Api'
/**
 * @classdesc 文章列表信息的父组件
 */
class Article extends Component {
    /**
     * @description: state自定义
     * @param {object} columns 表格配置
     * @param {object} params 表格分页
     * @param {Array} loading 等待标志
     */
    state = {
        params: {
            p: 1,
            pageSize: 30
        },
        columns: [
            {
                title: "序号", dataIndex: 'key', align: 'center', width: 40, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "文章名称", dataIndex: 'name', align: 'center', width: 120, render: (text, record) => {
                    return <a title={text} onClick={() => this.lookNote(record)} >{text}</a>
                },
            },
            {
                title: "类别", dataIndex: 'type', align: 'center', width: 80, render(text) {
                    switch (text) {
                        case 1:
                            text = '职业卫生综合';
                            break;
                        case 2:
                            text = '职业危害防护';
                            break;
                        case 3:
                            text = '职业健康监护';
                            break;
                        case 4:
                            text = '职业危害因素';
                            break;
                        case 5:
                            text = '职业卫生三同时';
                            break;
                        case 6:
                            text = '职业病';
                            break;
                        default:
                            text = '放射卫生';
                            break;
                    }
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "上架时间", dataIndex: 'shelvesTime', align: 'center', width: 120, render(text) {
                    return <span title={text}>{text}</span>
                },
            },

            {
                title: "阅读数", dataIndex: 'hot', align: 'center', width: 40, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "收藏数", dataIndex: 'collect', align: 'center', width: 40, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "状态", dataIndex: 'status', align: 'center', width: 40, render(text) {
                    text = text == '1' ? '未上架' : text == '2' ? '已上架' : '已下架'
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "操作", dataIndex: 'operation', align: 'center', width: 50, render: (text, record) => {
                    if (record.status == '1') {
                        return (
                            <div>
                                <a title="编辑" onClick={() => this.addNote(record)}>
                                    <Icon type="edit" style={{ paddingLeft: 7, fontSize: 20 }} />
                                </a>
                                <a title="删除" onClick={() => this.del(record)}>
                                    <Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{ paddingLeft: 7, fontSize: 20 }} />
                                </a>
                            </div>
                        )
                    } else if (record.status == '2') {
                        return (
                            <div>
                                <a title="下架" onClick={() => this.handOnline(record, false)}>
                                    <span className='fa fa-angle-double-down' style={{ paddingLeft: 7, fontSize: 20, color: "#52c41a" }}></span>
                                </a>
                                <a title="编辑" onClick={() => this.addNote(record)}>
                                    <Icon type="edit" style={{ paddingLeft: 7, fontSize: 20 }} />
                                </a>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <a title="上架" onClick={() => this.handOnline(record, true)}>
                                    <span className='fa fa-angle-double-up' style={{ paddingLeft: 7, fontSize: 20, color: "#52c41a" }}></span>
                                </a>
                            </div>
                        )
                    }

                },
            },
        ],
        loading: false,
    }
    componentWillMount() {
        this.requestTableList()
    }
    /**
     * @description: 请求表格数据函数
     * @function requestTableList
     */
    requestTableList = () => {
        let params = this.state.params
        let _this = this
        artivleGetList(params).then(res => {
            if (res.data.state.name === '操作成功' && res.data.state.value === 0) {
                for (var key of res.data.result.rows) {
                    if (key.shelvesTime) {
                        key.shelvesTime = _this.formatDate(key.shelvesTime)
                    } else {
                        key.shelvesTime = null
                    }
                }
                utils.tableListKey(res.data.result.rows, _this.state.params.p, _this.state.params.pageSize)
                this.setState({
                    total: res.data.result.rows.length,
                    bookListData: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.params.p = current
                        _this.state.params.pageSize = size
                        this.requestTableList()
                    }, (current, size) => {
                        _this.state.params.p = current
                        _this.state.params.pageSize = size
                        this.requestTableList()
                    }, res.data.result.pageSize)
                })
            }
        })
    }
    /**
     * 时间戳转换
     * @param {Date} now 时间戳
     * @return {Date} 显示格式
     * @function formatDate
     */
    formatDate = (now) => {
        var nowDate = new Date(now)
        var year = nowDate.getFullYear();  //取得4位数的年份
        var month = nowDate.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
        var date = nowDate.getDate();      //返回日期月份中的天数（1到31）
        return year + "-" + month + "-" + date;
    }
    /** 
     * @name: 上/下架操作 
     * @param {object} record行信息
     * @param {boolean} bol是否上架  true上架 false下架
     */
    handOnline = (record, bol) => {
        let text = '确认要上架吗？'
        let _this = this
        let obj = { id: record.id }
        if (bol) {
            obj.status = '2'
            obj.shelvesTime = new Date().getTime();
        } else {
            text = '确认要下架吗？'
            obj.status = '3'
        }
        Modal.confirm({
            title: '提示',
            content: text,
            width: 300,
            onOk() {
                artivleEdit(obj).then(res => {
                    if (res.data.state.value === 0) {
                        message.success('操作成功')
                        _this.requestTableList()
                    }


                })
            },

        });

    }
    /**
     * @description:  新增、编辑文章 跳转
     * @param {object} record 行信息 
     * @function addNote
     */
    addNote = (record) => {
        this.props.history.push({ pathname: `/training/article/${record === 'add' ? 'addNote' : 'editNote'}`, state: { data: record } })
    }
    /**
     * @description: 查看文章 跳转
     * @param {object} record 行信息 
     * @function lookNote
     */
    lookNote = (record) => {
        this.props.history.push({ pathname: `/training/article/lookNote`, state: { data: record } })
    }
    /**
     * @description: 删除文章
     * @param {object} record 行信息
     * @function del
     */
    del = (record) => {
        let _this = this
        Modal.confirm({
            title: '提示',
            content: '确认要删除吗？',
            okType: 'danger',
            onOk() {
                artivleDel(record.id).then(res => {
                    if (res.data.state) {
                        if (res.data.state.value === 0) {
                            message.success('操作成功')
                            _this.requestTableList()
                        } else {
                            message.warning('请稍后再试')
                        }

                    } else {
                        Modal.warning({
                            title: '提示',
                            content: '请稍后重试',
                        });
                    }
                })
            },

        });
    }
    /**
     * @description: 搜索文章
     * @param {number} zero 是否重置  0重置
     * @function handleSubmit
     */
    handleSubmit = (zero) => {
        this.props.form.validateFieldsAndScroll((err, value) => {
            if (zero === 0) {
                value.name = ''
                this.props.form.resetFields()
            }
            this.setState({
                params: {
                    p: 1,
                    pageSize: 30,
                    ...value
                }
            }, () => { this.requestTableList() })
        });
    }
    /**
     * @description: 选择法规标准
     * @param {boolean} bol true显示 false隐藏 
     * @function regulationsBol
     */
    regulationsBol = (bol) => {
        if (bol) {
            this.setState({
                regulations: true
            })
        } else {
            this.setState({
                regulations: false
            })
            this.formRef.resetValue()
        }
    }
    /**
     * @description: 通过法规标准添加
     * @param {object} item 法规组件传来的法规数据 
     * @function addRegulation
     */
    addRegulation = (item) => {
        this.setState({
            selectedRows: item
        })
    }
    /**
     * @description: 法规添加确定
     * @function regulationsOK
     */
    regulationsOK = () => {
        let _this = this
        let data = this.state.selectedRows ? this.state.selectedRows : []
        if (data.length > 0) {
            let obj = []
            for (var i = 0; i < data.length; i++) {
                obj.push({
                    name: data[i].lawName,
                    type: '1',
                    lawId: data[i].lawId,
                    content: data[i].ossFullUrl,
                    shelvesTime: new Date()
                })
            }
            artivleAddLaw(obj).then(res => {
                if (res.data.state && res.data.state.value === 0) {
                    this.setState({
                        regulations: false
                    })
                    if (res.data.result) {
                        message.warning(res.data.content)

                        var obj = ''
                        for (var key of res.data.result) {
                            obj += `<p><span style="color:black">${key.name} ${key.lawNo} </span>  在列表中已存在！</p>`
                        }
                        Modal.warning({
                            title: '提示',
                            content: (
                                <div dangerouslySetInnerHTML={{ __html: obj }}></div>
                            ),
                            width: 500
                        });
                    } else {
                        message.success(res.data.content)
                    }
                    _this.requestTableList()
                }
            })
        } else {
            this.setState({
                regulations: false
            })
        }
        this.formRef.resetValue()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        // console.log(this)
        const columns = this.state.columns
        return (
            <div className="home">
                {/* 顶部条件搜索 */}
                <Form layout="inline">
                    <Form.Item>
                        {getFieldDecorator('name')(
                            <Input
                                style={{ width: 240 }}
                                placeholder="文章名称"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSubmit}>
                            搜 索
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => this.handleSubmit(0)}>
                            重 置
                        </Button>
                    </Form.Item>
                    <Form.Item className='fr'>
                        <Button type="primary" onClick={() => this.addNote('add')}>
                            新 增
                        </Button>
                    </Form.Item>
                    <Form.Item className='fr'>
                        <Button type="primary" onClick={() => this.regulationsBol(true)}>
                            选择法规标准
                        </Button>
                    </Form.Item>

                </Form>
                <Table className="mt20"
                    bordered
                    dataSource={this.state.bookListData}
                    columns={columns}
                    pagination={false}
                />
                <Pagination {...this.state.pagination}
                    pageSize={this.state.params.pageSize}
                    className="fr mt20"
                />
                <Modal
                    visible={this.state.regulations}
                    loading={this.state.loading}
                    title="选择法规标准"
                    onOk={this.regulationsOK}
                    onCancel={() => this.regulationsBol(false)}
                    okText="确定"
                    cancelText="取消"
                    centered
                    width={1000}
                >
                    <Regulations parentDelete={this.addRegulation.bind(this)} wrappedComponentRef={(form) => this.formRef = form}></Regulations>
                </Modal>
            </div>
        )
    }
}
Article = Form.create()(Article);
export default Article;
/**
 * @classdesc:法规选择弹框模块
 */
class Regulations extends React.Component {
    constructor(props) {
        super(props)
        /**
         * @description: state自定义
         * @param {object} columns 表格配置
         * @param {object} params 表格分页
         * @param {Array} regdata 请求的数据
         * @param {Array} legalType 法律效力
         * @param {Array} selectedRows 选中的值
         */
        this.state = {
            columns: [
                {
                    title: "序号", dataIndex: 'key', align: 'center', width: 50, render(text) {
                        return <span title={text}>{text}</span>
                    }
                },
                {
                    title: "名称", dataIndex: "lawName", align: 'center', width: 150, render: (text, record) => {
                        return <span title={text}>{text}</span>
                    },
                },
                {
                    title: "文号", dataIndex: "lawNo", align: 'center', width: 150, render(text) {
                        return <div title={text} style={{
                            wordWrap: 'break-word',
                            wordBreak: 'break-all'
                        }}>{text}</div>
                    },
                },
                {
                    title: "状态", dataIndex: "lawStatus", align: 'center', render(text) {
                        switch (text) {
                            case 1:
                                text = '现行';
                                break;
                            case 2:
                                text = '作废';
                                break;
                            case 3:
                                text = '即将实施';
                                break;
                            case 4:
                                text = '废止';
                                break;
                            default:
                                break;
                        }
                        return <span title={text}>{text}</span>
                    },
                    width: 40,
                },
                {
                    title: "法律效力", dataIndex: "lawClassName", align: 'center', width: 40, render(text) {
                        return <span title={text}>{text}</span>
                    },
                }
            ],
            params: {
                pageNum: 1,
                pageSize: 10,
                isContainsValid: 2,
                isClassifyOneLevel: 0

            },
            regdata: [],
            legalType: [],
            selectedRows: [],
        }
    }
    componentDidMount() {
        this.tableData()
        this.requestLegalInit()
    }
    /**
     * @description: 重置法规数据
     * @function resetCheckedList
     */
    resetCheckedList = () => {
        this.setState({
            selectedRowKeys: [],
            selectedRows: null,
            selectedIds: [],
            selectItem: {},
        })
        this.updata()
    }
    /**
     * @description: 法规数据获取
     * @function tableData
     */
    tableData = () => {
        let _this = this
        artivleGetLawListByModel(this.state.params).then(res => {
            if (res.status === 200) {
                utils.tableListKey(res.data.result.rows, _this.state.params.pageNum, _this.state.params.pageSize)
                this.resetCheckedList()
                this.setState({
                    total: res.data.result.rows.length,
                    regdata: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.params.pageNum = current
                        _this.state.params.pageSize = size
                        this.tableData()
                    }, (current, size) => {
                        _this.state.params.pageNum = current
                        _this.state.params.pageSize = size
                        this.tableData()
                    }, res.data.result.pageSize)
                })
            }
        })
    }
    /**
     * @description: 获取法律效力一级
     * @function requestLegalInit
     */
    requestLegalInit = () => {
        let arr = []
        getLegalTypeInit().then(res => {
            if (res.status === 200 && res.data.state.value === 0) {
                res.data.result.legClassifyModels.map((item) => {
                    if (item.name == "解读") {
                        arr.push({
                            value: item.id,
                            label: item.name,
                            pid: item.pid,
                            key: item.id,
                            isLeaf: true,
                            type: res.data.result.type
                        })
                    } else {
                        arr.push({
                            value: item.id,
                            label: item.name,
                            pid: item.pid,
                            key: item.id,
                            isLeaf: false,
                            type: res.data.result.type
                        })
                    }
                })
                this.setState({
                    legalType: arr
                })
            }
        })
    }
    /**
     * 获取法律效力二级
     * @param {object} selected 法律效力一级选中数据
     * @function requestLegalType
     */
    requestLegalType = (selected) => {
        let arr = []
        getLegalType({ id: selected[0].value }).then(res => {
            if (res.status === 200 && res.data.state.value === 0) {
                res.data.result.list.map((item) => {
                    arr.push({
                        value: item.id,
                        label: item.name,
                        pid: item.pid,
                        key: item.id,
                        isLeaf: true
                    })
                })
                let arr2 = this.state.legalType
                arr2.map(item => {
                    if (item.value === arr[0].pid) {
                        item.children = arr
                        return
                    }
                })
                //必须要用this.setState异步赋值
                this.setState({
                    legalType: arr2
                })
            }
        })
    }
    /**
     * 搜索
     * @param {number} zero 0重置
     * @function handleSubmit
     */
    handleSubmit = (zero) => {
        let value = this.props.form.getFieldsValue()
        value.lawClassId = value.lawClassId ? value.lawClassId[value.lawClassId.length - 1] : null
        if (zero === 0) {
            value.lawClassId = null
            value.nameNo = null
            this.props.form.resetFields()
        }
        this.setState({
            params: {
                pageNum: 1,
                pageSize: 10,
                isContainsValid: 2,
                isClassifyOneLevel: 0,
                ...value
            }
        },
            () => { this.tableData() }
        )


    }
    /**
     * 返回父组件数据
     * @functionupdata
     */
    updata = () => {
        this.props.parentDelete(this.state.selectedRows)
    }
    /**
     * 清除法规选中数据
     * @functionresetValue
     */
    resetValue = () => {
        this.props.form.resetFields()
        this.setState({
            selectedRowKeys: [],
            selectedRows: null,
            selectedIds: [],
            selectItem: {},
        })
    }
    /**
     * 选中数据后先父组件传达数据 
     * @functionrowCheckSelection
     */
    rowCheckSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                let ids = []
                selectedRows.map((item) => {
                    ids.push(item.id)
                })
                this.setState({ selectedRowKeys, selectedIds: ids, selectedRows },
                    () => { this.updata() }
                )
            }
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div>
                <Form layout="inline">
                    <Form.Item label="法律效力">
                        {getFieldDecorator('lawClassId', {})(
                            <Cascader
                                placeholder="请选择法律效力"
                                options={this.state.legalType}
                                changeOnSelect
                                loadData={this.requestLegalType}
                            />
                        )}
                    </Form.Item>
                    <Form.Item >
                        {/* 模糊匹配的select */}
                        {getFieldDecorator('nameNo', {})(
                            <Input placeholder="名称/文号/代替修订情况" />
                        )
                        }
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSubmit}>
                            搜 索
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => this.handleSubmit(0)}>
                            重 置
                        </Button>
                    </Form.Item>
                </Form>
                <Table className="mt20"
                    bordered
                    dataSource={this.state.regdata}
                    columns={this.state.columns}
                    rowSelection={this.rowCheckSelection()}
                    pagination={false}
                />
                <Pagination {...this.state.pagination}
                    pageSize={this.state.params.pageSize}
                    style={{ textAlign: 'right' }}
                    className='mt20'
                />
            </div>
        )
    }
}
Regulations = Form.create()(Regulations)