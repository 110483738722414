import React, { Component } from 'react'
import {Tabs} from 'antd'
import Check from './Check'
import Uncheck from './Uncheck'
const TabPane = Tabs.TabPane
//首页-试用申请
export default class State extends Component {

    //切换tab回调
    callback=(key)=>{
        switch(key){
            case '1':
                    this.child.getList()
                break;
            case '2':
                    this.child.getList()
                break;
            default:
                break;
        }
    }
    onRef = (ref) => {
        this.child = ref
    }
    render() {
        return (
            <div className="home">
                <Tabs defaultActiveKey="1" onChange={this.callback}>
                    <TabPane tab="待处理" key="1">
                        <Uncheck onRef={this.onRef}/>
                    </TabPane>
                    <TabPane tab="已处理" key="2">
                        <Check onRef={this.onRef}/>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
