/**
 * @file 页面：代理商编辑
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Form, Input, Icon, Select, Button, Radio, message } from 'antd'
import {saveAgent} from '../../Api'
import Address from './components/Address'
import Draw from './components/Draw'
import ProxyArea from './components/ProxyArea'
import { getAgentBasicInfo, getFileUrl } from '../../Api'

const { Option } = Select

/**
 * @class
 * @extends React.Component
 */
class AgentManagementEditForm extends React.Component {
    
    /**
     * 组件状态值
     * @type {Object}
     */
    state = {
        cityData: [],
        cityDataOri: [],
        agentPaymentList: [],
        draw_keys: [0],
        contractLink: '#'
    }
    
    /**
     * 组件载入函数
     * @type {Function}
     */
    componentDidMount() {
        window.scrollTo(0, 0);
        const agentId = this.props.location.pathname ? this.props.location.pathname.split('/').reverse()[0] : 'edit'
        if (agentId !== 'edit') {
            getAgentBasicInfo({agentId}).then((res)=>{
                console.log(res,"edit");
                if(res.status === 200 && res.data.state.value == 0){
                    res.data.result.agentAreaIds = res.data.result.agentAreaIds.split(',')
                    res.data.result.agentAreaId = this.getAreaArray(res.data.result.agentAreaId)
                    this.setState({...res.data.result, draw_keys: Array.from({length: res.data.result.agentPaymentList.length}, (v, k) => k)})
                    this.downloadLink(res.data.result.agentContractFileId)
                }
            }).catch((err)=>{
                console.log(err)
            })
        }
    };

    /**
     * 获取下载附件的链接
     * @type {Function}
     * @param {String} fileId 文件的ID
     */
    downloadLink = (fileId) => {
        getFileUrl({fileId}).then(res => {
            console.log(res, 'link')
            if (res.status == 200 && res.data.state.value == 0) {
                this.setState({contractLink: res.data.result})
            }
        })
    }

    /**
     * 根据地区的ID获取地区的上级和地区的Array
     * @type {Function}
     * @param {String} areaId 地区ID
     * @return {Array}
     */
    getAreaArray = areaId => {
        if (areaId.endsWith('0'.repeat(2))) {
            return [areaId.slice(0, 2)+'0'.repeat(10), areaId.slice(0, 4)+'0'.repeat(8), areaId.slice(0, 6)+'0'.repeat(6), areaId]
        } else if (areaId.endsWith('0'.repeat(6))) {
            return [areaId.slice(0, 2)+'0'.repeat(10), areaId.slice(0, 4)+'0'.repeat(8), areaId]
        } else if (areaId.endsWith('0'.repeat(8))) {
            return [areaId.slice(0, 2)+'0'.repeat(10), areaId]
        } else if (areaId.endsWith('0'.repeat(10))) {
            return [areaId]
        }
    }

    /**
     * 提成比例删除列
     * @type {Function}
     * @param {Number} k 该列对应的key 
     */
    remove = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('draw_keys');
        // 至少需要一个
        if (keys.length === 1) {
            return;
        }

        form.setFieldsValue({
            draw_keys: keys.filter(key => key !== k),
        });
    };

    /**
     * 提成比例增加列
     * @type {Function} 
     */
    add = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('draw_keys');
        const nextKeys = keys.concat(keys.length);
        form.setFieldsValue({
            draw_keys: nextKeys,
        });
    };

    /**
     * 提交后事件
     * @type {Function}
     * @param {Event} e 提交事件 
     */
    handleSubmit = e => {
        e.preventDefault();
        const {history} = this.props
        
        this.props.form.validateFields((err, values) => {
            if (!values.agentAddr.area || (values.agentAddr.area && values.agentAddr.area.length < 3) || !values.agentAddr.address) {
                this.props.form.setFields({
                    agentAddr: {
                      value: values.agentAddr,
                      errors: [new Error('请将地址填写完整')],
                    },
                });
            }
            else if (!err) {
                const finalValues = {id: this.state.id, parentId: this.state.pagentBusiness.id, agentBusinessId: this.state.agentBusinessId}
                for (let key of Object.keys(values)) {
                    if (key == 'agentAddr') {
                        finalValues[key] = values[key].address
                        finalValues.agentAreaId = values[key].area.slice(-1)[0]
                    } else if (key == 'agentAreaList') {
                        finalValues[key] = values[key].map(item => {return {sysAreaId: item}})
                    } else if (key == 'agentContractFileId') {
                        finalValues[key] = values[key].file.response.result.id
                    } else if (key == 'parentId') {
                        finalValues[key] = values[key].split('-').slice(-1)[0]
                    } else if (key !== 'draw_keys') {
                        finalValues[key] = values[key]
                    }
                }
                console.log('Received values of form: ', finalValues)
                saveAgent(finalValues).then(res => {
                    console.log(res, "edit")
                    if(res.status === 200 && res.data.state.value == 0) {
                        history.push("/agent/management", {action: 'edit'})              
                    }
                }, reason => {
                    message.error(reason)
                })
            }
        });
    };

    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 21, offset: 3 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 24,
                    offset: 0,
                },
            },
        };
        getFieldDecorator('draw_keys', { initialValue: this.state.draw_keys });
        const keys = getFieldValue('draw_keys');
        const formItems = keys.map((k, index) => (
            <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? '提成比例：' : ''}
                required={true}
                key={k}
            >
                {getFieldDecorator(`agentPaymentList[${k}]`, {
                    initialValue: this.state.agentPaymentList[k],
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                        {
                            required: true,
                            message: "请输入提成比例或删除此行",
                        },
                    ],
                })(<Draw />)}
                {keys.length > 1 ? (
                    <Icon
                        className="dynamic-delete-button ml10"
                        type="minus-circle-o"
                        onClick={() => this.remove(k)}
                    />
                ) : null}
            </Form.Item>
        ));
        return <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label="代理属性：">
                {this.state.agentTypeName}
            </Form.Item>
            <Form.Item label="代理商名称：">
                {this.state.agentName}
            </Form.Item>

            <Form.Item label="代理商手机号：">
                {this.state.token}
            </Form.Item>
            <Form.Item label="代理商推广码">
                {this.state.promoteCode}
            </Form.Item>
            <Form.Item label="负责人：">
                {getFieldDecorator('agentManager', {
                    initialValue: this.state.agentManager,
                    rules: [
                        {
                            required: true,
                            message: '请输入负责人姓名',
                        },
                    ],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="联系电话：">
                {getFieldDecorator('agentTel', {
                    initialValue: this.state.agentTel,
                    rules: [
                        {
                            required: true,
                            message: '请输入联系电话',
                        },
                    ],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="联系邮箱：">
                {getFieldDecorator('agentEmail', {
                    initialValue: this.state.agentEmail,
                    rules: [
                        {
                            type: 'email',
                            message: '请输入有效邮箱',
                        },
                        {
                            required: true,
                            message: '请输入联系邮箱',
                        },
                    ],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="代理期限：">
                {getFieldDecorator('agentYear', {
                    initialValue: this.state.agentYear,
                    rules: [
                        {
                            required: true,
                            message: '请选择代理期限',
                        },
                    ],
                })(<Select>
                    <Option value={2}>2年</Option>
                    <Option value={3}>3年</Option>
                    <Option value={5}>5年</Option>
                </Select>)}
            </Form.Item>
            <Form.Item label="代理方式：">
                {getFieldDecorator('agentUnique', {
                    initialValue: this.state.agentUnique,
                    rules: [
                        {
                            required: true,
                            message: '请选择代理方式',
                        },
                    ],
                })(<Radio.Group>
                    <Radio value={true}>独家代理</Radio>
                    <Radio value={false}>非独家代理</Radio>
                </Radio.Group>)}
            </Form.Item>
            <Form.Item label="代理区域：">
                {getFieldDecorator('agentAreaList', {
                    initialValue: this.state.agentAreaIds,
                    rules: [
                        {
                            required: true,
                            message: '请选择代理区域',
                        },
                    ],
                })(<ProxyArea />)}
            </Form.Item>
            <Form.Item label="详细地址：">
                {getFieldDecorator('agentAddr', {
                    initialValue: {area: this.state.agentAreaId, address: this.state.agentAddr},
                    rules: [
                        {
                            required: true,
                            message: '请选择详细地址',
                        },
                    ],
                })(<Address />)}
            </Form.Item>
            {formItems}
            <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                    <Icon type="plus" /> 添加
                </Button>
            </Form.Item>
            <Form.Item label="所属业务员：">
                {this.state.pagentBusiness ? this.state.pagentBusiness.name: ''}
            </Form.Item>

            <Form.Item label="代理商合同">
                {this.state.agentContractFileId ? <a href={this.state.contractLink} download>{this.state.contractLink == '#' ? '' : this.state.contractLink.split('/').slice(-1)[0]}</a> : ''}
            </Form.Item>


            <Form.Item {...tailFormItemLayout} style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" className="mr10">
                    确定
                </Button>
                <Button type="cancel" onClick={() => {window.history.back()}}>
                    取消
                </Button>
            </Form.Item>
        </Form>
    }
}

/**
 * 创建表单
 * @const {Form} AgentManagementEdit
 */
const AgentManagementEdit = Form.create()(AgentManagementEditForm)

export default AgentManagementEdit
