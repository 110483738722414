import React, { Component } from 'react'
import { Form, Radio, Input, InputNumber,DatePicker,Upload, message, Button, Icon, Select} from 'antd'
import sparkMd5 from 'spark-md5'
import {postUploadFile,deleteFile} from './../../../Api'
import UploadFail from './Upload'
const { TextArea } = Input;
const { Option } = Select;
let idflage2=true
class CreateVersionOrder extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
    }
    state={
        files: [],
    }
    getItemsValue = ()=>{ 
        let returnValues
        this.props.form.validateFields((err,value) => {
            if(err){
                returnValues = false
            }else{
                returnValues = value
                returnValues = this.props.form.getFieldsValue()
                returnValues.filess = this.state.files
            }
        })
        return returnValues;
    }
    resetValue = () =>{
        this.props.form.resetFields()
        this.setState({
            files: []
        })
    }
    //使用期限
    onChange = (date, dateString) => {
        console.log(date, dateString);
    }
    normFile = e => {
        // 文件上传事件
        if (Array.isArray(e)) {
            return e;
        }
        if(e.file.type == 'application/msword' || e.file.type == 'application/vnd.ms-powerpoint'|| e.file.type == 'application/pdf' || !e.file.type || e.file.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || e.file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
            return e && e.fileList;
        }else{
            message.warning('文件上传类型错误，请上传PDF、Word、PPT形式文件！')
            e.fileList = [];
            return e && e.fileList
        }
    };
    render() {
        let _this = this
        //定义上传控件
        const uploadReadProps = {
            //解读文件
            name: 'files',
            accept:'application/msword,application/vnd.ms-powerpoint,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            beforeUpload(file){
                console.log(file);
                let reader = new FileReader();
                reader.onload = function(e){
                    let params = new FormData()
                    params.append('file',file)
                    params.append('filename',file.name)
                    params.append('type','0')
                    params.append('filehash',sparkMd5.hashBinary(e.target.result))
                    postUploadFile(params).then(res=>{
                        console.log(res)
                        if(res.status === 200 && res.data.state.value ===0){
                            let arr = _this.state.files
                            arr.push({
                                uid: res.data.result.id,
                                name: res.data.result.fileName,
                                status: 'done',
                                url: 'http:'+res.data.result.fullOssurl,
                              })
                              _this.setState({
                                  files:arr
                              })
                            idflage2 = false
                        }
                    })
                }
                // 传入一个参数对象即可得到基于该参数对象的文本内容
                reader.readAsBinaryString(file)
                //阻止antd自带的上传
                return false
            },
            //移除文件时清空url
            onRemove(file){
            }
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                <Form.Item label="购买企业">
                    {getFieldDecorator('companyName')(
                        <Input placeholder="购买企业"/>
                    )}
                </Form.Item>
                <Form.Item label="软件名称">
                    {getFieldDecorator('softIdStr')(
                        <Select key={'softIdStr_0'} placeholder="软件名称">
                            <Option value={1} key={'softIdStr_1'}>职业健康安全管理系统</Option>
                            <Option value={2} key={'softIdStr_2'}>放射卫生&辐射安全管理系统</Option>
                            <Option value={4} key={'softIdStr_4'}>职业健康管理集团解决方案</Option>
                            <Option value={5} key={'softIdStr_5'}>放射检测</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="代理商推广码">
                    {getFieldDecorator('promocode')(
                        <Input placeholder="代理商推广码"/>
                    )}
                </Form.Item>
                <Form.Item label="交易金额">
                    {getFieldDecorator('payCost')(
                        <InputNumber/>
                    )}
                    <span className="ant-form-text"> 元</span>
                </Form.Item>
                <Form.Item label="交易号">
                    {getFieldDecorator('tradeNo')(
                        <Input placeholder="交易号"/>
                    )}
                </Form.Item>
                <Form.Item label="合同附件">
                    {getFieldDecorator('files',{
                        valuePropName: 'fileList',
                        getValueFromEvent: this.normFile,
                    })(
                        <Upload {...uploadReadProps}>  
                            <Button>
                                <Icon type="upload"/> 上传文件
                            </Button>
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item label="备注">
                    {getFieldDecorator('remark',{
                        rules: [{max:200,message: '内容不可多于200个字符',}]
                    })(
                        <TextArea
                        placeholder="请填写备注"
                        style={{ height: 75}}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const CreateVerForm = Form.create()(CreateVersionOrder);

export default CreateVerForm;

// export default Form.create()(SoftwareOne); 