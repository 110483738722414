import React from 'react'
import { Row, Col, Icon, Dropdown, Menu, Badge, Breadcrumb, message } from 'antd'
// import {connect} from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import menuConfig from './../../Common/js/menuConfig'
import { homeWarnInfo,homeQuestionDel } from './../../Mock'
import { getAdminRemindListData,closeAdminRemind } from './../../Api'
class Header extends React.Component {
    state = {
        menuList: [],
        visible: false,
        warnNum:0
    }
    componentWillMount() {
        //this.setState({userName: 'Fimo'})
        this.getMenus()

    }
    handleVisibleChange = (flag) => {
        this.setState({
            visible: flag
        })
    }
    handleRemove = (e, id) => {
        closeAdminRemind({adminRemindId:id}).then(res=>{
            console.log(res)
            if(res.data.state.value == 0){
                message.success('操作成功!')
                this.getMenus()
            }
        })
    }
    loginOut = () => {
        // 1. 清空本地数据
        //sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        // 2. 回到首页
        window.location.href = '/';
    }

    getMenus = () => {
        //前端移除，需要把数据放入state，后台移除则不用
        getAdminRemindListData({dealType:1,pageSize:10000,pageNum:1}).then(res => {
            //console.log(res)
            if (res.data.state.value == 0) {
                this.setState({warnNum:res.data.result.rows.length})
                res.data.result&&this.renderDropMenu(res.data.result.rows)
            }
        })
    }
    renderDropMenu = (data) => {
        let arr = []
        if(data.length==0){
            this.setState({
                menuList:[<Menu.Item key={'key_none_info'} className="overhide">
                <div className="overhide">
                    暂无
                </div>
            </Menu.Item>]
            })
            return
        }
        data.map((item, index) => {
            arr.push(
                <Menu.Item key={item.id} className="overhide">
                    <div className="overhide">
                        <a
                            className="fr"
                            onClick={(e) => this.handleRemove(e, item.id)}
                        >
                            <Icon type="close"></Icon>
                        </a>
                        <h4 className="fl">{item.topic}</h4>
                    </div>
                    {this.renderDropMenuNav(item)}
                </Menu.Item>
            )
        })
        this.setState({
            menuList:arr
        })
    }
    renderDropMenuNav = (item) => {
        let url = ""
        switch (item.type) {
            case 0:
                url = "/home/check"
                break;
            case 1:
                url = "/home/suggestion"
                break;
            case 2:
                url = "/home/legal"
                break;
            case 3:
                url = "/home/application"
                break;
            default:
                break;
        }
        return <NavLink to={''} className="ft500">
            <div className="dlineb">
                {item.content.length > 30
                    ? item
                        .content
                        .replace(item.content.slice(30), '...')
                    : item.content}
                <p>时间：{item.businessTime}</p>
            </div>
        </NavLink>
    }
    // 对menuConfig里的选项进行遍历和层级递归，确定目标选项的位置
    loopToGetFromMenu = (slug, menu, value = []) => {
        for (let i = 0; i < menu.length; i++) {
            // console.log(menu[i].key)
            if (menu[i].key === slug) {
                value.push(i)
                return value
            }
            let children_assertion = (slug.search(menu[i].key) === 0) && menu[i].children
            if (children_assertion) {
                let testValue = [...value]
                testValue.push(i)
                return this.loopToGetFromMenu(slug, menu[i].children, testValue)
            }
        }
    }

    // 根据loopToGetFromMenu中得到的位置或是这个位置slice而得到的位置，来得到目标选项的信息
    RecordFromMenu = (menu, index) => {
        let record = menu[index[0]]
        if (index.length === 1) {
            return record
        }
        for (let i = 1; i < index.length; i++) {
            record = record.children[index[i]]
        }
        return record
    }

    // 这些详细路由要省去ID
    dealPath = slug => {
        if (slug.startsWith('/agent/management/detail/') || slug.startsWith('/agent/management/edit/')||slug.startsWith('/training/category/edit')||slug.startsWith('/training/category/editTwo')||slug.startsWith('/training/category/see')||slug.startsWith('/system/list1/edit')) {
            const slugList = slug.split('/')
            return slugList.slice(0, slugList.length-1).join('/')
        } else if (slug.startsWith('/activity/management/edit/')) {
            const slugList = slug.split('/')
            return slugList.slice(0, slugList.length-2).join('/')
        }
        return slug
    }

    // 面包屑导航
    getNav = () => {
        // 得到当前的地址
        const path = this.props.location.pathname
        // 导航默认含有首页
        let nav = {
            "/home": { title: "首页", key: '/home' }
        },
            path_elelist = [],
            // 得到当前地址在菜单当中的位置
            path_index = this.loopToGetFromMenu(this.dealPath(path), menuConfig)
        // console.log(path, path_index)

        // 得到位置之后进行处理
        if (path_index) {
            switch (path_index.length) {
                case 1:
                    // 如果目标地址是菜单中的叶节点，信息直接分配给导航栏
                    let path_info = this.RecordFromMenu(menuConfig, path_index)
                    nav[path] = path_info
                    break
                default:
                    // 如果目标地址是菜单中的子节点，需要进行层级遍历并逐级将信息分配给导航栏
                    for (let i = 1; i <= path_index.length; i++) {
                        let path_now = path_index.slice(0, i), path_info = this.RecordFromMenu(menuConfig, path_now)
                        nav[path_info.key] = path_info
                    }
                    break
            }
        }
        // 最后将所有导航栏信息分配给面包屑导航
        for (let page of Object.keys(nav)) {
            if (page == '/agent/management/detail' || page == '/agent/management/edit' || page == '/activity/management/edit'|| page == '/training/category/edit'|| page == '/training/category/editTwo'|| page == '/training/category/see' || page =='/system/list1/edit') {
                path_elelist.push(
                    <Breadcrumb.Item key={page} className="active">{nav[page].title}</Breadcrumb.Item>
                )
            }
            else{
                path_elelist.push(
                    <Breadcrumb.Item key={page}><NavLink exact to={page}>{nav[page].title}</NavLink></Breadcrumb.Item>
                )
            }
        }
        return path_elelist
    }

    render() {
        //localStorage里面调取数据
        const username = JSON.parse(localStorage.getItem('userData')).username;
        const menu = (
            <Menu className="header-drop" >
                {this.state.menuList}
            </Menu>
        );
        return (
            <div className="headers">
                <Row className="header-top">
                    <Col span={24}>
                        <Dropdown overlay={menu} trigger={['hover', 'click']} onVisibleChange={this.handleVisibleChange}
                            visible={this.state.visible}>
                            <a className="ant-dropdown-link mr25" href="#">
                                <Badge count={this.state.warnNum}>
                                    <Icon type="bell" className="f20 mr10 red"></Icon>
                                </Badge>
                            </a>
                        </Dropdown>
                        <a href="#">
                            <Icon type="exclamation-circle" className="f20 mr20 orange"></Icon>
                        </a>
                        <span className="mr20">
                            <Icon type="phone" className="f20"></Icon>
                            0571-89978775
                        </span>
                        <span className="mr20">欢迎，{username}</span>
                        <a href="#" onClick={this.loginOut}>退出</a>
                    </Col>
                </Row>
                <Row className="breadcrumb">
                    <Col span={24}>
                        {this.getNav()}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(Header)

// const mapStateToProps = (state) => {
//     return {
//         addCourseData: state.addCourseData
//     }
// };

// export default connect(mapStateToProps, null)(Header);