/**
 * @file 题库模块公用方法库
 * @author Sim <sim@snorl.ax>
 */
import { message } from 'antd'

/**
 * @callback successCallback 成功时执行的回调函数
 */
/**
 * @callback failureCallback 失败时执行的回调函数
 * @param {String} failureMessage 失败信息
 */

/**
 * 状态提示
 * @param {Boolean | String} status 状态，默认为true，若为string则输出错误信息
 */
export const statusMessage = (status=true) => status == true ? message.success('操作成功') : message.error(status)

/**
 * 将数字转换成两位的String
 * @const {Function} twoDigit
 * @param {Number} 原来的数字
 * @return {String} 转换结果
 */
export const twoDigit = num => num < 10 ? `0${num}` : num

/**
 * 将得到的日期数字转换成相应的格式
 * @const {Function} parseDate
 * @param {Number} 原日期数字
 * @return {String} 最终格式的字符串
 */
export const parseDate = date => {
    date = new Date(date)
    return `${date.getFullYear()}-${twoDigit(date.getMonth()+1)}-${twoDigit(date.getDate())} ${twoDigit(date.getHours())}:${twoDigit(date.getMinutes())}`
}

/**
 * 将得到的日期数字转换成相应的格式（无时分）
 * @const {Function} parseDate
 * @param {Number} 原日期数字
 * @return {String} 最终格式的字符串
 */
export const parseDate2 = date => {
    date = new Date(date)
    return `${date.getFullYear()}-${twoDigit(date.getMonth()+1)}-${twoDigit(date.getDate())}`
}

/**
 * 表格分页设置
 * @param {Number} pageSize 单页记录数
 * @param {Number} current 当前页码
 * @param {Number} total 总页码
 * @param {successCallback} callback 成功时执行的回调函数 
 * @return {Object}
*/
export const pagination = (pageSize, current, total, callback) => ({
    pageSize, 
    current, 
    showSizeChanger: true,
    pageSizeOptions:['10','20','30','40'],
    showTotal: () => `共${total}条数据`,
    showQuickJumper: true,
    total, 
    onChange: callback
})