/**
 * 表单页面逻辑:
 * 1\ 进入表单时传入type(编辑与新增共用一表单)
 *    若进入页面时有初始数据(即编辑),则渲染初始数据,且disabled法律效力选项
 *    若进入页面时无初始数据(即新增),则全部置空
 * 2\ 若法律效力为"解读"时
 *    通过this.state.expLegal隐藏部分表单内容
 */
import React from 'react'
import moment from 'moment'
import sparkMd5 from 'spark-md5'   //文件md5加密库
import{Form,Select,Input,DatePicker,Checkbox,Button,Icon,Upload,Cascader,message,Spin} from 'antd'
import { searchLegalOptionsByFileName,deleteFile,projectManage,uploadOri,getLegalTypeInit,getLegalType,searchLegalOptions } from './../../../Api'
const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea
/** @type {number} randkey -自增数字基数 */
let randKey = 1
/**
 * 自动生成Key
 * @type {function} keyGenerator -自动生成唯一Key
 * @returns {string} 返回唯一key
 * */
const keyGenerator = () => '_bi' + randKey++
/** @type {number} timer -模糊搜索防抖控制*/
/** @type {number} timr2 -滚动条懒加载防抖动控制*/
/** @type {boolean} idflage2 -解读文件上传状态值-false为已处理*/
/** @type {boolean} idflage1 -源文件上传状态值-false为已处理*/
let timer,timer2
let idflage2=true
let idflage1 =true

/**
 * @description 新增/编辑法律法规表单
 * @class AddLegalForm
 * @extends {React.Component}
 * */
class AddLegalForm extends React.Component {
    /**
     * @type {Object} state -组件内数据
     * @property {boolean} expLegal - 解读状态下的表单控制显示
     * @property {object} formItemLayout -解读状态下的表单布局
     * @property {object} scroll -懒加载查询参数
     * @property {array} children -“代替”模糊搜索的数据列表
     * @property {array} children1 -“部分代替”模糊搜索的数据列表
     * @property {array} children2 -”被...代替“模糊搜索的数据列表
     * @property {array} children3 -“代替修订”模糊搜索的数据列表
     * @property {array} children4 -”由...修订“模糊搜索的数据列表
     * @property {array} children5 -“所属法规标准”的数据列表
     * @property {boolean} isStander -是否为法规标准的状态值
     * @property {boolean} fetching -模糊搜索的加载动画状态值
     * @property {array} legalType -法规效力级联数据
     * @property {array} belongProject -所属项目级联数据
     * */
    state = {
        expLegal: false,
        formItemLayout: {
            labelCol: {
                xs: 24,
                sm: 4
            },
            wrapperCol: {
                xs: 24,
                sm: 20
            }
        },
        scroll:{
            pageNo:1,
            pageSize:50
        },
        children:[],
        children1:[],
        children2:[],
        children3:[],
        children4:[],
        children5:[],
        isStander:false,
        fetching:false,
        legalType:[],
        belongProject:[]
    }
    /**
     * 编辑法规库-回显数据设置
     * @function changeForm
     * @param {object} initState -初始化数据
     *  @param {string} formType -进入表单的类型（editLegalForm-编辑表单需要回显）
     * */
    changeForm = (initState,formType)=>{
        if (formType === "editLegalForm") {
            this.setState({editDisabled: true,reReadLawId:initState.readLawId})
            if(initState.twoLawName=='解读'){
                this.onLegalCascaderChange('_', [
                    {
                        label: '解读'
                    }
                ])
                initState.lawClassId = [{
                    key:initState.twoLawId,
                    value:initState.twoLawId,
                    label:initState.twoLawName,
                    isLeaf:true
                }]
                this.setState({
                    legalType:initState.lawClassId
                })
                if(initState.forStandardLawId&&initState.forStandardLawName){
                    initState.readLawIds = []
                    initState.readLawIds.push({
                        // key:portionRevisionOrRevisionrIds[i],
                        key:initState.forStandardLawId,
                        label: initState.forStandardLawName
                    })
                }
                if(initState.fileId&&initState.readLawFileName){
                    if(idflage2){
                        this.setState({
                            jdId:initState.fileId,
                            jdName:initState.readLawFileName,
                            jdUrl:initState.readFileOssUrl,
                        })
                    }
                    initState.uploadReadInitial=[{
                        uid:initState.id,
                        name:initState.readLawFileName,
                        status:'done',
                        url:initState.readFileOssUrl,
                    }]
                }else{
                    initState.uploadReadInitial=[]
                }
                initState.lawClassId = [initState.twoLawId]
                this.setState({
                    initFormState:initState
                })
            }else if(initState.oneLawName=="法律法规"){
                this.onLegalCascaderChange('_', [
                    {
                        label: '法律法规'
                    }
                ])
                initState.projClassId = [{
                    key:initState.oneProjectId,
                    value:initState.oneProjectId,
                    label:initState.oneProjectName,
                    isLeaf:false,
                    children:[{
                        key:initState.twoProjectId,
                        value:initState.twoProjectId,
                        label:initState.twoProjectName,
                        isLeaf:true,
                    }]
                }]
                initState.lawClassId = [{
                    key:initState.oneLawId,
                    value:initState.oneLawId,
                    label:initState.oneLawName,
                    isLeaf:false,
                    children:[{
                        key:initState.twoLawId,
                        value:initState.twoLawId,
                        label:initState.twoLawName,
                        isLeaf:true,
                    }]
                }]
                this.setState({
                    legalType:initState.lawClassId
                })
                initState.lawClassId = [initState.oneLawId,initState.twoLawId]
                initState.projClassId = [initState.oneProjectId,initState.twoProjectId]
                if(initState.type != 0){
                    if(initState.replaceOrRevisionr&&initState.replaceOrRevisionr.replace(/(^s*)|(s*$)/g, "").length!=0){
                        this.setState({
                            isEditChecked:true//修订为...
                        })
                        let replaceOrRevisionrStr = initState.replaceOrRevisionr.split(',')
                        let replaceOrRevisionrIds = initState.replaceOrRevisionrId.split(',')
                        let replaceOrRevisionrArr = []
                        for(let i =0;i<replaceOrRevisionrIds.length;i++){
                            replaceOrRevisionrArr.push({
                                key:replaceOrRevisionrIds[i],
                                // key:keyGenerator(),
                                label: replaceOrRevisionrStr[i]
                            })
                        }
                        initState.replaceOrRevisionrIds = replaceOrRevisionrArr
                    }
                    if(initState.portionRevisionOrRevisionr&&initState.portionRevisionOrRevisionr.replace(/(^s*)|(s*$)/g, "").length!=0){
                        this.setState({
                            isEditedChecked:true//由...修订
                        })
                        let portionRevisionOrRevisionrStr = initState.portionRevisionOrRevisionr.split(',')
                        let portionRevisionOrRevisionrIds = initState.portionRevisionOrRevisionrId.split(',')
                        let portionRevisionOrRevisionrArr = []
                        for(let i =0;i<portionRevisionOrRevisionrIds.length;i++){
                            portionRevisionOrRevisionrArr.push({
                                key:portionRevisionOrRevisionrIds[i],
                                // key:keyGenerator(),                                
                                label: portionRevisionOrRevisionrStr[i]
                            })
                        }
                        initState.portionRevisionOrRevisionrs = portionRevisionOrRevisionrArr
                    }
                }else if(initState.type == 0){
                    initState.revisionOrReplaceRemark = initState.replaceRelationship
                }
                if(initState.fileId&&initState.lawFileName){
                    if(idflage1){
                        this.setState({
                            originalId:initState.fileId,
                            originalName:initState.lawFileName,
                            originalUrl:initState.ossUrl,
                        })
                    }
                    initState.uploadOriInitial=[{
                        uid:initState.id,
                        name:initState.lawFileName,
                        status:'done',
                        url:initState.ossUrl
                    }]
                }else{
                    initState.uploadOriInitial=[]
                }
                if(initState.readLawId&&initState.readLawFileName){
                    if(idflage2){
                        this.setState({
                            jdId:initState.readLawId,
                            jdName:initState.readLawFileName,
                            jdUrl:initState.readFileOssUrl,
                        })
                    }
                    initState.uploadReadInitial=[{
                        uid:initState.readLawId,
                        name:initState.readLawFileName,
                        status:'done',
                        url:initState.readFileOssUrl,
                    }]
                }else{
                    initState.uploadReadInitial=[]
                }
                
                this.setState({
                    initFormState:initState
                })
            }else if(initState.oneLawName=='标准'){
                this.onLegalCascaderChange('_', [
                    {
                        label: '标准'
                    }
                ])
                initState.lawClassId = [{
                    key:initState.oneLawId,
                    value:initState.oneLawId,
                    label:initState.oneLawName,
                    isLeaf:false,
                    children:[{
                        key:initState.twoLawId,
                        value:initState.twoLawId,
                        label:initState.twoLawName,
                        isLeaf:true,
                    }]
                }]
                initState.projClassId = [{
                    key:initState.oneProjectId,
                    value:initState.oneProjectId,
                    label:initState.oneProjectName,
                    isLeaf:false,
                    children:[{
                        key:initState.twoProjectId,
                        value:initState.twoProjectId,
                        label:initState.twoProjectName,
                        isLeaf:true,
                    }]
                }]

                this.setState({
                    legalType:initState.lawClassId
                })
                initState.projClassId = [initState.oneProjectId,initState.twoProjectId]
                initState.lawClassId = [initState.oneLawId,initState.twoLawId]
                if(initState.type != 0){
                    if(initState.beReplace&&initState.beReplace.replace(/(^s*)|(s*$)/g, "").length!=0){
                        this.setState({
                            isReplacedChecked:true//被...代替
                        })
                        let beReplaceStr = initState.beReplace.split(',')
                        let beReplaceIds = initState.beReplaceId.split(',')
                        let beReplaceArr = []
                        for(let i =0;i<beReplaceIds.length;i++){
                            beReplaceArr.push({
                                key:beReplaceIds[i],
                                // key:keyGenerator(),
                                label: beReplaceStr[i]
                            })
                        }
                        // console.log(beReplaceArr,"被...代替")
                        initState.beReplaceIds = beReplaceArr
                    }
                    if(initState.replaceOrRevisionr&&initState.replaceOrRevisionr.replace(/(^s*)|(s*$)/g, "").length!=0){
                        this.setState({
                            isAllReplaceChecked:true //代替
                        })
                        let replaceOrRevisionrStr = initState.replaceOrRevisionr.split(',')
                        let replaceOrRevisionrIds = initState.replaceOrRevisionrId.split(',')
                        let replaceOrRevisionrArr = []
                        for(let i =0;i<replaceOrRevisionrIds.length;i++){
                            replaceOrRevisionrArr.push({
                                key:replaceOrRevisionrIds[i],
                                // key:keyGenerator(),
                                label: replaceOrRevisionrStr[i]
                            })
                        }
                        initState.replaceOrRevisionrIds = replaceOrRevisionrArr
                    }
                    if(initState.portionRevisionOrRevisionr&&initState.portionRevisionOrRevisionr.replace(/(^s*)|(s*$)/g, "").length!=0){
                        this.setState({
                            isReplaceChecked:true//部分代替
                        })
                        let portionRevisionOrRevisionrStr = initState.portionRevisionOrRevisionr.split(',')
                        let portionRevisionOrRevisionrIds = initState.portionRevisionOrRevisionrId.split(',')
                        let portionRevisionOrRevisionrArr = []
                        for(let i =0;i<portionRevisionOrRevisionrIds.length;i++){
                            portionRevisionOrRevisionrArr.push({
                                key:portionRevisionOrRevisionrIds[i],
                                // key:keyGenerator(),
                                label: portionRevisionOrRevisionrStr[i]
                            })
                        }
                        initState.portionRevisionOrRevisionrIds = portionRevisionOrRevisionrArr
                    }
                }else if(initState.type == 0){
                    initState.revisionOrReplaceRemark = initState.replaceRelationship
                }
                if(initState.fileId&&initState.lawFileName){
                    if(idflage1){
                        this.setState({
                            originalId:initState.fileId,
                            originalName:initState.lawFileName,
                            originalUrl:initState.ossUrl,
                        })
                    }
                    initState.uploadOriInitial=[{
                        uid:initState.id,
                        name:initState.lawFileName,
                        status:'done',
                        url:initState.ossUrl,
                    }]
                }else{
                    initState.uploadOriInitial=[]
                }
                if(initState.readLawId&&initState.readLawFileName){
                    if(idflage2){
                        this.setState({
                            jdId:initState.readLawId,
                            jdName:initState.readLawFileName,
                            jdUrl:initState.readFileOssUrl,
                        })
                    }
                    initState.uploadReadInitial=[{
                        uid:initState.readLawId,
                        name:initState.readLawFileName,
                        status:'done',
                        url:initState.readFileOssUrl,
                    }]
                }else{
                    initState.uploadReadInitial=[]
                }
                this.setState({
                    initFormState:initState
                })
            }else{
                message.warning('出错啦！')
            }
        }
    }
    /**
     * 父组件更新传入数据时即执行，可用于同步父组件数据
     * @function componentWillReceiveProps -监听父组件传入子组件的props变化
     * @param {object} nextProps -即将传入子组件的新props（上一次的props在this.props中）
     * */
    componentWillReceiveProps= (nextProps)=>{
        if(nextProps.flag){
            this.changeForm(nextProps.initFormState,nextProps.type)
        }
    }
    componentWillMount = () => {
        /** @constant {string} formType -修改表单的类型（新增/编辑） */
        const formType = this.props.type
        /** 清楚模糊搜索定时器 */
        clearTimeout(timer);
        /** 回显数据 */
        if(this.props.flag){
            this.changeForm(this.props.initFormState,this.props.type)
        }
        /** 表单状态为新增时，法律效应初始化 */
        if(formType =="addLegalForm"){
            this.requestLegalInit()
        }
        /** 初始化所属项目 */
        this.requestProject()
    }
    /** 获取法律效力一级 */
    requestLegalInit = ()=>{
        let arr = []
        getLegalTypeInit().then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                res.data.result.legClassifyModels.map((item)=>{
                    if(item.name == "解读"){
                        arr.push({
                            value:item.id,
                            label:item.name,
                            pid:item.pid,
                            key:item.id,
                            isLeaf:true
                        })
                    }else{
                        arr.push({
                            value:item.id,
                            label:item.name,
                            pid:item.pid,
                            key:item.id,
                            isLeaf:false
                        })
                    }
                })
                this.setState({
                    legalType:arr
                })
            }
        })
    }
    /** 获取法律效力二级 */
    requestLegalType = (selected)=>{
        let arr = []
        getLegalType({id:selected[0].value}).then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                res.data.result.list.map((item)=>{
                    arr.push({
                        value:item.id,
                        label:item.name,
                        pid:item.pid,
                        key:item.id,
                        isLeaf:true,
                        type:res.data.result.type
                    })
                })
                let arr2 = this.state.legalType
                arr2.map(item=>{
                    if(item.value === arr[0].pid){
                        item.children = arr
                        return
                    }
                })
                //必须要用this.setState异步赋值
                this.setState({
                    legalType:arr2
                })
                
            }
        })
    }
    /** 重置表单 */
    handleresetLegalFilter = () => {
        this.props.form.resetFields()
        setTimeout(()=>{
                this.setState({
                    isEditChecked:false,
                    isEditedChecked:false,
                    isAllReplaceChecked:false,
                    isReplaceChecked:false,
                    isReplacedChecked:false,//被...代替
                })
        },1000)
    }
    /**
     * 获取表单数据
     * @function handleAaddLegalFilterSubmit
     * @returns {(boolean|object)} returnValue 验证成功返回表单数据
     * */
    handleAaddLegalFilterSubmit = () => {
        /** 验证通过后，获取表单所有的值，父组件通过wrappedComponentRef调用 */
        let returnValue = false
        this.props.form.validateFields((err,value)=>{
            if(err){
                returnValue = false
            }else{
                if(this.state.legalAjaxType==1){
                    value.replace = this.state.isEditChecked&&value.replace.length?value.replace:[]
                    value.replaced = this.state.isEditedChecked&&value.replaced.length?value.replaced:[]
                    value.beReplace = []
                    value.bePartReplace = []
                    value.beReplaced = []
                }
                if(this.state.legalAjaxType==2){
                    value.beReplace = this.state.isAllReplaceChecked&&value.beReplace.length?value.beReplace:[]
                    value.bePartReplace = this.state.isReplaceChecked&&value.bePartReplace.length?value.bePartReplace:[]
                    value.beReplaced = this.state.isReplacedChecked&&value.beReplaced.length?value.beReplaced:[]
                    value.replace = []
                    value.replaced = []
                }
                if(this.state.legalAjaxType==0){
                    value.beReplace = []
                    value.bePartReplace = []
                    value.beReplaced = []
                    value.replace = []
                    value.replaced = []
                }
                returnValue = value
                returnValue.readLawId = this.state.reReadLawId
                returnValue.jdName = this.state.jdName
                returnValue.jdUrl = this.state.jdUrl
                returnValue.fileId = this.state.originalId
                returnValue.readFileId = this.state.jdId
                returnValue.originalName = this.state.originalName
                returnValue.originalUrl = this.state.originalUrl
                if(this.props.type == 'editLegalForm'){
                    returnValue.id = this.props.initFormState.id
                }
            }
        })
        return returnValue
    }
    /**
     * 获取所属项目
     * @function requestProject
     * */
    requestProject = ()=>{
        projectManage().then(res=>{
            if(res.status ===200){
                let arr = []
                res.data.result.projectOneLevelModels.map((item)=>{
                    let arr2 =[]
                    arr.push({
                        value:item.id,
                        label:item.name,
                        pid:item.pid,
                        key:item.id,
                        isLeaf:item.projectTwoLevelModels.length==0?true:false
                    })
                    if(item.projectTwoLevelModels&&item.projectTwoLevelModels.length!==0){
                        item.projectTwoLevelModels.map((item2)=>{
                            arr2.push({
                                value:item2.id,
                                label:item2.name,
                                pid:item2.pid,
                                key:item2.id,
                                isLeaf:true
                            })
                        })
                        arr[arr.length-1].children = arr2
                    }
                })
                this.setState({
                    belongProject:arr
                })
            }
        })
    }
    /**
     * 判断传入checkbox的类型对应select多选的disabled 代替修订模块-checkbox与select的联动
     * @function onCheckboxdChange
     * @param {Event} e -监听checkbox点击事件
     * @param {string} type -对应select的disabled状态值
     * */
    onCheckboxdChange = (e, type) => {
        this.setState({[type]: e.target.checked})
    }
    /**
     * 渲染下拉选项
     * @function renderOptions
     * @returns {array} options -select下拉的options数组
     * */
    renderOptions = ()=>{
        const options = []
        this.state.children.map((item,index)=>{
            options.push(
                <Option key={item.key}>{item.name}</Option>
            )
        })
        return options
    }
    /**
     * 代替修订-select选择的回调
     * @function handleChange
     * @param {string} value -下拉选中的value值
     * */
    handleChange = (value) => {
        // console.log(`selected ${value}`);
    }

    /**
     * 模糊搜索功能概览
     * @description
     * 法律类模糊搜索接口：searchLegalOptionsByFileName
     * 要求：显示名称，传名称
     * 标准类模糊搜索接口：searchLegalOptions
     * 要求：显示文号，传文号
     * 解读类模糊搜索接口：searchLegalOptionsByFileName -解读类模糊搜索
     * 要求：显示名称，传id
     *
     * @function handleAddFormSearchAll -模糊搜索
     * @function scrollRequest -懒加载
     * @function resetChildren -清空模糊搜索的选项
     */

    /**
     * @function requestLegalFile -模糊搜索法规文件
     * @param {string} childrenName -对应select下拉的数据数组名
     * @param {string} type -法规类型（legal-法规，stander-标准）
     * @param {boolean} jieDu -是否有解读
     * */
    requestLegalFile = (childrenName,type,jieDu)=>{
        if(type == 'legal'){
            /**
             * *@function searchLegalOptionsByFileName -法规模糊搜索
             * @param {object} 查询参数(fileName:Select初始化数据显示名称，提交表单传名称) || 解读时fileName-显示名称，传id
             * */
            searchLegalOptionsByFileName({...this.state.scroll,fileName:this.state.scrollParams}).then(res=>{
                if(res.status === 200){
                        if(res.data.result.length<50){
                            this.setState({
                                isScroll:false
                            })
                        }else{
                            this.setState({
                                isScroll:true
                            })
                        }
                        /** 解读：fileName-显示名称，传id */
                        if(jieDu){
                            res.data.result.map((item,index)=>{
                                this.state[childrenName].push({
                                    key:item.id,
                                    value:item.id,
                                    name:item.fileName,
                                    label:item.fileName
                                })
                            })
                        }else{
                            res.data.result.map((item,index)=>{
                                this.state[childrenName].push({
                                    key:item.id,
                                    value:item.id,
                                    name:item.fileName,
                                    label:item.fileName
                                })
                            })
                        }
                        this.setState({
                            // children:arr,
                            fetching:false
                        })
                }
            })
        }else if(type == 'stander'){
            /**
             * *@function searchLegalOptions -标准类模糊搜索
             * @param {object} 查询参数(fileNo-显示文号，传文号)
             * */
            searchLegalOptions({...this.state.scroll,fileNo:this.state.scrollParams}).then(res=>{
                if(res.status === 200){
                    if(res.data.result.length<50){
                        this.setState({
                            isScroll:false
                        })
                    }else{
                        this.setState({
                            isScroll:true
                        })
                    }
                        res.data.result.map((item,index)=>{
                            this.state[childrenName].push({
                                key:item.id,
                                value:item.id,
                                name:item.fileNo,
                                label:item.fileNo
                            })
                        })
                        
                        this.setState({
                            // children:arr,
                            fetching:false
                        })
                }
            })
        }else{
            console.log('模糊搜索出错啦！')
        }
    }

    /**
     * @function handleAddFormSearchAll -模糊搜索
     * @param {object} value -查询参数
     * @param {string} type -查询类型（1-“部分代替”,2-”被...代替“,3-"代替修订",4-"由...修订"," "-"代替"）
     * @param {string} lawType -法规类型(legal-法规，stander-标准)
     * @param {boolean} jieDu -是否解读
     * */
    handleAddFormSearchAll = (value,type,lawType,jieDu)=>{
        let childrenName = 'children' + type
        this.setState({
            [childrenName]:[],
            scroll:{
              pageNo:1,
              pageSize:50
            },
            scrollParams:value,
            isScroll:true
        })
         if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        
        this.setState({fetching:true})
        timer = setTimeout(()=>{
            this.requestLegalFile(childrenName,lawType,jieDu)
        },1500)
    }
    /**
     * 滚动条懒加载
     * @function scrollRequest
     * @param {Event} e -滚动条事件
     * @param {string} type -查询类型（1-“部分代替”,2-”被...代替“,3-"代替修订",4-"由...修订"," "-"代替"）
     * @param {string} lawType -法规类型(legal-法规，stander-标准)
     * @param {boolean} jieDu -是否解读
     * */
    scrollRequest =(e,type,lawType,jieDu)=>{
        if(this.state.isScroll){
            const {target} = e
            let childrenName = 'children' + type
            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                if(timer2){
                    clearTimeout(timer2)
                }
            const { pageNo } = this.state.scroll;
            const nextpageNo = pageNo + 1;
            this.setState({ 
                scroll: {
                    pageNo:nextpageNo,
                    pageSize:50
                } 
            });
            timer2 = setTimeout(()=>{
                this.requestLegalFile(childrenName,lawType,jieDu)          
            },800)
            
            }
        }else{
            return
        } 
    }
    /**
     * 清空模糊搜索的选项
     * @function resetChildren
     * @param {string} type -对应select的类编号（1-“部分代替”,2-”被...代替“,3-"代替修订",4-"由...修订"," "-"代替"）
     * */
    resetChildren = (type)=>{
        let childrenName = 'children' + type
        this.setState({
            [childrenName]:[]
        })
    }
    // 新增法律法规切换法律效力-法律效力为解读时,将部分表单内容隐藏且布局改变
    /**
     * 新增法律法规切换法律效力-法律效力为解读时,将部分表单内容隐藏且布局改变
     * @function onLegalCascaderChange
     * @param {object} value -表单数据
     * @param {array} selectOptions -选择的法规数据
     * */
    onLegalCascaderChange = (value, selectOptions) => {
        if (selectOptions[0].label == '解读') {
            this.setState({
                expLegal: true,
                formItemLayout: {
                    labelCol: {
                        xs: 24,
                        sm: 5
                    },
                    wrapperCol: {
                        xs: 24,
                        sm: 19
                    }
                },
                legalAjaxType:0
            })
        }else if(selectOptions[0].label == '标准'){
            this.setState({isStander:true,expLegal: false,legalAjaxType:2})
        }else if(selectOptions[0].label == '法律法规'){
            this.setState({isStander:false,expLegal: false,legalAjaxType:1})
        }else{
            this.setState({
                expLegal: false,
                formItemLayout: {
                    labelCol: {
                        xs: 24,
                        sm: 4
                    },
                    wrapperCol: {
                        xs: 24,
                        sm: 20
                    }
                }
            })
        }
    }
    /**
     * 获取法规类型提供给父组件更新使用
     * @function getLegalTypeForFather
     * @returns {number} 0-解读，1-法规，2-标准
     * */
    getLegalTypeForFather=()=>{
        return this.state.legalAjaxType
    }
    /**
     * 监听文件上传
     * @function normFile
     * @param {Event} e -上传事件
     * @returns {array} 已上传的文件列表
     * */
    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        if(e.file.type == 'application/msword' || e.file.type == 'application/vnd.ms-powerpoint'|| e.file.type == 'application/pdf' || !e.file.type || e.file.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || e.file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
            //实现单一文件
            e.fileList = e.fileList.slice(-1);
            return e && e.fileList;
        }else{
            message.warning('文件上传类型错误，请上传PDF、Word、PPT形式文件！')
            e.fileList = [];
            return e && e.fileList
        }
    };
    
    render() {
        /**  @constant {object} initFormState 传入表单初始数据 */
        const initFormState = this.state.initFormState
            ? this.state.initFormState
            : {}
        /**  @constant {object} textrow 文本域布局 */
        const textrow = {
            minRows: 4,
            maxRows: 6
        }
        const {getFieldDecorator} = this.props.form
        let _this = this
        /**
         * 解读文件上传
         * @constant
         * @type {Object} uploadReadProps -上传组件配置
         * @property {String} name -上传组件表单名称
         * @property {string} accept -接受可上传文件的类型
         * @property {Function} beforeUpload -文件上传前的函数
         * @property {Function} onRemove -监听移除文件
         * */
        const uploadReadProps = {
            name: 'files',
            accept:'application/msword,application/vnd.ms-powerpoint,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            beforeUpload(file){
                // if(file.type == 'application/msword' || file.type == 'application/vnd.ms-powerpoint'|| file.type == 'application/pdf'){
                    // console.log(file,"file")
                    let reader = new FileReader();
                    reader.onload = function(e){
                        let params = new FormData()
                        params.append('file',file)
                        params.append('filename',file.name)
                        params.append('type','0')
                        params.append('filehash',sparkMd5.hashBinary(e.target.result))
                        /** 校验文件本身在服务器上是否重复 */
                        // uploadCheck(sparkMd5.hashBinary(e.target.result)).then(res=>{
                        //     console.log(res)
                        // })
                        uploadOri(params).then(res=>{
                            if(res.status === 200 && res.data.state.value ===0){
                                _this.setState({
                                    jdUrl:res.data.result.fullOssurl?res.data.result.fullOssurl:'',
                                    jdName:res.data.result.fileName?res.data.result.fileName:'',
                                    jdId:res.data.result.id?res.data.result.id:'',
                                })
                                idflage2 = false
                                if(_this.state.legalAjaxType&&_this.state.legalAjaxType!==0){
                                    _this.props.form.setFieldsValue({jdname: res.data.result.fileName?res.data.result.fileName:''})
                                }else{
                                    _this.props.form.setFieldsValue({jdname:''})
                                }
                            }
                        })
                    }
                    reader.readAsBinaryString(file)
                /** 阻止antd自带的上传 */
                return false
            },
            /** 移除文件时清空url */
            onRemove(file){
                if(_this.props.type == 'editLegalForm'){
                    deleteFile({id:file.uid}).then(res=>{
                        if(res.data.state.value == 0){
                            _this.state.jdId = 'del'
                            _this.state.jdName = ''
                            _this.state.jdUrl = ''
                            idflage2 = false
                        }else{
                            message.warning('删除文件失败！')
                        }
                    })
                }else{
                    _this.state.jdId = 'del'
                    _this.state.jdName = ''
                    _this.state.jdUrl = ''
                    idflage2 = false
                }
            }
        };
        /**
         * 源文件上传
         * @constant
         * @type {Object} uploadOriProps -上传组件配置
         * @property {String} name -上传组件表单名称
         * @property {string} accept -接受可上传文件的类型
         * @property {Function} beforeUpload -文件上传前的函数
         * @property {Function} onRemove -监听移除文件
         * */
        const uploadOriProps = {
            name: 'file',
            accept:'application/msword,application/vnd.ms-powerpoint,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            beforeUpload(file){
                let reader = new FileReader();
                reader.onload = function(e){
                    /** target.result 该属性表示目标对象的DataURL */
                    let params = new FormData()
                    params.append('file',file)
                    params.append('filename',file.name)
                    params.append('type','0')
                    params.append('filehash',sparkMd5.hashBinary(e.target.result))
                    /** 校验文件本身在服务器上是否重复 */
                    // uploadCheck(sparkMd5.hashBinary(e.target.result)).then(res=>{
                    //     console.log(res)
                    // })
                    uploadOri(params).then(res=>{
                        if(res.status === 200 && res.data.state.value ===0){
                            _this.setState({
                                originalUrl:res.data.result.fullOssurl?res.data.result.fullOssurl:'',
                                originalName:res.data.result.fileName?res.data.result.fileName:'',
                                originalId:res.data.result.id?res.data.result.id:'',
                            })
                            idflage1 = false
                            _this.props.form.setFieldsValue({fileName:res.data.result.fileName?res.data.result.fileName:''})
                        }
                    })
                }
                /** 传入一个参数对象即可得到基于该参数对象的文本内容 */
                reader.readAsBinaryString(file)

                /**
                 * OSS直传，阿里云返回链接版上传、
                 * 采用utils.js文件中的upLoad函数进行上传，该函数为promise，可用then接收返回值
                 * res{服务器路径、文件本身名、服务器重新命名后的文件名}
                 */
                // if(file.type == 'application/msword' || file.type == 'application/vnd.ms-powerpoint'|| file.type == 'application/pdf'){
                //     utils.upLoad(file).then(res=>{
                //         if(res){
                //             _this.setState({
                //                 originalUrl:res.contentUrl,
                //                 originalName:res.name,
                //             })
                //             _this.props.form.setFieldsValue({fileName: res.name})
                //         }
                //     })
                // }else{
                //     _this.props.form.setFieldsValue({fileName: ''})
                // }
                /** 阻止antd自带的上传 */
                return false
            },
            /** 移除文件时清空url */
            onRemove(file){
                if(_this.props.type == 'editLegalForm'){
                    deleteFile({id:file.uid}).then(res=>{
                        if(res.data.state.value == 0){
                            _this.state.originalId = ''
                            _this.state.originalName = ''
                            _this.state.originalUrl = ''
                            idflage1 = false
                        }else{
                            message.warning('删除文件失败！')
                        }
                    })
                }else{
                    _this.state.originalId = ''
                    _this.state.originalName = ''
                    _this.state.originalUrl = ''
                    idflage1 = false
                }
            }
        }
        return (
            <Form className="form-item-mb0">
                {this.state.expLegal
                    ? ''
                    : <FormItem label="源文件" {...this.state.formItemLayout}>
                        {getFieldDecorator('uploadOri', {
                            valuePropName: 'fileList',
                            getValueFromEvent: this.normFile,
                            initialValue: initFormState.uploadOriInitial
                                ? initFormState.uploadOriInitial
                                : []
                        })(
                            <Upload {...uploadOriProps}>
                                <Button>
                                    <Icon type="upload"/>点击上传源文件
                                </Button>
                            </Upload>
                        )}
                    </FormItem>
                }
                <Form.Item label="法律效力" {...this.state.formItemLayout}>
                    {getFieldDecorator('lawClassId', {
                        initialValue: initFormState.lawClassId
                            ? initFormState.lawClassId
                            : [],
                        rules: [
                            {
                                type: 'array',
                                required: true,
                                message: '请选择法律效力'
                            }
                        ]
                    })(<Cascader
                        placeholder="请选择法律效力"
                        disabled={this.state.editDisabled}
                        options={this.state.legalType}
                        changeOnSelect
                        loadData={this.requestLegalType}
                        onChange={this.onLegalCascaderChange}/>
                        
                        )}
                </Form.Item>
                <FormItem label="文件名" {...this.state.formItemLayout}>
                    {getFieldDecorator('fileName', {
                        initialValue: initFormState.fileName
                            ? initFormState.fileName
                            : '',
                        rules: [
                            {
                                required: true,
                                message: '请输入文件名'
                            }
                        ]
                    })(<Input type="text" placeholder="请输入文件名"/>)
                  }
                </FormItem>
                {this.state.expLegal
                    ? ''
                    : <div>
                        <FormItem label="文号" {...this.state.formItemLayout}>
                            {getFieldDecorator('fileNo', {
                                initialValue: initFormState.fileNo
                                    ? initFormState.fileNo
                                    : '',
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入文号'
                                    }
                                ]
                            })(<Input type="text" placeholder="请输入文号"/>)
                        }
                        </FormItem>
                        <Form.Item label="所属项目" {...this.state.formItemLayout}>
                            {getFieldDecorator('projClassId', {
                                initialValue: initFormState.projClassId&&initFormState.projClassId.length
                                    ? initFormState.projClassId
                                    : [],
                                rules: [
                                    {
                                        type: 'array',
                                        required: true,
                                        message: '请选择所属项目'
                                    }
                                ]
                            })(
                                <Cascader 
                                placeholder="请选择所属项目" 
                                options={this.state.belongProject}
                                changeOnSelect
                                />)
                          }
                        </Form.Item>
                        <FormItem label="所属行业" {...this.state.formItemLayout}>
                            {getFieldDecorator('hyName', {
                                initialValue: initFormState.hyName
                                    ? initFormState.hyName
                                    : '',
                                rules: []
                            })(<Input type="text" placeholder="请输入所属行业"/>)
                          }
                        </FormItem>
                        <FormItem label="颁布部门" {...this.state.formItemLayout}>
                            {getFieldDecorator('department', {
                                initialValue: initFormState.department
                                    ? initFormState.department
                                    : '',
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入颁布部门'
                                    }
                                ]
                            })(<Input type="text" placeholder="请输入颁布部门"/>)
                          }
                        </FormItem>
                        <FormItem label="状态" {...this.state.formItemLayout}>
                            {getFieldDecorator('lawStatus', {
                                initialValue: initFormState.lawStatus
                                    ? initFormState.lawStatus
                                    : '',
                                rules: [
                                {
                                    required: true,
                                    message: '请选择状态'
                                }
                                ]
                            })(
                                <Select
                                    placeholder="请选择状态"
                                >
                                    <Option value={3}>即将实施</Option>
                                    <Option value={1}>现行</Option>
                                    <Option value={4}>废止</Option>
                                    <Option value={2}>作废</Option>
                                </Select>
                            )
                          }
                        </FormItem>
                        <FormItem label="颁布日期" {...this.state.formItemLayout}>
                            {getFieldDecorator('releaseDate', {
                                initialValue: initFormState.releaseDate
                                    ? moment(initFormState.releaseDate)
                                    : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择颁布日期'
                                    }
                                ]
                            })(<DatePicker format="YYYY-MM-DD"/>)
                          }
                        </FormItem>
                        <FormItem label="实施日期" {...this.state.formItemLayout}>
                            {getFieldDecorator('executeDate', {
                              initialValue: initFormState.executeDate
                                    ? moment(initFormState.executeDate)
                                    : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择颁布日期'
                                    }
                                ]
                            })(<DatePicker format="YYYY-MM-DD"/>)
                          }
                        </FormItem>
                        <FormItem label="失效日期" {...this.state.formItemLayout}>
                            {getFieldDecorator('cancelDate', {
                              initialValue: initFormState.cancelDate
                                    ? moment(initFormState.cancelDate)
                                    : null,
                            })(<DatePicker format="YYYY-MM-DD"/>)
                          }
                        </FormItem>
                        {
                            this.state.isStander?
                            <FormItem label="代替修订" {...this.state.formItemLayout}>
                                <FormItem>
                                    <Checkbox
                                        checked={this.state.isAllReplaceChecked}
                                        onChange={(e) => this.onCheckboxdChange(e, 'isAllReplaceChecked')}></Checkbox>
                                    <span className="ml10">代替</span>
                                    {getFieldDecorator('beReplace', {
                                        initialValue: initFormState.replaceOrRevisionr!=""
                                            ? initFormState.replaceOrRevisionrIds
                                            : []
                                    })(
                                        <Select
                                            disabled={!this.state.isAllReplaceChecked}
                                            className="w300 ml10"
                                            mode="multiple"
                                            labelInValue={true}
                                            placeholder="请选择"
                                            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                            onPopupScroll={(e)=>this.scrollRequest(e,'','stander')}
                                            onSearch={(value)=>this.handleAddFormSearchAll(value,'','stander')}
                                            onBlur={()=>this.resetChildren('')}
                                            filterOption={false}
                                            onChange={this.handleChange}>
                                             {this.state.children.map(item => (
                                                <Option key={item.key} value={item.value}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    )
                                }
                                </FormItem>
                                <FormItem>
                                    <Checkbox
                                        checked={this.state.isReplaceChecked}
                                        onChange={(e) => this.onCheckboxdChange(e, 'isReplaceChecked')}></Checkbox>
                                    <span className="ml5">部分代替</span>
                                    {getFieldDecorator('bePartReplace', {
                                        initialValue: initFormState.portionRevisionOrRevisionr!=""
                                            ? initFormState.portionRevisionOrRevisionrIds
                                            : []
                                    })(
                                        <Select
                                            disabled={!this.state.isReplaceChecked}
                                            className="w300 ml10"
                                            mode="multiple"
                                            placeholder="请选择"
                                            labelInValue={true}
                                            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                            onPopupScroll={(e)=>this.scrollRequest(e,'1','stander')}
                                            onSearch={(value)=>this.handleAddFormSearchAll(value,'1','stander')}
                                            onBlur={()=>this.resetChildren('1')}
                                            filterOption={false}
                                            onChange={this.handleChange}>
                                             {this.state.children1.map(item => (
                                                <Option key={item.key} value={item.value}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    )
                                }
                                </FormItem>
                                <FormItem>
                                    <Checkbox
                                        checked={this.state.isReplacedChecked}
                                        onChange={(e) => this.onCheckboxdChange(e, 'isReplacedChecked')}>
                                    </Checkbox>
                                    <span className="ml5">被</span>
                                    {getFieldDecorator('beReplaced', {
                                        initialValue: initFormState.beReplace!=""
                                            ? initFormState.beReplaceIds
                                            : []
                                    })(
                                        <Select
                                            disabled={!this.state.isReplacedChecked}
                                            className="w300 ml10"
                                            mode="multiple"
                                            placeholder="请选择"
                                            labelInValue={true}
                                            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                            onPopupScroll={(e)=>this.scrollRequest(e,'2','stander')}
                                            onSearch={(value)=>this.handleAddFormSearchAll(value,'2','stander')}
                                            onBlur={()=>this.resetChildren('2')}
                                            filterOption={false}
                                            onChange={this.handleChange}>
                                             {this.state.children2.map(item => (
                                                <Option key={item.key} value={item.value}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    )
                                }<span className="ml5">代替</span>
                                </FormItem>
                                <FormItem label="" {...this.state.formItemLayout}>
                                    {getFieldDecorator('revisionOrReplaceRemark', {
                                        initialValue: initFormState.revisionOrReplaceRemark!=""
                                            ? initFormState.revisionOrReplaceRemark
                                            : ''
                                    })(<TextArea autosize={textrow}/>)
                                }
                                </FormItem>
                            </FormItem>
                            :<FormItem label="代替修订" {...this.state.formItemLayout}>
                                <FormItem>
                                    <Checkbox
                                        checked={this.state.isEditChecked}
                                        onChange={(e) => this.onCheckboxdChange(e, 'isEditChecked')}></Checkbox>
                                    <span className="ml10">修订为</span>
                                    {getFieldDecorator('replace', {
                                        initialValue: initFormState.replaceOrRevisionr!=""
                                            ? initFormState.replaceOrRevisionrIds
                                            : []
                                    })(
                                        <Select
                                            disabled={!this.state.isEditChecked}
                                            className="w300 ml10"
                                            mode="multiple"
                                            placeholder="请选择"
                                            labelInValue={true}
                                            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                            onPopupScroll={(e)=>this.scrollRequest(e,'3','legal')}
                                            onSearch={(value)=>this.handleAddFormSearchAll(value,'3','legal')}
                                            onBlur={()=>this.resetChildren('3')}
                                            filterOption={false}
                                            onChange={this.handleChange}>
                                             {this.state.children3.map(item => (
                                                <Option key={item.key} value={item.value}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    )
                                }
                                </FormItem>
                                <FormItem>
                                    <Checkbox
                                        checked={this.state.isEditedChecked}
                                        onChange={(e) => this.onCheckboxdChange(e, 'isEditedChecked')}></Checkbox>
                                    <span className="ml5">由</span>
                                    {getFieldDecorator('replaced', {
                                        initialValue: initFormState.portionRevisionOrRevisionr!=""
                                            ? initFormState.portionRevisionOrRevisionrs
                                            : []
                                    })(
                                        <Select
                                            disabled={!this.state.isEditedChecked}
                                            className="w300 ml10"
                                            mode="multiple"
                                            placeholder="请选择"
                                            labelInValue={true}
                                            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                            onPopupScroll={(e)=>this.scrollRequest(e,'4','legal')}
                                            onSearch={(value)=>this.handleAddFormSearchAll(value,'4','legal')}
                                            onBlur={()=>this.resetChildren('4')}
                                            filterOption={false}
                                            onChange={this.handleChange}>
                                             {this.state.children4.map(item => (
                                                <Option key={item.key} value={item.value}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    )
                                    }<span className="ml5">修订</span>
                                </FormItem>
                                <FormItem label="" {...this.state.formItemLayout}>
                                    {getFieldDecorator('revisionOrReplaceRemark', {
                                        initialValue: initFormState.revisionOrReplaceRemark!=""
                                            ? initFormState.revisionOrReplaceRemark
                                            : ''
                                    })(<TextArea autosize={textrow}/>)
                                }
                                </FormItem>
                            </FormItem>
                        }
                        </div>
                  }
                <FormItem label="解读文件" {...this.state.formItemLayout}>
                    {getFieldDecorator('uploadRead', {
                        valuePropName: 'fileList',
                        getValueFromEvent: this.normFile,
                        initialValue: initFormState.uploadReadInitial
                            ? initFormState.uploadReadInitial
                            : []
                    })(
                        <Upload {...uploadReadProps}>
                            <Button>
                                <Icon type="upload"/>点击上传解读文件
                            </Button>
                        </Upload>
                    )}
                </FormItem>
                {this.state.expLegal
                    ? <FormItem
                            className="label-warp"
                            label="所属法律法规/标准"
                            {...this.state.formItemLayout}>
                            {getFieldDecorator('JDreadLawId', {
                                initialValue: initFormState.readLawIds
                                    ? initFormState.readLawIds
                                    : [],
                                rules: [
                                    (rule, value, callback, source, options) => {
                                        const errors = []
                                        if(value.length>1){
                                            errors.push(new Error('此项为单选项', rule.field))
                                            callback(errors)
                                            return false
                                        }else if(value.length ==0){
                                            errors.push(new Error('此项为必选项', rule.field))
                                            callback(errors)
                                            return false
                                        }else{
                                            return true
                                        }
                                        
                                    }
                                ]
                            })(
                                <Select
                                    className="w300 ml10"
                                    mode="multiple"
                                    placeholder="请选择"
                                    labelInValue={true}
                                    notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                    onPopupScroll={(e)=>this.scrollRequest(e,'5','legal',true)}
                                    onSearch={(value)=>this.handleAddFormSearchAll(value,'5','legal',true)}
                                    onBlur={()=>this.resetChildren('5')}
                                    filterOption={false}
                                    onChange={this.handleChange}>
                                        {this.state.children5.map(item => (
                                        <Option key={item.key}>{item.name}</Option>
                                    ))}
                                </Select>
                            )
                          }
                        </FormItem>
                    : <div>
                        <FormItem label="解读名称" {...this.state.formItemLayout}>
                            {getFieldDecorator('jdname', {
                                initialValue: initFormState.lawName
                                    ? initFormState.lawName
                                    : '',
                                rules: []
                            })(<Input type="text" placeholder="请输入解读名称"/>)
                          }
                        </FormItem>
                        <FormItem label="描述" {...this.state.formItemLayout}>
                            {getFieldDecorator('remake', {
                                initialValue: initFormState.remake
                                    ? initFormState.remake
                                    : ''
                            })(<TextArea autosize={textrow}/>)
                          }
                        </FormItem>
                    </div>
                  }
                  <FormItem label="法规详情链接" {...this.state.formItemLayout}>
                  {getFieldDecorator('webLink', {
                      initialValue: initFormState.webLink
                          ? initFormState.webLink
                          : '',
                      rules: []
                  })(<Input type="text" placeholder="请输入法规详情链接"/>)
                }
              </FormItem>
              
            </Form>

        )
    }
}
export default AddLegalForm = Form.create({})(AddLegalForm);