/*
 * @file: 查看文章页面 配合app端的查看
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import { artivlePublic } from '../../../Api'
/**
 * @classdesc: 查看文章页面
 */
class AddLook extends Component {
    constructor(props) {
        super(props);
        /**
         * @description: state自定义
         * @param {object} content 具体内容
         */
        this.state = {
            content: ''
        };
    }

    componentWillMount() {
        this.getArtivlePublic()
    }
    /**
     * @description: 处理数据
     */
    getArtivlePublic = () => {
        let _this = this
        let id = this.props.match.params.id
        artivlePublic(id).then(res => {
            if (res.data.state.value === 0) {
                _this.setState({
                    content: res.data.result.content
                })
                document.title = res.data.result.name
                var i = document.createElement('iframe');
                i.src = '/favicon.ico';
                i.style.display = 'none';
                i.onload = function () {
                    setTimeout(function () {
                        i.remove();
                    }, 20)
                }
                document.body.appendChild(i);
            }
        })
    }

    render() {
        return (
            <div className="home" style={{ padding: '10px 20px' }}>
                <div
                    dangerouslySetInnerHTML={{ __html: this.state.content }}
                    className='family-inherit-text'
                >
                </div>
            </div>
        )
    }
}
export default AddLook;