import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {getVideoList, delVideo, editVideo} from './../../../Api'
import {Icon, Table, Modal, Button, message} from 'antd'
import './index.css'
import VideoModal from './components/Vediomodal'
import SearchForm from './../../Home/component/form/Search'
import utils from "../../../Common/js/utils";
import STATUS from "../../../Common/js/status";
import moment from 'moment'
/**
 * @description 视频列表
 * @class Video
 * @extends {Component}
 * */
class Video extends Component {
    /**
     * 组件内数据
     * @param {Array} formList -搜索框配置
     * @param {Object} page -分页配置
     * @param {String} videoUrl -视频链接
     * @param {Boolean} isShowVideoModal -视频弹框显示状态
     */
    state = {
        formList: [{
            name: "name",
            type: 'input',
            placeholder: '视频名称搜索'
        }],
        page: {
            p: 1,
            pageSize: 30
        },
        videoUrl: null,
        isShowVideoModal: false
    }

    componentWillMount() {
        this.getList()
    }
    /**
     * 获取列表数据
     * @function getList -通过Api获取列表数据
     * @param {Object} params -查询参数
     */
    getList = (params) => {
        this.setState({
            params: params
        })
        let data = {...params, ...this.state.page}
        let _this = this
        getVideoList(data).then(res => {
            if (res) {
                /** 为表格数据加上唯一Key */
                res.data.result.rows && res.data.result.rows.map((item, index) => {
                    item.key = index + 1
                    return item
                })
                _this.setState({
                    tableList: res.data.result.rows ? res.data.result.rows : [],
                    pagination: utils.paginationSimple(res.data.result, (current, size) => {
                        _this.state.page.p = current
                        _this.state.page.pageSize = size
                        _this.getList(params)
                    }, (current, size) => {
                        _this.state.page.p = current
                        _this.state.page.pageSize = size
                        _this.getList(params)
                    }, Number(_this.state.page.pageSize),  Number(res.data.result.page),  Number(res.data.result.total),  Number(res.data.result.records))
                })
            }


        })
    }
    /**
     * 查看video播放入口
     * @function showVideoModal
     * @param {String} img -视频播放完整地址
     */
    showVideoModal = (img) => {
        this.setState({
            videoUrl: img,
            isShowVideoModal: true
        })
    }
    /**
     * 切换Modal状态
     * @function handleVisibleChange
     */
    handleVisibleChange = () => {
        this.setState({
            isShowVideoModal: !this.state.isShowVideoModal
        })
    }
    /**
     * 查看video详情入口
     * @function handleViewInformation
     * @param {Object} record -视频详细信息
     */
    handleViewInformation = (record) => {
        this.props.history.push({
            pathname: '/training/video/view',
            state: {record: record}
        });
    }
    /**
     * 编辑Video详情入口
     * @function handleEdit
     * @param {Object} record -视频详细信息
     */
    handleEdit = (record) => {
        this.props.history.push({
            pathname: '/training/video/edit',
            state: {type:'edit',record: record}
        });
    }
    /**
     * 新增Video详情入口
     * @function handleAdd
     * @param {Object} record -视频详细信息
     */
    handleAdd = (record) => {
        this.props.history.push({
            pathname: '/training/video/add',
            state: {type:'add',record:record}
        });
    }
    /**
     * 删除Video记录
     * @function handleDelete
     * @param {Object} record -视频详细信息
     */
    handleDelete = (record) => {
        let _this = this
        Modal.confirm({
            title: "删除提示",
            content: '确定要删除“'+record.name+'”吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                delVideo(record.id).then(res=>{
                    if(res.data.state.value==0){
                        message.success("删除成功！")
                        _this.getList(_this.state.params)
                    }else{
                        message.error(res.data.content)
                    }
                })
            }
        })
    }
    /**
     * 上架Video记录
     * @function handleUp
     * @param {Object} record -视频详细信息
     */
    handleUp=(record)=>{
        let _this = this
        Modal.confirm({
            title: "上架提示",
            content: '确定要上架“'+record.name+'”吗？',
            okText: '确定',
            okType: 'primary',
            cancelText: '取消',
            onOk() {
                editVideo({id:record.id,status:2}).then(res=>{
                    if(res.data.state.value==0){
                        message.success("上架成功！")
                        _this.getList(_this.state.params)
                    }else{
                        message.error(res.data.content)
                    }
                })
            }
        })
    }
    /**
     * 下架Video记录
     * @function handleDown
     * @param {Object} record -视频详细信息
     */
    handleDown=(record)=>{
        let _this = this
        Modal.confirm({
            title: "下架提示",
            content: '确定要下架“'+record.name+'”吗？',
            okText: '确定',
            okType: 'primary',
            cancelText: '取消',
            onOk() {
                editVideo({id:record.id,status:3}).then(res=>{
                    if(res.data.state.value==0){
                        message.success("下架成功！")
                        _this.getList(_this.state.params)
                    }else{
                        message.error(res.data.content)
                    }
                })
            }
        })
    }
    /**
     * 操作匹配.
     * @function optionInitial
     * @param {Array/Number} type -操作类型 （1:上架，2：下架，3：编辑，4:删除）
     * @param {Object} record -视频详细信息
     */
    optionInitial = (type,record)=>{
        if(Array.isArray(type)){
            let arr = []
            type.map(item=>{
                arr.push(this.matchOptions(item,record))
            })
            return arr
        }else{
            return this.matchOptions(type,record)
        }
    }

    /**
     * 操作匹配HTML Element.
     * @function matchOptions
     * @param {Array/Number} type -操作类型 （1:上架，2：下架，3：编辑，4:删除）
     * @param {Object} record -视频详细信息
     */
    matchOptions=(type,record)=>{
        let _this = this
        let dom = ''
        switch(type){
            case 1:
                dom =  <a onClick={() => _this.handleUp(record)} key={record.id+'b'} title="上架">
                    <span className='fa fa-angle-double-up mr10 ft-12-em' style={{ color: "#52c41a",fontSize:20 }}></span>
                </a>
                break;
            case 2:
                dom =  <a onClick={() => _this.handleDown(record)} key={record.id+'c'} title="下架">
                    <span className='fa fa-angle-double-down mr10' style={{ color: "#52c41a",fontSize:20 }}></span>
                </a>
                break;
            case 3:
                dom =  <a onClick={() => _this.handleEdit(record)} key={record.id+'d'} title="编辑">
                    <Icon type="edit" title="编辑" className="mr10 ft-12-em"/>
                </a>
                break;
             case 4:
                 dom =  <a onClick={() => _this.handleDelete(record)} key={record.id+'e'} title="删除">
                    <Icon type="delete" title="删除" className="mr10 red ft-12-em"/>
                </a>
                break;
            default:
                break;
        }
        return dom
    }
    /**
     * video状态匹配操作
     * @function statusOptions
     * @param {Array/Number} type -操作类型 （1:上架，2：下架，3：编辑，4:删除）
     * @param {Object} record -视频详细信息
     * @returns {HTMLElement}
     */
    statusOptions = (status,record)=>{
        let domArr = []
        switch(status){
            /** 未上架 - 删除、编辑 */
            case 1: //
                domArr.push(this.optionInitial([3,4],record))
                break;
            /** 已上架 - 下架、编辑 */
            case 2:
                domArr.push(this.optionInitial([2,3],record))
                break;
            /** 已下架 - 上架、编辑 */
            case 3:
                domArr.push(this.optionInitial(1,record))
                break;
            default:
                break;
        }
        return <div key={record.key+'a'}>
            {domArr}
        </div>
    }
    render() {
        let _this = this
        const columns = [
            {
                title: "序号",
                dataIndex: 'key',
                align: 'center',
                width: 30,
                render(text) {
                    return <span title={text}>{text}</span>
                },
            }, {
                title: "视频名称",
                dataIndex: 'name',
                align: 'center',
                width: 80,
                render(text, record) {
                    return <a onClick={() => _this.handleViewInformation(record)}>
                        <span title={text}>{text}</span>
                    </a>
                },

            }, {
                title: "类别",
                dataIndex: 'type',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={STATUS.TRAIN_TYPE[text]}>{STATUS.TRAIN_TYPE[text]}</span>
                },

            },
            {
                title: "状态",
                dataIndex: 'status',
                align: 'center',
                width: 30,
                render(text) {
                    return <span title={STATUS.VIDEO_STATUS[text]}>{STATUS.VIDEO_STATUS[text]}</span>
                },
            },
            {
                title: "视频时长",
                dataIndex: 'minutes',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            },
            {
                title: "上架时间",
                dataIndex: 'shelvesTime',
                align: 'center',
                width: 30,
                render(text) {
                    return <span title={text?moment(text).format('YYYY-MM-DD'):''}>{text?moment(text).format('YYYY-MM-DD'):''}</span>
                },

            }, {
                title: "视频",
                dataIndex: 'picList',
                align: 'center',
                width: 80,
                render(text, record) {
                    if(text){
                    return <div>
                        {text.length != 0 ? <a onClick={() => {
                            _this.showVideoModal(record.url)
                        }} className="pl10 pr10">
                            <img align="middle" alt={""} width={"100"} height={"50"} src={text[0].fullOssurl}/>
                        </a> : "暂无"}
                    </div>}
                    return ''
                },
            },

            {
                title: "观看数",
                dataIndex: 'hot',
                align: 'center',
                width: 30,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            },
            {
                title: "收藏数",
                dataIndex: 'collect',
                align: 'center',
                width: 30,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "操作",
                align: 'center',
                width: 50,
                render(text, record) {
                    return _this.statusOptions(record.status,record)
                },

            },
            {
                title: "视频url",
                dataIndex: 'url',
                align: 'center',
                width: 0,
                className: "dn",
            },
        ]
        const title = () => {
            return <div>
                <div className="fr">
                    <Button type="primary" onClick={() => this.handleAdd(null)}>
                        新增
                    </Button>
                </div>
                <div className="clear"></div>
            </div>
        }

        return (
            <div className="video">
                {/*搜索框*/}
                <SearchForm formList={this.state.formList} formSearchApi={(data) => this.getList(data)}/>
                {/*列表*/}
                <Table
                    className="mt20"
                    title={title}
                    bordered
                    columns={columns}
                    dataSource={this.state.tableList}
                    pagination={{...this.state.pagination, pageSize: this.state.page.pageSize}}
                />
                {/*视频播放Modal*/}
                <VideoModal
                    changeVisible={this.handleVisibleChange}
                    visibleState={this.state.isShowVideoModal}
                    videoUrl={this.state.videoUrl}
                />
            </div>
        )
    }
}

export default withRouter(Video)