/**
 * @file 页面：代理商管理
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Form, Input, Select, Button, Table, Icon, message } from 'antd'
import {Link} from 'react-router-dom'
import utils from '../../Common/js/utils'
import MonthRangePicker from './components/MonthRangePicker'
import TextModal from './components/TextModal'
import AjaxCascader from '../User/components/Common/AjaxCascader'
import { parseDate, statusMessage, pagination } from '../Training/Questions/features'
import { getAgentListData, enableAgent, disableAgent } from '../../Api'

const {Option} = Select

/**
 * @class
 * @extends React.Component
 */
class AgentManagement extends React.Component {
    /**
     * 组件状态值
     * @type {Object}
     */
    state = {
        pageNum:1,
        pageSize:30,
        searchParams: {}
    }

    /**
     * 重设表格数值为初始
     * @type {Function}
     */
    resetFields = () => this.props.form.resetFields()

    /**
     * 处理当前数据行表格列数据的并最终返回渲染值的函数
     * @typedef {Function} tableColumnRenderFunction
     * @param {String} text 当前数据行对应的该表格列的值
     * @param {Object} record 当前数据行
     * @returns {Number | String | HTMLDocument | HTMLCollection}
     */
    /**
     * 表格列
     * @typedef {Object} tableColumn
     * @property {String} title 表头标题
     * @property {String} dataIndex 表头取的字段名称
     * @property {String} key 表头的key值 
     * @property {tableColumnRenderFunction} render 
     */
    /**
     * 表格列数据
     * @const {Array.<tableColumn>} columns
     */
    columns = [
        {
            title: '代理商名称',
            dataIndex: 'agentName',
            key: 'agentName'
        },
        {
            title: '代理商账号',
            dataIndex: 'token',
            key: 'account'
        },
        {
            title: '推广码',
            dataIndex: 'promoteCode',
            key: 'promocode'
        },
        {
            title: '代理属性',
            dataIndex: 'agentTypeName',
            key: 'proxyattr'
        },
        {
            title: '代理方式',
            dataIndex: 'agentUniqueStatusName',
            key: 'proxymethod'
        },
        {
            title: '代理区域',
            dataIndex: 'agentAreasStr',
            key: 'proxyarea'
        },
        {
            title: '注册时间',
            dataIndex: 'createDate',
            key: 'regtime'
        },
        {
            title: '结算状态',
            dataIndex: 'balanceStatusName',
            key: 'status'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <div style={{fontSize:'1.2em'}}>
                    <Link to={{pathname: `/agent/management/detail/${record.id}`}}><Icon type={'eye'} className={"mr10"} style={this.props.style} title={'详情'} theme='twoTone' twoToneColor={"#1890ff"} /></Link>                    
                    <Link to={{pathname: `/agent/management/edit/${record.id}`}}><Icon type={'edit'} className={"mr10"} style={this.props.style} title={'编辑'} theme='twoTone' twoToneColor={"#52c41a"} /></Link>
                    <TextModal iconType={record.isForbidden ? "key" : "stop"} theme={record.isForbidden ? 'outlined' : 'twoTone'} iconColor={record.isForbidden ? "#13c2c2" : "#f5222d"} content={record.isForbidden ? "是否要启用该代理商？" : "是否要禁用该代理商？"} 
                               title={record.isForbidden ? "启用" : "禁用"} postOk={()=>{this.toggleLocked(record)}}/>
                </div>
            )
        }
    ]
    /**
     * 组件载入函数
     * @type {Function}
     */
    componentDidMount() {
        if (this.props.location.state) {
            switch (this.props.location.state.action) {
                case 'add':
                case 'edit':
                    statusMessage()
                    break
                default:
                    break
            }
        }
        
        this.requestTableList()
    }

    /**
     * 请求表格数据后将有关数据存入state
     * @type {Function}
     * @param {Object} res 请求的结果
     * @param {Number} pageNum 页码
     * @param {Number} pageSize 每页记录数
     */
    postRequest = (res, pageNum, pageSize) => {
        if (res.status === 200 && res.data.state.value == 0){
            utils.tableListKey(res.data.result.rows, pageNum, pageSize)
            this.setState({
                total: Number(res.data.result.records),
                pageNum,
                pageSize,
                agentManagementData: res.data.result.rows
            })
        }
    }

    /**
     * 请求表格数据
     * @type {Function}
     * @param {Number} pageNum 页码
     * @param {Number} pageSize 每页记录数
     * @param {Object} finalValue 搜索的条件
     */
    requestTableList = (pageNum, pageSize, finalValue)=>{
        const action = finalValue ? 'search' : 'get'
        pageNum = pageNum ? pageNum : this.state.pageNum
        pageSize = pageSize ? pageSize : this.state.pageSize
        finalValue = finalValue ? finalValue : this.state.searchParams
        getAgentListData({ pageNum, pageSize, ...finalValue }).then((res)=>{
            console.log(res, action)
            this.setState({searchParams: finalValue})
            this.postRequest(res, pageNum, pageSize)
        }, (err) => {
            statusMessage(err)
        })
    }

    /**
     * 搜索
     * @type {Function}
     * @param {Object} params 搜索的条件
     */
    searchTableList = (value)=>{
        const {agent, checkouttime, proxyarea, status} = value

        // value： keyword——agent，beginTime——checkouttime[0]，endTime——checkouttime[1]，agentAreaId——proxyarea.slice(-1)[0]，balanceStatus——status
        const finalValue = {keyword: agent ? agent: '', beginTime: checkouttime.length > 0 ? parseDate(checkouttime[0]) : '', endTime: checkouttime.length > 0 ? parseDate(checkouttime[1]) : '', agentAreaId: proxyarea && proxyarea.length > 0 ? proxyarea.slice(-1)[0] : '', balanceStatus: status ? status : ''}
        // console.log(finalValue)

        this.requestTableList(1, null, finalValue)

    }

    /** 根据数据进行启用或禁用
     * @type {Function}
     * @param {Object} data 数据
     */
    toggleLocked = (data)=>{
        const toggle = data.isForbidden ? enableAgent : disableAgent
        toggle({ agentBusinessId: data.agentBusinessId }).then((res)=>{
            console.log(res,"toggleLocked")
            statusMessage()
            this.requestTableList()            
        }, reason => {
            statusMessage(reason)
        })
    }
    
    /**
     * 提交后事件
     * @type {Function}
     * @param {Event} e 提交事件 
     */
    handleSubmit = (e) => {
        let value = this.props.form.getFieldsValue()
        console.log(value)
        this.searchTableList(value)
    }

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div id="agentList">
                <Form layout="inline" onKeyDown={this.handleKey}>
                    <Form.Item>
                        {getFieldDecorator(`agent`)(<Input placeholder="代理商名称/代理商账号" />)}
                    </Form.Item>
                    <Form.Item label={'结算状态：'}>
                        {getFieldDecorator(`status`)(
                        <Select placeholder="请选择">
                            <Option value={1}>都未结算</Option>
                            <Option value={2}>部分已结算</Option>
                            <Option value={3}>都结算完成</Option>
                        </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={'结算时间：'}>
                        {getFieldDecorator(`checkouttime`, {initialValue: []})(
                        <MonthRangePicker />
                        )}
                    </Form.Item>
                    <Form.Item label={'代理区域：'}>
                        {getFieldDecorator(`proxyarea`)(
                        <AjaxCascader
                            maxLevel={3}
                            expandTrigger="hover"
                        />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSubmit}>搜索</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={this.resetFields}>重置</Button>
                    </Form.Item>
                </Form>
                <Table
                    dataSource={this.state.agentManagementData}
                    pagination={pagination(this.state.pageSize, this.state.pageNum, this.state.total, (page, size) => {this.requestTableList(page, size)})}
                    bordered
                    className="mt20"
                    columns={this.columns}
                    footer={()=>{
                        return <Link to="/agent/management/add"><Icon type={"plus-circle"} className='f20' theme={"filled"} /></Link>
                    }}>
                </Table>
            </div>
        )
    }
}

/**
 * 创建表单
 * @const {Form} AgentManagementForm
 */
const AgentManagementForm = Form.create()(AgentManagement)

export default AgentManagementForm;