import React, {Component} from 'react'
import { Result, Button } from 'antd'
// import {Link} from 'react-router-dom';
// import Liao from './images/liao.jpg';

class ErorrPage extends Component {
    returnFalse = () => {
        window.location.href='/home'
    }
    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle="对不起，您访问的页面不存在！"
                extra={<Button type="primary" onClick={this.returnFalse}>返回首页</Button>}
            />
        );
    }
}

export default ErorrPage;