import React from 'react'
import { getAreaOptions } from '../../../../Api/index'
import { Cascader } from 'antd'

class AjaxCascader extends React.Component {
    state = {
        areaOptions: [],
        loadedNodes: []
    };
    componentDidMount() {
        this.requestArea()
    }
    componentDidUpdate(prevProps) {
        if (this.props.value && this.props.value.length > 0 && (!prevProps.value || !prevProps.value.length)) {
            for (let i = 0; i < this.props.value.length; i++) {
            let currentPlace = this.props.value[i]
            if (!(this.state.loadedNodes.indexOf(currentPlace)+1) && i < this.props.maxLevel-1) {
                this.requestArea({value: currentPlace})
            }
          }
        }
      }
    restore = (orignalData, listData)=>{
        // 逐级递归寻找现有和原有区域数据关联性
        if(orignalData.length){
            orignalData.map(item=>{
                if(item.children && item.children.length){
                    this.restore(item.children,listData)
                } else {
                    if(item.value == listData[0].pid){
                        item.children = listData
                        return
                    }
                }
            })
            this.setState({
                areaOptions:orignalData
            })
            return
        } else {
            this.setState({
                areaOptions:listData
            })
            return
        }
    }
    requestArea = (itemValue)=>{
        let pid = itemValue&&itemValue.value?itemValue.value:''
        getAreaOptions({parentId:pid}).then(res=>{
            let areaOptions = []
            if(res.status === 200 && res.data.state.value === 0){
                // 格式化区域级联数据
                res.data.result.map(item=>{
                    areaOptions.push({
                        value:item.id,
                        key:item.id,
                        pid:item.parentId,
                        label:item.name,
                        isLeaf:item.level==this.props.maxLevel?true:false,
                    })
                })
                // 插入区域分类数据
                if(areaOptions.length){
                    this.restore(this.state.areaOptions,areaOptions)
                }
                this.setState(state => {
                    let loadedNodes = state.loadedNodes.concat(pid)
                    return {loadedNodes}
                })
            }
        })
    }
    //区域-loaddata异步获取级联数据
    getArea = (a)=>{
        this.requestArea(a[a.length-1])
    }

    render() {
        return (
            <Cascader
                style={this.props.style}
                placeholder={this.props.placeholder||"--请选择省市区--"}
                value={this.props.value}
                options={this.state.areaOptions} 
                changeOnSelect
                loadData={this.getArea}
                onChange={this.props.onChange}
                expandTrigger={this.props.expandTrigger}
            />
        )
    }
}

export default AjaxCascader