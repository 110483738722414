/**
 * @file 图标对话框组件、图标渲染组件
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { statusMessage } from '../../Training/Questions/features'
import {Icon, Modal} from 'antd'

/**
 * 图标渲染组件
 * @class
 * @extends React.Component
 */
class IconSelect extends React.Component {
    render = () => this.props.theme == 'twoTone' ? <Icon type={this.props.type} onClick={this.props.showModal} title={this.props.title} className={this.props.className} style={this.props.style} theme='twoTone' twoToneColor={this.props.iconColor} /> : <Icon type={this.props.type} onClick={this.props.showModal} className={this.props.className} title={this.props.title} style={this.props.iconColor ? Object.assign((this.props.style ? this.props.style : {}), {color: this.props.iconColor}) : this.props.style} theme={this.props.theme} />
}

/**
 * 图标对话框组件
 * @class
 * @extends React.Component
 */
class TextModal extends React.Component {
    /**
     * 组件状态
     * @type {Object}
     */
    state = { visible: false }

    /**
     * 点击确认按钮的事件
     * @type {Function}
     */
    onOk = () => {
        if (this.props.onOk) {
            this.props.onOk().then(value => {

                if (this.props.okPromise) {
                    this.props.okPromise(value).then(res => {
                        statusMessage()
                        this.hideModal()
                        if (this.props.postOk) {
                            this.props.postOk(value)
                        }
                    }, reason => {
                        statusMessage(typeof(reason) == 'string' ? reason : '请填写完整')
                    })
                } else {
                    statusMessage()
                    if (this.props.postOk) {
                        this.props.postOk(value)
                    }
                    this.hideModal()
                }
                
            }, reason => {
                statusMessage(typeof(reason) == 'string' ? reason : '请填写完整')
            })
        }
        else {
            this.hideModal()
            if (this.props.postOk){
                this.props.postOk()
            }
        }
    } 

    /**
     * 点击取消按钮的事件
     * @type {Function}
     */
    onCancel = () => {
        this.props.onCancel();
        this.hideModal();
    }

    /**
     * 显示对话框
     * @type {Function}
     */
    showModal = () => {
        this.setState({
        visible: true,
        });
    };

    /**
     * 隐藏对话框
     * @type {Function}
     */
    hideModal = () => {
        this.setState({
            visible: false,
        })
        if (this.props.hideModal) {
            this.props.hideModal()
        }
    };

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => <div style={{display: 'inline'}}>
            <IconSelect type={this.props.iconType} showModal={this.showModal} title={this.props.title} className={this.props.className} theme={this.props.theme} style={this.props.style} iconColor={this.props.iconColor} />
            <Modal
            ref={this.modal}
            title={this.props.title||"Modal"}
            visible={this.state.visible}
            onOk={this.onOk}
            onCancel={this.hideModal}
            autoFocusButton={'ok'}
            okText={this.props.okText || "确认"}
            cancelText={this.props.cancelText || "取消"}
            >
            {this.props.content}
            </Modal>
        </div>
}
export default TextModal