/**
 * @description 体检报告
 * @author yzx yangzhixin177@163.com
 */
import React from 'react'
import {Card, Button, Form, Input, message,Select, Modal,Radio,Table,Icon,Spin} from 'antd'
import moment from 'moment'
import {deleteUserTijian, deleteUserweixin,getHealthCenterReportInfo,getWechatListData,regetUserTijian,saveTijian,getHealthCenterReportListData} from '../../Api'
import utils from '../../Common/js/utils'
const FormItem = Form.Item;
const Option = Select.Option;
/**
 * @description 组件-体检报告
 * @export
 * @class PermissManage
 * @extends {React.Component}
 */
export default class PermissManage extends React.Component{
    /**
     * @param {Object} params 页码，每页大小
     * @param {Object} params1 页码，每页大小
     * @memberof PermissManage
     */
    state = {
        params:{
            pageNum:1,
            pageSize:10
        },
        params1:{
            pageNum:1,
            pageSize:10
        },
        loading:false,
        loading1:false
    }
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof PermissManage
     */
    renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({ 
            title:title?title:'警告',
            content:content?content:''
        })
    }
    /**
     * @description 请求基础信息列表
     * @function requestList
     * @param {string} keyword 关键字
     * @memberof PermissManage
     */
    requestList = (keyword)=>{
        let _this = this
        let value=keyword?keyword:''
        getWechatListData({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize,keyword:value}).then((res)=>{
            // console.log(res.data)
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        // console.log(size,"size")
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList(keyword)
                    }, (current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList(keyword)
                    }, res.data.result.pageSize)
                })
            }
        }).catch((err)=>{
            // console.log(err)
        })
    }
    /**
     * @description 请求体检信息列表
     * @function requestList
     * @param {string} keyword 关键字
     * @memberof PermissManage
     */
    requestList1 = (keyword)=>{
        let _this = this
        let value=keyword?keyword:''
        // console.log(value)
        getHealthCenterReportListData({pageNum: _this.state.params1.pageNum, pageSize: _this.state.params1.pageSize,keyword:value}).then((res)=>{
            // console.log(res.data.result)
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.params1.pageNum,this.state.params1.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource1: res.data.result.rows,
                    page: res.data.result.page,
                    pagination1: utils.pagination(res.data,(current,size)=>{
                        // console.log(size,"size")
                        _this.state.params1.pageNum=current
                        _this.state.params1.pageSize=size
                        this.requestList1(keyword)
                    }, (current,size)=>{
                        _this.state.params1.pageNum=current
                        _this.state.params1.pageSize=size
                        this.requestList1(keyword)
                    }, res.data.result.pageSize)
                })
            }
        }).catch((err)=>{
            // console.log(err)
        })
    }
    /**
     * @description 表格搜索-基础信息
     * @function handleSearch
     * @memberof PermissManage
     */
    handleSearch = ()=>{
        // console.log(params)
        this.setState({
            loading:true
        })
        let _this = this
        let formData = this.searchRef.handleSearch()
        let value=formData.NameORNo?formData.NameORNo:''
        getWechatListData({pageNum: 1, pageSize: _this.state.params.pageSize,keyword:value}).then((res)=>{
            //  console.log(res,"search")
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        // console.log(size,"size")
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList(value)
                    }, (current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList(value)
                    }, res.data.result.pageSize),
                    loading:false
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    /**
    * @description 表格搜索-体检信息
    * @function handleSearch1
    * @memberof PermissManage
    */
    handleSearch1 = ()=>{
        let _this = this
        let formData = this.searchRef1.handleSearch1()
        let value=formData.dNameORNo?formData.dNameORNo:''
        this.setState({
            loading1:true
        })
        getHealthCenterReportListData({pageNum: 1, pageSize: _this.state.params1.pageSize,keyword:value}).then((res)=>{
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.params1.pageNum,this.state.params1.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource1: res.data.result.rows,
                    page: res.data.result.page,
                    pagination1: utils.pagination(res.data,(current,size)=>{
                        _this.state.params1.pageNum=current
                        _this.state.params1.pageSize=size
                        this.requestList1(value)
                    }, (current,size)=>{
                        _this.state.params1.pageNum=current
                        _this.state.params1.pageSize=size
                        this.requestList1(value)
                    }, res.data.result.pageSize),
                    loading1:false
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    /**
    * @description 异常处理
    * @function handleSearchEsp
    * @memberof PermissManage
    */
    handleSearchEsp = ()=>{
        let _this = this
        let formData = this.EspRef.handleSearchEsp()
        let value=formData.tijianNo?formData.tijianNo:''
        getHealthCenterReportListData({pageNum: _this.state.params1.pageNum, pageSize: _this.state.params1.pageSize,keyword:value}).then((res)=>{
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.params1.pageNum,this.state.params1.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource1: res.data.result.rows,
                    page: res.data.result.page,
                    pagination1: utils.pagination(res.data,(current,size)=>{
                        _this.state.params1.pageNum=current
                        _this.state.params1.pageSize=size
                        this.requestList1(value)
                    }, (current,size)=>{
                        _this.state.params1.pageNum=current
                        _this.state.params1.pageSize=size
                        this.requestList1(value)
                    }, res.data.result.pageSize)
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    /**
     * @description react生命周期，在渲染前调用
     * @function componentWillMount
     * @memberof PermissManage
     */
    componentWillMount =()=>{
        this.requestList()
        this.requestList1()
    }
    /**
     * @description 清空表格选中
     * @function resetCheckedList
     * @memberof PermissManage
     */
    resetCheckedList = ()=>{
        this.setState({
            selectedRowKeys:[],
            selectedRows:null,
            selectedIds:[],
            selectItem:{},
        })
    }
    /**
     * @description 点击行事件
     * @function onRowClick
     * @param {Object} record 当前行对象 
     * @param {Number} index 当前行下标
     * @memberof PermissManage
     */
    onRowClick = (record,index)=>{
        let selectKey = [index];
        this.setState({
            selectedRowKeys:selectKey,
            selectedItem: record
        })
    } 
    /**
     * @description 删除微信关系
     * @function handleDelete
     * @param {Object} record 当前行对象 
     * @memberof PermissManage
     */
    handleDelete=(record)=>{
        // console.log(record.idcard)
        let idCard=record.idcard
        let _this = this
        Modal.confirm({
            title:'删除微信关系',
            content: `是否确定要删除？`,
            onOk:()=>{
                deleteUserweixin({thirdIdcardId:record.id}).then((res)=>{
                    if(res.data.state.value == 0){
                        _this.setState({
                            isVisible:false
                        })
                        message.success('删除成功');
                        _this.requestList(idCard);
                    }
                    else{
                        this.renderModalSimple('warning','错误','删除失败，请重新删除!')
                    }
                })
            }
        })
    }
    /**
     * @description 删除体检单
     * @function handleDelete
     * @param {Object} record 当前行对象 
     * @memberof PermissManage
     */
    handleDelete1=(record)=>{
        // console.log(record)
        let _this = this
        // console.log(record.reportNo)
        Modal.confirm({
            title:'删除体检单号',
            content: `是否确定要删除？`,
            onOk:()=>{
                deleteUserTijian({tijianId:record.reportNo}).then((res)=>{
                    if(res.data.state.value == 0){
                        _this.setState({
                            isVisible:false
                        })
                        message.success('删除成功');
                        _this.requestList1(record.reportNo);
                    }
                    else{
                        this.renderModalSimple('warning','错误','删除失败，请重新删除!')
                    }
                })
            }
        })
    }
    /**
     * @description 点击事件-编辑
     * @function handleDelete
     * @param {string} type 类型 
     * @param {Object} record 当前行对象 
     * @memberof PermissManage
     */
    handleEditChart=(type,record)=>{
        this.setState({
            title:'编辑',
            isReportVisible:true,
            reportInfo:record,
            type
        })
        // console.log(type) 
    }
    /**
     * @description 弹框点击确定事件
     * @function handleSubmit
     * @memberof PermissManage
     */
    handleSubmit = ()=>{
        let data = this.ReportEditForm.props.form.getFieldsValue();
        let type = this.state.type;
        let reportInfo=this.state.reportInfo;
        this.ReportEditForm.props.form.validateFields((err)=>{
            if(!err){
        if(type=='edit'){
            let tiJianLb=(data.tiJianLb==1?'常规体检':'职业病体检')
            saveTijian({id:reportInfo.id,reportNo:reportInfo.reportNo,reportFullname:data.reportFullname,reportMobile:data.reportMobile,reportIdcard:data.reportIdcard,reportCompany:data.reportCompany,tiJianLb:tiJianLb,isExpose:data.isExpose,ciKaLbbm:data.ciKaLbbm}).then((res)=>{
            // console.log(res)
            if(res.data.state.value == 0){
                this.setState({
                    isReportVisible:false
                })     
                message.success('编辑成功');
                this.requestList1(reportInfo.reportNo);
                this.ReportEditForm.resetValue();
            }else{
                this.renderModalSimple('warning','错误','编辑失败') 
            }
            })
        }
    }
    }).catch((e) => {})
}
    render(){
        const columns = [
            {
                title: '',
                key: 'key',
                dataIndex: 'key'
            },
            {
                title: '头像',
                key:'thirdFaceUrl',
                dataIndex: 'thirdFaceUrl',
                render:(url)=>{
                     return <img src={url} alt="" style={{width:50,height:50}}></img>
                },
            }, {
                title: '姓名',
                key: 'idcardFullName',
                dataIndex: 'idcardFullName'
            },{
                title: '手机号',
                key: 'thirdMobile',
                dataIndex: 'thirdMobile',
                render(text) {
                    return <span title={text}>{text}</span>
                },
            },{
                title: '身份证号码',
                key: 'idcard',
                dataIndex: 'idcard',
                render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: '微信名称',
                key: 'thirdNickname',
                dataIndex: 'thirdNickname',
                render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: '创建时间',
                key: 'createDate',
                dataIndex: 'createDate',
                render(text) {
                    if(text){
                        return <span title={moment(text).format('YYYY-MM-DD HH:MM')}>{moment(text).format('YYYY-MM-DD HH:MM')}</span>
                    }else{
                        return '-'
                    }
                },
            },{
                title: '操作',
                dataIndex: 'opera', render: (type,record) => {
                        return<div>
                        <a title="删除" onClick={()=>this.handleDelete(record)}> <Icon type="delete"  theme="twoTone" twoToneColor="#dd715a" style={{fontSize:20}}/></a>
                       </div>
            }
        }
        ];
        const columns1 = [
            {
                title:'',
                key:'key',
                dataIndex: 'key'
            },
            {
                title: '体检单',
                key:'reportNo',
                dataIndex: 'reportNo'
            },  {
                title: '姓名',
                key: 'reportFullname',
                dataIndex: 'reportFullname'
            },{
                title: '手机号',
                key: 'reportMobile',
                dataIndex: 'reportMobile',
            },{
                title: '身份证号码',
                key: 'reportIdcard',
                dataIndex: 'reportIdcard',
                render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: '公司',
                key: 'reportCompany',
                dataIndex: 'reportCompany',
                render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: '体检类型',
                key: 'tiJianLb',
                dataIndex: 'tiJianLb',
                render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: '查询密码',
                key: 'ciKaLbbm',
                dataIndex: 'ciKaLbbm',
            },
            {
                title: '体检日期',
                key: 'reportDate',
                dataIndex: 'reportDate',
                render(text) {
                    if(text){
                        return <span title={moment(text).format('YYYY-MM-DD')}>{moment(text).format('YYYY-MM-DD')}</span>
                    }else{
                        return '-'
                    }
                },
            },
            {
                title: '创建时间',
                key: 'createDate',
                dataIndex: 'createDate',
                render(text) {
                    if(text){
                        return <span title={moment(text).format('YYYY-MM-DD HH:MM')}>{moment(text).format('YYYY-MM-DD HH:MM')}</span>
                    }else{
                        return '-'
                    }
                },
            },
                {
                title: '操作',
                dataIndex: 'opera', render: (type,record) => {
                        return<div>
                        <a title="编辑" onClick={()=>this.handleEditChart('edit',record)}> <Icon type="edit" style={{marginRight:8,fontSize:20,color:"#52c41a"}}/></a> 
                        <a title="删除" onClick={()=>this.handleDelete1(record)}> <Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{fontSize:20}}/></a>
                       </div>
            }
        }
        ];
        return (    
            <div className="mb11">
            <Card 
                className="CardBorderNone"
                title="基础信息">
                <div style={{marginTop:20}}>
                <SearchForm handleSearch={this.handleSearch} wrappedComponentRef={(inst) => this.searchRef = inst}/>
                </div>
                <Spin spinning={this.state.loading} tip="Loading..." delay={500}>
           
                <Table
                        bordered
                        onRow={(record,index) => {
                            return {
                                onClick:()=>{
                                    this.onRowClick(record,index);
                                }
                            };
                        }}
                        columns={columns}
                        dataSource={this.state.dataSource}
                        rowKey={(record,index)=> index}
                        pagination={{...this.state.pagination, pageSize: this.state.params.pageSize}}
                    /> 
                 </Spin>
            </Card>
            <Spin spinning={this.state.loading1} tip="Loading..." delay={500}>
            <Card 
                className="CardBorderNone"
                title="体检查询" >
                <div style={{marginTop:20}}>
                <SearchForm1 handleSearch1={this.handleSearch1} wrappedComponentRef={(inst) => this.searchRef1 = inst}/>
                </div>
                <Table
                        bordered
                        onRow={(record,index) => {
                            return {
                                onClick:()=>{
                                    this.onRowClick(record,index);
                                }
                            };
                        }}
                        columns={columns1}
                        rowKey={record=>record.id}
                        dataSource={this.state.dataSource1}
                        pagination={{...this.state.pagination1, pageSize: this.state.params1.pageSize}}
                    /> 
                  <Modal
                    title='编辑'
                    visible={this.state.isReportVisible}
                    onOk={()=>{this.handleSubmit()}}
                    onCancel={()=>{
                        this.ReportEditForm.props.form.resetFields();
                        this.setState({
                            isReportVisible:false,
                            roleInfo:''
                        })
                    }}
                >
                  <ReportEditForm reportInfo={this.state.reportInfo} type={this.state.type} wrappedComponentRef={(inst) => this.ReportEditForm = inst }/>
                 </Modal>
                 </Card>
            </Spin>
            <Card 
                className="CardBorderNone"
                title="体检数据异常处理" >
                <div style={{marginTop:20}}>
                <HandleEspForm handleSearchEsp={this.handleSearchEsp} wrappedComponentRef={(inst) => this.EspRef = inst}/>
                </div>
            </Card>
            </div>
                
        );
    }
}
/**
 * @description 组件-搜索基础数据
 * @class SearchForm
 * @extends {React.Component}
 */
class SearchForm extends React.Component{
    handleSearch=()=>{ //调用父组件方法
        return this.props.form.getFieldsValue()
    }
      handleReset = ()=>{
        this.props.form.resetFields();
      }
    
    render(){
        const {getFieldDecorator} = this.props.form;
        return (
            <Form layout="inline"> 
            <FormItem>
                {getFieldDecorator('NameORNo')(<Input
                placeholder="请输入姓名/手机号/身份证/微信昵称" prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                style={{ width: 300,marginBottom:20}}
                />)}
                </FormItem>
            <FormItem>
                <Button type="primary" onClick={this.props.handleSearch}>
                        搜索
                </Button>
            </FormItem>
            </Form>
        );
    }
  }
SearchForm = Form.create({})(SearchForm);

/**
 * @description 组件-搜索体检单数据
 * @class SearchForm1
 * @extends {React.Component}
 */
class SearchForm1 extends React.Component{
      handleReset = ()=>{
        this.props.form.resetFields();
      }
      handleSearch1=()=>{ //调用父组件方法
        return this.props.form.getFieldsValue()
    }
    render(){
        const {getFieldDecorator} = this.props.form;
        return (
            <Form layout="inline"> 
            <FormItem>
                {getFieldDecorator('dNameORNo')(<Input
                placeholder="请输入手机号/姓名/身份证号码/体检单号" prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                style={{ width: 300,marginBottom:20}}
                />)}
                </FormItem>
            <FormItem>
                <Button type="primary" onClick={this.props.handleSearch1}>
                        搜索
                </Button>
            </FormItem>
            </Form>
        );
    }
  }
SearchForm1 = Form.create({})(SearchForm1);

/**
 * @description 组件-体检单编辑弹框
 * @class ReportEditForm
 * @extends {React.Component}
 */
class ReportEditForm extends React.Component{

    resetValue = ()=>{
        this.props.form.resetFields()
    }
    getState = (state)=>{
        return {
        }[state]
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 12}
        };
        const reportInfo = this.props.reportInfo || {};
        const type = this.props.type;
        return (
            <Form layout="horizontal">
                <FormItem label="体检单" {...formItemLayout}>
                    {
                        getFieldDecorator('reportNo',{
                            initialValue:type=='edit'?reportInfo.reportNo:1,
                            rules: [{ required: true, message: '不可修改'},]
                          })(
                        <Input type="text" readOnly="readonly"/>
                    )}
                </FormItem>
                <FormItem label="姓名" {...formItemLayout}>
                    {
                        getFieldDecorator('reportFullname',{
                            initialValue:type=='edit'?reportInfo.reportFullname:[],
                            rules: [{ required: true, message: '输入姓名'},]
                          })(
                        <Input type="text"/>
                    )}
                </FormItem>
                <FormItem label="手机号" {...formItemLayout}>
                    {
                        getFieldDecorator('reportMobile',{
                            initialValue:type=='edit'?reportInfo.reportMobile:[],
                            rules: [{ required: true, message: '输入手机号'},]
                          })(
                        <Input type="text"/>
                    )}
                </FormItem>
                <FormItem label="身份证" {...formItemLayout}>
                    {
                        getFieldDecorator('reportIdcard',{
                            initialValue:type=='edit'?reportInfo.reportIdcard:[],
                            rules: [{ required: true, message: '输入身份证'},]
                          })(
                        <Input type="text"/>
                    )}
                </FormItem>
                <FormItem label="公司" {...formItemLayout}>
                    {
                        getFieldDecorator('reportCompany',{
                            initialValue:type=='edit'?reportInfo.reportCompany:[],
                            rules: [{ required: true, message: '输入公司'},]
                          })(
                        <Input type="text"/>
                    )}
                </FormItem>
                <FormItem label="体检类型" {...formItemLayout}>
                    {
                        getFieldDecorator('tiJianLb',{
                            initialValue:type=='edit'?reportInfo.tiJianLb:1,
                            rules: [{ required: true, message: '请选择体检类型'},]
                          })(
                        <Select placeholder="选择体检类型">
                            <Option value={1}>常规体检</Option>
                            <Option value={2}>职业病体检</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="查询密码" {...formItemLayout}>
                    {
                        getFieldDecorator('ciKaLbbm',{
                            initialValue:type=='edit'?reportInfo.ciKaLbbm:[]
                          })(
                        <Input type="text" readOnly/>
                    )}
                </FormItem>
                <FormItem label="是否公开" {...formItemLayout}>
                    {
                        getFieldDecorator('isExpose',{
                            initialValue:type=='edit'?reportInfo.isExpose:true
                        })(
                            <Radio.Group>
                            <Radio value={true}>公开</Radio>
                            <Radio value={false}>隐藏</Radio>
                            </Radio.Group>
                        )
                        }
                </FormItem>
            </Form>
        );
    }
}
ReportEditForm = Form.create({})(ReportEditForm);

/**
 * @description 组件-异常处理
 * @class HandleEspForm
 * @extends {React.Component}
 */
class HandleEspForm extends React.Component{
      handleReset = ()=>{
        this.props.form.resetFields();
      }
      handleSearchEsp=()=>{ //调用父组件方法
        return this.props.form.getFieldsValue()
    }
    
    render(){
        const {getFieldDecorator} = this.props.form;
        return (
            <Form layout="inline"> 
            <FormItem>
                {getFieldDecorator('tijianNo',{
                        })(<Input
                placeholder="请输入体检单号" prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                style={{ width: 300,marginBottom:20}}
                />)}
                </FormItem>
            <FormItem>
                <Button type="primary" onClick={this.props.handleSearchEsp}>
                        重新抓取
                </Button>
            </FormItem>
            </Form>
        );
    }
  }
HandleEspForm = Form.create({})(HandleEspForm);
