/*
 * @file: 医生详情的编辑组件
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import {
    Tag, Row, Col, Radio, Upload, Icon, message, Form,
    Input, Button, Select, Checkbox, Modal
} from 'antd'
import RadioGroup from 'antd/lib/radio/group';
import GoodAtAdd from './GoodAtAdd'//打标签---擅长疾病
import { getFile } from './../../../../Api/index'
const { Option } = Select
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader));
    reader.readAsDataURL(img);
}
/**
 * @classdesc: 医生详情的编辑组件
 */
class MedicalInformation extends Component {
    /**
     * @description: state自定义
     * @param {object} certPracticingLicense 资格证书1
     * @param {object} certDoctorLicense 资格证书2
     * @param {object} certProfTechnicalLicense 资格证书3
     * @param {boolean} loading1 等待标志
     * @param {boolean} loading2 等待标志
     * @param {boolean} loading3 等待标志
     * @param {boolean} goodAtbool 擅长疾病弹框判断值
     * @param {object} data 获取传递值
     * @param {object} haDoctorCheck 医生信息
     * @param {object} goodAt 擅长项目
     * @param {Array} doctorGoodAtText 医生擅长的文本集
     */
    state = {
        i: 0,
        certPracticingLicense: null,
        certDoctorLicense: null,
        certProfTechnicalLicense: null,
        loading1: false,
        loading2: false,
        loading3: false,
        goodAtbool: false,
        data: this.props.content[0],
        haDoctorCheck: this.props.content[1],
        goodAt: [],
        doctorGoodAtText: [],
    }

    componentWillReceiveProps(nextProps) {
        let i = this.state.i
        i++
        this.setState({
            data: this.props.content[0],
            haDoctorCheck: this.props.content[1],
            i
        })
        if (i === 3) {
            this.conduct()
        }
    }



    /**
     * @description: 处理父组件传来的数据
     * @function: conduct
     */
    conduct() {
        let _this = this
        let data = this.state.data;
        let obj = []
        if (data) {

            if (data.goodAtValue && data.goodAt) {
                if (typeof (data.goodAtValue) == 'string') {
                    data.goodAtValue = data.goodAtValue.split(',')
                }
                if (typeof (data.goodAt) == 'string') {
                    data.goodAt = data.goodAt.split(',')
                }
                for (var i = 0; i < data.goodAt.length; i++) {
                    obj.push([data.goodAt[i], data.goodAtValue[i]])

                }
                this.setState({
                    goodAt: obj,
                })
            }
        }

        if (this.state.haDoctorCheck) {
            let haDoctorCheck = this.state.haDoctorCheck
            if (haDoctorCheck.certPracticingLicenseId) {
                _this.imgUrlUp(haDoctorCheck.certPracticingLicenseId).then((val) => {
                    _this.setState({
                        certPracticingLicense: val
                    })
                })
            }
            if (haDoctorCheck.certDoctorLicenseId) {
                _this.imgUrlUp(haDoctorCheck.certDoctorLicenseId).then((val) => {
                    _this.setState({
                        certDoctorLicense: val
                    })
                })
            }
            if (haDoctorCheck.certProfTechnicalLicenseId) {
                _this.imgUrlUp(haDoctorCheck.certProfTechnicalLicenseId).then((val) => {
                    _this.setState({
                        certProfTechnicalLicense: val
                    })
                })
            }

        }
    }
    /**
     * @description: 医师执业证
     * @param {object} info 医师执业证数据 
     * @function: handleChange1
     */
    handleChange1 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading1: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                //console.log(info.file.response.result)
                this.setState({
                    certPracticingLicense: info.file.response.result,
                    loading1: false
                })
            });
        }
    };
    /**
     * @description: 医师资格证
     * @param {object} info 医师资格证数据 
     * @function: handleChange2
     */
    handleChange2 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                //console.log(info.file.response.result)
                this.setState({
                    certDoctorLicense: info.file.response.result,
                    loading2: false
                })
            });
        }
    };
    /**
    * @description: 专业技术资格证
    * @param {object} info 专业技术资格证数据 
    * @function: handleChange3
    */
    handleChange3 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading3: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                this.setState({
                    certProfTechnicalLicense: info.file.response.result,
                    loading3: false
                })
            });
        }
    };
    /**
     * @description: id请求图片路径
     * @param {number} urlId 图片id 
     * @function: imgUrlUp
     */
    imgUrlUp = (urlId) => {
        let _this = this
        return new Promise((resolve, reject) => {
            // 获取图片详细情况
            getFile({ fileId: urlId }).then(res => {
                if (res.status === 200 && res.statusText === "OK") {
                    // res.data.result.createDate=_this.formatDate(res.data.result.createDate)
                    resolve(res.data.result)
                } else {
                    reject()
                }
            })

        })
    }
    /**
     * @description: 添加擅长疾病的标签
     * @function: goodAtAdd
     */
    goodAtAdd = () => {
        this.setState({
            goodAtbool: true
        })
    }
    /**
     * @description: 添加擅长疾病---确认
     * @function: goodAtOk
     */
    goodAtOk = () => {
        let check = this.formRef.getItemsValue()
        //console.log(check)
        // 显示
        let goodAt = this.state.goodAt
        let doctorGoodAtText = this.state.doctorGoodAtText

        for (var key in check) {
            if (check[key] && check[key].length) {
                for (var item of check[key]) {
                    if (key !== 'elseSubject') {
                        goodAt.push(item)
                    } else {
                        goodAt.push(['自定义', item])
                    }
                }
            }
        }
        //去重
        goodAt = [...new Set(goodAt)]
        doctorGoodAtText = doctorGoodAtText.concat(check.elseSubject);
        this.setState({
            goodAt,
            doctorGoodAtText,
            goodAtbool: false
        })
        this.formRef.resetValue()
    }
    /**
     * @description: 添加擅长疾病---取消
     * @function: goodAtCancel
     */
    goodAtCancel = () => {
        this.setState({
            goodAtbool: false
        })
    }

    /**
     * @description: 子传父
     * @function: Submit
     */
    Submit = () => {
        let updata = ['3']
        let goodAt = this.state.goodAt
        let doctorGoodAtText = this.state.doctorGoodAtText;
        //console.log(goodAt, doctorGoodAtText)

        let goodAtValue = []
        let goodAts = []
        for (var key of goodAt) {
            if (key[0] !== '自定义') {
                goodAts.push(key[0])
                goodAtValue.push(key[1])
            }
        }
        goodAts = goodAts.join(',')
        goodAtValue = goodAtValue.join(',')
        doctorGoodAtText = doctorGoodAtText.join(',')
        //console.log(goodAts, goodAtValue, doctorGoodAtText)
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let value = this.props.form.getFieldsValue()

                value['haDoctorCheck']['certPracticingLicenseId'] = this.state.certPracticingLicense ? this.state.certPracticingLicense.id : null
                value['haDoctorCheck']['certDoctorLicenseId'] = this.state.certDoctorLicense ? this.state.certDoctorLicense.id : null
                value['haDoctorCheck']['certProfTechnicalLicenseId'] = this.state.certProfTechnicalLicense ? this.state.certProfTechnicalLicense.id : null
                value['haDoctorInfo']['goodAt'] = goodAts
                value['haDoctorInfo']['goodAtValue'] = goodAtValue
                value['haDoctorInfo']['doctorGoodAtText'] = doctorGoodAtText
                updata.push(value)
                this.props.parentDelete(updata);
            } else {
                message.error('请将带 * 的信息填写完整')
            }
        });
    }
    /**
     * @description: 返回上一步
     * @function: back
     */
    back = () => {
        let updata = ["1"]
        this.props.parentDelete(updata);
    }
    /**
     * @description: 删除标签
     * @function: goodAtrem
     */
    goodAtrem = (removedTag) => {
        const goodAt = this.state.goodAt.filter(tag => tag !== removedTag);
        this.setState({ goodAt })
        //console.log(goodAt)
    }

    render() {
        const data = this.state.data ? this.state.data : {}
        const formItemLayout = {
            labelCol: {
                sm: { span: 5 },
            },
            wrapperCol: {
                sm: { span: 15 },
            },
        };
        const { getFieldDecorator } = this.props.form
        // 图片上传
        const uploadButton1 = (
            <div className="practicePic">
                <Icon style={{ lineHeight: '110px' }} type={this.state.loading1 ? 'loading' : 'plus'} />
            </div>
        );
        const uploadButton2 = (
            <div className="practicePic">
                <Icon style={{ lineHeight: '110px' }} type={this.state.loading2 ? 'loading' : 'plus'} />
            </div>
        );
        const uploadButton3 = (
            <div className="practicePic">
                <Icon style={{ lineHeight: '110px' }} type={this.state.loading3 ? 'loading' : 'plus'} />
            </div>
        );
        return (
            <div>
                {/* 医资信息 */}
                <Form {...formItemLayout} className="mt20">
                    <Form.Item label="执业医院">
                        {getFieldDecorator('haDoctorInfo.hospitalName', {
                            initialValue: data.hospitalName,
                            rules: [
                                { required: true, message: '不能为空' },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item label="科室">
                        {getFieldDecorator('haDoctorInfo.departmentsName', {
                            initialValue: data.departmentsName,
                            rules: [
                                { required: true, message: '不能为空' },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item label="职称">
                        {getFieldDecorator('haDoctorInfo.jobTitle', {
                            initialValue: data.jobTitle,
                            rules: [
                                { required: true, message: '不能为空' },
                            ],
                        })(
                            <Select style={{ width: 120 }} placeholder="请选择">
                                <Option value="主任医生">主任医生</Option>
                                <Option value="主治医生">主治医生</Option>
                                <Option value="主管技师">主管技师</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="医生来源">
                        {getFieldDecorator('haDoctorInfo.regSource', {
                            initialValue: data.regSource + '',
                            rules: [
                                { type: 'string', required: true, message: '不能为空' },
                            ],
                        })(
                            <RadioGroup>
                                <Radio value="0">系统医生</Radio>
                                <Radio value="1">注册医生</Radio>
                            </RadioGroup>
                        )}
                    </Form.Item>
                    <Form.Item label="推荐医生">
                        {getFieldDecorator('haDoctorInfo.isRecommend', {
                            initialValue: data.isRecommend,
                            valuePropName: 'checked',
                        })(
                            <Checkbox >是</Checkbox>
                        )}
                    </Form.Item>
                    <Form.Item label="专家">
                        {getFieldDecorator('haDoctorInfo.isOccDoctor', {
                            initialValue: data.isOccDoctor,
                            valuePropName: 'checked'
                        })(
                            <Checkbox >是</Checkbox>
                        )}
                    </Form.Item>
                    <Form.Item label="每月基础订单">
                        {getFieldDecorator('haDoctorInfo.baseServiceCount', {
                            initialValue: data.baseServiceCount,
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item label="擅长疾病">
                        {(() => {
                            if (this.state.goodAt) {
                                return (
                                    this.state.goodAt.map((e, i) => {
                                        return (
                                            <Tag color="blue" closable onClose={() => this.goodAtrem(e)} key={e}>{e[1]}</Tag>
                                        )
                                    })
                                )
                            }
                        })()}
                        <Button type='primary'
                            style={{ lineHeight: '21px', height: '21px' }}
                            onClick={this.goodAtAdd}
                        >+</Button>
                    </Form.Item>
                    <Form.Item label="医学背景介绍">
                        {getFieldDecorator('haDoctorInfo.medicineEduInfo', {
                            initialValue: data.medicineEduInfo,
                            rules: [
                                { required: true, message: '不能为空' },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item label="资格证书">
                        <Row>
                            <Col span={8} className="mt20">
                                {getFieldDecorator('haDoctorCheck.certPracticingLicenseId')(
                                    <Upload
                                        name='file'
                                        showUploadList={false}
                                        action='/v1/ajax/file/upload'
                                        onChange={this.handleChange1}
                                        listType="picture-card"
                                    >
                                        {this.state.certPracticingLicense ? <img src={this.state.certPracticingLicense.fullOssurl} style={{ width: '100%' }} alt="" /> : uploadButton1}
                                    </Upload>
                                )}
                            </Col>
                            <Col span={8} className="mt20">
                                {getFieldDecorator('haDoctorCheck.certDoctorLicenseId')(
                                    <Upload
                                        name='file'
                                        showUploadList={false}
                                        action='/v1/ajax/file/upload'
                                        onChange={this.handleChange2}
                                        listType="picture-card"
                                    >
                                        {this.state.certDoctorLicense ? <img src={this.state.certDoctorLicense.fullOssurl} style={{ width: '100%' }} alt="" /> : uploadButton2}
                                    </Upload>
                                )}

                            </Col>
                            <Col span={8} className="mt20">
                                {getFieldDecorator('haDoctorCheck.certProfTechnicalLicenseId')(
                                    <Upload
                                        name='file'
                                        showUploadList={false}
                                        action='/v1/ajax/file/upload'
                                        onChange={this.handleChange3}
                                        listType="picture-card"
                                    >
                                        {this.state.certProfTechnicalLicense ? <img src={this.state.certProfTechnicalLicense.fullOssurl} style={{ width: '100%' }} alt="" /> : uploadButton3}
                                    </Upload>
                                )}
                            </Col>
                        </Row>

                    </Form.Item>
                </Form>
                <div style={{ textAlign: 'center', width: '100%' }}>
                    {
                        this.state.data ?
                            null :
                            <Button type='primary' className="mlr10" onClick={this.back.bind(this)}>上一步</Button>
                    }
                    <Button type='primary' className="mlr10" onClick={this.Submit.bind(this)}>下一步</Button>
                </div>
                {/* 添加擅长疾病的标签 */}
                <Modal
                    visible={this.state.goodAtbool}
                    loading={this.state.loading}
                    title={'选择'}
                    onOk={this.goodAtOk}
                    onCancel={this.goodAtCancel}
                    okText="确定"
                    cancelText="取消"
                    width='700px'
                >
                    <GoodAtAdd
                        wrappedComponentRef={(form) => this.formRef = form}
                    />
                </Modal>




            </div>

        )
    }
}

const MedicalInformationForm = Form.create()(MedicalInformation);

export default MedicalInformationForm;
