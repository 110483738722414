import * as constants from './actionTypes'
// import {getUserUnitList,getUserData} from './../Api/index'
import {getUserData} from './../Api/index'


//用户登录
export const getUserDataAction = (data, callback) => {
    //debugger;
    return (dispatch) => {
        //请求网络数据
        getUserData(data).then((res) => {
            // console.log(res);
            // debugger
            if(res.status === 200 && res.data.state.value ===0){
                //const userItem = res.data.result.token;
                const userData = res.data.result.token;
                dispatch({
                    type:constants.INIT_USER_DATA,
                    userData:data
                })
                // 成功的回调
                callback && callback(userData);
            }
        }).catch((error) => {
            console.log(error);
        });
    }
};

//home
// export const searchHomeCheck = (data, callback,type) => {
//     // debugger;
//     //console.log(data,"data")
//     return (dispatch) => {
//         //请求网络数据
//         if(type==1){
//             alert(1)
//         }else{
//             dispatch({
//                 type:constants.HOME_CHECK_SEARCH,
//                 homeSearch:data
//             })
//             callback&&callback(data)
//         }
        
//     }
// };



