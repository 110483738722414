import React, { Component } from 'react'
import { Form, Input,InputNumber } from 'antd'
import moment from 'moment'
const { TextArea } = Input;

class OrderDetailRenewal extends Component {
    getItemsValue = ()=>{ 
        const valus= this.props.form.getFieldsValue();
        return valus;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    render() {
        console.log(this.props.record);
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList SoftWareWidth">
                <Form.Item label="订单号" className="widtHalf">
                    {getFieldDecorator('id',{
                        initialValue:this.props.record.id
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="订单类型" className="widtHalf">
                    {getFieldDecorator('oderTypeName',{
                        initialValue:this.props.record.oderTypeName
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="付费类型" className="widtHalf">
                    {getFieldDecorator('typeName',{
                        initialValue:this.props.record.typeName
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="套餐类型" className="widtHalf">
                    {getFieldDecorator('packageName',{
                        initialValue:this.props.record.packageName
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="续费时长" className="widtHalf">
                    {getFieldDecorator('payCount',{
                        initialValue:this.props.record.payCount
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="创建时间" className="widtHalf">
                    {getFieldDecorator('createDate',{
                        initialValue:moment(this.props.record.createDate).format("YYYY-MM-DD HH:MM:SS")
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="处理时间" className="widtHalf">
                    {getFieldDecorator('checkDate',{
                        initialValue:moment(this.props.record.checkDate).format("YYYY-MM-DD HH:MM:SS")
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="处理人" className="widtHalf">
                    {getFieldDecorator('adminName',{
                        initialValue:this.props.record.adminName
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="处理结果" className="widtHalf">
                    {getFieldDecorator('stateName',{
                        initialValue:this.props.record.stateName
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>

                <Form.Item label="代理商推广码">
                    {getFieldDecorator('promocode',{
                        initialValue:this.props.record.promocode !== null ? this.props.record.promocode : "",
                    })(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item label="交易金额">
                    {getFieldDecorator('payCost',{
                        initialValue:this.props.record.payCost !== null ? this.props.record.payCost : 0,
                    })(
                        <InputNumber/>
                    )}
                    <span className="ant-form-text"> 元</span>
                </Form.Item>
                <Form.Item label="交易号">
                    {getFieldDecorator('tradeNo',{
                        initialValue:this.props.record.tradeNo !== null ? this.props.record.tradeNo : "",
                    })(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item label="备注">
                    {getFieldDecorator('remark',{
                        initialValue:this.props.record.remark !== null ? this.props.record.remark : "",
                        rules: [{max:200,message: '内容不可多于200个字符',}]
                    })(
                        <TextArea
                        style={{ height: 75}}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const OrderDetailRenewalForm = Form.create()(OrderDetailRenewal);

export default OrderDetailRenewalForm;

// export default Form.create()(SoftwareOne); 