import React, { Component } from 'react'
import { Card, Row, Col, Icon, Popover, Button, Modal, Badge, message } from 'antd'
import ReactEcharts from 'echarts-for-react'
import { NavLink } from 'react-router-dom'
import { homeQuestionCheck, homeQuestionList } from './../../Mock'
import { getAdminNotebookListData,addAdminNotebook } from './../../Api'
import './home.css'
import View from './Home/Viewlist'
import Checkmodal from './component/form/Checkmodal'
export default class Home extends Component {
    state = {
        writeModal: false,
        viewModal: false,
        questionList: [],
        pageInfo: {
            page: 1,
            pageSize: 20
        },
        questionNum: 0,
        formModal: {
            title: '记事本',
            list: [{
                name: "type",
                label: "问题类型",
                type: 'select',
                placeholder: '请选择问题类型',
                optionList: [{
                    label: '平台订单问题',
                    value: 1
                }, {
                    label: '平台数据问题',
                    value: 2
                }, {
                    label: '平台用户问题',
                    value: 3
                }, {
                    label: '健康顾问订单问题',
                    value: 4
                }, {
                    label: '健康顾问数据问题',
                    value: 5
                }, {
                    label: '健康顾问用户问题',
                    value: 6
                }, {
                    label: '其他问题',
                    value: 7
                }],
                rules: [{
                    required: true,
                    message: '此项为必选项'
                }]
            }, {
                name: "content",
                label: "内容",
                type: 'textArea',
                placeholder: '请输入具体内容',
                rules: [{
                    required: true,
                    message: '此项为必填项'
                }]
            }]
        }
    }
    getStaticOptionOne = () => {
        let option = {}
        option = {
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: [
                    '周一',
                    '周二',
                    '周三',
                    '周四',
                    '周五',
                    '周六',
                    '周日'
                ]
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '邮件营销',
                    type: 'line',
                    stack: '总量',
                    data: [
                        120,
                        132,
                        101,
                        134,
                        90,
                        230,
                        210
                    ]
                }, {
                    name: '联盟广告',
                    type: 'line',
                    stack: '总量',
                    data: [
                        220,
                        182,
                        191,
                        234,
                        290,
                        330,
                        310
                    ]
                }, {
                    name: '视频广告',
                    type: 'line',
                    stack: '总量',
                    data: [
                        150,
                        232,
                        201,
                        154,
                        190,
                        330,
                        410
                    ]
                }, {
                    name: '直接访问',
                    type: 'line',
                    stack: '总量',
                    data: [
                        320,
                        332,
                        301,
                        334,
                        390,
                        330,
                        320
                    ]
                }, {
                    name: '搜索引擎',
                    type: 'line',
                    stack: '总量',
                    data: [
                        820,
                        932,
                        901,
                        934,
                        1290,
                        1330,
                        1320
                    ]
                }
            ]
        }
        return option
    }
    getStaticOptionTwo = () => {
        let option = {}
        option = {
            xAxis: {
                type: 'category',
                data: [
                    'Mon',
                    'Tue',
                    'Wed',
                    'Thu',
                    'Fri',
                    'Sat',
                    'Sun'
                ]
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: [
                        120,
                        200,
                        150,
                        80,
                        70,
                        110,
                        130
                    ],
                    type: 'bar'
                }
            ]
        }
        return option
    }
    handleWriteOpen = () => {
        this.setState({ writeModal: true })
    }
    getQuestionList = (data) => {
        getAdminNotebookListData({...data,status:1,pageSize:10,pageNum:1}).then(res => {
            if (res.data.state.value == 0) {
                this.setState({
                    questionNum: res.data.result.records,
                })
            }
        })
    }
    componentWillMount() {
        this.getQuestionList()
    }
    componentWillReceiveProps(){
        this.getQuestionList()
    }
    handleViewOpen = () => {
        this.setState({ viewModal: true })
    }
    updateQuestionNum = (data) => {
        this.setState({
            questionNum: data
        })
    }
    handleVisibleChange = () => {
        this.setState({ writeModal: !this.state.writeModal })
    }
    handleCheck = (data) => {
        if (Object.values(data).every((item) => {return item?true:false}))
            return addAdminNotebook(data).then(res => {
                return new Promise((resolve, reject) => {
                    if (res.data.state.value == 0) {
                        message.success('操作成功!')
                        this.getQuestionList()
                        this.setState({
                            writeModal: false
                        })
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })

            })
    }
    render() {
        const formItemLayout = {
            labelCol: {
                xs: 24,
                sm: 4
            },
            wrapperCol: {
                xs: 24,
                sm: 20
            }
        }
        return (
            <div className="home">
                <Card title='待办事项' className='margin20'>
                    <Row>
                        <Col span={6} className='pl-5'>
                            <NavLink to={'/home/check'}>
                                <div className='pl20 plred fl black'>
                                    <Icon type='contacts' className='pr20 f50 fl'></Icon>
                                    <div className='fl '>
                                        <span className='f20'>50</span><br />
                                        <span>待认证用户</span>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                        <Col span={6} className='pl-5'>
                            <NavLink to={'/home/suggestion'}>
                                <div className='pl20 plblue fl black'>
                                    <Icon type='contacts' className='pr20 f50 fl'></Icon>
                                    <div className='fl '>
                                        <span className='f20'>50</span><br />
                                        <span>意见处理</span>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                        <Col span={6} className='pl-5'>
                            <NavLink to={'/home/legal'}>
                                <div className='pl20 plorange fl black'>
                                    <Icon type='contacts' className='pr20 f50 fl'></Icon>
                                    <div className='fl '>
                                        <span className='f20'>50</span><br />
                                        <span>法规反馈</span>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                        <Col span={6} className='pl-5'>
                            <NavLink to={'/home/application'}>
                                <div className='pl20 plpruple fl black'>
                                    <Icon type='contacts' className='pr20 f50 fl'></Icon>
                                    <div className='fl '>
                                        <span className='f20'>50</span><br />
                                        <span>试用申请</span>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                    </Row>
                </Card>
                <Card title='数据统计' extra={<a href='#' > 查看更多统计 </a>} className='margin20'>
                    <Row>
                        <Col span={12}>
                            <ReactEcharts className='height450' option={this.getStaticOptionOne()} />
                            <p className='text-align-center fw700'>XXXXXX数据统计</p>
                        </Col>
                        <Col span={12}>
                            <ReactEcharts className='height450' option={this.getStaticOptionTwo()} />
                            <p className='text-align-center fw700'>XXXXXX数据统计</p>

                        </Col>
                    </Row>
                </Card>
                <Popover
                    placement='left'
                    title={null}
                    content='记录问题'
                    trigger={['click', 'hover']}>
                    <Button
                        type='primary'
                        shape='circle'
                        icon='edit'
                        size='large'
                        className='button-fixed-write'
                        onClick={this.handleWriteOpen}></Button>
                </Popover>
                <Badge count={this.state.questionNum} className="view-btn-badge">
                    <Popover
                        placement='left'
                        title={null}
                        content='查看记录'
                        trigger={['click', 'hover']}>
                        <Button
                            shape='circle'
                            icon='book'
                            size='large'
                            className='button-fixed-view btn-green'
                            onClick={this.handleViewOpen}>
                        </Button>
                    </Popover>
                </Badge>

                <Checkmodal
                    formSubmitApi={(data) => this.handleCheck(data)}
                    handleVisibleChange={this.handleVisibleChange}
                    visibleCheckModal={this.state.writeModal}
                    formList={this.state.formModal}
                    formItemLayout={formItemLayout}
                />
                <Modal
                    width={800}
                    title='记事本'
                    okText='确定'
                    cancelText='取消'
                    visible={this.state.viewModal}
                    onOk={this.handleViewOk}
                    onCancel={() => {
                        this.setState({ viewModal: false })
                    }}>
                    <View questionNum={this.state.questionNum} getQuestionList={(data) => this.getQuestionList(data)}></View>
                </Modal>
            </div>
        )
    }
}
