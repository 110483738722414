/*
 * @file: 健康顾问用户的详情页
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react';
import { Pagination, Card, Tooltip, Form, Input, Table, Row, Col, Button, Modal, message } from 'antd'
import CloseOrder from './components/Common/CloseOrder'//关闭订单
import utils from './../../Common/js/utils'    //表格分页等     
import {
  getHaOrderFormDetailListData, getUserInviteListData, getUserOrderListData,
  getWithdrawListData, getHealthAdvisorAccount, closeHaOrderFormDetail
} from './../../Api/index'

/**
 * @classdesc 健康顾问用户的详情页
 */
class UserBasicJ extends Component {
  // 跳转过来  传的值
  constructor(props, context) {
    super(props, context)
    /**
     * @description: state自定义
     * @param {string} userId 获取到用户列表所传过来的userModel
     * @param {string} recordId 获取健康顾问等列表传来haUserid
     * @param {boolean} loading 等待判断
     * @param {boolean} closeOrder 订单弹框判断
     * @param {boolean} closeOrderDis 订单弹框判断
     * @param {object} params 表格分页
     * @param {object} params1 表格分页
     * @param {object} params2 表格分页
     * @param {object} params3 表格分页
     * @param {object} basic 基础信息
     * @param {object} Consulting 咨询记录
     * @param {object} Invitational 邀请记录
     * @param {object} Order 邀请订单记录
     * @param {object} Cash 提现记录
     */
    this.state = {
      recordId: this.props.location.state.recordId,
      userId: this.props.location.state.userId,
      loading: false,
      closeOrder: false,
      closeOrderDis: false,
      params: {
        pageNum: 1,
        pageSize: 10
      },
      params1: {
        pageNum: 1,
        pageSize: 10
      },
      params2: {
        pageNum: 1,
        pageSize: 10
      },
      params3: {
        pageNum: 1,
        pageSize: 10
      },
      basic: [
        { title: "createDate", val: "", As: "健康顾问开通时间" },
        { title: "ticket2", val: "", As: "电话券剩余" },
        { title: "ticket1", val: "", As: "图文券剩余" },
        { title: "ticket6", val: "", As: "通用券剩余" },
        { title: "total", val: "", As: "合计" }
      ],
      Consulting: [
        {
          title: "订单号", dataIndex: 'id', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '订单类型', dataIndex: 'orderTypeName', align: 'center', width: 120, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '订单金额', dataIndex: 'orderMoney', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '申请人', dataIndex: 'userName', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '服务医生', dataIndex: 'userDoctorName', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '订单状态', dataIndex: 'orderStateName', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '订单创建时间', dataIndex: 'createDate', align: 'center', width: 190, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '操作', dataIndex: 'operation', width: 60, align: 'center', render: (text, record) => {
            if (text == "待服务" || text == "服务中") {
              return (
                <div>
                  <a className="blue mlr5" onClick={() => this.info(record)}>
                    <Tooltip title="查看详情">
                      <span className="fa fa-eye f18"></span>
                    </Tooltip>
                  </a>
                  <a className="blue mlr5" onClick={() => this.closeOrder(record)}>
                    <Tooltip title="关闭订单">
                      <span className="fa fa-times-circle-o f18" style={{ color: '#f5222d' }}></span>
                    </Tooltip>
                  </a>
                </div>
              )
            } else {
              return (<a className="blue mlr5" onClick={() => this.info(record)}>
                <Tooltip title="查看详情">
                  <span className="fa fa-eye f18"></span>
                </Tooltip>
              </a>)
            }
          }
        }
      ],
      Invitational: [
        {
          title: "序号", dataIndex: 'key', align: 'center', width: 40, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: "姓名", dataIndex: 'fullname', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '绑定手机号', dataIndex: 'bindingTel', align: 'center', width: 120, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '账号性质', dataIndex: 'accountStyle', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '注册时间', dataIndex: 'createDate', align: 'center', width: 190, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '邀请状态', dataIndex: 'inviteTyle', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '提现金额', dataIndex: 'bonus', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        }
      ],
      Order: [
        {
          title: "序号", dataIndex: 'key', align: 'center', width: 40, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: "姓名", dataIndex: 'fullname', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '绑定手机号', dataIndex: 'userMobile', align: 'center', width: 120, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '订单号', dataIndex: 'orderkey', align: 'center', width: 120, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '订单时间', dataIndex: 'createDate', align: 'center', width: 120, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '咨询类型', dataIndex: 'moneyTypeName', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '提现金额', dataIndex: 'moneyFee', align: 'center', width: 100, render(text) {
            return <span title={text}>{text}</span>
          },
        }
      ],
      Cash: [
        {
          title: "序号", dataIndex: 'key', align: 'center', width: 40, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: "提现时间", dataIndex: 'createDate', align: 'center', width: 200, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '提现金额', dataIndex: 'moneyFee', align: 'center', width: 120, render(text) {
            return <span title={text}>{text}</span>
          },
        },
        {
          title: '状态', dataIndex: 'moneyType', align: 'center', width: 120, render(text) {
            return <span title={text}>{text}</span>
          },
        }
      ]
    }
  }

  componentWillMount() {
    this.basicJ()
    this.consultinglist();
    this.invitationallist();
    this.orderlist();
    this.cashlist();
  }
  /**
   * @description: 请求基本数据
   * @function: basicJ
   */
  basicJ = () => {
    let _this = this
    let params = { userId: this.state.userId }
    let basic = this.state.basic
    getHealthAdvisorAccount(params).then((res) => {
      if (res.data.state.name === "操作成功") {
        let HaCorpUserHealthTicket = res.data.result.HaCorpUserHealthTicket
        if (HaCorpUserHealthTicket.createDate == null) {
          HaCorpUserHealthTicket.createDate = '暂无数据'
        }
        for (var key of basic) {
          key['val'] = HaCorpUserHealthTicket[key['title']]
          if (key['title'] === 'total') {
            key['val'] = HaCorpUserHealthTicket.ticket1 + HaCorpUserHealthTicket.ticket2 + HaCorpUserHealthTicket.ticket6
          }
        }
        _this.setState({
          basic
        })
      }
    })
  }


  /**
   * @description: 请求咨询表格数据函数
   * @function: consultinglist
   */
  consultinglist = () => {
    let _this = this
    let params = this.state.params
    params['userId'] = this.state.recordId
    getHaOrderFormDetailListData(params).then((res) => {
      if (res.data.state.name === "操作成功" && res.data.state.value === 0) {
        if (res.data.result.rows.length !== 0) {
          for (var i = 0; i < res.data.result.rows.length; i++) {
            res.data.result.rows[i]['operation'] = res.data.result.rows[i].orderStateName
          }
          utils.tableListKey(res.data.result.rows, _this.state.params.pageNum, _this.state.params.pageSize)
          this.setState({
            total: res.data.result.rows.length,
            consultinglist: res.data.result.rows,
            page: res.data.result.page,
            pagination: utils.pagination(res.data, (current, size) => {
              //console.log(size,"size")
              _this.state.params.pageNum = current
              _this.state.params.pageSize = size
              this.consultinglist()
            }, (current, size) => {
              _this.state.params.pageNum = current
              _this.state.params.pageSize = size
              this.consultinglist()
            }, res.data.result.pageSize)
          })
        }
      }

    })

  }
  /**
   * @description: 请求邀请表格数据函数
   * @function: invitationallist
   */
  invitationallist = () => {
    let _this = this
    let params1 = this.state.params1
    if (params1.InvNameTel == '') {
      params1 = {
        pageNum: 1,
        pageSize: 10
      }
    }
    params1['userId'] = this.state.recordId

    getUserInviteListData(params1).then((res) => {
      if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
        if (res.data.result.rows.length > 0) {
          utils.tableListKey(res.data.result.rows, _this.state.params1.pageNum, _this.state.params1.pageSize)
          this.setState({
            total: res.data.result.rows.length,
            invitationallist: res.data.result.rows,
            page: res.data.result.page,
            pagination1: utils.pagination(res.data, (current, size) => {
              //console.log(size,"size")
              _this.state.params1.pageNum = current
              _this.state.params1.pageSize = size
              this.invitationallist()
            }, (current, size) => {
              _this.state.params1.pageNum = current
              _this.state.params1.pageSize = size
              this.invitationallist()
            }, res.data.result.pageSize)
          })
        }
      }

    })
  }
  /**
   * @description: 请求订单表格数据
   * @function: orderlist
   */
  orderlist = () => {
    let _this = this
    let params2 = this.state.params2
    if (params2.InvOrderNameTel == '') {
      params2 = {
        pageNum: 1,
        pageSize: 10
      }
    }
    params2['userId'] = this.state.recordId
    getUserOrderListData(params2).then((res) => {
      if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
        if (res.data.result.rows.length > 0) {
          utils.tableListKey(res.data.result.rows, _this.state.params1.pageNum, _this.state.params1.pageSize)
          this.setState({
            total: res.data.result.rows.length,
            orderlist: res.data.result.rows,
            page: res.data.result.page,
            pagination1: utils.pagination(res.data, (current, size) => {
              //console.log(size,"size")
              _this.state.params2.pageNum = current
              _this.state.params2.pageSize = size
              this.invitationallist()
            }, (current, size) => {
              _this.state.params2.pageNum = current
              _this.state.params2.pageSize = size
              this.invitationallist()
            }, res.data.result.pageSize)
          })
        }
      }


    })

  }
  /**
   * @description: 请求提现表格数据函数
   * @function: cashlist
   */
  cashlist = () => {
    let _this = this
    let params3 = this.state.params3
    if (params3.CashNameTel == '') {
      params3 = {
        pageNum: 1,
        pageSize: 10
      }
    }
    params3['userId'] = this.state.recordId
    getWithdrawListData(params3).then((res) => {
      if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
        if (res.data.result.rows.length > 0) {
          utils.tableListKey(res.data.result.rows, _this.state.params1.pageNum, _this.state.params1.pageSize)
          this.setState({
            total: res.data.result.rows.length,
            cashlist: res.data.result.rows,
            page: res.data.result.page,
            pagination1: utils.pagination(res.data, (current, size) => {
              //console.log(size,"size")
              _this.state.params3.pageNum = current
              _this.state.params3.pageSize = size
              this.invitationallist()
            }, (current, size) => {
              _this.state.params3.pageNum = current
              _this.state.params3.pageSize = size
              this.invitationallist()
            }, res.data.result.pageSize)
          })
        }
      }
    })

  }
  // 弹出框-----S
  /**
   * @description: 关闭订单---显示
   * @param {object} record 行信息 
   * @function: closeOrder
   */
  closeOrder = (record) => {
    this.setState({
      closeOrderDis: true,
      record
    })
  }
  /**
   * @description: 关闭订单---取消
   * @function: closeOrderCancel
   */
  closeOrderCancel = () => {
    this.formRef.resetValue()
    this.setState({ closeOrderDis: false })
  }
  /**
   * @description: 关闭订单---确认
   * @function: closeOrderOk
   */
  closeOrderOk = () => {
    this.setState({ loading: true });
    let params = { id: this.state.record.id }
    //console.log(params)
    let close = this.formRef.getItemsValue()
    //console.log(close)
    if (close) {
      if (close['checkbox-group'][0] === 'Refunds') {
        params.isRefund = true
      } else {
        params.isRefund = false

      }
      params.remarks = close.reasons
    }
    closeHaOrderFormDetail(params).then(res => {
      if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
        message.success('操作成功！')
        //console.log(res)
        this.setState({ loading: false, closeOrderDis: false });
        this.consultinglist()
      } else {
        this.renderModalSimple('warning', '错误', '操作失败，请重新提交！')
      }
      this.formRef.resetValue()
    })
  }
  /**
   * @description: 查看详情
   * @param {object} e 行信息 
   * @function: info
   */
  info = (e) => {
    let detail = e
    Modal.info({
      title: '订单详情',
      okText: '关闭',
      width: '560px',
      className: 'userBasiJY',
      content: (
        <div className="showdetail" style={{ color: '#333' }}>
          <p>
            <span className="tie" style={{
              width: '90px', color: '#000', fontWeight: '900'
            }}>订单状态</span>
            <span style={{ fontWeight: '400' }}>{detail.orderStateName}</span>
          </p>
          {/* 订单详情 */}
          <Row>
            <Col span={12}>
              <p>
                <span>订单号</span>
                <span>{detail.id}</span>
              </p>
              <p>
                <span>申请人</span>
                <span>{detail.userName}</span>
              </p>
              <p>
                <span>服务医生</span>
                <span>{detail.userDoctorName}</span>
              </p>
              <p>
                <span>联系电话</span>
                <span>{detail.userDoctorMobile}</span>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <span>创建时间</span>
                <span>{detail.createDate}</span>
              </p>
              <p>
                <span>订单类型</span>
                <span>{detail.orderTypeName}</span>
              </p>
              <p>
                <span>订单金额</span>
                <span>{detail.orderMoney}</span>
              </p>
              <p>
                <span>预约时间</span>
                <span>{detail.orderDate}</span>
              </p>
            </Col>
          </Row>
          <hr />
          {/* 咨询问题 */}
          <div>
            <p className="tie" style={{ color: '#000', fontWeight: '900' }}>咨询问题</p>
            <p>{detail.problemDetails}</p>
            {/* <div>
                        <img className="mr20" src={detail.pic1} alt="#" />
                        <img src={detail.pic2} alt="#" />
                    </div> */}
          </div>
          <hr />
          {/* 订单服务进度 */}
          <div>
            <span className="tie" style={{ color: '#000', fontWeight: '900' }}>使用健康券情况</span>
            <span style={{ fontWeight: '400' }}>{detail.orderTypeName}</span>
            <Row className='mt10'>
              <Col span={12}>
                {
                  detail.payMoney != null ?
                    <p>
                      <span>支付金额</span>
                      <span>{detail.payMoney}</span>
                    </p> : null
                }
                {
                  detail.paydate ?
                    <p>
                      <span>支付时间</span>
                      <span>{detail.paydate}</span>
                    </p> : null
                }
                {
                  detail.serviceTime ?
                    <p>
                      <span>服务时间</span>
                      <span>{detail.serviceTime}</span>
                    </p> : null
                }
                {
                  detail.completeDate ?
                    <p>
                      <span>完成时间</span>
                      <span>{detail.completeDate}</span>
                    </p> : null
                }
                {
                  detail.null ?
                    <p>
                      <span>关闭时间</span>
                      <span>{detail.null}</span>
                    </p> : null
                }
                {
                  detail.isRefund != null ?
                    <p>
                      <span>是否退款</span>
                      <span>{detail.isRefund ? '是' : '否'}</span>
                    </p> : null
                }
                {
                  detail.remarks ?
                    <p>
                      <span>关闭理由</span>
                      <span>{detail.remarks}</span>
                    </p> : null
                }
              </Col>
            </Row>
          </div>

        </div>
      ),
      onCancel() { },
    });
  }
  /**
      * 警告---提示框
      * @param {string} type 类型
      * @param {string} title 标题
      * @param {string} content 内容 
      * @function renderModalSimple
      */
  renderModalSimple = (type, title, content) => {
    return Modal[type ? type : 'info']({
      title: title ? title : '警告',
      content: content ? content : ''
    })
  }
  // 弹出框---E


  /**
   * @description: 邀请记录-----搜索
   * @function: InvSubmit
   */
  InvSubmit = () => {
    let fieldsParams = this.props.form.getFieldsValue()
    let _this = this
    new Promise((resolve, reject) => {
      _this.setState({
        params1: {
          keyword: fieldsParams.InvNameTel,
          pageNum: 1,
          pageSize: 10
        }
      })
      resolve()
    }).then(() => { this.invitationallist() })
  }
  /**
   * @description: 邀请订单记录-----搜索
   * @function: InvOrderSubmit
   */
  InvOrderSubmit = () => {
    let fieldsParams = this.props.form.getFieldsValue()
    let _this = this
    new Promise((resolve, reject) => {
      _this.setState({
        params2: {
          keyword: fieldsParams.InvOrderNameTel,
          pageNum: 1,
          pageSize: 10
        }
      })
      resolve()
    }).then(() => { this.orderlist() })
  }
  /**
   * @description: 提现记录-----搜索
   * @function: CashSubmit
   */
  CashSubmit = () => {
    let fieldsParams = this.props.form.getFieldsValue()
    let _this = this
    new Promise((resolve, reject) => {
      _this.setState({
        params3: {
          keyword: fieldsParams.CashNameTel,
          pageNum: 1,
          pageSize: 10
        }
      })
      resolve()
    }).then(() => { this.cashlist() })
  }
  /**
   * @description: 切换到用户基本信息页
   * @function: back
   */
  back = () => {
    this.props.history.goBack();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const Consulting = this.state.Consulting;
    const Invitational = this.state.Invitational;
    const Order = this.state.Order;
    const Cash = this.state.Cash;
    return (
      <div style={{ minWidth: '1000px' }} className='userBasiJY'>
        <div className="mb20" style={{ position: 'relative' }}>
          <Button className="mr20" onClick={this.back}>基本信息</Button>
          <Button type="primary">健康顾问</Button>
          <span className="blue-connecting"> </span>
          <Button type="primary" style={{ marginLeft: "200px" }}>健康顾问普通用户</Button>
        </div>
        {/* 基本信息 */}
        <Card
          className="CardBorderNone"
          title="基础信息"
        >
          <div className="overhide width-90" style={{ margin: 'auto' }}>
            {
              this.state.basic.map((e, i) => {
                return (
                  <Row className="fl width-50" key={i} >
                    <Col span={7} className="mr20 mtb10">{e.As}</Col>
                    <Col span={13} className="border-bottom mtb10 pl10">{e.val}</Col>
                  </Row>
                )
              })
            }
          </div>
        </Card>
        {/* 咨询记录 */}
        <Card
          className="CardBorderNone"
          title="咨询记录"
        >
          <Table
            bordered
            dataSource={this.state.consultinglist}
            columns={Consulting}
            pagination={false}
          />
          <Pagination {...this.state.pagination}
            pageSize={this.state.params.pageSize}
            className="fr mtb10"
          />
        </Card>
        {/* 邀请记录 */}
        <Card
          className="CardBorderNone"
          title="邀请信息"
        >
          {/* 搜索 */}
          <Form layout="inline" style={{ margin: '7px 0 5px 20px' }}>
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator('InvNameTel')(
                <Input
                  style={{
                    borderRadius: 0,
                  }}
                  placeholder="姓名/绑定手机号"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={this.InvSubmit} style={{ borderRadius: 0 }}>
                搜 索
                </Button>
            </Form.Item>
          </Form>
          <Table
            bordered
            dataSource={this.state.invitationallist}
            columns={Invitational}
            pagination={false}
          />
          <Pagination {...this.state.pagination1}
            pageSize={this.state.params1.pageSize}
            className="fr mtb10"
          />
        </Card>
        {/* 邀请订单记录 */}
        <Card
          className="CardBorderNone"
          title="邀请订单记录"
        >
          {/* 搜索 */}
          <Form layout="inline" style={{ margin: '7px 0 5px 20px' }}>
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator('InvOrderNameTel')(
                <Input
                  style={{
                    borderRadius: 0,
                  }}
                  placeholder="姓名/绑定手机号"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={this.InvOrderSubmit} style={{ borderRadius: 0 }}>
                搜 索
                </Button>
            </Form.Item>
          </Form>

          <Table
            bordered
            dataSource={this.state.orderlist}
            columns={Order}
            pagination={false}
          />
          <Pagination {...this.state.pagination2}
            pageSize={this.state.params2.pageSize}
            className="fr mtb10"
          />
        </Card>
        {/* 提现记录 */}
        <Card
          className="CardBorderNone"
          title="提现记录"
        >
          {/* 搜索 */}
          <Form layout="inline" style={{ margin: '7px 0 5px 20px' }}>
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator('CashNameTel')(
                <Input
                  style={{
                    borderRadius: 0,
                  }}
                  placeholder="姓名/绑定手机号"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={this.CashSubmit} style={{ borderRadius: 0 }}>
                搜 索
                </Button>
            </Form.Item>
          </Form>

          <Table
            bordered
            dataSource={this.state.cashlist}
            columns={Cash}
            pagination={false}
          />
          <Pagination {...this.state.pagination3}
            pageSize={this.state.params3.pageSize}
            className="fr mtb10"
          />
        </Card>

        {/* 关闭订单--弹出框 */}
        <Modal
          visible={this.state.closeOrderDis}
          loading={this.state.loading}
          title='关闭订单'
          onCancel={this.closeOrderCancel}
          onOk={this.closeOrderOk}
          okText='确认关闭'
          cancelText='取消'
        >
          <CloseOrder
            wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
          />
        </Modal>

      </div>
    )
  }
}

const UserSearchForm = Form.create()(UserBasicJ);
export default UserSearchForm;