/*
 * @Author: 沈晨露
 * @Date: 2020-05-09 08:04:55
 * @LastEditTime: 2020-06-11 10:00:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \运营后台4.0\src\App.js
 */ 
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import LayOut from './Components/LayOut'
//每个模块对应的首页面
import Login from './Pages/Login/Login'//登陆页
import ErrorPage from './Pages/ErrorPage'//404错误页面
import AddLook from './Pages/Training/Article/AddLook'
import VideoShare from './Pages/Share/video'
import Region from './Pages/Share'
import ArticalShare from './Pages/Share/artical'
//每个模块对应的路由
import HomeRouter from './Pages/Home/router'
import ActivityRouter from './Pages/Activity/router'
import UserRouter from './Pages/User/router'
import OrderRouter from './Pages/Order/router'
import AgentRouter from './Pages/Agent/router'
import DataRouter from './Pages/Data/router'
import SystemRouter from './Pages/System/router'
import Home from './Pages/Home/Home'
import TrainingRouter from './Pages/Training/router'
import * as constants from "./Store/actionTypes"
import { Divider } from 'antd';

class App extends Component {
    render() {
        //取出本地的用户数据
        // const userData = JSON.parse(sessionStorage.getItem("userData"));
        // console.log(userData);
        let LayOutRouter = (
            <LayOut>
                <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/home" component={HomeRouter} />
                        <Route path="/activity" component={ActivityRouter} />
                        <Route path="/user" component={UserRouter} />
                        <Route path="/order" component={OrderRouter} />
                        <Route path="/agent" component={AgentRouter} />
                        <Route path="/data" component={DataRouter} />
                        <Route path="/training" component={TrainingRouter} />
                        <Route path="/system" component={SystemRouter} />
                        <Route component={ErrorPage} />
                </Switch>
            </LayOut>
        );
        return (
            <Router>
                <Switch>
                    <Route path="/addLook/:id" component={AddLook} exact />
                    <Route path="/videoShare/:id" component={VideoShare} exact />
                    <Route path="/region/:id" component={Region} exact />
                    <Route path="/articalShare/:id" component={ArticalShare} exact />
                    {
                        typeof this.props.userData == 'string' || localStorage.getItem('token') ?
                            <Route path="/" render={props => LayOutRouter} /> : <Route path="/" component={Login}/>
                    }
                </Switch>
            </Router>
        );
    }
}

// export default App;
const mapDispatchToProps = (dispatch) => {
    return {
        reqLocalData() {
            const userData = localStorage.getItem('token');
            dispatch({
                type: constants.INIT_USER_DATA,
                userData: userData
            });
        }
    }
};

const mapStateToProps = (state) => {
    return {
        userData: state.userData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);