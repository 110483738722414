import React, { Component } from 'react'
import { Form, Input, Button, Select, Table, Modal, Tabs, Pagination, message,Tag } from 'antd'
import {getImplementList,getaddCorpList,getaddCorpItem,saveGetCorpItem} from './../../Api/index'
import utils from './../../Common/js/utils'
// import { getList } from '../../Mock'
import CreateVersionOrder from './components/CreateVersionOrder'//创建实施版订单弹出组件
import VersionOrderType from './components/VersionOrderType'//实施版软件查看弹出组件
import { isArray } from 'util';

const { Option } = Select
class VerOrderList extends Component {
    //弹出初始状态
    state = {
        loading: false,
        versionOrderType:false,//实施版软件状态 false隐藏 true显示
        createVer:false,//创建实施版订单
        VerOrderList:[],//列表数据
        pageNum:1,
        flag:true,
        pageSize:30,
    };
    componentWillMount() {
        this.RequestOrderList();
    }
    //列表数据请求接口
    RequestOrderList = () => {
        let _this = this
        getImplementList({
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize
        }).then((res)=>{
            console.log(res)
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total:res.data.result.total,
                    VerOrderList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    },(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //下拉
    handleSelectChange = value => {
        // console.log(value);
        this.props.form.setFieldsValue({
            //note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
        });
    };
    //搜索按钮
    handleSubmit = ()=> {
        let _this = this
        let fieldsParams = this.props.form.getFieldsValue()
        // console.log(fieldsParams);
        getImplementList({
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            softId:fieldsParams.softId,
            keyword:fieldsParams.keyword
        }).then((res)=>{
            console.log(res,"12222")
            if(res.status === 200){

                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total:res.data.result.total,
                    VerOrderList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    },(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        }) 
    };
    //重置按钮
    handleReset = () => {
        this.props.form.resetFields();
        this.setState({
            params:{
                pageNum:1,
                pageSize:30
            }
        })
        this.RequestOrderList(this.state.params)
    };

    //////////////////////////////////////创建实施版订单
    createVerShowModal = () => {
        this.setState({
            createVer: true
        });
        //this.RequestOrderView2(record);
    }
    //创建实施版订单 弹出框 确定按钮 操作
    createVerOk = () => {
        this.setState({ loading: true });
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, createVer: false });
        this.RequestcreateVerOrder(formdata);
        this.formRef.resetValue(); 
    };
    //创建实施版订单 弹出框 取消按钮 操作
    createVerCancel = () => {
        this.setState({ createVer: false });
        this.formRef.resetValue(); 
    };
    restore = (arr)=>{
        let str = ''
        arr.map((item,index)=>{
            str += item.id + ','
        })
        console.log(str,"str")
        return str.slice(0,str.length-1)
    }
    RequestcreateVerOrder = (formdata) => {
        console.log(formdata,"formdata");
        let pearmsData = new FormData()
		pearmsData.append("companyName",formdata.companyName?formdata.companyName:'')
        pearmsData.append("promocode",formdata.promocode?formdata.promocode:'')
        pearmsData.append("softId",formdata.softIdStr?formdata.softIdStr:'')
        pearmsData.append("payCost",formdata.payCost?formdata.payCost:'')
        pearmsData.append("tradeNo",formdata.tradeNo?formdata.tradeNo:'')
        pearmsData.append("remark",formdata.remark?formdata.remark:'')
        pearmsData.append("files",this.RestoreIds(formdata.filess))
        getaddCorpList(pearmsData).then((res)=>{
            //console.log(res);
            if(res.data.state.value == 0){
                message.success("操作成功！")
            this.RequestOrderList();
            }else{
                message.warning(res.data.content)
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    //////////////////////////////////////查看实施版软件弹出
    versionShowModal = (record) => {
        this.setState({
            versionOrderType: true,
            record:record,
            flag:true
        });
        this.RequestOrderView2(record);
    }
    //实施版软件 弹出框 确定按钮 操作
    versionOrderTypeOk = () => {
        this.setState({ loading: true });
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, versionOrderType: false });
        this.RequestOrderView(formdata);
        this.formRef.resetValue(); 
    };
    //实施版软件 弹出框 取消按钮 操作
    versionOrderTypeCancel = () => {
        this.setState({ versionOrderType: false });
        this.formRef.resetValue(); 
    };
    //
    changeFlag = ()=>{
        this.setState({
            flag:false
        })
    }
    //订单查看-查看 请求接口
    RequestOrderView2 = (data) => {
        console.log(data);
        this.setState({
            flag : true
        })
        getaddCorpItem({
            orderId:data.id
        }).then((res)=>{
            // console.log(res);
            this.setState({
                record:res.data.result,
            });
        }).catch((error)=>{
            console.log(error);
        })
    }
    //文件数组，
    RestoreIds = (arr)=>{
        let strIds = ''
        if(!Array.isArray(arr))return arr
        arr&&arr.map(item=>{
            strIds += item.uid + ','
        })
        strIds=strIds.slice(0,-1)
        return strIds
    }
    //订单查看-提交 请求接口
    RequestOrderView = (data) => {
        let pearmsData = new FormData()
		pearmsData.append("id",this.state.record.id?this.state.record.id:'')
        pearmsData.append("softId",this.state.record.softId?this.state.record.softId:'')
        pearmsData.append("companyName",data.companyName?data.companyName:'')
        pearmsData.append("tradeNod",data.tradeNod?data.tradeNod:'')
        pearmsData.append("packagedName",data.packagedName?data.packagedName:'')
        pearmsData.append("createDate",data.createDate?data.createDate:'')
        pearmsData.append("promocode",data.promocode?data.promocode:'')
        pearmsData.append("payCost",data.payCost?data.payCost:'')
        pearmsData.append("tradeNo",data.tradeNo?data.tradeNo:'')
        pearmsData.append("remark",data.remark?data.remark:'')
        pearmsData.append("files",this.RestoreIds(data.files))
        saveGetCorpItem(pearmsData).then((res)=>{
            if(res.data.state.value == 0){
                message.success("操作成功！")
                this.RequestOrderList();
            }else{
                message.warning(res.data.content)
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        //表格 表头
        const columns = [
            {title: '订单号',dataIndex: 'id',align:'center'},
            {title: '企业名称',dataIndex: 'companyName',align:'center'},
            {title: '软件名称',dataIndex: 'softIdStr',align:'center'},
            {title: '创建人',dataIndex: 'createByStr',align:'center'},
            {title: '创建时间',dataIndex: 'createDate',align:'center'},
            {title: '操作',dataIndex: 'operation',align:'center',
                render: (text,record) => {
                    return (
                        <div>
                            <a className="blue mlr5" onClick={() => this.versionShowModal(record)}>查看</a>
                        </div>
                    ) 
                }
            }
        ];
        return (
            <div>
                <Form layout="inline">
                    <Form.Item>
                        {getFieldDecorator('keyword')(
                            <Input
                            style={{width:180}}
                            placeholder="企业名称/订单号"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('softId')(
                            <Select
                            style={{width:180}}
                            placeholder="--请选择软件名称--"
                            onChange={this.handleSelectChange}
                            >
                                <Option value={1}>职业健康管理系统</Option>
                                <Option value={2}>放射卫生&辐射安全管理系统</Option>
                                <Option value={4}>职业健康管理集团解决方案</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSubmit}>
                            搜 索
                        </Button>
                        <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                            重 置
                        </Button>
                        <Button type="primary" style={{ marginLeft: 10 }} onClick={() => this.createVerShowModal()}>
                            创建实施版订单
                        </Button>
                    </Form.Item>
                </Form>
                
                <Table bordered className="mt20" columns={columns} dataSource={this.state.VerOrderList} pagination={false} />
                <Pagination {...this.state.pagination} className="fr margin10" />
                
                {/* 创建实施版订单时 弹出页面 */}
                <Modal
                width={620}
                visible={this.state.createVer}
                loading={this.state.loading}
                title="创建实施版购买订单"
                onOk={this.createVerOk}
                onCancel={this.createVerCancel}
                okText="确定"
                cancelText="取消"
                >
                    {/* 创建实施版订单时 */}
                    <CreateVersionOrder
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 实施版软件查看 弹出页面 */}
                <Modal
                width={700}
                visible={this.state.versionOrderType}
                loading={this.state.loading}
                title="订单详情"
                onOk={this.versionOrderTypeOk}
                onCancel={this.versionOrderTypeCancel}
                okText="确定"
                cancelText="取消"
                >
                    <VersionOrderType record={this.state.record} flag={this.state.flag} changeFlag={this.changeFlag}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
            </div>
        )
    }
}

const VerOrderListForm = Form.create()(VerOrderList);

export default VerOrderListForm;