/**
 * @file 组件：省市区、地址
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Input } from 'antd'
import AjaxCascader from '../../User/components/Common/AjaxCascader'

/**
 * 组件：省市区、地址
 * @class
 * @extends React.Component
 */
class Address extends React.Component {
  /**
   * 地址变化
   * @type {Function}
   * @param {Event} e 值变化事件
   */
  handleAddressChange = e => this.triggerChange({ address: e.target.value })

  /**
   * 地区变化
   * @type {Function}
   * @param {Event} area 值
   */
  handleCascaderChange = area => this.triggerChange({ area })

  /**
   * 触发值变化
   * @type {Function}
   * @param {Object} changedValue 变化的值
   */
  triggerChange = changedValue => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  /**
   * 渲染最终值
   * @type {Function}
   * @return {HTMLElement}
   */
  render() {
    const { size } = this.props, { area, address } = this.props.value ? this.props.value : {};
    return (
      <span>
        <AjaxCascader
          value={area}
          size={size}
          maxLevel={4}
          expandTrigger={"click"}
          style={{ width: '32%', marginRight: '1%' }}
          onChange={this.handleCascaderChange}
        />
        <Input
          value={address}
          type="text"
          size={size}
          onChange={this.handleAddressChange}
          style={{ width: '67%' }}
        />
      </span>
    );
  }
}

export default Address