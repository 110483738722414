/**
 * @file 页面：题库
 * @author Sim <sim@snorl.ax>
 */
import React, { Component } from 'react'
import { Tabs } from 'antd'
import { pagination, statusMessage } from './features'
import TabContent from './components/TabContent'
import TextModal from '../../Agent/components/TextModal'
import Add from './components/AddForm'
import Edit from './components/EditForm'
import Import from './components/ImportForm'
import { queryQuestionList, addRadioQuestion, addCheckboxQuestion, addFillQuestion, updateQuestion, delQuestion, importQuestion, importFillQuestion, updateQuestionStatus } from '../../../Api'

const { TabPane } = Tabs, category =['职业卫生综合', '职业危害防护', '职业健康监护', '职业危害因素', '职业卫生三同时', '职业病', '放射卫生']

/**
 * @class
 * @extends React.Component
 */
class Quesition extends Component {
    /**
     * 组件状态值
     * @type {Object}
     */
    state = {SingleOptionQuestionPage: 1, SingleOptionQuestionSize: 10, MultiOptionQuestionPage: 1, MultiOptionQuestionSize: 10, FillInQuestionPage: 1, FillInQuestionSize: 10}
  
    /**
     * 组件载入函数
     * @type {Function}
     */
    componentDidMount() {
      this.getQuestion(this.state.SingleOptionQuestionPage, this.state.SingleOptionQuestionSize, 1)
      this.getQuestion(this.state.MultiOptionQuestionPage, this.state.MultiOptionQuestionSize, 2)
      this.getQuestion(this.state.FillInQuestionPage, this.state.FillInQuestionSize, 3)
    }

    /**
     * 查询问题列表，返回对应API的Promise
     * @type {Function}
     * @param {Number} pageNum 请求页码
     * @param {Number} pageSize 单页记录数
     * @param {Number} questionType 问题类型（1：单选；2：多选；3：填空）
     * @param {Number} type 问题所属范畴（1：职业卫生综合；2：职业危害防护；3：职业健康监护；4：职业危害因素；5：职业卫生三同时；6：职业病；7：放射卫生）
     * @return {Promise}
     */
    queryQuestionList = (pageNum, pageSize, questionType, type) => queryQuestionList({pageNum, pageSize, questionType, type})

    /**
     * @callback successCallback 成功时执行的回调函数
     */
    /**
     * @callback failureCallback 失败时执行的回调函数
     * @param {String} failureMessage 失败信息
     */
    /**
     * 查询问题列表，并加载到列表中
     * @type {Function}
     * @param {Number} pageNum 请求页码
     * @param {Number} pageSize 单页记录数
     * @param {Number} questionType 问题类型（1：单选；2：多选；3：填空）
     * @param {Number} type 问题所属范畴（1：职业卫生综合；2：职业危害防护；3：职业健康监护；4：职业危害因素；5：职业卫生三同时；6：职业病；7：放射卫生）
     * @param {Boolean} isAction 是否是操作
     * @param {failureCallback} fallback 失败时执行的回调函数
     * @param {successCallback} callback 成功时执行的回调函数
     */
    getQuestion = (pageNum, pageSize, questionType, type, fallback, callback) => {
      let questionTypes = ['SingleOption-单选', 'MultiOption-多选', 'FillIn-填空']
      type = typeof(type) == 'undefined' ? this.state[`${questionTypes[questionType-1].split('-')[0]}QuestionType`] : type
      this.queryQuestionList(pageNum, pageSize, questionType, type).then(res => {
        if (res.status == 200 && res.data.state.value == 0) {
          console.log(res, `${questionTypes[questionType-1].split('-')[1]}`)
          this.setState({[`${questionTypes[questionType-1].split('-')[0]}QuestionPage`]: pageNum, [`${questionTypes[questionType-1].split('-')[0]}QuestionSize`]: pageSize, [`${questionTypes[questionType-1].split('-')[0]}Question`]: [], [`${questionTypes[questionType-1].split('-')[0]}QuestionTotal`]: 0})
          let index = 1
          const data = res.data.result.rows.map(item => {
            item.index = index++
            item.key = `${questionTypes[questionType-1].split('-')[0]}-${item.index}`
            return item
          })
          this.setState({[`${questionTypes[questionType-1].split('-')[0]}QuestionPage`]: pageNum, [`${questionTypes[questionType-1].split('-')[0]}QuestionSize`]: pageSize, [`${questionTypes[questionType-1].split('-')[0]}Question`]: data, [`${questionTypes[questionType-1].split('-')[0]}QuestionTotal`]: Number(res.data.result.records), [`${questionTypes[questionType-1].split('-')[0]}QuestionType`]: type})
          if (callback) { callback() }
        }
      }, reason => {if (fallback) {fallback(reason)}})
    }

    /**
     * 增加单选题
     * @type {Function}
     * @param {Object} values 单选题的有关值
     * @param {failureCallback} fallback 失败时执行的回调函数
     * @param {successCallback} callback 成功时执行的回调函数
     */
    AddSingle = (values, callback, fallback) => {
      addRadioQuestion(values).then(res => {
        if (res.status == 200 && res.data.state.value == 0) {
          console.log(res, '单选增加')
          this.getQuestion(1, this.state.SingleOptionQuestionSize, 1)
          callback()
        }
      }, err => {
        fallback(err)
      })
    }

    /**
     * 增加多选题
     * @type {Function}
     * @param {Object} values 多选题的有关值
     * @param {failureCallback} fallback 失败时执行的回调函数
     * @param {successCallback} callback 成功时执行的回调函数
     */
    AddMulti = (values, callback, fallback) => {
      addCheckboxQuestion(values).then(res => {
        if (res.status == 200 && res.data.state.value == 0) {
          console.log(res, '多选增加')
          this.getQuestion(1, this.state.MultiOptionQuestionSize, 2)
          callback()
        }
      }, err => {
        fallback(err)
      })
    }

    /**
     * 增加填空题
     * @type {Function}
     * @param {Object} values 填空题的有关值
     * @param {failureCallback} fallback 失败时执行的回调函数
     * @param {successCallback} callback 成功时执行的回调函数
     */
    AddFill = (values, callback, fallback) => {
      addFillQuestion(values).then(res => {
        if (res.status == 200 && res.data.state.value == 0) {
          console.log(res, '填空增加')
          this.getQuestion(1, this.state.FillInQuestionSize, 3)
          callback()
        }
      }, err => {
        fallback(err)
      })
    }

    /**
     * 删除题目
     * @type {Function}
     * @param {Object} value 该题目有关信息，主要是ID
     * @return {Promise}
     */
    deleteQuestion = (value) => delQuestion(value)

    /**
     * 搜索问题，主要是根据题目范畴和题目类型进行搜索
     * @type {Function}
     * @param {Number} questionType 问题类型（1：单选；2：多选；3：填空）
     * @param {Number} type 问题所属范畴（1：职业卫生综合；2：职业危害防护；3：职业健康监护；4：职业危害因素；5：职业卫生三同时；6：职业病；7：放射卫生）
     */
    searchQuestion = (type, questionType) => {
      switch (questionType) {
        case 'single':
          this.getQuestion(1, this.state.SingleOptionQuestionSize, 1, type ? type : null, statusMessage, statusMessage)
          break
        case 'multi':
          this.getQuestion(1, this.state.MultiOptionQuestionSize, 2, type ? type : null, statusMessage, statusMessage)
          break
        default:
          this.getQuestion(1, this.state.FillInQuestionSize, 3, type ? type : null, statusMessage, statusMessage)
          break
      }
    }

    /**
     * 编辑问题，主要是根据题目范畴和题目类型进行搜索
     * @type {Function}
     * @param {Number} questionType 问题类型（1：单选；2：多选；3：填空）
     * @param {Object} values 问题所有值
     * @return {Promise}
     */
    updateQuestion = (values, questionType) => new Promise((resolve, reject) => {
      if (questionType == 2) {
        values.result = Array.from(values.result).join('').replace(',', '')
      }
      updateQuestion({questionType, ...values}).then(res => {
        if (res.status == 200 && res.data.state.value == 0) {
          console.log(res, '编辑')
          switch (questionType) {
            case 1:
              this.getQuestion(this.state.SingleOptionQuestionPage, this.state.SingleOptionQuestionSize, 1, )
              break
            case 2:
              this.getQuestion(this.state.MultiOptionQuestionPage, this.state.MultiOptionQuestionSize, 2)
              break
            default:
              this.getQuestion(this.state.FillInQuestionPage, this.state.FillInQuestionSize, 3)
              break
          }
          resolve()
        }
      }, err => {
        reject(err)
      })})

    /**
     * 导入选择题
     * @type {Function}
     * @param {Object} values 有关导入文件的值，主要是导入文件上传之后返回的ID
     * @param {Number} questionType 问题类型（1：单选；2：多选；3：填空）
     * @param {failureCallback} fallback 失败时执行的回调函数
     * @param {successCallback} callback 成功时执行的回调函数
     */
    importQuestion = (values, questionType, fallback, callback) => {
      importQuestion(values).then(res => {
        if (res.status == 200 && res.data.state.value == 0) {
          console.log(res, '导入')
          switch (questionType) {
            case 1:
              this.getQuestion(1, this.state.SingleOptionQuestionSize, 1)
              break
            default:
              this.getQuestion(1, this.state.MultiOptionQuestionSize, 2)
              break
          }
          callback(res.data.result)
        }
      }, err => {
        fallback(err)
      })
    }

    /**
     * 导入填空题
     * @type {Function}
     * @param {Object} values 有关导入文件的值，主要是导入文件上传之后返回的ID
     * @param {failureCallback} fallback 失败时执行的回调函数
     * @param {successCallback} callback 成功时执行的回调函数
     */
    importFillQuestion = (values, fallback, callback) => {
      importFillQuestion(values).then(res => {
        if (res.status == 200 && res.data.state.value == 0) {
          console.log(res, '导入')
          this.getQuestion(1, this.state.FillInQuestionSize, 3)
          callback(res.data.result)
        } 
      }, err => {
        fallback(err)
      })
    }

    /**
     * 提交前，处理选择题、多选题的值，并返回
     * @type {Function}
     * @param {Object} value 要处理的值
     * @return {Object}
     */
    optionValues = value => {
      value.optionA = value.optionList[0]
      value.optionB = value.optionList[1]
      value.optionC = value.optionList[2]
      value.optionD = value.optionList[3]
      delete value.optionList
      return value
    }

    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => <Tabs defaultActiveKey="1">
    <TabPane tab="单选题" key="1">
      {this.state.SingleOptionQuestion ? <TabContent type='single' category={category} searchFunction={this.searchQuestion} addTitle={'新增单选题'} 
                                                    addForm={<Add category={category} type='single' wrappedComponentRef={inst => this.singleAdd = inst} />}
                                                    onOk={() => new Promise((resolve, reject) => {
                                                      this.singleAdd.onSubmit().then(value => {
                                                        this.AddSingle(this.optionValues(value), () => {this.singleAdd.emptyValues(); resolve()}, reason => {reject(reason)})
                                                      }, reason => {reject(reason)})})}
                                                    data={this.state.SingleOptionQuestion} 
                                                    pagination={pagination(this.state.SingleOptionQuestionSize, this.state.SingleOptionQuestionPage, this.state.SingleOptionQuestionTotal, (page, size) => {this.getQuestion(page, size, 1)})}
                                                    actionRender={(text, record) => record.status == 1 ? (
                                                    <div style={{fontSize:'1.2em'}}>
                                                        <TextModal iconType={"edit"} className="mr10" theme={'twoTone'} iconColor={"#1890ff"} content={<Edit category={category} type='single' wrappedComponentRef={(inst)=>this.singleEdit=inst} value={record}/>} 
                                                                  title="编辑单选题" onOk={() => new Promise((resolve, reject) => {this.singleEdit.onSubmit().then(value => {resolve(value)}, reason => {reject(reason)})})}
                                                                  okPromise={value => this.updateQuestion({...this.optionValues(value), id: record.id}, 1)} 
                                                                  hideModal={() => {this.singleEdit.resetFields()}}/>
                                                        <TextModal iconType={"delete"} theme={'twoTone'} iconColor={"#f5222d"} content={"是否确认要删除？"} 
                                                                  title="删除单选题" onOk={() => new Promise((resolve, reject) => {
                                                                    this.deleteQuestion({questionId: record.id}).then(res => {
                                                                      if (res.status == 200 && res.data.state.value == 0) {
                                                                        console.log(res, '单选删除')
                                                                        this.getQuestion(this.state.SingleOptionQuestionPage, this.state.SingleOptionQuestionSize, 1)
                                                                        resolve()
                                                                      }
                                                                    }, reason => {
                                                                        reject(reason)
                                                                    })
                                                                  })} />
                                                    </div>) : record.status == 2 ? (
                                                    <div style={{fontSize:'1.2em'}}>
                                                        <TextModal iconType={"stop"} className="mr10" theme={'twoTone'} iconColor={"#f5222d"} content={"是否要禁用该题目？"} 
                                                                   title={"禁用"} onOk={() => new Promise((resolve, reject) => {
                                                                    updateQuestionStatus({questionId: record.id, status: 3}).then(res => {
                                                                      if (res.status == 200 && res.data.state.value == 0) {
                                                                        this.getQuestion(this.state.SingleOptionQuestionPage, this.state.SingleOptionQuestionSize, 1)
                                                                        resolve()
                                                                      }
                                                                    }, reason => {
                                                                      reject(reason)
                                                                    })
                                                                   })} />
                                                        <TextModal iconType={"edit"} theme={'twoTone'} iconColor={"#1890ff"} content={<Edit category={category} type='single' wrappedComponentRef={(inst)=>this.singleEdit=inst} value={record}/>} 
                                                                  title="编辑单选题" onOk={() => new Promise((resolve, reject) => {this.singleEdit.onSubmit().then(value => {resolve(value)}, reason => {reject(reason)})})}
                                                                  okPromise={value => this.updateQuestion({...this.optionValues(value), id: record.id}, 1)} 
                                                                  hideModal={() => {this.singleEdit.resetFields()}} />
                                                    </div>)  : <TextModal iconType={"key"} theme={'outlined'} iconColor={"#13c2c2"} content={"是否要启用该题目？"} 
                                                                          title={"启用"} onOk={() => new Promise((resolve, reject) => {
                                                                            updateQuestionStatus({questionId: record.id, status: 2}).then(res => {
                                                                              if (res.status == 200 && res.data.state.value == 0) {
                                                                                this.getQuestion(this.state.SingleOptionQuestionPage, this.state.SingleOptionQuestionSize, 1)
                                                                                resolve()
                                                                              }
                                                                            }, reason => {
                                                                              reject(reason)
                                                                            })
                                                                           })}/>} importTitle='导入'
                                                    importForm={<Import wrappedComponentRef={inst=> this.singleImport = inst} importExample={'/单选题导入模板.xls'} />} onOkImport={() => new Promise((resolve, reject) => {
                                                                                            this.singleImport.onSubmit().then(value => {if (value.file.file.response) {
                                                                                              this.importQuestion({fileId: value.file.file.response.result.id}, 1, message => {reject(message)}, value => {resolve(value)})
                                                                                            }
                                                                                              else {reject('未上传')}
                                                                                            }, 
                                                                                            reason => {reject(reason)})
                                                    })} /> : '载入中……'}
    </TabPane>
    <TabPane tab="多选题" key="2">
      {this.state.MultiOptionQuestion ? <TabContent searchFunction={this.searchQuestion} category={category} type='multi' addTitle={'新增多选题'}
                                                    addForm={<Add category={category} type='multi' wrappedComponentRef={inst => this.multiAdd = inst} />}
                                                    onOk={() => new Promise((resolve, reject) => {this.multiAdd.onSubmit().then(value => {
                                                      this.AddMulti(this.optionValues(value), () => {this.multiAdd.emptyValues(); resolve()}, reason => {reject(reason)})},
                                                      reason => {reject(reason)})})}
                                                    data={this.state.MultiOptionQuestion} 
                                                    pagination={pagination(this.state.MultiOptionQuestionSize, this.state.MultiOptionQuestionPage, this.state.MultiOptionQuestionTotal, (page, size) => {this.getQuestion(page, size, 2)})}                            
                                                    actionRender={(text, record) => record.status == 1 ? (
                                                      <div style={{fontSize:'1.2em'}}>
                                                          <TextModal iconType={"edit"} className="mr10" theme={'twoTone'} iconColor={"#1890ff"} content={<Edit category={category} type='multi' wrappedComponentRef={(inst)=>this.multiEdit=inst} value={record}/>} 
                                                                    title="编辑多选题"
                                                                    onOk={() => new Promise((resolve, reject) => {this.multiEdit.onSubmit().then(value => {resolve(value)}, reason => {reject(reason)})})}
                                                                    okPromise={value => this.updateQuestion({...this.optionValues(value), id: record.id}, 2)}
                                                                    hideModal={() => {this.multiEdit.resetFields()}} />
                                                          <TextModal iconType={"delete"} theme={'twoTone'} iconColor={"#f5222d"} content={"是否确认要删除？"} 
                                                                    title="删除多选题"
                                                                    onOk={() => new Promise((resolve, reject) => {
                                                                      this.deleteQuestion({questionId: record.id}).then(res => {
                                                                        if (res.status == 200 && res.data.state.value == 0) {
                                                                          console.log(res, '多选删除')
                                                                          this.getQuestion(this.state.MultiOptionQuestionPage, this.state.MultiOptionQuestionSize, 2)
                                                                          resolve()
                                                                        }
                                                                      }, reason => {
                                                                        reject(reason)
                                                                        })})} />
                                                      </div>) : record.status == 2 ? (
                                                    <div style={{fontSize:'1.2em'}}>
                                                        <TextModal iconType={"stop"} className="mr10" theme={'twoTone'} iconColor={"#f5222d"} content={"是否要禁用该题目？"} 
                                                                   title={"禁用"} onOk={() => new Promise((resolve, reject) => {
                                                                    updateQuestionStatus({questionId: record.id, status: 3}).then(res => {
                                                                      if (res.status == 200 && res.data.state.value == 0) {
                                                                        this.getQuestion(this.state.MultiOptionQuestionPage, this.state.MultiOptionQuestionSize, 2)
                                                                        resolve()
                                                                      }
                                                                    }, reason => {
                                                                      reject(reason)
                                                                    })
                                                                   })} />
                                                        <TextModal iconType={"edit"} theme={'twoTone'} iconColor={"#1890ff"} content={<Edit category={category} type='multi' wrappedComponentRef={(inst)=>this.multiEdit=inst} value={record}/>} 
                                                                    title="编辑多选题"
                                                                    onOk={() => new Promise((resolve, reject) => {this.multiEdit.onSubmit().then(value => {resolve(value)}, reason => {reject(reason)})})}
                                                                    okPromise={value => this.updateQuestion({...this.optionValues(value), id: record.id}, 2)}
                                                                    hideModal={() => {this.multiEdit.resetFields()}} />
                                                    </div>)  : <TextModal iconType={"key"} theme={'outlined'} iconColor={"#13c2c2"} content={"是否要启用该题目？"} 
                                                                          title={"启用"} onOk={() => new Promise((resolve, reject) => {
                                                                            updateQuestionStatus({questionId: record.id, status: 2}).then(res => {
                                                                              if (res.status == 200 && res.data.state.value == 0) {
                                                                                this.getQuestion(this.state.MultiOptionQuestionPage, this.state.MultiOptionQuestionSize, 2)
                                                                                resolve()
                                                                              }
                                                                            }, reason => {
                                                                              reject(reason)
                                                                            })
                                                                           })}/>} importTitle='导入'
                                                    importForm={<Import wrappedComponentRef={inst=> this.multiImport = inst} importExample={'/多选题导入模板.xls'} />}
                                                    onOkImport={() => new Promise((resolve, reject) => {
                                                      this.multiImport.onSubmit().then(value => {
                                                                                                  if (value.file.file.response) {this.importQuestion({fileId: value.file.file.response.result.id}, 2, message => {reject(message)}, value => {resolve(value)})}
                                                                                                  else {reject('未上传')}}, 
                                                                                      reason => {reject(reason)})})} /> : '载入中……'}
    </TabPane>
    <TabPane tab="填空题" key="3">
      {this.state.FillInQuestion ? <TabContent type='fillin' category={category} searchFunction={this.searchQuestion} addTitle={'新增填空题'}
                                              addForm={<Add category={category} type='fillin' wrappedComponentRef={inst => this.fillinAdd = inst} />}
                                              onOk={() => new Promise((resolve, reject) => {this.fillinAdd.onSubmit().then(value => {resolve(value)}, reason => {reject(reason)})})}
                                              postOk={(value)=>{this.AddFill(value, () => {this.fillinAdd.emptyValues()})}} data={this.state.FillInQuestion} 
                                              pagination={pagination(this.state.FillInQuestionSize, this.state.FillInQuestionPage, this.state.FillInQuestionTotal, (page, size) => {this.getQuestion(page, size, 3)})}
                                              actionRender={(text, record) => record.status == 1 ? (
                                                <div style={{fontSize:'1.2em'}}>
                                                    <TextModal iconType={"edit"} className="mr10" theme={'twoTone'} iconColor={"#1890ff"} content={<Edit category={category} type='fillin' wrappedComponentRef={(inst)=>this.fillinEdit=inst} value={record}/>} 
                                                              title="编辑填空题" 
                                                              onOk={() => new Promise((resolve, reject) => {this.fillinEdit.onSubmit().then(value => {resolve(value)}, reason => {reject(reason)})})}
                                                              okPromise={value => this.updateQuestion({...value, id: record.id}, 3)}
                                                              hideModal={() => {this.fillinEdit.resetFields()}}/>
                                                    <TextModal iconType={"delete"} theme={'twoTone'} iconColor={"#f5222d"} content={"是否确认要删除？"} 
                                                              title="删除填空题" onOk={() => new Promise((resolve, reject) => {
                                                                this.deleteQuestion({questionId: record.id}).then(res => {
                                                                  if (res.status == 200 && res.data.state.value == 0) {
                                                                    console.log(res, '填空删除')
                                                                    this.getQuestion(this.state.FillInQuestionPage, this.state.FillInQuestionSize, 3)
                                                                    resolve()
                                                                  }
                                                                }, reason => {reject(reason)})
                                                            })} />
                                                </div>) : record.status == 2 ? (
                                                    <div style={{fontSize:'1.2em'}}>
                                                        <TextModal iconType={"stop"} className="mr10" theme={'twoTone'} iconColor={"#f5222d"} content={"是否要禁用该题目？"} 
                                                                   title={"禁用"} onOk={() => new Promise((resolve, reject) => {
                                                                    updateQuestionStatus({questionId: record.id, status: 3}).then(res => {
                                                                      if (res.status == 200 && res.data.state.value == 0) {
                                                                        this.getQuestion(this.state.FillInQuestionPage, this.state.FillInQuestionSize, 3)
                                                                        resolve()
                                                                      }
                                                                    }, reason => {
                                                                      reject(reason)
                                                                    })
                                                                   })} />
                                                        <TextModal iconType={"edit"} theme={'twoTone'} iconColor={"#1890ff"} content={<Edit category={category} type='fillin' wrappedComponentRef={(inst)=>this.fillinEdit=inst} value={record}/>} 
                                                              title="编辑填空题" 
                                                              onOk={() => new Promise((resolve, reject) => {this.fillinEdit.onSubmit().then(value => {resolve(value)}, reason => {reject(reason)})})}
                                                              okPromise={value => this.updateQuestion({...value, id: record.id}, 3)}
                                                              hideModal={() => {this.fillinEdit.resetFields()}}/>
                                                    </div>)  : <TextModal iconType={"key"} theme={'outlined'} iconColor={"#13c2c2"} content={"是否要启用该题目？"} 
                                                                          title={"启用"} onOk={() => new Promise((resolve, reject) => {
                                                                            updateQuestionStatus({questionId: record.id, status: 2}).then(res => {
                                                                              if (res.status == 200 && res.data.state.value == 0) {
                                                                                this.getQuestion(this.state.FillInQuestionPage, this.state.FillInQuestionSize, 3)
                                                                                resolve()
                                                                              }
                                                                            }, reason => {
                                                                              reject(reason)
                                                                            })
                                                                           })}/>}
                                              importTitle='导入' importForm={<Import wrappedComponentRef={inst=> this.FillInImport = inst} importExample={'/填空题导入模板.xls'} />}
                                              onOkImport={() => new Promise((resolve, reject) => {
                                                this.FillInImport.onSubmit().then(value => {
                                                  if (value.file.file.response) {
                                                    this.importFillQuestion({fileId: value.file.file.response.result.id}, message => {
                                                      reject(message)
                                                    }, value => {
                                                      resolve(value)
                                                    })
                                                  } else {reject('未上传')}
                                                }, reason => {reject(reason)})})} /> : '载入中……'}
    </TabPane>
    </Tabs>
}

export default Quesition