import React, { Component } from 'react'
import {Card,Button,Form,Input,Upload,Icon,message, Modal} from 'antd'
import { viewCompany,editCompany } from './../../../../Api'
import utils from './../../../../Common/js/utils'
import STATUS from './../../../../Common/js/status' 
import moment from 'moment'
class UnitOther extends Component {
    // 状态机
    state={
        display_none: 'none', //此状态机为display的取值
        display_block: 'block', //此状态机为display的取值
        cid:this.props.record,
        companyInfo:{},
        //上传图片初始状态
        loading: false,
    }
    //组件内获取信息接口-弃用
    requestList = ()=>{
        let _this = this
        viewCompany(this.state.cid).then(res=>{
            if(res.status === 200 && res.data.state.value ===0){
                this.setState({
                    companyInfo:res.data.result,
                    logoFileUrl:res.data.result.companyBaseModel&&res.data.result.companyBaseModel.logoFileUrl?res.data.result.companyBaseModel.logoFileUrl:'',
                    corpFileUrl:res.data.result.companyPlatformModel&&res.data.result.companyPlatformModel.corpFileUrl?res.data.result.companyPlatformModel.corpFileUrl:'',
                })
            }else{
                message.warning('获取企业信息失败！')
            }
        })
    }
    initialBasic=(res)=>{
        this.setState({
            companyInfo:res,
            logoFileUrl:res.companyBaseModel&&res.companyBaseModel.logoFileUrl?res.companyBaseModel.logoFileUrl:'',
            corpFileUrl:res.companyPlatformModel&&res.companyPlatformModel.corpFileUrl?res.companyPlatformModel.corpFileUrl:'',
        })
    }
    //点击编辑
    switchTal = () => {
        this.setState({
            display_none: 'block',
            display_block: 'none',
        })
    }
    //form表单
    componentDidMount() {
        this.props.form.validateFields();
    }
    componentWillReceiveProps=()=>{
        //父组件内获取信息接口
        this.setState({
            companyInfo:this.props.companyInfo()
        })
    }
    //保存按钮
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                let params = {
                    cid:this.state.cid,
                    companyBaseModel:{},
                    companyPlatformModel:{}
                }
                //companyPlatformModel部分（cid\id必传）
                params.companyPlatformModel.cid = this.state.cid
                params.companyPlatformModel.id = this.state.companyInfo.companyPlatformModel.id
                params.companyPlatformModel.corpFileUrl = this.state.corpFileUrl//证照照片
                //companyBaseModel部分（cid\id必传）
                params.companyBaseModel.id = this.state.companyInfo.companyBaseModel.id//id
                params.companyBaseModel.logoFileUrl =  this.state.logoFileUrl//企业logo
                editCompany(params).then((res)=>{
                    if(res.status===200){
                        this.props.form.resetFields();//取消先清空之前输入的数据
                        this.setState({ //跳转到查看页面
                            display_none: 'none',
                            display_block: 'block',
                        })
                        this.props.requestList().then(res=>{
                            this.initialBasic(res)
                        })
                    }
                })
            }
        });
    };
    //取消按钮
    handleReset = () => {
        this.props.form.resetFields();//取消先清空之前输入的数据
        this.setState({ //跳转到查看页面
            display_none: 'none',
            display_block: 'block',
        })
    };
    previewImg= (type)=>{
        switch (type) {
            case 'corp':
                Modal.info({
                    title:"预览",
                    width:800,
                    content:<img src={this.state.corpFileUrl} alt="预览" style={{ width: '100%' }}/>
                })
                break;
            case 'logo':
                Modal.info({
                    title:"预览",
                    width:800,
                    content:<img src={this.state.logoFileUrl} alt="预览" style={{ width: '100%' }}/>
                })
                break
            default:
                break;
        }
    }
    render() {
        let _this = this
        const { getFieldDecorator } = this.props.form;
        //上传图片-按钮
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const uploadButtonCorp = (
            <div>
                <Icon type={this.state.loadingCorp ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        //上传控件参数
        const uploadCorp = {
            name: 'corpFileUrl',
            listType:"picture-card",
            className:"avatar-uploader",
            showUploadList:false,
            fileList:null,

            onPreview:()=>{},
            beforeUpload(file){
                _this.setState({
                    loadingCorp:true
                })
                utils.upLoad(file).then(res=>{
                    if(res){
                        _this.setState({
                            corpFileUrl:res.url,
                            loadingCorp:false
                        })
                    }
                })
                return false
            }
        }
        const uploadLogo = {
            name: 'logoFileUrl',
            listType:"picture-card",
            className:"avatar-uploader",
            showUploadList:false,
            fileList:null,
            onPreview:()=>{},
            beforeUpload(file){
                _this.setState({
                    loading:true
                })
                utils.upLoad(file).then(res=>{
                    if(res){
                        _this.setState({
                            logoFileUrl:res.url,
                            loading:false
                        })
                    }
                })
                return false
            }
        };
        const { imageUrl,logoUrl } = this.state;
        const {companyInfo} = this.state
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="其他信息" 
                extra={
                    <Button style={{display:this.state.display_block}} type="primary" onClick={this.switchTal}>编辑</Button>
                }>
                    <Form className="unitBasicView" style={{display:this.state.display_block}} layout="inline">
                        <Form.Item label="申请人">
                            <div className="unitBasicData">{companyInfo.companyPlatformModel?companyInfo.companyPlatformModel.corpApplyName:' '}</div>
                        </Form.Item>
                        <Form.Item label="申请人手机号">
                            <div className="unitBasicData">{companyInfo.companyPlatformModel?companyInfo.companyPlatformModel.corpApplyMobile:' '}</div>
                        </Form.Item>
                        <Form.Item label="创建时间">
                            <div className="unitBasicData">{companyInfo.companyBaseModel&&companyInfo.companyBaseModel.createDate?moment(companyInfo.companyBaseModel.createDate).format('YYYY-MM-DD'):' '}</div>
                        </Form.Item>
                        {/* <Form.Item label="最后修改人">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.updateDate:' '}</div>
                        </Form.Item> */}
                        <Form.Item label="最后修改时间">
                            <div className="unitBasicData">{companyInfo.companyBaseModel&&companyInfo.companyBaseModel.updateDate?moment(companyInfo.companyBaseModel.updateDate).format('YYYY-MM-DD'):' '}</div>
                        </Form.Item>
                        <Form.Item label="标签">
                            <div className="unitBasicData">{companyInfo.companyPlatformAdminModel&&companyInfo.companyPlatformAdminModel.isAdminAdd?'内部人员':' '}</div>
                        </Form.Item>
                        <Form.Item label="备注">
                            <div className="unitBasicData">{companyInfo.companyPlatformAdminModel?companyInfo.companyPlatformAdminModel.remark:' '}</div>
                        </Form.Item>
                        {/* <Form.Item className="kongForm"></Form.Item> */}
                        <Form.Item label="证照照片">
                            <div className="">{companyInfo.companyPlatformModel&&companyInfo.companyPlatformModel.corpFileUrl?<img src={companyInfo.companyPlatformModel.corpFileUrl} alt="预览" className="uploadImg" style={{ width: '100%' }} onClick={()=>this.previewImg('corp')}/>:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业logo">
                            <div className="">{companyInfo.companyBaseModel&&companyInfo.companyBaseModel.logoFileUrl?<img src={companyInfo.companyBaseModel.logoFileUrl} alt="预览" className="uploadImg" style={{ width: '100%' }} onClick={()=>this.previewImg('logo')}/>:' '}</div>
                        </Form.Item>
                    </Form>

                    <Form className="unitBasicEdit" style={{display:this.state.display_none}} layout="inline" onSubmit={this.handleSubmit}>
                        <Form.Item label="申请人">
                            {getFieldDecorator('corpApplyName',{
                                initialValue:companyInfo.companyPlatformModel?companyInfo.companyPlatformModel.corpApplyName:' '
                            })(
                                <Input disabled/>
                            )}
                        </Form.Item>
                        <Form.Item label="申请人手机号">
                            {getFieldDecorator('corpApplyMobile',{
                                initialValue:companyInfo.companyPlatformModel?companyInfo.companyPlatformModel.corpApplyMobile:''
                            })(
                                <Input disabled/>
                            )}
                        </Form.Item>
                        <Form.Item label="创建时间">
                            {getFieldDecorator('createDate',{
                                initialValue:companyInfo.companyBaseModel&&companyInfo.companyBaseModel.createDate?moment(companyInfo.companyBaseModel.createDate).format('YYYY-MM-DD'):null
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        {/* <Form.Item label="最后修改人">
                            {getFieldDecorator('headman',{
                                initialValue:"陈晓薇"
                            })(
                                <Input disabled placeholder="最后修改人"/>
                            )}
                        </Form.Item> */}
                        <Form.Item label="最后修改时间">
                            {getFieldDecorator('updateDate',{
                                initialValue:companyInfo.companyBaseModel&&companyInfo.companyBaseModel.updateDate?moment(companyInfo.companyBaseModel.updateDate).format('YYYY-MM-DD'):null
                            })(
                                <Input disabled/>
                            )}
                        </Form.Item>
                        <Form.Item label="标签">
                            {getFieldDecorator('isAdminAdd',{
                                initialValue:companyInfo.companyPlatformAdminModel?STATUS.IS_ADMIN_ADD[companyInfo.companyPlatformAdminModel.isAdminAdd]:''
                            })(
                                <Input disabled/>
                            )}
                        </Form.Item>
                        <Form.Item label="备注">
                            {getFieldDecorator('remark',{
                                initialValue:companyInfo.companyPlatformAdminModel?companyInfo.companyPlatformAdminModel.remark:' '
                            })(
                                <Input disabled/>
                            )}
                        </Form.Item>
                        {/* <Form.Item className="kongForm"></Form.Item> */}
                        <Form.Item label="证照照片" className="uploadImgBtn">
                            {this.state.corpFileUrl ? <img src={this.state.corpFileUrl} alt="证照照片" className="uploadImg" onClick={()=>this.previewImg('corp')}/> : null}
                            {getFieldDecorator('corpFileUrl')(
                                <Upload
                                    {...uploadCorp}
                                >   
                                    {uploadButtonCorp}
                                </Upload>
                            )}
                        </Form.Item>
                        <Form.Item label="企业logo" className="uploadImgBtn">
                            {this.state.logoFileUrl ? <img src={this.state.logoFileUrl} alt="企业logo" className="uploadImg" onClick={()=>this.previewImg('logo')}/> : null}
                            {getFieldDecorator('logoFileUrl')(
                                <Upload
                                    {...uploadLogo}
                                >   
                                    {uploadButton}
                                </Upload>
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                保 存
                            </Button>
                            <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                                取 消
                            </Button>
                        </Form.Item>
                    </Form>
                   
                </Card> 
            </div>
        )
    }
}

const UnitOtherForm = Form.create()(UnitOther);

export default UnitOtherForm;

//export default Form.create()(UnitBusiness); 