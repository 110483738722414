/**
 * @file 组件：业务员选择
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { getAllAgentBusinessList } from '../../../Api'
import { Select } from 'antd'

const {Option} = Select

/**
 * 组件：业务员选择
 * @class
 * @extends React.Component
 */
export default class SalesManager extends React.Component {
    /**
     * 组件状态存储区域
     * @type {Object}
     */
    state = {selections: [<Option value="0" key='boss-none'>请选择上级领导</Option>]}

    /**
     * 组件载入后执行的操作
     * @type {Function}
     */
    componentDidMount() {
        getAllAgentBusinessList({agentBusinessType: 1}).then(res => {
            this.setState({selections: res.data.result.map(item => {
                return <Option value={item.id} key={'boss-'+item.id}>{item.name}</Option>
            })})
        })
    }

    /**
     * 数值变化时进行操作
     * @type {Function} 
     * @param {String} value 当前值
     */ 
    handleChange = value => {
        const {onChange} = this.props
        if (onChange) {
            onChange(value)
        }
    }
    
    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => <Select onChange={this.handleChange} value={this.props.value}>
            {this.state.selections}
            </Select>
            
}