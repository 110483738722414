import React, { Component } from 'react'
import {Button,Modal,Table,message,Icon} from 'antd'
import SearchForm from '../component/form/Search'       //搜索框组件
import Checkmodal from '../component/form/Checkmodal'   //Modal-form组件
import moment from 'moment'
import STATUS from '../../../Common/js/status'
import AddLegalForm from '../../Data/components/addLegalForm'  //编辑法规库组件
import { getLegalForm, legalEdit,queryLawInfoById,getStandardLawSuggestionListData,ignoreStandardLawSuggestion,updateStandardLawSuggestion,updateAndSolvedStandardLawSuggestion } from './../../../Api'
import { homeCheckList, checkPass } from '../../../Mock'
import { connect } from 'react-redux'
import utils from '../../../Common/js/utils'
//首页-法律法规-未处理Tab
class Legalcheck extends Component {
    state = {
        formList: [{
            name: "keyword",
            label: "法规",
            type: 'input',
            placeholder: '法规/标准名称/文号'
        }],
        //初始化Modal
        formList2: {
            title: '是否忽略此记录？',
            list: [{
                name: "remarks",
                label: "备注",
                type: 'textArea',
                placeholder: '请输入备注'
            }]
        },
        visibleCheckModal: false,
        page: {
            pageNum: 1,
            pageSize: 30
        },
    }
    //通过Api获取表格数据
    getList = (params) => {
        this.setState({
            params:params
        })
        let data = {  ...params,...this.state.page }
        let _this = this
        data.state = 0
        getStandardLawSuggestionListData(data).then(res => {
            console.log(res)
            if (res.data.state.value == 0) {
                res.data.result.rows.map(item => {
                    item.key = item.id
                    return item
                })
                this.setState({
                    tableList: res.data.result.rows,
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        this.getList(params)
                    }, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        this.getList(params)
                    }, res.data.result.pageSize)
                })
            }

        })
    }
    //挂载前获取表格数据
    componentWillMount() {
        this.getList()
    }
    //弹框的“确认”回调 需返回Promise对象
    handleCheck = (data) => {
        data.id = this.state.currentId
        return ignoreStandardLawSuggestion(data).then((res) => {
            console.log(res)
            return new Promise((resolve, reject) => {
                if (res.data.state.value == 0) {
                    this.setState({
                        visibleCheckModal: false
                    })
                    message.success('操作成功！')
                    this.getList(this.state.params)
                    resolve(true)
                } else {
                    reject(false)
                }
            })
        })
    }
    //Modal-Form弹窗根据状态设定Modal内容
    handleOpenCheck = (record) => {
        this.setState({
            currentId:record.id
        })
        this.setState({ visibleCheckModal: true })
    }
    //更改弹框的显示状态
    handleVisibleChange = () => {
        this.setState({
            visibleCheckModal: !this.state.visibleCheckModal
        })
    }
    //拼接代替修订
    reStoreStr = (record) => {
        let keys = 18898
        if (record.type == 0) {
            return {
                str: record.replaceRelationship,
            }
        } else if (record.type == 1) {
            //法律
            let str = ''
            if (record.replaceOrRevisionr && record.replaceOrRevisionr != "") {
                if (record.replaceOrRevisionr.indexOf(',') > 0) {
                    let replaceOrRevisionrStr = record.replaceOrRevisionr.split(',').slice(0, record.replaceOrRevisionr.length - 1)
                    let replaceOrRevisionrIds = record.replaceOrRevisionrId.split(',').slice(0, record.replaceOrRevisionrId.length - 1)
                    replaceOrRevisionrStr.map((item, index) => {
                        str += "修订为" + replaceOrRevisionrStr[index] + "；"
                    })
                } else {
                    str += "修订为" + record.replaceOrRevisionr + "；"
                }
            }
            if (record.portionRevisionOrRevisionr && record.portionRevisionOrRevisionr != "") {
                if (record.portionRevisionOrRevisionr.indexOf(',') > 0) {
                    let portionRevisionOrRevisionrStr = record.portionRevisionOrRevisionr.split(',').slice(0, record.portionRevisionOrRevisionr.length - 1)
                    let portionRevisionOrRevisionrIds = record.portionRevisionOrRevisionrId.split(',').slice(0, record.portionRevisionOrRevisionrId.length - 1)
                    portionRevisionOrRevisionrStr.map((item, index) => {
                        str += "由" + portionRevisionOrRevisionrStr[index] + "修订；"
                    })
                } else {
                    str += "由" + record.portionRevisionOrRevisionr + "修订；"
                }
            }
            if (record.revisionOrReplaceRemark) {
                str += record.revisionOrReplaceRemark
            }
            return {
                str: str,
            }
        } else if (record.type == 2) {
            //法律
            let str = ''
            if (record.replaceOrRevisionr && record.replaceOrRevisionr != "") {
                if (record.replaceOrRevisionr.indexOf(',') > 0) {
                    let replaceOrRevisionrStr = record.replaceOrRevisionr.split(',').slice(0, record.replaceOrRevisionr.length - 1)
                    let replaceOrRevisionrIds = record.replaceOrRevisionrId.split(',').slice(0, record.replaceOrRevisionrId.length - 1)
                    replaceOrRevisionrStr.map((item, index) => {
                        str += "代替" + replaceOrRevisionrStr[index] + "；"
                    })
                } else {
                    str += "代替" + record.replaceOrRevisionr + "；"
                }
            }
            if (record.portionRevisionOrRevisionr && record.portionRevisionOrRevisionr != "") {
                if (record.portionRevisionOrRevisionr.indexOf(',') > 0) {
                    let portionRevisionOrRevisionrStr = record.portionRevisionOrRevisionr.split(',').slice(0, record.portionRevisionOrRevisionr.length - 1)
                    let portionRevisionOrRevisionrIds = record.portionRevisionOrRevisionrId.split(',').slice(0, record.portionRevisionOrRevisionrId.length - 1)
                    portionRevisionOrRevisionrStr.map((item, index) => {
                        str += "部分代替" + portionRevisionOrRevisionrStr[index] + "；"
                    })
                } else {
                    str += "部分代替" + record.portionRevisionOrRevisionr + "；"
                }
            }
            if (record.beReplace && record.beReplace != "") {
                if (record.beReplace.indexOf(',') > 0) {
                    let beReplaceStr = record.beReplace.split(',').slice(0, record.beReplace.length - 1)
                    let beReplaceIds = record.beReplaceId.split(',').slice(0, record.beReplaceId.length - 1)
                    beReplaceStr.map((item, index) => {
                        str += "被" + beReplaceStr[index] + "代替；"
                    })
                } else {
                    str += "被" + record.beReplace + "代替；"
                }
            }
            if (record.revisionOrReplaceRemark) {
                str += record.revisionOrReplaceRemark
            }
            return {
                str: str,
            }
        }
    }
    //格式化数据-保存法规库更改
    reStoreFileValue = (filedValue) => {
        if (filedValue) {
            let params = {}
            //处理代替修订字段
            let replaceOrRevisionrStr = ''
            let replaceOrRevisionrIds = ''
            let portionRevisionOrRevisionrStr = ''
            let portionRevisionOrRevisionrIds = ''
            let beReplaceStr = ''
            let beReplaceIds = ''
            switch (this.legalRef.getLegalTypeForFather()) {
                case 0://解读
                    params.type = 0
                    // params.fileId = filedValue.readFileId
                    if (filedValue.readFileId != '') {
                        if (filedValue.readFileId == 'del') {
                            params.fileId = ""
                        } else {
                            params.fileId = filedValue.readFileId
                        }
                    }
                    // params.fileName = filedValue.fileName
                    // params.readLawId = filedValue.readLawId

                    params.lawId = filedValue.JDreadLawId && filedValue.JDreadLawId.length && filedValue.JDreadLawId[0].key ? filedValue.JDreadLawId[0].key : ''
                    params.lawClassId = filedValue.lawClassId && filedValue.lawClassId.length ? filedValue.lawClassId[filedValue.lawClassId.length - 1] : null
                    break;
                case 1://法律法规
                    params.type = 1
                    filedValue.replace && filedValue.replace.map(item => {
                        replaceOrRevisionrStr += item.label + ','
                        replaceOrRevisionrIds += item.key + ','
                    })
                    filedValue.replaced && filedValue.replaced.map(item => {
                        portionRevisionOrRevisionrStr += item.label + ','
                        portionRevisionOrRevisionrIds += item.key + ','
                    })
                    //处理新增字段
                    //修订为...
                    params.replaceOrRevisionr = replaceOrRevisionrStr.slice(0, replaceOrRevisionrStr.length - 1)
                    params.replaceOrRevisionrId = replaceOrRevisionrIds.slice(0, replaceOrRevisionrIds.length - 1)
                    //由...修订
                    params.portionRevisionOrRevisionr = portionRevisionOrRevisionrStr.slice(0, portionRevisionOrRevisionrStr.length - 1)
                    params.portionRevisionOrRevisionrId = portionRevisionOrRevisionrIds.slice(0, portionRevisionOrRevisionrIds.length - 1)

                    // params.fileNo = filedValue.fileNo
                    params.hyName = filedValue.hyName
                    params.department = filedValue.department
                    params.lawStatus = filedValue.lawStatus
                    params.status = STATUS.LAW_STATUS[filedValue.lawStatus]
                    params.fileNo = filedValue.fileNo
                    params.releaseDate = filedValue.releaseDate ? moment(filedValue.releaseDate).format('YYYY-MM-DD') : null
                    params.executeDate = filedValue.executeDate ? moment(filedValue.executeDate).format('YYYY-MM-DD') : null
                    params.cancelDate = filedValue.cancelDate ? moment(filedValue.cancelDate).format('YYYY-MM-DD') : null
                    params.readFileName = filedValue.jdname
                    params.projClassId = filedValue.projClassId && filedValue.projClassId.length ? filedValue.projClassId[filedValue.projClassId.length - 1] : null
                    // params.lawClassId = filedValue.lawClassId&&filedValue.lawClassId.length?filedValue.lawClassId[filedValue.lawClassId.length-1]:null
                    params.remake = filedValue.remake
                    params.fileId = filedValue.fileId
                    if (filedValue.readFileId != '') {
                        if (filedValue.readFileId == 'del') {
                            params.readFileId = ""
                        } else {
                            params.readFileId = filedValue.readFileId
                        }
                    }
                    if (filedValue.readLawId) {
                        params.readLawId = filedValue.readLawId
                    }
                    params.revisionOrReplaceRemark = filedValue.revisionOrReplaceRemark
                    let objStr1 = this.reStoreStr({
                        type: params.type,
                        portionRevisionOrRevisionr: params.portionRevisionOrRevisionr,
                        portionRevisionOrRevisionrId: params.portionRevisionOrRevisionrId,
                        replaceOrRevisionr: params.replaceOrRevisionr,
                        replaceOrRevisionrId: params.replaceOrRevisionrId,
                        revisionOrReplaceRemark: params.revisionOrReplaceRemark,
                    })
                    params.replaceRelationship = objStr1.str
                    break
                case 2://标准
                    params.type = 2
                    filedValue.beReplace && filedValue.beReplace.map(item => {
                        beReplaceStr += item.label + ','
                        beReplaceIds += item.key + ','
                    })
                    filedValue.beReplaced && filedValue.beReplaced.map(item => {
                        replaceOrRevisionrStr += item.label + ','
                        replaceOrRevisionrIds += item.key + ','
                    })
                    filedValue.bePartReplace && filedValue.bePartReplace.map(item => {
                        portionRevisionOrRevisionrStr += item.label + ','
                        portionRevisionOrRevisionrIds += item.key + ','
                    })
                    //处理新增字段
                    //被...代替
                    params.beReplace = beReplaceStr.slice(0, beReplaceStr.length - 1)
                    params.beReplaceId = beReplaceIds.slice(0, beReplaceIds.length - 1)
                    //替代
                    params.replaceOrRevisionr = replaceOrRevisionrStr.slice(0, replaceOrRevisionrStr.length - 1)
                    params.replaceOrRevisionrId = replaceOrRevisionrIds.slice(0, replaceOrRevisionrIds.length - 1)
                    //部分代替
                    params.portionRevisionOrRevisionr = portionRevisionOrRevisionrStr.slice(0, portionRevisionOrRevisionrStr.length - 1)
                    params.portionRevisionOrRevisionrId = portionRevisionOrRevisionrIds.slice(0, portionRevisionOrRevisionrIds.length - 1)
                    // params.fileNo = filedValue.fileNo
                    params.hyName = filedValue.hyName
                    params.department = filedValue.department
                    params.lawStatus = filedValue.lawStatus
                    params.status = STATUS.LAW_STATUS[filedValue.lawStatus]
                    params.fileNo = filedValue.fileNo
                    params.releaseDate = filedValue.releaseDate ? moment(filedValue.releaseDate).format('YYYY-MM-DD') : null
                    params.executeDate = filedValue.executeDate ? moment(filedValue.executeDate).format('YYYY-MM-DD') : null
                    params.cancelDate = filedValue.cancelDate ? moment(filedValue.cancelDate).format('YYYY-MM-DD') : null
                    params.readFileName = filedValue.jdname
                    params.projClassId = filedValue.projClassId && filedValue.projClassId.length ? filedValue.projClassId[filedValue.projClassId.length - 1] : null
                    // params.lawClassId = filedValue.lawClassId&&filedValue.lawClassId.length?filedValue.lawClassId[filedValue.lawClassId.length-1]:null
                    params.revisionOrReplaceRemark = filedValue.revisionOrReplaceRemark
                    params.remake = filedValue.remake
                    params.fileId = filedValue.fileId
                    if (filedValue.readFileId != '') {
                        if (filedValue.readFileId == 'del') {
                            params.readFileId = ""
                        } else {
                            params.readFileId = filedValue.readFileId
                        }
                    }
                    if (filedValue.readLawId) {
                        params.readLawId = filedValue.readLawId
                    }
                    let objStr2 = this.reStoreStr({
                        type: params.type,
                        portionRevisionOrRevisionr: params.portionRevisionOrRevisionr,
                        portionRevisionOrRevisionrId: params.portionRevisionOrRevisionrId,
                        replaceOrRevisionr: params.replaceOrRevisionr,
                        replaceOrRevisionrId: params.replaceOrRevisionrId,
                        beReplace: params.beReplace,
                        beReplaceId: params.beReplaceId,
                        revisionOrReplaceRemark: params.revisionOrReplaceRemark,
                    })
                    params.replaceRelationship = objStr2.str
                    break
                default:
                    params = null
                    break
            }
            params.id = filedValue.id

            //文件上传
            params.fileName = filedValue.fileName
            // params.ossUrl = filedValue.originalUrl
            // params.lawOssUrl = filedValue.jdUrl
            return params
        }
    }
    //对接接口-编辑法规库
    editLegalSubmit = (submitType) => {
        new Promise(resolve => {
            this.setState({ editSubmitFlag: false })
            resolve()
        }).then(() => {
            let filedValue = this.legalRef.handleAaddLegalFilterSubmit()
            let params = this.reStoreFileValue(filedValue)
            if (Object.keys(params).length != 0) {
                if (submitType == 'submit') {
                    updateStandardLawSuggestion(params).then(res => {
                        if (res.status === 200 && res.data.state.value === 0) {
                            message.success('保存数据成功！')
                            this.setState({
                                editLegalShow: false
                            })
                            this.getList(this.state.params)
                            this.legalRef.handleresetLegalFilter()
                        } else {
                            message.warning(res.data.content)
                        }
                    })
                } else if (submitType == 'save') {
                    updateAndSolvedStandardLawSuggestion(params,this.state.currentId).then(res => {
                        if (res.status === 200 && res.data.state.value === 0) {
                            message.success('保存并解决成功！')
                            this.setState({
                                editLegalShow: false
                            })
                            this.getList(this.state.params)
                            this.legalRef.handleresetLegalFilter()
                        } else {
                            message.warning(res.data.content)
                        }
                    })
                    console.log('保存并解决')
                }
            }
        })
    }
    //对接表格内编辑
    handleEditOpen = (record) => {
        this.setState({
            currentId:record.id
        })
        //暂时法律id
        queryLawInfoById({ lawId: record.lawId }).then(res => {
            if (res.status === 200) {
                res.data.id = record.id
                new Promise((resolve) => {
                    this.setState({
                        legalFormState: res.data.result,
                        editSubmitFlag: true,
                    })
                    resolve()
                }).then(() => {
                    this.setState({
                        editLegalShow: true,
                    })
                })
            } else {
                this.renderModalSimple('warning', '错误', '无法编辑，请稍后再试!')
            }
        })
        // console.log(record, 'edit')
    }
    render() {
        let _this = this
        //Modal布局
        let formItemLayout = {
            labelCol: {
                xs: 24,
                sm: 2
            },
            wrapperCol: {
                xs: 24,
                sm: 22
            }
        }
        const columns = [
            {
                title: "法规/标准名称",
                dataIndex: 'lawName',
                align: 'center',
                width: 100,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "文号",
                dataIndex: 'lawNo',
                align: 'center',
                width: 80,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "问题",
                dataIndex: 'content',
                align: 'center',
                width: 80,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "反馈来源",
                dataIndex: 'sourceType',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={STATUS.LEGAL_SOURCE[text]}>{STATUS.LEGAL_SOURCE[text]}</span>
                },

            },
            {
                title: "提交时间",
                dataIndex: 'updateDate',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={moment(text).format('YYYY-MM-DD')}>{moment(text).format('YYYY-MM-DD')}</span>
                },

            }, 
            {
                title: "状态",
                dataIndex: 'state',
                align: 'center',
                width: 30,
                render(text) {
                    return <span title={STATUS.HOME_LEGAL_STATUS[text]}>{STATUS.HOME_LEGAL_STATUS[text]}</span>
                },

            }, {
                title: "操作",
                align: 'center',
                width: 50,
                render(text, record) {
                    return <div>
                        
                        <a onClick={() => _this.handleEditOpen(record)}><Icon type="check-circle" title="查看并解决" className="mr10 table-icon-green"/></a>
                        <a onClick={() => _this.handleOpenCheck(record)}><Icon type="stop" title="忽略" className="table-icon-red"/></a>
                    </div>
                },

            },
            {
                title: "lawId",
                dataIndex: 'lawId',
                className:'disnone'

            }, 
        ]
        return (
            <div className="home">
                <SearchForm formList={this.state.formList} formSearchApi={(data) => this.getList(data)} />
                <Table
                    className="mt20"
                    bordered
                    columns={columns}
                    dataSource={this.state.tableList}
                    pagination={{ ...this.state.pagination, pageSize: this.state.page.pageSize }}
                />
                <Checkmodal formItemLayout={formItemLayout} formSubmitApi={this.handleCheck} handleVisibleChange={this.handleVisibleChange} visibleCheckModal={this.state.visibleCheckModal} formList={this.state.formList2} />
                <Modal
                    wrapClassName='modal-max-height-500'
                    onCancel={() => {
                        this.legalRef.handleresetLegalFilter()
                        this.setState({
                            editLegalShow: false
                        })
                    }}
                    title="编辑法规库项目"
                    visible={this.state.editLegalShow}
                    footer={[
                        <Button key="back" onClick={() => {
                            this.legalRef.handleresetLegalFilter()
                            this.setState({
                                editLegalShow: false
                            })
                        }}>取消</Button>,
                        <Button key="save" type="primary" onClick={() => this.editLegalSubmit('submit')}>
                            保存
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => this.editLegalSubmit('save')}>
                            保存并解决
                        </Button>
                    ]}
                >
                    <AddLegalForm wrappedComponentRef={(inst) => this.legalRef = inst} type="editLegalForm" initFormState={this.state.legalFormState} flag={this.state.editSubmitFlag}></AddLegalForm>
                </Modal>
            </div>
        )
    }
}
export default connect()(Legalcheck);