import React, { Component } from 'react'
import { Form, Card, Pagination, Select, Input, DatePicker, Checkbox, Table, Button, Modal, Icon, Upload, Cascader, message } from 'antd'
import moment from 'moment'
import { Resizable } from 'react-resizable'
import STATUS from './../../Common/js/status'
import TreeEdit from './../../Components/components/treeEdit'
import AddLegalForm from './components/addLegalForm'
import { getTable, getBelongProject, getUrl, getLegalTypeInit, getLegalType, deleteTreeList, searchLegalOptions, addTree, editTree, fileTem, getLegalForm, legalAdd, legalEdit, legalSearch, editProject, deleteList, projectManage, projectManageAdd, projectManageDel, projectManageEdit } from '../../Api'
import utils from './../../Common/js/utils'
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let keys = 958958954
/**
 * @description 表头拉伸组件
 * @function ResizeableTitle
 * @param {Object} props 传入表格的属性
 * @returns {HTMLElement}
 */
const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
export default class LegalManagement extends Component {
  /**
   * 组件内数据
   * @type {Object} state -LegalManagement类初始数据
   * @property {Boolean} treeShow -项目管理树显示状态
   * @property {Boolean} editProject -修改项目Modal显示状态
   * @property {Boolean} videoUrl -视频链接
   * @property {Boolean} addLegalShow -新增法律法规Modal显示状态
   * @property {Boolean} editLegalShow -编辑法律法规Modal显示状态
   * @property {Boolean} isPDFShow -查看pdf文件Modal显示状态
   * @property {Array} selectedIds -表格选中的记录id数组
   * @property {Array} tableList -法规表格数据
   * @property {Object} getParams -列表查询参数
   * @property {Array} legalType -法规类型级联的数据
   * @property {Array} belongProject -所属项目级联的数据
   * @property {Array} columns -表格列定义
   */
  state = {
    treeShow: false,
    editProject: false,
    addLegalShow: false,
    editLegalShow: false,
    isPDFShow: false,
    selectedIds: [],
    tableList: [],
    getParams: {
      pageNo: 1,
      pageSize: 30
    },
    legalType: [],
    belongProject: [],
    columns: [
      {
        title: "序号",
        dataIndex: 'key',
        align: 'center',
        width: 50,
        render(text) {
          return <span title={text}>{text}</span>
        }
      }, {
        title: "名称",
        dataIndex: "fileName",
        align: 'center',
        width: 150,
        render: (text, record) => {
          return <div>
            <a onClick={() => this.handleEnterDetailed(record)} className="mlr5">{text}</a>
          </div>
        },
      }, {
        title: "文号",
        dataIndex: "fileNo",
        align: 'center',
        width: 150,
        render(text) {
          return <div
            title={text}
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-all'
            }}>{text}</div>
        },
      }, {
        title: "状态",
        dataIndex: "lawStatus",
        align: 'center',
        render(text) {
          return <span title={STATUS.LAW_STATUS[text]}>{STATUS.LAW_STATUS[text]}</span>
        },
        width: 40,
      }, {
        title: "颁布部门",
        dataIndex: "department",
        align: 'center',
        width: 80,
        render(text) {
          return <span title={text}>{text}</span>
        }
      }, {
        title: "颁布日期",
        dataIndex: "releaseDate",
        align: 'center',
        width: 90,
        render(text) {
          if (text) {
            return <span title={moment(text).format('YYYY-MM-DD')}>{moment(text).format('YYYY-MM-DD')}</span>
          } else {
            return '-'
          }
        },
      }, {
        title: "实施/失效日期",
        dataIndex: "executeOrCancelDate",
        align: 'center',
        width: 90,
        render(text) {
          if (text) {
            return <span title={moment(text).format('YYYY-MM-DD')}>{moment(text).format('YYYY-MM-DD')}</span>
          } else {
            return '-'
          }
        },
      }, {
        title: "法律效力",
        dataIndex: "lawClassName",
        align: 'center',
        width: 40,
        render(text) {
          return <span title={text}>{text}</span>
        },
      }, {
        title: "代替/修订情况",
        align: 'center',
        render: (text, record) => {
          let obj = this.reStoreStr(record)
          return obj.dom
        },
        width: 150,
      }, {
        title: "点击量",
        dataIndex: "clickCount",
        align: 'center',
        width: 50,
        render(text) {
          return <span title={text}>{text}</span>
        },
      }, {
        title: "下载量",
        dataIndex: "downloadCount",
        align: 'center',
        width: 50,
        render(text) {
          return <span title={text}>{text}</span>
        },
      }, {
        title: "收藏量",
        dataIndex: "collectCount",
        align: 'center',
        width: 50,
        render(text) {
          return <span title={text}>{text}</span>
        },
      }, {
        title: "操作",
        align: 'center',
        render: (text, record) => {
          return <div>
            <a onClick={() => this.handleCompanyTableEdit(record)} className="mlr5"><Icon title="编辑" type="edit" style={{ "fontSize": "1.2em", "marginRight": "10px" }} /></a>
            <a onClick={() => this.handleCompanyTableDelete(record)} className="red"><Icon title="删除" type="delete" style={{ "fontSize": "1.2em" }} /></a>
          </div>
        },
        width: 120,
      }, {
        title: "id",
        dataIndex: "id",
        className: "dn",
        width: 0
      }, {
        title: "ossUrl",
        dataIndex: "ossUrl",
        className: "dn",
        width: 0
      }, {
        title: "portionRevisionOrRevisionr",
        dataIndex: "portionRevisionOrRevisionr",
        className: "dn",
        width: 0
      }, {
        title: "portionRevisionOrRevisionrId",
        dataIndex: "portionRevisionOrRevisionrId",
        className: "dn",
        width: 0
      }, {
        title: "beReplace",
        dataIndex: "beReplace",
        className: "dn",
        width: 0
      }, {
        title: "beReplaceId",
        dataIndex: "beReplaceId",
        className: "dn",
        width: 0
      },
      {
        title: "replaceOrRevisionr",
        dataIndex: "replaceOrRevisionr",
        className: "dn",
        width: 0
      }, {
        title: "replaceOrRevisionrId",
        dataIndex: "replaceOrRevisionrId",
        className: "dn",
        width: 0
      }, {
        title: "type",
        dataIndex: "type",
        className: "dn",
        width: 0
      }, {
        title: "replaceRelationship",
        dataIndex: "replaceRelationship",
        className: "dn",
        width: 0
      }

    ]
  };
  /**
   * 拼接“代替修订”字段
   * @param {Object} record -法规记录详细数据
   * @returns {Object} -拼接后的字符串和DOM
   */
  reStoreStr = (record) => {
    //法律效力类型 0表示早期版本的数据,没有链接 1表示法律，2表示标准
    /** 法律效力类型：0-早期版本的数据,没有链接；1-表示法律；2-表示标准 */
    /** type=0  期版本的数据,没有链接 */
    if (record.type == 0) {
      return {
        str: record.replaceRelationship,
        dom: <span key={keys++} title={record.replaceRelationship}>{record.replaceRelationship}</span>
      }
    } else if (record.type == 1) {
      /** type=1  表示法律 */
      let temp = []
      let str = ''
      /** 拼接“修订为”字段  */
      if (record.replaceOrRevisionr && record.replaceOrRevisionr != "") {
        if (record.replaceOrRevisionr.indexOf(',') > 0) {
          let replaceOrRevisionrStr = record.replaceOrRevisionr.split(',').slice(0, record.replaceOrRevisionr.length - 1)
          let replaceOrRevisionrIds = record.replaceOrRevisionrId.split(',').slice(0, record.replaceOrRevisionrId.length - 1)
          replaceOrRevisionrStr.map((item, index) => {
            temp.push(<span key={keys++}>修订为<a onClick={() => this.getFileUrl(replaceOrRevisionrIds[index])}>{replaceOrRevisionrStr[index]}</a>；</span>)
            str += "修订为" + replaceOrRevisionrStr[index] + "；"
          })
        } else {
          temp.push(<span key={keys++}>修订为<a onClick={() => this.getFileUrl(record.replaceOrRevisionrId)}>{record.replaceOrRevisionr}</a>；</span>)
          str += "修订为" + record.replaceOrRevisionr + "；"
        }
      }
      /** 拼接“由...修订”字段  */
      if (record.portionRevisionOrRevisionr && record.portionRevisionOrRevisionr != "") {
        if (record.portionRevisionOrRevisionr.indexOf(',') > 0) {
          let portionRevisionOrRevisionrStr = record.portionRevisionOrRevisionr.split(',').slice(0, record.portionRevisionOrRevisionr.length - 1)
          let portionRevisionOrRevisionrIds = record.portionRevisionOrRevisionrId.split(',').slice(0, record.portionRevisionOrRevisionrId.length - 1)
          portionRevisionOrRevisionrStr.map((item, index) => {
            temp.push(<span key={keys++}>由<a onClick={() => this.getFileUrl(portionRevisionOrRevisionrIds[index])}>{portionRevisionOrRevisionrStr[index]}</a>修订；</span>)
            str += "由" + portionRevisionOrRevisionrStr[index] + "修订；"
          })
        } else {
          temp.push(<span key={keys++}>由<a onClick={() => this.getFileUrl(record.portionRevisionOrRevisionrId)}>{record.portionRevisionOrRevisionr}</a>修订；</span>)
          str += "由" + record.portionRevisionOrRevisionr + "修订；"
        }
      }
      /** 代替or修订的描述  */
      if (record.revisionOrReplaceRemark) {
        temp.push(<span key={keys++}>{record.revisionOrReplaceRemark}</span>)
        str += record.revisionOrReplaceRemark
      }
      return {
        str: str,
        dom: <div key={keys++} title={str}>{temp}</div>
      }
    } else if (record.type == 2) {
      /** type=1  表示标准 */
      let temp = []
      let str = ''
      /** 拼接“代替”字段  */
      if (record.replaceOrRevisionr && record.replaceOrRevisionr != "") {
        if (record.replaceOrRevisionr.indexOf(',') > 0) {
          let replaceOrRevisionrStr = record.replaceOrRevisionr.split(',').slice(0, record.replaceOrRevisionr.length - 1)
          let replaceOrRevisionrIds = record.replaceOrRevisionrId.split(',').slice(0, record.replaceOrRevisionrId.length - 1)
          replaceOrRevisionrStr.map((item, index) => {
            temp.push(<span key={keys++}>代替<a onClick={() => this.getFileUrl(replaceOrRevisionrIds[index])}>{replaceOrRevisionrStr[index]}</a>；</span>)
            str += "代替" + replaceOrRevisionrStr[index] + "；"
          })
        } else {
          temp.push(<span key={keys++}>代替<a onClick={() => this.getFileUrl(record.replaceOrRevisionrId)}>{record.replaceOrRevisionr}</a>；</span>)
          str += "代替" + record.replaceOrRevisionr + "；"
        }
      }
      /** 拼接“部分代替”字段  */
      if (record.portionRevisionOrRevisionr && record.portionRevisionOrRevisionr != "") {
        if (record.portionRevisionOrRevisionr.indexOf(',') > 0) {
          let portionRevisionOrRevisionrStr = record.portionRevisionOrRevisionr.split(',').slice(0, record.portionRevisionOrRevisionr.length - 1)
          let portionRevisionOrRevisionrIds = record.portionRevisionOrRevisionrId.split(',').slice(0, record.portionRevisionOrRevisionrId.length - 1)
          portionRevisionOrRevisionrStr.map((item, index) => {
            temp.push(<span key={keys++}>部分代替<a onClick={() => this.getFileUrl(portionRevisionOrRevisionrIds[index])}>{portionRevisionOrRevisionrStr[index]}</a>；</span>)
            str += "部分代替" + portionRevisionOrRevisionrStr[index] + "；"
          })
        } else {
          temp.push(<span key={keys++}>部分代替<a onClick={() => this.getFileUrl(record.portionRevisionOrRevisionrId)}>{record.portionRevisionOrRevisionr}</a>；</span>)
          str += "部分代替" + record.portionRevisionOrRevisionr + "；"
        }
      }
      /** 拼接“被...代替”字段  */
      if (record.beReplace && record.beReplace != "") {
        if (record.beReplace.indexOf(',') > 0) {
          let beReplaceStr = record.beReplace.split(',').slice(0, record.beReplace.length - 1)
          let beReplaceIds = record.beReplaceId.split(',').slice(0, record.beReplaceId.length - 1)
          beReplaceStr.map((item, index) => {
            temp.push(<span key={keys++}>被<a onClick={() => this.getFileUrl(beReplaceIds[index])}>{beReplaceStr[index]}</a>代替；</span>)
            str += "被" + beReplaceStr[index] + "代替；"
          })
        } else {
          temp.push(<span key={keys++}>被<a onClick={() => this.getFileUrl(record.beReplaceId)}>{record.beReplace}</a>代替；</span>)
          str += "被" + record.beReplace + "代替；"
        }
      }
      /** 代替or修订的描述  */
      if (record.revisionOrReplaceRemark) {
        temp.push(<span key={keys++}>{record.revisionOrReplaceRemark}</span>)
        str += record.revisionOrReplaceRemark
      }
      return {
        str: str,
        dom: <div key={keys++} title={str}>{temp}</div>
      }
    }
  }
  /**
   * 进入PDF查看页面
   * @param {Object} record -法规记录详细数据
   */
  handleEnterDetailed = (record) => {
    if (record.ossUrl) {
      let url = record.ossUrl
      let urlList = url.split('.')
      if (urlList[urlList.length - 1] == 'pdf') {
        this.setState({
          isPDFShow: true,
          PDFUrl: record.ossUrl
        })
      } else {
        /**
         * @type {String}
         * @description pdf预览链接（微软自带预览接口地址+链接）
         * */
        this.setState({
          isPDFShow: true,
          PDFUrl: "https://view.officeapps.live.com/op/view.aspx?src=" + record.ossUrl
        })
      }
    } else {
      message.warning('暂无相关PDF可查看')
    }
  }
  /**
   * 清空表格选中数据方法
   * @function resetCheckedList
   */
  resetCheckedList = () => {
    this.setState({
      selectedRowKeys: [],
      selectedRows: null,
      selectedIds: [],
      selectItem: {},
    })
  }
  /**
   * 获取表格数据
   * @function requestList
   */
  requestList = () => {
    getTable(this.state.getParams).then(res => {
      if (res.status === 200) {
        /** 表格自增序号及唯一key赋值 */
        if (res.data.result) {
          res.data.result.collect && utils.tableListKey(res.data.result.collect, this.state.getParams.pageNo, this.state.getParams.pageSize)
          let _this = this
          /** 清空表格选中状态 */
          this.resetCheckedList()
          /** 表格赋值 */
          this.setState({
            // legalType:arr1,
            // belongProject:arr2,
            tableList: res.data.result.collect ? res.data.result.collect : [],
            /** 表格分页 */
            pagination: utils.pagination(res.data, (current, size) => {
              _this.state.getParams.pageNo = current
              _this.state.getParams.pageSize = size
              this.requestList()
            }, (current, size) => {
              _this.state.getParams.pageNo = current
              _this.state.getParams.pageSize = size
              this.requestList()
            }, res.data.result.collect.length)
          })
        }

      }
    })
  }
  componentWillMount = () => {
    this.requestList()
  }
  /**
   * 新增项目管理-弹框状态
   * @function handleTreeAdd
   * @param {Object} item -进入新增项目的节点
   */
  handleTreeAdd = (item) => {
    this.setState({
      isAddTreeProject: true,
      addTreeItem: item
    })
  }
  /**
   * 项目管理-新增提交
   * @function addTreeProject
   */
  addTreeProject = () => {
    let _this = this
    let params = {
      id: '',
      pid: this.state.addTreeItem.pid,
      name: this.inputRef.filtersValue().name
    }
    editTree(params).then(res => {
      if (res.status === 200) {
        message.success('新增成功！')
        this.setState({
          isAddTreeProject: false,
          addTreeItem: []
        })
        this.handleManageProject()
        this.inputRef.resetValue()
      } else {
        message.warning('新增出错，请重新提交！')
      }
    })
  }
  /**
   * 项目管理-删除提交
   * @function addTreeProject
   */
  handleTreeDelete = (item) => {
    let _this = this
    this.setState({
      isDelete: true,
      deleteType: function () {
        deleteTreeList({ id: item.id }).then((res) => {
          if (res.status === 200) {
            message.success('删除成功！')
            _this.handleManageProject()
          } else {
            this.renderModalSimple('warning', '错误', '删除失败，请重新删除!')
          }
          _this.setState({
            isDelete: false
          })
        })
      }
    })
  }
  /**
   * 编辑项目管理-弹框状态
   * @function addTreeProject
   */
  handleTreeEdit = (item) => {
    // console.log(item,'edit')
    this.setState({
      isEditProject: true,
      editTreeItem: item
    })
  }
  /**
   * 编辑项目管理-提交信息
   * @function editTreeProject
   */
  editTreeProject = () => {
    let _this = this
    let params = {
      id: this.state.editTreeItem.id,
      pid: this.state.editTreeItem.pid,
      name: this.inputRef.filtersValue().name
    }
    editTree(params).then(res => {
      if (res.status === 200) {
        if (res.data.state.value === 1) {
          message.warning(res.data.content)
        } else if (res.data.state.value === 0) {
          message.success('编辑成功！')
          this.setState({
            isEditProject: false,
            editTreeItem: []
          })
          this.handleManageProject()
          this.inputRef.resetValue()
        }
      } else {
        message.warning('编辑出错，请重新提交！')
      }
    })
  }
  /**
   * 展示项目管理
   * @function handleManageProject -获取树结构的展示数据
   */
  handleManageProject = () => {
    projectManage().then(res => {
      if (res.status === 200) {
        this.setState({
          treeData: res.data.result.projectOneLevelModels,
          treeShow: true
        })
      }
    })
  }
  /**
   * 展示项目管理
   * @function handleEditProject -展示修改项目Modal
   * @returns {Bollean|null} -如果符合要求则返回true，否则false；弱无选中不进行操作
   */
  handleEditProject = () => {
    if (this.state.selectedIds.length == 0) {
      this.renderModalSimple('warning', '提示', '请先选择至少一条信息!')
      return
    }
    let flag = this.state.selectedRows.every((item, index) => {
      if (item.lawClassName === "解读") {
        this.renderModalSimple('warning', '提示', '所选项中有法律效力为解读！请重新选择')
        return false
      } else {
        return true
      }
    })
    if (flag) {
      this.setState({
        editProject: true
      })
    }
  }
  /**
   * 批量删除表格记录
   * @function handleLotsDelete
   * @returns -弱无选中不进行操作
   */
  handleLotsDelete = () => {
    let _this = this
    if (this.state.selectedIds.length == 0) {
      this.renderModalSimple('warning', '提醒', '请先选择至少一条信息!')
      return
    }
    let idStr = ''
    this.state.selectedIds.map(item => {
      idStr += item + ','
    })
    this.setState({
      isDelete: true,
      deleteType: function () {
        deleteList({ ids: idStr }).then((res) => {
          if (res.status === 200) {
            message.success('删除成功！')
            _this.requestList()
          } else {
            this.renderModalSimple('warning', '错误', '删除失败，请重新删除!')

          }
          _this.setState({
            isDelete: false
          })
        })
      }
    })
  }
  /**
   * 表格内编辑-获取数据展示Modal
   * @function handleCompanyTableEdit -展示Modal前，先获取法规详细信息回显
   */
  handleCompanyTableEdit = (record) => {
    getLegalForm({ id: record.id }).then(res => {
      if (res.status === 200) {
        res.data.id = record.id
        new Promise((resolve) => {
          this.setState({
            legalFormState: res.data.result,
            editSubmitFlag: true,
          })
          resolve()
        }).then(() => {
          this.setState({
            editLegalShow: true,
          })
        })
      } else {
        this.renderModalSimple('warning', '错误', '无法编辑，请稍后再试!')
      }
    })
  }
  /**
   * 表格内删除-提交表单
   * @function handleCompanyTableDelete
   */
  handleCompanyTableDelete = (record) => {
    let _this = this
    this.setState({
      isDelete: true,
      deleteType: function () {
        deleteList({ ids: record.id }).then((res) => {
          if (res.status === 200) {
            message.success('删除成功！')
            _this.requestList()
          } else {
            this.renderModalSimple('warning', '错误', '删除失败，请重新删除!')
          }
          _this.setState({
            isDelete: false
          })
        })
      }
    })
  }
  /**
   * 监听点击表格行事件
   * @function onRowClick
   */
  onRowClick = (record, index) => {
    let obj = this.reStoreStr(record)
    this.setState({
      selectItem: record,
      selectedLawRelationShip: obj.str
    })
  }
  /**
   * 监听多选表格行事件
   * @function rowCheckSelection
   * @returns {Object} -含多选事件触发的回调
   */
  rowCheckSelection = () => {
    return {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        let ids = []
        selectedRows.map((item) => {
          ids.push(item.id)
        })
        this.setState({ selectedRowKeys, selectedIds: ids, selectedRows })
      }
    }
  }
  /**
   * 修改项目-提交表单
   * @function handleSubmitEditProjectFilter
   */
  handleSubmitEditProjectFilter = () => {
    let filedsValue = this.formRef.editProjectFilterSubmit()
    let str = ''
    this.state.selectedIds.map(item => {
      str += item + ','
    })
    let params = {
      ids: str,
      projectId: filedsValue.projectId[filedsValue.projectId.length - 1]
    }
    editProject(params).then((res => {
      if (res.status === 200) {
        message.success('修改成功！')
        this.setState({
          editProject: false
        })
        this.requestList()
        this.formRef.resetValue()
      } else {
        message.warning('修改出错，请重新提交！')
      }
    }))
  }
  /**
   * 新增法规库-提交表单
   * @function addLegalSubmit
   */
  addLegalSubmit = () => {
    let filedValue = this.legalRef.handleAaddLegalFilterSubmit()
    if (filedValue) {
      let params = {}
      /** 处理代替修订等字段 */
      let replaceOrRevisionrStr = ''
      let replaceOrRevisionrIds = ''
      let portionRevisionOrRevisionrStr = ''
      let portionRevisionOrRevisionrIds = ''
      let beReplaceStr = ''
      let beReplaceIds = ''
      switch (this.legalRef.getLegalTypeForFather()) {
        /** 解读 */
        case 0:
          params.type = 0
          params.fileId = filedValue.readFileId
          // params.readLawId = filedValue.read    解读文件信息
          params.lawId = filedValue.JDreadLawId && filedValue.JDreadLawId.length && filedValue.JDreadLawId[0].key ? filedValue.JDreadLawId[0].key : ''
          params.lawClassId = filedValue.lawClassId && filedValue.lawClassId.length ? filedValue.lawClassId[filedValue.lawClassId.length - 1] : null
          break;
        /** 法律法规 */
        case 1:
          params.type = 1
          /** 处理“修订为”字段 */
          filedValue.replace && filedValue.replace.map(item => {
            replaceOrRevisionrStr += item.label + ','
            replaceOrRevisionrIds += item.key + ','
          })
          /** 处理“由...修订”字段 */
          filedValue.replaced && filedValue.replaced.map(item => {
            portionRevisionOrRevisionrStr += item.label + ','
            portionRevisionOrRevisionrIds += item.key + ','
          })
          /** 处理“修订为”字段 */
          params.replaceOrRevisionr = replaceOrRevisionrStr.slice(0, replaceOrRevisionrStr.length - 1)
          params.replaceOrRevisionrId = replaceOrRevisionrIds.slice(0, replaceOrRevisionrIds.length - 1)
          /** 处理“由...修订”字段 */
          params.portionRevisionOrRevisionr = portionRevisionOrRevisionrStr.slice(0, portionRevisionOrRevisionrStr.length - 1)
          params.portionRevisionOrRevisionrId = portionRevisionOrRevisionrIds.slice(0, portionRevisionOrRevisionrIds.length - 1)
          // params.fileNo = filedValue.fileNo
          params.hyName = filedValue.hyName
          params.department = filedValue.department
          params.lawStatus = filedValue.lawStatus
          params.status = STATUS.LAW_STATUS[filedValue.lawStatus]
          params.fileNo = filedValue.fileNo
          params.readFileName = filedValue.jdname
          params.releaseDate = filedValue.releaseDate ? moment(filedValue.releaseDate).format('YYYY-MM-DD') : null
          params.executeDate = filedValue.executeDate ? moment(filedValue.executeDate).format('YYYY-MM-DD') : null
          params.cancelDate = filedValue.cancelDate ? moment(filedValue.cancelDate).format('YYYY-MM-DD') : null
          params.projClassId = filedValue.projClassId && filedValue.projClassId.length ? filedValue.projClassId[filedValue.projClassId.length - 1] : null
          params.lawClassId = filedValue.lawClassId && filedValue.lawClassId.length ? filedValue.lawClassId[filedValue.lawClassId.length - 1] : null
          params.remake = filedValue.remake
          params.revisionOrReplaceRemark = filedValue.revisionOrReplaceRemark
          params.fileId = filedValue.fileId
          params.readFileId = filedValue.readFileId
          let objStr1 = this.reStoreStr({
            type: params.type,
            portionRevisionOrRevisionr: params.portionRevisionOrRevisionr,
            portionRevisionOrRevisionrId: params.portionRevisionOrRevisionrId,
            replaceOrRevisionr: params.replaceOrRevisionr,
            replaceOrRevisionrId: params.replaceOrRevisionrId,
          })
          params.replaceRelationship = objStr1.str
          break
        /** 标准 */
        case 2:
          params.type = 2
          /** 处理“代替”字段 */
          filedValue.beReplaced && filedValue.beReplaced.map(item => {
            beReplaceStr += item.label + ','
            beReplaceIds += item.key + ','
          })
          /** 处理“被代替”字段 */
          filedValue.beReplace && filedValue.beReplace.map(item => {
            replaceOrRevisionrStr += item.label + ','
            replaceOrRevisionrIds += item.key + ','
          })
          /** 处理“部分代替”字段 */
          filedValue.bePartReplace && filedValue.bePartReplace.map(item => {
            portionRevisionOrRevisionrStr += item.label + ','
            portionRevisionOrRevisionrIds += item.key + ','
          })
          /** 处理“被代替”字段 */
          params.beReplace = beReplaceStr.slice(0, beReplaceStr.length - 1)
          params.beReplaceId = beReplaceIds.slice(0, beReplaceIds.length - 1)
          /** 处理“代替”字段 */
          params.replaceOrRevisionr = replaceOrRevisionrStr.slice(0, replaceOrRevisionrStr.length - 1)
          params.replaceOrRevisionrId = replaceOrRevisionrIds.slice(0, replaceOrRevisionrIds.length - 1)
          /** 处理“部分代替”字段 */
          params.portionRevisionOrRevisionr = portionRevisionOrRevisionrStr.slice(0, portionRevisionOrRevisionrStr.length - 1)
          params.portionRevisionOrRevisionrId = portionRevisionOrRevisionrIds.slice(0, portionRevisionOrRevisionrIds.length - 1)
          // params.fileNo = filedValue.fileNo
          params.hyName = filedValue.hyName
          params.department = filedValue.department
          params.readFileName = filedValue.jdname
          params.lawStatus = filedValue.lawStatus
          params.status = STATUS.LAW_STATUS[filedValue.lawStatus]
          params.fileNo = filedValue.fileNo
          params.releaseDate = filedValue.releaseDate ? moment(filedValue.releaseDate).format('YYYY-MM-DD') : null
          params.executeDate = filedValue.executeDate ? moment(filedValue.executeDate).format('YYYY-MM-DD') : null
          params.cancelDate = filedValue.cancelDate ? moment(filedValue.cancelDate).format('YYYY-MM-DD') : null
          params.projClassId = filedValue.projClassId && filedValue.projClassId.length ? filedValue.projClassId[filedValue.projClassId.length - 1] : null
          params.lawClassId = filedValue.lawClassId && filedValue.lawClassId.length ? filedValue.lawClassId[filedValue.lawClassId.length - 1] : null
          params.revisionOrReplaceRemark = filedValue.revisionOrReplaceRemark
          params.remake = filedValue.remake
          params.readFileId = filedValue.readFileId
          params.fileId = filedValue.fileId
          let objStr2 = this.reStoreStr({
            type: params.type,
            portionRevisionOrRevisionr: params.portionRevisionOrRevisionr,
            portionRevisionOrRevisionrId: params.portionRevisionOrRevisionrId,
            replaceOrRevisionr: params.replaceOrRevisionr,
            replaceOrRevisionrId: params.replaceOrRevisionrId,
            beReplace: params.beReplace,
            beReplaceId: params.beReplaceId,
          })
          params.replaceRelationship = objStr2.str
          break
        default:
          params = null
          break
      }

      /** 文件上传 */
      params.fileName = filedValue.fileName
      // params.ossUrl = filedValue.originalUrl
      // params.lawOssUrl = filedValue.jdUrl
      /**
       * @type {String} webLink
       * @description 法规链接
       * */
      params.webLink = filedValue.webLink
      if (params) {
        legalAdd(params).then(res => {
          if (res.status === 200) {
            if (res.data.state.value == 0) {
              message.success('新增数据成功！')
              this.setState({
                addLegalShow: false
              })
              this.legalRef.handleresetLegalFilter()
              this.requestList()
            } else {
              message.warning(res.data.content)
            }


          } else {
            message.warning('新增出错，请重新提交！')
          }
        })
      } else {
        message.warning('未选择正确的法律法规，请重新选择！')
      }
    }
  }
  /**
   * 编辑法规库-提交表单
   * @function editLegalSubmit
   * */
  //对接接口-编辑法规库
  editLegalSubmit = () => {
    this.setState({ editSubmitFlag: false }, () => {
      let filedValue = this.legalRef.handleAaddLegalFilterSubmit()
      if (filedValue) {
        let params = {}
        /** 处理代替修订字段 */
        let replaceOrRevisionrStr = ''
        let replaceOrRevisionrIds = ''
        let portionRevisionOrRevisionrStr = ''
        let portionRevisionOrRevisionrIds = ''
        let beReplaceStr = ''
        let beReplaceIds = ''
        switch (this.legalRef.getLegalTypeForFather()) {
          /** 解读 */
          case 0:
            params.type = 0
            // params.fileId = filedValue.readFileId
            if (filedValue.readFileId != '') {
              if (filedValue.readFileId == 'del') {
                params.fileId = ""
              } else {
                params.fileId = filedValue.readFileId
              }
            }
            // params.fileName = filedValue.fileName
            // params.readLawId = filedValue.readLawId
            params.lawId = filedValue.JDreadLawId && filedValue.JDreadLawId.length && filedValue.JDreadLawId[0].key ? filedValue.JDreadLawId[0].key : ''
            params.lawClassId = filedValue.lawClassId && filedValue.lawClassId.length ? filedValue.lawClassId[filedValue.lawClassId.length - 1] : null
            break;
          /** 法律法规 */
          case 1:
            params.type = 1
            filedValue.replace && filedValue.replace.map(item => {
              replaceOrRevisionrStr += item.label + ','
              replaceOrRevisionrIds += item.key + ','
            })
            filedValue.replaced && filedValue.replaced.map(item => {
              portionRevisionOrRevisionrStr += item.label + ','
              portionRevisionOrRevisionrIds += item.key + ','
            })
            /** 处理“修订为”字段 */
            params.replaceOrRevisionr = replaceOrRevisionrStr.slice(0, replaceOrRevisionrStr.length - 1)
            params.replaceOrRevisionrId = replaceOrRevisionrIds.slice(0, replaceOrRevisionrIds.length - 1)
            /** 处理“由...修订”字段 */
            params.portionRevisionOrRevisionr = portionRevisionOrRevisionrStr.slice(0, portionRevisionOrRevisionrStr.length - 1)
            params.portionRevisionOrRevisionrId = portionRevisionOrRevisionrIds.slice(0, portionRevisionOrRevisionrIds.length - 1)
            // params.fileNo = filedValue.fileNo
            params.hyName = filedValue.hyName
            params.department = filedValue.department
            params.lawStatus = filedValue.lawStatus
            params.status = STATUS.LAW_STATUS[filedValue.lawStatus]
            params.fileNo = filedValue.fileNo
            params.releaseDate = filedValue.releaseDate ? moment(filedValue.releaseDate).format('YYYY-MM-DD') : null
            params.executeDate = filedValue.executeDate ? moment(filedValue.executeDate).format('YYYY-MM-DD') : null
            params.cancelDate = filedValue.cancelDate ? moment(filedValue.cancelDate).format('YYYY-MM-DD') : null
            params.readFileName = filedValue.jdname
            params.projClassId = filedValue.projClassId && filedValue.projClassId.length ? filedValue.projClassId[filedValue.projClassId.length - 1] : null
            // params.lawClassId = filedValue.lawClassId&&filedValue.lawClassId.length?filedValue.lawClassId[filedValue.lawClassId.length-1]:null
            params.remake = filedValue.remake
            params.fileId = filedValue.fileId
            if (filedValue.readFileId != '') {
              if (filedValue.readFileId == 'del') {
                params.readFileId = ""
              } else {
                params.readFileId = filedValue.readFileId
              }
            }
            if (filedValue.readLawId) {
              params.readLawId = filedValue.readLawId
            }
            params.revisionOrReplaceRemark = filedValue.revisionOrReplaceRemark
            let objStr1 = this.reStoreStr({
              type: params.type,
              portionRevisionOrRevisionr: params.portionRevisionOrRevisionr,
              portionRevisionOrRevisionrId: params.portionRevisionOrRevisionrId,
              replaceOrRevisionr: params.replaceOrRevisionr,
              replaceOrRevisionrId: params.replaceOrRevisionrId,
              revisionOrReplaceRemark: params.revisionOrReplaceRemark,
            })
            params.replaceRelationship = objStr1.str
            break
          /** 标准 */
          case 2:
            params.type = 2
            /** 处理“代替”字段 */
            filedValue.beReplaced && filedValue.beReplaced.map(item => {
              beReplaceStr += item.label + ','
              beReplaceIds += item.key + ','
            })
            /** 处理“被代替”字段 */
            filedValue.beReplace && filedValue.beReplace.map(item => {
              replaceOrRevisionrStr += item.label + ','
              replaceOrRevisionrIds += item.key + ','
            })
            /** 处理“部分代替”字段 */
            filedValue.bePartReplace && filedValue.bePartReplace.map(item => {
              portionRevisionOrRevisionrStr += item.label + ','
              portionRevisionOrRevisionrIds += item.key + ','
            })
            /** 处理“被代替”字段 */
            params.beReplace = beReplaceStr.slice(0, beReplaceStr.length - 1)
            params.beReplaceId = beReplaceIds.slice(0, beReplaceIds.length - 1)
            /** 处理“代替”字段 */
            params.replaceOrRevisionr = replaceOrRevisionrStr.slice(0, replaceOrRevisionrStr.length - 1)
            params.replaceOrRevisionrId = replaceOrRevisionrIds.slice(0, replaceOrRevisionrIds.length - 1)
            /** 处理“部分代替”字段 */
            params.portionRevisionOrRevisionr = portionRevisionOrRevisionrStr.slice(0, portionRevisionOrRevisionrStr.length - 1)
            params.portionRevisionOrRevisionrId = portionRevisionOrRevisionrIds.slice(0, portionRevisionOrRevisionrIds.length - 1)
            // params.fileNo = filedValue.fileNo
            params.hyName = filedValue.hyName
            params.department = filedValue.department
            params.lawStatus = filedValue.lawStatus
            params.status = STATUS.LAW_STATUS[filedValue.lawStatus]
            params.fileNo = filedValue.fileNo
            params.releaseDate = filedValue.releaseDate ? moment(filedValue.releaseDate).format('YYYY-MM-DD') : null
            params.executeDate = filedValue.executeDate ? moment(filedValue.executeDate).format('YYYY-MM-DD') : null
            params.cancelDate = filedValue.cancelDate ? moment(filedValue.cancelDate).format('YYYY-MM-DD') : null
            params.readFileName = filedValue.jdname
            params.projClassId = filedValue.projClassId && filedValue.projClassId.length ? filedValue.projClassId[filedValue.projClassId.length - 1] : null
            // params.lawClassId = filedValue.lawClassId&&filedValue.lawClassId.length?filedValue.lawClassId[filedValue.lawClassId.length-1]:null
            params.revisionOrReplaceRemark = filedValue.revisionOrReplaceRemark
            params.remake = filedValue.remake
            params.fileId = filedValue.fileId
            if (filedValue.readFileId != '') {
              if (filedValue.readFileId == 'del') {
                params.readFileId = ""
              } else {
                params.readFileId = filedValue.readFileId
              }
            }
            if (filedValue.readLawId) {
              params.readLawId = filedValue.readLawId
            }
            let objStr2 = this.reStoreStr({
              type: params.type,
              portionRevisionOrRevisionr: params.portionRevisionOrRevisionr,
              portionRevisionOrRevisionrId: params.portionRevisionOrRevisionrId,
              replaceOrRevisionr: params.replaceOrRevisionr,
              replaceOrRevisionrId: params.replaceOrRevisionrId,
              beReplace: params.beReplace,
              beReplaceId: params.beReplaceId,
              revisionOrReplaceRemark: params.revisionOrReplaceRemark,
            })
            params.replaceRelationship = objStr2.str
            break
          default:
            params = null
            break
        }
        params.id = filedValue.id

        /** 文件上传 */
        params.fileName = filedValue.fileName
        // params.ossUrl = filedValue.originalUrl
        // params.lawOssUrl = filedValue.jdUrl

        /** @type {String} webLink -法规详情链接 */
        params.webLink = filedValue.webLink
        if (params) {
          legalEdit(params).then(res => {
            if (res.status === 200 && res.data.state.value === 0) {
              message.success('编辑数据成功！')
              this.setState({
                editLegalShow: false
              })
              this.requestList()
              this.legalRef.handleresetLegalFilter()
            } else {
              message.warning(res.data.content)
            }
          })
        }
      }
    })


  }
  /**
   * 搜索法规列表
   * @function handleSearch
   * */
  handleSearch = () => {
    let formData = this.searchRef.handleSearch()
    let params = {}
    params.pageNo = 1
    params.pageSize = 30
    params.noFileId = formData.noFileId ? 1 : 0
    params.hyName = formData.hyName ? formData.hyName : ''
    params.noReplace = formData.noReplace ? 1 : 0
    params.lawStatus = formData.lawStatus ? formData.lawStatus : ''
    params.lawClassId = formData.lawClassId && formData.lawClassId.length ? formData.lawClassId[formData.lawClassId.length - 1] : ''
    params.projClassId = formData.projClassId && formData.projClassId.length ? formData.projClassId[formData.projClassId.length - 1] : ''
    params.nameOrNum = formData.nameOrNum
    if (formData["releaseStartTime,releaseEndTime"]) {
      params.releaseStartTime = moment(formData["releaseStartTime,releaseEndTime"][0]).format('YYYY-MM-DD')
      params.releaseEndTime = moment(formData["releaseStartTime,releaseEndTime"][1]).format('YYYY-MM-DD')
    }
    if (formData["executeStartTime,executeEndTime"]) {
      params.executeStartTime = moment(formData["executeStartTime,executeEndTime"][0]).format('YYYY-MM-DD')
      params.executeEndTime = moment(formData["executeStartTime,executeEndTime"][1]).format('YYYY-MM-DD')
    }
    this.setState({
      getParams: params
    }, () => {
      this.requestList()
    })
  }
  /**
   * Modal弹框渲染方法
   * @function renderModal
   * @param {String} titleName -弹框标题
   * @param {(Number|null)} width -弹框宽度
   * @param {String} stateName -控制弹框显示的状态值
   * @param {(Function|null)} okfn -弹框确认事件
   * @param {(Function|null)} canclefn -弹框取消事件
   * @param {HTMLElement} content -弹框内容
   * @param {(String|null)} wrapClassName-弹框wrapClassName样式名
   * @param {(String|null)} className -弹框样式名
   * @return {HTMLElement} 返回Antd Modal组件
   * */
  renderModal = (titleName, width, stateName, okfn, canclefn, content, className, wrapClassName, footer) => {
    let modalConfig = {
      wrapClassName: wrapClassName ? wrapClassName : null,
      visible: this.state[stateName],
      title: titleName ? titleName : '信息',
      className: className ? className : null,
      onCancel: () => {
        if (canclefn) {
          canclefn()()
        }
        this.setState({
          [stateName]: false
        })

      },
      onOk: okfn ? okfn : null,
      width: width ? width : 520,
    }
    if (footer == 'none') {
      modalConfig.footer = null
    }
    return <Modal {...modalConfig}>{content ? content : ''}</Modal>
  }
  /**
   * Modal弹框渲染方法-简易
   * @function renderModalSimple
   * @param {String} type -弹框类型
   * @param {String} title -弹框标题
   * @param {HTMLElement} content -弹框取消事件
   * @return {HTMLElement} Antd-Modal
   * */
  renderModalSimple = (type, title, content) => {
    return Modal[type ? type : 'info']({
      title: title ? title : '警告',
      content: content ? content : ''
    })
  }
  /**
   * 查看文件接口
   * @function getFileUrl
   * @param {String} fileid -文件ID
   * */
  getFileUrl = (fileid) => {
    getUrl({ id: fileid }).then(res => {
      if (res.status === 200) {
        if (res.data.state.value == 0) {
          this.handleEnterDetailed({
            ossUrl: res.data.result
          })
        } else {
          message.warning(res.data.content)
        }
      }
    })
  }
  /**  表格拉伸 */
  components = {
    header: {
      cell: ResizeableTitle,
    },
  }
  /**  表格拉伸 */
  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };
  render() {
    let _this = this
    /**  可拉伸表格的定义 */
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    /**
     * @constant
     * @type {Object} uploadOriProps 上传组件配置
     * @property {String} name 上传组件表单名称
     * @property {Boolean} showUploadList 上传文件列表显示状态
     * @property {Function} beforeUpload 文件上传前的函数
     *
     * */
    const uploadOriProps = {
      name: 'uploadOri',
      showUploadList: false,
      beforeUpload(file) {
        utils.upLoad(file).then(res => {
          if (res) {
            fileTem({ filePath: res.url }).then(res => {
              if (res.status == 200) {
                if (res.data.state.value == 0) {
                  message.success('导入成功')
                  _this.requestList()
                } else {
                  message.success('导入失败，请重新导入')
                }
              } else {
                this.renderModalSimple('warning', '错误', '解析数据出错，请重新导入！')
              }
            })
          }
        })
        /** 阻止antd自带的上传 */
        return false
      },

    }
    const title = () => {
      return (
        <div>
          <div className="fr">
            <Upload {...uploadOriProps} className="dlineb">
              <Button onClick={this.handleDownload} className="mr20 btn-green">
                导入模板
                                    </Button>
            </Upload>
            <Button type="primary" onClick={this.handleManageProject} className="mr20">项目管理</Button>
            <Button type="primary" className="mr20" onClick={this.handleEditProject}>修改项目</Button>
            <Button type="danger" onClick={this.handleLotsDelete}>批量删除</Button>
          </div>
          <div className="clear"></div>
        </div>
      )
    };
    return (
      <div>
        <FilterForm
          wrappedComponentRef={(inst) => this.searchRef = inst}
          handleSearch={this.handleSearch}
        />

        <Table
          title={title}
          className="fl width-100 mt20 warpTd"
          bordered
          components={this.components}
          footer={() => {
            return <a onClick={() => { this.setState({ addLegalShow: true }) }}><Icon type="plus-circle" theme="filled" title="新增" className="f20"></Icon></a>
          }}
          onRow={(record, index) => {
            return {
              onClick: () => {
                this.onRowClick(record, index)
              }
            }
          }}
          rowSelection={this.rowCheckSelection()}
          columns={columns}
          dataSource={this.state.tableList}
          pagination={false}
        />
        <Pagination
          {...this.state.pagination}
          className="fr mt20"
          pageSize={this.state.getParams.pageSize}
        />
        {/** 规则:renderModal = (titleName,width,stateName,okfn,()=>canclefn,content,className,wrapClassName) */}
        {this.renderModal('预览文件', 800, 'isPDFShow', null, null, <iframe width={750} height={600} src={this.state.PDFUrl} title="PDFperview"></iframe>, null, null, 'none')}
        {this.renderModal('编辑法规库项目', 700, 'editLegalShow', this.editLegalSubmit, () => this.legalRef.handleresetLegalFilter, <AddLegalForm wrappedComponentRef={(inst) => this.legalRef = inst} type="editLegalForm" initFormState={this.state.legalFormState} flag={this.state.editSubmitFlag}></AddLegalForm>, 'modal-max-height-500')}
        {this.renderModal('新增法规库项目', 700, 'addLegalShow', this.addLegalSubmit, () => this.legalRef.handleresetLegalFilter, <AddLegalForm wrappedComponentRef={(inst) => this.legalRef = inst} type="addLegalForm"></AddLegalForm>, 'modal-max-height-500')}
        {this.renderModal('修改项目', null, 'editProject', this.handleSubmitEditProjectFilter, () => this.formRef.resetValue, <SelectForm wrappedComponentRef={(inst) => this.formRef = inst}></SelectForm>)}
        {this.renderModal('项目管理', null, 'treeShow', null, null, <TreeEdit treeData={this.state.treeData} handleTreeAdd={this.handleTreeAdd} handleTreeDelete={this.handleTreeDelete} handleTreeEdit={this.handleTreeEdit}></TreeEdit>, 'modal-max-height-500')}
        {this.renderModal('编辑', 300, 'isEditProject', this.editTreeProject, () => this.inputRef.resetValue, <InputForm wrappedComponentRef={(inst) => this.inputRef = inst} initialName={this.state.editTreeItem && this.state.editTreeItem.length ? this.state.editTreeItem.name : ''}></InputForm>, '', 'z1005')}
        {this.renderModal('新增', 300, 'isAddTreeProject', this.addTreeProject, () => this.inputRef.resetValue, <InputForm wrappedComponentRef={(inst) => this.inputRef = inst} initialName={this.state.addTreeItem && this.state.addTreeItem.length ? this.state.addTreeItem.name : ''}></InputForm>, '', 'z1005')}
        {this.renderModal('提示', 300, 'isDelete', this.state.deleteType, null, '确定要删除吗？')}
      </div>
    )
  }
}

/**
 * @description 修改项目Form
 * @class SelectForm
 * @extends {React.Component}
 * */
class SelectForm extends React.Component {
  /**
   * @type {Object} state -组件内数据
   * @property {Array} belongProject - 所属行业数据
   * */
  state = {
    belongProject: []
  }
  /**
   * From数据提交给父组件
   * @type {Function} editProjectFilterSubmit - 表单数据提交
   * @returns {Object} fieldsValue -表单提交的数据
   */
  editProjectFilterSubmit = () => {
    let fieldsValue = this.props.form.getFieldsValue()
    /** 通过wrappedComponentRef，传递给父组件 */
    return fieldsValue
  }
  /**
   * 请求所属项目数据并初始化
   * @type {Function} requestProject - 请求所属项目数据
   */
  requestProject = () => {
    projectManage().then(res => {
      if (res.status === 200) {
        let arr = []
        res.data.result.projectOneLevelModels.map((item) => {
          let arr2 = []
          arr.push({
            value: item.id,
            label: item.name,
            pid: item.pid,
            key: item.id,
            isLeaf: item.projectTwoLevelModels.length == 0 ? true : false
          })
          if (item.projectTwoLevelModels && item.projectTwoLevelModels.length !== 0) {
            item.projectTwoLevelModels.map((item2) => {
              arr2.push({
                value: item2.id,
                label: item2.name,
                pid: item2.pid,
                key: item2.id,
                isLeaf: true
              })
            })
            arr[arr.length - 1].children = arr2
          }
        })
        this.setState({
          belongProject: arr
        })
      }
    })
  }
  componentWillMount = () => {
    this.requestProject()
  }
  /**
   * 请求所属项目数据并初始化
   * @type {Function} resetValue -重置表单
   */
  resetValue = () => {
    this.props.form.resetFields()
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <Form.Item>
          {getFieldDecorator('projectId', {
            rules: []
          })(
            <Cascader
              placeholder="请选择所属项目"
              options={this.state.belongProject}
              changeOnSelect
            />
          )
          }
        </Form.Item>
      </Form>
    );
  }
}
/** 创建Form */
SelectForm = Form.create({})(SelectForm);

/**
 * @description 搜索Form
 * @class FilterForm
 * @extends {React.Component}
 * */
class FilterForm extends React.Component {
  /**
   * @type {Object} state -组件内数据
   * @property {Boolean} isIcroot - 代替修订字段的选中状态
   * @property {Boolean} isIcreplace - 代替修订关联字段的选中状态
   * @property {Array} legalType - 法律效力数据
   * */
  state = {
    isIcroot: false,
    isIcreplace: false,
    legalType: []
  }
  componentWillMount = () => {
    this.requestLegalInit()
    this.requestProject()
  }
  /**
   * @type {Function} handleReset -表单重置
   * */
  handleReset = () => {
    this.props.form.resetFields();
  }
  /**
   * @type {Function} handleSearch -表单提交
   * */
  handleSearch = () => {
    return this.props.form.getFieldsValue()
  }
  /**
   * @type {Function} onCheckboxdChange -监听Form中的checkbox选中状态
   * @param {Event} e -checkbox选中事件
   * @param {String} tyoe -对应checkbox的控制状态值
   * */
  onCheckboxdChange = (e, type) => {
    this.setState({ [type]: e.target.checked })
  }
  /**
   * @type {Function} requestProject -请求所属项目的数据
   * */
  requestProject = () => {
    projectManage().then(res => {
      if (res.status === 200) {
        let arr = []
        res.data.result.projectOneLevelModels.map((item) => {
          let arr2 = []
          arr.push({
            value: item.id,
            label: item.name,
            pid: item.pid,
            key: item.id,
            isLeaf: item.projectTwoLevelModels.length == 0 ? true : false
          })
          if (item.projectTwoLevelModels && item.projectTwoLevelModels.length !== 0) {
            item.projectTwoLevelModels.map((item2) => {
              arr2.push({
                value: item2.id,
                label: item2.name,
                pid: item2.pid,
                key: item2.id,
                isLeaf: true
              })
            })
            arr[arr.length - 1].children = arr2
          }
        })
        this.setState({
          belongProject: arr
        })
      }
    })
  }
  /**
   * @type {Function} requestLegalInit -获取法律效力一级
   * */
  requestLegalInit = () => {
    let arr = []
    getLegalTypeInit().then(res => {
      if (res.status === 200 && res.data.state.value === 0) {
        res.data.result.legClassifyModels.map((item) => {
          if (item.name == "解读") {
            arr.push({
              value: item.id,
              label: item.name,
              pid: item.pid,
              key: item.id,
              isLeaf: true,
              type: res.data.result.type
            })
          } else {
            arr.push({
              value: item.id,
              label: item.name,
              pid: item.pid,
              key: item.id,
              isLeaf: false,
              type: res.data.result.type
            })
          }
        })
        this.setState({
          legalType: arr
        })
      }
    })
  }
  /**
   * @type {Function} requestLegalType -获取法律效力二级
   * */
  requestLegalType = (selected) => {
    let arr = []
    getLegalType({ id: selected[0].value }).then(res => {
      if (res.status === 200 && res.data.state.value === 0) {
        res.data.result.list.map((item) => {
          arr.push({
            value: item.id,
            label: item.name,
            pid: item.pid,
            key: item.id,
            isLeaf: true
          })
        })
        let arr2 = this.state.legalType
        arr2.map(item => {
          if (item.value === arr[0].pid) {
            item.children = arr
            return
          }
        })
        //必须要用this.setState异步赋值
        this.setState({
          legalType: arr2
        })

      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline">
        <FormItem label="实施日期">
          {getFieldDecorator(['executeStartTime', 'executeEndTime'], {})(
            <RangePicker
              placeholder={["开始日期", "结束日期"]}

            />)
          }
        </FormItem>
        <FormItem label="颁布日期">
          {getFieldDecorator(['releaseStartTime', 'releaseEndTime'], {})(
            <RangePicker
              placeholder={["开始日期", "结束日期"]}

            />)
          }
        </FormItem>
        <FormItem>
          {getFieldDecorator('lawStatus')(
            <Select
              style={{
                width: 120
              }}
              placeholder="请选择状态">
              <Option value="">全部状态</Option>
              <Option value="1">现行</Option>
              <Option value="2">作废</Option>
              <Option value="3">即将实施</Option>
              <Option value="4">废止</Option>
            </Select>
          )
          }
        </FormItem>
        <FormItem>
          {getFieldDecorator('hyName')(
            <Select
              style={{
                width: 120
              }}
              placeholder="请选择行业">
              <Option value="">全部行业</Option>
              <Option value="1">电力</Option>
              <Option value="2">其他</Option>
            </Select>
          )
          }
        </FormItem>
        <Form.Item label="法律效力">
          {getFieldDecorator('lawClassId', {})(
            <Cascader
              placeholder="请选择法律效力"
              options={this.state.legalType}
              // onChange={this.requestLegalType}
              changeOnSelect
              loadData={this.requestLegalType}
            />
          )}
        </Form.Item>
        <Form.Item label="所属项目">
          {getFieldDecorator('projClassId', {
            rules: []
          })(
            <Cascader
              placeholder="请选择所属项目"
              options={this.state.belongProject}
              // onChange={this.requestLegalType}
              changeOnSelect
            // loadData={this.requestBelongProject}
            />
          )}
        </Form.Item>
        <FormItem >
          {/* 模糊匹配的select */}
          {getFieldDecorator('nameOrNum', {})(
            <Input placeholder="名称/文号/代替修订情况" />
          )
          }
        </FormItem>
        <FormItem>
          {getFieldDecorator('noFileId', {
            initialValue: false
          })(
            <Checkbox
              checked={this.state.isIcroot}
              onChange={(e) => this.onCheckboxdChange(e, 'isIcroot')}

            >无源文件</Checkbox>
          )
          }
        </FormItem>
        <FormItem>
          {getFieldDecorator('noReplace', {
            initialValue: false
          })(
            <Checkbox
              checked={this.state.isIcreplace}
              onChange={(e) => this.onCheckboxdChange(e, 'isIcreplace')}
            >无代替修订关联</Checkbox>
          )
          }
        </FormItem>
        <Button type="primary" className="mr20" onClick={this.props.handleSearch}>
          查询</Button>
        <Button onClick={this.handleReset} className="mr20">
          重置</Button>

      </Form>
    );
  }
}
/** 创建Form */
FilterForm = Form.create({})(FilterForm);


/**
 * @description 项目管理-编辑Form
 * @class InputForm
 * @extends {React.Component}
 * */
class InputForm extends React.Component {
  /**
   * @type {Function} filtersValue -提交表单
   * @returns {Object} -表单的数据
   * */
  filtersValue = () => {
    return this.props.form.getFieldsValue()
  }
  /**
   * @type {Function} resetValue -重置表单
   * */
  resetValue = () => {
    this.props.form.resetFields()
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return <Form>
      <FormItem>
        {getFieldDecorator('name', {
          initialValue: this.props.initialName ? this.props.initialName : ''
        })(
          <Input></Input>
        )}
      </FormItem>
    </Form>
  }
}
/** 创建Form */
InputForm = Form.create()(InputForm)