/**
 * @file 组件：代理商推广码生成
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Button } from 'antd'
import { createPromCode } from '../../../Api'

/**
 * 组件：代理商推广码生成
 * @class
 * @extends React.Component
 */
class PromoCode extends React.Component {

    /**
     * 获取推广码并且将组件的value改为推广码 
     * @param {String} value 当前值
     */ 
    handleChange = () => {
        createPromCode({agentBusinessId: this.props.man ? this.props.man.split('-').slice(-1)[0] : ''}).then(res => {
            console.log(res, 'code')
            if (res.status === 200 && res.data.state.value == 0) {
                const {onChange} = this.props
                if (onChange) {
                    onChange(res.data.result)
                }
            }
        })
    }

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => (
            <div><Button onClick={this.handleChange} icon="qrcode">生成</Button><span className="ml10">{this.props.value ? this.props.value : '未生成'}</span></div>
        )

}
export default PromoCode