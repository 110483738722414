/**
 * @file 组件：业务员编辑
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import SalesManagerSelection from './SalesManagerSelection'
import { Form, Input, Select } from 'antd'

const {Option} = Select

/**
 * 组件：业务员编辑
 * @class
 * @extends React.Component
 */
class EditForm extends React.Component {
    /**
     * 得到表格数值
     * @type {Function}
     * @return {Object}
     */
    getValues = () =>  ({...this.props.form.getFieldsValue(), id: this.props.record.id})

    /**
     * 表格提交时进行校验
     * @type {Function}
     * @return {Promise}
     */
    onSubmit = () => this.props.form.validateFields()

    /**
     * 上级领导表格项展示
     * @type {Function}
     * @return {HTMLElement}
     */
    parentId = (getFieldDecorator) => {
        if (this.getValues().type == 2) {
            return <Form.Item label="上级领导">
                {getFieldDecorator('parentId', {
                    rules: [
                    {
                        required: true,
                        message: '没有上级领导！',
                    },
                    ],
                    initialValue: this.props.record.parentId
                })(<SalesManagerSelection />)}
            </Form.Item>
        }
    }

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator } = this.props.form,
        formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }
        return (
            <Form {...formItemLayout} onSubmit={this.onSubmit}>
                <Form.Item label="职务">
                {getFieldDecorator('type', {
                    rules: [
                    {
                        required: true,
                        message: '请选择你的职务！',
                    }
                    ],
                    initialValue: this.props.record.type
                })(<Select>
                    <Option value={1}>业务主管</Option>
                    <Option value={2}>业务员</Option>
                  </Select>)}
                </Form.Item>
                {this.parentId(getFieldDecorator)}
                <Form.Item label="姓名">
                {getFieldDecorator('name', {
                    rules: [
                    {
                        required: true,
                        message: '请输入你的姓名！',
                    },
                    ],
                    initialValue: this.props.record.name
                })(<Input />)}
                </Form.Item>
                <Form.Item label="联系电话">
                {getFieldDecorator('tel', {
                    rules: [
                    {
                        required: true,
                        message: '请输入你的联系电话！',
                    },
                    ],
                    initialValue: this.props.record.tel
                })(<Input />)}
                </Form.Item>
            </Form>
        )
    }
}

/**
 * 创建表单
 * @const {Form} Edit
 */
const Edit = Form.create()(EditForm)

export default Edit