import React, { Component } from 'react'
import { Table, message,Icon } from 'antd'
import SearchForm from '../component/form/Search'             //Search-Form组件
import Checkmodal from './../component/form/Checkmodal'      //Modal-Form组件
import { homeCheckList, getHomeCheckInfo, checkPass } from './../../../Mock'
import { suggestListData,suggestView,suggestEdit } from './../../../Api'
import utils from '../../../Common/js/utils'
import moment from 'moment'
import STATUS from '../../../Common/js/status'
//首页-意见建议
class Suggestion extends Component {
    state = {
        formList: [{
            name: "time",
            type: 'rangePicker',
            placeholder: ['开始时间', '结束时间']
        }, {
            name: "checkState",
            type: 'select',
            placeholder: '请选择状态',
            optionList: [{
                value: 1,
                label: '待反馈'
            }, {
                value: 2,
                label: '已反馈'
            }]
        }],
        //初始化Modal
        formModal: {
            title: '回复',
            list: []
        },
        page: {
            pageNum: 1,
            pageSize: 30
        }
    }
    //通过Api获取表格数据
    getList = (params) => {
        this.setState({
            params:params
        })
        if(params&&params.time){
            params.startDate = moment(params.time[0]).format('YYYY-MM-DD HH:MM:SS')
            params.endDate = moment(params.time[1]).format('YYYY-MM-DD HH:MM:SS')
            delete params.time
        }
        let data = {  ...params,...this.state.page }
        let _this = this
        suggestListData(data).then(res => {
            console.log(res)
            if (res.data.state.value == 0) {
                res.data.result.rows.map(item => {
                    item.key = item.id
                    return item
                })
                this.setState({
                    tableList: res.data.result.rows,
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        _this.getList(params)
                    }, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        _this.getList(params)
                    }, res.data.result.pageSize)
                })
            }

        })
    }
    //挂载前获取表格数据
    componentWillMount() {
        this.getList()
    }
    //更改弹框的显示状态
    handleVisibleChange = () => {
        this.setState({
            visibleCheckModal: !this.state.visibleCheckModal
        })
    }
    //Modal-Form弹窗根据状态设定Modal内容
    handleOpenCheck = (record, type) => {
        this.setState({
            currentId:record.id
        })
        switch (type) {
            case 'reply':
                this.setState({
                    formModal: {
                        title: '回复',
                        list: [{
                            name: "content",
                            label: "意见和建议内容",
                            type: 'original',
                            value: record.content
                        }, {
                            name: "checkContent",
                            type: 'textArea',
                            label: "回复内容",
                            placeholder: '回复内容'
                        }]
                    },
                }, function () {
                    this.setState({
                        visibleCheckModal: true,
                        footer: false
                    })
                })
                break;
            case 'view':
                suggestView({ id: record.id }).then(res => {
                    console.log(res)
                    if (res.data.state.value == 0) {
                        this.setState({
                            formModal: {
                                title: '回复',
                                list: [{
                                    name: "content",
                                    label: "意见和建议内容",
                                    type: 'original',
                                    value: res.data.result.content
                                }, {
                                    name: "checkContent",
                                    type: 'original',
                                    label: "回复内容",
                                    value: res.data.result.checkContent
                                }]
                            },
                        }, function () {
                            this.setState({
                                visibleCheckModal: true,
                                footer: true
                            })
                        })
                    }
                })
                break;
            default:
                break;
        }
    }
    //弹框的“确认”回调 需返回Promise对象
    handleCheck = (data) => {
        data.id = this.state.currentId
        return suggestEdit(data).then((res) => {
            console.log(res)
            return new Promise((resolve, reject) => {
                if (res.data.state.value == 0) {
                    this.setState({
                        visibleCheckModal: false
                    })
                    message.success('操作成功！')
                    this.getList(this.state.params)
                    resolve(true)
                } else {
                    reject(false)
                }
            })
        })
    }
    
    render() {
        let _this = this
        const columns = [
            {
                title: "意见和建议内容",
                dataIndex: 'content',
                align: 'center',
                width: 100,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "联系人",
                dataIndex: 'fullname',
                align: 'center',
                width: 80,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "联系电话",
                dataIndex: 'linktel',
                align: 'center',
                width: 80,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "状态",
                dataIndex: 'checkState',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{STATUS.HOME_SUGGEATION_STATUS[text]}</span>
                },

            },
            {
                title: "提交时间",
                dataIndex: 'createDate',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "回复时间",
                dataIndex: 'checkDatetime',
                align: 'center',
                width: 30,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "操作",
                align: 'center',
                width: 50,
                render(text, record) {
                    return <div>
                        {
                            record.checkState == 1 ? <a onClick={() => _this.handleOpenCheck(record, 'reply')}><Icon type="mail" title="回复" className="table-icon-green"/></a> :''
                        }
                        {
                            record.checkState == 2 ? <a onClick={() => _this.handleOpenCheck(record, 'view')}><Icon type="eye" className="table-icon-blue" title="查看"/></a>:''
                        }
                    </div>
                },

            }
        ]
        return (
            <div className="home">
                <SearchForm formList={this.state.formList} formSearchApi={(data) => this.getList(data)} />
                <Table
                    className="mt20"
                    bordered
                    columns={columns}
                    dataSource={this.state.tableList}
                    pagination={{ ...this.state.pagination, pageSize: this.state.page.pageSize }}
                />
                <Checkmodal footer={this.state.footer} formSubmitApi={(data) => this.handleCheck(data)} handleVisibleChange={this.handleVisibleChange} visibleCheckModal={this.state.visibleCheckModal} formList={this.state.formModal} />
            </div>
        )
    }
}

export default Suggestion;