import React, { Component } from 'react'
import {Button, Modal,Spin } from 'antd'
import '../index.css'
/**
 * @description 视频播放-Modal
 * @class VideoModal
 * @extends {Component}
 * @property {String} videoUrl -视频播放链接
 * @property {Function} changeVisible -切换modal显示状态的方法
 * @property {Boolean} visibleState -控制modal显示的参数
 * */
class VideoModal extends Component {
    state={
        showModal:false,
        isMp4:false
    }
    componentWillReceiveProps(){
        if(this.props.videoUrl){
            let str = this.props.videoUrl + ""
            //上传的video阿里云地址播放地址-用video标签显示
            if(str.indexOf("oss-cn-hangzhou.aliyuncs.com") < 0){
                this.setState({
                    isMp4:false
                });
            }else{
            //直接添加的video播放地址依赖第三方-用iframe显示
                this.setState({
                    isMp4:true
                });
            }
        }
    }
    render() {
        return (
            <div className="video">
                <Modal
                    className="video-modal"
                    title="视频播放"
                    width={820}
                    visible={this.props.visibleState?this.props.visibleState:false}
                    onOk={null}
                    onCancel={this.props.changeVisible}
                    footer={ <Button key="back" onClick={this.props.changeVisible}>取消</Button>}
                >
                    {this.props.videoUrl
                    ? ( !this.state.isMp4
                        ? <iframe title={"videoModal"} allowFullScreen="allowfullscreen" width="100%" height="500" scrolling="no" frameBorder="0" sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"  src={this.props.videoUrl}></iframe>
                        : <video src={this.props.videoUrl} width="100%" height="500" controls="controls"/>
                      )
                    : <div className={"text-center"}><Spin /> 加载中</div>}
                </Modal>
            </div>
        )
    }
}

export default VideoModal