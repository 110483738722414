/**
 * @description 行政区划管理
 * @author yzx yangzhixin177@163.com
 */
import React from 'react'
import { Table,Icon,Modal,message,Input, Card, Button,Form,Cascader} from 'antd';
import './../../Common/css/path.css'
import utils from './../../Common/js/utils'
// import {dmanageSearch,dmanageAdd,dmanageEdit,dmanageDel} from './../../Mock'
import {getAreaListData,addArea,delArea,saveArea,sortArea,queryAreaListData} from '../../Api'
const FormItem = Form.Item;
/**
 * @description 组件-行政区划管理
 * @class DivisionManage
 * @extends {React.Component}
 */
class DivisionManage extends React.Component{
    /**
     * @param {Object} params 页码，每页大小
     * @param {Object} expandedRows 扩展行数据
     * @param {Object} areaOptions 区域数据
     * @memberof DivisionManage
     */
    state={
        params:{
            pageNum:1,
            pageSize:10
        },
        expandedRows:[],
        areaOptions:[],
    }
    /**
     * @description 点击行事件
     * @function onRowClick
     * @param {Object} record 当前行对象 
     * @param {Number} index 当前行下标
     * @memberof DivisionManage
     */
    onRowClick = (record,index)=>{
      let selectKey = [index];
      this.setState({
          selectedRowKeys:selectKey,
          selectedItem: record,
      })
  }  
    /**
     * @description react生命周期，在渲染前调用
     * @function componentWillMount
     * @memberof DivisionManage
     */
    componentWillMount =()=>{
        this.requestList()
    }
    /**
     * @description 异步加载级联数据
     * @function requestList
     * @param {string} itemValue 父级id
     * @memberof DivisionManage
     */
    requestList = (itemValue)=>{
        let pid = itemValue&&itemValue.id?itemValue.id:-1
        // console.log(itemValue)
        let promise
        if(pid == 'none'){
            return
        }else{
        // console.log(pid)
            getAreaListData({pid:pid}).then(res=>{
                // console.log(res)
                promise =  new Promise((resolve)=>{
                    let induOptions = []
                    if(res.status === 200 && res.data.state.value === 0){
                            res.data.result&&res.data.result.length&&res.data.result.map(item=>{
                                if(item.level==0){
                                induOptions.push({
                                    id:item.id,
                                    key:item.id,
                                    pid:item.parentId,
                                    // code:item.id.substr(0,6),
                                    level:item.level,
                                    name:item.name,
                                    updateDate:item.updateDate,
                                    isLeaf:item.level==4?true:false,
                                    children:[]
                                })}
                                else if(item.level==1||item.level==2||item.level==3){
                                    induOptions.push({
                                        id:item.id,
                                        key:item.id,
                                        pid:item.parentId,
                                        // code:item.id.substr(0,6),
                                        level:item.level,
                                        name:item.name,
                                        updateDate:item.updateDate,
                                        isLeaf:item.level==4?true:false,
                                        children:itemValue
                                    })}
                                else if(item.level==4){
                                    induOptions.push({
                                        id:item.id,
                                        key:item.id,
                                        pid:item.parentId,
                                        // code:item.id.substr(0,10),
                                        level:item.level,
                                        name:item.name,
                                        updateDate:item.updateDate,
                                        isLeaf:item.level==4?true:false,
                                    })
                                }
                            })
                            if(induOptions.length){
                                this.restore(this.state.areaOptions,induOptions)
                            }
                            //console.log(induOptions)
                            resolve()
                    }
                })
            })
            return promise
        }
    }
    /**
     * @description 异步加载级联数据
     * @function requestList1
     * @param {string} value 关键字
     * @memberof DivisionManage
     */
    requestList1 = (value)=>{
        let data=[...this.state.areaOptions]
        // console.log(data)
        let pid = value
        let promise
        if(pid == 'none'){
            return
        }else{
            getAreaListData({pid:pid}).then(res=>{
                promise =  new Promise((resolve)=>{
                    let induOptions = []
                    if(res.status === 200 && res.data.state.value === 0){
                            res.data.result&&res.data.result.length&&res.data.result.map(item=>{
                                if(item.level==4){
                                    induOptions.push({
                                        id:item.id,
                                        key:item.id,
                                        pid:item.parentId,
                                        // code:item.id.substr(0,10),
                                        level:item.level,
                                        updateDate:item.updateDate,
                                        name:item.name,
                                        isLeaf:item.level==4?true:false,
                                    })
                                }else{
                                induOptions.push({
                                    id:item.id,
                                    key:item.id,
                                    pid:item.parentId,
                                    // code:item.id.substr(0,6),
                                    level:item.level,
                                    name:item.name,
                                    updateDate:item.updateDate,
                                    isLeaf:item.level==4?true:false,
                                    children:[]
                                })}
                            })
                            // console.log(induOptions)
                            if(induOptions.length){
                                this.restore1(data,induOptions)
                            }
                            resolve()
                    }
                })

            })
        return promise
    }
     }
     /**
     * @description 异步加载级联数据
     * @function requestList2
     * @param {string} value 关键字
     * @memberof DivisionManage
     */
    requestList2 = (value)=>{
        let data=[...this.state.areaOptions]
        // console.log(data)
        let pid = value
        let promise
        if(pid == 'none'){
            return
        }else{
            getAreaListData({pid:pid}).then(res=>{
                // console.log(res.data.result)
                if (res.data.result.length==0) {
                    // console.log(1)
                    this.restore2(data,[])
                }else{
                promise =  new Promise((resolve)=>{
                    let induOptions = []
                    if(res.status === 200 && res.data.state.value === 0){
                            res.data.result&&res.data.result.length&&res.data.result.map(item=>{
                                if(item.level==4){
                                    induOptions.push({
                                        id:item.id,
                                        key:item.id,
                                        pid:item.parentId,
                                        // code:item.id.substr(0,10),
                                        level:item.level,
                                        updateDate:item.updateDate,
                                        name:item.name,
                                        isLeaf:item.level==4?true:false,
                                    })
                                }else{
                                induOptions.push({
                                    id:item.id,
                                    key:item.id,
                                    pid:item.parentId,
                                    // code:item.id.substr(0,6),
                                    level:item.level,
                                    updateDate:item.updateDate,
                                    name:item.name,
                                    isLeaf:item.level==4?true:false,
                                    children:[]
                                })}
                            })
                            // console.log(induOptions)
                            if(induOptions.length){
                                this.restore1(data,induOptions)
                            }
                            resolve()
                    }
                })
            }
            })
        return promise
    }
     }
    /**
     * @description 点击事件-展开下一级，或者已展开行收缩
     * @function handleOnExpand
     * @param {*} expanded 是否已经扩展
     * @param {*} record 扩展行
     * @memberof DivisionManage
     */
    handleOnExpand = (expanded, record) => {
        if(!expanded)  return  //如果是关闭就返回
        if(record.children && record.children.length > 0)  return  //如果已经有数据就返回
        this.requestList(record)    
    }
    /**
     * @description 递归插入级联数据（用于下级展开）
     * @function restore
     * @param {Object} orignalData 原始数据
     * @param {Object} listData 插入数据
     * @memberof DivisionManage
     */
    restore = (orignalData,listData)=>{
        if(orignalData.length){
            orignalData.map(item=>{
                if(item.children&&item.children.length){
                    this.restore(item.children,listData)
                }else{
                    if(item.id == listData[0].pid){
                        item.children = listData
                        return
                    }
                }
            })
            this.setState({
                areaOptions:orignalData
            })
            return
        }else{
            this.setState({
                areaOptions:listData
            })
            return
        }
    }
    /**
     * @description 递归插入级联数据（用于编辑和新增）
     * @function restore
     * @param {Object} orignalData 原始数据
     * @param {Object} listData 插入数据
     * @memberof DivisionManage
     */
    restore1 = (orignalData,listData)=>{
        let flag=false
        if(orignalData.length){
            orignalData.map(item=>{
                if(item.id == listData[0].pid){
                    item.children = listData
                    flag=true
                    return
                }
                else if (flag==false){
                    this.restore1(item.children,listData)
                }
            })
            this.setState({
                areaOptions:orignalData
            })
            return
        }else{
            this.setState({
                areaOptions:listData
            })
            return
        }
    }
    /**
     * @description 递归插入级联数据（用于删除）
     * @function restore
     * @param {Object} orignalData 原始数据
     * @param {Object} listData 插入数据
     * @memberof DivisionManage
     */
    restore2 = (orignalData,listData)=>{
        if(orignalData.length){
            orignalData.map(item=>{
                if(item.children.length == 1){
                    item.children = []
                    return
                }
                else {
                    this.restore2(item.children,listData)
                }
            })
            this.setState({
                areaOptions:orignalData
            })
            return
        }else{
            this.setState({
                areaOptions:listData
            })
            return
        }
    }
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof DivisionManage
     */
    renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({ 
            title:title?title:'警告',
            content:content?content:''
        })
    }
    /**
     * @description 上移
     * @function shiftUp
     * @param {Object} record 当前行对象
     * @memberof DivisionManage
     */
    shiftUp=(record)=>{
        sortArea({id:record.id,upOrDwon:-1}).then((res)=>{
            if(res.data.state.value==0){
                this.requestList1(record.pid);
            }
        })
    }
    /**
     * @description 下移
     * @function shiftDown
     * @param {Object} record 当前行对象
     * @memberof DivisionManage
     */
    shiftDown=(record)=>{
        sortArea({id:record.id,upOrDwon:1}).then((res)=>{
            if(res.data.state.value==0){
                this.requestList1(record.pid);
            }
        })
    }
    /**
     * @description 点击事件-删除
     * @function handleDelete
     * @param {Object} record 当前行对象
     * @memberof DivisionManage
     */
    handleDelete=(record)=>{
        // console.log(record.id)
        const deleteId=record.pid
        if (record.isLeaf==true) {
            Modal.confirm({
                title:'删除提示',
                content: `确定要删除该行政区划？`,
                onOk:()=>{
                    delArea({id:record.id}).then((res)=>{
                        // console.log(record.value)
                        if(res.data.state.value==0){
                            this.setState({
                                isVisible:false
                            })
                            this.requestList2(deleteId); 
                            message.success('删除成功');
                        }else{
                            this.renderModalSimple('warning','错误','删除失败，请重新删除!')
                        }
                    })
                }
            })
        }
        else{
            this.setState({
                title:"删除提示",
                isDeleteVisible:true,
                manageInfo:record
            })
        }
    }
    /**
     * @description 点击事件-新增
     * @function handleAdd
     * @param {Object} record 当前行对象
     * @memberof DivisionManage
     */
    handleAdd = (type,record)=>{
        // let rows = this.state.expandedRows;
        // rows.push(record.key);
        this.setState({
            title:"新增",
            isManageVisible:true,
            manageInfo:record,
            type
        })
    }
    /**
     * @description 点击事件-编辑
     * @function handleEdit
     * @param {string} 类型
     * @param {Object} record 当前行对象
     * @memberof DivisionManage
     */
    handleEdit=(type,record)=>{
        this.setState({
            title:'编辑',
            isManageVisible:true,
            manageInfo:record,
            type
        })
    }
    /**
     * @description 点击事件-提交
     * @function handleSubmit
     * @memberof DivisionManage
     */
    handleSubmit = ()=>{
        let data = this.ManageForm.props.form.getFieldsValue();
        let type = this.state.type;
        let manageInfo=this.state.manageInfo;
        // console.log(manageInfo.id)
        this.ManageForm.props.form.validateFields((err)=>{
            if(!err){
        if(type=='create'){
            addArea({id:data.id,parentId:manageInfo.id,name:data.name}).then((res)=>{
            if(res.data.state.value==0){
                this.setState({
                    isManageVisible:false
                })
                message.success('新增成功')
                this.requestList1(manageInfo.id)
                this.ManageForm.resetValue()
            }else{
                this.renderModalSimple('warning','错误','新增失败') 
            }
        })}
        if(type=='edit'){
            saveArea({id:data.id,parentId:manageInfo.pid,name:data.name}).then((res)=>{
                if(res.data.state.value==0){
                    this.setState({
                        isManageVisible:false
                    })
                    message.success('编辑成功')
                    this.requestList1(manageInfo.pid)
                    this.ManageForm.resetValue()
                }
                else{
                    this.renderModalSimple('warning','错误','编辑失败') 
                }
            })
        }
    }
    }).catch((e) => {})
}
     /**
     * @description 点击事件-删除（有下级）
     * @function handleDeleteSubmit
     * @memberof DivisionManage
     */
    handleDeleteSubmit=()=>{
        let manageInfo=this.state.manageInfo;
        // console.log(formData)
        this.DeleteForm.props.form.validateFields((err)=>{
            if(!err){
        delArea({id:manageInfo.id}).then((res)=>{
            if(res.data.state.value==0){
                this.setState({
                    isDeleteVisible:false
                })
                message.success('删除成功')
                this.requestList2(manageInfo.pid)
                this.DeleteForm.resetValue()
            }else{
                this.renderModalSimple('warning','错误','删除失败') 
            }
        })
    }
      })
    }
    /**
     * @description 搜索行政区划
     * @function handleSearch
     * @memberof DivisionManage
     */
    handleSearch = () => {
        let _this=this
        let formData = this.searchRef.handleSearch()
        queryAreaListData({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize,keyword:formData.keyword}).then((res)=>{
          if(res.status === 200){
              utils.tableListKey(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
              this.setState({
                  total: res.data.result.total,
                  areaOptions: res.data.result.rows,
                  page: res.data.result.page,
                  pagination: utils.pagination(res.data,(current,size)=>{
                      _this.state.params.pageNum=current
                      _this.state.params.pageSize=size
                      this.handleSearch()
                  }, (current,size)=>{
                      _this.state.params.pageNum=current
                      _this.state.params.pageSize=size
                      this.handleSearch()
                  }, res.data.result.pageSize)
              })
          }
      }).catch((err)=>{
          console.log(err)
      })
    }
    /**
     * @description 自动展开子项
     * @function changeExpandedRows
     * @param {Object} 已展开的行
     * @memberof DivisionManage
     */
    changeExpandedRows = (expandedRows) => {
            this.setState({
                expandedRows,
            })
        };
        
    render(){
        const columns = [
            {
              title: '行政区划名称',
              dataIndex: 'name',
              key: 'name',
              fixed:'left',
              width:280,
              render(text) {
                return <span title={text}>{text}</span>
            },
            },
            {
              title: '编号',
              dataIndex: 'id',
              key: 'id',
            },
            // {
            //   title: '代码',
            //   dataIndex: 'code',
            //   key: 'code',
            // },
            {
              title: '行政级别',
              dataIndex: 'level',
              key: 'level',
              render:(level)=>{
                if(level==0){
                    return <a style={{color:'#595959'}}>/</a>
                    }
                if(level==1){
                return <a style={{color:'#595959'}}>省</a>
                }
                if(level==2){
                    return <a style={{color:'#595959'}}>市</a>
                    }
                if(level==3){
                        return <a style={{color:'#595959'}}>区/县</a>
                        }
                if(level==4){
                     return <a style={{color:'#595959'}}>乡镇街道</a>
                }
           },
            },
            {
              title: '更新时间',
              dataIndex: 'updateDate',
              key: 'updateDate',
            },
            {
              title: '操作',
              dataIndex: 'opera',
              key: 'opera',
              render: (text,record) => {
                if(record.id==0){
                  return<div>/
                </div>
                }
                if(record.isLeaf==true){
                    return<div>
                    <a title="编辑" onClick={()=>this.handleEdit('edit',record)}><Icon type="edit"style={{paddingLeft:27,fontSize:20,color:"#52c41a"}}/></a> 
                    <a title="删除" onClick={()=>this.handleDelete(record)}><Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{paddingLeft:7,fontSize:20}}/></a>
                    <a title="上移" onClick={()=>this.shiftUp(record)}><Icon type="arrow-up" style={{paddingLeft:7,fontSize:20,color:"#595959"}}/></a>
                    <a title="下移" onClick={()=>this.shiftDown(record)}><Icon type="arrow-down" style={{paddingLeft:7,fontSize:20,color:"#595959"}}/></a>
                    </div>
                  }
                return<div>
                <a title="新增" onClick={()=>this.handleAdd('create',record)}><Icon type="plus"style={{fontSize:20}}/></a> 
                <a title="编辑" onClick={()=>this.handleEdit('edit',record)}><Icon type="edit"style={{paddingLeft:7,fontSize:20,color:"#52c41a"}}/></a> 
                <a title="删除" onClick={()=>this.handleDelete(record)}><Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{paddingLeft:7,fontSize:20}}/></a>
                <a title="上移" onClick={()=>this.shiftUp(record)}><Icon type="arrow-up" style={{paddingLeft:7,fontSize:20,color:"#595959"}}/></a>
                <a title="下移" onClick={()=>this.shiftDown(record)}><Icon type="arrow-down" style={{paddingLeft:7,fontSize:20,color:"#595959"}}/></a>
                </div>
            }
            },
          ];
          
        return(
            <div className='manage'>
          <Card>
           <DivisionForm handleSearch={this.handleSearch} wrappedComponentRef={(inst) => this.searchRef = inst}/>
            <Table 
                columns={columns} 
                dataSource={this.state.areaOptions}
                bordered
                onRow={(record,index) => {
                  return {
                      onClick:()=>{
                          this.onRowClick(record,index);
                      }
                  };
              }}
                expandedRowKeys={this.state.expandedRows}
                onExpandedRowsChange={this.changeExpandedRows.bind(this)}
                onExpand={this.handleOnExpand}
                pagination={this.state.pagination}
            />
                <Modal
                    title={this.state.title}
                    visible={this.state.isManageVisible}
                    onOk={()=>{this.handleSubmit()}}
                    onCancel={()=>{
                        this.ManageForm.props.form.resetFields();
                        this.setState({
                          isManageVisible:false,
                          manageInfo:''
                        })
                    }}
                >
                    <ManageForm manageInfo={this.state.manageInfo} type={this.state.type}  wrappedComponentRef={(inst) => this.ManageForm = inst }/>
                </Modal>
                <Modal
                    title={this.state.title}
                    visible={this.state.isDeleteVisible}
                    onOk={()=>{this.handleDeleteSubmit()}}
                    width={300}
                    onCancel={()=>{
                        this.DeleteForm.props.form.resetFields();
                        this.setState({
                          isDeleteVisible:false,
                        })
                    }}
                >
                    <DeleteForm deleteInfo={this.state.deleteInfo} wrappedComponentRef={(inst) => this.DeleteForm = inst }/>
                </Modal>
            </Card>
            </div>
        );
    }
}
export default DivisionManage;

/**
 * @description 组件-搜索
 * @class DivisionForm
 * @extends {React.Component}
 */
class DivisionForm extends React.Component{
    handleReset = ()=>{
      this.props.form.resetFields();
    }
    handleSearch=()=>{ //调用父组件方法
        return this.props.form.getFieldsValue()
    }
  render(){
      const {getFieldDecorator} = this.props.form;
      return (
          <Form layout="inline"> 
          <FormItem>
              {getFieldDecorator('keyword')(<Input
              placeholder="请输入行政区划名称/编号" prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
              style={{ width: 264,marginBottom:20}}
              />)}
              </FormItem>
          <FormItem>
              <Button type="primary" onClick={this.props.handleSearch}>
                      搜索
              </Button>
          </FormItem>
          </Form>
      );
  }
}
DivisionForm = Form.create({})(DivisionForm);


/**
 * @description 组件-编辑和新增弹框
 * @class DivisionForm
 * @extends {React.Component}
 */
class ManageForm extends React.Component{
  //重置
  resetValue = ()=>{
      this.props.form.resetFields()
  }
  getAdminLevel = (level)=>{
    return {
        '1':'省',
        '2':'市',
        '3':'区/县',
        '4':'乡镇街道'
    }[level]
}
  render(){
      const manageInfo = this.props.manageInfo || {};
      const type = this.props.type;
      const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
          labelCol: {span: 6},
          wrapperCol: {span: 16}
      };
      return (
          <Form layout="horizontal">
              <FormItem label="行政区划名称" {...formItemLayout}>
                  {
                      getFieldDecorator('name',{
                          initialValue:type=='edit'?manageInfo.name:[],rules: [{ required: true, message: '请输入行政区划名称'},]
                      })(
                          <Input type="text"/>
                      )
                      }
              </FormItem>
              <FormItem label="编码" {...formItemLayout}>
                  {
                      getFieldDecorator('id',{
                        initialValue:type=='edit'?manageInfo.id:[], rules: [{ required: true, message: '请输入编码' },]
                      })(
                          <Input type="text"/>
                      )
                      }
              </FormItem>
              <FormItem label="行政级别" {...formItemLayout}>
                  {
                    manageInfo.level&&type=='edit'?this.getAdminLevel(manageInfo.level):this.getAdminLevel(manageInfo.level+1)
                      }
              </FormItem>
          </Form>
      );
  }
}
ManageForm = Form.create({})(ManageForm);

/**
 * @description 组件-输入"确认删除"弹框
 * @class DivisionForm
 * @extends {React.Component}
 */
class DeleteForm extends React.Component{
    //重置
    resetValue = ()=>{
        this.props.form.resetFields()
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="horizontal">
                <FormItem label="请输入delete确认删除">
                    {
                        getFieldDecorator('contain',{
                          initialValue:[], rules: [{ required: true,message: '请输入delete'},{pattern: /[d][e][l][e][t][e]/,message: '请输入delete'}]
                        })(
                            <Input type="text"/>
                        )
                        }
                </FormItem>
            </Form>
        );
    }
}
DeleteForm = Form.create({})(DeleteForm);