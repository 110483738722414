import React, { Component } from 'react'
import { Form, Input, Button, Select, Checkbox, Table, Modal, Tabs, Pagination, message,Tag } from 'antd'
import utils from './../../Common/js/utils'
import {getAdvisorList,getAdvisorItem,closeHaOrder} from './../../Api/index'
// import { orderList,getList } from '../../Mock'

import CloseOrder from './components/closeOrder'//关闭订单
import PictureTopTwo from './components/PictureTopTwo'//查看详情-通用top
import PictureCenter from './components/PictureCenter'//查看详情-通用center
import PictureBottom1 from './components/PictureBottom1'//查看详情-bottom 1
import PictureBottom2 from './components/PictureBottom2'//查看详情-bottom 2
import PictureBottom3 from './components/PictureBottom3'//查看详情-bottom 3
import PictureBottom4 from './components/PictureBottom4'//查看详情-bottom 4

const { Option } = Select
class PhoneOrder extends Component {
    //弹出初始状态
    state = {
        OrderList:[],//列表数据
        pageNum:1,
        pageSize:30,
        loading:false,//加载默认状态
        closevisible: false,//关闭订单弹出框默认状态
        paymentvisible:false,//待付款 查看详情
        servicevisible:false,//待服务 查看详情
        servicedvisible:false,//服务中 查看详情
        completevisible:false,//已完成 查看详情
        closeviewvisible:false,//已关闭 查看详情
    };
    componentWillMount() {
        this.RequestOrderList();
    }
    //列表数据请求接口
    RequestOrderList = () => {
        // console.log(type);
        let _this = this
        getAdvisorList({
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            orderType:1
        }).then((res)=>{
            console.log(res)
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total:res.data.result.total,
                    OrderList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    },(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //下拉
    handleSelectChange = value => {
        // console.log(value);
        this.props.form.setFieldsValue({
            //note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
        });
    };
    //搜索按钮
    handleSubmit = ()=> {
        let fieldsParams = this.props.form.getFieldsValue()
        let _this = this
        getAdvisorList({
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            orderStatus:fieldsParams.orderStatus,
            keyword:fieldsParams.keyword,
            orderType:1
        }).then((res)=>{
            console.log(res)
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total:res.data.result.total,
                    OrderList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    },(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        })
    };
    //重置按钮
    handleReset = () => {
        this.props.form.resetFields();
        this.setState({
            params:{
                pageNum:1,
                pageSize:30
            }
        })
        this.RequestOrderList(this.state.params)
    };

    /////////////////////////////////////////关闭订单
    //关闭订单弹出状态
    closeOrder = (record) => {
        this.setState({
            closevisible: true,
            loading:true,
            record:record
        });
    };
    //关闭订单确定
    closeOrderOk = () => {
        //console.log(e);
        console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        let formdata = this.formRef.getItemsValue();
        console.log(formdata);
        this.closeOrderItem(formdata);
        this.formRef.resetValue(); 
        this.setState({
            closevisible: false,
            loading:false
        });
    };
    //关闭订单取消
    closeOrderCancel = (e) => {
        this.setState({
            closevisible: false,
            loading:false
        });
    };
    closeOrderItem = (data) => {
        console.log(this.state.record,data);
        let pearmsData = new FormData()
		pearmsData.append("orderId",this.state.record.id)
        pearmsData.append("isRefund",data.isRefund)
        pearmsData.append("remarks",data.remarks)
        closeHaOrder(pearmsData).then((res)=>{
            this.RequestOrderList();
        }).catch((error) => {
            console.log(error);
        })
    }

    /////////////////////////////////////////待付款 查看详情
    //待付款 弹出状态
    paymentView = (record) => {
        this.setState({
            paymentvisible: true,
            loading:true,
            record:record
        });
        this.RequestOrderItem(record);
    };
    //待付款 确定
    paymentOk = (e) => {
        this.setState({
            paymentvisible: false,
            loading:false
        });
    };
    //待付款 取消
    paymentCancel = (e) => {
        console.log(e);
        this.setState({
            paymentvisible: false,
            loading:false
        });
    };

    /////////////////////////////////////////待服务 查看详情
    //待服务 弹出状态
    serviceView = (record) => {
        this.setState({
            servicevisible: true,
            loading:true,
            record:record
        });
        this.RequestOrderItem(record);
    };
    //待服务 确定
    serviceOk = (e) => {
        //console.log(e);
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        this.setState({
            servicevisible: false,
            loading:false
        });
    };
    //待服务 取消
    serviceCancel = (e) => {
        console.log(e);
        this.setState({
            servicevisible: false,
            loading:false
        });
    };

    /////////////////////////////////////////服务中 查看详情
    //服务中 弹出状态
    servicedView = (record) => {
        this.setState({
            servicedvisible: true,
            loading:true,
            record:record
        });
        this.RequestOrderItem(record);
    };
    //服务中 确定
    servicedOk = (e) => {
        //console.log(e);
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        this.setState({
            servicedvisible: false,
            loading:false
        });
    };
    //服务中 取消
    servicedCancel = (e) => {
        console.log(e);
        this.setState({
            servicedvisible: false,
            loading:false
        });
    };

    /////////////////////////////////////////已完成 查看详情
    //已完成 弹出状态
    completeView = (record) => {
        this.setState({
            completevisible: true,
            loading:true,
            record:record
        });
        this.RequestOrderItem(record);
    };
    //已完成 确定
    completeOk = (e) => {
        //console.log(e);
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        this.setState({
            completevisible: false,
            loading:false
        });
    };
    //已完成 取消
    completeCancel = (e) => {
        console.log(e);
        this.setState({
            completevisible: false,
            loading:false
        });
    };

    /////////////////////////////////////////已关闭 查看详情
    //已关闭 弹出状态
    closeView = (record) => {
        this.setState({
            closeviewvisible: true,
            loading:true,
            record:record
        });
        this.RequestOrderItem(record);
    };
    //已关闭 确定
    closeOk = (e) => {
        //console.log(e);
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        this.setState({
            closeviewvisible: false,
            loading:false
        });
    };
    //已关闭 取消
    closeCancel = (e) => {
        console.log(e);
        this.setState({
            closeviewvisible: false,
            loading:false
        });
    };

    //查看详情 接口请求
    RequestOrderItem = (data) => {
        // console.log(data);
        getAdvisorItem({
            orderId:data.id
        }).then((res) => {
            console.log(res);
            this.setState({
                record:res.data.result
            });
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        //表格 表头
        const columns = [
            {title: '订单号',dataIndex: 'id',align:'center'},
            // {title: '下单微信号',dataIndex: 'transactionId',align:'center'},
            {title: '订单金额',dataIndex: 'orderMoney',align:'center'},
            {title: '申请人',dataIndex: 'fullname',align:'center'},
            {title: '服务医生',dataIndex: 'userDoctorName',align:'center'},
            {title: '订单状态',dataIndex: 'orderState',align:'center',
                render: (text,record) => {
                    if(record.orderState === 0) {
                        return (
                            <div>待支付</div>
                        )
                    }else if(record.orderState === 1){
                        return (
                            <div>待服务</div>
                        )
                    }else if(record.orderState === 2){
                        return (
                            <div>服务中</div>
                        )
                    }else if(record.orderState === 3){
                        return (
                            <div>医生已完成</div>
                        )
                    }else if(record.orderState === 4){
                        return (
                            <div>关闭已退款</div>
                        )
                    }else if(record.orderState === 5){
                        return (
                            <div>关闭未退款</div>
                        )
                    }else if(record.orderState === 6){
                        return (
                            <div>用户已完成</div>
                        )
                    }
                }
            },
            {title: '订单创建时间',dataIndex: 'createDate',align:'center'},
            {title: '操作',dataIndex: 'operation',align:'center',
                render: (text,record) => {
                    if(record.orderState === 0) {//a.状态为待付款时
                        return (
                            <div>
                                <a className="blue mlr5" onClick={() => this.paymentView(record)}>查看详情</a>
                            </div>
                        )
                    }else if(record.orderState === 1){
                        return (
                            <div>
                                <a className="blue mlr5" onClick={() => this.serviceView(record)}>查看详情</a>
                                <a className="red mlr5" onClick={() => this.closeOrder(record)}>关闭订单</a>
                            </div>
                        )
                    }else if(record.orderState === 2){
                        return (
                            <div>
                                <a className="blue mlr5" onClick={() => this.servicedView(record)}>查看详情</a>
                                <a className="red mlr5" onClick={() => this.closeOrder(record)}>关闭订单</a>
                            </div>
                        )
                    }else if(record.orderState === 3){
                        return (
                            <div>
                                <a className="blue mlr5" onClick={() => this.completeView(record)}>查看详情</a>
                            </div>
                        )
                    }else{
                        return (
                            <div>
                                <a className="blue mlr5" onClick={() => this.closeView(record)}>查看详情</a>
                            </div>
                        )
                    }
                }
            }
        ];
        return (
            <div>
                <Form layout="inline">
                    <Form.Item>
                        {getFieldDecorator('keyword')(
                            <Input
                            style={{width:230}}
                            placeholder="订单号/申请人/服务医生"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('orderStatus')(
                            <Select
                            style={{width:230}}
                            placeholder="订单状态"
                            onChange={this.handleSelectChange}
                            >
                                <Option value={0}>待支付</Option>
                                <Option value={1}>待服务</Option>
                                <Option value={2}>服务中</Option>
                                <Option value={3}>医生已完成</Option>
                                <Option value={4}>关闭已退款</Option>
                                <Option value={5}>关闭未退款</Option>
                                <Option value={6}>用户已完成</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSubmit}>
                            搜 索
                        </Button>
                        <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                            重 置
                        </Button>
                    </Form.Item>
                </Form>
                
                <Table bordered className="mt20" columns={columns} dataSource={this.state.OrderList} pagination={false} />
                <Pagination {...this.state.pagination} className="fr margin10" />

                {/* 关闭订单 */}
                <Modal
                width={450}
                title="关闭订单"
                visible={this.state.closevisible}
                loading={this.state.loading}
                onOk={this.closeOrderOk}
                onCancel={this.closeOrderCancel}
                okText="确定"
                cancelText="取消"
                >
                    <CloseOrder 
                    wrappedComponentRef={(form) => this.formRef = form}//使用 wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 待付款 订单详情 */}
                <Modal
                width={700}
                title="订单详情"
                visible={this.state.paymentvisible}
                loading={this.state.loading}
                onOk={this.paymentOk}
                onCancel={this.paymentCancel}
                okText="确定"
                cancelText="取消"
                >
                    <PictureTopTwo record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureCenter record={this.state.record} />
                </Modal>

                {/* 待服务 订单详情 */}
                <Modal
                width={700}
                title="订单详情"
                visible={this.state.servicevisible}
                loading={this.state.loading}
                onOk={this.serviceOk}
                onCancel={this.serviceCancel}
                okText="确定"
                cancelText="取消"
                >
                    <PictureTopTwo record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureCenter record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureBottom1 record={this.state.record} />
                </Modal>

                {/* 服务中 订单详情 */}
                <Modal
                width={700}
                title="订单详情"
                visible={this.state.servicedvisible}
                loading={this.state.loading}
                onOk={this.servicedOk}
                onCancel={this.servicedCancel}
                okText="确定"
                cancelText="取消"
                >
                    <PictureTopTwo record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureCenter record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureBottom2 record={this.state.record} />
                </Modal>

                {/* 已完成 订单详情 */}
                <Modal
                width={700}
                title="订单详情"
                visible={this.state.completevisible}
                loading={this.state.loading}
                onOk={this.completeOk}
                onCancel={this.completeCancel}
                okText="确定"
                cancelText="取消"
                >
                    <PictureTopTwo record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureCenter record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureBottom3 record={this.state.record} />
                </Modal>

                {/* 已关闭 订单详情 */}
                <Modal
                width={700}
                title="订单详情"
                visible={this.state.closeviewvisible}
                loading={this.state.loading}
                onOk={this.closeOk}
                onCancel={this.closeCancel}
                okText="确定"
                cancelText="取消"
                >
                    <PictureTopTwo record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureCenter record={this.state.record} />
                    <div className="PointLine"></div>
                    <PictureBottom4 record={this.state.record} />
                </Modal>
                
            </div>
        )
    }
}

const PhoneOrderForm = Form.create()(PhoneOrder);

export default PhoneOrderForm;