/*
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'

import { Table,  Pagination, } from 'antd'
import { queryUserIntegralList } from '../../../Api'
import utils from '../../../Common/js/utils'    //表格分页等     
/**
 * @classdesc 积分列表
 */
class Article extends Component {
    /**
     * @description: state自定义
     * @param {object} columns 表格配置
     * @param {object} params 分页
     */
    state = {
        columns: [
            {
                title: "序号", dataIndex: 'key', align: 'center', width: 40, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "账号", dataIndex: 'username', align: 'center', width: 120, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "姓名", dataIndex: 'fullname', align: 'center', width: 80, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "所在单位", dataIndex: 'companyNames', align: 'center', width: 120, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: "积分", dataIndex: 'integralSum', align: 'center', width: 80, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            
        ],
        params:{
            pageNum:1,
            pageSize:10
        },
    }
    componentWillMount() {
        this.requestTableList()
    }
    /**
     *  请求表格数据函数
     * @function requestTableList
     */
    requestTableList = ()=>{
        let params=this.state.params
        let _this = this
        queryUserIntegralList(params).then(res=>{
            if(res.data.state.value===0){
                utils.tableListKey(res.data.result.rows,_this.state.params.pageNum,_this.state.params.pageSize)
                this.setState({
                    total: res.data.result.rows.length,
                    IntegralListData: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestTableList()
                    }, (current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestTableList()
                    }, res.data.result.pageSize)
                })
            }
        })
    }
    render() {
        return (
            <div className="home">
                <Table className="mt20"  
                    bordered 
                    dataSource={this.state.IntegralListData}
                    columns={this.state.columns} 
                    pagination={false}
                /> 
                <Pagination {...this.state.pagination}
                    pageSize={this.state.params.pageSize}
                    className="fr mt20"
                />
            </div>
        )
    }
}
export default Article;