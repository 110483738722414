import React, { Component } from 'react'
import {Tabs} from 'antd'
import {getAdvisorList,getOrderDealWith,getOrderView,getOrderView2} from './../../Api/index'
import utils from './../../Common/js/utils'

import PictureOrder from './PictureOrder'//图文订单
import PhoneOrder from './PhoneOrder'//电话订单

const { TabPane } = Tabs
class HealthOrderList extends Component {

    // state = {
    //     OrderList:[],//列表数据
    //     pageNum:1,
    //     pageSize:30,
    //     pagination:[]
    // };
    // componentWillMount() {
    //     this.RequestOrderList(2);
    // }
    // //列表数据请求接口
    // RequestOrderList = (type) => {
    //     // console.log(type);
    //     let _this = this
    //     getAdvisorList({
    //         pageNum:this.state.pageNum,
    //         pageSize:this.state.pageSize,
    //         orderType:type
    //     }).then((res)=>{
    //         console.log(res)
    //         if(res.status === 200){
    //             utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
    //             this.setState({
    //                 total:res.data.result.total,
    //                 OrderList:res.data.result.rows,
    //                 page:res.data.result.page,
    //                 records:res.data.result.records
    //                 // pagination:utils.pagination(res.data,(current,size)=>{
    //                 //     _this.state.pageNum = current
    //                 //     _this.state.pageSize = size
    //                 //     this.RequestOrderList()
    //                 // },(current,size)=>{
    //                 //     _this.state.pageNum = current
    //                 //     _this.state.pageSize = size
    //                 //     this.RequestOrderList()
    //                 // })
    //             })
    //         }
    //     }).catch((error)=>{
    //         console.log(error);
    //     }) 
    // }

    //tab切换
    callback = (key) => {
        console.log(key);
        // if(key === "1"){
        //     this.RequestOrderList(2);
        // }else if(key === "2"){
        //     this.RequestOrderList(1);
        // }
    }
    render() {
        return (
            <div>
                <Tabs onChange={this.callback} type="line" animated={false} defaultActiveKey="1">
                    <TabPane tab="图文订单" key="1">
                        <PictureOrder />
                    </TabPane>
                    <TabPane tab="电话订单" key="2">
                        <PhoneOrder />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default HealthOrderList;