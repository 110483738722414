import React, { Component } from 'react'
import {Card,Modal} from 'antd'
import {getSoftAdjust,getSoftBuy} from './../../../../Api/index'
import moment from 'moment'

import imgUrl from './../../../../Common/images/fswsyl.png'
import SoftwareThree from './../Common/SoftwareThree'//购买放射卫生软件
import Adjust from './../Common/Adjust3'//调整软件属性
let ids
class RadiaBasic extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        visible: false,
        adjustvisible:false,
        RadiaBasic: this.props.RadiaBasic,
        OrderList:[],//列表数据
        pageNum:1,
        pageSize:10,
    };

    // componentWillMount() {
    //     this.requestBasic();
    // }

    //购买软件弹出显示状态
    showModalSoft = () => {
        this.setState({
            visible: true,
        });
    };
    //购买软件弹出框确定按钮操作
    softwareOk = () => {
        this.setState({ loading: true });
        let formdata = this.formRef.getItemsValue();
        if(
            formdata.comboId !== '' && formdata.comboId !== undefined && 
            formdata.payCount !== '' && formdata.payCount !== undefined && 
            formdata.comboSpaceCount !== '' && formdata.comboSpaceCount !== undefined && 
            formdata.type !== '' && formdata.type !== undefined 
        ){
            this.setState({ loading: false, visible: false });
            this.getBuyRequest(formdata);  
            this.formRef.resetValue(); 
        }else{
            this.setState({ loading: true, visible: true });
        }
    };
    //购买软件弹出框取消按钮操作
    softwareCancel = () => {
        this.setState({ visible: false });
        this.formRef.resetValue(); 
    };
    //购买软件请求接口
    getBuyRequest = (data) => {
        getSoftBuy({
            cid:this.state.cid,
            softId:2,
            comboId:data.comboId,
            payCount:data.payCount,
            comboUserCount:data.comboUserCount,
            comboSpaceCount:data.comboSpaceCount,
            type:data.type,
            promocode:data.promocode,
            payCost:data.payCost,
            tradeNo:data.tradeNo,
            remark:data.remark
        }).then((res)=>{
            this.props.requestBasic(2);
            this.props.fnOrder();
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //调整属性弹出显示状态
    showModalAdjust = () => {
        this.setState({
            adjustvisible: true,
        });
    };
    //调整属性弹出框确定按钮操作
    adjustOk = () => {
        this.setState({ loading: true });
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, adjustvisible: false });
        console.log(formdata,"formdata")
        this.getSoftAdjustRegister(formdata);
    };
    //调整属性弹出框取消按钮操作
    adjustCancel = () => {
        this.setState({ adjustvisible: false });
        this.formRef.resetValue(); 
    };
    componentWillMount() {
        ids = this.props.RadiaBasic
        console.log(this.props,"this.props")
    }

    componentWillReceiveProps(nextProps){
        ids = nextProps.RadiaBasic
    }
    //调整接口
    getSoftAdjustRegister = (data) => {
        console.log(data,this.state,ids);
        // console.log(ids,this.state.RadiaBasic.corpSoftId,"==============");
        getSoftAdjust({
            corpSoftId:this.props.RadiaBasic.corpSoftId,
            // corpSoftId:ids,
            newSpaceTotal:data.comboSpaceCount,
            newUserTotal:data.comboUserCount,
            newEdate:moment(data.dueTime).format("YYYY-MM-DD"),
            remark:data.remark
        }).then((res)=>{
            //console.log("1111111111");
            this.props.requestBasic(2);
            this.props.fnRecord();
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    render() {
        const RadiaBasic = this.props.RadiaBasic;
        //console.log(RadiaBasic,"qqqqqqqqqqqqqqqqqq");
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="基本信息">
                    <div className="HealthBasicPicture">
                        <img alt="职业健康企业版" src={imgUrl} />
                        <ul>
                            <li>
                                <div className="title">软件名称：</div>
                                <div className="neire">{RadiaBasic.softName}</div>
                            </li>
                            <li>
                                <div className="title">当前套餐类型：</div>
                                <div className="neire">{RadiaBasic.comboName}</div>
                            </li>
                            <li>
                                <a className="red" onClick={this.showModalSoft}>【购买】</a>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div className="title">空间数：</div>
                                <div className="neire">{RadiaBasic.comboSpaceCount} GB</div>
                            </li>
                            <li>
                                <div className="title">用户数：</div>
                                <div className="neire">{RadiaBasic.comboUserCount}</div>
                            </li>
                            <li>
                                <div className="title">到期时间：</div>
                                <div className="neire">{RadiaBasic.dueTime}</div>
                            </li>
                            <li>
                                <a className="blue" onClick={()=>this.showModalAdjust(RadiaBasic)}>【调整】</a>
                            </li>
                        </ul>
                    </div>
                </Card> 

                {/* 购买软件弹出 */}
                <Modal
                width={620}
                visible={this.state.visible}
                loading={this.state.loading}
                title="创建购买订单"
                onOk={this.softwareOk}
                onCancel={this.softwareCancel}
                okText="确定"
                cancelText="取消"
                >
                    <SoftwareThree
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 调整软件属性弹出 */}
                <Modal
                width={620}
                visible={this.state.adjustvisible}
                loading={this.state.loading}
                title="调整软件属性"
                onOk={this.adjustOk}
                onCancel={this.adjustCancel}
                okText="确定"
                cancelText="取消"
                >
                    <Adjust RadiaBasic={RadiaBasic}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
            </div>
        )
    }
}

export default RadiaBasic;