/**
 * @description 分类答题主页
 * @author yzx yangzhixin177@163.com
 */
import React, { Component } from 'react'
import { Tabs, Radio,Card,Icon,Table,Modal,Form,message,Button,Input} from 'antd';
import {Link} from 'react-router-dom'
import {queryExamList,queryExamQuestionList,delExam,addSpecialExam} from '../../../Api'
import './../../../Common/css/path.css'
import utils from './../../../Common/js/utils'
const FormItem = Form.Item;
const { TabPane } = Tabs;
/**
 * @description 显示分类答题页面的组件
 * @class Categorymanage
 * @extends {Component}
 */
class Categorymanage extends Component {
    /**
     * @description 组件内数据
     * @param {Object} dataSource 每周答题试卷列表
     * @param {Object} dataSource1 专项答题试卷列表
     * @param {Object} detail 每周答题详情数据
     * @param {Object} params 页码，每页大小
     * @param {Object} params1 页码，每页大小
     * @memberof Categorymanage
     */
    state={
        dataSource:[],
        dataSource1:[],
        detail:[],
        params:{
            pageNum:1,
            pageSize:10
        },
        params1:{
            pageNum:1,
            pageSize:10
        }
    }
    /**
     * @description 查看每周试题详情
     * @function handleDetail
     * @param {Object} record 当前行对象 
     * @memberof Categorymanage
     */
    handleDetail=(record)=>{
            queryExamQuestionList({examId:record.id}).then((res)=>{
              if(res.data.state.value==0){
              this.setState({
                detail:res.data.result
              })
            }
          })
        this.setState({
            title:'每周答题查看-'+record.name,
            isWeekVisible:true
        })
    }

    /**
     * @description 点击行事件
     * @function onRowClick
     * @param {Object} record 当前行对象 
     * @param {Number} index 当前行下标
     * @memberof Categorymanage
     */
    onRowClick = (record,index)=>{
        let selectKey = [index];
        this.setState({
            selectedRowKeys:selectKey,
            selectedItem: record,
            selectedIds:[0]
        })
    } 
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof CategorymanageEdit
     */
    renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({ 
            title:title?title:'警告',
            content:content?content:''
        })
    }
    /**
     * @description 删除试卷
     * @function handleDelete
     * @param {Object} record 当前行对象 
     * @memberof Categorymanage
     */
    handleDelete=(record)=>{
        // console.log(record)
        let _this = this
        Modal.confirm({
            title:'删除提示',
            content: `您确定要删除试卷吗？`,
            onOk:()=>{
                delExam({examId:record.id}).then((res)=>{
                    if(res.data.state.value == 0){
                        _this.setState({
                            isVisible:false
                        })
                        message.success('删除成功');
                        _this.requestList1();
                    }
                    else{
                        this.renderModalSimple('warning','错误','删除失败，请重新删除!')
                    }
                })
            }
                })
    }
    /**
     * @description 请求每周答题列表
     * @function requestList
     * @memberof Categorymanage
     */
    requestList = ()=>{
        let _this = this
        queryExamList({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize,isSpecial:false}).then((res)=>{
            // console.log(res,"list");
            if(res.status === 200){
                // console.log(res.data.result.rows)
                // console.log(res.data.result.total)
                utils.tableListKey(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        // console.log(size,"size")
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList()
                    }, (current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList()
                    }, res.data.result.pageSize)
                })
            }
        }).catch((err)=>{
            // console.log(err)
        })
    }
    /**
     * @description 请求专项答题列表
     * @function requestList1
     * @memberof Categorymanage
     */
    requestList1 = ()=>{
        let _this = this
        queryExamList({pageNum: _this.state.params1.pageNum, pageSize: _this.state.params1.pageSize,isSpecial:true}).then((res)=>{
           
            // console.log(res,"list");
            if(res.status === 200){
                // console.log(res.data.result.rows)
                // console.log(res.data.result.total)
                utils.tableListKey(res.data.result.rows,this.state.params1.pageNum,this.state.params1.pageSize)
                this.setState({
                    total1: res.data.result.total,
                    dataSource1: res.data.result.rows,
                    page1: res.data.result.page,
                    pagination1: utils.pagination(res.data,(current,size)=>{
                        // console.log(size,"size")
                        _this.state.params1.pageNum=current
                        _this.state.params1.pageSize=size
                        this.requestList1()
                    }, (current,size)=>{
                        _this.state.params1.pageNum=current
                        _this.state.params1.pageSize=size
                        this.requestList1()
                    }, res.data.result.pageSize)
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
   
    /**
     * @description 点击新增按钮事件
     * @function handleAdd
     * @memberof Categorymanage
     */
    handleAdd = ()=>{
        this.setState({
            title:"新增",
            isAddVisible:true
        })
    }
    /**
     * @description 新增试卷提交事件
     * @function handleSubmit
     * @memberof Categorymanage
     */
    handleSubmit = ()=>{
        let data = this.AddForm.props.form.getFieldsValue();
        
        this.AddForm.props.form.validateFields((err)=>{
            if(!err){
        addSpecialExam({name:data.name}).then((res)=>{
                if(res.status === 200){
                    this.setState({
                        isAddVisible:false
                    })
                    message.success('新增成功')
                    this.requestList1()
                    this.AddForm.resetValue()
                }else{
                    this.renderModalSimple('warning','错误','新增失败！') 
                }
            })
        }
        }).catch((e) => {})
    }
    onTabChange = (activeKey) => {
        this.setState({ activeKey });
      }
      /**
       * @description 日期转换
       * @function formatDate
       * @memberof CategorymanageEdit
       * @param {string} now 时间戳
       * @return {string} year + "-" + month + "-" + date 格式转化后的时间
       */
    formatDate = (now) => {
        var nowDate = new Date(now)
        var year = nowDate.getFullYear();  //取得4位数的年份
        var month = nowDate.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
        var date = nowDate.getDate();      //返回日期月份中的天数（1到31）
        return year + "-" + month + "-" + date;
    }
    /**
     * @description react生命周期，在渲染前调用
     * @function componentWillMount
     * @memberof Categorymanage
     */
    componentWillMount =()=>{
        this.requestList()
        this.requestList1()
    }
    componentDidMount=()=>{
        let _this=this
  
        if(this.props.location.state){
            if(this.props.location.state.homeIndex=='2'){
                _this.setState({
                    activeKey:'2'
                })
            }
            else{
                _this.setState({
                    activeKey:'1'
                })
            }
        }
        this.props.history.replace({
            pathname: `/training/category`,
            state: {activeKey:'1'}
        });
    }
      render() {
        const _this=this
        // const key = this.props.location.state?this.props.location.state.homeIndex:'1'
        
        const title = () => {
            return (
                <div>
                    <div className="fr">
                    <Button type="primary" onClick={()=>this.handleAdd()}>新增</Button>   
                    </div>
                    <div className="clear"></div>
                </div>
            )
        };
        const columns = [
            {
                title: '序号',
                key:'key',
                dataIndex: 'key'
            }, {
                title: '名称',
                key: 'name',
                dataIndex: 'name',
                render(text, record) {
                    return <a title={text} onClick={() => { _this.handleDetail(record) }} >{text}</a>
                },
            },{
                title: '答题人数',
                key: 'totalUser',
                dataIndex: 'totalUser',
            },{
                title: '状态',
                key: 'status',
                dataIndex: 'status',
                render(text) {
                    if(text==1){
                        return '未上架'
                    }
                    else{
                        return '已上架'
                    }
                },
            },{
                title: '更新时间',
                key: 'updateDate',
                dataIndex: 'updateDate',
                render(text) {
                    if(text){
                        return <span title={_this.formatDate(text)}>{_this.formatDate(text)}</span>
                    }else{
                        return '-'
                    }
                },
            }
            ,{
                title: '操作',
                dataIndex: 'opera',
                    render: (type,record) => {
                        if(record.status==1){
                          return<div>
                       <Link to={{pathname: `/training/category/edit/${record.id}`,state:{name:record.name}}} title='编辑'><Icon type="edit"style={{fontSize:20}}/></Link>
                       </div> 
                        }
                       
                        
                    }  
                }
        ]; 
        const columns1 = [
            {
                title: '序号',
                key:'key',
                dataIndex: 'key'
            }, {
                title: '专项名称',
                key: 'name',
                dataIndex: 'name',
                render(text, record) {
                        return<span>
        <Link to={{pathname: `/training/category/see/${record.id}`,state:{name:record.name,createByStr:record.createByStr,totalUser:record.totalUser,updateDate:record.updateDate}}}><span title={text}>{text}</span></Link>
               </span>
                },
            },{
                title: '上架时间',
                key: 'shelvesTime',
                dataIndex: 'shelvesTime',
                render(text) {
                    if(text){
                        return <span title={_this.formatDate(text)}>{_this.formatDate(text)}</span>
                    }else{
                        return '-'
                    }
                },
            },{
                title: '答题人数',
                key: 'totalUser',
                dataIndex: 'totalUser',
            },{
                title: '状态',
                key: 'status',
                dataIndex: 'status',
                render(text) {
                    if(text==1){
                        return '未上架'
                    }
                    else{
                        return '已上架'
                    }
                },
            },{
                title: '操作',
                width:150,
                dataIndex: 'opera',
                    render: (type,record) => {
                        if(record.status==1){
                          return<div>
                            <Link to={{pathname: `/training/category/editTwo/${record.id}`}} title='编辑'><Icon type="edit"style={{paddingRight:7,fontSize:20}}/>
                            
                            </Link>
                             <a title="删除" onClick={()=>this.handleDelete(record)}><Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{fontSize:20}}/></a> 
                        </div>
                        }
                    }  
                }
        ]; 
        return (
          <div>
           <Tabs activeKey={this.state.activeKey?this.state.activeKey:'1'} onChange={this.onTabChange}>
                <TabPane tab="每周答题" key="1">
                <Table
                        bordered
                        columns={columns}
                        dataSource={this.state.dataSource}
                        onRow={(record,index) => {
                            return {
                                onClick:()=>{
                                    this.onRowClick(record,index);
                                }
                            };
                        }}
                        rowKey={(record,index)=> index}
                        pagination={{ ...this.state.pagination, pageSize: this.state.params.pageSize}}
                    /> 
                <Modal className='detail'
                    title={this.state.title}
                    visible={this.state.isWeekVisible}
                    footer={
                        [] // 设置footer为空，去掉 取消 确定默认按钮
                      }            
                    onCancel={()=>{
                        this.setState({
                            isWeekVisible:false
                        })
                    }}
                >
                     <WeekProForm detail={this.state.detail}wrappedComponentRef={(inst) => this.WeekProForm = inst }/>
                </Modal>
                </TabPane>
                <TabPane tab="专项答题" key="2">
                <Table 
                        title={title}
                        bordered
                        columns={columns1}
                        dataSource={this.state.dataSource1}
                        onRow={(record,index) => {
                            return {
                                onClick:()=>{
                                    this.onRowClick(record,index);
                                }
                            };
                        }}
                        rowKey={(record,index)=> index}
                        pagination={{ ...this.state.pagination1, pageSize: this.state.params1.pageSize}}
                    /> 
                <Modal className='add'
                    title={this.state.title}
                    visible={this.state.isAddVisible}  
                    onOk={()=>{this.handleSubmit()}}      
                    onCancel={()=>{
                        this.setState({
                            isAddVisible:false
                        })
                    }}
                >
                     <AddForm wrappedComponentRef={(inst) => this.AddForm = inst }/>
                </Modal>
                </TabPane>
            </Tabs>
          </div>
        );
      }
} 
export default Categorymanage
/**
 * @description 组件-显示试题列表弹框(每周答题)
 * @class WeekProForm
 * @extends {React.Component}
 */
class WeekProForm extends React.Component{
    render(){
        const columns = [
            {
                title: '序号',
                key:'key',
                dataIndex: 'key',
                render:(text,record,index)=>`${index+1}`
            }, {
                title: '试题类型',
                key: 'questionType',
                dataIndex: 'questionType',
                render(text) {
                    if(text==1){
                        return '单选题'
                    }
                    if(text==2){
                        return '多选题'
                    }
                    else{
                        return '填空题'
                    }
                },
            },{
                title: '题干',
                key: 'stem',
                dataIndex: 'stem',
            },{
                title: '答案',
                key: 'result',
                dataIndex: 'result',
                render(text,record) {
                    if(record.questionType==1){
                        return <div>
                        <div className={record.result=='A'?'show':'none'}>{record.optionA}</div>
                        <div className={record.result=='B'?'show':'none'}>{record.optionB}</div>
                        <div className={record.result=='C'?'show':'none'}>{record.optionC}</div>
                        <div className={record.result=='D'?'show':'none'}>{record.optionD}</div>
                        </div>
                    }
                    if(record.questionType==2){
                        let showA,showB,showC,showD
                        for(var i=0;i<record.result.length;i++){
                            if(record.result[i]=='A'){
                                showA=true
                            }
                            if(record.result[i]=='B'){
                                showB=true
                            }
                            if(record.result[i]=='C'){
                                showC=true
                            }
                            if(record.result[i]=='D'){
                                showD=true
                            }
                        }
                        return <div>
                        <div className={showA==true?'show':'none'}>{record.optionA}</div>
                        <div className={showB==true?'show':'none'}>{record.optionB}</div>
                        <div className={showC==true?'show':'none'}>{record.optionC}</div>
                        <div className={showD==true?'show':'none'}>{record.optionD}</div>
                        </div>
                    }
                    else if(record.questionType==3){
                        return <span>{record.fillResult}</span>
                    }
                },
            }
        ]; 
        return (
            <Form>
            <Table
                bordered
                columns={columns}
                dataSource={this.props.detail}
                rowKey={(record,index)=> index}
                pagination={ false }
             /> 
            </Form>  
        );
    }
}
WeekProForm = Form.create({})(WeekProForm);
/**
 * @description 组件-新增弹框
 * @class AddForm
 * @extends {React.Component}
 */
class AddForm extends React.Component{
    //重置
    resetValue = ()=>{
        this.props.form.resetFields()
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        return (
            <Form layout="horizontal">
                <FormItem label="专项名称" {...formItemLayout}>
                    {
                        getFieldDecorator('name',{
                            initialValue:'',rules: [{ required: true, message: '请输入名称'},]
                        })(
                            <Input type="text"/>
                        )
                    }
                </FormItem>
            </Form>
        );
    }
}
AddForm = Form.create({})(AddForm);