import React, { Component } from 'react'
import {Tabs} from 'antd'
import Identity from './Identity'
import Doctor from './Doctor'
const TabPane = Tabs.TabPane
//首页-待认证用户
export default class Check extends Component {
    callback = (e)=>{
        console.log(e)
    }
    render() {
        
        return (
            <div className="home">
                <Tabs defaultActiveKey="1" onChange={this.callback}>
                    <TabPane tab="身份" key="1">
                        <Identity />
                    </TabPane>
                    <TabPane tab="医生" key="2">
                        <Doctor />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
