/**
 * @file 页面：总订单列表
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Form, DatePicker, Input, Select, Button, Table, message } from 'antd'
import {getAgentOrderListData} from '../../Api'
import { statusMessage, pagination, parseDate2 } from '../Training/Questions/features'
import utils from '../../Common/js/utils'

const {Option} = Select, parseDate = parseDate2,
/**
 * 处理当前数据行表格列数据的并最终返回渲染值的函数
 * @typedef {Function} tableColumnRenderFunction
 * @param {String} text 当前数据行对应的该表格列的值
 * @param {Object} record 当前数据行
 * @returns {Number | String | HTMLDocument | HTMLCollection}
 */
/**
 * 表格列
 * @typedef {Object} tableColumn
 * @property {String} title 表头标题
 * @property {String} dataIndex 表头取的字段名称
 * @property {String} key 表头的key值 
 * @property {tableColumnRenderFunction} render 
 */
/**
 * 右边的表格列数据
 * @const {Array.<tableColumn>} columns
 */
columns = [
    {
        title: '订单号',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: '企业名称',
        dataIndex: 'companyName',
        key: 'company'
    },
    {
        title: '代理商',
        dataIndex: 'agentName',
        key: 'agent'
    },
    {
        title: '产品',
        dataIndex: 'softName',
        key: 'product'
    },
    {
        title: '套餐',
        dataIndex: 'comboName',
        key: 'suit'
    },
    {
        title: '购买年限',
        dataIndex: 'payCount',
        key: 'expiration'
    },
    {
        title: '付款金额',
        dataIndex: 'payCost',
        key: 'price'
    },
    {
        title: '付款状态',
        dataIndex: 'payStatus',
        key: 'status'
    },
    {
        title: '付款时间',
        dataIndex: 'payDate',
        key: 'time'
    }
]

/**
 * @class
 * @extends React.Component
 */
class OrderListForm extends React.Component {
    /**
     * 组件状态值
     * @type {Object}
     */
    state = {
        pageNum:1,
        pageSize:30,
        searchParams: {}
    }
    /**
     * 组件载入函数
     * @type {Function}
     */
    componentDidMount() {
        this.requestTableList()
    }

    /**
     * 请求表格数据后将有关数据存入state
     * @type {Function}
     * @param {Object} res 请求的结果
     * @param {Number} pageNum 页码
     * @param {Number} pageSize 每页记录数
     */
    postRequest = (res, pageNum, pageSize) => {
        if (res.status === 200 && res.data.state.value == 0){
            utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
            this.setState({
                total: Number(res.data.result.records),
                pageNum,
                pageSize,
                agentOrderListData: res.data.result.rows
            })
        }
    }

    /**
     * 请求表格数据
     * @type {Function}
     * @param {Number} pageNum 页码
     * @param {Number} pageSize 每页记录数
     * @param {Object} finalValue 搜索的条件
     */
    requestTableList = (pageNum, pageSize, finalValue)=>{
        const action = finalValue ? 'search' : 'get'
        pageNum = pageNum ? pageNum : this.state.pageNum
        pageSize = pageSize ? pageSize : this.state.pageSize
        finalValue = finalValue ? finalValue : this.state.searchParams
        getAgentOrderListData({ pageNum, pageSize, ...finalValue }).then((res)=>{
            console.log(res, action)
            this.setState({searchParams: finalValue})
            this.postRequest(res, pageNum, pageSize)
        }, reason => {
            statusMessage(reason)
        })
    }

    /**
     * 搜索
     * @type {Function}
     * @param {Object} params 搜索的条件
     */
    searchTableList = (params)=>{
        let finalValue = {...params}
        if (finalValue.beginTime) {
            finalValue.beginTime = parseDate(finalValue.beginTime)+' 00:00'
            finalValue.endTime = parseDate(finalValue.beginTime)+' 23:59'
        }
        this.requestTableList(1, null, finalValue)
    }

    /**
     * 回车事件
     * @type {Function}
     * @param {Event} e 事件
     */
    handleKey = e => {
        if (e.keyCode == 13) {
            this.handleSubmit(e)
        }
    }
    
    /**
     * 提交事件
     * @type {Function}
     * @param {Event} e 事件
     */
    handleSubmit = e => this.searchTableList(this.props.form.getFieldsValue())

    /**
     * 重设表格数值为初始
     * @type {Function}
     */
    resetFields = () => this.props.form.resetFields()

    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div id="agentList">
            <Form layout="inline" onKeyDown={this.handleKey}>
                <Form.Item label={'代理商：'}>
                    {getFieldDecorator(`keyword`)(<Input placeholder="请输入代理商" />)}
                </Form.Item>
                <Form.Item label={'付款状态：'}>
                    {getFieldDecorator(`payStatus`)(
                    <Select placeholder="请选择">
                        <Option value={0}>未付款</Option>
                        <Option value={1}>已付款</Option>
                    </Select>
                    )}
                </Form.Item>
                <Form.Item label={'付款时间：'}>
                    {getFieldDecorator(`beginTime`)(
                    <DatePicker />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={this.handleSubmit}>搜索</Button>
                </Form.Item>
                <Form.Item>
                    <Button onClick={this.resetFields}>重置</Button>
                </Form.Item>
            </Form>
            <Table className="mt20" dataSource={this.state.agentOrderListData} columns={columns} pagination={pagination(this.state.pageSize, this.state.pageNum, this.state.total, (page, size) => {this.requestTableList(page, size)})} bordered/>
            </div>
        )
    }
}

/**
 * 表格生成
 * @const {Form} OrderList
 */
const OrderList = Form.create()(OrderListForm)

export default OrderList