import React, { Component } from 'react'
import {Button,Modal} from 'antd'
import {getSoftBuy,getSoftHealthConsut} from './../../../../Api/index'
import SoftwareOne from './../Common/SoftwareOne'//开通职业健康软件
import SoftwareTwo from './../Common/SoftwareTwo'//开通职业健康集团版软件
import SoftwareThree from './../Common/SoftwareThree'//开通职业健康集团版软件



class OpenSoftware extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        visible: false,
        visible2:false,
        visible3:false,
        visible4:false,
        keys:this.props.keys
    };
    //开通软件弹出显示状态
    showModalSoft = () => {
        console.log(this.props.keys,"keys");

        if(this.props.keys == 2){
            this.setState({
                visible: true,
            });
        }else if(this.props.keys == 3){
            this.setState({
                visible2: true,
            });
        }else if(this.props.keys == 4){
            this.setState({
                visible4: true,
            });
        }else if(this.props.keys == 5){
            this.setState({
                visible3: true,
            });
        }else{
            this.setState({
                visible: false,
                visible2: false,
                visible3: false,
            });
        }
    };

    //开通职业健康软件弹出框确定按钮操作
    softwareOk = () => {
        this.setState({ loading: true });
        let formdata = this.formRef.getItemsValue();
        if(
            formdata.comboId !== '' && formdata.comboId !== undefined && 
            formdata.payCount !== '' && formdata.payCount !== undefined && 
            formdata.comboSpaceCount !== '' && formdata.comboSpaceCount !== undefined && 
            formdata.type !== '' && formdata.type !== undefined 
        ){
            this.setState({ loading: false, visible: false });
            this.getBuyRequest(formdata);  
        this.formRef.resetValue(); 
        }else{
            this.setState({ loading: true, visible: true });
        }
        
    };
    //开通职业健康软件弹出框取消按钮操作
    softwareCancel = () => {
        this.setState({ visible: false });
        this.formRef.resetValue(); 
    };
    //开通职业健康软件  请求接口
    getBuyRequest = (data) => {
        getSoftBuy({
            cid:this.state.cid,
            softId:1,
            comboId:data.comboId,
            payCount:data.payCount,
            comboSpaceCount:data.comboSpaceCount,
            type:data.type,
            promocode:data.promocode,
            payCost:data.payCost,
            tradeNo:data.tradeNo,
            remark:data.remark
        }).then((res)=>{
            this.props.requestBasic(1);
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //开通职业健康集团版软件弹出框确定按钮操作
    softwareOk2 = () => {
        this.setState({ loading: true });
        let formdata = this.formRef.getItemsValue();
        if(
            formdata.comboId !== '' && formdata.comboId !== undefined && 
            formdata.payCount !== '' && formdata.payCount !== undefined && 
            formdata.comboUserCount !== '' && formdata.comboUserCount !== undefined && 
            formdata.type !== '' && formdata.type !== undefined 
        ){
            this.setState({ loading: false, visible2: false });
            this.getBuyRequest2(formdata);  
            this.formRef.resetValue(); 
        }else{
            this.setState({ loading: true, visible2: true });
        }  
    };
    //开通职业健康集团版软件弹出框取消按钮操作
    softwareCancel2 = () => {
        this.setState({ visible2: false });
        this.formRef.resetValue(); 
    };
    getBuyRequest2 = (data) => {
        //console.log(this.state.cid);
        getSoftBuy({
            cid:this.state.cid,
            softId:4,
            comboId:data.comboId,
            payCount:data.payCount,
            comboUserCount:data.comboUserCount,
            type:data.type,
            promocode:data.promocode,
            payCost:data.payCost,
            tradeNo:data.tradeNo,
            remark:data.remark
        }).then((res)=>{
            this.props.requestBasic(4);
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //开通健康顾问软件弹出框确定按钮操作
    softwareOk3 = () => {
        this.setState({ loading: true });
        this.setState({ loading: false, visible3: false });
        this.getBuyRequest3();
    };
    //开通健康顾问软件弹出框取消按钮操作
    softwareCancel3 = () => {
        this.setState({ visible3: false });
    };
    getBuyRequest3 = () => {
        getSoftHealthConsut({
            cid:this.state.cid,
        }).then((res)=>{
            this.props.requestBasic(3);
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    softwareCancel4=()=>{
        this.setState({ visible4: false });
    }
    //开通健康顾问软件弹出框确定按钮操作
    softwareOk4 = () => {
        this.setState({ loading: true });
        let formdata = this.formRef.getItemsValue();
        if(
            formdata.comboId !== '' && 
            formdata.comboId !== undefined && 
            formdata.payCount !== '' && 
            formdata.payCount !== undefined && 
            formdata.comboUserCount !== '' && 
            formdata.comboUserCount !== undefined && 
            formdata.comboSpaceCount !== '' && 
            formdata.comboSpaceCount !== undefined && 
            formdata.type !== '' && 
            formdata.type !== undefined 
        ){
            this.setState({ loading: false, visible4: false });
        this.getBuyRequest4(formdata); 
            this.formRef.resetValue(); 
        }else{
            this.setState({ loading: true, visible4: true });
        }  
    };
    getBuyRequest4 = (data) => {
        //console.log(this.state.cid);

        getSoftBuy({
            cid:this.state.cid,
            softId:2, //软件ID
            comboId:data.comboId,  //套餐
            payCount:data.payCount,  //购买年限
            comboUserCount:data.comboUserCount, //套餐用户数
            comboSpaceCount:data.comboSpaceCount,//套餐空间数
            type:data.type, //付费类型/操作类型(
            promocode:data.promocode, //推广码
            payCost:data.payCost, //合计费用
            tradeNo:data.tradeNo,  //交易号
            remark:data.remark  //备注
        }).then((res)=>{
            this.props.requestBasic(2);
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    render() {
        //console.log(this.props)
        return (
            <div className="text-align-center">
               <h2 className="mtb20-auto">该用户尚未开通此软件服务,是否开通？</h2>

               <Button type="primary" size="large" onClick={this.showModalSoft}>开 通</Button>

               {/* 开通职业健康软件弹出 */}
               <Modal
                width={620}
                visible={this.state.visible}
                loading={this.state.loading}
                title="开通软件"
                onOk={this.softwareOk}
                onCancel={this.softwareCancel}
                okText="确定"
                cancelText="取消"
                >
                    <SoftwareOne
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 开通职业健康集团版软件弹出 */}
               <Modal
                width={620}
                visible={this.state.visible2}
                loading={this.state.loading}
                title="开通软件"
                onOk={this.softwareOk2}
                onCancel={this.softwareCancel2}
                okText="确定"
                cancelText="取消"
                >
                    <SoftwareTwo
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                  {/* 开通职业健康集团版软件弹出 */}
               <Modal
               width={620}
               visible={this.state.visible2}
               loading={this.state.loading}
               title="开通软件"
               onOk={this.softwareOk2}
               onCancel={this.softwareCancel2}
               okText="确定"
               cancelText="取消"
               >
                   <SoftwareTwo
                   wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                   />
               </Modal>
               {/* 开通放射卫生&辐射安全管理系统 */}
               <Modal
               width={620}
               visible={this.state.visible4}
               loading={this.state.loading}
               title="开通软件"
               onOk={this.softwareOk4}
               onCancel={this.softwareCancel4}
               okText="确定"
               cancelText="取消"
               >
                   <SoftwareThree
                   wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                   />
               </Modal>

                <Modal
                width={400}
                visible={this.state.visible3}
                loading={this.state.loading}
                title="开通软件"
                onOk={this.softwareOk3}
                onCancel={this.softwareCancel3}
                okText="确定"
                cancelText="取消"
                >
                    确定开通健康顾问？
                </Modal>
            </div>
        )
    }
}

export default OpenSoftware;