/**
 * @description 专项答题编辑
 * @author yzx yangzhixin177@163.com
 */
import React, { Component } from 'react'
import { Tabs, Radio,Card,Icon,Table,Modal,Form,message,Button,Input,Select} from 'antd';
import {Link} from 'react-router-dom'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import SocialProForm from './components/BaseForm'
import update from 'immutability-helper';
import {queryExamQuestionList,reCreateExam,queryQuestionList,updateExam,updateExamQuestionList,queryExam,upExam,saveExamOptionIndex} from '../../../Api'
import './../../../Common/css/path.css'
const Option = Select.Option;
const type = 'DragbleBodyRow';
/**
 * @description 拖拽表格函数
 * @function DragableBodyRow
 * @param {*} { index, moveRow, className, style, ...restProps }
 * @returns
 */
const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};
/**
 * @description 组件-专项答题编辑
 * @class CategorymanageEdit
 * @extends {Component}
 */
class CategorymanageEdit extends Component {
     /**
     * @description 组件内数据
     * @param {Object} data 选择试题完成后-试题列表
     * @param {Object} detail 试卷题目详情
     * @param {Object} problemData 题库列表
     * @param {String} inpValue 输入框内容
     * @param {String} transId 试卷ID
     * @param {String} selectValue 选择框内容
     * @param {Boolean} isShow true：显示保存，取消按钮 false：隐藏
     * @param {Boolean} disabled true：普通文本框 false：input框
     * @param {Object} params 页码，每页大小
     * @memberof CategorymanageEdit
     */
    state={
        data:[],
        detail:[],
        problemData:[],
        inpValue:'',
        transId:'',
        selectValue:'',
        isShow:false,
        disabled: true,
        params:{
          pageNum:1,
          pageSize:100
      }
    }
    /**
     * @description 清空表格选中项
     * @function resetCheckedList
     * @memberof CategorymanageEdit
     */
    resetCheckedList = ()=>{
      this.setState({
          selectedRowKeys:[],
          selectedRows:null,
          selectedIds:[],
          selectItem:{},
      })
  }
    /**
     * @description 选中表中数据后，保存到state
     * @function rowCheckSelection
     * @memberof CategorymanageEdit
     */
    rowCheckSelection = () => {
      return {
          selectedRowKeys: this.state.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
              let ids = []
              selectedRows.map((item) => {
                  ids.push(item.id)//注意之后为id
              })
              this.setState({selectedRowKeys, selectedIds: ids, selectedRows})
          }
      }
    }
    /**
     * @description 选择试题
     * @function selectPro
     * @memberof CategorymanageEdit
     */
    selectPro =()=>{
      let _this=this
      queryQuestionList({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize,status:2}).then((res)=>{
          this.setState({
            problemData:res.data.result.rows
          })
      })
      this.setState({
          title:'选择试题',
          isProblemDataVisible:true
      })
    }
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof CategorymanageEdit
     */
    renderModalSimple = (type,title,content) =>{
      return Modal[type?type:'info']({ 
          title:title?title:'警告',
          content:content?content:''
      })
  }
    /**
     * @description 保存试题，请求接口，保存成功后刷新数据
     * @function handleSave
     * @memberof CategorymanageEdit
     */
    handleSave = ()=>{
      const transId=this.state.transId
      let _this = this   
      // console.log(this.state.selectedRows)
      if(this.state.selectedIds.length <10){
          this.renderModalSimple('warning','提醒','必须选择10道题目！')
          return
      }
      if(this.state.selectedIds.length >10){
        this.renderModalSimple('warning','提醒','不能选择超过10道题目！')
        return
    }
      let idStr = ''
      this.state.selectedIds.map(item=>{
          idStr += item + ','
      })
      let list=idStr.substring(0,idStr.lastIndexOf(',')); 
      updateExamQuestionList({examId:transId,'questionIdList[]':list}).then((res)=>{
        // console.log(res.data._data)
        if(res.data.state.value == 0&&!res.data.result){
          this.reflash()
          message.success('操作成功')
      }else{
        this.renderModalSimple('warning','提醒','操作失败!')
      }
    })
        this.setState({
          data:this.state.selectedRows,
          isProblemDataVisible:false
        })
        this.resetCheckedList()
  }

    /**
     * @description 保存专项试题名称,请求保存名称的接口
     * @function handleSubmit
     * @memberof CategorymanageEdit
     */
    handleSubmit = () => {
      const transId=this.state.transId
      const inputName=this.state.inpValue
      if(inputName==''){
        this.renderModalSimple('warning','提醒','专项名称为空或未作修改')
          return
      }
      updateExam({id:transId,name:inputName}).then((res)=>{
        if(res.data.state.value == 0&&!res.data.result){
          this.setState({
              isShow:false,
              disabled:true,
              name:inputName
          })
          message.success('保存成功');
      }else{
        this.renderModalSimple('warning','提醒','保存失败')
      }
    })
  };
    /**
     * @description 自动生成试题,请求自动生成试题接口
     * @function autoCreate
     * @memberof CategorymanageEdit
     */
    autoCreate = ()=>{
      let selectValue=this.state.selectValue
      let transId=this.state.transId
      // console.log(transId)
      reCreateExam({examId:transId,type:selectValue}).then((res)=>{
          if(res.data.state.value == 0&&!res.data.result){
              this.reflash()
              message.success('操作成功')
        }else{
          this.renderModalSimple('warning','提醒','操作失败!')
        }
      })
  }
    /**
     * @description 刷新页面显示的试题（请求成功后）
     * @function reflash
     * @memberof CategorymanageEdit
     */
    reflash=()=>{
      let transId=this.state.transId
      queryExamQuestionList({examId:transId}).then((res)=>{
        // console.log(res.data)
        this.setState({
            data:res.data.result
        })
    })
    }

    /**
     * @description react周期函数，请求试题列表接口
     * @function componentDidMount
     * @memberof CategorymanageEdit
     */
    componentDidMount() {
      const id = this.props.location.pathname ? this.props.location.pathname.split('/').reverse()[0] : 'edit'
      // console.log(id)
      // console.log(this.props.location.state.name)
        if (id !== 'edit') {
          queryExamQuestionList({examId:id}).then((res)=>{
            // console.log(res.data)
            this.setState({
                data:res.data.result,
                transId:id
            })
        })
        }
    }
    componentWillMount(){
      const id = this.props.location.pathname ? this.props.location.pathname.split('/').reverse()[0] : 'edit'
      // console.log(id)
      // console.log(this.props.location.state.name)
        if (id !== 'edit') {
          queryExam({examId:id}).then((res)=>{
            // console.log(res.data.result.name)
            this.setState({
                detail:res.data.result,
                name:res.data.result.name
            })
        })
        }
    }
      components = {
          body: {
            row: DragableBodyRow,
          },
        };
      /**
       * @description 日期转换
       * @function formatDate
       * @memberof CategorymanageEdit
       * @param {string} now 时间戳
       * @return {string} year + "-" + month + "-" + date 格式转化后的时间
       */
      formatDate = (now) => {
        var nowDate = new Date(now)
        var year = nowDate.getFullYear();  //取得4位数的年份
        var month = nowDate.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
        var date = nowDate.getDate();      //返回日期月份中的天数（1到31）
        return year + "-" + month + "-" + date;
    }
      /**
       * @description 表格行移动
       * @function moveRow
       * @param {string} dragIndex 移动前行的下标
       * @param {string} hoverIndex 移动后行的下标
       * @memberof CategorymanageEdit
       */
      moveRow = (dragIndex, hoverIndex) => {
          const { data } = this.state;
          const dragRow = data[dragIndex];
          this.setState(
            update(this.state, {
              data: {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, dragRow],
                ],
              },
            }),
          );
        };

      /**
       * @description 点击取消后处理事件
       * @function handleCancel
       * @memberof CategorymanageEdit
       */
      handleCancel=()=>{
        this.setState({
          isProblemDataVisible:false
        })
      }
      /**
       * @description 监控输入框输入事件
       * @function handleChange
       * @param {*} e event事件
       * @memberof CategorymanageEdit
       */
      handleChange(e){
        // console.log(e.target)
        this.setState({
          inpValue:e.target.value
        })
      }
      /**
       * @description 选择框的值变化监控
       * @function selectOnChange
       * @param {*} value
       * @memberof CategorymanageEdit
       */
      selectOnChange(value) {
      this.setState({
        selectValue:value
      })
    }
      /**
       * @description 点击"编辑"按钮后事件
       * @function handelEdit
       * @memberof CategorymanageEdit
       */
      handelEdit() {
        this.setState({
          isShow:true,
          disabled:false
        })
      }
      /**
       * @description 点击取消事件
       * @function handelEditCancel
       * @memberof CategorymanageEdit
       */
      handelEditCancel() {
        this.setState({
          isShow:false,
          disabled:true
        })
      }
      /**
       * @description 试题上架
       * @function handleUp
       * @param {Object} record 当前行对象
       * @memberof Categorymanage
       */
      handleUp=(examId)=>{
        let _this = this
        Modal.confirm({
            title: "上架提示",
            content: '是否确认上架',
            okText: '确定',
            okType: 'primary',
            cancelText: '取消',
            onOk() {
                upExam({examId:examId}).then(res=>{
                    if(res.data.state.value==0){
                        message.success("上架成功！")
                        _this.props.history.push({ pathname: `/training/category`,state:{homeIndex:'2'}})
                    }else{
                        message.error(res.data.content)
                    }
                })
            }
        })
    }
      /**
       * @description 保存排序顺序
       * @function saveSequence
       * @memberof CategorymanageEdit
       */
      saveSequence=()=>{
        const SequenceList=this.state.data;
        let transId=this.state.transId;
        let SequenceIds = ''
        SequenceList.map(item=>{
          SequenceIds += item.examQuestionId + ','
        })
        let Sequence=SequenceIds.substring(0,SequenceIds.lastIndexOf(',')); 
        saveExamOptionIndex({examId:transId,'examQuestionIdList[]':Sequence}).then((res)=>{
          // console.log(res.data._data)
          if(res.data.state.value == 0&&!res.data.result){
            this.reflash()
            message.success('操作成功')
        }else{
          this.renderModalSimple('warning','提醒','操作失败!')
        }
      })
    }
      render() {
        const isShow=this.state.isShow
        // console.log(isShow)
        const _this=this
        const len=this.state.selectedIds?this.state.selectedIds.length:0
        const name=this.state.name
        const disabled=this.state.disabled
        let time=_this.state.detail.shelvesTime?_this.state.detail.shelvesTime:''
        // console.log(name)
        const columns = [
            {
                title: '序号',
                key:'key',
                dataIndex: 'key',
                render:(text,record,index)=>`${index+1}`
            }, {
                title: '试题类型',
                key: 'questionType',
                dataIndex: 'questionType',
                render(text) {
                  if(text==1){
                      return '单选题'
                  }
                  if(text==2){
                      return '多选题'
                  }
                  else{
                      return '填空题'
                  }
              },
            },{
                title: '题干',
                key: 'stem',
                dataIndex: 'stem',
            },{
                title: '答案',
                key: 'result',
                dataIndex: 'result',
                render(text,record) {
                  if(record.questionType==1){
                      return <div>
                      <div className={record.result=='A'?'show':'none'}>{record.optionA}</div>
                      <div className={record.result=='B'?'show':'none'}>{record.optionB}</div>
                      <div className={record.result=='C'?'show':'none'}>{record.optionC}</div>
                      <div className={record.result=='D'?'show':'none'}>{record.optionD}</div>
                      </div>
                  }
                  if(record.questionType==2){
                      let showA,showB,showC,showD
                      for(var i=0;i<record.result.length;i++){
                          if(record.result[i]=='A'){
                              showA=true
                          }
                          if(record.result[i]=='B'){
                              showB=true
                          }
                          if(record.result[i]=='C'){
                              showC=true
                          }
                          if(record.result[i]=='D'){
                              showD=true
                          }
                      }
                      return <div>
                      <div className={showA==true?'show':'none'}>{record.optionA}</div>
                      <div className={showB==true?'show':'none'}>{record.optionB}</div>
                      <div className={showC==true?'show':'none'}>{record.optionC}</div>
                      <div className={showD==true?'show':'none'}>{record.optionD}</div>
                      </div>
                  }
                  else if(record.questionType==3){
                      return <span>{record.fillResult}</span>
                  }
              },
            }
        ]; 
        return (
          <Card>
              <div className='weekTitle'>
                <div className='weekTitle1'><span style={{color:'red'}}>*</span>专项名称</div>
                <div className='weekTitle2'>
                {disabled==true?<span>{name}</span>:<Input className='inputSpecial' onChange={this.handleChange.bind(this)} defaultValue={name}></Input>}
                <Button className={isShow==true?'unshow':'show'} style={{float:"right",width:"10%"}} type='primary' onClick={()=>this.handelEdit()}>编辑</Button></div>
                </div>
                <div>
                <div className={isShow==true?'show':'unshow'} style={{textAlign:"center",marginBottom:15}}>
                            <Button type='primary' style={{marginRight:6}} onClick={()=>this.handleSubmit()}>
                              保存
                               </Button>
                            <Button onClick={()=>this.handelEditCancel()}>
                              取消
                              </Button>
                              </div>
             <table className='seeTable'>
                 <tbody>
                    <tr>
                        <td>编制人</td>
                        <td style={{textAlign:"left"}}>{_this.state.detail.createByStr}</td>
                        <td>创建时间</td>
                        <td style={{textAlign:"left"}}>{_this.formatDate(_this.state.detail.createDate)}</td>
                    </tr>
                    <tr>
                        <td>答题人数</td>
                        <td style={{textAlign:"left"}}>{_this.state.detail.totalUser}</td>
                        <td>上架时间</td>
                        <td style={{textAlign:"left"}}>{time==''?'':_this.formatDate(time)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
              <div style={{ marginBottom: 16 }}>
                    <div>
                    <Select onChange={this.selectOnChange.bind(this)}
                        style={{width:123,float:"left"}}
                        placeholder="职业卫生综合" defaultValue="职业卫生综合">
                        <Option value="1">职业卫生综合</Option>
                        <Option value="2">职业危害防护</Option>
                        <Option value="3">职业健康监护</Option>
                        <Option value="4">职业危害因素</Option>
                        <Option value="5">职业卫生三同时</Option>
                        <Option value="6">职业病</Option>
                        <Option value="7">放射卫生</Option>
                        </Select>
                            <Button style={{ marginLeft: 10 ,float:"left",backgroundColor: '#52C41A',borderColor:'#52C41A',color:'#fff'}} onClick={()=>this.autoCreate()}>自动生成</Button>
                            <Button type="primary" style={{ marginLeft: 10}} onClick={()=>this.selectPro()}>
                                    选择试题
                            </Button>
                            <Button style={{ marginLeft: 10,backgroundColor: '#808080',borderColor:'#808080',color:'#fff'}} onClick={()=>this.saveSequence()}>保存顺序</Button>
                            <Button type="primary" style={{ marginLeft: 10,float:"right"}} onClick={()=>this.handleUp(_this.state.detail.id)}>
                                    上架
                            </Button>
                    </div>
                    <div className="clear"></div>
                </div>
              <DndProvider backend={HTML5Backend}>
                <Table className='move'
                        bordered
                        columns={columns}
                        components={this.components}
                        dataSource={this.state.data}
                        onRow={(record, index) => ({
                            index,
                            moveRow: this.moveRow,
                          })
                        }
                        rowKey={(record,index)=> index}
                        pagination={false}
                    /> 
                </DndProvider>
                <Modal className='ProblemData'
                    title={this.state.title}
                    visible={this.state.isProblemDataVisible}      
                    onOk={()=>{this.handleSave()}}
                    onCancel={()=>{
                        this.setState({
                          isProblemDataVisible:false
                        })
                    }}
                    footer={[
                      <span key='num' style={{marginRight:20}}>已选{len}题</span>,
                      <Button key="back" onClick={()=>this.handleCancel()}>
                        取消
                      </Button>,
                      <Button key="submit" type="primary" onClick={()=>this.handleSave()}>
                        保存
                      </Button>,
                    ]}
                >
                     <SocialProForm resetCheckedList={this.resetCheckedList} rowCheckSelection={this.rowCheckSelection} problemData={this.state.problemData} wrappedComponentRef={(inst) => this.SocialProForm = inst }/>
                </Modal>
          </Card>
        );
      }
} 
export default CategorymanageEdit