/**
 * @file 页面：活动设置->发布公告
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import Editor from './components/Editor'
import { Form, Upload, message, Button, Input, Icon } from 'antd'
import Preview from './components/Preview'
import { statusMessage } from '../Training/Questions/features'
import { saveSysNotice, saveHaNotice } from '../../Api'

/**
 * @typedef {Object} UploadProperty
 * @property {String} action 上传接口地址
 * @property {Object} headers 上传头部数据
 * @property {Function} onChange 上传状态变化时进行的函数
 * 
 */
/** 
 * 上传组件配置
 * @const {UploadProperty} props
 */
const props = {
    action: '/v1/ajax/file/upload',
    multiple: true,
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        console.log(info.file, info.fileList);
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} 上传成功。`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} 上传失败。`);
        }
    },
};

/**
 * @class
 * @extends React.Component
 */
class ActivityManagementAddForm extends React.Component {

    /**
     * 页面载入执行的函数
     */
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    /**
     * 提交后事件
     * @type {Function}
     * @param {Event} e 提交按钮事件
     */
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                const finalValues = {}
                for (let key of Object.keys(values)) {
                    console.log(key)
                    if (key == 'attachment') {
                        finalValues['fileIds'] = values[key] ? values[key].fileList.map(item => item.response.result.id).join(',') : ''
                    } else {
                        finalValues[key] = values[key]
                    }
                }
                const saveNotice = this.props.location.state && this.props.location.state.tableId == 1 ? saveHaNotice : saveSysNotice
                saveNotice(finalValues).then(res => {
                    console.log(res)
                    if(res.status === 200 && res.data.state.value == 0) {
                        this.props.history.push("/activity/management", {tableId: this.props.location.state && this.props.location.state.tableId == 1 ? '1' : '0', action: 'add'} )
                    }
                }, reason => {
                    statusMessage(reason)
                })
            }
        });
    }

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator } = this.props.form,
            formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 },
                },
            }

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="公告标题">
                    {getFieldDecorator('title', {
                        rules: [
                            {
                                required: true,
                                message: '请输入公告标题！',
                            },
                        ]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="附件">
                    {getFieldDecorator('attachment', {valuePropName: 'file'})(<Upload {...props}>
                        <Button>
                            <Icon type="upload" /> 上传附件
                    </Button>
                    </Upload>)}
                </Form.Item>
                <Form.Item label="内容">
                    {getFieldDecorator('content', {
                        rules: [
                            {
                                required: true,
                                message: '请输入内容！',
                            },
                        ]
                    })(<Editor />)}
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" className="mr10">
                        确定
                    </Button>
                    <Preview buttonText="预览"
                        content={this.props.form.getFieldValue('content')}
                        title={this.props.form.getFieldValue('title')}
                        date={new Date()}
                        attachment={this.props.form.getFieldValue('attachment')} />
                </Form.Item>
            </Form>

        )
    }
}

/**
 * 创建表单
 * @const {Form} ActivityManagementAdd
 */
const ActivityManagementAdd = Form.create()(ActivityManagementAddForm)
export default ActivityManagementAdd