/**
 * @file 组件：题库导入-表格
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Form, Upload, Button, Icon, message } from 'antd'

/**
 * @typedef {Object} UploadProperty
 * @property {String} action 上传接口地址
 * @property {Object} headers 上传头部数据
 * @property {Function} onChange 上传状态变化时进行的函数
 * 
 * 上传组件配置
 * 
 * @const {UploadProperty} props
 */

const props = {
    action: '/v1/ajax/file/upload',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} 上传成功。`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} 上传失败。`);
        }
    },
};

/**
 * 题库导入-表格
 * @class
 * @extends React.Component
 */
class ImportForm extends React.Component {

    /**
     * 上传提交检验
     * @type {Function}
     * @return {Promise}
     */
    onSubmit = () => this.props.form.validateFields()

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator } = this.props.form,
        formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }
        return (
            <Form {...formItemLayout} onSubmit={this.onSubmit}>
                <Form.Item label="下载模板">
                    <a href={this.props.importExample}>下载导入模板</a>
                </Form.Item>
                <Form.Item label="文件">
                {getFieldDecorator('file', {
                    rules: [
                    {
                        required: true,
                        message: '请选择文件！',
                    }
                    ], valuePropName: 'file'
                })(<Upload {...props}>
                    <Button>
                        <Icon type="upload" /> 选择文件
                    </Button>
                    </Upload>)}
                </Form.Item>
            </Form>
        )
        
    }
}

/**
 * 表单创建
 * @const {Form} Import
 */
const Import = Form.create()(ImportForm)

export default Import