/**
 * @description 行业管理
 * @author yzx yangzhixin177@163.com
 */
import React from 'react'
import axios from 'axios'
import { Table,Icon,Modal,message,Input, Card, Button,Form,Upload} from 'antd';
import './../../Common/css/path.css'
import utils from './../../Common/js/utils'
// import {dmanageSearch,dmanageAdd,dmanageEdit,dmanageDel} from './../../Mock'
import {getIndustryList,addIndustry,delIndustry,saveIndustry,sortIndustry,queryIndustryList,importIndustry,exportIndustry,TEST_URL} from '../../Api'
const FormItem = Form.Item;
const { TextArea } = Input;
/**
 * @description 组件-行业管理
 * @class IndustryManage
 * @extends {React.Component}
 */
class IndustryManage extends React.Component{
    /**
     * @param {Object} params 页码，每页大小
     * @param {Object} expandedRows 扩展行数据
     * @param {Object} IndustryOptions 行业数据
     * @param {Array} url 资源Url
     * @param {String} fileID 文件ID
     * @memberof DivisionManage
     */
    state={
        params:{
            pageNum:1,
            pageSize:10
        },
        expandedRows:[],
        IndustryOptions:[],
        url:[],
        fileID:0
    }
    /**
     * @description 父子组件传值
     * @function transferID
     * @param {Boolean} isRule 正则判断输入状况
     * @memberof IndustryManage
     */
    transferID(id) {
        this.setState({
            fileID:id
        });
      }
    /**
     * @description 点击行事件
     * @function onRowClick
     * @param {Object} record 当前行对象 
     * @param {Number} index 当前行下标
     * @memberof IndustryManage
     */
    onRowClick = (record,index)=>{
      let selectKey = [index];
      this.setState({
          selectedRowKeys:selectKey,
          selectedItem: record,
      })
  }  
    /**
     * @description 行业导出
     * @function exportExcel
     * @param {*} param 内容
     * @memberof IndustryManage
     */
    exportExcel(param){
        axios({
            method: "GET",
            url: TEST_URL+'/v1/admin/datamaintenance/industry/exportIndustry',
            params:param||{},
            responseType: 'blob'
            }).then(r => {
            let blob = new Blob([r.data], {type: 'application/vnd.ms-excel'});
            window.URL = window.URL || window.webkitURL;
            let href = URL.createObjectURL(blob);
            let downA = document.getElementById("down");
            downA.href =  href;//
            downA.download = '行业导出.xls';
            })
        }
    /**
     * @description react生命周期，在渲染前调用
     * @function componentWillMount
     * @memberof IndustryManage
     */
    componentWillMount =()=>{
        this.requestList()
        this.exportExcel()
    }
    /**
     * @description 异步加载级联数据
     * @function requestList
     * @param {string} itemValue 父级id
     * @memberof IndustryManage
     */
    requestList = (itemValue)=>{
    let pid = itemValue&&itemValue.id?itemValue.id:0
        // console.log(itemValue)
        let promise
        if(pid == 'none'){
            return
        }else{
            // console.log(pid)
            getIndustryList({pid:pid}).then(res=>{
                // console.log(res)
                promise =  new Promise((resolve)=>{
                    let induOptions = []
                    if(res.status === 200 && res.data.state.value === 0){
                            res.data.result&&res.data.result.length&&res.data.result.map(item=>{
                                if(item.level==0){
                                induOptions.push({
                                    id:item.id,
                                    key:item.id,
                                    pid:item.pid,
                                    code:item.code,
                                    level:item.level,
                                    name:item.name,
                                    description:item.description,
                                    updateDate:item.createDate,
                                    isLeaf:item.isLeaf,
                                    children:[]
                                })}
                                else if(item.level==1||item.level==2||item.level==3){
                                    induOptions.push({
                                        id:item.id,
                                        key:item.id,
                                        pid:item.pid,
                                        code:item.code,
                                        level:item.level,
                                        name:item.name,
                                        description:item.description,
                                        updateDate:item.createDate,
                                        isLeaf:item.isLeaf,
                                        children:itemValue
                                    })}
                                else if(item.level==4){
                                    induOptions.push({
                                        id:item.id,
                                        key:item.id,
                                        pid:item.pid,
                                        code:item.code,
                                        level:item.level,
                                        name:item.name,
                                        description:item.description,
                                        updateDate:item.createDate,
                                        isLeaf:item.isLeaf,
                                    })
                                }
                            })
                            if(induOptions.length){
                                this.restore(this.state.IndustryOptions,induOptions)
                            }
                            //console.log(induOptions)
                            resolve()
                    }
                })
            })
            return promise
        }
    }
    /**
     * @description 异步加载级联数据
     * @function requestList1
     * @param {string} value 关键字
     * @memberof IndustryManage
     */
    requestList1 = (value)=>{
        let data=[...this.state.IndustryOptions]
        // console.log(data)
        let pid = value
        let promise
        if(pid == 'none'){
            return
        }else{
            getIndustryList({pid:pid}).then(res=>{
            promise =  new Promise((resolve)=>{
                let induOptions = []
                if(res.status === 200 && res.data.state.value === 0){
                        res.data.result&&res.data.result.length&&res.data.result.map(item=>{
                            if(item.level==4){
                                induOptions.push({
                                    id:item.id,
                                    key:item.id,
                                    pid:item.pid,
                                    code:item.code,
                                    description:item.description,
                                    level:item.level,
                                    updateDate:item.createDate,
                                    name:item.name,
                                    isLeaf:item.isLeaf,
                                })
                            }else{
                            induOptions.push({
                                id:item.id,
                                key:item.id,
                                pid:item.pid,
                                code:item.code,
                                description:item.description,
                                level:item.level,
                                name:item.name,
                                updateDate:item.createDate,
                                isLeaf:item.isLeaf,
                                children:[]
                            })}
                        })
                        // console.log(induOptions)
                        if(induOptions.length){
                            this.restore1(data,induOptions)
                        }
                        resolve()
                }
            })

        })
        return promise
    }
     }
    /**
     * @description 异步加载级联数据
     * @function requestList2
     * @param {string} value 关键字
     * @memberof IndustryManage
     */
    requestList2 = (value)=>{
        let data=[...this.state.IndustryOptions]
        // console.log(data)
        let pid = value
        let promise
        if(pid == 'none'){
            return
        }else{
            getIndustryList({pid:pid}).then(res=>{
            // console.log(res.data.result)
            if (res.data.result.length==0) {
                // console.log(1)
                this.restore2(data,[])
            }else{
            promise =  new Promise((resolve)=>{
                let induOptions = []
                if(res.status === 200 && res.data.state.value === 0){
                        res.data.result&&res.data.result.length&&res.data.result.map(item=>{
                            if(item.level==4){
                                induOptions.push({
                                    id:item.id,
                                    key:item.id,
                                    pid:item.pid,
                                    level:item.level,
                                    code:item.code,
                                    description:item.description,
                                    updateDate:item.createDate,
                                    name:item.name,
                                    isLeaf:item.isLeaf,
                                })
                            }else{
                            induOptions.push({
                                id:item.id,
                                key:item.id,
                                pid:item.pid,
                                code:item.code,
                                description:item.description,
                                level:item.level,
                                updateDate:item.createDate,
                                name:item.name,
                                isLeaf:item.isLeaf,
                                children:[]
                            })}
                        })
                        // console.log(induOptions)
                        if(induOptions.length){
                            this.restore1(data,induOptions)
                        }
                        resolve()
                }
            })
        }
        })
        return promise
    }
     }
    handleOnExpand = (expanded, record) => {
        if(!expanded)  return  //如果是关闭就返回
        if(record.children && record.children.length > 0)  return  //如果已经有数据就返回
        this.requestList(record)    
    }
    /**
     * @description 递归插入级联数据（用于下级展开）
     * @function restore
     * @param {Object} orignalData 原始数据
     * @param {Object} listData 插入数据
     * @memberof IndustryManage
     */
    restore = (orignalData,listData)=>{
    if(orignalData.length){
        orignalData.map(item=>{
            if(item.children&&item.children.length){
                this.restore(item.children,listData)
            }else{
                if(item.id == listData[0].pid){
                    item.children = listData
                    return
                }
            }
        })
        this.setState({
            IndustryOptions:orignalData
        })
        return
    }else{
        this.setState({
            IndustryOptions:listData
        })
        return
    }
    }
    /**
     * @description 递归插入级联数据（用于编辑和新增）
     * @function restore
     * @param {Object} orignalData 原始数据
     * @param {Object} listData 插入数据
     * @memberof IndustryManage
     */
    restore1 = (orignalData,listData)=>{
        let flag=false
        if(orignalData.length){
            orignalData.map(item=>{
                if(item.id == listData[0].pid){
                    item.children = listData
                    flag=true
                    return
                }
                else if (flag==false){
                    this.restore1(item.children,listData)
                }
            })
            this.setState({
                IndustryOptions:orignalData
            })
            return
        }else{
            this.setState({
                IndustryOptions:listData
            })
            return
        }
    }
    /**
     * @description 递归插入级联数据（用于删除）
     * @function restore
     * @param {Object} orignalData 原始数据
     * @param {Object} listData 插入数据
     * @memberof IndustryManage
     */
    restore2 = (orignalData,listData)=>{
        if(orignalData.length){
            orignalData.map(item=>{
                if(item.children.length == 1){
                    item.children = []
                    return
                }
                else {
                    this.restore2(item.children,listData)
                }
            })
            this.setState({
                IndustryOptions:orignalData
            })
            return
        }else{
            this.setState({
                IndustryOptions:listData
            })
            return
        }
    }
   /**
     * @description 上移
     * @function shiftUp
     * @param {Object} record 当前行对象
     * @memberof IndustryManage
     */
    shiftUp=(record)=>{
        sortIndustry({code:record.code,upOrDwon:-1}).then((res)=>{
            if(res.data.state.value==0){
                this.requestList1(record.pid);
            }
        })
    }
     /**
     * @description 下移
     * @function shiftDown
     * @param {Object} record 当前行对象
     * @memberof IndustryManage
     */
    shiftDown=(record)=>{
        sortIndustry({code:record.code,upOrDwon:1}).then((res)=>{
            if(res.data.state.value==0){
                this.requestList1(record.pid);
            }
        })
    }
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof IndustryManage
     */
    renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({ 
            title:title?title:'警告',
            content:content?content:''
        })
    }
    /**
     * @description 点击事件-下载
     * @function handleDownLoad
     * @memberof IndustryManage
     */
    handleDownLoad=()=>{
        let fileId='97614662497005935'
        utils.downLoad(fileId).then(res=>{
            let url="http:"+res
            window.open(url)
        })
    }
    /**
     * @description 点击事件-删除
     * @function handleDelete
     * @param {Object} record 当前行对象
     * @memberof IndustryManage
     */
    handleDelete=(record)=>{
        const deleteId=record.pid
        if (record.level==3) {
            Modal.confirm({
                title:'删除提示',
                content: `确定删除该行业？`,
                onOk:()=>{
                    delIndustry({industryId:record.id}).then((res)=>{
                        // console.log(record.value)
                        if(res.data.state.value==0){
                            this.setState({
                                isVisible:false
                            })
                            this.requestList2(deleteId); 
                            message.success('删除成功');
                        }else{
                            this.renderModalSimple('warning','错误','删除失败，请重新删除!')
                        }
                    })
                }
            })
        }
        else{
            this.setState({
                title:"删除提示",
                isDeleteVisible:true,
                manageInfo:record
            })
        }
    }
    /**
     * @description 点击事件-新增
     * @function handleAdd
     * @param {Object} record 当前行对象
     * @memberof IndustryManage
     */
    handleAdd = (type,record)=>{
        // let rows = this.state.expandedRows;
        // rows.push(record.key);
        this.setState({
            title:"新增",
            isManageVisible:true,
            manageInfo:record,
            type
        })
    }
    /**
     * @description 点击事件-编辑
     * @function handleEdit
     * @param {string} 类型
     * @param {Object} record 当前行对象
     * @memberof IndustryManage
     */
    handleEdit=(type,record)=>{
    this.setState({
        title:'编辑',
        isManageVisible:true,
        manageInfo:record,
        type
    })
    }
    /**
     * @description 点击事件-导入
     * @function onImportExcel
     * @memberof IndustryManage
     */
    onImportExcel=()=>{
        this.setState({
            title:'导入',
            isImportVisible:true
        })
        }
    /**
     * @description 点击事件-提交
     * @function handleSubmit
     * @memberof IndustryManage
     */
    handleSubmit = ()=>{
        let data = this.ManageForm.props.form.getFieldsValue();
        let type = this.state.type;
        let manageInfo=this.state.manageInfo;
        this.ManageForm.props.form.validateFields((err)=>{
            if(!err){
        // console.log(manageInfo.id)
        if(type=='create'){
            addIndustry({code:data.code,pid:manageInfo.id,name:data.name,description:data.description}).then((res)=>{
            if(res.data.state.value==0){
                this.setState({
                    isManageVisible:false
                })
                message.success('新增成功')
                this.requestList1(manageInfo.id)
                this.ManageForm.resetValue()
            }else{
                this.renderModalSimple('warning','错误','新增失败') 
            }
        })}
        if(type=='edit'){
            saveIndustry({id:manageInfo.id,code:data.code,pid:manageInfo.pid,name:data.name,description:data.description}).then((res)=>{
                if(res.data.state.value==0){
                    this.setState({
                        isManageVisible:false
                    })
                    message.success('编辑成功')
                    this.requestList1(manageInfo.pid)
                    this.ManageForm.resetValue()
                }
                else{
                    this.renderModalSimple('warning','错误','编辑失败') 
                }
            })
        }
    }
    }).catch((e) => {})
}
    /**
     * @description 点击事件-删除（有下级）
     * @function handleDeleteSubmit
     * @memberof IndustryManage
     */
    handleDeleteSubmit=()=>{
        let manageInfo=this.state.manageInfo;
        this.DeleteForm.props.form.validateFields((err)=>{
            if(!err){
        delIndustry({industryId:manageInfo.id}).then((res)=>{
            if(res.data.state.value==0){
                this.setState({
                    isDeleteVisible:false
                })
                message.success('删除成功')
                this.requestList2(manageInfo.pid)
                this.DeleteForm.resetValue()
            }else{
                this.renderModalSimple('warning','错误','删除失败') 
            }
        })
    }
      })
    }
    /**
     * @description 导入行业数据
     * @function handleImportSubmit
     * @memberof IndustryManage
     */
    handleImportSubmit=()=>{
        let fileId=this.state.fileID
        importIndustry({fileId:fileId}).then((res)=>{
            if(res.status === 200){
                this.setState({
                    isImportVisible:false
                })
                if(res.data.result!=null && res.data.result.length > 0) {
                    var arr=[]
                    for (var i=0;i<res.data.result.length;i++){
                        arr.push(res.data.result[i])
                    }
                    message.success(arr)
                }else{
                    message.success(res.data.content)
                }
                this.ImportForm.resetValue()
                this.requestList()
            }else{
                this.renderModalSimple('warning','错误','导入失败') 
            }
        })
    }
    /**
     * @description 行业搜索
     * @function handleSearch
     * @memberof IndustryManage
     */
    handleSearch = () => {
        let _this=this
        let formData = this.searchRef.handleSearch()
        queryIndustryList({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize,keyword:formData.keyword}).then((res)=>{
          if(res.status === 200){
              this.setState({
                  total: res.data.result.total,
                  IndustryOptions: res.data.result.rows,
                  page: res.data.result.page,
                  pagination: utils.pagination(res.data,(current,size)=>{
                      _this.state.params.pageNum=current
                      _this.state.params.pageSize=size
                      this.handleSearch()
                  }, (current,size)=>{
                      _this.state.params.pageNum=current
                      _this.state.params.pageSize=size
                      this.handleSearch()
                  }, res.data.result.pageSize)
              })
          }
      }).catch((err)=>{
          console.log(err)
      })
    }
    /**
     * @description 自动展开子项
     * @function changeExpandedRows
     * @param {Object} 已展开的行
     * @memberof IndustryManage
     */
    changeExpandedRows = (expandedRows) => {
            this.setState({
                expandedRows,
            })
        };
        
    render(){
        const columns = [
            {
              title: '行业名称',
              dataIndex: 'name',
              key: 'name',
              fixed:'left',
              width:280,
            },
            {
              title: '编号',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: '代码',
              dataIndex: 'code',
              key: 'code',
            },
            {
              title: '描述',
              dataIndex: 'description',
              key: 'description',
              render(text) {
                return <span title={text}>{text}</span>
            },
            },
            {
              title: '更新时间',
              dataIndex: 'updateDate',
              key: 'updateDate',
            },
            {
              title: '操作',
              dataIndex: 'opera',
              key: 'opera',
              render: (text,record) => {
                if(record.level==0){
                  return<div>
                <a title="新增" onClick={()=>this.handleAdd('create',record)}><Icon type="plus"style={{fontSize:20}}/></a> 
                </div>
                }
                if(record.level==4){
                    return<div>
                    <a title="编辑" onClick={()=>this.handleEdit('edit',record)}><Icon type="edit"style={{paddingLeft:27,fontSize:20,color:"#52c41a"}}/></a> 
                    <a title="删除" onClick={()=>this.handleDelete(record)}><Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{paddingLeft:7,fontSize:20}}/></a>
                    <a title="上移" onClick={()=>this.shiftUp(record)}><Icon type="arrow-up" style={{paddingLeft:7,fontSize:20,color:"#595959"}}/></a>
                    <a title="下移" onClick={()=>this.shiftDown(record)}><Icon type="arrow-down" style={{paddingLeft:7,fontSize:20,color:"#595959"}}/></a>
                    </div>
                  }
                return<div>
                <a title="新增" onClick={()=>this.handleAdd('create',record)}><Icon type="plus"style={{fontSize:20}}/></a> 
                <a title="编辑" onClick={()=>this.handleEdit('edit',record)}><Icon type="edit"style={{paddingLeft:7,fontSize:20,color:"#52c41a"}}/></a> 
                <a title="删除" onClick={()=>this.handleDelete(record)}><Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{paddingLeft:7,fontSize:20}}/></a>
                <a title="上移" onClick={()=>this.shiftUp(record)}><Icon type="arrow-up" style={{paddingLeft:7,fontSize:20,color:"#595959"}}/></a>
                <a title="下移" onClick={()=>this.shiftDown(record)}><Icon type="arrow-down" style={{paddingLeft:7,fontSize:20,color:"#595959"}}/></a>
                </div>
            }
            },
          ];
          const title = () => {
            return (
                <div>
                    <div className="fr">
                            <Button style={{ marginLeft: 10 }}><a id='down' onClick={()=>this.exportExcel()}>
                                    导出
                            </a></Button>
                            <Button type="primary" onClick={this.onImportExcel} style={{ marginLeft: 10 }}>
                                    导入
                            </Button>
                    </div>
                    <div className="clear"></div>
                </div>
            )
        };
          
        return(
            <div className='manage'>
          <Card>
           <IndustryForm handleSearch={this.handleSearch} wrappedComponentRef={(inst) => this.searchRef = inst}/>
            <Table 
                className="mt20" title={title}
                columns={columns} 
                dataSource={this.state.IndustryOptions}
                bordered
                onRow={(record,index) => {
                  return {
                      onClick:()=>{
                          this.onRowClick(record,index);
                      }
                  };
              }}
                expandedRowKeys={this.state.expandedRows}
                onExpandedRowsChange={this.changeExpandedRows.bind(this)}
                onExpand={this.handleOnExpand}
                pagination={this.state.pagination}
                rowKey={record=>record.id}
            />
                <Modal
                    title={this.state.title}
                    visible={this.state.isManageVisible}
                    onOk={()=>{this.handleSubmit()}}
                    onCancel={()=>{
                        this.ManageForm.props.form.resetFields();
                        this.setState({
                          isManageVisible:false,
                          manageInfo:''
                        })
                    }}
                >
                    <ManageForm manageInfo={this.state.manageInfo} type={this.state.type} wrappedComponentRef={(inst) => this.ManageForm = inst }/>
                </Modal>
                <Modal
                    title={this.state.title}
                    visible={this.state.isDeleteVisible}
                    onOk={()=>{this.handleDeleteSubmit()}}
                    width={300}
                    onCancel={()=>{
                        this.DeleteForm.props.form.resetFields();
                        this.setState({
                          isDeleteVisible:false,
                        })
                    }}
                >
                    <DeleteForm deleteInfo={this.state.deleteInfo} wrappedComponentRef={(inst) => this.DeleteForm = inst }/>
                </Modal>
                <Modal
                    title={this.state.title}
                    visible={this.state.isImportVisible}
                    onOk={()=>{this.handleImportSubmit()}}
                    onCancel={()=>{
                        this.setState({
                            isImportVisible:false,
                        })
                    }}
                >
                    <ImportForm transferID = {fileID => this.transferID(fileID)}wrappedComponentRef={(inst) => this.ImportForm = inst }/>
                </Modal>
            </Card>
            </div>
        );
    }
}
export default IndustryManage;

/**
 * @description 组件-行业搜索
 * @class IndustryForm
 * @extends {React.Component}
 */
class IndustryForm extends React.Component{
    handleReset = ()=>{
      this.props.form.resetFields();
    }
    handleSearch=()=>{ //调用父组件方法
        return this.props.form.getFieldsValue()
    }
  render(){
      const {getFieldDecorator} = this.props.form;
      return (
          <Form layout="inline"> 
          <FormItem>
              {getFieldDecorator('keyword')(<Input
              placeholder="请输入行业名称/编号/代码" prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
              style={{ width: 264,marginBottom:20}}
              />)}
              </FormItem>
          <FormItem>
              <Button type="primary" onClick={this.props.handleSearch}>
                      搜索
              </Button>
          </FormItem>
          </Form>
      );
  }
}
IndustryForm = Form.create({})(IndustryForm);


/**
 * @description 组件-行业编辑和新增
 * @class ManageForm
 * @extends {React.Component}
 */
class ManageForm extends React.Component{
  //重置
  resetValue = ()=>{
      this.props.form.resetFields()
  }
  render(){
      const manageInfo = this.props.manageInfo || {};
      const type = this.props.type;
      const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
          labelCol: {span: 6},
          wrapperCol: {span: 16}
      };
      return (
          <Form layout="horizontal">
              <FormItem label="行业名称" {...formItemLayout}>
                  {
                      getFieldDecorator('name',{
                          initialValue:type=='edit'?manageInfo.name:[],rules: [{ required: true, message: '请输入行业名称'},]
                      })(
                          <Input type="text"/>
                      )
                      }
              </FormItem>
              <FormItem label="代码" {...formItemLayout}>
                  {
                      getFieldDecorator('code',{
                        initialValue:type=='edit'?manageInfo.code:[], rules: [{ required: true, message: '请输入代码' },]
                      })(
                          <Input type="text"/>
                      )
                      }
              </FormItem>
              <FormItem label="描述" {...formItemLayout}>
                  {
                   getFieldDecorator('description',{
                    initialValue:type=='edit'?manageInfo.description:[],
                })(
                    <TextArea rows={2} type='text'/>
                 )
                      }
              </FormItem>
          </Form>
      );
  }
}
ManageForm = Form.create({})(ManageForm);

/**
 * @description 组件-输入"确认删除"弹框
 * @class DeleteForm
 * @extends {React.Component}
 */
class DeleteForm extends React.Component{
    //重置
    resetValue = ()=>{
        this.props.form.resetFields()
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="horizontal">
                <FormItem label="请输入delete确认删除">
                    {
                        getFieldDecorator('contain',{
                          initialValue:[], rules: [{ required: true,message: '请输入delete'},{pattern: /[d][e][l][e][t][e]/,message: '请输入delete'}]
                        })(
                            <Input type="text"/>
                        )
                        }
                </FormItem>
            </Form>
        );
    }
}
DeleteForm = Form.create({})(DeleteForm);
/**
 * @description 组件-导入行业数据弹框
 * @class ImportForm
 * @extends {React.Component}
 */
class ImportForm extends React.Component{
    resetValue = ()=>{
        this.props.form.resetFields()
    }
    render(){
        let that=this
        const props = {
            name: 'file',
            action: '/v1/ajax/file/upload',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
              if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                //   console.log(info.file.response.result.id)
                message.success(`${info.file.name} 上传成功`);
                that.props.transferID(info.file.response.result.id)
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败`);
              }
            },
          };
        const formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 16}
        };
        return (
            <Form layout="horizontal" className='importForm'>
                <FormItem label="第一步" {...formItemLayout}>
                    {
                        <span className='ti'>下载
                            <a href="../../../导入四级行业.xls" download='' className="text-underline">导入模板</a> 
                            </span>
                        }
                </FormItem>
                <FormItem label="第二步" {...formItemLayout}>
                    {
                         <Upload {...props}>
                         <Button type="primary">
                            上传文件
                         </Button>
                       </Upload>
                        }
                </FormItem>
            </Form>
        );
    }
}
ImportForm = Form.create({})(ImportForm);