/**
 * @file 题库的Tab内部内容模板组件
 * @author Sim <sim@snorl.ax>
 */
import React, { Component } from 'react'
import { Select, Form, Button, Table, Modal } from 'antd'
import { statusMessage, parseDate } from '../features'
import { cyan, lime } from '@ant-design/colors'

/**
 * Ant Design的选项元素组件
 * @const {Object} Option
 * @type {class}
 */
const { Option } = Select, 

/**
 * Ant Design的表格项元素组件
 * @const {Object} Item
 * @type {class}
 */
    {Item} = Form, 
/**
 * 处理当前数据行表格列数据的并最终返回渲染值的函数
 * @typedef {Function} tableColumnRenderFunction
 * @param {String} text 当前数据行对应的该表格列的值
 * @param {Object} record 当前数据行
 * @returns {Number | String | HTMLDocument | HTMLCollection}
 */
/**
 * 表格列
 * @typedef {Object} tableColumn
 * @property {String} title 表头标题
 * @property {String} dataIndex 表头取的字段名称
 * @property {String} key 表头的key值 
 * @property {tableColumnRenderFunction} render 
 */
/**
 * 表格列数据
 * @const {Array.<tableColumn>} columnsOri
 */
    columnsOri = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index'
    },{
        title: '题干',
        dataIndex: 'stem',
        key: 'stem'
    }, {
        title: '选项',
        dataIndex: 'optionList',
        key: 'optionList'
    }, {
        title: '解析',
        dataIndex: 'analysis',
        key: 'analysis'
    }, {
        title: '答案',
        dataIndex: 'result',
        key: 'result'
    }, {
        title: '类别',
        dataIndex: 'type',
        key: 'type'
    },{
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: (text) => text == 1 ? '未入库' : text == 2 ? '已入库' : '已禁用'
    }, {
        title: '更新时间',
        dataIndex: 'updateDate',
        key: 'updateDate',
        render: (text) => parseDate(Number(text))
    }, {
        title: '操作',
        dataIndex: 'action',
        key: 'action'
    }
]

/**
 * 题库的Tab内部内容模板组件
 * @class Tab
 * @extends React.Component
 */

class Tab extends Component {

    /**
     * 状态集合
     * @type {Object}
     */
    state = {addVisible: false, importVisible: false}

    /**
     * 重设表格数值为初始
     * @type {Function}
     * @return {Promise}
     */
    resetFields = () => this.props.form.resetFields()

    /**
     * 表格列
     * @type {Function}
     * @return {Object}
     */
    columns = () => {
        const columns = [...columnsOri]
        columns[0].render = (text) => (this.props.pagination.current - 1) * this.props.pagination.pageSize + text
        if (this.props.actionRender) {
            columns[columns.length-1].render = this.props.actionRender
            columns[5].render = (text) => this.props.category ? this.props.category[text-1] : ''
        }
        if (this.props.type == 'fillin') {
            columns[4].dataIndex = 'fillResult'
            columns.splice(2, 1)
        } else if (this.props.type == 'single') {
            columns[4].dataIndex = 'result'
            columns[2].render = (text, record) => {
                let i = 0, options = ['A', 'B', 'C', 'D'], optionList = record.optionList ? record.optionList : [record.optionA, record.optionB, record.optionC, record.optionD]
                return optionList.map(item => {
                    if (item && item.length && item.length > 0) {
                        return <p key={'single'+record.index+options[i]+'. '+item}>{options[i++]+'. '+item}</p>
                    } else {
                        i++;
                    }
                })
            }
        } else {
            columns[4].dataIndex = 'result'
            columns[2].render = (text, record) => {
                let i = 0, options = ['A', 'B', 'C', 'D'], optionList = record.optionList ? record.optionList : [record.optionA, record.optionB, record.optionC, record.optionD]
                return optionList.map(item => {
                    if (item && item.length && item.length > 0) {
                        return <p key={'multi'+record.index+options[i]+'. '+item}>{options[i++]+'. '+item}</p>
                    } else {
                        i++;
                    }
            })
            }
        }
        return columns
    }

    /**
     * 新增表格的弹框点击确定时要做的事
     * @type {Function}
     */
    onOk = () => {
        if (this.props.onOk) {
            this.props.onOk().then(value => {
                statusMessage()
                this.hideAddModal()
                if (this.props.postOk){
                    this.props.postOk(value)
                }
            }, reason => {
                statusMessage(typeof(reason) == 'string' ? reason : '请填写完整')
            })
        }
        else {
            this.hideAddModal()
            if (this.props.postOk){
                this.props.postOk()
            }
        }
    } 

    /**
     * 导入表格的弹框点击确定时要做的事
     * @type {Function}
     */
    onOkImport = () => {
        if (this.props.onOkImport) {
            this.props.onOkImport().then(value => {
                statusMessage()
                if (value) {
                    alert(value.join('\n'))
                }
                this.hideImportModal()
                if (this.props.postOkImport){
                    this.props.postOkImport(value)
                }
            }, reason => {
                statusMessage(typeof(reason) == 'string' ? reason : '请填写完整')
            })
        }
        else {
            this.hideImportModal()
            if (this.props.postOkImport){
                this.props.postOkImport()
            }
        }
    } 

    /**
     * 新增表格的弹框点击确定时要做的事
     * @type {Function}
     */
    onCancel = () => {
        this.props.onCancel();
        this.hideAddModal();
    }

    /**
     * 新增表格从无到有
     * @type {Function}
     */
    showAddModal = () => {
        this.setState({
            addVisible: true,
        });
    };

    /**
     * 导入表格从无到有
     * @type {Function}
     */
    showImportModal = () => {
        this.setState({
            importVisible: true,
        });
    };

    /**
     * 新增表格从有到无
     * @type {Function}
     */
    hideAddModal = () => {
        this.setState({
            addVisible: false,
        });
    };

    /**
     * 导入表格从有到无
     * @type {Function}
     */
    hideImportModal = () => {
        this.setState({
            importVisible: false,
        });
    };

    /**
     * 搜索分类的提交表格前的校验
     * @type {Function}
     */
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values)
            this.props.searchFunction(values.type, this.props.type)
          }
        });
    };

    /**
     * 新增表格的弹框点击确定时要做的事
     * @type {Function}
     * @return {object}
     */
    getValues = () => this.props.form.getFieldsValue()

    /**
     * 题目范畴Option生成
     * @type {Function}
     * @return {Array<Option>}
     */
    searchOptions = () => this.props.category ? this.props.category.map((item, index) => <Option key={index+item+'类别'} value={index+1}>{item}</Option>) : <Option value={1}>暂无</Option>
    
    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => {
        const { getFieldDecorator } = this.props.form
        return <div>
            <Button style={{float: 'right', marginLeft: '1em', backgroundColor: lime[6], borderColor: lime[6]}} type='primary' onClick={() => this.showAddModal()}>新增</Button>
            <Button style={{float: 'right', backgroundColor: cyan[6], borderColor: cyan[6]}} type='primary' onClick={() => this.showImportModal()}>导入</Button>
            <Form layout='inline' name='label'>
                <Item label='类别'>
                    {getFieldDecorator('type')(<Select style={{width: '15em'}} placeholder='请选择'>
                        {this.searchOptions()}
                    </Select>)}
                </Item>
                <Item>
                    <Button type='primary' onClick={this.handleSubmit}>搜索</Button>
                </Item>
                <Item>
                    <Button onClick={this.resetFields}>重置</Button>
                </Item>
            </Form>

            <Table style={{marginTop: '1em'}} columns={this.columns()} dataSource={this.props.data} pagination={this.props.pagination ? this.props.pagination : undefined} />
            <Modal
                title={this.props.addTitle||"Modal"}
                visible={this.state.addVisible}
                onOk={this.onOk}
                onCancel={this.hideAddModal}
                autoFocusButton={'ok'}
                okText={this.props.okText || "确认"}
                cancelText={this.props.cancelText || "取消"}
                >
                {this.props.addForm}
            </Modal>
            <Modal
                key={Math.random()}
                title={this.props.importTitle||"Modal"}
                visible={this.state.importVisible}
                onOk={this.onOkImport}
                onCancel={this.hideImportModal}
                autoFocusButton={'ok'}
                okText={this.props.okTextImport || "确认"}
                cancelText={this.props.cancelTextImport || "取消"}
                >
                {this.props.importForm}
            </Modal>
        </div>
    }
}

/**
 * 表单创建
 * @const {Form} TabContent
 */
const TabContent = Form.create()(Tab)
export default TabContent