import React, { Component } from 'react'
import { Form, Input, message } from 'antd'
import {getCorpResetPassword} from './../../../../Api/index'

class EditAccount extends Component {
    getItemsValue = ()=>{ 
        // const valus= this.props.form.getFieldsValue();
        // return valus;
        let returnValues
        this.props.form.validateFields((err,value) => {
            if(err){
                returnValues = false
            }else{
                returnValues = value
                returnValues = this.props.form.getFieldsValue()
            }
        })
        return returnValues;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    //请求重置密码接口
    ResetPassword = () => {
        getCorpResetPassword({
            username:this.props.record.username,
        }).then((res)=>{
            console.log(res);
            message.success(res.data.content);
        }).catch((error)=>{
            console.log(error);
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                <Form.Item label="姓名">
                    {getFieldDecorator('name',{
                        initialValue:this.props.record.name,
                        rules: [{ required: true, message: '请输入姓名！' }],
                    })(
                        <Input placeholder="姓名"/>
                    )}
                </Form.Item>
                <Form.Item label="账号">
                    {getFieldDecorator('username',{
                        initialValue:this.props.record.username,
                        rules: [
                            { required: true, message: '请输入账号！' },
                            // { pattern: /[0-9a-zA-Z]{2,20}$/, message: '请输入2-20位字母或数字！' }
                        ],
                    })(
                        <Input placeholder="账号"/>
                    )}
                </Form.Item>
                <Form.Item label="手机号">
                    {getFieldDecorator('mobile',{
                        initialValue:this.props.record.mobile,
                        rules: [ {pattern: /^((13|14|15|17|18)[0-9]{9})$/,message: '请正确输入手机号！'}],
                    })(
                        <Input placeholder="手机号"/>
                    )}
                </Form.Item>
                <Form.Item label="密码">
                    <a className="blue" onClick={this.ResetPassword}>重置密码</a>
                </Form.Item>
                <Form.Item label="创建时间">
                    {getFieldDecorator('createDate',{
                        initialValue:this.props.record.createDate,
                    })(
                        <Input disabled placeholder="创建时间"/>
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const EditAccountForm = Form.create()(EditAccount);

export default EditAccountForm;
