import React, { Component } from 'react'
import {Table,message,Icon} from 'antd'
import { homeCheckList, getHomeCheckInfo, checkPass } from './../../../Mock'
import { getHaDoctorCertificationListData,getHaDoctorCertification,getFileUrl,auditHaDoctorCertification } from './../../../Api'
import Checkmodal from './../component/form/Checkmodal'    //Modal-Form组件
import SearchForm from '../component/form/Search'      //搜索框组件
import utils from './../../../Common/js/utils'
import { withRouter, NavLink } from 'react-router-dom'

//首页-待认证用户-医生Tab
class Doctor extends Component {
    state = {
        formList: [{
            name: "keyword",
            type: 'input',
            placeholder: '姓名/手机号'
        }],
        page: {
            pageNum: 1,
            pageSize: 30
        },
        //初始化Modal组件
        formModal: {
            title: '审核',
            list: []
        },
    }
    //通过Api获取表格数据
    getList = (params) => {
        this.setState({
            params:params
        })
        let data = {  ...params,...this.state.page }
        let _this = this
        getHaDoctorCertificationListData(data).then(res => {
            if (res.data.state.value == 0) {
                //为表格数据加上唯一Key
                res.data.result.rows.map(item => {
                    item.key = item.id
                    return item
                })
                this.setState({
                    tableList: res.data.result.rows,
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        this.getList(params)
                    }, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        this.getList(params)
                    }, res.data.result.pageSize)
                })
            }

        })
    }
    //挂载前获取表格数据
    componentWillMount() {
        this.getList()
    }
    //更改弹框的显示状态
    handleVisibleChange = () => {
        this.setState({
            visibleCheckModal: !this.state.visibleCheckModal
        })
    }

    //获取文件url
    async getPicUrl(idsObj){
        let picObj = {}
        let idsArr = Object.keys(idsObj)
        for(let i=0;i< idsArr.length;i++){
            await new Promise((resolve,reject)=>{
                if(idsArr[i]){
                    getFileUrl({fileId:idsObj[idsArr[i]]}).then(res=>{
                        console.log(res)
                        if(res.data.state.value == 0){
                            picObj[idsArr[i]] = res.data.result
                            resolve(res.data.result)
                        }else{
                            resolve(res.data.content)
                        }
                        }
                    )
                }else{

                }
                
            })
        }
        return new Promise((resolve,reject)=>{
            console.log(picObj,"picobj")
            if(Object.keys(picObj).length!=0){
                resolve(picObj)
            }else{
                resolve({})
            }
        })
    }


    //设定Modal-Form弹窗的内容
    handleOpenCheck = (record) => {
        let _this = this
        if(!record)return
        this.setState({
            currentUserId:record.userId
        })
        let picIdsObj = {
            cardPositiveFileId:record.cardPositiveFileId,
            cardBackFileId:record.cardBackFileId,
            certDoctorLicenseId:record.certDoctorLicenseId,
            certPracticingLicenseId:record.certPracticingLicenseId,
            certProfTechnicalLicenseId:record.certProfTechnicalLicenseId
        }
        this.getPicUrl(picIdsObj).then(urlObj=>new Promise((resolve,reject)=>{
            getHaDoctorCertification({ userId: record.userId }).then(res => {
                console.log(res)
                console.log(urlObj,'urlObj')
                if (res.data.state.value == 0) {
                    if(!res.data.result)return
                    _this.setState({
                        formModal: {
                            title: '认证审核',
                            list: [{
                                name: "fullname",
                                label: "真实姓名",
                                type: 'original',
                                value: res.data.result.fullname
                            }, {
                                name: "identityNo",
                                label: "身份证号码",
                                type: 'original',
                                value: res.data.result.identityNo
                            },
                            {
                                name: "identiyPic",
                                label: "身份证正反照",
                                type: 'identiyPic',
                                value: [urlObj.cardPositiveFileId, urlObj.cardBackFileId]
                            },
                            {
                                name: "address",
                                label: "职业地点",
                                type: 'original',
                                value: res.data.result.address
                            },
                            {
                                name: "departmentsName",
                                label: "所在科室",
                                type: 'original',
                                value: res.data.result.departmentsName
                            },
                            {
                                name: "jobTitle",
                                label: "职称",
                                type: 'original',
                                value: res.data.result.jobTitle
                            },
                            {
                                name: "partPic",
                                label: "执照照片",
                                type: 'identiyPic',
                                value: [urlObj.certDoctorLicenseId, urlObj.certPracticingLicenseId,urlObj.certProfTechnicalLicenseId]
                            },
                            {
                                name: "checkState",
                                label: "审核结果",
                                type: 'radio',
                                radioList: [{
                                    label: '通过',
                                    value: 2
                                }, {
                                    label: '不通过',
                                    value: 3
                                }],
                                rules:[{
                                    required: true,
                                    message: '请选择审核结果'
                                }],
                                initial:(res.data.result.checkState != 2 || res.data.result.checkState != 3)?null:res.data.result.checkState
                            }, {
                                name: "checkStateResult",
                                type: 'textArea',
                                label: "不通过原因",
                                placeholder: '请输入不通过原因',
                                initial:res.data.result.checkStateResult
                            }]
                        },
                    }, function () {
                        this.setState({
                            visibleCheckModal: true,
                        })
                    })
                }
            })
        }))
       
    }
    //弹框的“确认”回调 需返回Promise对象
    handleCheck = (data) => {
        data.userId = this.state.currentUserId
        if(!data.checkState)return 
        return auditHaDoctorCertification(data).then((res) => {
            return new Promise((resolve, reject) => {
                if (res.data.state.value == 0) {
                    this.setState({
                        visibleCheckModal: false
                    })
                    message.success('操作成功')
                    this.getList(this.state.params)
                    resolve(true)
                } else {
                    reject(false)
                }
            })
        })
    }
    jumpTo = (record)=>{
        this.props.history.push({pathname: '/user/all/list2/basicp', state: {data: record,text:'医生用户'}})
    }
    render() {
        let _this = this
        const columns = [
            {
                title: "姓名",
                dataIndex: 'name',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "注册来源",
                dataIndex: 'regResource',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "手机号",
                dataIndex: 'mobile',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "申请时间",
                dataIndex: 'createDate',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "操作",
                align: 'center',
                width: 50,
                render(text, record) {
                    return <div>
                        <a onClick={() => _this.jumpTo(record)}><Icon type="eye" title="查看" className="mr10 table-icon-blue"/></a>
                        <a onClick={() => _this.handleOpenCheck(record)}><Icon type="check-circle" title="审核" className="table-icon-green"/></a>
                    </div>
                },

            },{
                title: "身份证背面id",
                dataIndex: 'cardBackFileId',
                width: 0,
                className:'disnone'
            },{
                title: "身份证正面id",
                dataIndex: 'cardPositiveFileId',
                width: 0,
                className:'disnone'   
            },{
                title: "医师执业证Id",
                dataIndex: 'certPracticingLicenseId',
                width: 0,
                className:'disnone'
            },{
                title: "专业技术资格证Id",
                dataIndex: 'certProfTechnicalLicenseId',
                width: 0,
                className:'disnone'   
            },{
                title: "医师资格证Id",
                dataIndex: 'certDoctorLicenseId',
                width: 0,
                className:'disnone'   
            },{
                title: "用户id",
                dataIndex: 'userId',
                width: 0,
                className:'disnone'   
            }
            
        ]
        return (
            <div className="home">
                <SearchForm formList={this.state.formList} formSearchApi={(data) => { this.getList(data) }} />
                <Table
                    className="mt20"
                    bordered
                    columns={columns}
                    dataSource={this.state.tableList}
                    pagination={{ ...this.state.pagination, pageSize: this.state.page.pageSize }}
                />
                <Checkmodal formSubmitApi={(data) => this.handleCheck(data)} handleVisibleChange={this.handleVisibleChange} visibleCheckModal={this.state.visibleCheckModal} formList={this.state.formModal} />
            </div>
        )
    }
}

export default withRouter(Doctor);