import React, { Component } from 'react'
import { Input, Select, Button, Upload, Form, Icon, message } from 'antd'
import './../index.css'
import { addVideo, editVideo, postUploadFile, uploadVideo } from "../../../../Api";
const { Option } = Select
const { TextArea } = Input
const FormItem = Form.Item
function getBase64 (img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
class Addvideo extends Component {
    /**
     * 文件上传后的回调事件
     * @function normFile
     * @param {Event} e - 提交事件
     * @return {Array} - 处理后的已上传文件列表
     */
    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    /**
     * 组件内数据
     * @type {Object} state -数据
     * @param {Boolean} loading - 控制上传按钮的状态
     * @param {Boolean}  isEdit - 控制组件的状态（true：编辑，false：新增）
     * @param {Boolean}  isShowDatePicker - 控制时间选择显示的状态值
     */
    state = {
        loading: false,
        isEdit: false,
        // isShowDatePicker:false     //定时上架操作
    };
    componentWillMount () {
        if (this.props.location.state && this.props.location.state.type == "edit") {
            if (this.props.location.state.record.picList && this.props.location.state.record.picList[0]) {
                let obj = {
                    uid: this.props.location.state.record.picList[0].id,
                    name: this.props.location.state.record.picList[0].fileName,
                    status: 'done',
                    url: this.props.location.state.record.picList[0].fullOssurl,
                }

                this.props.location.state.record.picList[0] = obj
            }
            this.setState({
                isEdit: true,
                isUp: this.props.location.state.record.status,
                initialObj: this.props.location.state.record,
                imageUrl: this.props.location.state.record.picList && this.props.location.state.record.picList.length != 0 && this.props.location.state.record.picList[0].url
            })
        }

    }
    /**
     * 监听上传组件
     * @function handleChange
     * @param {Event} info - 上传事件
     */
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };
    /**
     * 切换时间选择器显示状态
     * @function handleChangeUpdateTime
     * @param {Event} e - 点击事件
     */
    //定时上架操作-radio change time type
    // handleChangeUpdateTime = (e)=>{
    //     console.log(e)
    //     switch(e.target.value){
    //         case 0:
    //             this.setState({
    //                 isShowDatePicker:false
    //             });
    //             break;
    //         case 1:
    //             this.setState({
    //                 isShowDatePicker:true
    //             });
    //             break;
    //         default:
    //             break;
    //     }
    // }

    /**
     * 提交编辑的表单
     * @function handleChangeUpdateTime
     * @param {String}  type - 提交表单的类型，'up'-上架按钮提交 ，其余为普通保存
     */
    handleEdit = (type) => {
        let params = this.props.form.getFieldsValue()
        delete params.screenshotload
        /** status ：上架-2，下架-3，未上架-1  */
        if (type == 'up') {
            params.status = 2
        }
        if (this.state.initialObj && this.state.initialObj.id) {
            params.id = this.state.initialObj.id
            editVideo(params).then(res => {
                if (res.data.state.value == 0) {
                    message.success("编辑成功！")
                    this.props.history.push({
                        pathname: '/training/video'
                    })
                }
            })
        } else {
            message.warning("编辑出错，请重新提交！")
        }

    }
    /**
     * 提交新增的表单
     * @function handleSubmit
     * @param {String}  type - 提交表单的类型，'up'-上架按钮提交 ，其余为普通保存
     */
    handleSubmit = (type) => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let params = this.props.form.getFieldsValue()
                delete params.screenshotload
                if (type == 'up') {
                    /** status ：上架-2，下架-3，未上架-1  */
                    params.status = 2
                }
                addVideo(params).then(res => {
                    if (res.data.state.value == 0) {
                        message.success("新增成功！")
                        this.props.history.push({
                            pathname: '/training/video'
                        })
                    }
                })
            }
        })

    }
    /**
     * 重置表单并回退
     * @function handleResetForm
     */
    handleResetForm = () => {
        this.props.form.resetFields()
        this.props.history.push({
            pathname: '/training/video'
        })
    }
    /**
     * 校验时长工具
     * @function toolCheckMinutes
     * @param {string} val 需要校验的视频时长字符串
     * @return {boolean} 校验是否通过,通过-true
     */
    toolCheckMinutes = (val) => {
        if (!val) return false
        console.log(val.split(":"))
        var regTime = /^(([1-9]\d*)|([0-9][0-9])):([0-5]\d)$/
        return regTime.test(val)
    }
    render () {
        let _this = this
        /**
         * @typedef {HTMLElement} uploadButton - 上传按钮
         */
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const { imageUrl, initialObj, isEdit, isUp } = this.state;
        const { getFieldDecorator } = this.props.form
        /**
         * @typedef {Object} upload - 上传配置
         * @property {String} name - 上传表单名
         * @property {String} accept - 接受上传的文件类型
         * @property {Function} beforeUpload - 上传前回调
         * @property {Function} onRemove - 移除文件的回调
         */
        const upload = {
            /** 自定义上传，无需action */
            name: 'screenshotload',
            accept: 'image/jpeg,image/png',
            beforeUpload (file) {
                let params = new FormData()
                params.append('file', file)
                postUploadFile(params).then(res => {
                    if (res.status === 200 && res.data.state.value === 0) {
                        _this.setState({
                            imageUrl: res.data.result.fullOssurl ? res.data.result.fullOssurl : ''
                        });
                        _this.props.form.setFieldsValue({ screenshot: res.data.result.id ? res.data.result.id : '' })
                    }
                })
                /** 阻止antd自带的上传 */
                return false
            }
        }
        /**
         * @typedef {Object} videoUpload - 上传配置
         * @property {String} name - 上传表单名
         * @property {String} accept - 接受上传的文件类型
         * @property {Function} beforeUpload - 上传前回调
         * @property {Function} onRemove - 移除文件的回调
         */
        const videoUpload = {
            /** 自定义上传，无需action */
            name: 'urlUpload',
            accept: 'video/*',
            showUploadList: false,
            beforeUpload (file) {
                let params = new FormData()
                params.append('file', file)
                uploadVideo(params).then(res => {
                    if (res.status === 200 && res.data.state.value === 0) {
                        _this.props.form.setFieldsValue({ url: res.data.result.fullOssurl ? res.data.result.fullOssurl : '' })
                    }
                })
                /** 阻止antd自带的上传 */
                return false
            }
        };
        return (
            <div className="add-video">
                <table className="add-video-table width-100" border={"2"}>
                    <tbody>
                        <tr>
                            <td width={"150"}><b className={"red"}>*</b>视频名称</td>
                            <td>
                                <FormItem>
                                    {getFieldDecorator('name', {
                                        initialValue: isEdit ? initialObj.name : undefined,
                                        rules: [{
                                            required: true,
                                            message: '视频名称必填！'
                                        }]
                                    })(<Input></Input>)}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td><b className={"red"}>*</b>类别</td>
                            <td>
                                <FormItem>
                                    {getFieldDecorator('type', {
                                        initialValue: isEdit ? initialObj.type : undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '类别未必选项！',
                                            },
                                        ],
                                    })(
                                        <Select placeholder="请选择类别" style={{ width: '100%' }}>

                                            <Option value={1}>职业卫生综合</Option>
                                            <Option value={2}>职业危害防护</Option>
                                            <Option value={3}>职业健康监护</Option>
                                            <Option value={4}>职业危害因素</Option>
                                            <Option value={5}>职业卫生三同时</Option>
                                            <Option value={6}>职业病</Option>
                                            <Option value={7}>放射卫生</Option>
                                        </Select>
                                    )}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td><b className={"red"}>*</b>视频地址</td>
                            <td>
                                <FormItem>
                                    {getFieldDecorator('url', {
                                        initialValue: isEdit ? initialObj.url : '',
                                        rules: [{
                                            required: true,
                                            message: '视频地址必填！'
                                        }]
                                    })(
                                        <Input></Input>
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('url', {
                                        rules: [{
                                            required: true,
                                            message: '视频地址必填！'
                                        }]
                                    })(
                                        <Upload {...videoUpload}>
                                            <Button>
                                                <Icon type="upload" /> Upload
                                            </Button>
                                        </Upload>
                                    )}
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td><b className={"red"}>*</b>视频时长<br />（分钟:秒数）</td>
                            <td>
                                <FormItem>
                                    {getFieldDecorator('minutes', {
                                        initialValue: isEdit ? initialObj.minutes : '',
                                        rules: [{
                                            required: true,
                                            message: '视频时长必填！'
                                        },
                                        {
                                            validator: (rule, val, callback) => {
                                                if (!this.toolCheckMinutes(val)) {
                                                    callback([new Error("请按固定格式填写视频时长！")])
                                                }
                                                return this.toolCheckMinutes(val)
                                            }
                                        }]
                                    })(
                                        <Input></Input>
                                    )}
                                </FormItem>
                                ①时间冒号为英文分号②一小时内时长均按 xx:xx 格式填写<br />
                                格式举例  108:45   00:15    08:59
                            </td>
                        </tr>
                        <tr>
                            <td>腾讯视频地址</td>
                            <td>
                                <FormItem>
                                    {getFieldDecorator('txUrl', {
                                        initialValue: isEdit ? initialObj.txUrl : '',
                                    })(
                                        <Input></Input>
                                    )}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td>来源</td>
                            <td>
                                <FormItem>
                                    {getFieldDecorator('source', {
                                        initialValue: isEdit ? initialObj.source : '',
                                    })(
                                        <Input></Input>
                                    )}
                                </FormItem>

                            </td>
                        </tr>
                        <tr>
                            <td><b className={"red"}>*</b>视频画面</td>
                            <td>
                                <FormItem>
                                    {getFieldDecorator('screenshotload', {
                                        valuePropName: 'fileList',
                                        getValueFromEvent: this.normFile,
                                        initialValue: isEdit && initialObj.picList ? initialObj.picList : [],
                                        rules: [{
                                            required: true,
                                            message: '视频画面必传！'
                                        }]
                                    })(
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            {...upload}
                                            onChange={this.handleChange}
                                        >
                                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                        </Upload>
                                    )}
                                </FormItem>
                                {getFieldDecorator('screenshot', {
                                    initialValue: isEdit ? initialObj.screenshot : '',
                                })(
                                    <Input type={"hidden"}></Input>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td><b className={"red"}>*</b>视频简介</td>
                            <td>
                                <FormItem>
                                    {getFieldDecorator('description', {
                                        initialValue: isEdit ? initialObj.description : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '视频简介为必填项!',
                                            }
                                        ],
                                    })(
                                        <TextArea
                                            rows={8}
                                        />
                                    )}
                                </FormItem>

                            </td>
                        </tr>
                    </tbody>
                </table>
                {/*定时上架操作*/}
                {/*<FormItem className="mt20">*/}
                {/*    {getFieldDecorator('updateType', {*/}
                {/*        rules:[*/}
                {/*            {*/}
                {/*                required:true,*/}
                {/*                message:"bixu",*/}
                {/*            }*/}

                {/*        ]}*/}
                {/*    )(*/}
                {/*        <RadioGroup name={"time"} onChange={this.handleChangeUpdateTime}>*/}
                {/*            <Radio value={0}>立即上架</Radio>*/}
                {/*            <Radio value={1}>定时上架</Radio>*/}
                {/*        </RadioGroup>*/}
                {/*    )}*/}
                {/*    {*/}
                {/*        this.state.isShowDatePicker?<span>{getFieldDecorator('time', {})(<DatePicker*/}
                {/*        showTime*/}
                {/*        />*/}
                {/*            )}</span>*/}
                {/*            :null*/}
                {/*    }*/}
                {/*</FormItem>*/}
                <div className="text-center mt20">
                    {
                        isEdit ? <div className={"dlineb"}>
                            {isUp == 2 ? null : <Button type={'primary'} className={"mr10"} onClick={this.handleEdit}>保存</Button>}
                            <Button className={"btn-green mr10"} onClick={() => this.handleEdit('up')}>上架</Button>
                        </div> : <div className={"dlineb"}>
                            <Button type={'primary'} className={"mr10"} onClick={this.handleSubmit}>保存</Button>
                            <Button className={"btn-green mr10"} onClick={() => this.handleSubmit('up')}>上架</Button>
                        </div>
                    }
                    <Button className="mr20" onClick={this.handleResetForm}>取消</Button>
                </div>
            </div>
        )
    }
}
const AddvideoForm = Form.create({ name: 'validate_other' })(Addvideo);
export default AddvideoForm