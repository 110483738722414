import React, { Component } from 'react'
import { Form, Input } from 'antd'

class AddAccount extends Component {
    getItemsValue = () => { 
        //const valus= this.props.form.getFieldsValue();
        let returnValues
        this.props.form.validateFields((err,value) => {
            if(err){
                returnValues = false
            }else{
                returnValues = value
                returnValues = this.props.form.getFieldsValue()
            }
        })
        return returnValues;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                <Form.Item label="姓名">
                    {getFieldDecorator('name',{
                        rules: [{ required: true, message: '请输入姓名！' }],
                    })(
                        <Input placeholder="姓名"/>
                    )}
                </Form.Item>
                <Form.Item label="账号">
                    {getFieldDecorator('username',{
                        rules: [
                            { required: true, message: '请输入账号！' },
                            { pattern: /[0-9a-zA-Z]{2,20}$/, message: '请输入2-20位字母或数字！' }
                        ],
                    })(
                        <Input placeholder="账号"/>
                    )}
                </Form.Item>
                <Form.Item label="手机号">
                    {getFieldDecorator('mobile',{
                        rules: [ {pattern: /^((13|14|15|17|18)[0-9]{9})$/,message: '请正确输入手机号！'}],
                    })(
                        <Input placeholder="手机号"/>
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const AddAccountForm = Form.create()(AddAccount);

export default AddAccountForm;
