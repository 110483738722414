import React, { Component } from 'react'
import { Modal, Table, message, Icon } from 'antd'
import SearchForm from '../component/form/Search'   //搜索框组件
import Checkmodal from './../component/form/Checkmodal'    //Modal组件
import { homeCheckList, getHomeCheckInfo, checkPass, checkDel } from './../../../Mock'
import { getSoftTryList,getSoftTry,saveSoftTry,auditSoftTryFinish} from './../../../Api'
import { connect } from 'react-redux'
import utils from '../../../Common/js/utils'
import STATUS from './../../../Common/js/status'
import moment from 'moment'
//首页-试用申请-已处理Tab
class Check extends Component {
    state = {
        formList: [{
            name: "keyword",
            type: 'input',
            placeholder: '申请人/申请人电话/单位名称'
        }, {
            name: "tryState",
            type: 'select',
            placeholder: '请选择试用状态',
            optionList: [{
                value: 2,
                label: '试用中'
            }, {
                value: 3,
                label: '已忽略'
            }, {
                value: 4,
                label: '已关闭'
            }]
        }],
        //初始化Modal组件
        formModal: {
            title: '开始试用',
            list: []
        },
        page: {
            pageNum: 1,
            pageSize: 30
        }
    }
    componentDidMount(){
        this.props.onRef(this)
    }
    //通过Api获取表格数据
    getList = (params) => {
        this.setState({
            params:params
        })
        let data = {  ...params,...this.state.page }
        let _this = this
        getSoftTryList(data,-1).then(res => {
            console.log(res)
            if (res.data.state.value == 0) {
                //为表格数据加上唯一Key
                res.data.result.rows&&res.data.result.rows.map(item => {
                    item.key = item.id
                    return item
                })
                this.setState({
                    tableList: res.data.result.rows?res.data.result.rows:[],
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        _this.getList(params)
                    }, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        _this.getList(params)
                    }, res.data.result.pageSize)
                })
            }
        

        })
    }
    //挂载前获取表格数据
    componentWillMount() {
        this.getList()
    }
    //通过Api关闭申请
    handleClose = (record) => {
        let _this = this
        Modal.confirm({
            title: '提示',
            content: '确定要关闭吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                auditSoftTryFinish({ id: record.id }).then(res => {
                    console.log(res)
                    if (res.data.state.value == 0) {
                        message.success('操作成功！')
                        _this.getList(_this.state.params)
                    }
                })
            },
        })
    }
    //Modal-Form弹窗根据状态设定Modal内容
    handleOpenCheck = (record) => {
        this.setState({
            currentId:record.id
        })
        switch (record.tryState) {
            case 4:
                //Modal内容需要通过Api先获取后再显示
                getSoftTry({ id: record.id }).then(res => {
                    console.log(res)
                    if (res.data.state.value == 0) {
                        this.setState({
                            formModal: {
                                title: '已关闭-处理细节',
                                list: [{
                                    name: "createDate",
                                    label: "开始时间",
                                    type: 'original',
                                    value: res.data.result.createDate
                                }, {
                                    name: "applyName",
                                    label: "开始人",
                                    type: 'original',
                                    value: res.data.result.checkAdminName
                                },
                                {
                                    name: "checkDatetime",
                                    label: "关闭时间",
                                    type: 'original',
                                    value: res.data.result.checkDatetime
                                }, {
                                    name: "closeAdmin",
                                    label: "关闭人",
                                    type: 'original',
                                    value: res.data.result.closeAdmin
                                }, {
                                    name: "remark",
                                    label: "备注",
                                    type: 'textArea',
                                    initial: res.data.result.remark
                                },]
                            }
                        }, function () {
                            this.setState({
                                visibleCheckModal: true,
                                width: null,  //设定弹框宽度（null为默认）
                                //设定弹框内布局
                                formItemLayout: {
                                    labelCol: {
                                        xs: 24,
                                        sm: 4
                                    },
                                    wrapperCol: {
                                        xs: 24,
                                        sm: 20
                                    }
                                }
                            })
                        })
                    }
                })
                break;
            case 2:
                getSoftTry({ id: record.id }).then(res => {
                    console.log(res)
                    if (res.data.state.value == 0) {
                        this.setState({
                            formModal: {
                                title: '试用中-处理细节',
                                list: [{
                                    name: "checkDatetime",
                                    label: "开始时间",
                                    type: 'original',
                                    value: res.data.result.checkDatetime
                                }, {
                                    name: "checkAdminName",
                                    label: "开始人",
                                    type: 'original',
                                    value: res.data.result.checkAdminName
                                }, {
                                    name: "remindExpireDate",
                                    label: "试用到期时间",
                                    type: 'editTime',
                                    value: res.data.result.remindExpireDate,
                                    placeholder: '请选择时间'
                                }, {
                                    name: "remark",
                                    label: "备注",
                                    type: 'textArea',
                                    initial: res.data.result.remark
                                },]
                            }
                        }, function () {
                            this.setState({
                                visibleCheckModal: true,
                                formItemLayout: {
                                    labelCol: {
                                        xs: 24,
                                        sm: 4
                                    },
                                    wrapperCol: {
                                        xs: 24,
                                        sm: 20
                                    }
                                },
                                width: 600
                            })
                        })
                    }
                })
                break;
            case 3:
                getSoftTry({ id: record.id }).then(res => {
                    console.log(res)
                    if (res.data.state.value == 0) {
                        this.setState({
                            formModal: {
                                title: '已忽略-处理细节',
                                list: [{
                                    name: "passNoDatetime",
                                    label: "忽略时间",
                                    type: 'original',
                                    value: res.data.result.passNoDatetime
                                }, {
                                    name: "passNoAdmin",
                                    label: "忽略人",
                                    type: 'original',
                                    value: res.data.result.passNoAdmin
                                }, {
                                    name: "remark",
                                    label: "备注",
                                    type: 'textArea',
                                    initial: res.data.result.remark
                                },]
                            }
                        }, function () {
                            this.setState({
                                visibleCheckModal: true,
                                width: null,
                                formItemLayout: {
                                    labelCol: {
                                        xs: 24,
                                        sm: 4
                                    },
                                    wrapperCol: {
                                        xs: 24,
                                        sm: 20
                                    }
                                }
                            })
                        })
                    }
                })
                break;
            default:
                break
        }

    }
    //更改弹框的显示状态
    handleVisibleChange = () => {
        this.setState({
            visibleCheckModal: !this.state.visibleCheckModal
        })
    }
    //弹框的“确认”回调 需返回Promise对象
    handleCheck = (data) => {
        data.id = this.state.currentId
        data.remindExpireDate = moment(data.remindExpireDate).format('YYYY-MM-DD HH:mm:ss')
        console.log(data,"data")
        return saveSoftTry(data).then((res) => {
            console.log(res)
            return new Promise((resolve, reject) => {
                if (res.data.state.value == 0) {
                    Modal.success({
                        content: '操作成功'
                    })
                    this.setState({
                        visibleCheckModal: false
                    })
                    this.getList(this.state.params)                    
                    resolve(true)
                } else {
                    reject(false)
                }
            })
        })
    }
    render() {
        let _this = this
        //表格列定义
        const columns = [
            {
                title: "申请人姓名",
                dataIndex: 'applyName',
                align: 'center',
                width: 100,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "申请人电话",
                dataIndex: 'applyMobile',
                align: 'center',
                width: 80,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "所在单位",
                dataIndex: 'cname',
                align: 'center',
                width: 80,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "试用产品",
                dataIndex: 'softName',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "途径",
                dataIndex: 'method',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            },
            {
                title: "试用状态",
                dataIndex: 'tryStateName',
                align: 'center',
                width: 30,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            },
            {
                title: "申请时间",
                dataIndex: 'createDate',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "操作",
                align: 'center',
                width: 50,
                render(text, record) {
                    return <div>
                        <a  onClick={() => _this.handleOpenCheck(record)}><Icon type="eye" title="查看" className="mr10 table-icon-blue"/></a>
                        {
                            record.tryStateName == '试用中' ? <a  onClick={() => _this.handleClose(record)}><Icon type="close" className="table-icon-red" title="关闭"/></a>: null
                        }
                    </div>
                },

            }
        ]
        return (
            <div className="home">
                <SearchForm formList={this.state.formList} formSearchApi={(data) => this.getList(data)} />
                <Table
                    className="mt20"
                    bordered
                    columns={columns}
                    dataSource={this.state.tableList}
                    pagination={{ ...this.state.pagination, pageSize: this.state.page.pageSize }}
                />
                <Checkmodal width={this.state.width} formItemLayout={this.state.formItemLayout} formSubmitApi={(data) => this.handleCheck(data)} handleVisibleChange={this.handleVisibleChange} visibleCheckModal={this.state.visibleCheckModal} formList={this.state.formModal} />
            </div>
        )
    }
}

export default connect()(Check);