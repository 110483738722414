import React, { Component } from 'react'

class SystemList extends Component {
    render() {
        return (
            <div>系统管理</div>
        )
    }
}

export default SystemList;