import React, {Component} from 'react'
import MenuConfig from './../../Common/js/menuConfig'
import { NavLink } from 'react-router-dom'
import { Menu, Icon } from 'antd';
const { SubMenu } = Menu;

class NavLeft extends Component {
    state = {
        currentKey: '',
        collapsed: false,
    }
    componentWillMount() {
        const menuTreeNode = this.renderMenu(MenuConfig)
        let currentKey = [window.location.hash.replace(/#|\?.+$/g, '')]
        this.setState({
            currentKey,
            menuTreeNode
        })
    }

    handleClick = ({ item, key }) => {
        this.setState({
            currentKey: [key]
        })
    }
    // 菜单栏递归渲染
    renderMenu = (data) => {
        return data.map((item) => {
            if (!item.sideHide) {
                if (item.children && item.children.filter(item=>!item.sideHide).length) {
                    let navName = <div>{item.icon?<Icon type={item.icon} />:null}<span>{item.title}</span></div>
                    return (
                        <SubMenu title={navName} key={item.key}>
                            {this.renderMenu(item.children)}
                        </SubMenu>
                    )
                }
                return <Menu.Item title={item.title} key={item.key}>
                    <NavLink to={item.key}>{item.icon?<Icon type={item.icon} />:null}<span>{item.title}</span></NavLink>
                </Menu.Item>
            }
        })
    }
    render() {
        return (
            <div>
                <Menu
                    className="leftfixed menu-scorll"
                    // mode控制菜单的展示方式
                    mode="inline"
                    onClick={this.handleClick}
                    theme="dark"
                    selectedKeys={this.state.currentKey}
                >
                    {this.state.menuTreeNode}
                </Menu>
            </div>
        );
    }
}

export default NavLeft;