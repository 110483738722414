import React, { Component } from 'react'
import {Card,Modal} from 'antd'
import {getSoftAdjust,getSoftBuy} from './../../../../Api/index'
import moment from 'moment'

import imgUrl from './../../../../Common/images/zyjkqy.png'
import SoftwareOne from './../Common/SoftwareOne'//购买职业健康软件
import Adjust from './../Common/Adjust'//调整软件属性
let ids
class HealthBasic extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        visible: false,
        adjustvisible:false,
        healthBasic: this.props.healthBasic,
        OrderList:[],//列表数据
        pageNum:1,
        pageSize:10,
    };

    // componentWillMount() {
    //     this.requestBasic();
    // }

    //购买软件弹出显示状态
    showModalSoft = () => {
        this.setState({
            visible: true,
        });
    };
    //购买软件弹出框确定按钮操作
    softwareOk = () => {
        this.setState({ loading: true });
        // this.getBuyRequest();
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        let formdata = this.formRef.getItemsValue();
        if(
            formdata.comboId !== '' && formdata.comboId !== undefined && 
            formdata.payCount !== '' && formdata.payCount !== undefined && 
            formdata.comboSpaceCount !== '' && formdata.comboSpaceCount !== undefined && 
            formdata.type !== '' && formdata.type !== undefined 
        ){
            this.setState({ loading: false, visible: false });
            this.getBuyRequest(formdata);  
            this.formRef.resetValue(); 
        }else{
            this.setState({ loading: true, visible: true });
        }
    };
    //购买软件弹出框取消按钮操作
    softwareCancel = () => {
        this.setState({ visible: false });
        this.formRef.resetValue(); 
    };
    //购买软件请求接口
    getBuyRequest = (data) => {
        //console.log(this.state.cid);
        getSoftBuy({
            cid:this.state.cid,
            softId:1,
            comboId:data.comboId,
            payCount:data.payCount,
            comboSpaceCount:data.comboSpaceCount,
            type:data.type,
            promocode:data.promocode,
            payCost:data.payCost,
            tradeNo:data.tradeNo,
            remark:data.remark
        }).then((res)=>{
            //console.log("fnOrderfnOrderfnOrderfnOrderfnOrder");
            this.props.requestBasic(1);
            this.props.fnOrder();
            //this.RequestOrderList(1);
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //调整属性弹出显示状态
    showModalAdjust = () => {
        this.setState({
            adjustvisible: true,
        });
    };
    //调整属性弹出框确定按钮操作
    adjustOk = () => {
        this.setState({ loading: true });
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        //this.setState({ loading: false, adjustvisible: false });
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, adjustvisible: false });
        this.getSoftAdjustRegister(formdata);
    };
    //调整属性弹出框取消按钮操作
    adjustCancel = () => {
        this.setState({ adjustvisible: false });
        this.formRef.resetValue(); 
    };
    componentWillReceiveProps(nextProps){
        ids = nextProps.healthBasic
        //console.log(nextProps);
    }
    //调整接口
    getSoftAdjustRegister = (data) => {
        //console.log(data);
        //console.log(ids,this.state.healthBasic.corpSoftId,"==============");
        getSoftAdjust({
            //corpSoftId:this.state.healthBasic.corpSoftId,
            corpSoftId:ids ? ids.corpSoftId : this.state.healthBasic.corpSoftId,
            newSpaceTotal:data.comboSpaceCount,
            newEdate:moment(data.dueTime).format("YYYY-MM-DD"),
            remark:data.remark
        }).then((res)=>{
            //console.log("1111111111");
            this.props.requestBasic(1);
            this.props.fnRecord();
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    render() {
        const healthBasic = this.props.healthBasic;
        //console.log(healthBasic,"qqqqqqqqqqqqqqqqqq");
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="基本信息">
                    <div className="HealthBasicPicture">
                        <img alt="职业健康企业版" src={imgUrl} />
                        <ul>
                            <li>
                                <div className="title">软件名称：</div>
                                <div className="neire">{healthBasic.softName}</div>
                            </li>
                            <li>
                                <div className="title">当前套餐类型：</div>
                                <div className="neire">{healthBasic.comboName}</div>
                            </li>
                            <li>
                                <a className="red" onClick={this.showModalSoft}>【购买】</a>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div className="title">空间数：</div>
                                <div className="neire">{healthBasic.comboSpaceCount} GB</div>
                            </li>
                            <li>
                                <div className="title">到期时间：</div>
                                <div className="neire">{healthBasic.dueTime}</div>
                            </li>
                            <li>
                                <a className="blue" onClick={()=>this.showModalAdjust(healthBasic)}>【调整】</a>
                            </li>
                        </ul>
                    </div>
                </Card> 
                {/* 购买软件弹出 */}
                <Modal
                width={620}
                visible={this.state.visible}
                loading={this.state.loading}
                title="创建购买订单"
                onOk={this.softwareOk}
                onCancel={this.softwareCancel}
                okText="确定"
                cancelText="取消"
                >
                    <SoftwareOne
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
                {/* 调整软件属性弹出 */}
                <Modal
                width={620}
                visible={this.state.adjustvisible}
                loading={this.state.loading}
                title="调整软件属性"
                onOk={this.adjustOk}
                onCancel={this.adjustCancel}
                okText="确定"
                cancelText="取消"
                >
                    <Adjust healthBasic={healthBasic}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
            </div>
        )
    }
}

export default HealthBasic;