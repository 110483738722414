import React, { Component } from 'react'
import {Card,Modal,Table,Pagination} from 'antd'
import {getOrderList,getOrderDealWith,getOrderView,getOrderView2} from './../../../../Api/index'
import utils from './../../../../Common/js/utils'
import OrderManage from './../Common/OrderManage'//订单状态未处理弹出组件
import OrderDetailOpen from './../Common/OrderDetailOpen'//订单类型为开通弹出组件
import OrderDetailBuy from './../Common/OrderDetailBuy'//订单类型为购买弹出组件
import OrderDetailRenewal from './../Common/OrderDetailRenewal'//订单类型为续费弹出组件

class RadiaOrder extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        untreated:false, //订单状态未处理弹出框的状态 false隐藏 true显示
        openOrderType:false,//订单类型开通弹出框的状态 false隐藏 true显示
        buyOrderType:false, //订单类型购买弹出框的状态 false隐藏 true显示
        renewalOrderType:false, //订单类型续费弹出框的状态 false隐藏 true显示
        OrderList:[],//列表数据
        pageNum:1,
        pageSize:10,
    };
    componentWillMount() {
        this.RequestOrderList();
    }
    //列表数据请求接口
    RequestOrderList = (types) => {
        //console.log(types,"typestypestypestypestypestypestypestypestypestypestypes");
        let _this = this
        getOrderList({
            cid:this.state.cid,
            softId:2,
            pageNum:types ? types : this.state.pageNum,
            pageSize:this.state.pageSize
        }).then((res)=>{
            //console.log(res,"Order职业健康职业健康职业健康");
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total:res.data.result.total,
                    OrderList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    },(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.RequestOrderList()
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //////////////////////////////////////////////// 订单状态为未处理时
    //订单状态为未处理时 弹出内容 显示状态
    untreatedshowModal = (record) => {
        this.setState({
            untreated: true,
            record:record
        });
        this.RequestOrderView2(record);
    };
    //订单状态为未处理时 弹出框 确定按钮 操作
    untreatedOk = () => {
        this.setState({ loading: true });
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, untreated: false });
        this.RequestOrderDealWith(formdata);
    };
    //订单状态为未处理时 弹出框 取消按钮 操作
    untreatedCancel = () => {
        this.setState({ untreated: false });
        this.formRef.resetValue(); 
    };
    //订单状态为未处理 请求接口
    RequestOrderDealWith = (data) => {
        //console.log(data,"未处理未处理");
        getOrderDealWith({
            id:data.id,
            oderTypeName:data.oderTypeName,
            typeName:data.typeName,
            packageName:data.packageName,
            payCount:data.payCount,
            createDate:data.createDate,
            promocode:data.promocode,
            payCost:data.payCost,
            tradeNo:data.tradeNo,
            state:data.state,
            remark:data.remark
        }).then((res)=>{
            //console.log(res,"未处理");
            if(res.status === 200){
                this.RequestOrderList();
            }
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //////////////////////////////////////////////// 订单类型为开通时
    //订单类型为开通时 弹出内容 显示状态
    openshowModal = (record) => {
        this.setState({
            openOrderType: true,
            record:record
        });
        this.RequestOrderView2(record);
    };
    //订单类型为开通时 弹出框 确定按钮 操作
    openOrderTypeOk = () => {
        this.setState({ loading: true });
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, openOrderType: false });
        this.RequestOrderView(formdata);
        this.formRef.resetValue(); 
    };
    //订单类型为开通时 弹出框 取消按钮 操作
    openOrderTypeCancel = () => {
        this.setState({ openOrderType: false });
        this.formRef.resetValue(); 
    };

    //////////////////////////////////////////////// 订单类型为购买时
    //订单类型为购买时 弹出内容 显示状态
    buyshowModal = (record) => {
        this.setState({
            buyOrderType: true,
            record:record
        });
        this.RequestOrderView2(record);
    };
    //订单类型为购买时 弹出框 确定按钮 操作
    buyOrderTypeOk = () => {
        this.setState({ loading: true });
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, buyOrderType: false });
        this.RequestOrderView(formdata);
        this.formRef.resetValue(); 
    };
    //订单类型为购买时 弹出框 取消按钮 操作
    buyOrderTypeCancel = () => {
        this.setState({ buyOrderType: false });
        this.formRef.resetValue(); 
    };

    //////////////////////////////////////////////// 订单类型为续费时
    //订单类型为续费时 弹出内容 显示状态
    renewalshowModal = (record) => {
        this.setState({
            renewalOrderType: true,
            record:record
        });
        this.RequestOrderView2(record);
    };
    //订单类型为续费时 弹出框 确定按钮 操作
    renewalOrderTypeOk = () => {
        this.setState({ loading: true });
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        let formdata = this.formRef.getItemsValue();
        this.setState({ loading: false, renewalOrderType: false });
        this.RequestOrderView(formdata);
        this.formRef.resetValue(); 
    };
    //订单类型为续费时 弹出框 取消按钮 操作
    renewalOrderTypeCancel = () => {
        this.setState({ renewalOrderType: false });
        this.formRef.resetValue(); 
    };

    //订单查看-查看 请求接口
    RequestOrderView2 = (data) => {
        //console.log(data.id,"rrrrrrrrrrrrrrrrrrrrrr");
        getOrderView2({
            id:data.id,
        }).then((res)=>{
            //console.log(res,"查看1111");
            //this.RequestOrderList(1);
            this.setState({
                record:res.data.result
            });
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //订单查看-提交 请求接口
    RequestOrderView = (data) => {
        getOrderView({
            id:data.id,
            oderTypeName:data.oderTypeName,
            typeName:data.typeName,
            packageName:data.packageName,
            payCount:data.payCount,
            comboSpaceCount:data.comboSpaceCount,
            createDate:data.createDate,
            checkDate:data.checkDate,
            checkResult:data.checkResult,
            adminName:data.adminName,
            
            promocode:data.promocode,
            payCost:data.payCost,
            tradeNo:data.tradeNo,
            state:data.state,
            remark:data.remark,
        }).then((res)=>{
            //console.log(res,"查看");
            this.RequestOrderList(1);
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    render() {
        //表格 表头
        const columns = [
            {title: '订单号',dataIndex: 'id',align:'center'},
            {title: '订单类型',dataIndex: 'oderTypeName',align:'center'},
            // {title: '创建类型',dataIndex: 'createType',align:'center'},
            {title: '付费类型',dataIndex: 'typeName',align:'center'},
            {title: '套餐类型',dataIndex: 'packageName',align:'center'},
            {title: '状态',dataIndex: 'state',align:'center',
                render: (text,record) => {
                    //开通record.orderType === 1
                    //购买record.orderType === 2
                    //续费record.orderType === 3
                    if(record.orderType === 3){
                        //待审核record.state === 0
                        //通过record.state === 1
                        //不通过record.state === 2
                        if(record.state === 0){//a.当订单类型为续费 状态为待审核时
                            return (
                                <div className="red">
                                    未处理
                                </div>
                            )
                        }else if(record.state === 1){//b.当订单类型为续费 状态为通过时
                            return (
                                <div className="green">
                                    通过
                                </div>
                            )
                        }else if(record.state === 2){//c.当订单类型为续费 状态为不通过时
                            return (
                                <div className="gary">
                                    不通过
                                </div>
                            )
                        }
                    }else{
                        return (
                            <div>
                                /
                            </div>
                        )
                    }
                }
            },
            {title: '提交时间',dataIndex: 'createDate',align:'center'},
            {title: '处理时间',dataIndex: 'checkDate',align:'center'},
            {title: '操作',dataIndex: 'operation',align:'center',
                render: (text,record) => {
                    if(record.state === 0) {//a.状态为待审核时
                        return (
                            <div>
                                <a className="red mlr5" onClick={() => this.untreatedshowModal(record)}>处理</a>
                            </div>
                        )
                    }else{
                        //开通record.orderType === 1
                        //购买record.orderType === 2
                        //续费record.orderType === 3
                        if(record.orderType === 1){//a.订单类型为开通时
                            return (
                                <div>
                                    <a className="blue mlr5" onClick={() => this.openshowModal(record)}>查看</a>
                                </div>
                            ) 
                        }else if(record.orderType === 2){//b.订单类型为购买时
                            return (
                                <div>
                                    <a className="blue mlr5" onClick={() => this.buyshowModal(record)}>查看</a>
                                </div>
                            ) 
                        }else if(record.orderType === 3){//c.订单类型为续费时
                            return (
                                <div>
                                    <a className="blue mlr5" onClick={() => this.renewalshowModal(record)}>查看</a>
                                </div>
                            ) 
                        }
                    }
                }
            }
        ];
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="订单信息">
                     <Table bordered columns={columns} dataSource={this.state.OrderList} pagination={false} />
                     <Pagination {...this.state.pagination} className="fr margin10" />
                </Card>

                {/* 当表格的订单状态为未处理时 弹出页面 */}
                <Modal
                width={620}
                visible={this.state.untreated}
                loading={this.state.loading}
                title="订单处理"
                onOk={this.untreatedOk}
                onCancel={this.untreatedCancel}
                okText="确定"
                cancelText="取消"
                >
                    <OrderManage record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 当表格的订单类型为开通时 弹出页面 */}
                <Modal
                width={700}
                visible={this.state.openOrderType}
                loading={this.state.loading}
                title="订单详情"
                onOk={this.openOrderTypeOk}
                onCancel={this.openOrderTypeCancel}
                okText="确定"
                cancelText="取消"
                >
                    <OrderDetailOpen record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 当表格的订单类型为购买时 弹出页面 */}
                <Modal
                width={700}
                visible={this.state.buyOrderType}
                loading={this.state.loading}
                title="订单详情"
                onOk={this.buyOrderTypeOk}
                onCancel={this.buyOrderTypeCancel}
                okText="确定"
                cancelText="取消"
                >
                    <OrderDetailBuy record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 当表格的订单类型为续费时 弹出页面 */}
                <Modal
                width={700}
                visible={this.state.renewalOrderType}
                loading={this.state.loading}
                title="订单详情"
                onOk={this.renewalOrderTypeOk}
                onCancel={this.renewalOrderTypeCancel}
                okText="确定"
                cancelText="取消"
                >
                    <OrderDetailRenewal record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
 
            </div>
        )
    }
}

export default RadiaOrder;