import React, { Component } from 'react'
import {connect} from 'react-redux'
import { Form, Icon, Input, Button } from 'antd'
import {getUserDataAction} from './../../Store/actionCreators'
import md5 from 'md5'
import './../../Common/css/login.css'


class Login extends Component {
    //状态
    constructor(props){
        super(props);
        this.state={
            username: '',
            password: ''
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="login">
                <div className="login-wrap">
                    <h1>运营后台管理系统</h1>
                    <Form className="login-form">
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [
                                    { required: true, message: '请输入您的用户名!' }
                                ],
                            })(
                                <Input
                                name="username"
                                prefix={<Icon type="user" />}
                                placeholder="用户名"
                                onChange={e=>this._onInputChange(e)}
                                onKeyUp={e=>this._onInputKeyUp(e)}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [
                                    { required: true, message: '请输入密码！' },
                                    { min:6, message:'长度不在范围内，需6位以上'},
                                    {pattern:/^\w+$/g,message:'必须为字母或数字'}
                                ],
                            })(
                                <Input
                                name="password"
                                prefix={<Icon type="lock" />}
                                type="password"
                                placeholder="密码"
                                onChange={e=>this._onInputChange(e)}
                                onKeyUp={e=>this._onInputKeyUp(e)}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item> 
                            <Button type="primary" onClick={e => this.handleSubmit(e)} className="login-form-button">
                                登 录
                            </Button>
                        </Form.Item>
                    </Form>
                
                </div>
            </div>
        )
    }

    //当输入框内容发生改变时
    _onInputChange = (e) => {
        //获取数据
        let inputValue = e.target.value,
            inputName = e.target.name;
        //console.log(inputValue,"value");
        //console.log(inputName,"name");
        //更新数据
        this.setState({
            [inputName] : inputValue
        })
        //console.log(this.state);
    }

    //处理回车事件
    _onInputKeyUp = (e) => {
        if(e.keyCode === 13){
            this.handleSubmit();
        }
    }

    //表单提交
    handleSubmit = () => {
        //获取数据
        const {username,password} = this.state;
        //密码加密
        const md5_password = md5(password);
        
        //发起网络请求
        this.props.reqLogin({
            username,
            password:md5_password
        },(userData) => {
            localStorage.setItem('token',userData)
            if(userData !== ''){
                this.props.history.push('/home');
                this.props.history.go()
            }else{
                this.props.history.push('/');
            }
        })
    };

}

const mapDispatchToProps = (dispatch) => {
    return {
        reqLogin(data, callback){
            const action = getUserDataAction(data, callback);
            dispatch(action);
        }
    }
}

const LoginForm = Form.create()(Login);

export default connect(null, mapDispatchToProps)(LoginForm);