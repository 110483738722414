/**
 * @file 页面：活动设置->管理公告
 * @author Sim <sim@snorl.ax>
 */
import React, { Component } from 'react'
import { Form, DatePicker, Input, Button, Table, Icon, Popconfirm, Tabs } from 'antd'
import {Link} from 'react-router-dom'
import { getPlatformNoticeListData, delSysNotice, getPlatformNoticeListData2, delHaNotice } from '../../Api'
import { blue, red } from '@ant-design/colors';
import { parseDate2, pagination, statusMessage } from '../Training/Questions/features'

const {RangePicker} = DatePicker, { TabPane } = Tabs, parseDate = parseDate2

/**
 * @class
 * @extends {React.Component}
 */
class ActivityManagementForm extends Component {
    /**
     * 页面状态储值区域
     * @type {object}
     */
    state = {
        pageNum: 1,
        pageSize: 10,
        pageNum2: 1,
        pageSize2: 10,
        searchParams: {},
        searchParams2: {},
    }
    /**
     * 处理当前数据行表格列数据的并最终返回渲染值的函数
     * @typedef {Function} tableColumnRenderFunction
     * @param {String} text 当前数据行对应的该表格列的值
     * @param {Object} record 当前数据行
     * @returns {Number | String | HTMLDocument | HTMLCollection}
     */
    /**
     * 表格列
     * @typedef {Object} tableColumn
     * @property {String} title 表头标题
     * @property {String} dataIndex 表头取的字段名称
     * @property {String} key 表头的key值 
     * @property {tableColumnRenderFunction} render 
     */
    /**
     * 表格列数据
     * @type {Function}
     * @param {Number} tableId 表格ID，0-平台，1-健康顾问
     * @return {Array.<tableColumn>}
     */
    columns = tableId => [
            {
                title: '公告标题',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: '点击数',
                dataIndex: 'clickCount',
                key: 'clickCount'
            },
            {
                title: '发布时间',
                dataIndex: 'createDate',
                key: 'createDate'
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <div style={{fontSize:'1.2em'}}>                  
                        <Link to={{pathname: `/activity/management/edit/${tableId}/${record.id}`, state: {...record, tableId}}} title={'编辑'}><Icon type={'edit'} className={"mr10"} theme='twoTone' twoToneColor={blue[5]} /></Link>
                        <Popconfirm
                            title="是否删除公告？"
                            icon={<Icon type="question-circle-o" style={{ color: red[5] }} />}
                            onConfirm={()=>{tableId == 0 ? this.deleteFromList(record.id) : this.deleteFromList2(record.id)}}
                        >
                            <Icon type={'delete'} theme='twoTone' twoToneColor={red[5]} title={'删除'} />
                        </Popconfirm>
                    </div>
                )
            }
        ]

    /**
     * 页面载入后执行的函数
     * @type {Function}
     */
    componentDidMount() {
        Promise.all([new Promise(resolve => {
            document.getElementById('ajaxLoading').style.display = 'block'
            resolve(document.getElementById('ajaxLoading').style.display)
        }), this.requestTableList(), this.requestTableList2()]).then(values => {
            document.getElementById('ajaxLoading').style.display = 'none'            
            // console.log(values)
            if (this.props.location.state) {
                switch (this.props.location.state.action) {
                    case 'add':
                    case 'edit':
                        this.setState({tableId: this.props.location.state.tableId})
                        console.log(this.state)
                        statusMessage()
                        break
                    default:
                        break
                }
            }
        }).catch(reason => {
            statusMessage(reason)
            document.getElementById('ajaxLoading').style.display = 'none'
        })
    }

    /**
     * 请求表格数据的回调函数
     * @type {Function}
     * @param {Object} res 返回的数据
     * @param {Number} pageNum 页码
     * @param {String} pageNumTitle 页码在this.state中对应的字段
     * @param {Number} pageSize 每页记录数
     * @param {String} pageSizeTitle 每页记录数在this.state中对应的字段
     * @param {String} totalTitle 总记录数在this.state中对应的字段
     * @param {String} activityManagementData 当前记录在this.state中对应的字段
     * @param {Function} resolve 成功后的回调函数
     */
    requestCallback = (res, pageNum, pageNumTitle, pageSize, pageSizeTitle, totalTitle, activityManagementData, resolve) => {
        if (res.status === 200 && res.data.state.value == 0){
            const rows = res.data.result.rows.map(item => ({...item, key: item.id+activityManagementData}))
            this.setState({
                [totalTitle]: Number(res.data.result.records),
                [pageNumTitle]: pageNum,
                [pageSizeTitle]: pageSize,
                [activityManagementData]: rows
            })
            if (resolve) {
                resolve()
            }
        }
    }

    /**
     * 请求平台的公告表格数据
     * @type {Function}
     * @param {Number} pageNum 页码
     * @param {Number} pageSize 每页记录数
     * @param {Object} finalValue 搜索的条件
     * @return {Promise}
     */
    requestTableList = (pageNum, pageSize, finalValue) => new Promise((resolve, reject) => {
            const action = finalValue ? 'search' : 'get'
            pageNum = pageNum ? pageNum : this.state.pageNum
            pageSize = pageSize ? pageSize : this.state.pageSize
            finalValue = finalValue ? finalValue : this.state.searchParams
            getPlatformNoticeListData({ pageNum, pageSize, ...finalValue }).then((res)=>{
                console.log(res, action)
                this.setState({searchParams: finalValue})
                this.requestCallback(res, pageNum, 'pageNum', pageSize, 'pageSize', 'total', 'activityManagementData', resolve)
            }, reason => {
                reject(reason)
            })
        })

    /**
     * 请求健康顾问的公告表格数据
     * @type {Function}
     * @param {Number} pageNum 页码
     * @param {Number} pageSize 每页记录数
     * @param {Object} finalValue 搜索的条件
     * @return {Promise}
     */
    requestTableList2 = (pageNum, pageSize, finalValue) => new Promise((resolve, reject) => {
            const action = finalValue ? 'search' : 'get'
            pageNum = pageNum ? pageNum : this.state.pageNum
            pageSize = pageSize ? pageSize : this.state.pageSize
            finalValue = finalValue ? finalValue : this.state.searchParams2
            getPlatformNoticeListData2({ pageNum, pageSize, ...finalValue }).then((res)=>{
                console.log(res, action)
                this.setState({searchParams2: finalValue})
                this.requestCallback(res, pageNum, 'pageNum2', pageSize, 'pageSize2', 'total2', 'activityManagementData2', resolve)
            }, reason => {
                reject(reason)
            }).catch(reason => {
                reject(reason)
            })
        })

    /**
     * 删除平台的指定的一行公告表格数据
     * @type {Function}
     * @param {String} id 指定行的id
     */
    deleteFromList = (id) =>{
        delSysNotice({id}).then((res) => {
            console.log(res,"delete");
            if(res.status === 200 && res.data.state.value == 0){
                this.requestTableList().then(() => {
                    document.getElementById('ajaxLoading').style.display = 'none'
                    statusMessage()
                }, reason => {
                    statusMessage(reason)
                })
            }
        })
    }

    /**
     * 删除健康顾问的指定的一行公告表格数据
     * @type {Function}
     * @param {String} id 指定行的id
     */
    deleteFromList2 = (id) =>{
        delHaNotice({id}).then((res) => {
            console.log(res,"delete");
            if(res.status === 200 && res.data.state.value == 0){
                this.requestTableList2().then(() => {
                    document.getElementById('ajaxLoading').style.display = 'none'
                    statusMessage()
                }, reason => {
                    statusMessage(reason)
                })
            }
        })
    }

    /**
     * 搜索平台的公告表格数据
     * @type {Function}
     * @param {Object} value 搜索条件
     */
    searchTableList = (value)=>{
        const { title, time } = value, finalValue = {keyword: title ? title: '', startDate: time && time.length > 0 ? parseDate(time[0])+' 00:00:00' : '', endDate: time && time.length > 0 ? parseDate(time[1])+' 23:59:59' : ''}
        document.getElementById('ajaxLoading').style.display = 'block'
        this.requestTableList(1, null, finalValue).then(() => {
            document.getElementById('ajaxLoading').style.display = 'none'
            statusMessage()
        }, reason => {
            statusMessage(reason)
        })
    }

    /**
     * 搜索健康顾问的公告表格数据
     * @type {Function}
     * @param {Object} value 搜索条件
     */
    searchTableList2 = (value)=>{
        const { title1, time1 } = value, finalValue = {keyword: title1 ? title1: '', beginDate: time1 && time1.length > 0 ? parseDate(time1[0])+' 00:00:00' : '', endDate: time1 && time1.length > 0 ? parseDate(time1[1])+' 23:59:59' : ''}
        document.getElementById('ajaxLoading').style.display = 'block'        
        this.requestTableList2(1, null, finalValue).then(() => {
            document.getElementById('ajaxLoading').style.display = 'none'
            statusMessage()
        }, reason => {
            statusMessage(reason)
        })
    }

    /**
     * 提交后事件（平台）
     * @type {Function}
     * @param {Event} e 提交按钮事件 
     */
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            this.searchTableList(values)
          }
        });
      };

    /**
     * 提交后事件（健康顾问）
     * @type {Function}
     * @param {Event} e 提交按钮事件 
     */
    handleSubmit2 = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
            console.log('Received values of form: ', values);
            this.searchTableList2(values)
            }
        });
    };
    
    /**
     * 平台内容
     * @type {Function}
     * @return {HTMLElement} 渲染结果
     */
    tab1 = () => <div>
            <Form layout="inline">
                <Form.Item label={'公告标题：'}>
                    {this.props.form.getFieldDecorator(`title`)(<Input placeholder="请输入公告标题" />)}
                </Form.Item>
                <Form.Item label={'发布时间：'}>
                    {this.props.form.getFieldDecorator(`time`)(
                    <RangePicker />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={this.handleSubmit}>搜索</Button>
                </Form.Item>
            </Form>
            <Table
                dataSource={this.state.activityManagementData}
                pagination={pagination(this.state.pageSize, this.state.pageNum, this.state.total, (page, size) => {this.requestTableList(page, size).then(()=>{}, reason => {statusMessage(reason)})})}
                bordered
                className="mt20"
                columns={this.columns(0)}
                footer={()=>{
                    return <Link to={{pathname: '/activity/management/add', state: {tableId: '0'}}}><Icon type={"plus-circle"} className='f20' theme={"filled"} /></Link>
                }}>
            </Table></div>
    
    /**
     * 健康顾问内容
     * @type {Function}
     * @return {HTMLElement} 渲染结果
     */
    tab2 = () => <div>
        <Form layout="inline">
            <Form.Item label={'公告标题：'}>
                {this.props.form.getFieldDecorator(`title1`)(<Input placeholder="请输入公告标题" />)}
            </Form.Item>
            <Form.Item label={'发布时间：'}>
                {this.props.form.getFieldDecorator(`time1`)(
                <RangePicker />
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={this.handleSubmit2}>搜索</Button>
            </Form.Item>
        </Form>
        <Table
            dataSource={this.state.activityManagementData2}
            pagination={pagination(this.state.pageSize2, this.state.pageNum2, this.state.total2, (page, size) => {this.requestTableList2(page, size).then(()=>{}, reason => {statusMessage(reason)})})}
            bordered
            className="mt20"
            columns={this.columns(1)}
            footer={()=>{
                return <Link to={{pathname: '/activity/management/add', state: {tableId: '1'}}}><Icon type={"plus-circle"} className='f20' theme={"filled"} /></Link>
            }}>
        </Table></div>

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => (
            <div>
                <Tabs defaultActiveKey={this.props.location.state && this.props.location.state.tableId ? this.props.location.state.tableId : '0'}>
                    <TabPane tab="平台" key='0'>
                        {this.tab1()}
                    </TabPane>
                    <TabPane tab="健康顾问" key='1'>
                        {this.tab2()}
                    </TabPane>
                </Tabs>
            </div>
        )
}

/**
 * 创建内部表单
 * @const {Form} ActivityManagement
 */
const ActivityManagement = Form.create()(ActivityManagementForm)

export default ActivityManagement;