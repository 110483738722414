/*
 * @file: 平台用户的基础信息
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import {
    Card, Row, Col, Radio, Upload, Icon, message, Form, Input, Button, Select, Modal
} from 'antd'
import {
    getAreaOptions, getUserModel, resetPassword, saveUserModel,
    getFile, getHealthAdvisorAccount, getHaDoctorInfo, saveHaDoctorBasicInfo
} from './../../Api/index'
import RadioGroup from 'antd/lib/radio/group';
const { Option } = Select
const { confirm } = Modal;
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader));
    reader.readAsDataURL(img);
}
/**
 * @classdesc: 平台用户的基础信息
 */
class UserBasicP extends Component {
    // 跳转过来  传的值
    constructor(props, context) {
        super(props, context)
        /**
         * @description: state自定义
         * @param {string} userId 获取到用户列表所传过来的userModel
         * @param {string} recordId 获取健康顾问等列表传来haUserid
         * @param {string} userStatus 用户类型
         * @param {boolean} jl 节流
         * @param {object} headPic 头像
         * @param {object} cardPositivePic 正面身份证
         * @param {object} cardBackPic 反面身份证
         * @param {object} parent 省
         * @param {object} city 市
         * @param {object} area 区
         * @param {string} parentId 省id
         * @param {string} cityId 市id
         * @param {string} areaId 区id
         * @param {string} address 详细地址
         * @param {boolean} set 编辑状态
         * @param {boolean} loading 图片上传等待状态
         * @param {boolean} headloading 头像上传等待状态
         * @param {boolean} piczloading 正面身份证上传等待状态
         * @param {boolean} picfloading 反面身份证上传等待状态
         * @param {object} params 表格分页
         * @param {object} list 基础格式
         * @param {object} data 数据
         */
        this.state = {
            userId: this.props.location.state.data.userId,
            recordId: this.props.location.state.data.id,
            userStatus: this.props.location.state.text,
            jl: true,
            headPic: null,
            cardPositivePic: null,
            cardBackPic: null,
            parent: [],
            city: [],
            area: [],
            parentId: '省',
            cityId: '市',
            areaId: '区',
            address: [],
            set: false,
            loading: false,
            headloading: false,
            piczloading: false,
            picfloading: false,
            params: {},
            list: [
                { title: "name", val: "", AS: "姓名" },
                { title: "gender", val: "", AS: "性别" },
                { title: "address", val: "", AS: "所在地" },
                { title: "cardNo", val: "", AS: "身份证号码" },
                { title: "nickName", val: "", AS: "微信昵称" },
                { title: "email", val: "", AS: "个人邮箱" },
                { title: "mobile", val: "", AS: "手机号" },
                { title: "companyName", val: "", AS: "所属企业" },
                { title: "regResource", val: "", AS: "注册来源" },
                { title: "createDate", val: "", AS: "注册时间" },
                { title: "isInternalStr", val: "", AS: "标签" },
                { title: "updateDate", val: "", AS: "最后修改时间" },
                { title: "updateByName", val: "", AS: "最后修改人" },
                { title: "remark", val: "", AS: "备注" },
                { title: "pic", val: '', AS: "身份证照片" }
            ],
            data: [],
        };
    }
    // 生命周期
    componentWillMount() {
        this.requestTableList()
    }
    /**
     * @description: 获取详情数据
     * @function: requestTableList
     */
    requestTableList = () => {
        let _this = this
        let params = this.state.params
        params['userId'] = this.state.userId

        // 平台用户的接口
        if (this.state.userStatus === '平台用户') {
            getUserModel(params).then((res) => {
                if (res.data.state.name === "操作成功") {
                    let result = res.data.result
                    conduct(result, '平台用户')
                }
            })
            // 健康顾问的接口
        } else if (this.state.userStatus === '健康顾问用户') {
            getHealthAdvisorAccount(params).then((res) => {
                if (res.data.state.name === "操作成功") {
                    let result = res.data.result.userModel
                    let userObj = {
                        name: res.data.result.haUser.fullname,
                        gender: res.data.result.haUser.gender == 1 ? '男' : res.data.result.haUser.gender == 0 ? '女' : null,
                        cityId: res.data.result.haUser.cityId,
                        cardNo: null,
                        address: null,
                        nickName: null,
                        email: null,
                        mobile: res.data.result.haUser.userMobile,
                        companyName: res.data.result.haUser.companyName,
                        regResource: null,
                        createDate: null,
                        isInternalStr: null,
                        overtime: null,
                        remark: null,
                        cardPositiveFileId: res.data.result.userModel.cardPositiveFileId,
                        cardBackFileId: res.data.result.userModel.cardBackFileId,
                        faceId: res.data.result.haUser.faceId
                    }
                    conduct(result, '健康顾问用户')
                }
            })
            // 医生用户的接口
        } else if (this.state.userStatus === '医生用户') {

            getHaDoctorInfo(params).then((res) => {
                if (res.data.state.name === "操作成功") {
                    //console.log(res.data.result)

                    // let result = res.data.result.userModel
                    let userObj = {
                        name: res.data.result.haUser.fullname,
                        gender: res.data.result.haUser.gender == 1 ? '男' : res.data.result.haUser.gender == 0 ? '女' : null,
                        cityId: res.data.result.haUser.cityId,
                        address: res.data.result.haDoctorInfo.address,
                        nickName: null,
                        email: null,
                        mobile: res.data.result.haUser.userMobile,
                        companyName: res.data.result.haUser.companyName,
                        regResource: null,
                        createDate: null,
                        isInternalStr: null,
                        overtime: null,
                        remark: null,

                        faceId: res.data.result.haUser.faceId
                    }
                    if (res.data.result.haDoctorCheck) {
                        userObj['cardNo'] = res.data.result.haDoctorCheck.certNo
                        userObj['cardPositiveFileId'] = res.data.result.haDoctorCheck.certFrontId
                        userObj['cardBackFileId'] = res.data.result.haDoctorCheck.certBackId
                    }
                    conduct(userObj, '医生用户')
                    this.setState({
                        haDoctorCheckId: res.data.result.haDoctorCheck ? res.data.result.haDoctorCheck.id : null,
                        haDoctorInfoId: res.data.result.haDoctorInfo.id
                    })
                }
            })
        }
        // 处理接口传来数据
        function conduct(result, text, obj) {
            //console.log(result)
            if (result.faceId) {
                _this.imgUrlUp(result.faceId).then((val) => {
                    //console.log(val)
                    _this.setState({
                        headPic: val
                    })
                })
            }
            if (result.cardPositiveFileId) {
                _this.imgUrlUp(result.cardPositiveFileId).then((val) => {
                    if (val) {
                        val['createDate'] = _this.formatDate(val.createDate)
                        _this.setState({
                            cardPositivePic: val
                        })
                    }
                })
            }
            if (result.cardBackFileId) {
                _this.imgUrlUp(result.cardBackFileId).then((val) => {
                    if (val) {
                        val['createDate'] = _this.formatDate(val.createDate)
                        _this.setState({
                            cardBackPic: val
                        })
                    }
                })
            }
            let list = _this.state.list;
            for (var i = 0; i < list.length; i++) {
                list[i].val = result[list[i].title] ? result[list[i].title] : null
            }
            list[9].val = _this.formatDate(list[9].val)
            _this.setState({
                list,
                data: result,
            })
            _this.requestArea(result)
        }
    }
    /**
     * @description: id请求图片路径
     * @param {number} urlId 图片id 
     * @function: imgUrlUp
     */
    imgUrlUp = (urlId) => {
        let _this = this
        return new Promise((resolve, reject) => {
            // 获取图片详细情况
            getFile({ fileId: urlId }).then(res => {
                if (res.status === 200 && res.statusText === "OK") {
                    // res.data.result.createDate=_this.formatDate(res.data.result.createDate)
                    resolve(res.data.result)
                } else {
                    reject()
                }
            })

        })
    }
    /**
    * 时间戳转换
    * @param {Date} now 时间戳
    * @return: {Date} 显示格式
    * @function formatDate
    */
    formatDate = (now) => {
        var nowDate = new Date(now)
        var year = nowDate.getFullYear();  //取得4位数的年份
        var month = nowDate.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
        var date = nowDate.getDate();      //返回日期月份中的天数（1到31）
        return year + "-" + month + "-" + date;
    }
    /**
     * @description: 获取级联地址的数据
    * @param {object} result 详情数据 
     * @function: requestArea
     */
    requestArea = (result) => {
        let _this = this
        let data = result
        getAreaOptions().then(res => {
            if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                _this.setState({
                    parent: res.data.result
                })
            }
        })
        if (data.cityId) {
            getAreaOptions({ parentId: data.cityId }).then(res => {
                if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                    getAreaOptions({ parentId: res.data.result[0].provinceId }).then(res => {
                        if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                            _this.setState({
                                city: res.data.result,
                                cityId: data.cityId,

                            })
                        }
                    })
                    _this.setState({
                        parentId: res.data.result[0].provinceId,
                        area: res.data.result
                    })
                }
            })
        }
    }
    /**
     * @description: 地址   点击了省
     * @param {number} value 省id 
     * @function: handleProvinceChange
     */
    handleProvinceChange = value => {
        let _this = this
        this.props.form.setFields({ city: { value: '', errors: [new Error('forbid ha')] } })
        this.props.form.setFields({ area: { value: '', errors: [new Error('forbid ha')] } })
        getAreaOptions({ parentId: value }).then(res => {
            if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                _this.setState({
                    city: res.data.result,
                    area: []
                })
            }
        })
    };
    /**
    * @description: 地址   点击了市
    * @param {number} value 省id 
    * @function: onSecondCityChange
    */
    onSecondCityChange = value => {
        let _this = this
        this.props.form.setFields({ area: { value: '', errors: [new Error('forbid ha')] } })
        getAreaOptions({ parentId: value }).then(res => {
            if (res.data.state.value === 0 && res.data.state.name === '操作成功') {
                _this.setState({
                    area: res.data.result
                })
            }
        })
    };
    /**
     * @description: 点击进入编辑模块
     * @function: listSet
     */
    listSet = () => {
        this.setState({
            set: true
        })
    }
    /**
     * @description: 点击取消进入浏览模块
     * @function: Cancels
     */
    Cancels = () => {
        this.setState({
            set: false
        })
    }
    /**
     * @description: 点击保存进入浏览模块， 并将数据上传
     * @function: Save
     */
    Save = () => {
        let _this = this
        let jl = this.state.jl
        if (jl) {
            this.setState({ jl: false })
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let userSet = this.props.form.getFieldsValue()
                    let params = this.state.params
                    // 需要上传的数据
                    if (this.state.userStatus === '医生用户') {
                        params = {
                            haUser: {
                                fullname: userSet.name,
                                gender: userSet.gender,
                                cityId: userSet.city,
                                userMobile: userSet.mobile,
                                id: this.state.recordId,
                                userId: this.state.userId,
                                faceId: this.state.headPic ? this.state.headPic.id : null
                            },
                            haDoctorInfo: {
                                address: userSet.address,
                                id: this.state.haDoctorInfoId
                            },
                            haDoctorCheck: {
                                certNo: userSet.cardNo,
                                certFrontId: this.state.cardPositivePic ? this.state.cardPositivePic.id : null,
                                certBackId: this.state.cardBackPic ? this.state.cardBackPic.id : null,
                                id: this.state.haDoctorCheckId
                            }
                        }
                        //console.log(params)
                        saveHaDoctorBasicInfo(params).then(res => {
                            if (res.status === 200 && res.data.state.name === '操作成功') {
                                message.success('操作成功')
                                _this.setState({
                                    set: false
                                })
                                _this.requestTableList()
                            } else {
                                message.error(res.data.content)
                            }
                        })
                    } else {
                        for (var key in userSet) {
                            if (userSet[key]) {
                                params[key] = userSet[key]
                            }
                        }
                        var createDate = new Date().getTime()
                        params['createDate'] = createDate
                        params['userId'] = this.state.userId
                        delete params.city;
                        delete params.parent;
                        delete params.gander;
                        delete params.area;
                        delete params.address;
                        if (this.state.headPic) {
                            params["faceId"] = this.state.headPic.id
                        }
                        if (this.state.cardPositivePic) {
                            params["cardPositiveFileId"] = this.state.cardPositivePic.id
                        }
                        if (this.state.cardBackPic) {
                            params["cardBackFileId"] = this.state.cardBackPic.id
                        }

                        saveUserModel(params).then(res => {
                            if (res.status === 200 && res.data.state.name === '操作成功') {
                                message.success('操作成功')
                                _this.setState({
                                    set: false
                                })
                                _this.requestTableList()
                            } else {
                                message.error(res.data.content)
                            }
                        })
                    }

                } else {
                    message.error('请将带 * 的信息填写正确')
                }
            });
            setTimeout(function () {
                _this.setState({ jl: true })
            }, 1000)
        } else {
            this.renderModalSimple('warning', '提示', '正在提交，请稍后！')
        }
    }
    /**
     * @description: 接收数据判断然后跳转
     * @function: herfBasicJ
     */
    herfBasicJ = () => {
        // 要判断是普通用户还是医生
        let userId = this.state.userId
        let recordId = this.state.recordId
        if (this.state.userStatus === '医生用户') {
            var url = "list4/basicy"
        } else {
            var url = 'list3/basicj'
        }
        // 要判断这个用户的id  然后传入id
        this.props.history.push({ pathname: `/user/all/${url}`, state: { recordId, userId } })
    }
    /**
     * @description: 重置密码
     * @function: resetUpwd
     */
    resetUpwd = () => {
        let _this = this
        confirm({
            title: '是否要初始化平台登陆密码?',
            onOk() {
                _this.resetUpwdHt()
            },
            onCancel() { },
        });
    }
    /**
     * @description: 重置密码发送后台
     * @function: resetUpwdHt
     */
    resetUpwdHt = () => {
        let _this = this
        let userId = this.state.userId
        resetPassword({ userId: userId }).then(res => {
            if (res.status === 200 && res.statusText === 'OK') {
                _this.Confirmed()
            } else {
                message.error('初始化失败，请稍后重新尝试')
            }
        })
    }
    /**
     * @description: 重置成功弹出框
     * @function: Confirmed
     */
    Confirmed = () => {
        Modal.success({
            title: '初始化成功',
            content: '初始密码为：zyj123456',
        });
    }
    /**
     * @description: 头像上传
     * @param {object} info 头像数据 
     * @function: handleChange
     */
    handleChange = info => {
        if (info.file.status === 'uploading') {

            this.setState({ headloading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                this.setState({
                    headPic: info.file.response.result,
                    headloading: false
                })
            });
        }
    };
    /**
     * @description: 身份证正面
     * @param {object} info 身份证数据 
     * @function: handleChange2
     */
    handleChange2 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ piczloading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                this.setState({
                    cardPositivePic: info.file.response.result,
                    piczloading: false
                })
            });
        }
    };
    /**
     * @description: 身份证反面
     * @param {object} info 身份证数据 
     * @function: handleChange3
     */
    handleChange3 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ picfloading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                this.setState({
                    cardBackPic: info.file.response.result,
                    picfloading: false
                })
            });
        }
    };
    /**
   * 警告---提示框
   * @param {string} type 类型
   * @param {string} title 标题
   * @param {string} content 内容 
   * @function renderModalSimple
   */
    renderModalSimple = (type, title, content) => {
        return Modal[type ? type : 'info']({
            title: title ? title : '提示',
            content: content ? content : ''
        })
    }

    render() {
        let _this = this
        //   还未上传图片的显示的按钮  正面
        const piczButton = (
            <div className="buttonPic">
                {!this.state.piczloading ? <span className="f12">暂未上传</span> : <Icon type='loading' />}
            </div>
        );
        //   还未上传图片的显示的按钮  反面
        const picfButton = (
            <div className="buttonPic">
                {!this.state.picfloading ? <span className="f12">暂未上传</span> : <Icon type='loading' />}
            </div>
        );
        const uppic = (
            <div style={{
                width: '100px',
                height: '100px',
                textAlign: 'center',
                lineHeight: '100px',
                border: '1px solid #eee',
                padding: '5px',
                margin: 'auto'
            }}>
                {!this.state.headloading ? <span className="f12">暂无头像</span> : <Icon type='loading' />}
            </div>
        );
        const { imageUrl } = this.state;
        // 地址
        const parent = this.state.parent
        const city = this.state.city
        const area = this.state.area
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 17 },
            },
        };
        return (
            <div className="userBasiPForm">
                <div className="mb20" >
                    <Button type="primary" className="mr20">基本信息</Button>
                    {(() => {
                        if (this.state.userStatus !== "平台用户") {
                            return (<Button onClick={this.herfBasicJ}>健康顾问</Button>)
                        }
                    })()}
                </div>
                <Card
                    className="CardNone"
                    title="基本信息"
                    extra={
                        (() => {
                            if (this.state.set) {
                                return (<div>
                                    <Button type="primary"
                                        onClick={this.Cancels}
                                        className="mr20"
                                    >取消</Button>
                                    <Button type="primary"
                                        onClick={this.Save}
                                        htmlType="submit"
                                    >保存</Button>
                                </div>)
                            } else {
                                return (
                                    <Button style={{ display: this.state.display_block }} type="primary" onClick={this.listSet}>编辑</Button>
                                )
                            }
                        })()
                    }
                >
                    <Row className="mt25" style={{ width: 1200 }}>
                        {/* 左边头像 */}
                        <Col span={3} style={{ textAlign: 'center' }} className='headUrlPic'>
                            {
                                this.state.headPic ?
                                    <img src={this.state.headPic.fullOssurl} style={{ width: 100, height: 100 }} className="border padding5" alt="" />
                                    : uppic
                            }
                            <p className="margin5 ">头像</p>
                            {this.state.set ?
                                <Upload
                                    name='file'
                                    showUploadList={false}
                                    action='/v1/ajax/file/upload'
                                    onChange={this.handleChange}
                                >
                                    <span style={{ color: '#1890ff', cursor: 'pointer' }}>上传头像</span>
                                </Upload>
                                : null
                            }
                        </Col>
                        {/* 右边主题 */}
                        {(() => {
                            // 编辑模式
                            if (this.state.set) {
                                return (
                                    <Col span={21} className="fr"
                                        style={{
                                            height: 1000
                                        }}
                                    >
                                        <Form  {...formItemLayout}>
                                            {/* 姓名 */}
                                            <Form.Item label="姓名" className="m0t10"  >
                                                {
                                                    getFieldDecorator('name', {
                                                        initialValue: this.state.data.name,
                                                        rules: [
                                                            { required: true, message: '不能为空' },
                                                        ],
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </Form.Item>
                                            {/* 性别 */}
                                            {
                                                this.state.userStatus !== '平台用户' ?
                                                    < Form.Item label="性别" className="m0t10" >
                                                        {
                                                            getFieldDecorator('gender', {
                                                                initialValue: this.state.data.gender == '男' ? '1' : this.state.data.gender == '女' ? '0' : null,
                                                                // rules: [
                                                                //     {required: true, message: '不能为空'},
                                                                // ],
                                                            })(
                                                                <RadioGroup>
                                                                    <Radio value="1">男</Radio>
                                                                    <Radio value="0">女</Radio>
                                                                </RadioGroup>
                                                            )
                                                        }
                                                    </Form.Item>
                                                    : null
                                            }
                                            {/* 所在地 */}
                                            {
                                                this.state.userStatus !== '平台用户' ?
                                                    <Form.Item label="所在地" className="m0t10" >
                                                        <Row>
                                                            {
                                                                getFieldDecorator('parent', {
                                                                    initialValue: this.state.parentId + '',
                                                                    // rules: [
                                                                    //     { required: true, message: '不能为空' },
                                                                    // ],
                                                                })(
                                                                    <Select
                                                                        style={{ width: 120, marginRight: '20px' }}
                                                                        onChange={this.handleProvinceChange}
                                                                    >
                                                                        {parent.map((e, i) => (
                                                                            <Option key={e.id} >{e.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                )
                                                            }
                                                            {
                                                                getFieldDecorator('city', {
                                                                    initialValue: this.state.cityId + '',
                                                                    // rules: [
                                                                    //     { required: true, message: '不能为空' },
                                                                    // ],
                                                                })(
                                                                    <Select
                                                                        style={{ width: 120, marginRight: '20px' }}
                                                                        onChange={this.onSecondCityChange}
                                                                    >
                                                                        {city.map((e, i) => (
                                                                            <Option key={e.id} >{e.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                )
                                                            }
                                                            {
                                                                getFieldDecorator('area', {
                                                                    initialValue: this.state.areaId + '',
                                                                    // rules: [
                                                                    //     { required: true, message: '不能为空' },
                                                                    // ],
                                                                })(
                                                                    <Select
                                                                        style={{ width: 120, marginRight: '20px' }}
                                                                    >
                                                                        {area.map((e, i) => (
                                                                            <Option key={e.id} >{e.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                )
                                                            }
                                                            {this.state.userStatus === '医生用户' ?
                                                                getFieldDecorator('address', {
                                                                    initialValue: this.state.data.address,
                                                                    // rules: [
                                                                    //     {required: true, message: '不能为空'},
                                                                    // ],
                                                                })(
                                                                    <Input style={{ width: 323 }} placeholder='请输入详细地址' />
                                                                )
                                                                : null
                                                            }
                                                        </Row>
                                                    </Form.Item>
                                                    : null
                                            }
                                            {/* 身份号码 */}
                                            <Form.Item label="身份号码" className="m0t10" >
                                                {
                                                    getFieldDecorator('cardNo', {
                                                        initialValue: this.state.data.cardNo,
                                                        rules: [
                                                            { required: true, message: '不能为空' },
                                                        ],
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </Form.Item>
                                            {/* 微信昵称 */}
                                            <Form.Item label="微信昵称" className="m0t10">
                                                <div>不可更改</div>
                                            </Form.Item>
                                            {/* 个人邮箱 */}
                                            <Form.Item label="个人邮箱" className="m0t10" >
                                                {
                                                    getFieldDecorator('email', {
                                                        initialValue: this.state.data.email,
                                                        // rules: [
                                                        //     { required: true, message: '不能为空' },
                                                        //     { type: 'email', message: '请输入正确的格式' }
                                                        // ],
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </Form.Item>
                                            {/* 手机号 */}
                                            <Form.Item label="手机号" className="m0t10" >
                                                {
                                                    getFieldDecorator('mobile', {
                                                        initialValue: this.state.data.mobile,
                                                        rules: [
                                                            { required: true, message: '不能为空' },
                                                            {
                                                                pattern: new RegExp(/^1[3-9]\d{9}$/),
                                                                message: '格式不正确'
                                                            }
                                                        ],
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </Form.Item>
                                            {/* 所属企业 */}
                                            <Form.Item label="所属企业" className="m0t10" >
                                                <div>不可更改</div>
                                            </Form.Item>
                                            {/* 注册来源 */}
                                            <Form.Item label="注册来源" className="m0t10" >
                                                <div>不可更改</div>
                                            </Form.Item>
                                            {/* 注册时间 */}
                                            <Form.Item label="注册时间" className="m0t10">
                                                <div>不可更改</div>
                                            </Form.Item>
                                            {/* 标签 */}
                                            <Form.Item label="标签" className="m0t10">
                                                <div>不可更改</div>
                                            </Form.Item>
                                            {/* 最后修改时间 */}
                                            <Form.Item label="最后修改时间" className="m0t10">
                                                <div>不可更改</div>
                                            </Form.Item>
                                            {/* 最后修改人 */}
                                            <Form.Item label="最后修改人" className="m0t10">
                                                <div>不可更改</div>
                                            </Form.Item>
                                            {/* 备注 */}
                                            <Form.Item label="备注" className="m0t10" >
                                                {
                                                    getFieldDecorator('remark', {
                                                        initialValue: this.state.data.remark,
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </Form.Item>
                                            {/* 身份证照片 */}
                                            <Form.Item label="身份证照片" className="m0t10" >
                                                <Row className="IdCardpic">
                                                    <Col span={12} className="mt20 text-align-center">
                                                        {
                                                            this.state.cardPositivePic ?
                                                                <img src={this.state.cardPositivePic.fullOssurl} style={{ width: 300, height: 170 }} className='border padding5' alt="" />
                                                                : piczButton
                                                        }
                                                        <Upload
                                                            name='file'
                                                            showUploadList={false}
                                                            action='/v1/ajax/file/upload'
                                                            onChange={this.handleChange2}
                                                        >
                                                            <span style={{ color: '#1890ff', cursor: 'pointer' }}>上传照片（正面）</span>
                                                        </Upload>
                                                    </Col>
                                                    <Col span={12} className="mt20 text-align-center">
                                                        {
                                                            this.state.cardBackPic ?
                                                                <img src={this.state.cardBackPic.fullOssurl} style={{ width: 300, height: 170 }} className='border padding5' alt="" />
                                                                : picfButton
                                                        }
                                                        <Upload
                                                            name='file'
                                                            showUploadList={false}
                                                            action='/v1/ajax/file/upload'
                                                            onChange={this.handleChange3}
                                                        >
                                                            <span style={{ color: '#1890ff', cursor: 'pointer' }}>上传照片（反面）</span>
                                                        </Upload>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Form>
                                    </Col>)
                            } else {
                                return (
                                    <Col span={21} className="fr"
                                        style={{
                                            height: 1000
                                        }}
                                    >
                                        {/* As标题 */}
                                        <Col span={3} key>
                                            {this.state.list.map((e, i) => {
                                                if (this.state.userStatus !== '平台用户') {
                                                    return (
                                                        <Col span={24} key={i} className="mt10"
                                                            style={{
                                                                height: 40,
                                                            }}
                                                        >
                                                            <span className="ml20"
                                                                style={{ lineHeight: '40px' }}
                                                            >{e.AS}</span>
                                                        </Col>
                                                    )
                                                } else {
                                                    if (i === 0 || i > 2) {

                                                        return (
                                                            <Col span={24} key={i} className="mt10"
                                                                style={{
                                                                    height: 40,
                                                                }}
                                                            >
                                                                <span className="ml20"
                                                                    style={{ lineHeight: '40px' }}
                                                                >{e.AS}</span>
                                                            </Col>
                                                        )
                                                    }
                                                }
                                            })}
                                            {/* 数据显示 */}
                                        </Col>
                                        <Col span={21} >
                                            {this.state.list.map((e, i) => {
                                                if (i == 6) {
                                                    return (
                                                        <Col span={20} key={i} className="mt10 lineh40">
                                                            <div className="width-100 plr20"
                                                                style={{
                                                                    borderBottom: '#ddd solid 1px',
                                                                    height: '40px'
                                                                }}
                                                            >
                                                                <span>{e.val}</span>
                                                                <span className="fr"
                                                                    style={{
                                                                        color: "#1890ff",
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={this.resetUpwd}
                                                                >[重置平台密码]</span>
                                                            </div>
                                                        </Col>
                                                    )
                                                } else if (i === this.state.list.length - 1) {
                                                    return (
                                                        <Col span={20} key={i}
                                                            style={{
                                                                height: 50,
                                                                marginTop: 20,
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <Col span={12}>
                                                                {
                                                                    this.state.cardPositivePic ?
                                                                        <img src={this.state.cardPositivePic.fullOssurl} style={{ width: 300, height: 170 }} className='border padding5' alt="" />
                                                                        : piczButton
                                                                }
                                                                <p className="margin5">正面</p>
                                                                {this.state.cardPositivePic ?
                                                                    <p className="margin5">
                                                                        <span>上传时间：</span>
                                                                        <span>{this.state.cardPositivePic.createDate}</span>
                                                                    </p> : null
                                                                }
                                                            </Col>
                                                            <Col span={12}>
                                                                {
                                                                    this.state.cardBackPic ?
                                                                        <img src={this.state.cardBackPic.fullOssurl} style={{ width: 300, height: 170 }} className='border padding5' alt="" />
                                                                        : picfButton
                                                                }
                                                                <p className="margin5">反面</p>
                                                                {this.state.cardBackPic ?
                                                                    <p className="margin5">
                                                                        <span>上传时间：</span>
                                                                        <span>{this.state.cardBackPic.createDate}</span>
                                                                    </p> : null
                                                                }
                                                            </Col>
                                                        </Col>)
                                                } else if (this.state.userStatus === '平台用户') {
                                                    if (i === 0 || i > 2) {
                                                        return (
                                                            <Col span={20} key={i} className="mt10 lineh40">
                                                                <div className="width-100 pl20"
                                                                    style={{
                                                                        borderBottom: '#ddd solid 1px',
                                                                        height: '40px'
                                                                    }}
                                                                >
                                                                    <span>{e.val}</span>
                                                                </div>
                                                            </Col>
                                                        )

                                                    }
                                                } else {
                                                    return (
                                                        <Col span={20} key={i} className="mt10 lineh40">
                                                            <div className="width-100 pl20"
                                                                style={{
                                                                    borderBottom: '#ddd solid 1px',
                                                                    height: '40px'
                                                                }}
                                                            >
                                                                <span>{e.val}</span>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Col>
                                    </Col>
                                )
                            }
                        })()}
                    </Row>
                </Card>
            </div >
        )
    }
}
const UserSearchForm = Form.create()(UserBasicP);
export default UserSearchForm;
