/*
 * @file: 健康顾问列表
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import { userisAdminAdd,userBatchAdd } from '../../Mock'
import { Tooltip,Form, Input, Button, Select, Checkbox,  Table, Modal,  Pagination, message,Tag,DatePicker} from 'antd'
import {getHealthAdvisorAccountListData,} from './../../Api/index'
import TaggingBatch from './components/Common/TaggingBatch'//批量打标签/取消标签
import Tagging from './components/Common/Tagging'//打标签
import utils from './../../Common/js/utils'    //表格分页等     
import moment from 'moment';
const { Option } = Select
const {  RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
/**
 * @classdesc 健康顾问列表
     */  
class UserList3 extends Component {
    /**
     * @description: state自定义
     * @param {object} params 分页数据
     * @param {object} UserList3Data 健康顾问数据
     * @param {object} selectedRows 多选
     * @param {boolean} batchvisible 打标签判断值
     * @param {boolean} visible 批量打标签
     * @param {boolean} loading 等待判断值
     * @param {object} columns 表格配置
     */
    state={
        UserList3Data:[],
        params:{
            pageNum:1,
            pageSize:10
        },
        selectedRows: [],
        batchvisible:false, //批量打标签
        visible:false,
        loading:false,
        columns:[
            {title: "序号",dataIndex: 'key',align: 'center',width: 40,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: "微信昵称",dataIndex: 'nickName',align: 'center',width: 120,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '绑定手机号',dataIndex: 'mobile',align:'center',width:120,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '姓名',dataIndex: 'name',align:'center',width:120,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '用户类型',dataIndex: 'isDoctor',align:'center',width:120,render(text){
                if(text===1){
                    return <span title={text}>医生</span>
                }else{
                    return <span title={text}>个人</span>
                }
            },},

            {title: '健康顾问开通时间',dataIndex: 'createDate',align:'center',width:120,render(text){
                return <span title={text}>{text}</span>
            },},
            // {title: '所属行业',dataIndex: 'industryCategoryStr',align:'center',width:90},
            {title: '标签',dataIndex: 'isInternal',align:'center',width:80,render:(text,record)=>{
                if(text === 1){
                    return (
                        <Tag color='green' key={text}>
                        内部人员
                        </Tag>
                    ) 
                }
            }},
            {title: '操作',dataIndex: 'operation',width:60,align:'center',render: (text,record) => {
                return (
                    <div>
                        <a className="blue mlr5" onClick={() => this.jumpTo(record)}>
                            <Tooltip title="查看">
                                <span className="fa fa-eye f18"></span>
                            </Tooltip>
                        </a>
                        <a className="blue mlr5" onClick={() => this.showModal(record)}>
                            <Tooltip title="打标签">
                                <span className="fa fa-plus-square-o f18"></span>
                            </Tooltip>
                        </a>
                    </div>
                )
            }},
            {title: 'cid',dataIndex: 'cid',align:'center',className:'dn',width:0},
            {title: 'companyPlatformAdminId',dataIndex: 'companyPlatformAdminId',align:'center',className:'dn',width:0},
        ]
    };
    componentWillMount() {
        this.requestTableList()
    }
    /**
     * 请求表格数据函数
     * @function requestTableList
     */
    requestTableList = ()=>{
        let params=this.state.params
        let _this = this
        getHealthAdvisorAccountListData(params).then((res)=>{
            if(res.status === 200&&res.statusText==='OK'){
                utils.tableListKey(res.data.result.rows,_this.state.params.pageNum,_this.state.params.pageSize)
                this.setState({
                    total: res.data.result.rows.length,
                    UserList3Data: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        //console.log(size,"size")
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestTableList()
                    }, (current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestTableList()
                    }, res.data.result.pageSize)
                })
            }
            
        })
    }

// 弹出框-----S
    /**
     * 批量打标签和取消标签------弹出显示状态
     * @param {string} type 类型 
     * @function showModalBatch
     */
    showModalBatch = (type) => {
        // 获取到选中行的内容
        var selectedRows=this.state.selectedRows
        if(selectedRows.length == 0){
            this.renderModalSimple('warning','提醒','请选择至少一条信息')
        }else{
            this.setState({
                batchvisible: true,
                BatchOkType:type
            });
        }
    };
   /**
     * 批量打标签弹出框-----确定
     * @function BatchOk
     */    
    BatchOk = () => {
        this.setState({ loading: true });
        let idArr = []    
        this.state.selectedRows.map(item=>{
            idArr.push(item.cid)
        })
        // 将要改变的用户ID放入idArr
        let params = {idArr:idArr}
        let checkGrpoup = this.formRef.getItemsValue()['checkbox-group']
        if(this.state.BatchOkType === 'remove'){
            if(checkGrpoup){
                checkGrpoup.map(item=>{
                    if(item == 'isAdminAdd'){
                        params.isAdminAdd = false
                    }
                })
            }
        }else if(this.state.BatchOkType === 'add'){
            if(checkGrpoup){
                checkGrpoup.map(item=>{
                    if(item == 'isAdminAdd'){
                        params.isAdminAdd = true
                    }
                })
            }
        }
        //批量打标签，后台接口未完成
        userBatchAdd(params).then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                message.success('操作成功！')
                this.setState({ loading: false, batchvisible: false });
                this.requestTableList()
            }else{
                this.renderModalSimple('warning','错误','操作失败，请重新提交！')
            }
            this.formRef.resetValue()
        })
    };
    /**
     * @description: 批量打标签弹出-----框取消
     * @function: BatchCancel
     */
    BatchCancel = () => {
        this.formRef.resetValue()
        this.setState({ batchvisible: false });
    };
    /**
     * @description: 打标签弹出显示状态
     * @param {object} record 行信息 
     * @function: showModal
     */
    showModal = (record) => {
        this.setState({
            visible: true,
            record:record,
        });
    };
    /**
     * @description:打标签弹出框确定按钮操作 
     * @function: handleOk
     */
    handleOk = () => {
        this.setState({ loading: true });
        //console.log(this.state.record,'15')
       
        let params = this.state.record
        let checkGrpoup = this.formRef.getItemsValue()['checkbox-group']
        if(checkGrpoup){
            checkGrpoup.map(item=>{
                if(item == 'isAdminAdd'){
                    params.isAdminAdd = true
                }
            })
        }
        //console.log(params)
        //连接后台
        userisAdminAdd(params).then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                message.success('操作成功！')
                this.setState({ loading: false, visible: false });
                this.requestTableList()                           
            }else{
                this.renderModalSimple('warning','错误','操作失败，请重新提交！')
            }
            this.formRef.resetValue()
        })
    };
    /**
     * @description: 打标签弹出框取消按钮操作
     * @function: handleCancel
     */
    handleCancel = () => {
        this.formRef.resetValue()
        this.setState({ visible: false });
    };
      /**
     * 警告---提示框
     * @param {string} type 类型
     * @param {string} title 标题
     * @param {string} content 内容 
     * @function renderModalSimple
     */  
    renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({
            title:title?title:'警告',
            content:content?content:''
        })
    }

// 弹出框-----E


      /**
     * 时间戳转换
     * @param {Date} now 时间戳
     * @return: {Date} 显示格式
     * @function formatDate
     */
    formatDate=(now)=>{ 
        var nowDate=new Date(now)
        var year=nowDate.getFullYear();  //取得4位数的年份
        var month=nowDate.getMonth()+1;  //取得日期中的月份，其中0表示1月，11表示12月
        var date=nowDate.getDate();      //返回日期月份中的天数（1到31）
        return year+"-"+month+"-"+date; 
    }
     /**
     *  搜索按钮
     * @function handleSubmit
     */
    handleSubmit = ()=> {
        let fieldsParams = this.props.form.getFieldsValue()
        let _this=this
        if(fieldsParams.regDate&&fieldsParams.regDate.length){
            var Btime=_this.formatDate(fieldsParams.regDate[0]._d)
            var Etime=_this.formatDate(fieldsParams.regDate[1]._d)

        }
        new Promise((resolve,reject)=>{
            _this.setState({
                params:{
                    name:fieldsParams.identity,
                    beginTime:fieldsParams.regDate!=undefined?Btime:undefined,
                    endTime:fieldsParams.regDate!=undefined?Etime:undefined,
                    isInternal:fieldsParams.isAdminAdd?1:0,
                    userCheckState:fieldsParams.Authentication,
                    pageNum:1,
                    pageSize:10
                }
            })
            resolve()
        }).then(()=>{this.requestTableList()})
    };
     /**
     *  重置按钮
     * @function handleReset
     */
    handleReset = () => {
        let _this=this
        this.props.form.resetFields();

        new Promise((resolve,reject)=>{
            _this.setState({
                params:{
                    pageNum:1,
                    pageSize:10
                }
            })
            resolve()
        }).then(()=>{this.requestTableList()})
    };
    /**
     * 跳转到详情页
     * @param {object} record 跳转用的行信息 
     * @function jumpTo
     */   
    jumpTo = (record)=>{
        // window.location.href='/user/basic/'+record.cid
        this.props.history.push({pathname: `/user/all/list3/basicp`, state: {data: record,text:"健康顾问用户"}})
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        // 多选框触发函数  selectedRowKeys 每一个选中的key  
        // selectedRows每一个选中的行内容
        // selected 点击的按钮是否选中状态
        const rowSelection = {
            // 每次改变触发
            onChange: (selectedRowKeys, selectedRows) => {
                //console.log(selectedRowKeys,  selectedRows);
                this.setState({
                selectedRowKeys, selectedRows
                })
            },
            // 每次点击选中一个触发
            onSelect: (record, selected, selectedRows) => {
                //console.log(record, selected, selectedRows);
                this.setState({
                record, selected, selectedRows
                })
            },
            // 当点击全选按钮触发
            onSelectAll: (selected, selectedRows, changeRows) => {
                //console.log(selected, selectedRows, changeRows);
                this.setState({
                changeRows, selected, selectedRows
                })
            },
        };
        //表格 表头
        const columns = this.state.columns
        const title = () => {
            return (
                <div>
                    <div className="fr">
                        <Button type="primary" onClick={()=>this.showModalBatch('add')}>
                            打标签
                        </Button>
                        <Button type="primary" onClick={()=>this.showModalBatch('remove')} style={{ marginLeft: 10 }}>
                            取消标签
                        </Button>
                    </div> <div className="clear"></div>
                </div>
            )
        };
        return (
            <div>
                {/* 顶部条件搜索 * @function 
     */}
                <Form layout="inline">
                    <Form.Item>
                        {getFieldDecorator('identity')(
                            <Input
                            style={{width:180}}
                            placeholder="姓名/绑定手机号/身份证号码"
                            />
                        )}
                    </Form.Item>
                    {/* 实名认证 * @function 
     */}
                    <Form.Item>
                        {getFieldDecorator('Authentication')(
                            <Select
                            style={{width:180}}
                            placeholder="选择认证状态"
                            onChange={this.handleSelectChange}
                            >
                                <Option value={0}>未认证</Option>
                                <Option value={1}>待认证</Option>
                                <Option value={2}>已认证</Option>
                                <Option value={3}>认证不通过</Option>
                            </Select>
                        )}
                    </Form.Item>
                    {/* 注册时间 * @function 
     */}
                    <Form.Item>
                        {getFieldDecorator('regDate',{
                            initiaValue:[moment('2015/08/08', dateFormat), moment('2015/08/08', dateFormat)]
                        })(
                            <RangePicker
                            style={{width:350}}
                            format={dateFormat}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('isAdminAdd', {
                            valuePropName: 'checked',
                        })(
                            <Checkbox>内部人员</Checkbox>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSubmit}>
                            搜 索
                        </Button>
                        <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                            重 置
                        </Button>
                    </Form.Item>
                </Form>
                <Table className="mt20"  
                    bordered title={title}
                    dataSource={this.state.UserList3Data}
                    scroll={{ x: 1500}}
                    columns={columns} 
                    rowSelection={rowSelection} 
                    pagination={false}
                /> 
                <Pagination {...this.state.pagination}
                    pageSize={this.state.params.pageSize}
                    className="fr mt20"
                />
                {/* 打标签-----弹出框 * @function 
     */}
                <Modal
                visible={this.state.visible}
                loading={this.state.loading}
                title="标签选择"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="确定"
                cancelText="取消"
                >
                    <Tagging record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
                {/* 批量打标签-----弹出 * @function 
     */}
                <Modal
                visible={this.state.batchvisible}
                loading={this.state.loading}
                title={this.state.BatchOkType==='add'?'请选择要打的标签':'请选择要取消的标签'}
                onOk={this.BatchOk}
                onCancel={this.BatchCancel}
                okText="确定"
                cancelText="取消"
                >
                    <TaggingBatch
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
            </div>
        )
    }
}

const UserSearchForm = Form.create()(UserList3);
export default UserSearchForm;