import React, { Component } from 'react'
import {Table,message,Icon} from 'antd'
import SearchForm from '../component/form/Search'    //搜索框组件
import { homeCheckList,getHomeCheckInfo,checkPass } from './../../../Mock'
import { getUserCertificationListData,getUserCertification,getFileUrl,auditUserCertification } from './../../../Api'
import { connect } from 'react-redux'
import store from '../../../Store'
import utils from './../../../Common/js/utils'
import { withRouter, NavLink } from 'react-router-dom'
import Checkmodal from './../component/form/Checkmodal'   //Modal组件
//首页-待认证用户-用户Tab
class Identity extends Component { 
    constructor(props){
        super(props)
        //监听store变化
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState())
          })      
    }
    state = {
        formList:[{
            name:"keyword",
            type:'input',
            placeholder:'姓名/手机号'
        }],
        //初始化Modal组件
        formModal: {
            title: '审核', 
            list: []
        },
        page:{
            pageNum:1,
            pageSize:30
        }
    }       
    //通过Api获取表格数据
    getList = (params)=>{
        this.setState({
            params:params
        })
        let data = {  ...params,...this.state.page }
        let _this = this
        getUserCertificationListData(data).then(res=>{
            console.log(res)
            if(res.data.state.value == 0){
                res.data.result.rows.map(item=>{
                    item.key = item.id
                    return item
                })
                this.setState({
                    tableList:res.data.result.rows,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        _this.state.page.pageNum=current
                        _this.state.page.pageSize=size
                        this.getList(params)
                    }, (current,size)=>{
                        _this.state.page.pageNum=current
                        _this.state.page.pageSize=size
                        this.getList(params)
                    }, res.data.result.pageSize)
                })
            }

        })
    }
    //弹框的“确认”回调 需返回Promise对象
    handleCheck = (data) => {
        data.id = this.state.currentId
        if(!data.reviewState)return 
        return auditUserCertification(data).then((res) => {
            return new Promise((resolve, reject) => {
                if (res.data.state.value == 0) {
                    this.setState({
                        visibleCheckModal: false
                    })
                    message.success('操作成功')
                    this.getList(this.state.params)                    
                    resolve(true)
                } else {
                    reject(false)
                }
            })
        })
    }
    //挂载前获取表格数据
    componentWillMount(){
        this.getList()
    }

    //获取文件url
    async getPicUrl(cardPositiveFileId,cardBackFileId){
        let picArr = []
        await new Promise((resolve,reject)=>{
            if(cardPositiveFileId){
                getFileUrl({fileId:cardPositiveFileId}).then(res=>{
                    console.log(res)
                    if(res.data.state.value == 0){
                        picArr.push(res.data.result)
                        resolve(res.data.result)
                    }else{
                        resolve(res.data.content)
                    }
                    }
                )
            }
            
        })
        await new Promise((resolve,reject)=>{
            if(cardBackFileId){
                getFileUrl({fileId:cardBackFileId}).then(res=>{
                    console.log(res)
                    if(res.data.state.value == 0){
                        picArr.push(res.data.result)
                        resolve(res.data.result)
                    }else{
                        resolve(res.data.content)
                    }
                    }
                )
            } 
        })
        return new Promise((resolve,reject)=>{
            if(picArr.length!=0){
                resolve(picArr)
            }else{
                resolve([])
            }
        })
    }

    //Modal-Form弹窗根据状态设定Modal内容
    handleOpenCheck = (record) => {
        let _this = this
        if(!record)return
        this.setState({
            currentId:record.id
        })
        this.getPicUrl(record.cardPositiveFileId,record.cardBackFileId).then(urlArr=>new Promise((resolve,reject)=>{
            getUserCertification({id:record.id}).then(res=>{
                if(res.data.state.value == 0){
                    _this.setState({
                        visibleCheckModal: true,
                        formModal: {
                            title: '认证审核', 
                            list: [{
                                name: "realName",
                                label: "真实姓名",
                                type: 'original',
                                value:res.data.result.fullname
                            },{
                                name: "identityNo",
                                label: "身份证号码",
                                type: 'original',
                                value:res.data.result.cardNo
                            },
                            {
                                name: "identiyPic",
                                label: "身份证正反照",
                                type: 'identiyPic',
                                value:urlArr.length!=0?urlArr:[]
                            },
                             {
                                name: "reviewState",
                                label: "审核结果",
                                type: 'radio',
                                radioList: [{
                                    label:'通过',
                                    value:2
                                },{
                                    label:'不通过',
                                    value:3
                                }],
                                rules:[{
                                    required: true,
                                    message: '请选择审核结果'
                                }],
                                initial:(res.data.result.reviewState != 2 || res.data.result.reviewState != 3)?null:res.data.result.reviewState
                            }, {
                                name: "reviewStateResult",
                                type: 'textArea',
                                label: "不通过原因",
                                placeholder: '请输入不通过原因',
                                initial:res.data.result.reviewStateResult
                            }]
                        },
                    },function(){
                        this.setState({
                            visibleCheckModal: true,
                        })
                    })
                }
            })
        }))
        
    }
    //更改弹框的显示状态
    handleVisibleChange = () => {
        this.setState({
            visibleCheckModal: !this.state.visibleCheckModal
        })
    }

    jumpTo = (record)=>{
        this.props.history.push({pathname: '/user/all/list2/basicp', state: {data: record,text:'平台用户'}})
    }
    render() {
        let _this = this
        const columns=[
            {
                title: "姓名",
                dataIndex: 'fullname',
                align: 'center',
                width: 50,
                render(text){
                    return <span title={text}>{text}</span>
                },
                
            },{
                title: "注册来源",
                dataIndex: 'regResource',
                align: 'center',
                width: 50,
                render(text){
                    return <span title={text}>{text}</span>
                },
                
            },{
                title: "手机号",
                dataIndex: 'mobile',
                align: 'center',
                width: 50,
                render(text){
                    return <span title={text}>{text}</span>
                },
                
            },{
                title: "申请时间",
                dataIndex: 'createDate',
                align: 'center',
                width: 50,
                render(text){
                    return <span title={text}>{text}</span>
                },
                
            },{
                title: "操作",
                align: 'center',
                width: 50,
                render(text,record){
                    return <div>
                        <a onClick={() => _this.jumpTo(record)}><Icon type="eye" title="查看" className="mr10 table-icon-blue"/></a>
                        <a onClick={() => _this.handleOpenCheck(record)}><Icon type="check-circle" title="审核" className="table-icon-green"/></a>
                    </div>
                },
                
            },{
                title: "身份证背面id",
                dataIndex: 'cardBackFileId',
                width: 0,
                className:'disnone'
            },{
                title: "身份证正面id",
                dataIndex: 'cardPositiveFileId',
                width: 0,
                className:'disnone'   
            }
        ]
        return (
            <div className="home">
                <SearchForm formList={this.state.formList} formSearchApi={(data)=>this.getList(data)}/>
                <Table
                    className="mt20"
                    bordered
                    columns={columns}
                    dataSource={this.state.tableList}
                    pagination={{...this.state.pagination, pageSize: this.state.page.pageSize}}
                />
                <Checkmodal formSubmitApi={(data)=>this.handleCheck(data)} handleVisibleChange={this.handleVisibleChange} visibleCheckModal={this.state.visibleCheckModal} formList={this.state.formModal} />

            </div>
        )
    }
}

export default withRouter(connect(null,null)(Identity));