import React, { Component } from 'react'
import { Route, Switch, Redirect} from "react-router-dom";

//对应页面
import OrderList from './OrderList'
import SalesmanList from './SalesmanList'
import AgentManagement from './AgentManagement'
import AgentManagementAdd from './AgentManagementAdd'
import AgentManagementEdit from './AgentManagementEdit'
import AgentManagementDetail from './AgentManagementDetail'

class AgentRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/agent/orderlist" component={OrderList} />
                    <Route path="/agent/salesmanlist" component={SalesmanList} />
                    <Route path="/agent/management/detail" component={AgentManagementDetail} />
                    <Route path="/agent/management/edit" component={AgentManagementEdit} />
                    <Route path="/agent/management/add" component={AgentManagementAdd} />
                    <Route path="/agent/management" component={AgentManagement} />
                    <Redirect exact from="/agent" to="/agent/orderlist" />
                </Switch>
            </div>
        )
    }
}

export default AgentRouter;