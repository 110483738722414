/**
 * @description 每周答题编辑
 * @author yzx yangzhixin177@163.com
 */
import React, { Component } from 'react'
import { Tabs, Radio,Card,Icon,Table,Modal,Form,message,Button} from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import SocialProForm from './components/BaseForm'
import {queryExamQuestionList,reCreateExam,queryQuestionList,updateExamQuestionList,saveExamOptionIndex, saveRiskFactors} from '../../../Api'
import './../../../Common/css/path.css'
const type = 'DragbleBodyRow';
/**
 * @description 拖拽表格函数
 * @function DragableBodyRow
 * @param {*} { index, moveRow, className, style, ...restProps }
 * @returns
 */
const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};
/**
 * @description 每周答题编辑组件
 * @class CategorymanageEdit
 * @extends {Component}
 */
class CategorymanageEdit extends Component {
    /**
     * @description 组件内数据
     * @param {Object} data 选择试题完成后-试题列表
     * @param {Object} problemData 题库列表
     * @param {String} transId 试卷ID
     * @param {Object} params 页码，每页大小
     * @memberof CategorymanageEdit
     */
    state={
        data:[],
        problemData:[],
        transId:'',
        params:{
          pageNum:1,
          pageSize:100
      }
    }
    /**
     * @description 清空表格选中项
     * @function resetCheckedList
     * @memberof CategorymanageEdit
     */
    resetCheckedList = ()=>{
      this.setState({
          selectedRowKeys:[],
          selectedRows:null,
          selectedIds:[],
          selectItem:{},
      })
  }
    /**
     * @description 选中表中数据后，保存到state
     * @function rowCheckSelection
     * @memberof CategorymanageEdit
     */
    rowCheckSelection = () => {
      return {
          selectedRowKeys: this.state.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
              let ids = []
              selectedRows.map((item) => {
                  ids.push(item.id)
              })
              this.setState({selectedRowKeys, selectedIds: ids, selectedRows})
          }
      }
    }
    /**
     * @description 选择试题
     * @function selectPro
     * @memberof CategorymanageEdit
     */
    selectPro =()=>{
      let _this=this
      queryQuestionList({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize,status:2}).then((res)=>{
          this.setState({
            problemData:res.data.result.rows
          })
      })
      this.setState({
          title:'选择试题',
          isProblemDataVisible:true
      })
    }
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof CategorymanageEdit
     */
    renderModalSimple = (type,title,content) =>{
      return Modal[type?type:'info']({ 
          title:title?title:'警告',
          content:content?content:''
      })
  }
    /**
     * @description 点击取消后关闭弹窗
     * @function handleCancel
     * @memberof CategorymanageEdit
     */
    handleCancel=()=>{
      this.setState({
        isProblemDataVisible:false
      })
    }
    /**
     * @description 保存试题，请求接口，保存成功后刷新数据
     * @function handleSave
     * @memberof CategorymanageEdit
     */
    handleSave = ()=>{
      let transId=this.state.transId
      let _this = this   
      // console.log(this.state.selectedRows)
      if(this.state.selectedIds.length <5){
          this.renderModalSimple('warning','提醒','必须选择5道题目！')
          return
      }
      if(this.state.selectedIds.length >5){
        this.renderModalSimple('warning','提醒','不能选择超过5道题目！')
        return
    }
      let idStr = ''
      this.state.selectedIds.map(item=>{
          idStr += item + ','
      })
      let list=idStr.substring(0,idStr.lastIndexOf(','));  
      updateExamQuestionList({examId:transId,'questionIdList[]':list}).then((res)=>{
        // console.log(res.data._data)
        if(res.data.state.value == 0&&!res.data.result){
          this.reflash()
          message.success('操作成功')
      }else{
        this.renderModalSimple('warning','提醒','操作失败!')
      }
    })
        this.setState({
          data:this.state.selectedRows,
          isProblemDataVisible:false
        })
        this.resetCheckedList()

  }
    /**
     * @description 刷新页面显示的试题（请求成功后）
     * @function reflash
     * @memberof CategorymanageEdit
     */
    reflash=()=>{
      let transId=this.state.transId
      queryExamQuestionList({examId:transId}).then((res)=>{
        // console.log(res.data)
        this.setState({
            data:res.data.result
        })
    })
    }
    /**
     * @description 自动生成试题,请求自动生成试题接口
     * @function autoCreate
     * @memberof CategorymanageEdit
     */
    autoCreate = ()=>{
      let transId=this.state.transId
      // console.log(transId)
      reCreateExam({examId:transId}).then((res)=>{
          // console.log(res.data._data)
          if(res.data.state.value == 0&&!res.data.result){
            this.reflash()
            message.success('操作成功')
        }else{
          this.renderModalSimple('warning','提醒','操作失败!')
        }
      })
  }
    /**
     * @description react周期函数，请求试题列表接口
     * @function componentDidMount
     * @memberof CategorymanageEdit
     */
    componentDidMount() {
      const id = this.props.location.pathname ? this.props.location.pathname.split('/').reverse()[0] : 'edit'
      // console.log(this.props.location.state.name)
        if (id !== 'edit') {
          queryExamQuestionList({examId:id}).then((res)=>{
            // console.log(res.data)
            this.setState({
                data:res.data.result,
                transId:id
            })
        })
        }
    }
    components = {
        body: {
          row: DragableBodyRow,
        },
      };
     /**
     * @description 表格行移动
     * @function moveRow
     * @param {string} dragIndex 移动前行的下标
     * @param {string} hoverIndex 移动后行的下标
     * @memberof CategorymanageEdit
     */
    moveRow = (dragIndex, hoverIndex) => {
        const { data } = this.state;
        const dragRow = data[dragIndex];
        this.setState(
          update(this.state, {
            data: {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
              ],
            },
          }),
        );
      };
    /**
     * @description 保存排序顺序
     * @function saveSequence
     * @memberof CategorymanageEdit
     */
    saveSequence=()=>{
        const SequenceList=this.state.data;
        let transId=this.state.transId;
        let SequenceIds = ''
        SequenceList.map(item=>{
          SequenceIds += item.examQuestionId + ','
        })
        let Sequence=SequenceIds.substring(0,SequenceIds.lastIndexOf(',')); 
        saveExamOptionIndex({examId:transId,'examQuestionIdList[]':Sequence}).then((res)=>{
          // console.log(res.data._data)
          if(res.data.state.value == 0&&!res.data.result){
            this.reflash()
            message.success('操作成功')
        }else{
          this.renderModalSimple('warning','提醒','操作失败!')
        }
    })
  }
      render() {
        const len=this.state.selectedIds?this.state.selectedIds.length:0
        const columns = [
            {
                title: '序号',
                key:'key',
                dataIndex: 'key',
                render:(text,record,index)=>`${index+1}`
            }, {
                title: '试题类型',
                key: 'questionType',
                dataIndex: 'questionType',
                render(text) {
                  if(text==1){
                      return '单选题'
                  }
                  if(text==2){
                      return '多选题'
                  }
                  else{
                      return '填空题'
                  }
              },
            },{
                title: '题干',
                key: 'stem',
                dataIndex: 'stem',
            },{
              title: '答案',
              key: 'result',
              dataIndex: 'result',
              render(text,record) {
                if(record.questionType==1){
                    return <div>
                    <div className={record.result=='A'?'show':'none'}>{record.optionA}</div>
                    <div className={record.result=='B'?'show':'none'}>{record.optionB}</div>
                    <div className={record.result=='C'?'show':'none'}>{record.optionC}</div>
                    <div className={record.result=='D'?'show':'none'}>{record.optionD}</div>
                    </div>
                }
                if(record.questionType==2){
                    let showA,showB,showC,showD
                    for(var i=0;i<record.result.length;i++){
                        if(record.result[i]=='A'){
                            showA=true
                        }
                        if(record.result[i]=='B'){
                            showB=true
                        }
                        if(record.result[i]=='C'){
                            showC=true
                        }
                        if(record.result[i]=='D'){
                            showD=true
                        }
                    }
                    return <div>
                    <div className={showA==true?'show':'none'}>{record.optionA}</div>
                    <div className={showB==true?'show':'none'}>{record.optionB}</div>
                    <div className={showC==true?'show':'none'}>{record.optionC}</div>
                    <div className={showD==true?'show':'none'}>{record.optionD}</div>
                    </div>
                }
                else if(record.questionType==3){
                    return <span>{record.fillResult}</span>
                }
            },
          }
        ]; 
        return (
          <Card>
              <div className='weekTitle'>
                <div className='weekTitle1'>名称</div>
                <div className='weekTitle2'>{this.props.location.state.name}</div>
                </div>
              <div style={{ marginBottom: 16, marginTop: 16 }}>
                    <div className="fr">
                            <Button style={{ marginLeft: 10,backgroundColor: '#808080',borderColor:'#808080',color:'#fff'}} onClick={()=>this.saveSequence()}>保存顺序</Button>
                            <Button style={{ marginLeft: 10,backgroundColor: '#52C41A',borderColor:'#52C41A',color:'#fff'}} onClick={()=>this.autoCreate()}>自动生成</Button>
                            <Button type="primary" style={{ marginLeft: 10 }} onClick={()=>this.selectPro()}>
                                    选择试题
                            </Button>
                    </div>
                    <div className="clear"></div>
                </div>
              <DndProvider backend={HTML5Backend}>
                <Table className='move'
                        bordered
                        columns={columns}
                        components={this.components}
                        dataSource={this.state.data}
                        onRow={(record, index) => ({
                            index,
                            moveRow: this.moveRow,
                          })
                        }
                        rowKey={(record,index)=> index}
                        pagination={false}
                    /> 
                </DndProvider>
                <Modal className='ProblemData'
                    title={this.state.title}
                    visible={this.state.isProblemDataVisible}      
                    onOk={()=>{this.handleSave()}}
                    onCancel={()=>{this.handleCancel()}}
                    footer={[
                      <span key='num' style={{marginRight:20}}>已选{len}题</span>,
                      <Button key="back" onClick={()=>this.handleCancel()}>
                        取消
                      </Button>,
                      <Button key="submit" type="primary" onClick={()=>this.handleSave()}>
                        保存
                      </Button>,
                    ]}
                >
                     <SocialProForm resetCheckedList={this.resetCheckedList} rowCheckSelection={this.rowCheckSelection} problemData={this.state.problemData} wrappedComponentRef={(inst) => this.SocialProForm = inst }/>
                </Modal>
          </Card>
        );
      }
} 
export default CategorymanageEdit