// 关闭订单

import React, { Component } from 'react'
import { Form, Checkbox,Input } from 'antd'
const{TextArea}=Input;
class ColoseOrder extends Component {
    getItemsValue = ()=>{ 
        const valus= this.props.form.getFieldsValue();
        return valus;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="closeOrderFrom">
                <p style={{color:'#999'}}>关闭后用户无法继续咨询</p>
                <Form.Item style={{width:'100%'}}>
                    {getFieldDecorator('checkbox-group', {
                        initialValue: [this.props.record?this.props.record:''],
                    })(
                        <Checkbox.Group style={{ width: '100%' }}>
                            <Checkbox value="Refunds">已退款</Checkbox>
                        </Checkbox.Group>
                    )}
                </Form.Item>
                <Form.Item style={{width:'100%'}}>
                    {getFieldDecorator('reasons')(
                        <TextArea style={{width:'100%'}} rows={4} placeholder='请输入关闭理由' />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const ColoseOrderForm = Form.create()(ColoseOrder);

export default ColoseOrderForm;
