import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Select, Input, DatePicker } from 'antd'
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
/**
 * Search-Form组件
 * 内容：Form
 * 功能：对接请求表格数据接口，完成搜索功能
 * 父组件传入属性：
 *      formSearchApi       提交搜索表单的方法（即请求表格数据的列表方法等）
 *      formList[]          配置Serach-Form显示的对象
 */
class SearchForm extends Component {
    state = {
        formItemList: []
    }
    //Modal-Form提交事件
    submit = () => {
        this.props.form.validateFields((err, value) => {
            //调用父组件传入的提交Api方法
            this.props.formSearchApi({ ...value,pageNum:1,pageSize:30})
            // this.props.searchSubmit(value,this.props.formSearchApi({...value,pageNum:1,pageSize:30}))
        })
    }
    //清空表单
    reset = () => {
        this.props.form.resetFields();
        this.submit()
    }
    render() {
        const { getFieldDecorator } = this.props.form
        const { isCheck } = this.props?this.props:false
        let obj = isCheck?this.props.optionalObj:{}
        //Modal展示内容
        let formItemList = []
        //通过父组件传入的配置  渲染Modal内容
        this.props.formList.map((item, index) => {
            switch (item.type) {
                case 'input':
                    let inputForm = <FormItem key={item.name + index}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: []
                        })(
                            <Input placeholder={item.placeholder} style={{ width: 205 }} />
                        )
                        }
                    </FormItem>
                    formItemList.push(inputForm)
                    break;
                case 'select':
                    let selectForm = <FormItem key={item.name + index}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: []
                        })(
                            <Select style={{ width: 150 }} placeholder={item.placeholder}>
                                {item.optionList.map(kid => {
                                    return <Option value={kid.value} key={kid.label + kid.value}>{kid.label}</Option>
                                })}
                            </Select>
                        )
                        }
                    </FormItem>
                    formItemList.push(selectForm)
                    break
                case 'rangePicker':
                    let rangePickerForm = <FormItem key={item.name + index}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: []
                        })(
                            <RangePicker placeholder={item.placeholder} />
                        )
                        }
                    </FormItem>
                    formItemList.push(rangePickerForm)
                    break
                default:
                    break

            }
        })
        return (
            <div className="home">
                <Form layout="inline" className="search-box">
                    {formItemList}
                    <FormItem>
                        <Button type="primary" onClick={this.submit}>搜索</Button>
                    </FormItem>
                    <FormItem>
                        <Button onClick={this.reset}>重置</Button>
                    </FormItem>
                    {
                        isCheck?
                        <FormItem>
                            <Button onClick={obj.fn}>{obj.buttonName}</Button>
                        </FormItem>
                        :null
                    }
                </Form>
            </div>
        )
    }
}

SearchForm = Form.create({})(SearchForm);

//redux用法样例
const mapstateDispatch = (state) => {
    console.log(state)
    return {
        // home:state.home
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // searchSubmit(data, callback) {
        //     const action = searchHomeCheck(data, callback);
        //     dispatch(action);
        // }
    }
}

export default connect(mapstateDispatch, mapDispatchToProps)(SearchForm);