import React, { Component } from 'react'

import { Resizable } from 'react-resizable'
import { Form, Input, Button, Select, Checkbox, Cascader, Table, Modal, Tabs, Pagination, message,Tag,DatePicker} from 'antd'
import {getUserUnitList,getAreaOptions,isAdminAdd,saveRemark,getSoftBuyBatch} from './../../Api/index'
import TaggingBatch from './components/Common/TaggingBatch'//批量打标签/取消标签
import Tagging from './components/Common/Tagging'//打标签
import Notebook from './components/Common/Notebook'//备注
import moment from 'moment';

const { Option } = Select
const { TabPane } = Tabs
const { MonthPicker, RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const monthFormat = 'YYYY/MM';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const ResizeableTitle = props => {
    const { onResize, width, ...restProps } = props;
    if (!width) {
      return <th {...restProps} />;
    }
    return (
      <Resizable
        width={width}
        height={0}
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps} />
      </Resizable>
    );
  };
class User extends Component {
    state={
        keys:1,
        columns:[
            {title: "序号",dataIndex: 'key',align: 'center',width: 40,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '手机号',dataIndex: 'legalTel',align:'center',width:120,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '注册来源',dataIndex: 'dataSourceStr',align:'center',width:120,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '注册时间',dataIndex: 'regDate',align:'center',width:120,render(text){
                return <span title={text}>{text}</span>
            },},
            // {title: '所属行业',dataIndex: 'industryCategoryStr',align:'center',width:90},
            {title: '标签',dataIndex: 'isAdminAdd',align:'center',width:80,render:(text,record)=>{
                if(text == true){
                    return (
                        <Tag color='green' key={text}>
                        内部人员
                        </Tag>
                    ) 
                }
            }},

            {title: '操作',dataIndex: 'operation',width:150,align:'center',render: (text,record) => {
                return (
                    <div>
                        <a className="blue mlr5" onClick={() => this.jumpTo(record)}>查看</a>
                        <a className="blue mlr5" onClick={() => this.showModal(record)}>打标签</a>
                    </div>
                )
            }},
            {title: 'cid',dataIndex: 'cid',align:'center',className:'dn',width:0},
            {title: 'companyPlatformAdminId',dataIndex: 'companyPlatformAdminId',align:'center',className:'dn',width:0},
        ]
    };

    //批量打标签弹出显示状态
    showModalBatch = (type) => {
        console.log(this.state.selectedRows,'this.state.selectedRows')
        if(this.state.selectedRows.length == 0){
            this.renderModalSimple('warning','提醒','请选择至少一条信息')
        }else{
            this.setState({
                batchvisible: true,
                BatchOkType:type
            });
        }
    };
    //批量打标签弹出框确定按钮操作-待接口校验
    BatchOk = () => {
        this.setState({ loading: true });
        let idArr = []    
        this.state.selectedRows.map(item=>{
            idArr.push(item.companyPlatformAdminId)
        })
        let params = {idArr:idArr}
        let checkGrpoup = this.formRef.getItemsValue()['checkbox-group']
        if(this.state.BatchOkType === 'remove'){
            if(checkGrpoup){
                checkGrpoup.map(item=>{
                    if(item == 'isAdminAdd'){
                        params.isAdminAdd = false
                    }
                })
            }
        }else if(this.state.BatchOkType === 'add'){
            if(checkGrpoup){
                checkGrpoup.map(item=>{
                    if(item == 'isAdminAdd'){
                        params.isAdminAdd = true
                    }
                })
            }
        }
        //批量打标签，后台接口未完成
        isAdminAdd(params).then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                message.success('操作成功！')
                this.setState({ loading: false, batchvisible: false });
                this.requestTableList()
            }else{
                this.renderModalSimple('warning','错误','操作失败，请重新提交！')
            }
            this.formRef.resetValue()
        })
        
    };
    //批量打标签弹出框取消按钮操作
    BatchCancel = () => {
        this.formRef.resetValue()
        this.setState({ batchvisible: false });
    };
    //打标签弹出显示状态
    showModal = (record) => {
        this.setState({
            visible: true,
            record:record,
        });
    };
    //打标签弹出框确定按钮操作
    handleOk = () => {
        this.setState({ loading: true });
        // console.log(this.state.record,'15')
        let idArr = []
        idArr.push(this.state.record.companyPlatformAdminId)
        let params = {idArr:idArr}
        let checkGrpoup = this.formRef.getItemsValue()['checkbox-group']
        if(checkGrpoup){
            checkGrpoup.map(item=>{
                if(item == 'isAdminAdd'){
                    params.isAdminAdd = true
                }
            })
        }
        //批量打标签，后台接口未完成
        isAdminAdd(params).then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                message.success('操作成功！')
                this.setState({ loading: false, visible: false });
                this.requestTableList()                           
            }else{
                this.renderModalSimple('warning','错误','操作失败，请重新提交！')
            }
            this.formRef.resetValue()
        })
    };
    //打标签弹出框取消按钮操作
    handleCancel = () => {
        this.formRef.resetValue()
        this.setState({ visible: false });
    };
    //下拉
    handleSelectChange = value => {
        // console.log(value);
        this.props.form.setFieldsValue({
            //note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
        });
    };
    //搜索按钮
    handleSubmit = ()=> {
        let fieldsParams = this.props.form.getFieldsValue()
        // console.log(fieldsParams)
        let _this = this
        new Promise((resolve,reject)=>{
            _this.setState({
                params:{
                    areaId:fieldsParams.areaId&&fieldsParams.areaId[fieldsParams.areaId.length-1],
                    dataSource:fieldsParams.dataSource,
                    name:fieldsParams.name,
                    regType:fieldsParams.regType,
                    isAdminAdd:fieldsParams['checkbox-group']&&fieldsParams['checkbox-group'].length?true:false,
                    pageNum:1,
                    pageSize:30
                }
            })
            resolve()
        }).then(()=>{this.requestTableList()})
        
    };
    //重置按钮
    handleReset = () => {
        this.props.form.resetFields();
        this.setState({
            params:{
                pageNum:1,
                pageSize:30
            }
        })
        this.requestTableList(this.state.params)
    };



    render() {
        const { getFieldDecorator } = this.props.form;
        //表格 表头
        const columns = this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: this.handleResize(index),
            }),
          }));
        return (
            <div>
                {/* 顶部条件搜索 */}
                <Form layout="inline">
                    <Form.Item>
                        {getFieldDecorator('legalTel')(
                            <Input
                            style={{width:180}}
                            placeholder="手机号"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('dataSourceStr')(
                            <Select
                            style={{width:180}}
                            placeholder="--请选择注册来源--"
                            onChange={this.handleSelectChange}
                            >
                                <Option value={1}>平台用户</Option>
                                <Option value={2}>健康顾问</Option>
                                <Option value={3}>医生用户</Option>
                            </Select>
                        )}
                    </Form.Item>
                    {/* 注册时间 */}
                    <Form.Item>
                        {getFieldDecorator('regDate')(
                            <RangePicker
                            style={{width:350}}
                            defaultValue={[moment('2015/08/08', dateFormat), moment('2015/08/08', dateFormat)]}
                            format={dateFormat}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('checkbox-group')(
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Checkbox value="isAdminAdd">内部人员</Checkbox>
                            </Checkbox.Group>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSubmit}>
                            搜 索
                        </Button>
                        <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                            重 置
                        </Button>
                    </Form.Item>
                </Form>
                <Table className="mt20"  

                    columns={columns} 
  
                /> 
                    

            </div>
        )
    }
}

export default User; 