/*
 * @file: 查看各种文章的组件
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import { artivleView, getFile } from '../../../Api/index'
import { Modal, message } from 'antd'
/**
 * @classdesc 查看文章的父组件
     */
class LookNote extends Component {
    constructor(props) {
        super(props);
        /**
         * @description: state自定义
         * @param {object} content 具体内容
         */
        this.state = {
            content: props.location.state ? props.location.state.data : null
        };
    }
    componentWillMount() {
        this.lookAlldata()
    }
    /**
     * 获取查看文章数据 
     * @function lookAlldata
     */
    lookAlldata = () => {
        let _this = this
        artivleView(this.state.content.id).then(res => {
            if (res.data.state.value === 0) {
                switch (res.data.result.type) {
                    case 1:
                        res.data.result.type = '职业卫生综合';
                        break;
                    case 2:
                        res.data.result.type = '职业危害防护';
                        break;
                    case 3:
                        res.data.result.type = '职业健康监护';
                        break;
                    case 4:
                        res.data.result.type = '职业危害因素';
                        break;
                    case 5:
                        res.data.result.type = '职业卫生三同时';
                        break;
                    case 6:
                        res.data.result.type = '职业病';
                        break;
                    default:
                        res.data.result.type = '放射卫生';
                        break;
                }
                res.data.result.shelvesTime = res.data.result.shelvesTime ? _this.formatDate(res.data.result.shelvesTime) : null
                let data = res.data.result

                // 获取图片详细情况
                getFile({ fileId: data.screenshot }).then(res => {
                    if (res.status === 200 && res.statusText === "OK") {
                        data.textImage = res.data.result ? res.data.result : []
                        _this.setState({
                            data,
                            faData: data.law
                        })
                    }
                })
            }
        })
    }

    /**
      * 时间戳转换
      * @param {Date} now 时间戳
      * @return: {Date} 显示格式
      * @function formatDate
     */
    formatDate = (now) => {
        var nowDate = new Date(now)
        var year = nowDate.getFullYear();  //取得4位数的年份
        var month = nowDate.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
        var date = nowDate.getDate();      //返回日期月份中的天数（1到31）
        return year + "-" + month + "-" + date;
    }
    render() {
        const data = this.state.data
        return (
            <div className="home lookNote-detail">
                {
                    data ? data.lawId ?
                        <LookFa content={data} faData={this.state.faData} ></LookFa>
                        :
                        <LookText content={data} ></LookText>
                        : <span>请稍后</span>
                }
            </div>
        )
    }
}
export default LookNote;
/**
 * @classdesc 文章详情
 */
class LookText extends Component {
    render() {
        const data = this.props.content
        return (
            <div>
                <table
                    className='text-align-center width-100 article-lookNote mb10'
                >
                    <tbody>
                        <tr>
                            <td>

                                <span>文章名称</span>
                            </td>
                            <td colSpan={3} className='text-align-left'>
                                <span>{data ? data.name : null}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>类别</span>
                            </td>
                            <td colSpan={3} className='text-align-left'>
                                <span>{data ? data.type : null}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>来源</span>
                            </td>
                            <td colSpan={3} className='text-align-left'>
                                <span>{data ? data.source : null}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>

                                <span>文章内容</span>
                            </td>
                            <td colSpan={3}>
                                <div className='Html-style'
                                    style={{
                                        textAlign: 'initial',
                                        height: '400px',
                                        overflowY: 'auto',
                                        whiteSpace: 'normal'
                                    }}
                                    dangerouslySetInnerHTML={{ __html: data ? data.content : null }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>文章缩略图</span>
                            </td>
                            <td colSpan={3} className='text-align-left'>
                                {
                                    data.textImage.fullOssurl ?
                                        <img style={{ height: 170 }} src={data.textImage.fullOssurl} alt="" />
                                        : null
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>文章摘要</span>
                            </td>
                            <td colSpan={3} className='text-align-left' style={{ whiteSpace: 'normal' }}>
                                <span style={{ display: 'block', textIndent: '2rem' }}>{data ? data.summary : null}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>

                                <span>编制人</span>
                            </td>
                            <td className='value-td'>
                                <span className='text-align-left'>
                                    {data ? data.compiler : null}
                                </span>

                            </td>
                            <td>

                                <span>上架时间</span>
                            </td>
                            <td>
                                <span className='text-align-left'>
                                    {data ? data.shelvesTime : null}
                                </span>

                            </td>
                        </tr>
                        <tr>
                            <td>

                                <span>阅读数</span>
                            </td>
                            <td className='value-td'>
                                <span className='text-align-left'>
                                    {data ? data.hot : null}
                                </span>

                            </td>
                            <td>

                                <span>收藏数</span>
                            </td>
                            <td>
                                <span>
                                    {data ? data.collect : null}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

/**
 * @classdesc 法规详情
 */
class LookFa extends Component {
    /**
     * @description: state自定义
     * @param {boolean} isPDFShow 判断值
     */
    state = {
        isPDFShow: false
    }
    /**
  * 查看pdf文件
  * @param {string} url 
  * @function lookPdf
     */
    lookPdf = (url) => {
        let _this = this
        if (url) {
            let urlList = url.split('.')
            if (urlList[urlList.length - 1] == 'pdf') {
                _this.setState({
                    isPDFShow: true,
                    PDFUrl: url
                })
            } else {
                _this.setState({
                    isPDFShow: true,
                    PDFUrl: "https://view.officeapps.live.com/op/view.aspx?src=" + url
                })
            }
        } else {
            message.warning('暂无相关PDF可查看')
        }
    }
    render() {
        const data = this.props.content ? this.props.content : []
        const faData = this.props.faData ? this.props.faData : []
        return (
            <div>
                <table
                    className='text-align-center width-100 article-lookNote mb10'
                >
                    <tbody>
                        <tr>
                            <td>
                                <span>法规标准名称</span>
                            </td>
                            <td style={{ width: '30%', paddingLeft: '10px' }} className="text-align-left pl10">
                                <span>{faData.lawName}</span>
                            </td>
                            <td>
                                <span>文号</span>
                            </td>
                            <td className="text-align-left" style={{ paddingLeft: '10px' }}>
                                <span>{faData.lawNo}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>状态</span>
                            </td>
                            <td className="text-align-left" style={{ paddingLeft: '10px' }}>
                                <span>{faData.status}</span>
                            </td>
                            <td>
                                <span>法律效力</span>
                            </td>
                            <td className="text-align-left" style={{ paddingLeft: '10px' }}>
                                <span>{faData.lawClassName}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>法规标准附件</span>
                            </td>
                            <td colSpan={3} className="text-align-left" style={{ paddingLeft: '10px' }}>
                                <a onClick={() => this.lookPdf(faData.ossFullUrl)}>{faData.lawName}.pdf</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>类别</span>
                            </td>
                            <td colSpan={3} className=' text-align-left'>
                                <span>{data.type}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>来源</span>
                            </td>
                            <td colSpan={3} className=' text-align-left'>
                                <span>{data.source}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>文章缩略图</span>
                            </td>
                            <td colSpan={3} className='text-align-left'>
                                {
                                    data.textImage.fullOssurl ?
                                        <img style={{ width: 300, height: 170 }} src={data.textImage.fullOssurl} alt="" />
                                        : null
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>文章摘要</span>
                            </td>
                            <td colSpan={3} className='text-align-left' style={{ whiteSpace: 'normal' }}>
                                <span style={{ display: 'block', textIndent: '2rem' }}>{data ? data.summary : null}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>

                                <span>编制人</span>
                            </td>
                            <td className='value-td text-align-left'>
                                <span>
                                    {data ? data.compiler : null}
                                </span>

                            </td>
                            <td>

                                <span>上架时间</span>
                            </td>
                            <td className='text-align-left'>
                                <span>
                                    {data ? data.shelvesTime : null}
                                </span>

                            </td>
                        </tr>
                        <tr>
                            <td>

                                <span>阅读数</span>
                            </td>
                            <td className='value-td text-align-left'>
                                <span>
                                    {data ? data.hot : null}
                                </span>

                            </td>
                            <td>

                                <span>收藏数</span>
                            </td>
                            <td className='text-align-left'>
                                <span>
                                    {data ? data.collect : null}
                                </span>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <Modal
                    visible={this.state.isPDFShow}
                    title="预览文件"
                    footer={null}
                    onCancel={() => {
                        this.setState({
                            isPDFShow: false
                        })
                    }}
                    centered
                    width={800}
                >
                    <iframe width={750} height={600} src={this.state.PDFUrl} title="PDFperview"></iframe>
                </Modal>
            </div>
        )
    }
}

