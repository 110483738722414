import React, { Component } from 'react'
import { Route, Switch, Redirect} from "react-router-dom";

//对应页面
import Home from './Home'
import Check from './check/Check'
import Suggestion from './suggestion/Suggestion'
import Legal from './legal/Legal'
import Application from './application/Application'
class HomeRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    {/* 首页 */}
                    <Route path="/home/index" component={Home} />
                    {/* 待认证用户 */}
                    <Route path="/home/check" component={Check} />  
                    {/* 意间处理 */}
                    <Route path="/home/suggestion" component={Suggestion} />
                    {/* 法规库 */}
                    <Route path="/home/legal" component={Legal} />
                    {/* 试用申请 */}
                    <Route path="/home/application" component={Application} />
                    {/* 默认跳转 */}
                    <Redirect exact from="/home" to="/home/index" />
                </Switch>
            </div>
        )
    }
}

export default HomeRouter;