/**
 * @file 多选题单选题表单内四个选项填空组件
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Input } from 'antd'

/**
 * 多选题单选题表单内四个选项填空组件
 * @class
 * @extends React.Component
 */
export default class SingleFourOptions extends React.Component {

    /**
     * Inout目标的值发生变化时，进行的操作
     * @type {Function} 
     * @param {Object} e 当前目标
     * @param {Number} index 目标在所有值当中的排序
     */
    HandleChange = (e, index) => {
        const { onChange, value } = this.props
        value[index] = e.target.value
        if (onChange) {
            onChange( value )
        }
    }

    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => <div>
                <span style={{marginRight: '1em'}}>A</span><Input style={{width: '80%'}} value={this.props.value[0]} onChange={e => {this.HandleChange(e, 0)}} /><br />
                <span style={{marginRight: '1em'}}>B</span><Input style={{width: '80%'}} value={this.props.value[1]} onChange={e => {this.HandleChange(e, 1)}} /><br />
                <span style={{marginRight: '1em'}}>C</span><Input style={{width: '80%'}} value={this.props.value[2]} onChange={e => {this.HandleChange(e, 2)}} /><br />
                <span style={{marginRight: '1em'}}>D</span><Input style={{width: '80%'}} value={this.props.value[3]} onChange={e => {this.HandleChange(e, 3)}} />
            </div>
}