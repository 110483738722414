import React, { Component } from 'react'
import {Card,Button,Form,Input,Select,Cascader,InputNumber,message} from 'antd'
import { viewCompany,editCompany } from './../../../../Api'
import STATUS from './../../../../Common/js/status'
import moment from 'moment'
const { Option } = Select

class UnitHealth extends Component {
    // 状态机
    state={
        display_none: 'none', //此状态机为display的取值
        display_block: 'block', //此状态机为display的取值
        cid:this.props.record,
        companyInfo:{},
    }
    //组件内获取信息接口-弃用
    requestList = ()=>{
        viewCompany(this.state.cid).then(res=>{
            if(res.status === 200 && res.data.state.value ===0){
                this.setState({
                    companyInfo:res.data.result
                })
            }else{
                message.warning('获取企业信息失败！')
            }
        })
    }
    componentDidMount=()=>{
        this.props.form.validateFields();
    }
    componentWillReceiveProps=()=>{
        //父组件内获取信息接口
        this.setState({
            companyInfo:this.props.companyInfo()
        })
    }
    //点击编辑
    switchTal = () => {
        this.setState({
            display_none: 'block',
            display_block: 'none',
        })
    }
    //保存按钮
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                let params = {
                    cid:this.state.cid,
                    companyBaseModel:{}
                }
                //companyBaseModel部分（cid\id必传）
                params.companyBaseModel.id = this.state.companyInfo.companyBaseModel.id//id
                params.companyBaseModel.chemicalEmergencyPhone = values.chemicalEmergencyPhone//化学事故应急咨询服务电话
                params.companyBaseModel.occLeaderTel = values.occLeaderTel//职业卫生负责人联系电话
                params.companyBaseModel.occLeaderSid = values.occLeaderSid//职业卫生负责人
                params.companyBaseModel.pickupCount = values.pickupCount//接害人数
                params.companyBaseModel.staffCount = values.staffCount//在岗职工人数
                params.companyBaseModel.occupationalRiskClassify = values.occupationalRiskClassify//职业病危害风险分类
                //地址暂时无法对接
                // params.companyBaseModel.registerAddress = values.registerAddress
                // params.companyBaseModel.companyPostalAdress = values.companyPostalAdress
                // params.companyBaseModel.workAddress = values.workAddress
                editCompany(params).then((res)=>{
                    if(res.status===200){
                        this.props.form.resetFields();//取消先清空之前输入的数据
                        this.setState({ //跳转到查看页面
                            display_none: 'none',
                            display_block: 'block',
                        })
                        this.props.requestList().then(res=>{
                            this.setState({
                                companyInfo:res
                            })
                        })
                    }
                })
            }
        });
    };
    //取消按钮
    handleReset = () => {
        this.props.form.resetFields();//取消先清空之前输入的数据
        this.setState({ //跳转到查看页面
            display_none: 'none',
            display_block: 'block',
        })
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {companyInfo} = this.state
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="职业卫生相关信息" 
                extra={
                    <Button style={{display:this.state.display_block}} type="primary" onClick={this.switchTal}>编辑</Button>
                }>
                    <Form className="unitBasicView" style={{display:this.state.display_block}} layout="inline">
                        <Form.Item label="职业病危害风险分类">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?STATUS.RISK_STATUS[companyInfo.companyBaseModel.occupationalRiskClassify]:' '}</div>
                        </Form.Item>
                        <Form.Item label="在岗职工人数">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.staffCount:' '}</div>
                        </Form.Item>
                        <Form.Item label="接害人数">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.pickupCount:' '}</div>
                        </Form.Item>
                        <Form.Item label="职业卫生负责人">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.occLeaderSid:' '}</div>
                        </Form.Item>
                        <Form.Item label="职业卫生负责人联系电话">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.occLeaderTel:' '}</div>
                        </Form.Item>
                        <Form.Item label="化学事故应急咨询服务电话">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.chemicalEmergencyPhone:' '}</div>
                        </Form.Item>
                        <Form.Item label="申报系统名称">
                            <div className="unitBasicData">{companyInfo.companySuperviseModel?companyInfo.companySuperviseModel.reportCompanyName:' '}</div>
                        </Form.Item>
                        <Form.Item label="申报日期">
                            <div className="unitBasicData">{companyInfo.companySuperviseModel&&companyInfo.companySuperviseModel.reportDate?moment(companyInfo.companySuperviseModel.reportDate).format('YYYY-MM-DD'):' '}</div>
                        </Form.Item>
                        <Form.Item label="申报类型">
                            <div className="unitBasicData">{companyInfo.companySuperviseModel?companyInfo.companySuperviseModel.reportType:' '}</div>
                        </Form.Item>
                        <Form.Item className="kongForm"></Form.Item>
                    </Form>

                    <Form className="unitBasicEdit" style={{display:this.state.display_none}} layout="inline" onSubmit={this.handleSubmit}>
                        <Form.Item label="职业病危害风险分类">
                            {getFieldDecorator('occupationalRiskClassify',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.occupationalRiskClassify:'',
                            })(
                                <Select
                                placeholder="职业病危害风险分类"
                                onChange={this.handleSelectChange}
                                >
                                    <Option value={2}>一般</Option>
                                    <Option value={1}>较重</Option>
                                    <Option value={0}>严重</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="在岗职工人数">
                            {getFieldDecorator('staffCount',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.staffCount:'',
                            })(
                                <InputNumber placeholder="在岗职工人数" />
                            )}
                            <span className="ant-form-text"> 人</span>
                        </Form.Item>
                        <Form.Item label="接害人数">
                            {getFieldDecorator('pickupCount',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.pickupCount:'',
                            })(
                                <InputNumber placeholder="接害人数" />
                            )}
                            <span className="ant-form-text"> 人</span>
                        </Form.Item>
                        {/* 职业卫生负责人-暂无 */}
                        <Form.Item label="职业卫生负责人">
                            {getFieldDecorator('occLeaderSid',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.occLeaderSid:'',
                            })(
                                <Input placeholder="职业卫生负责人"/>
                            )}
                        </Form.Item>
                        <Form.Item label="职业卫生负责人联系电话">
                            {getFieldDecorator('occLeaderTel',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.occLeaderTel:'',
                            })(
                                <Input placeholder="职业卫生负责人联系电话"/>
                            )}
                        </Form.Item>
                        <Form.Item label="化学事故应急咨询服务电话">
                            {getFieldDecorator('chemicalEmergencyPhone',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.chemicalEmergencyPhone:'',
                            })(
                                <Input placeholder="化学事故应急咨询服务电话"/>
                            )}
                        </Form.Item>
                        <Form.Item label="申报系统名称">
                            {getFieldDecorator('reportCompanyName',{
                                initialValue:companyInfo.companySuperviseModel?companyInfo.companySuperviseModel.reportCompanyName:'',
                            })(
                                <Input disabled placeholder="申报系统名称"/>
                            )}
                        </Form.Item>
                        <Form.Item label="申报日期">
                            {getFieldDecorator('reportDate',{
                                initialValue:companyInfo.companySuperviseModel?companyInfo.companySuperviseModel.reportDate:'',
                            })(
                                <Input disabled placeholder="申报日期"/>
                            )}
                        </Form.Item>
                        <Form.Item label="申报类型">
                            {getFieldDecorator('reportType',{
                                initialValue:companyInfo.companySuperviseModel?companyInfo.companySuperviseModel.reportType:'',
                            })(
                                <Input disabled placeholder="申报类型"/>
                            )}
                        </Form.Item>
                        <Form.Item className="kongForm"></Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                保 存
                            </Button>
                            <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                                取 消
                            </Button>
                        </Form.Item>
                    </Form>
                </Card> 
            </div>
        )
    }
}

const UnitHealthForm = Form.create()(UnitHealth);

export default UnitHealthForm;

//export default Form.create()(UnitBusiness); 