import React, { Component } from 'react'
import {Card,Modal,Table,Pagination} from 'antd'
import {getSoftAdjustList,getSoftSaveRemark} from './../../../../Api/index'
import utils from './../../../../Common/js/utils'
import Notebook from './../Common/Notebook'//备注
let ids
class HealthRecord extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        visibleNote:false,
        //corpSoftId: this.props.corpSoftId,//企业id
        healthBasic: this.props.healthBasic,
        dataList:[],//列表数据
        pageNum:1,
        pageSize:10,
    };
    componentWillMount() {
        this.requestAdjustList();
    }
    componentWillReceiveProps(nextProps){
        //console.log(nextProps.keys,"020+++++++++++++++");
        if(nextProps.keys === "2"){
            ids = nextProps.healthBasic.corpSoftId
            this.requestAdjustList();
        }
    }
    //列表数据接口请求
    requestAdjustList = () => {
        //console.log(this.props.key,"123456=======================");
        let _this = this
        getSoftAdjustList({
            corpSoftId:ids ? ids : this.props.healthBasic.corpSoftId,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize
        }).then((res)=>{
            //console.log(res,"corpSoftId");
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total:res.data.result.total,
                    dataList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.requestAdjustList()
                    },(current,size)=>{
                        _this.state.pageNum = current
                        _this.state.pageSize = size
                        this.requestAdjustList()
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        })  
    }

    //备注弹出显示状态
    showModalNote = (record) => {
        this.setState({
            visibleNote: true,
            record:record.remark,
            id:record.id,
        });
        //console.log(record);
        //console.log(record.id,"-------id-------");
    };
    //备注弹出框确定按钮操作
    handleOkNote = () => {
        this.setState({ loading: true });
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        this.setState({ loading: false, visibleNote: false });
        let remarkData = this.formRef.getItemsValue();
        this.requestAdjustRemark(remarkData);
        this.formRef.resetValue();
    };
    //备注弹出框取消按钮操作
    handleCancelNote = () => {
        this.setState({ visibleNote: false });
        this.formRef.resetValue();
    };
    //备注接口
    requestAdjustRemark = (remarkData) => {
        //console.log(remarkData,"------remarkData------");
        getSoftSaveRemark({
            corpSoftAdjustId:this.state.id,
            remark:remarkData.remark
        }).then((res)=>{
            //console.log(res,"--Remark--");
            if(res.status === 200){
                this.requestAdjustList();
            }
        }).catch((error)=>{
            console.log(error);
        }) 
    }
    

    render() {
        //表格 表头
        const columns = [
            {title: '序号',dataIndex: 'key',align:'center'},
            {title: '调整前到期时间',dataIndex: 'oldEdate',align:'center'},
            {title: '调整后到期时间',dataIndex: 'newEdate',align:'center'},
            {title: '调整前空间数',dataIndex: 'oldSpaceTotal',align:'center'},
            {title: '调整后空间数',dataIndex: 'newSpaceTotal',align:'center'},
            {title: '调整时间',dataIndex: 'createDate',align:'center'},
            {title: '调整人',dataIndex: 'adjustName',align:'center'},
            {title: '备注',dataIndex: 'remark',align:'center'},
            {title: '操作',dataIndex: 'operation',align:'center',
                render: (text,record) => {
                    return (
                        <div>
                            <a className="blue mlr5" onClick={() => this.showModalNote(record)}>备注</a>
                        </div>
                    )
                }
            }
        ];
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="调整记录">
                    <Table bordered
                    columns={columns} 
                    dataSource={this.state.dataList}
                    pagination={false} />
                    <Pagination {...this.state.pagination}
                        className="fr margin10"
                    />
                </Card>

                {/* 备注弹出 */}
                <Modal
                visible={this.state.visibleNote}
                loading={this.state.loading}
                title="备注"
                onOk={this.handleOkNote}
                onCancel={this.handleCancelNote}
                okText="确定"
                cancelText="取消"
                >
                    <Notebook record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
            </div>
        )
    }
}

export default HealthRecord;