import React, { Component } from 'react'
import {Modal,Table,message,Icon} from 'antd'
import SearchForm from '../component/form/Search'
import Checkmodal from '../component/form/Checkmodal'
import { homeCheckList,checkDel, getHomeCheckInfo, checkPass } from '../../../Mock'
import { getSoftTryList,getSoftTry,saveSoftTry,auditSoftTryPassNo,auditSoftTryPassYes,auditSoftTryFinish } from '../../../Api'
import { connect } from 'react-redux'
import utils from '../../../Common/js/utils'
import STATUS from './../../../Common/js/status'
import moment from 'moment';
//首页-试用申请-未处理Tab
class Uncheck extends Component {
    state = {
        formList: [{
            name: "keyword",
            type: 'input',
            placeholder: '申请人/申请人电话/单位名称'
        }, {
            name: "tryState",
            type: 'select',
            placeholder: '请选择试用状态',
            optionList: [{
                value: 1,
                label: '申请中'
            }, {
                value: 2,
                label: '待关闭'
            }]
        }],
        //初始化Modal组件
        formModal: {
            title: '开始试用',
            list: []
        },
        page: {
            pageNum: 1,
            pageSize: 30
        }
    }
    componentDidMount(){
        this.props.onRef(this)
    }
    //通过Api获取表格数据
    getList = (params) => {
        this.setState({
            params:params
        })
        let data = {  ...params,...this.state.page }
        let _this = this
        getSoftTryList(data,1).then(res => {
            console.log(res)
            if (res.data.state.value == 0 && res.data.result.rows) {
                //为表格数据加上唯一Key
                res.data.result.rows&&res.data.result.rows.map(item => {
                    item.key = item.id
                    return item
                })
                this.setState({
                    tableList: res.data.result.rows?res.data.result.rows:[],
                    pagination: utils.pagination(res.data, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        this.getList(params)
                    }, (current, size) => {
                        _this.state.page.pageNum = current
                        _this.state.page.pageSize = size
                        this.getList(params)
                    }, res.data.result.pageSize)
                })
            }

        })
    }
    //挂载前获取表格数据
    componentWillMount() {
        this.getList()
    }
    //Modal-Form弹窗根据功能设定Modal内容
    handleOpenCheck = (record, type) => {
        this.setState({
            currentId:record.id
        })
        switch (type) {
            case 'start':
                this.setState({
                    currentModal:'start',
                    formModal: {
                        title: '开始试用',
                        list: [{
                            name: "remindExpireDate",
                            label: "设置到期时间",
                            type: 'datePicker',
                            placeholder: "请选择时间",
                            rules:[{
                                required: true,
                                message: '请选择到期时间'
                            }],
                        }, {
                            name: "remark",
                            type: 'textArea',
                            label: "备注",
                            placeholder: "请填写备注"
                        }]
                    }
                }, function () {
                    this.setState({
                        visibleCheckModal: true,
                        //设定弹框内布局
                        formItemLayout:{
                            labelCol: {
                                xs: 24,
                                sm: 5
                            },
                            wrapperCol: {
                                xs: 24,
                                sm: 19
                            }
                        }
                    })
                })
                break;
            case 'ignore':
                this.setState({
                    currentModal:'ignore',
                    formModal: {
                        title: '是否忽略此试用申请',
                        list: [{
                            name: "remark",
                            type: 'textArea',
                            label: "备注",
                            placeholder: "请填写备注"
                        }]
                    }
                }, function () {
                    this.setState({
                        visibleCheckModal: true,
                        formItemLayout:{
                            labelCol: {
                                xs: 24,
                                sm: 3
                            },
                            wrapperCol: {
                                xs: 24,
                                sm: 21
                            }
                        }
                    })
                })
                break;
            case 'view':
                getSoftTry({id:record.id}).then(res => {
                        console.log(res)
                        if (res.data.state.value == 0) {
                            this.setState({
                                currentModal:'view',
                                formModal: {
                                    title: '处理细节',
                                    list: [{
                                        name: "checkDatetime",
                                        label: "开始时间",
                                        type: 'original',
                                        value:res.data.result.checkDatetime
                                    }, {
                                        name: "checkAdminName",
                                        label: "开始人",
                                        type: 'original',
                                        value:res.data.result.checkAdminName
                                    },  {
                                        name: "remindExpireDate",
                                        label: "试用到期时间",
                                        type: 'original',
                                        value:res.data.result.remindExpireDate,
                                        placeholder:'请选择时间',
                                    }, 
                                    {
                                        name: "tryStateName",
                                        label: "试用状态",
                                        type: 'original',
                                        value:res.data.result.tryStateName
                                    }, {
                                        name: "remark",
                                        label: "备注",
                                        type: 'textArea',
                                        initial:res.data.result.remark
                                    },]
                                }
                            }, function () {
                                this.setState({
                                    visibleCheckModal: true,
                                    formItemLayout:{
                                        labelCol: {
                                            xs: 24,
                                            sm: 5
                                        },
                                        wrapperCol: {
                                            xs: 24,
                                            sm: 19
                                        }
                                    }
                                })
                            })
                        }
                    })
                
                break;
            default:
                break;

        }
        
    }
    //更改弹框的显示状态
    handleVisibleChange = () => {
        this.setState({
            visibleCheckModal: !this.state.visibleCheckModal
        })
    }
    //弹框的“确认”回调 需返回Promise对象
    handleCheck = (data) => {
        data.id = this.state.currentId
        switch(this.state.currentModal){
            case 'view':
                    return saveSoftTry(data).then((res) => {
                        console.log(res)
                        return new Promise((resolve, reject) => {
                            if (res.data.state.value == 0) {
                                message.success('操作成功')
                                this.setState({
                                    visibleCheckModal: false
                                })
                                this.getList(this.state.params)
                                resolve(true)
                            } else {
                                reject(false)
                            }
                        })
                    })
            case 'ignore':
                    return auditSoftTryPassNo(data).then((res) => {
                        console.log(res)
                        return new Promise((resolve, reject) => {
                            if (res.data.state.value == 0) {
                                message.success('操作成功')
                                this.setState({
                                    visibleCheckModal: false
                                })
                                this.getList(this.state.params)
                                resolve(true)
                            } else {
                                reject(false)
                            }
                        })
                    })
            case 'start':
                if(!data.remindExpireDate)return
                data.remindExpireDate = moment(data.remindExpireDate).format('YYYY-MM-DD HH:MM:SS')
                    return auditSoftTryPassYes(data).then((res) => {
                        console.log(res)
                        return new Promise((resolve, reject) => {
                            if (res.data.state.value == 0) {
                                message.success('操作成功')
                                this.setState({
                                    visibleCheckModal: false
                                })
                                this.getList(this.state.params)
                                resolve(true)
                            } else {
                                reject(false)
                            }
                        })
                    })
            default:
                break;
        }
       
    }
    //通过Api关闭申请
    handleClose = (record)=>{
        let _this = this
        Modal.confirm({
            title: '提示',
            content: '确定要关闭吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                auditSoftTryFinish({id:record.id}).then(res=>{
                    console.log(res)
                    if(res.data.state.value  == 0){
                        message.success('操作成功')
                        _this.getList(_this.state.params)
                    }
                })
            },
            onCancel() {},
          });
    }
    render() {
        let _this = this
        const columns = [
            {
                title: "申请人姓名",
                dataIndex: 'applyName',
                align: 'center',
                width: 100,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "申请人电话",
                dataIndex: 'applyMobile',
                align: 'center',
                width: 80,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "所在单位",
                dataIndex: 'cname',
                align: 'center',
                width: 80,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "试用产品",
                dataIndex: 'softName',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "途径",
                dataIndex: 'method',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            },
            {
                title: "试用状态",
                dataIndex: 'tryStateName',
                align: 'center',
                width: 30,
                render(text,record) {
                    return <span title={text}>{text}</span>
                },

            },
            {
                title: "申请时间",
                dataIndex: 'createDate',
                align: 'center',
                width: 50,
                render(text) {
                    return <span title={text}>{text}</span>
                },

            }, {
                title: "操作",
                align: 'center',
                width: 50,
                render(text, record) {
                    return record.tryStateName != '申请中' ?
                        <div>
                            <a onClick={() => _this.handleOpenCheck(record, 'view',)}><Icon className="mr10 table-icon-blue"  type="eye" title="查看"/></a>
                            <a onClick={() => _this.handleClose(record)}><Icon type="close" title="关闭" className="table-icon-red"/></a>
                        </div>
                        : <div>
                        <a onClick={() => _this.handleOpenCheck(record, 'start')}><Icon type="check-circle" title="开始" className="mr10 table-icon-green"/></a>
                        <a onClick={() => _this.handleOpenCheck(record, 'ignore')}><Icon type="stop" title="忽略" className="table-icon-red"/></a>
                    </div>

                }

            }
        ]
        return (
            <div className="home">
                <SearchForm formList={this.state.formList} formSearchApi={(data) => this.getList(data)} />
                <Table
                    className="mt20"
                    bordered
                    columns={columns}
                    dataSource={this.state.tableList}
                    pagination={{ ...this.state.pagination, pageSize: this.state.page.pageSize }}
                />
                <Checkmodal formSubmitApi={(data) => this.handleCheck(data)} handleVisibleChange={this.handleVisibleChange} visibleCheckModal={this.state.visibleCheckModal} formList={this.state.formModal} formItemLayout={this.state.formItemLayout}/>
            </div>
        )
    }
}

export default connect()(Uncheck);