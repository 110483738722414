import React, { Component } from 'react'
import { Form, Radio, Input, InputNumber } from 'antd'
import moment from 'moment'

const { TextArea } = Input;

class OrderManage extends Component {
    getItemsValue = ()=>{ 
        const valus= this.props.form.getFieldsValue();
        return valus;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    render() {
        console.log(this.props.record);
        console.log(this.props.record.createDate);
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                <Form.Item label="订单号">
                    {getFieldDecorator('id',{
                        initialValue:this.props.record.id
                    })(
                        <Input disabled placeholder="订单号"/>
                    )}
                </Form.Item>
                <Form.Item label="订单类型">
                    {getFieldDecorator('oderTypeName',{
                        initialValue:this.props.record.oderTypeName
                    })(
                        <Input disabled placeholder="订单类型"/>
                    )}
                </Form.Item>
                <Form.Item label="付费类型">
                    {getFieldDecorator('typeName',{
                        initialValue:this.props.record.typeName
                    })(
                        <Input disabled placeholder="付费类型"/>
                    )}
                </Form.Item>
                <Form.Item label="套餐类型">
                    {getFieldDecorator('packageName',{
                        initialValue:this.props.record.packageName
                    })(
                        <Input disabled placeholder="套餐类型"/>
                    )}
                </Form.Item>
                <Form.Item label="续费时长">
                    {getFieldDecorator('payCount',{
                        initialValue:this.props.record.payCount
                    })(
                        <Input disabled placeholder="续费时长"/>
                    )}
                </Form.Item>
                <Form.Item label="创建时间">
                    {getFieldDecorator('createDate',{
                        initialValue:this.props.record.createDate?this.props.record.createDate:''
                    })(
                        <Input disabled placeholder="创建时间"/>
                    )}
                </Form.Item>
                <Form.Item label="代理商推广码">
                    {getFieldDecorator('promocode',{
                        initialValue:this.props.record.promocode !== null ? this.props.record.promocode : "",
                        // rules: [ {pattern: /^((13|14|15|17|18)[0-9]{9})$/,message: '请输入正确的代理商推广码！'}],
                    })(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item label="交易金额">
                    {getFieldDecorator('payCost',{
                        initialValue:this.props.record.payCost !== null ? this.props.record.payCost : 0,
                    })(
                        <InputNumber/>
                    )}
                    <span className="ant-form-text"> 元</span>
                </Form.Item>
                <Form.Item label="交易号">
                    {getFieldDecorator('tradeNo',{
                        initialValue:this.props.record.tradeNo !== null ? this.props.record.tradeNo : "",
                    })(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item label="处理结果">
                    {getFieldDecorator('state',{
                         initialValue:"1",
                         rules: [
                            { required: true, message: '请选择处理结果！' }
                        ],
                    })(
                        <Radio.Group>
                            <Radio value="1">通过</Radio>
                            <Radio value="2">不通过</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                <Form.Item label="备注">
                    {getFieldDecorator('remark',{
                        initialValue:this.props.record.remark !== null ? this.props.record.remark : "",
                        rules: [{max:200,message: '内容不可多于200个字符',}]
                    })(
                        <TextArea
                        style={{ height: 75}}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const OrderManageForm = Form.create()(OrderManage);

export default OrderManageForm;

// export default Form.create()(SoftwareOne); 