/**
 * @file 页面：单个代理商的详情
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Form } from 'antd'
import { getAgentBasicInfo, getFileUrl } from '../../Api'

/**
 * @class
 * @extends React.Component
 */
class AgentManagementDetail extends React.Component {

    /**
     * 组件载入函数
     * @type {Function}
     */
    componentDidMount() {
        const agentId = this.props.location.pathname ? this.props.location.pathname.split('/').reverse()[0] : 'detail'
        if (agentId !== 'detail') {
            getAgentBasicInfo({agentId}).then((res)=>{
                console.log(res,"detail");
                if(res.status === 200 && res.data.state.value == 0){
                    this.setState(res.data.result)
                    this.downloadLink(res.data.result.agentContractFileId)
                }
            }).catch((err)=>{
                console.log(err)
            })
        }
    }

    /**
     * 组件状态值
     * @type {Object}
     */
    state = {contractLink: '#'}

    /**
     * 得到提成比例的渲染结果
     * @type {Function}
     * @return {Array}
     */
    getDraw = () => this.state.agentPaymentList.map(item => this.state.agentPaymentList.indexOf(item) == this.state.agentPaymentList.length-1 ? <span key={`draw-${this.state.agentPaymentList.indexOf(item)}`}>{item.paymentRows}-{item.paymentOffset}：{item.paymentScale}%</span> : <span key={`draw-${this.state.agentPaymentList.indexOf(item)}`}>{item.paymentRows}-{item.paymentOffset}：{item.paymentScale}%<br /></span>)

    /**
     * 获取下载附件的链接
     * @type {Function}
     * @param {String} fileId 文件的ID
     */
    downloadLink = (fileId) => {
        getFileUrl({fileId}).then(res => {
            console.log(res, 'link')
            if (res.status == 200 && res.data.state.value == 0) {
                this.setState({contractLink: res.data.result})
            }
        })
    }

    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 },
            },
        };
        return <Form {...formItemLayout}>
            <Form.Item label="代理属性：">
                {this.state.agentTypeName}
            </Form.Item>
            <Form.Item label="代理商名称：">
                {this.state.agentName}
            </Form.Item>

            <Form.Item label="代理商帐号：">
                {this.state.token}
            </Form.Item>
            <Form.Item label="负责人姓名：">
                {this.state.agentManager}
            </Form.Item>
            <Form.Item label="联系电话：">
                {this.state.agentTel}
            </Form.Item>
            <Form.Item label="联系邮箱：">
                {this.state.agentEmail}
            </Form.Item>
            <Form.Item label="代理期限：">
                {this.state.agentYear ? `${this.state.agentYear}年` : ''}
            </Form.Item>
            <Form.Item label="代理方式：">
                {this.state.agentUniqueStatusName}
            </Form.Item>
            <Form.Item label="代理区域：">
                {this.state.agentAreasStr}
            </Form.Item>
            <Form.Item label="详细地址：">
                {this.state.agentArea + this.state.agentAddr}
            </Form.Item>
            <Form.Item label="提成比例">
                {this.state.agentPaymentList ? this.getDraw(): ''}
            </Form.Item>
            <Form.Item label="代理商推广码">
                {this.state.promoteCode}
            </Form.Item>

            <Form.Item label="代理商合同">
                {this.state.agentContractFileId ? <a href={this.state.contractLink} download>{this.state.contractLink == '#' ? '' : this.state.contractLink.split('/').slice(-1)[0]}</a> : ''}
            </Form.Item>
            <Form.Item label="所属业务员">
                {this.state.pagentBusiness ? this.state.pagentBusiness.name: ''}
            </Form.Item>
        </Form>
    }
}

export default AgentManagementDetail