import React, { Component } from 'react'
// import {connect} from "react-redux"
import { Resizable } from 'react-resizable'
import { Tooltip,Form, Input, Button, Select, Checkbox, Table, Modal, Tabs, Pagination, message,Tag } from 'antd'
import {getUserUnitList,isAdminAdd,saveRemark,getSoftBuyBatch} from './../../Api/index'
import TaggingBatch from './components/Common/TaggingBatch'//批量打标签/取消标签
import Tagging from './components/Common/Tagging'//打标签
import Notebook from './components/Common/Notebook'//备注
import SoftwareOne from './components/Common/SoftwareOne'//软件开通1-职业健康
import SoftwareTwo from './components/Common/SoftwareTwo'//软件开通2-职业健康集团版
import SoftwareThree from './components/Common/SoftwareThree'//软件开通3-放射卫生
import utils from './../../Common/js/utils'
import AjaxCascader from './components/Common/AjaxCascader'
// import {getUserUnitDataAction} from "../../Store/actionCreators"

const { Option } = Select
const { TabPane } = Tabs
const ResizeableTitle = props => {
    const { onResize, width, ...restProps } = props;
    if (!width) {
      return <th {...restProps} />;
    }
    return (
      <Resizable
        width={width}
        height={0}
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps} />
      </Resizable>
    );
  };
class UserListUnit extends Component {
    //弹出初始状态
    state = {
        loading: false,
        visible: false,
        visibleNote:false,
        softwarevisible:false,
        batchvisible:false,
        userUnitData:[],
        selectedRows:[],
        params:{
            pageNum:1,
            pageSize:30
        },
        areaOptions:[],
        keys:1,
        columns:[
            {title: "序号",dataIndex: 'key',align: 'center',width: 40,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '企业名称',dataIndex: 'name',align:'center',width:200,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '所属行业',dataIndex: 'industryCategoryStr',align:'center',width:200,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '联系人',dataIndex: 'corpApplyName',align:'center',width:200,render(text){
                return <span title={text}>{text}</span>
            },},
            
            {title: '注册来源',dataIndex: 'dataSourceStr',align:'center',width:150,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '注册类型',dataIndex: 'regTypeStr',align:'center',width:100,render(text){
                return <span title={text}>{text}</span>
            },},
            // {title: '所属行业',dataIndex: 'industryCategoryStr',align:'center',width:90},
            {title: '标签',dataIndex: 'isAdminAdd',align:'center',width:80,render:(text,record)=>{
                if(text == true){
                    return (
                        <Tag color='green' key={text}>
                          内部人员
                        </Tag>
                      ) 
                }
            }},
            // {title: '联系人',dataIndex: 'legalName',align:'center',width:60},
            {title: '联系电话',dataIndex: 'legalTel',align:'center',width:120,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '已开通软件',dataIndex: 'software',align:'center',width:200,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '备注',dataIndex: 'remark',align:'center',width:150,render(text){
                return <span title={text}>{text}</span>
            },},
            {title: '操作',dataIndex: 'operation',width:150,align:'center',render: (text,record) => {
                return (
                    <div>
                        
                        <a className="blue mlr5" onClick={() => this.jumpTo(record)}>
                            <Tooltip title="查看">
                                <span className="fa fa-search-plus"></span>
                            </Tooltip>
                        </a>
                        <a className="blue mlr5" onClick={() => this.showModalNote(record)}>
                            <Tooltip title="备注">
                                <span className="fa fa-pencil" style={{color:'#52c41a'}}></span>
                            </Tooltip>
                        </a>
                        <a className="blue mlr5" onClick={() => this.showModal(record)}>
                            <Tooltip title="打标签">
                                <span className="fa fa-plus-square-o"></span>
                            </Tooltip>
                        </a>
                    </div>
                )
            }},
            {title: 'cid',dataIndex: 'cid',align:'center',className:'dn',width:0},
            {title: 'companyPlatformAdminId',dataIndex: 'companyPlatformAdminId',align:'center',className:'dn',width:0},
        ]
    };
    //请求表格数据函数
    requestTableList = ()=>{
        let _this = this
        getUserUnitList(this.state.params).then((res)=>{
            //console.log(res,"list");
            if(res.status === 200 && res.data.state.value == 0){
                console.log(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
                utils.tableListKey(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
                
                this.setState({
                    total:res.data.result.total,
                    userUnitData:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        console.log(size,"size")
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestTableList()
                    },(current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestTableList()
                    },res.data.result.rows.length)
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    //生命周期 挂在前
    componentWillMount() {
        this.requestTableList()
    }



    //生命周期 挂在后
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
        //this.props.reqUserUnitList();
    }
    // //级联选择后自动赋值
    // handleSetValue = (idArr,itemArr,type)=>{
    //     this.props.form.setFieldsValue({[type]:itemArr[itemArr.length-1].id})
    // }
     //批量打标签弹出显示状态
     showModalBatch = (type) => {
        console.log(this.state.selectedRows,'this.state.selectedRows')
        if(this.state.selectedRows.length == 0){
            this.renderModalSimple('warning','提醒','请选择至少一条信息')
        }else{
            this.setState({
                batchvisible: true,
                BatchOkType:type
            });
        }
    };
    //批量打标签弹出框确定按钮操作-待接口校验
    BatchOk = () => {
        this.setState({ loading: true });
        let idArr = []    
        this.state.selectedRows.map(item=>{
            idArr.push(item.companyPlatformAdminId)
        })
        let params = {idArr:idArr}
        let checkGrpoup = this.formRef.getItemsValue()['checkbox-group']
        if(this.state.BatchOkType === 'remove'){
            if(checkGrpoup){
                checkGrpoup.map(item=>{
                    if(item == 'isAdminAdd'){
                        params.isAdminAdd = false
                    }
                })
            }
        }else if(this.state.BatchOkType === 'add'){
            if(checkGrpoup){
                checkGrpoup.map(item=>{
                    if(item == 'isAdminAdd'){
                        params.isAdminAdd = true
                    }
                })
            }
        }
         //批量打标签，后台接口未完成
         isAdminAdd(params).then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                message.success('操作成功！')
                this.setState({ loading: false, batchvisible: false });
                this.requestTableList()
            }else{
                this.renderModalSimple('warning','错误','操作失败，请重新提交！')
            }
            this.formRef.resetValue()
        })
        
    };
    //批量打标签弹出框取消按钮操作
    BatchCancel = () => {
        this.formRef.resetValue()
        this.setState({ batchvisible: false });
    };
    //打标签弹出显示状态
    showModal = (record) => {
        this.setState({
            visible: true,
            record:record,
        });
    };
    //打标签弹出框确定按钮操作
    handleOk = () => {
        this.setState({ loading: true });
        // console.log(this.state.record,'15')
        let idArr = []
        idArr.push(this.state.record.companyPlatformAdminId)
        let params = {idArr:idArr}
        let checkGrpoup = this.formRef.getItemsValue()['checkbox-group']
        if(checkGrpoup){
            checkGrpoup.map(item=>{
                if(item == 'isAdminAdd'){
                    params.isAdminAdd = true
                }
            })
        }
         //批量打标签，后台接口未完成
         isAdminAdd(params).then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                message.success('操作成功！')
                this.setState({ loading: false, visible: false });
                this.requestTableList()                           
            }else{
                this.renderModalSimple('warning','错误','操作失败，请重新提交！')
            }
            this.formRef.resetValue()
        })
    };
    //打标签弹出框取消按钮操作
    handleCancel = () => {
        this.formRef.resetValue()
        this.setState({ visible: false });
    };

    //下拉
    handleSelectChange = value => {
        // console.log(value);
        this.props.form.setFieldsValue({
            //note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
        });
    };
    //搜索按钮
    handleSubmit = ()=> {
        let fieldsParams = this.props.form.getFieldsValue()
        console.log(fieldsParams)
        let _this = this
        new Promise((resolve,reject)=>{
            _this.setState({
                params:{
                    areaId:fieldsParams.areaId&&fieldsParams.areaId[fieldsParams.areaId.length-1],
                    dataSource:fieldsParams.dataSource,
                    name:fieldsParams.name,
                    regType:fieldsParams.regType,
                    isAdminAdd:fieldsParams['checkbox-group']&&fieldsParams['checkbox-group'].length?true:"",
                    pageNum:1,
                    pageSize:30
                }
            })
            resolve()
        }).then(()=>{this.requestTableList()})
        
    };
    //重置按钮
    handleReset = () => {
        this.props.form.resetFields();
        this.setState({
            params:{
                pageNum:1,
                pageSize:30
            }
        })
        this.requestTableList(this.state.params)
    };

    //备注弹出显示状态
    showModalNote = (record) => {
        this.setState({
            visibleNote: true,
            record:record,
        });
        // console.log(record);
    };
    //备注弹出框确定按钮操作
    handleOkNote = () => {
        this.setState({ loading: true });
        let idArr = []
        idArr.push(this.state.record.companyPlatformAdminId)
        let params = {idArr:idArr}
        let remark = this.formRef.getItemsValue()['remark']
        if(remark){
            params.remark = remark
        }
         //批量打标签，后台接口未完成
         saveRemark(params).then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                message.success('操作成功！')
                this.setState({ loading: false, visibleNote: false });
                this.requestTableList()

            }else{
                this.renderModalSimple('warning','错误','操作失败，请重新提交！')
            }
            this.formRef.resetValue()
        })
        console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        
    };
    //备注弹出框取消按钮操作
    handleCancelNote = () => {
        this.formRef.resetValue()
        this.setState({ visibleNote: false });
    };

    //批量开通软件弹出显示状态
    showModalSoft = () => {
        if(this.state.selectedRows.length == 0){
            this.renderModalSimple('warning','提醒','请选择至少一条信息')
        }else{
            this.setState({
                softwarevisible: true,
            });
        }
    };
    //批量开通软件弹出框确定按钮操作
    softwareOk = () => {
        console.log(this.state.key,"keyyyyy")
        this.setState({ loading: true });
        let idArr = []    
        this.state.selectedRows.map(item=>{
            idArr.push(item.cid)
        })
        let params = {idArr:idArr}
        // console.log(this.state.key,params,"ididididi");
        let formdata = this.formRef.getItemsValue();
        if(this.state.key === "1"){//职业健康
            if(
                formdata.comboId !== '' && formdata.comboId !== undefined && 
                formdata.payCount !== '' && formdata.payCount !== undefined && 
                formdata.comboSpaceCount !== '' && formdata.comboSpaceCount !== undefined && 
                formdata.type !== '' && formdata.type !== undefined 
            ){
                this.setState({ loading: false, softwarevisible: false });//弹出隐藏
                this.getBuyRequest(1,formdata,params);//接口传入数据 1：软件类型（职业健康） formdata：弹出表格输入内容 params：选中企业id
                this.formRef.resetValue();//弹出内容 清空之前输入内容
            }else{
                this.setState({ loading: true, softwarevisible: true });
            }
        }else if(this.state.key === "2"){//职业健康集团版
            if(
                formdata.comboId !== '' && formdata.comboId !== undefined && 
                formdata.payCount !== '' && formdata.payCount !== undefined && 
                formdata.comboUserCount !== '' && formdata.comboUserCount !== undefined && 
                formdata.type !== '' && formdata.type !== undefined 
            ){
                this.setState({ loading: false, softwarevisible: false });//弹出隐藏
                this.getBuyRequest(4,formdata,params);//接口传入数据 4：软件类型（职业健康集团版） formdata：弹出表格输入内容 params：选中企业id
                this.formRef.resetValue();//弹出内容 清空之前输入内容
            }else{
                this.setState({ loading: true, softwarevisible: true });
            }
        }else if(this.state.key === "3"){//放射卫生
            if(
                formdata.comboId !== '' && formdata.comboId !== undefined && 
                formdata.payCount !== '' && formdata.payCount !== undefined && 
                formdata.comboUserCount !== '' && formdata.comboUserCount !== undefined && 
                formdata.type !== '' && formdata.type !== undefined 
            ){
                this.setState({ loading: false, softwarevisible: false });//弹出隐藏
                this.getBuyRequest(2,formdata,params);//接口传入数据 2：放射卫生 formdata：弹出表格输入内容 params：选中企业id
                this.formRef.resetValue();//弹出内容 清空之前输入内容
            }else{
                this.setState({ loading: true, softwarevisible: true });
            }
        }else if(this.state.key === "4"){//健康顾问
            this.setState({ loading: false, softwarevisible: false });//弹出隐藏
            this.getBuyRequest(3,formdata,params);//接口传入数据 3：软件类型（健康顾问） formdata：弹出表格输入内容 params：选中企业id
        }else{//默认职业健康
            if(
                formdata.comboId !== '' && formdata.comboId !== undefined && 
                formdata.payCount !== '' && formdata.payCount !== undefined && 
                formdata.comboSpaceCount !== '' && formdata.comboSpaceCount !== undefined && 
                formdata.type !== '' && formdata.type !== undefined 
            ){
                this.setState({ loading: false, softwarevisible: false });//弹出隐藏
                this.getBuyRequest(1,formdata,params);//接口传入数据 1：软件类型（1.职业健康 4.职业健康集团版） formdata：弹出表格输入内容 params：选中企业id
                this.formRef.resetValue();//弹出内容 清空之前输入内容
            }else{
                this.setState({ loading: true, softwarevisible: true });
            }
        }
        
    };
    //批量开通软件弹出框取消按钮操作
    softwareCancel = () => {
        this.setState({ softwarevisible: false });
        this.formRef.resetValue();
    };
    //购买软件请求接口
    getBuyRequest = (keys,data,params) => {
        console.log(params,keys,data,"datadatadatadata");
        getSoftBuyBatch({
            cidArr:params.idArr,//企业id
            softId:keys,//软件类型
            comboId:data.comboId,//套餐类型
            payCount:data.payCount,//套餐类型
            comboSpaceCount:data.comboSpaceCount,//备注
            comboUserCount:data.comboUserCount,//套餐类型
            type:data.type,//付费类型
            promocode:data.promocode,//代理商推广码
            payCost:data.payCost,//交易金额
            tradeNo:data.tradeNo,//交易号
            remark:data.remark//备注
        }).then((res)=>{
            this.requestTableList();
        }).catch((error)=>{
            console.log(error);
        }) 
    }

    //批量开通软件tab切换
    callback = key => {
        // console.log(key);
        if(key === '1'){
            this.setState({
                key:key
            },()=>{
                this.softwareOk(1);
            })
        }else if(key === '2'){
            this.setState({
                key:key
            },()=>{
            this.softwareOk(4);                
            })
        }else if(key === '4'){
            this.setState({
                key:key
            },()=>{
                this.softwareOk(3);
            })
        }else if(key === '3'){
            this.setState({
                key:key
            },()=>{
                this.softwareOk(2);
            })
        }else{
            this.setState({
                key:key
            },()=>{
                this.softwareOk(1);

            })
        }
        // this.setState({
        //     key:key
        // })
    }

    //Modal提醒渲染方法
    renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({
            title:title?title:'警告',
            content:content?content:''
        })
    }
    jumpTo = (record)=>{
        window.location.href='/user/basic/'+record.cid
    }
    //表格拉伸
    components={
        header: {
          cell: ResizeableTitle,
        },
    }
    handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return { columns: nextColumns };
        });
      };
    
    render() {
        // const {flagCount} = this.state;
        const { getFieldDecorator } = this.props.form;
        //表格行可选/多选属性
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
            //   console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              this.setState({
                selectedRowKeys, selectedRows
              })
            },
            onSelect: (record, selected, selectedRows) => {
            //   console.log(record, selected, selectedRows);
              this.setState({
                record, selected, selectedRows
              })
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
            //   console.log(selected, selectedRows, changeRows);
              this.setState({
                changeRows, selected, selectedRows
              })
            },
        };
        //表格头部操作按钮
        const title = () => {
            return (
                <div>
                    <div className="fr">
                        <Button type="primary" onClick={this.showModalSoft}>
                            批量开通软件
                        </Button>
                        <Button type="primary" onClick={()=>this.showModalBatch('add')} style={{ marginLeft: 10 }}>
                            打标签
                        </Button>
                        <Button onClick={()=>this.showModalBatch('remove')} style={{ marginLeft: 10 }}>
                            取消标签
                        </Button>
                    </div>
                    <div className="clear"></div>
                </div>
            )
        };
        //表格 表头
        const columns = this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: this.handleResize(index),
            }),
        }));
    
        return (
            <div>
                <Form layout="inline">
                    <Form.Item>
                        {getFieldDecorator('name')(
                            <Input
                            style={{width:180}}
                            placeholder="企业名称/联系电话"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('regType')(
                            <Select
                            style={{width:180}}
                            placeholder="--请选择注册类型--"
                            onChange={this.handleSelectChange}
                            >
                                <Option value={1}>企业注册</Option>
                                <Option value={2}>医疗机构注册</Option>
                                <Option value={3}>其他机构注册</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('dataSource')(
                            <Select
                            style={{width:180}}
                            placeholder="--请选择注册来源--"
                            onChange={this.handleSelectChange}
                            >
                                <Option value={2}>导入</Option>
                                <Option value={1}>注册</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('checkbox-group')(
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Checkbox value="isAdminAdd">内部人员</Checkbox>
                            </Checkbox.Group>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('areaId')(
                            <AjaxCascader
                                style={{width:250}}
                                maxLevel = {4}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSubmit}>
                            搜 索
                        </Button>
                        <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                            重 置
                        </Button>
                    </Form.Item>
                </Form>
                <Table className="mt20" bordered title={title} 
                    rowSelection={rowSelection} 
                    columns={columns} 
                    components={this.components}
                    dataSource={this.state.userUnitData}
                    pagination={false}
                    // scroll={{ x: 1500}}
                />
                <Pagination {...this.state.pagination}
                    pageSize={this.state.params.pageSize}
                    className="fr mt20"
                />

                {/* 打标签弹出 */}
                <Modal
                visible={this.state.visible}
                loading={this.state.loading}
                title="标签选择"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="确定"
                cancelText="取消"
                >
                    <Tagging record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
                {/* 备注弹出 */}
                <Modal
                visible={this.state.visibleNote}
                loading={this.state.loading}
                title="备注"
                onOk={this.handleOkNote}
                onCancel={this.handleCancelNote}
                okText="确定"
                cancelText="取消"
                >
                    <Notebook record={this.state.record?this.state.record.remark:null}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
                 {/* 批量打标签弹出 */}
                 <Modal
                visible={this.state.batchvisible}
                loading={this.state.loading}
                title="标签选择"
                onOk={this.BatchOk}
                onCancel={this.BatchCancel}
                okText="确定"
                cancelText="取消"
                >
                    <TaggingBatch
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>
                {/* 批量开通软件弹出 */}
                <Modal
                width={620}
                visible={this.state.softwarevisible}
                loading={this.state.loading}
                title="批量开通软件"
                onOk={this.softwareOk}
                onCancel={this.softwareCancel}
                okText="确定"
                cancelText="取消"
                >
                    <Tabs onChange={this.callback} type="line" animated={false} defaultActiveKey="1">
                        <TabPane tab="职业健康" key="1">
                            <SoftwareOne
                            wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                            />
                        </TabPane>
                        <TabPane tab="职业健康集团版" key="2">
                            <SoftwareTwo
                            wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                            />
                        </TabPane>
                        <TabPane tab="放射卫生" key="3">
                            <SoftwareThree
                            wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                            />
                        </TabPane>
                        <TabPane tab="健康顾问" key="4">
                            是否开通健康顾问
                        </TabPane>
                    </Tabs>
                </Modal>
            </div>
        )
    }
}

const UserSearchForm = Form.create()(UserListUnit);

export default UserSearchForm;
