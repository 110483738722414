import React, { Component } from 'react'
import { Form, Input,InputNumber,Anchor,Upload,Button,Icon,message } from 'antd'
import moment from 'moment'
import UploadFail from './Upload'
import {showUploadFile,postUploadFile,deleteFile} from './../../../Api'

// import utils from './../../../Common/js/utils'
const { TextArea } = Input;
let idflage2=true

class OrderDetailRenewal extends Component {
    //state状态机
    state={
        files: [],
        record:[],
        currentUidList:{}
    }
    //提交表单
    getItemsValue = ()=>{ 
        const valus= this.props.form.getFieldsValue();
        valus.files = this.state.files
        return valus;
    }
    //重置表单
    resetValue = () =>{
        this.props.form.resetFields()
        this.setState({
            files: []
        })
    }
    componentWillReceiveProps(nextProps){
        if(!nextProps.record)return
        if(nextProps.record == this.props.record)return
        let record =[]
        showUploadFile({fileIds:nextProps.record.files}).then(res=>{
            console.log(res)
            if(res.data.state.value === 0){
                res.data.result&&res.data.result.length&&res.data.result.map(item=>{
                    record.push({
                        uid: item.id,
                        id: item.id,
                        name: item.fileName,
                        status: 'done',
                        url: 'http:'+item.fullOssurl,
                      })
                })
                if(res.data.result&&res.data.result.id){
                    record.push({
                        uid: res.data.result.id,
                        name: res.data.result.fileName,
                        status: 'done',
                        url: 'http:'+res.data.result.fullOssurl,
                      })
                }}
                this.setState({
                    files:record
                })
        })

    }
    normFile = e => {
        // 文件上传事件
        if (Array.isArray(e)) {
            return e;
        }
        if(e.file.type == 'application/msword' || e.file.type == 'application/vnd.ms-powerpoint'|| e.file.type == 'application/pdf' || !e.file.type || e.file.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || e.file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
            return e && e.fileList;
        }else{
            message.warning('文件上传类型错误，请上传PDF、Word、PPT形式文件！')
            e.fileList = [];
            return e && e.fileList
        }
    };
    render() {
        let _this = this
        const { getFieldDecorator } = this.props.form;
        const uploadReadProps = {
            name: 'files',
            accept:'application/msword,application/vnd.ms-powerpoint,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            beforeUpload(file){
                let reader = new FileReader();
                reader.onload = function(e){
                    let params = new FormData()
                    params.append('file',file)
                    postUploadFile(params).then(res=>{
                        if(res.status === 200 && res.data.state.value ===0){
                            let arr = _this.state.files
                            arr.push({
                                uid: res.data.result.id,
                                id: res.data.result.id,
                                name: res.data.result.fileName,
                                status: 'done',
                                url: 'http:'+res.data.result.fullOssurl,
                              })
                              let idlist = _this.state.currentUidList
                                idlist[file.uid] = res.data.result.id
                                _this.setState({
                                    currentUidList:idlist
                                })
                              _this.setState({
                                  files:arr
                              })
                            idflage2 = false
                        }
                    })
                }
                // 传入一个参数对象即可得到基于该参数对象的文本内容
                reader.readAsBinaryString(file)
                //阻止antd自带的上传
                return false
            },
            //移除文件时清空url
            onRemove(file){
                let arr = _this.state.files
                _this.state.files.map((item,index)=>{
                    if(item === file || _this.state.currentUidList[file.uid] == item.id){
                        arr.splice(index,1)
                        _this.setState({
                            files:arr
                        })
                    }
                })
            }
        }
        return (
            <Form layout="inline" className="SoftWareList SoftWareWidth">
                <Form.Item label="购买企业">
                    {getFieldDecorator('companyName',{
                        initialValue:this.props.record.companyName
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="软件名称">
                {getFieldDecorator('softIdStr',{
                    initialValue:this.props.record.softIdStr
                })(
                    <Input disabled/>
                )}
                </Form.Item>  
                <Form.Item label="订单号">
                    {getFieldDecorator('id',{
                        initialValue:this.props.record.id
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="创建人">
                    {getFieldDecorator('createByStr',{
                        initialValue:this.props.record.createByStr
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="创建时间">
                    {getFieldDecorator('createDate',{
                        initialValue:this.props.record.createDate
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="合同附件">
                {console.log(this.state,"state")}
                    {getFieldDecorator('files',{
                        valuePropName: 'fileList',
                        getValueFromEvent: this.normFile,
                        initialValue:this.state.files
                    })(
                        <Upload
                            {...uploadReadProps}
                        >  
                            <Button>
                            <Icon type="upload"/> 上传文件
                            </Button>
                        </Upload>
                        // <UploadFail record={this.props.record.files}/>
                    )}
                </Form.Item>
                {/* <Form.Item label="购买企业">
                    {getFieldDecorator('companyName',{
                        initialValue:this.props.record.companyName
                    })(
                        <Input/>
                    )}
                </Form.Item> */}
                <Form.Item label="代理商推广码">
                    {getFieldDecorator('promocode',{
                        initialValue:this.props.record.promocode !== null ? this.props.record.promocode : "",
                    })(
                        <Input disabled/>
                    )}
                </Form.Item>
                <Form.Item label="交易金额">
                    {getFieldDecorator('payCost',{
                        initialValue:this.props.record.payCost !== null ? this.props.record.payCost : 0,
                    })(
                        <InputNumber/>
                    )}
                    <span className="ant-form-text"> 元</span>
                </Form.Item>
                <Form.Item label="交易号">
                    {getFieldDecorator('tradeNo',{
                        initialValue:this.props.record.tradeNo !== null ? this.props.record.tradeNo : "",
                    })(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item label="备注">
                    {getFieldDecorator('remark',{
                        initialValue:this.props.record.remark !== null ? this.props.record.remark : "",
                        rules: [{max:200,message: '内容不可多于200个字符',}]
                    })(
                        <TextArea
                        style={{ height: 75}}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const OrderDetailRenewalForm = Form.create()(OrderDetailRenewal);

export default OrderDetailRenewalForm;

// export default Form.create()(SoftwareOne); 