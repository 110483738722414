import React, { Component } from 'react'
import { Form, InputNumber } from 'antd'
// const { TextArea } = Input;

class FreeAdmission extends Component {
    getItemsValue = ()=>{ 
        // const valus= this.props.form.getFieldsValue();
        // return valus;
        let returnValues
        this.props.form.validateFields((err,value) => {
            if(err){
                returnValues = false
            }else{
                returnValues = value
                returnValues = this.props.form.getFieldsValue()
            }
        })
        return returnValues;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    render() {
        const DoctorBasic = this.props.DoctorBasic;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                <Form.Item label="通用券">
                    {getFieldDecorator('ticketCommon',{
                        initialValue: DoctorBasic.ticketCommon ? DoctorBasic.ticketCommon : 0,
                    })(
                        <InputNumber placeholder="通用券" />
                    )}
                </Form.Item>
                <Form.Item label="电话券">
                    {getFieldDecorator('ticketTel',{
                        initialValue: DoctorBasic.ticketTel ? DoctorBasic.ticketTel : 0,
                    })(
                        <InputNumber placeholder="电话券" />
                    )}
                </Form.Item>
                <Form.Item label="图文券">
                    {getFieldDecorator('ticketNet',{
                        initialValue: DoctorBasic.ticketNet ? DoctorBasic.ticketNet : 0,
                    })(
                        <InputNumber placeholder="图文券" />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const FreeAdmissionForm = Form.create()(FreeAdmission);

export default FreeAdmissionForm;

// export default Form.create()(SoftwareOne); 