/**
 * @file 组件：月份选择
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { DatePicker } from 'antd'

const { RangePicker } = DatePicker;

/**
 * 组件：月份选择
 * @class
 * @extends React.Component
 */
export default class MonthRangePicker extends React.Component {

    /**
     * 月份值改变时改变组件value
     * @type {Function}
     */
    handlePanelChange = (value, mode) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(value);
        }
    };

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => (
            <RangePicker
                placeholder={['开始时间', '结束时间']}
                format="YYYY-MM"
                value={this.props.value}
                mode={['month', 'month']}
                onChange={this.handlePanelChange}
                onPanelChange={this.handlePanelChange}
            />
        )
}