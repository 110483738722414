import React, { Component } from 'react'
import { Route, Switch, Redirect} from "react-router-dom";
import  LegalManagement from './LegalManagement'
//对应页面
import DataList from './DataList'
import DangerSearch from './DangerSearch'
// import DataAdd from './DataAdd'
import DivisionManage from './DivisionManage'
import IndustryManage from './IndustryManage'
class dataRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/data/list" component={DataList} />
                    <Route path="/data/legalManagement" component={LegalManagement} />
                    <Route path="/data/list6" component={DangerSearch} />
                    <Route path="/data/list8" component={DivisionManage} />
                    <Route path="/data/list9" component={IndustryManage} />
                    {/* <Route path="/data/add" Component={DataAdd} /> */}
                    <Redirect exact from="/data" to="/data/list" />
                </Switch>
            </div>
        )
    }
}

export default dataRouter;