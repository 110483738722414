/**
 * @file 页面：增加新代理商
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Form, Input, Icon, message, Select, Button, Radio, Upload } from 'antd'
import {addAgent} from '../../Api'
import Address from './components/Address'
import Draw from './components/Draw'
import ProxyArea from './components/ProxyArea'
import SalesmanSelection from './components/SalesmanSelection'
import PromoCode from './components/PromoCode'

const { Option } = Select;

/**
 * @typedef {Object} UploadProperty
 * @property {String} action 上传接口地址
 * @property {Object} headers 上传头部数据
 * @property {Function} onChange 上传状态变化时进行的函数
 * 
 * 上传组件配置
 * 
 * @const {UploadProperty} props
 */
const props = {
    action: '/v1/ajax/file/upload',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} 上传成功。`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} 上传失败。`);
        }
    },
};

/**
 * @var {Number} id 提成比例ID 
 */
let id = 1;

/**
 * @class
 * @extends React.Component
 */
class AgentManagementAddForm extends React.Component {

    /**
     * 组件状态
     * @type {Object}
     */
    state = {
        confirmDirty: true,
        salesman: undefined
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    /**
     * 提成比例删除列
     * @type {Function}
     * @param {Number} k 该列对应的key 
     */
    remove = k => {
        const { form } = this.props
        const keys = form.getFieldValue('draw_keys')
        // 至少需要一个
        if (keys.length === 1) {
            return
        }

        form.setFieldsValue({
            draw_keys: keys.filter(key => key !== k),
        })
    }

    /**
     * 提成比例增加列
     * @type {Function} 
     */
    add = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('draw_keys');
        const nextKeys = keys.concat(id++);
        form.setFieldsValue({
            draw_keys: nextKeys,
        })
    }

    /**
     * 提交后事件
     * @type {Function}
     * @param {Event} e 提交事件 
     */
    handleSubmit = e => {
        e.preventDefault();
        const {history} = this.props        
        this.props.form.validateFields((err, values) => {
            if (!values.agentAddr.area || (values.agentAddr.area && values.agentAddr.area.length < 3) || !values.agentAddr.address) {
                this.props.form.setFields({
                    agentAddr: {
                      value: values.agentAddr,
                      errors: [new Error('请将地址填写完整')],
                    },
                })
            }
            else if (!err) {
                const finalValues = {}
                for (let key of Object.keys(values)) {
                    if (key == 'agentAddr') {
                        finalValues[key] = values[key].address
                        finalValues.agentAreaId = values[key].area.slice(-1)[0]
                    } else if (key == 'agentAreaList') {
                        finalValues[key] = values[key].map(item => {return {sysAreaId: item}})
                    } else if (key == 'agentContractFileId') {
                        finalValues[key] = values[key].file.response.result.id
                    } else if (key == 'parentId') {
                        finalValues[key] = values[key].split('-').slice(-1)[0]
                    } else if (key !== 'draw_keys') {
                        finalValues[key] = values[key]
                    }
                }
                addAgent(finalValues).then(res => {
                    console.log(res, "add")
                    if(res.status === 200 && res.data.state.value == 0) {
                        history.push("/agent/management", {action: 'add'})              
                    } else if (res.data.content.search('手机号码')+1) {
                        console.log(res.data.content)
                        this.props.form.setFields({
                            tel: {
                                value: values.tel,
                                errors: [new Error(res.data.content)],
                            },
                        })
                    }
                }, reason => {
                    message.error(reason)
                })
            }
        });
    };

    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 },
            },
        }
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 21, offset: 3 },
            },
        }
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 24,
                    offset: 0,
                },
            },
        }
        getFieldDecorator('draw_keys', { initialValue: [0] });
        const keys = getFieldValue('draw_keys');
        const formItems = keys.map((k, index) => (
            <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? '提成比例：' : ''}
                required={true}
                key={k}
            >
                {getFieldDecorator(`agentPaymentList[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                        {
                            required: true,
                            message: "请输入提成比例或删除此行",
                        },
                    ],
                })(<Draw />)}
                {keys.length > 1 ? (
                    <Icon
                        className="dynamic-delete-button ml10"
                        type="minus-circle-o"
                        onClick={() => this.remove(k)}
                    />
                ) : null}
            </Form.Item>
        ))
        return <Form {...formItemLayout} 
                onSubmit={this.handleSubmit}
                >
            <Form.Item label="代理属性：">
                {getFieldDecorator('agentType', {
                    rules: [
                        {
                            required: true,
                            message: '请选择代理属性',
                        },
                    ],
                })(<Radio.Group>
                    <Radio value={1}>企业代理</Radio>
                    <Radio value={2}>个人代理</Radio>
                </Radio.Group>)}
            </Form.Item>
            <Form.Item label="代理商名称：">
                {getFieldDecorator('agentName', {
                    rules: [
                        {
                            required: true,
                            message: '请输入代理商名称',
                        },
                    ],
                })(<Input />)}
            </Form.Item>

            <Form.Item label="代理商手机号：">
                {getFieldDecorator('tel', {
                    rules: [
                        {
                            required: true,
                            message: '请输入代理商手机号',
                        },
                    ],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="负责人姓名：">
                {getFieldDecorator('agentManager', {
                    rules: [
                        {
                            required: true,
                            message: '请输入负责人姓名',
                        },
                    ],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="联系电话：">
                {getFieldDecorator('agentTel', {
                    rules: [
                        {
                            required: true,
                            message: '请输入联系电话',
                        },
                    ],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="联系邮箱：">
                {getFieldDecorator('agentEmail', {
                    rules: [
                        {
                            type: 'email',
                            message: '请输入有效邮箱',
                        },
                        {
                            required: true,
                            message: '请输入联系邮箱',
                        },
                    ],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="代理期限：">
                {getFieldDecorator('agentYear', {
                    rules: [
                        {
                            required: true,
                            message: '请选择代理期限',
                        },
                    ],
                })(<Select>
                    <Option value={2}>2年</Option>
                    <Option value={3}>3年</Option>
                    <Option value={5}>5年</Option>
                </Select>)}
            </Form.Item>
            <Form.Item label="代理方式：">
                {getFieldDecorator('agentUnique', {
                    rules: [
                        {
                            required: true,
                            message: '请选择代理方式',
                        },
                    ],
                })(<Radio.Group>
                    <Radio value={true}>独家代理</Radio>
                    <Radio value={false}>非独家代理</Radio>
                </Radio.Group>)}
            </Form.Item>
            <Form.Item label="代理区域：">
                {getFieldDecorator('agentAreaList', {
                    rules: [
                        {
                            required: true,
                            message: '请选择代理区域',
                        },
                    ],
                })(<ProxyArea />)}
            </Form.Item>
            <Form.Item label="详细地址：">
                {getFieldDecorator('agentAddr', {
                    rules: [
                        {
                            required: true,
                            message: '请选择详细地址',
                        }
                    ],
                })(<Address />)}
            </Form.Item>
            {formItems}
            <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                    <Icon type="plus" /> 添加
                </Button>
            </Form.Item>
            <Form.Item label="所属业务员：">
                {getFieldDecorator('parentId', {
                    initialValue: this.state.salesman,
                    rules: [
                        {
                            required: true,
                            message: '请选择所属业务员',
                        },
                    ],
                })(<SalesmanSelection onChange={salesman => {this.setState({salesman})}}/>)}
            </Form.Item>

            <Form.Item label="代理商推广码">
                {getFieldDecorator('promoCode', {
                    rules: [
                        {
                            required: true,
                            message: '请生成代理商推广码'
                        }
                    ]
                })(
                    <PromoCode man={this.state.salesman} />
                )}
            </Form.Item>

            <Form.Item label="代理商合同">
                {getFieldDecorator('agentContractFileId', {rules: [
                        {
                            required: true,
                            message: '请上传代理商合同',
                        },
                    ], valuePropName: 'file'})(
                    <Upload {...props}>
                    <Button>
                        <Icon type="upload" /> 上传
                    </Button>
                    </Upload>
                )}
            </Form.Item>


            <Form.Item {...tailFormItemLayout} style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" className="mr10">
                    确定
                </Button>
                <Button type="cancel" onClick={() => {window.history.back()}}>
                    取消
                </Button>
            </Form.Item>
        </Form>
    }
}

/**
 * 创建表单
 * @const {Form} AgentManagementAdd
 */
const AgentManagementAdd = Form.create()(AgentManagementAddForm)

export default AgentManagementAdd