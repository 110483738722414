import React, { Component } from 'react'
import { Route, Switch, Redirect} from "react-router-dom";

//对应页面
import OrderList from './OrderList'
import VerOrderList from './VerOrderList'
import HealthOrderList from './HealthOrderList'

class OrderRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/order/list" component={OrderList} />
                    <Route path="/order/verorder" component={VerOrderList} />
                    <Route path="/order/health" component={HealthOrderList} />
                    <Redirect exact from="/order" to="/order/list" />
                </Switch>
            </div>
        )
    }
}

export default OrderRouter;