import React, { Component } from 'react'
import { Form, Checkbox } from 'antd'

class Tagging extends Component {
    getItemsValue = ()=>{ 
        const valus= this.props.form.getFieldsValue();
        return valus;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline">
                <Form.Item>
                    {getFieldDecorator('checkbox-group', {
                        initialValue: [this.props.record?this.props.record:''],
                    })(
                        <Checkbox.Group style={{ width: '100%' }}>
                            <Checkbox value="isAdminAdd">内部人员</Checkbox>
                            {/* <Checkbox value="外部人员">外部人员</Checkbox>
                            <Checkbox value="游客">游客</Checkbox>
                            <Checkbox value="管理员">管理员</Checkbox> */}
                        </Checkbox.Group>
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const TaggingForm = Form.create()(Tagging);

export default TaggingForm;

// export default Form.create()(Tagging); 