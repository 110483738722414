/**
 * @file 组件：弹框预览
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Button, Icon, Modal } from 'antd'
import { twoDigit } from '../../Training/Questions/features'

/**
 * 组件：弹框预览
 * @class
 * @extends React.Component
 */
export default class Preview extends React.Component {
    /**
     * 组件状态值存储区域
     * @type {Object}
     */
    state = {
        visible: false
    }

    /**
     * 显示弹框
     * @type {Function}
     */
    showModal = () => {
        this.setState({
            visible: true,
        });
        console.log(this.props.attachment)
    }


    /**
     * 点击确认后的事件
     * @type {Function}
     * @param {Event} e 确认按钮事件
     */
    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    /**
     * 点击取消后的事件
     * @type {Function}
     * @param {Event} e 取消按钮事件
     */
    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    /**
     * 附件渲染
     * @type {Function}
     * @return {HTMLElement} 渲染后的附件链接
     */
    attachment = () => (this.props.attachment && this.props.attachment.file && this.props.attachment.file.response) ? <a href={this.props.attachment.file.response.url} target="_blank" rel="noopener noreferrer"><Icon className="mt10 mr5" type="file" />{this.props.attachment.file.response.name}</a> : ''

    /**
     * 渲染日期
     * @type {Function}
     * @return {String}
     */
    date = () => `${this.props.date.getFullYear()}-${twoDigit(this.props.date.getMonth() + 1)}-${twoDigit(this.props.date.getDate())} ${twoDigit(this.props.date.getHours())}:${twoDigit(this.props.date.getMinutes())}`

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => <span><Button onClick={this.showModal}>
            {this.props.buttonText}
        </Button>
            <Modal
                title={`预览：${this.props.title ? this.props.title : '无标题'}（时间：${this.date()}）`}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                className={'previewModal'}
            >
                <span dangerouslySetInnerHTML={{ __html: this.props.content }}></span>
                {this.attachment()}
            </Modal>
        </span>
}