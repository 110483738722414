import React, { Component } from 'react'
import { Form, Radio, Input, InputNumber } from 'antd'
const { TextArea } = Input;

class SoftwareOne extends Component {
    getItemsValue = ()=>{ 
        // const valus= this.props.form.getFieldsValue();
        // return valus;
        let returnValues
        this.props.form.validateFields((err,value) => {
            if(err){
                returnValues = false
            }else{
                returnValues = value
                returnValues = this.props.form.getFieldsValue()
            }
        })
        return returnValues;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                <Form.Item label="套餐类型">
                    {getFieldDecorator('comboId', {
                        rules: [{ required: true, message: '请选择套餐类型！' }],
                    })(
                        <Radio.Group>
                            <Radio value="4">小微企业套餐</Radio>
                            <Radio value="5">中型企业套餐</Radio>
                            <Radio value="6">大型企业套餐</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                <Form.Item label="开通时长">
                    {getFieldDecorator('payCount', {
                        rules: [{ required: true, message: '请输入开通时长！' }],
                    })(
                        <InputNumber placeholder="开通时长" />
                    )}
                    <span className="ant-form-text"> 年</span>
                </Form.Item>
                <Form.Item label="空间数">
                    {getFieldDecorator('comboSpaceCount', {
                        rules: [{ required: true, message: '请输入空间大小！' }],
                    })(
                        <InputNumber placeholder="空间数" />
                    )}
                    <span className="ant-form-text"> G</span>
                </Form.Item>
                <Form.Item label="付费类型">
                    {getFieldDecorator('type', {
                        rules: [{ required: true, message: '请选择付费类型！' }],
                    })(
                        <Radio.Group>
                            <Radio value="2">付费</Radio>
                            <Radio value="3">赠送</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                <Form.Item label="代理商推广码">
                    {getFieldDecorator('promocode')(
                        <Input placeholder="代理商推广码"/>
                    )}
                </Form.Item>
                <Form.Item label="交易金额">
                    {getFieldDecorator('payCost')(
                        <Input placeholder="交易金额"/>
                    )}
                </Form.Item>
                <Form.Item label="交易号">
                    {getFieldDecorator('tradeNo')(
                        <Input placeholder="交易号"/>
                    )}
                </Form.Item>
                <Form.Item label="备注">
                    {getFieldDecorator('remark',{
                        rules: [{max:200,message: '内容不可多于200个字符',}]
                    })(
                        <TextArea
                        placeholder="请填写备注"
                        style={{ height: 75}}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const SoftwareOneForm = Form.create()(SoftwareOne);

export default SoftwareOneForm;

// export default Form.create()(SoftwareOne); 