import React, { Component } from 'react'
import { Form, Input, InputNumber, DatePicker } from 'antd'
import moment from 'moment'

const { TextArea } = Input;

class Adjust extends Component {
    getItemsValue = ()=>{ 
        const valus= this.props.form.getFieldsValue();
        return valus;
    }
    resetValue = () =>{
        this.props.form.resetFields()
    }
    //日期处理
    onChange = (date, dateString) => {
        console.log(date, dateString);
    }
    render() {
        //console.log(this.props.groupBasic)
        const groupBasic = this.props.groupBasic;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList">
                 <Form.Item label="子企业数">
                    {getFieldDecorator('comboUserCount',{
                        initialValue: groupBasic.comboUserCount,
                    })(
                        <InputNumber placeholder="子企业数" />
                    )}
                    <span className="ant-form-text"> 个</span>
                </Form.Item>
                <Form.Item label="到期时间">
                    {getFieldDecorator('dueTime',{
                        initialValue:  moment(groupBasic.dueTime),
                    })(
                        <DatePicker format="YYYY-MM-DD" onChange={this.onChange} placeholder="成立时间"/>
                    )}
                    <span className="ant-form-text"> 00:00:00</span>
                </Form.Item>
                <Form.Item label="备注">
                    {getFieldDecorator('remark',{
                        initialValue: groupBasic.remark,
                    })(
                        <TextArea
                        placeholder="请填写备注"
                        style={{ height: 75}}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const AdjustForm = Form.create()(Adjust);

export default AdjustForm;

// export default Form.create()(Adjust); 