import React, { Component } from 'react'
import {Table,Modal,Pagination,message,Card} from 'antd'
import {getQueryListData,PostAuthorData} from './../../../../Api/index'
import utils from './../../../../Common/js/utils'

class MedicalManage extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        //addVisible:false, //新增账号
        //editVisible:false, //编辑账号
        ableVisible:false, //授权
        disableVisible:false, //取消授权
        pagination:'',
        AuthorList:[],//企业授权列表
        pageRequest:{
            page:1,
            pageSize:10
        },
    };

    componentWillMount() {
        this.setState({
            
        })
        this.AuthorRequestList();
        
    }

    //企业授权列表
    AuthorRequestList = () => {
        let _this = this
        getQueryListData({
            cid:this.state.cid,
            pageNum:this.state.pageRequest.page,
            pageSize:this.state.pageRequest.pageSize
        }).then((res)=>{
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.pageRequest.page,this.state.pageRequest.pageSize);
                this.setState({
                    total:res.data.result.total,
                    AuthorList:res.data.result.rows,
                    page:res.data.result.page,
                    pagination:utils.pagination(res.data,(current,size)=>{
                        _this.state.pageRequest.page = current
                        _this.state.pageRequest.pageSize = size
                        this.AuthorRequestList()
                    },(current,size)=>{
                        _this.state.pageRequest.page = current
                        _this.state.pageRequest.pageSize = size
                        this.AuthorRequestList()
                    })
                })
            }else{
                message.error(res.data.content);
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    ///////////////////////////////////// 取消授权
    //取消授权 弹出内容 显示状态
    disableshowModal = (record) => {
        this.setState({
            disableVisible: true,
            record:record
        });
    };
    //取消授权 弹出框 确定按钮 操作
    disableOk = () => {
        this.setState({ loading: false, disableVisible: false });
        this.AuthorIsRequest(this.state.record);
    };
    //取消授权 弹出框 取消按钮 操作
    disableCancel = () => {
        this.setState({ disableVisible: false });
    };

    ///////////////////////////////////// 授权
    //授权 弹出内容 显示状态
    ableshowModal = (record) => {
        this.setState({
            ableVisible: true,
            record:record,
        });
    };
    //授权 弹出框 确定按钮 操作
    ableOk = () => {
        this.setState({ loading: false, ableVisible: false });
        this.AuthorIsRequest(this.state.record);
    };
    //授权 弹出框 取消按钮 操作
    ableCancel = () => {
        this.setState({ ableVisible: false });
    };

    //启用账号/禁用账号 请求接口
    AuthorIsRequest = (record) => {
        PostAuthorData({
            cid:record.cid,
            healthCorpId:record.healthCorpId,
            reportFullName:record.reportFullName,
            status:record.status=0 ? 1 : 0,
            statusName:record.statusName
        }).then((res)=>{
            if(res.status === 200){
                this.AuthorRequestList()
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    
    render() {
        //表格 表头
        const columns = [
            {title: '企业名称',dataIndex: 'reportFullName',align:'center'},
            {title: '状态',dataIndex: 'status',align:'center',
                render: (text,record) => {
                    if(record.status === 0) {
                        return (
                            <div>
                                待授权
                            </div>
                        )
                    }else if(record.status === 1){
                        return (
                            <div>
                                已授权
                            </div>
                        )
                    }
                }
            },
            {title: '操作',dataIndex: 'operation',align:'center',
                render: (text,record) => {
                    if(record.status === 0) {
                        return (
                            <div>
                                <a className="green mlr5" onClick={() => this.ableshowModal(record)}>授权</a>
                            </div>
                        )
                    }else if(record.status === 1){
                        return (
                            <div>
                                <a className="red mlr5" onClick={() => this.disableshowModal(record)}>取消授权</a>
                            </div>
                        )
                    }
                }
            }
        ];
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="企业授权列表">
                    <Table bordered columns={columns} dataSource={this.state.AuthorList} pagination={false} />
                    <Pagination {...this.state.pagination} className="fr margin10" />
                </Card>

                {/* 取消授权 */}
                <Modal
                width={360}
                visible={this.state.disableVisible}
                loading={this.state.loading}
                title="取消体检查询授权"
                onOk={this.disableOk}
                onCancel={this.disableCancel}
                okText="是"
                cancelText="否"
                >
                    是否取消授权该用户查询所选体检企业的体检单？
                </Modal>

                {/* 授权 */}
                <Modal
                width={360}
                visible={this.state.ableVisible}
                loading={this.state.loading}
                title="体检查询授权"
                onOk={this.ableOk}
                onCancel={this.ableCancel}
                okText="是"
                cancelText="否"
                >
                    是否确认授权该用户查询所选体检企业的体检单？
                </Modal>
                
            </div>
        )
    }
}

export default MedicalManage;
