import React, { Component } from 'react'
import {Card,Row,Col,Icon,Popover,Button,Modal,Form,Select,Input,List,Tabs,Pagination,Badge} from 'antd'
import ReactEcharts from 'echarts-for-react'
import { NavLink } from 'react-router-dom'
import { homeQuestionCheck,homeQuestionList } from '../../../Mock'
import { getAdminNotebookListData } from './../../../Api'
import '../home.css'
import Check from './Check'
import Uncheck from './Uncheck'
const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea
const TabPane = Tabs.TabPane
const ListItem = List.Item
const ListItemMeta = List.Item.Meta
export default class View extends React.Component {
    state={
        questionNum:0
    }
    getQuestionListNumber = (data) => {
        getAdminNotebookListData({...data,status:1,pageSize:10,pageNum:1}).then(res => {
            if (res.data.state.value == 0) {
                this.setState({
                    questionNum: res.data.result.records,
                })
            }
        })
    }
    componentWillMount(){
        this.getQuestionListNumber()
    }
    handleChangeTab = (key) => {
        switch(key){
            case '1':
                    this.getQuestionListNumber()
                    this.child.getQuestionList()
                break;
            case '2':
                    this.getQuestionListNumber()
                    this.child.getQuestionList()
                break;
            default:
                break;
        }
    }
    onRef = (ref) => {
        this.child = ref
    }
    render() {
        return (
            <div className="home">
                <Tabs defaultActiveKey='1' onChange={this.handleChangeTab}>
                    <TabPane
                        className="width-100"
                        tab={
                            <a href='#' className='home'>
                                <Badge count={this.state.questionNum} className="subBadge">
                                    <span>待解决</span>
                                </Badge>
                            </a>
                        }
                        key='1'
                    >
                        <Uncheck onRef={this.onRef} getQuestionListNumber={this.getQuestionListNumber}></Uncheck>
                    </TabPane>
                    <TabPane tab='已解决' key='2'>
                        <Check onRef={this.onRef} getQuestionListNumber={this.getQuestionListNumber}></Check>
                    </TabPane>
                </Tabs></div>)
    }
}