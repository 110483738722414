import React, { Component } from 'react'

// import imgUrl from './../../../Common/images/01.png'

class PictureBottom1 extends Component {
    render() {
        console.log(this.props.record);
        return (
            <div className="HealthCertificate">
                <div className="HealthTitle">
                    <div className="HealthTitLeft">健康券使用情况</div>
                    <div className="HealthTitRight">使用健康券</div>
                </div>
                <ul className="HealthPerop">
                    <li>
                        <div className="HealthTitl">支付金额</div>
                        <div className="HealthNeir">{this.props.record.payCost} 元</div>
                    </li>
                    <li>
                        <div className="HealthTitl">支付时间</div>
                        <div className="HealthNeir">{this.props.record.payData}</div>
                    </li>
                    <li>
                        <div className="HealthTitl">服务时间</div>
                        <div className="HealthNeir">{this.props.record.serviceData}</div>
                    </li>
                    <li>
                        <div className="HealthTitl">关闭时间</div>
                        <div className="HealthNeir">{this.props.record.shutData}</div>
                    </li>
                    <li>
                        <div className="HealthTitl">是否退款</div>
                        <div className="HealthNeir">{this.props.record.isRefund === true ? "是" : "否"}</div>
                    </li>
                    <li>
                        <div className="HealthTitl">关闭理由</div>
                        <div className="HealthNeir">{this.props.record.closeReason}</div>
                    </li>
                </ul>
            </div>
        )
    }
}

// const PictureTopForm = Form.create()(PictureCenter);

export default PictureBottom1;