import React, { Component } from 'react'
import {Card,message,Button,Form,Input,Select,Cascader,InputNumber} from 'antd'
import { viewCompany,editCompany,getRegisterType,getIndustry } from './../../../../Api'
// import { resolve } from 'dns'

const { Option } = Select

class UnitBusiness extends Component {
    // 状态机
    state={
        display_none: 'none', //此状态机为display的取值
        display_block: 'block', //此状态机为display的取值
        cid:this.props.record,
        companyInfo:{},
        registerOptions:[],
        industryOptions:[]
    }
    //组件内获取信息接口-弃用
    requestList = ()=>{
        viewCompany(this.state.cid).then(res=>{
            if(res.status === 200 && res.data.state.value ===0){
                if(res.data.result.companyBaseModel&&res.data.result.companyBaseModel.industryCategoryValue){
                    let industry = res.data.result.companyBaseModel
                    res.data.result.companyBaseModel.industryCategoryValues = industry.industryCategoryValue1 +(industry.industryCategoryValue2?('/' +industry.industryCategoryValue2):'')
                    if(industry.industryCategoryValue3){
                        res.data.result.companyBaseModel.industryCategoryValues += '/'+industry.industryCategoryValue3
                    }
                    if(industry.industryCategoryValue4){
                        res.data.result.companyBaseModel.industryCategoryValues += '/'+industry.industryCategoryValue4
                    }
                    res.data.result.companyBaseModel.industryCategoryValue = []
                }
                this.setState({
                    companyInfo:res.data.result
                })
            }else{
                message.warning('获取企业信息失败！')
            }
        })
    }
    //初始化-父组件请求数据
    initialBasic = (res)=>{
        if(res.companyBaseModel&&res.companyBaseModel.industryCategoryValue){
            let industry = res.companyBaseModel
            res.companyBaseModel.industryCategoryValues = industry.industryCategoryValue1 + (industry.industryCategoryValue2?('/' +industry.industryCategoryValue2):'')
            if(industry.industryCategoryValue3){
                res.companyBaseModel.industryCategoryValues += '/'+industry.industryCategoryValue3
            }
            if(industry.industryCategoryValue4){
                res.companyBaseModel.industryCategoryValues += '/'+industry.industryCategoryValue4
            }
            res.companyBaseModel.industryCategoryValue = []
        }
        this.setState({
            companyInfo:res
        })
    }
    requestRegister = ()=>{
        getRegisterType().then(res=>{
            if(res.status === 200 && res.data.state.value === 0){
                let resOptions = []
                res.data.result.map(item=>{
                    resOptions.push(<Option value={item.id} key={item.id}>{item.paramValue}</Option>)
                })
                this.setState({
                    registerOptions:resOptions
                })
            }
        })
    }
    //插入行业分类数据
    restore = (orignalData,listData)=>{
        if(listData.length){
            if(orignalData.length>1){
                orignalData.map(item=>{
                    if(item.children&&item.children.length){
                        this.restore(item.children,listData)
                    }else{
                        if(item.value == listData[0].pid){
                            item.children = listData
                            return
                        }
                    }
                })
                this.setState({
                    industryOptions:orignalData
                })
                return
            }else{
                this.setState({
                    industryOptions:listData
                })
                return
            }
        }
        
    }
    //行业分类级联+异步加载
    requestIndustry = (itemValue)=>{
        let pid = itemValue&&itemValue.value?itemValue.value:''
        if(pid == 'none'){
            return new Promise((resolve)=>{
                resolve()
            })
        }else{
            getIndustry({pid:pid}).then(res=>{
                return new Promise((resolve)=>{
                    let induOptions = []
                    if(res.status === 200 && res.data.state.value === 0){
                            //格式化行业级联数据
                            res.data.result.map(item=>{
                                induOptions.push({
                                    value:item.id,
                                    key:item.id,
                                    pid:item.parentId,
                                    label:item.paramValue,
                                    isLeaf:item.isLeaf,
                                })
                            })
                            //插入行业分类数据
                            this.restore(this.state.industryOptions,induOptions)
                            resolve()
                    }
                })
            })
        }
        
    }
    componentDidMount(){ 
        this.requestRegister()
        this.requestIndustry()
        this.props.form.validateFields();
    }
    componentWillReceiveProps=()=>{
        //父组件内获取信息接口
        this.setState({
            companyInfo:this.props.companyInfo()
        })
        this.initialBasic(this.props.companyInfo())
    }
    requestIndustryList= async ()=>{
        let arr = []
        if(this.state.companyInfo.companyBaseModel.industryCategory1){
            arr.push(this.state.companyInfo.companyBaseModel.industryCategory1)
        }
        if(this.state.companyInfo.companyBaseModel.industryCategory2){
            arr.push(this.state.companyInfo.companyBaseModel.industryCategory2)
        }
        if(this.state.companyInfo.companyBaseModel.industryCategory3){
            arr.push(this.state.companyInfo.companyBaseModel.industryCategory3)
        }
        if(this.state.companyInfo.companyBaseModel.industryCategory4){
            arr.push(this.state.companyInfo.companyBaseModel.industryCategory4)
        }
        await this.requestIndustry({value:this.state.companyInfo.companyBaseModel.industryCategory1?this.state.companyInfo.companyBaseModel.industryCategory1:'none'})
        await this.requestIndustry({value:this.state.companyInfo.companyBaseModel.industryCategory2?this.state.companyInfo.companyBaseModel.industryCategory2:'none'})
        await this.requestIndustry({value:this.state.companyInfo.companyBaseModel.industryCategory3?this.state.companyInfo.companyBaseModel.industryCategory3:'none'})
        await this.requestIndustry({value:this.state.companyInfo.companyBaseModel.industryCategory4?this.state.companyInfo.companyBaseModel.industryCategory4:'none'})
        await new Promise((resolve)=>{
            // this.state.companyInfo.companyBaseModel.industryCategoryValue = arr
            let copy = this.state.companyInfo
            copy.companyBaseModel.industryCategoryValue = arr
            this.setState({
                display_none: 'block',
                display_block: 'none',
                companyInfo:copy
            })
            resolve()
        })
    }
    //点击编辑
    switchTal = () => {
        this.requestIndustryList()
    }
    //下拉
    handleSelectChange = value => {
        // console.log(value);
        this.props.form.setFieldsValue({
            //note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
        });
    };
    //保存按钮
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                let params = {
                    cid:this.state.cid,
                    companyBaseModel:{},
                    companySuperviseModel:{}
                }
                //companyPlatformModel部分（cid\id必传）
                params.companySuperviseModel.id = this.state.companyInfo.companySuperviseModel&&this.state.companyInfo.companySuperviseModel.id//id
                params.companySuperviseModel.cid = this.state.cid //cid
                params.companySuperviseModel.operationStatus = values.operationStatus//经营状态
                //companyBaseModel部分（cid\id必传）
                params.companyBaseModel.id = this.state.companyInfo&&this.state.companyInfo.companyBaseModel.id//id
                params.companyBaseModel.registerType = Number(values.registerType)//注册类型
                params.companyBaseModel.registerCapital = values.registerCapital//注册资本
                params.companyBaseModel.legalName = values.legalName//法人代表姓名
                params.companyBaseModel.legalTel = values.legalTel//法人代表联系电话
                params.companyBaseModel.industryCategory = values.industryCategory[values.industryCategory.length-1]//行业分类
                params.companyBaseModel.scale = values.scale//企业规模
                params.companyBaseModel.mainOperaIncome = values.mainOperaIncome//主营营业收入
                params.companyBaseModel.yearAssets = values.yearAssets//企业年度产值
                params.companyBaseModel.totalAssets = values.totalAssets//企业资产总值
                //地址暂时无法对接
                // params.companyBaseModel.registerAddress = values.registerAddress
                // params.companyBaseModel.companyPostalAdress = values.companyPostalAdress
                // params.companyBaseModel.workAddress = values.workAddress
                editCompany(params).then((res)=>{
                    if(res.status===200){
                        this.props.form.resetFields();//取消先清空之前输入的数据
                        this.setState({ //跳转到查看页面
                            display_none: 'none',
                            display_block: 'block',
                        })
                        this.props.requestList().then(res=>{
                            this.initialBasic(res)
                        })
                    }
                })
            }
        });
    };
    //取消按钮
    handleReset = () => {
        this.props.form.resetFields();//取消先清空之前输入的数据
        this.setState({ //跳转到查看页面
            display_none: 'none',
            display_block: 'block',
        })
    };
    change = (a) =>{
            this.requestIndustry(a[a.length-1])
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const {companyInfo} = this.state
        return (
            <div className="mb10">
                <Card 
                className="CardBorderNone"
                title="经营信息" 
                extra={
                    <Button style={{display:this.state.display_block}} type="primary" onClick={this.switchTal}>编辑</Button>
                }>
                    <Form className="unitBasicView" style={{display:this.state.display_block}} layout="inline">
                        <Form.Item label="注册类型">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.registerTypeValue:' '}</div>
                        </Form.Item>
                        <Form.Item label="注册资本(万元)">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.registerCapital:' '}</div>
                        </Form.Item>
                        <Form.Item label="法人代表姓名">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.legalName:' '}</div>
                        </Form.Item>
                        <Form.Item label="法人代表联系电话">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.legalTel:' '}</div>
                        </Form.Item>
                        <Form.Item label="行业分类">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.industryCategoryValues:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业规模">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.scaleStr:' '}</div>
                        </Form.Item>
                        <Form.Item label="经营状态">
                            <div className="unitBasicData">{companyInfo.companySuperviseModel?companyInfo.companySuperviseModel.operationStatus:' '}</div>
                        </Form.Item>
                        <Form.Item label="主营营业收入(万元)">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.mainOperaIncome:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业年度产值(万元)">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.yearAssets:' '}</div>
                        </Form.Item>
                        <Form.Item label="企业资产总值(万元)">
                            <div className="unitBasicData">{companyInfo.companyBaseModel?companyInfo.companyBaseModel.totalAssets:' '}</div>
                        </Form.Item>
                    </Form>

                    <Form className="unitBasicEdit" style={{display:this.state.display_none}} layout="inline" onSubmit={this.handleSubmit}>
                        <Form.Item label="注册类型">
                            {getFieldDecorator('registerType',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.registerType:'',
                            })(
                                <Select
                                    placeholder="注册类型"
                                    onChange={this.handleSelectChange}
                                >
                                    {this.state.registerOptions}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="注册资本">
                            {getFieldDecorator('registerCapital',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.registerCapital:'',
                            })(
                                <InputNumber placeholder="注册资本" />
                            )}
                            <span className="ant-form-text"> 万元</span>
                        </Form.Item>
                        <Form.Item label="法人代表姓名">
                            {getFieldDecorator('legalName',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.legalName:'',
                            })(
                                <Input placeholder="法人代表姓名"/>
                            )}
                        </Form.Item>
                        <Form.Item label="法人代表联系电话">
                            {getFieldDecorator('legalTel',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.legalTel:'',
                            })(
                                <Input placeholder="法人代表联系电话"/>
                            )}
                        </Form.Item>
                        <Form.Item label="行业分类">
                            {getFieldDecorator('industryCategory',{
                                initialValue:companyInfo.companyBaseModel&&companyInfo.companyBaseModel.industryCategoryValue?companyInfo.companyBaseModel.industryCategoryValue:[],
                            })(
                                <Cascader
                                    placeholder="行业分类"
                                    options={this.state.industryOptions} 
                                    // onChange={this.change}
                                    changeOnSelect
                                    loadData={this.change}
                                />
                            )}
                            {<span>原：{companyInfo.companyBaseModel?companyInfo.companyBaseModel.industryCategoryValues:'无'}</span>}
                        </Form.Item>
                        <Form.Item label="企业规模">
                            {getFieldDecorator('scale',{
                                initialValue:companyInfo.companyBaseModel?Number(companyInfo.companyBaseModel.scale):'',
                            })(
                                <Select
                                placeholder="企业规模"
                                onChange={this.handleSelectChange}
                                >
                                    <Option value={4}>微型企业</Option>
                                    <Option value={3}>小型企业</Option>
                                    <Option value={2}>中型企业</Option>
                                    <Option value={1}>大型企业</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="经营状态">
                            {getFieldDecorator('operationStatus',{
                                initialValue:companyInfo.companySuperviseModel?companyInfo.companySuperviseModel.operationStatus:'',
                            })(
                                <Select
                                placeholder="经营状态"
                                onChange={this.handleSelectChange}
                                >
                                    <Option value="存续">存续</Option>
                                    <Option value="吊销">吊销</Option>
                                    <Option value="迁出">迁出</Option>
                                    <Option value="医院">医院</Option>
                                    <Option value="在业">在业</Option>
                                    <Option value="正常">正常</Option>
                                    <Option value="政府">政府</Option>
                                    <Option value="注销">注销</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="主营营业收入">
                            {getFieldDecorator('mainOperaIncome',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.mainOperaIncome:'',
                            })(
                                <InputNumber placeholder="主营营业收入"/>
                            )}
                            <span className="ant-form-text"> 万元</span>
                        </Form.Item>
                        <Form.Item label="企业年度产值">
                            {getFieldDecorator('yearAssets',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.yearAssets:'',
                            })(
                                <InputNumber placeholder="企业年度产值"/>
                            )}
                            <span className="ant-form-text"> 万元</span>
                        </Form.Item>
                        <Form.Item label="企业资产总值">
                            {getFieldDecorator('totalAssets',{
                                initialValue:companyInfo.companyBaseModel?companyInfo.companyBaseModel.totalAssets:'',
                            })(
                                <InputNumber placeholder="企业资产总值"/>
                            )}
                            <span className="ant-form-text"> 万元</span>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                保 存
                            </Button>
                            <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                                取 消
                            </Button>
                        </Form.Item>
                    </Form>
                </Card> 
            </div>
        )
    }
}

const UnitBusinessForm = Form.create()(UnitBusiness);

export default UnitBusinessForm;