import React, { Component } from 'react'
import { Route, Switch, Redirect} from "react-router-dom";
import PermissManage from './PermissManage'
import PermissEdit from './components/editPermiss'
import ManagerList from './ManagerList'
//对应页面
import SystemList from './SystemList'
// import SystemAdd from './SystemAdd'
class SystemRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    {/* <Route path="/system/list" component={SystemList} /> */}
                    <Route path="/system/list" component={ManagerList} />
                    <Route path="/system/list1/edit" component={PermissEdit} />
                    <Route path="/system/list1" component={PermissManage} />
                    {/* <Route path="/system/add" Component={SystemAdd} /> */}
                    <Redirect exact from="/system" to="/system/list" />
                </Switch>
            </div>
        )
    }
}

export default SystemRouter;