import ajax from './ajax'

// 定义基础路径
export const TEST_URL = '',formHeader={'Content-Type':'application/x-www-form-urlencoded'}
export const download = (data)=> ajax(TEST_URL+'/v1/ajax/file/download/{fileid}', data, 'POST');
 

//上传法律文件
export const uploadOri = (data,headers)=> ajax(TEST_URL+'/v1/ajax/file/uploadLaw', data, 'POST',headers);
//上传文件
export const upload = (data)=> ajax(TEST_URL+'/v1/ajax/file/upload', data, 'POST');
//校验服务器文件存在与否
export const uploadCheck = (data)=> ajax(TEST_URL+'/v1/ajax/file/check/'+data, {}, 'POST');
//删除文件
export const deleteFile=(data)=> ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/deleteFileByStandardLaw',data,'GET');
//通过文件ID获取文件对象
export const getFile = (data)=> ajax(TEST_URL+'/v1/ajax/file/getFile', data, 'POST',formHeader);
//通过文件ID获取文件oss地址
export const getFileUrl = (data)=> ajax(TEST_URL+'/v1/ajax/file/getFileUrl', data, 'POST', formHeader);
//用户登录
export const getUserData = (data)=> ajax(TEST_URL + '/v1/login/byAdmin', data, 'POST');
//获取上传OSS信息
export const getOSS = ()=> ajax(TEST_URL + '/v1/ajax/file/postSignature',{}, 'GET');
//上传到OSS
export const uploadOSS = (data,headers)=> ajax('http://yanfa-oss-file1.oss-cn-hangzhou.aliyuncs.com', data, 'POST',headers);
// 获取表格数据
// export const getTable = (data)=> ajax(RAP_2+'/v1/legalTable/list/page',data, 'GET');
//获取法规库表格数据
export const getTable = (data)=>ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/searchStandardLawList',data,'POST',{},true)
//获取法律效力一级
export const getLegalTypeInit = ()=>ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/queryOneLawAndPro',{},'GET')
//获取法律效力二级
export const getLegalType = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/getLawClassify',data,'GET')
//获取所属项目管理二级
export const getBelongProject = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/getProj',data,'GET')
//法规库-项目管理-树列表
export const projectManage = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/ztreeList',data,'GET')
//法规库-项目管理-树列表-编辑/新增
export const editTree =(data) => ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/ztree/edit',data,'GET')
//法规库-项目管理-树列表-删除
export const deleteTreeList = (data) => ajax(TEST_URL +  '/v1/admin/datamaintenance/v1standardlaw/ztree/delProj',data,'GET')
//法规库-新增-模糊搜索
export const searchLegalAllOptions = (data) => ajax(TEST_URL +  '/v1/admin/datamaintenance/v1standardlaw/searchStandardLawList',data,'POST')
//法规库-新增-模糊搜索-只根据文号搜索
export const searchLegalOptions = (data) => ajax(TEST_URL +  '/v1/admin/datamaintenance/v1standardlaw/queryStandardLawByFileNo',data,'GET')
//法规库-新增-模糊搜索-只根据文号搜索
export const searchLegalOptionsByFileName = (data) => ajax(TEST_URL +  '/v1/admin/datamaintenance/v1standardlaw/queryStandardLawByFileName',data,'GET')
//法规库-新增
export const legalAdd = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/v1standardlaw/addStandardLaw',data,'POST')
//法规库-修改项目
export const editProject  = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/updateProjectByLaw',data,'GET')
//法规库-表格删除
export const deleteList = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/delBatchIds',data,'GET')
//法规库-编辑获取数据
export const getLegalForm = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/v1standardlaw/queryLawInfoById',data,'GET')
//法规库-导入模板
export const fileTem = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/v1standardlaw/importExcelStandardLaw',data,'GET')

export const legalEdit = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/v1standardlaw/updateStandardLaw',data,'POST')
//法规库-查找文件URL
export const getUrl = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/queryOssUrlByStandardLawId',data,'GET')
// export const getUrl = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/v1standardlaw/queryOssUrlByFileNo',data,'GET')

//首页模块Api

//首页-试用申请-待确认-开始/通过
export const auditSoftTryPassYes = (data) => ajax(TEST_URL+'/v1/admin/home/softtry/auditSoftTry_pass_yes',data,'POST',formHeader)
//首页-试用申请-待确认-忽略/不通过
export const auditSoftTryPassNo = (data) => ajax(TEST_URL+'/v1/admin/home/softtry/auditSoftTry_pass_no',data,'POST',formHeader)
//首页-试用申请-待确认-保存
export const saveSoftTry = (data) => ajax(TEST_URL+'/v1/admin/home/softtry/saveSoftTry',data,'POST',formHeader)
//首页-试用申请-待确认
export const getSoftTryList = (data,tryState) => ajax(TEST_URL+'/v1/admin/home/softtry/getSoftTryList?state='+tryState,data,'GET')
//首页-试用申请-详情
export const getSoftTry = (data) => ajax(TEST_URL+'/v1/admin/home/softtry/getSoftTry',data,'GET')
//首页-待认证-用户列表
export const getUserCertificationListData = (data) => ajax(TEST_URL+'/v1/admin/home/certification/user/getUserCertificationListData',data,'GET')
//首页-待认证-医生列表
export const getHaDoctorCertificationListData = (data) => ajax(TEST_URL+'/v1/admin/home/certification/haDoctor/getHaDoctorCertificationListData',data,'GET')
//首页-待认证-医生详情
export const getHaDoctorCertification = (data) => ajax(TEST_URL+'/v1/admin/home/certification/haDoctor/getHaDoctorCertification',data,'GET')
//首页-待认证-用户-详情
export const getUserCertification = (data) => ajax(TEST_URL+'/v1/admin/home/certification/user/getUserCertification',data,'GET')
//首页-待认证-用户审核
export const auditUserCertification = (data) => ajax(TEST_URL+'/v1/admin/home/certification/user/auditUserCertification',data,'POST',formHeader)
//首页-待认证-医生审核
export const auditHaDoctorCertification = (data) => ajax(TEST_URL+'/v1/admin/home/certification/haDoctor/auditHaDoctorCertification',data,'POST',formHeader)
//首页-意见处理-列表
export const suggestListData = (data) => ajax(TEST_URL+'/v1/admin/home/suggest/listData',data,'GET')
//首页-意见处理-详情
export const suggestView = (data) => ajax(TEST_URL+'/v1/admin/home/suggest/view',data,'GET')
//首页-意见处理-回复
export const suggestEdit = (data) => ajax(TEST_URL+'/v1/admin/home/suggest/edit',data,'POST',formHeader)
//首页-法律法规-列表
export const getStandardLawSuggestionListData = (data) => ajax(TEST_URL+'/v1/admin/home/standardLawSuggestion/getStandardLawSuggestionListData',data,'GET')
//首页-记事本-列表
export const getAdminNotebookListData = (data) => ajax(TEST_URL+'/v1/admin/getAdminNotebookListData',data,'GET')
//首页-消息-列表
export const getAdminRemindListData = (data) => ajax(TEST_URL+'/v1/admin/getAdminRemindListData',data,'GET')
//首页-记事本-新增
export const addAdminNotebook = (data) => ajax(TEST_URL+'/v1/admin/addAdminNotebook',data,'POST',formHeader)
//首页-记事本-删除
export const delAdminNotebook = (data) => ajax(TEST_URL+'/v1/admin/delAdminNotebook',data,'POST',formHeader)
//首页-记事本-解决
export const solveAdminNotebook = (data) => ajax(TEST_URL+'/v1/admin/solveAdminNotebook',data,'POST',formHeader)
//首页-记事本-关闭
export const closeAdminRemind = (data) => ajax(TEST_URL+'/v1/admin/closeAdminRemind',data,'POST',formHeader)
//首页-法规反馈-忽略
export const ignoreStandardLawSuggestion = (data) => ajax(TEST_URL+'/v1/admin/home/standardLawSuggestion/ignore',data,'POST',formHeader)
//首页-法规库-保存
export const updateStandardLawSuggestion = (data) => ajax(TEST_URL+'/v1/admin/home/standardLawSuggestion/update',data,'POST')
//首页-法规库-保存并解决
export const updateAndSolvedStandardLawSuggestion = (data,id) => ajax(TEST_URL+'/v1/admin/home/standardLawSuggestion/updateAndSolved?feedbackId='+id,data,'POST')
//首页-法规库-回显
export const queryLawInfoById = (data) => ajax(TEST_URL+'/v1/admin/home/standardLawSuggestion/queryLawInfoById',data,'GET')
//首页-法规库-忽略-编辑
export const standardLawSuggestionSave = (data) => ajax(TEST_URL+'/v1/admin/home/standardLawSuggestion/save',data,'POST',formHeader)
//首页-法规库-忽略-详情
export const getLawFeedbackByIdStandardLawSuggestion = (data) => ajax(TEST_URL+'/v1/admin/home/standardLawSuggestion/getLawFeedbackById',data,'GET')
//首页-试用申请-关闭
export const auditSoftTryFinish = (data) => ajax(TEST_URL+'/v1/admin/home/softtry/auditSoftTry_finish',data,'POST',formHeader)









//用户管理-企业用户列表-RAP2
//export const getUserUnitList = (data)=> ajax('http://rap2api.taobao.org/app/mock/231740/user/unit/list',data);
//用户管理-企业用户列表 + 用户管理-企业用户详情-搜索
export const getUserUnitList = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/listData',data,'POST',{},true);
//用户管理-企业用户列表-批量打标签/取消标签
export const isAdminAdd = (data)=>ajax(TEST_URL+'/v1/admin/account/companyaccount/saveIsAdminAdd',data,'POST')
//用户管理-企业用户列表-备注
export const saveRemark = (data)=>ajax(TEST_URL+'/v1/admin/account/companyaccount/saveRemark',data,'POST')
//用户管理-企业用户详情-获取信息
export const viewCompany = (id)=>ajax(TEST_URL+'/v1/admin/account/companyaccount/viewCompany?cid='+id,{},'POST')
//用户管理-企业用户详情-编辑信息
export const editCompany = (data) => ajax(TEST_URL+'/v1/admin/account/companyaccount/saveCompany',data,'POST')
//注册类型-级联-获取信息
export const getRegisterType = (data) => ajax(TEST_URL+'/v1/ajax/sysParam/getRegisterTypeList',data,'GET')
//行业分类-级联-获取信息
export const getIndustry = (data) => ajax(TEST_URL+'/v1/ajax/sysParam/getIndustryCategoryList',data,'GET')
//区域-级联-获取信息
export const getAreaOptions = (data) => ajax(TEST_URL + '/v1/ajax/district',data,'GET')

//行政区划管理-行政区划列表数据
export const getAreaListData = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/area/getAreaListData',data,'GET')
//行政区划管理-新增行政区域
export const addArea = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/area/addArea',data,'POST',formHeader)
//行政区划管理-删除行政区域
export const delArea = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/area/delArea',data,'POST',formHeader)
//行政区划管理-编辑行政区域
export const saveArea = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/area/saveArea',data,'POST',formHeader)
//行政区划管理-排序行政区域
export const sortArea = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/area/sortArea',data,'POST',formHeader)
//查询
export const queryAreaListData = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/area/queryAreaListData',data,'GET')

//行业管理-行政列表数据
export const getIndustryList = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/industry/getIndustryList',data,'GET')
//行业管理-新增行业
export const addIndustry = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/industry/addIndustry',data,'POST',formHeader)
//行业管理-删除行政行业
export const delIndustry = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/industry/delIndustry',data,'POST',formHeader)
//行业管理-保存行业
export const saveIndustry = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/industry/saveIndustry',data,'POST',formHeader)
//行业管理-排序行业
export const sortIndustry = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/industry/sortIndustry',data,'POST',formHeader)
//查询
export const queryIndustryList = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/industry/queryIndustryList',data,'GET')
//导入
export const importIndustry = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/industry/importIndustry',data,'POST',formHeader)
//导出
export const exportIndustry = (data) => ajax(TEST_URL + '/v1/admin/datamaintenance/industry/exportIndustry',data,'GET')

//系统管理-子管理员账号新增
export const addAdmin = (data) => ajax(TEST_URL + '/v1/admin/sys/admin/addAdmin',data,'POST',formHeader)
//系统管理-删除账号
export const delAdmin = (data) => ajax(TEST_URL + '/v1/admin/sys/admin/delAdmin',data,'POST',formHeader)
//系统管理-运营后台账号列表数据
export const getAdminListData = (data) => ajax(TEST_URL + '/v1/admin/sys/admin/getAdminListData',data,'GET')
//系统管理-运营后台账号详情
export const getAdminModel = (data) => ajax(TEST_URL + '/v1/admin/sys/admin/getAdminModel',data,'GET')
//系统管理-保存运营后台账号数据
export const saveAdmin = (data) => ajax(TEST_URL + '/v1/admin/sys/admin/saveAdmin',data,'POST',formHeader)
//系统管理-修改密码
export const savePassword = (data) => ajax(TEST_URL + '/v1/admin/sys/admin/savePassword',data,'POST',formHeader)








//用户管理-企业用户列表-批量开通软件
export const getSoftBuyBatch = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/soft/batchEnableSoftware', data,'POST');







/*段*/
//用户管理-登陆账号管理
export const getCorpAdminListData = (id)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/corpAdmin/getCorpAdminListData?cid='+id, {},'POST');
//用户管理-登陆账号管理-禁用/启用
export const getCorpAdminForbidden = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/corpAdmin/forbidden', data,'Get');
//用户管理-登陆账号管理-新增账号
export const getCorpAddCorpAdmin = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/corpAdmin/addCorpAdmin', data,'POST');
//用户管理-登陆账号管理-编辑账号
export const getCorpEditCorpAdmin = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/corpAdmin/editCorpAdmin', data,'POST');
//用户管理-登陆账号管理-编辑账号-重置密码
export const getCorpResetPassword = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/corpAdmin/resetCorpAdminPassword', data,'Get');

//职业健康/集团版-基本信息
export const getHealthBasic = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/soft/getSoftBaseInfo', data,'Get');
//职业健康/集团版-基本信息-购买
export const getSoftBuy = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/soft/buy', data,'POST');
//职业健康/集团版-基本信息-调整
export const getSoftAdjust = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/corpSoftAdjustLog/adjustCorpSoft', data,'POST');

//职业健康/集团版-订单信息-列表
export const getOrderList = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/soft/getCorpSoftLogListData', data,'Get',{},true);
//职业健康/集团版-订单信息-处理
export const getOrderDealWith = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/soft/processOrder', data,'POST');
//职业健康/集团版-订单信息-查看
export const getOrderView = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/soft/saveCorpSoftLog', data,'POST');
//职业健康/集团版-订单信息-查看--详情
export const getOrderView2 = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/soft/getCorpSoftLog', data,'GET');

//职业健康/集团版-调整记录-列表
export const getSoftAdjustList = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/corpSoftAdjustLog/getCorpSoftAdjustLogListData', data,'Get',{},true);
//职业健康/集团版-调整记录-备注
export const getSoftSaveRemark = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/corpSoftAdjustLog/saveRemark', data,'Get');

//体检查询管理-企业授权列表
export const getQueryListData = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/healthCheck/getHealthCorpListData', data,'Get');
//体检查询管理-企业授权列表-授权/未授权
export const PostAuthorData = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/healthCheck/chmodHealthCorp', data,'POST');
//体检查询管理-体检单列表
export const getReportItemList = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/healthCheck/getHealthReportItemModelListData', data,'Get');
//体检查询管理-体检单-编辑
export const getEditReportItem = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/healthCheck/editHealthCenterReport', data,'POST');
//体检查询管理-体检单-删除
export const getRemoveReportItem = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/healthCheck/delHealthCenterReport', data,'Get');
//体检查询管理-体检单-一键设置公开
export const batchExpose = (data)=> ajax(TEST_URL + '/v1/admin/account/companyaccount/healthCheck/batchExpose',data,'POST',formHeader)

//开通健康顾问软件
export const getSoftHealthConsut = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/advisor/buyHealthAdvisor', data,'Get');

//健康顾问-赠送接口
export const getSoftTicket = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/advisor/giveCorpHealthTicket', data,'POST');
//健康顾问-赠送记录
export const getSoftLogListData = (data)=> ajax(TEST_URL+'/v1/admin/account/companyaccount/advisor/getHaCorpHealthTicketLogListData', data,'Get');

/* 订单 */
//订单管理-平台列表接口
export const getPlatformList = (data)=> ajax(TEST_URL+'/v1/admin/order/platformOrder/getPlatformOrderListData', data,'Get');

//订单管理-实施版列表接口
export const getImplementList = (data)=> ajax(TEST_URL+'/v1/admin/order/platformOrder/getCorpSoftLogImplementListData', data,'Get');
//订单管理-实施版-创建订单接口
export const getaddCorpList = (data)=> ajax(TEST_URL+'/v1/admin/order/platformOrder/addCorpSoftLogImplement', data,'POST');
//订单管理-实施版-订单详情接口
export const getaddCorpItem = (data)=> ajax(TEST_URL+'/v1/admin/order/platformOrder/getCorpSoftLogImplement', data,'Get');
//订单管理-实施版-编辑订单接口
export const saveGetCorpItem = (data)=> ajax(TEST_URL+'/v1/admin/order/platformOrder/saveCorpSoftLogImplement', data,'POST');

//订单管理-健康顾问订单列表接口
export const getAdvisorList = (data)=> ajax(TEST_URL+'/v1/admin/order/advisorOrder/getAdvisorOrderListData', data,'Get');
//订单管理-健康顾问-订单详情接口
export const getAdvisorItem = (data)=> ajax(TEST_URL+'/v1/admin/order/advisorOrder/getAdvisorOrder', data,'Get');
//订单管理-健康顾问-关闭订单接口
export const closeHaOrder = (data)=> ajax(TEST_URL+'/v1/admin/order/advisorOrder/closeHaOrder', data,'POST');
//订单管理-实施版-上传文件接口
export const postUploadFile = (data)=> ajax(TEST_URL+'/v1/ajax/file/upload', data,'POST');
//订单管理-实施版-查看文件接口
export const showUploadFile = (data)=> ajax(TEST_URL+'/v1/ajax/file/getFileList', data,'POST',formHeader);





//数据维护-体检报告接口
//删除体检单
export const deleteUserTijian = (data)=> ajax(TEST_URL+'/v1/admin/datamaintenance/tijianreport/deleteUserTijian', data,'POST',formHeader);
//删除微信绑定
export const deleteUserweixin = (data)=> ajax(TEST_URL+'/v1/admin/datamaintenance/tijianreport/deleteUserweixin', data,'POST',formHeader);
//体检单详情
export const getHealthCenterReportInfo = (data)=> ajax(TEST_URL+'/v1/admin/datamaintenance/tijianreport/getHealthCenterReportInfo', data,'GET');
//微信列表数据
export const getWechatListData = (data)=> ajax(TEST_URL+'/v1/admin/datamaintenance/tijianreport/getWechatListData', data,'GET');
//重新获取体检单
export const regetUserTijian = (data)=> ajax(TEST_URL+'/v1/admin/datamaintenance/tijianreport/regetUserTijian', data,'POST');
//修改体检单
export const saveTijian = (data)=> ajax(TEST_URL+'/v1/admin/datamaintenance/tijianreport/saveTijian', data,'POST',formHeader);
//用户体检列表数据
export const getHealthCenterReportListData = (data)=> ajax(TEST_URL+'/v1/admin/datamaintenance/tijianreport/getHealthCenterReportListData', data,'GET');



// 个人用户管理--平台用户--列表    +搜索
export const getUserAccountListData = (data)=>ajax(TEST_URL+'/v1/admin/account/useraccount/getUserAccountListData',data,'GET',formHeader)
// 个人用户管理--平台用户--详情
export const getUserModel = (data)=>ajax(TEST_URL+'/v1/admin/account/useraccount/getUserModel',data,'GET')
// 个人用户管理--平台用户--重置平台密码
export const resetPassword = (data)=>ajax(TEST_URL+'/v1/admin/account/useraccount/resetPassword',data,'GET')
// 个人用户管理--平台用户--保存平台账号数据
export const saveUserModel = (data)=>ajax(TEST_URL+'/v1/admin/account/useraccount/saveUserModel',data,'POST')
// 个人用户管理--健康顾问--列表
export const getHealthAdvisorAccountListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haUser/getHealthAdvisorAccountListData',data,'GET',formHeader)
// 个人用户管理--健康顾问--详情
export const getHealthAdvisorAccount = (data)=>ajax(TEST_URL+'/v1/admin/account/haUser/getHealthAdvisorAccount',data,'GET')
// 个人用户管理--健康顾问--保存
export const saveHealthAdvisor = (data)=>ajax(TEST_URL+'/v1/admin/account/haUser/saveHealthAdvisor',data,'GET')
// 个人用户管理--健康顾问--详情--咨询记录
export const getHaOrderFormDetailListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haUser/getHaOrderFormDetailListData',data,'GET')
// 个人用户管理--健康顾问--详情--邀请记录
export const getUserInviteListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haUser/getUserInviteListData',data,'GET')
// 个人用户管理--健康顾问--详情--邀请订单
export const getUserOrderListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haUser/getUserOrderListData',data,'GET')
// 个人用户管理--健康顾问--详情--提现记录
export const getWithdrawListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haUser/getWithdrawListData',data,'GET')
// 个人用户管理--医生用户--列表
export const getHaDoctorListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/getHaDoctorListData',data,'GET')
// 个人用户管理--医生用户--禁用
export const disable = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/disable',data,'POST',formHeader)
// 个人用户管理--医生用户--启用
export const enable = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/enable',data,'POST',formHeader)
// 个人用户管理--医生用户--详情
export const getHaDoctorInfo = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/getHaDoctorInfo',data,'GET')
// 个人用户管理--医生用户--保存编辑
export const saveHaDoctorInfo = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/saveHaDoctorInfo',data,'POST')
// 个人用户管理--医生用户--新增用户
export const addHaDoctorInfo = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/addHaDoctorInfo',data,'POST')
// 个人用户管理--医生用户--擅长疾病
export const doctorGoodAt = (data)=>ajax(TEST_URL+'/v1/ajax/sysParam/getHaSysParamList_doctor_good_at',data,'GET')
// 个人用户管理--医生和健康顾问--订单关闭
export const closeHaOrderFormDetail = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/closeHaOrderFormDetail',data,'POST',formHeader)
// 个人用户管理--健康顾问--详情--咨询记录
export const dgetHaOrderFormDetailListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/getHaOrderFormDetailListData',data,'GET')
// 个人用户管理--健康顾问--详情--邀请记录
export const dgetUserInviteListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/getUserInviteListData',data,'GET')
// 个人用户管理--健康顾问--详情--邀请订单
export const dgetUserOrderListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/getUserOrderListData',data,'GET')
// 个人用户管理--健康顾问--详情--提现记录
export const dgetWithdrawListData = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/getWithdrawListData',data,'GET')
// 个人用户管理--医生用户的基本信息保存
export const saveHaDoctorBasicInfo = (data)=>ajax(TEST_URL+'/v1/admin/account/haDoctor/saveHaDoctorBasicInfo',data,'POST')



// 代理商-订单列表-代理商订单列表数据
export const getAgentOrderListData = (data) => ajax(TEST_URL+'/v1/admin/agent/agentOrder/getAgentOrderListData', data, 'GET', {}, true, true)



// 代理商-业务员管理-查询所有业务员
export const getAllAgentBusinessList = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBusiness/getAllAgentBusinessList', data, 'GET', {}, false, true)
// 代理商-业务员管理-业务员列表数据
export const getAgentBusinessListData = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBusiness/getAgentBusinessListData', data, 'GET', {}, true, true)
// 代理商-业务员管理-查询业务员详情
export const getAgentBusiness = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBusiness/getAgentBusiness', data, 'GET', {}, false, true)
// 代理商-业务员管理-查询下级业务员
export const getAgentBusinessList = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBusiness/getAgentBusinessList', data, 'GET', {}, false, true)
// 代理商-业务员管理-业务员删除
export const delAgentBusiness = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBusiness/delAgentBusiness', data, 'POST', formHeader, false, true)
// 代理商-业务员管理-业务员新增
export const addAgentBusiness = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBusiness/addAgentBusiness', data, 'POST', formHeader, false, true)
// 代理商-业务员管理-业务员信息编辑
export const saveAgentBusiness = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBusiness/saveAgentBusiness', data, 'POST', formHeader, false, true)




// 代理商-代理商管理-基础信息-代理商详情
export const getAgentBasicInfo = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBasicInfo/getAgentBasicInfo', data, 'GET', {}, true, true)
// 代理商-代理商管理-基础信息-代理商列表数据
export const getAgentListData = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBasicInfo/getAgentListData', data, 'GET', {}, true, true)
// 代理商-代理商管理-基础信息-代理商新增
export const addAgent = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBasicInfo/addAgent', data, 'POST', {}, false, true)
// 代理商-代理商管理-基础信息-代理商编辑
export const saveAgent = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBasicInfo/saveAgent', data, 'POST', {}, false, true)
// 代理商-代理商管理-基础信息-代理商启用
export const enableAgent = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBasicInfo/enableAgent', data, 'POST', formHeader, false, true)
// 代理商-代理商管理-基础信息-代理商禁用
export const disableAgent = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBasicInfo/disableAgent', data, 'POST', formHeader, false, true)
// 代理商-代理商管理-基础信息-生成代理商推广码
export const createPromCode = (data) => ajax(TEST_URL+'/v1/admin/agent/agentBasicInfo/createPromCode', data, 'POST', formHeader, false, true)


// 活动设置-管理公告-公告列表数据（平台）
export const getPlatformNoticeListData = (data) => ajax(TEST_URL+'/v1/admin/activity/notice/getPlatformNoticeListData', data, 'GET', {}, false, true)
// 活动设置-管理公告-活动公告详情（平台）
export const editPage = (data) => ajax(TEST_URL+'/v1/admin/activity/notice/editPage', data, 'GET', {}, true, true)
// 活动设置-管理公告-保存活动公告（平台）
export const saveSysNotice = (data) => ajax(TEST_URL+'/v1/admin/activity/notice/saveSysNotice', data, 'POST', formHeader, false, true)
// 活动设置-管理公告-删除活动公告（平台）
export const delSysNotice = (data) => ajax(TEST_URL+'/v1/admin/activity/notice/delSysNotice', data, 'POST', formHeader, false, true)
// 活动设置-管理公告-公告列表数据（健康顾问）
export const getPlatformNoticeListData2 = (data) => ajax(TEST_URL+'/v1/admin/advisor/hanotice/getPlatformNoticeListData', data, 'GET', {}, false, true)
// 活动设置-管理公告-活动公告详情（健康顾问）
export const editPage2 = (data) => ajax(TEST_URL+'/v1/admin/advisor/hanotice/editPage', data, 'GET', {}, true, true)
// 活动设置-管理公告-保存活动公告（健康顾问）
export const saveHaNotice = (data) => ajax(TEST_URL+'/v1/admin/advisor/hanotice/saveHaNotice', data, 'POST', formHeader, false, true)
// 活动设置-管理公告-删除活动公告（健康顾问）
export const delHaNotice = (data) => ajax(TEST_URL+'/v1/admin/advisor/hanotice/delHaNotice', data, 'POST', formHeader, false, true)

//数据维护--危害因素
//新增
export const addRiskFactors = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/riskFactors/addRiskFactors', data, 'POST', formHeader)
//删除
export const delRiskFactors = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/riskFactors/delRiskFactors', data, 'POST', formHeader)
//列表
export const getRiskFactorsListData = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/riskFactors/getRiskFactorsListData', data, 'GET')
//详情
export const getRiskFactors = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/riskFactors/getRiskFactors', data, 'GET')
//保存
export const saveRiskFactors = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/riskFactors/saveRiskFactors', data, 'POST', formHeader)
//显示
export const saveIsShowRiskFactors = (data) => ajax(TEST_URL+'/v1/admin/datamaintenance/riskFactors/saveIsShowRiskFactors', data, 'POST', formHeader)
//在线培训
//文章
// 列表
export const artivleGetList = (data) => ajax(TEST_URL+'/v1/admin/training/article/getList', data, 'GET')
// 查看
export const artivleView = (data) => ajax(TEST_URL+'/v1/admin/training/article/view/'+data, 'GET')
// 新增
export const artivleAdd = (data) => ajax(TEST_URL+'/v1/admin/training/article/add',data, 'POST',formHeader)
// 编辑
export const artivleEdit = (data) => ajax(TEST_URL+'/v1/admin/training/article/edit',data, 'POST',formHeader)
// 删除
export const artivleDel = (data) => ajax(TEST_URL+'/v1/admin/training/article/del?id='+data,{} ,'POST')
// 搜索法规 
export const artivleGetLawListByModel = (data) => ajax(TEST_URL+'/v1/admin/training/article/getLawListByModel',data ,'GET')
// 法规新增文章 
export const artivleAddLaw = (data) => ajax(TEST_URL+'/v1/admin/training/article/addLaw',data, 'POST')
    //公共资源请求 
export const artivlePublic = (id) => ajax(TEST_URL+'/v1/training/article/'+id,{}, 'GET')


//积分管理
export const queryUserIntegralList = (data) => ajax(TEST_URL+'/v1/admin/training/userIntegral/queryUserIntegralList',data, 'GET')

//题库
export const queryQuestionList = (data) => ajax(TEST_URL+'/v1/admin/training/question/queryQuestionList', data, 'GET', {}, false, true)
export const addQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/question/addQuestion', data, 'POST', formHeader, false, true)
export const addRadioQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/question/addRadioQuestion', data, 'POST', formHeader, false, true)
export const addCheckboxQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/question/addCheckBoxQuestion', data, 'POST', formHeader, false, true)
export const addFillQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/question/addFillQuestion', data, 'POST', formHeader, false, true)
export const updateQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/question/updateQuestion', data, 'POST', formHeader, false, true)
export const delQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/question/delQuestion', data, 'POST', formHeader, false, true)
export const importQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/question/importQuestion', data, 'POST', formHeader, false, true)
export const importFillQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/question/importFillQuestion', data, 'POST', formHeader, false, true)
export const updateQuestionStatus = (data) => ajax(TEST_URL+'/v1/admin/training/question/updateQuestionStatus', data, 'POST', formHeader, false, true)

//视频
export const getVideoList = (data) => ajax(TEST_URL+'/v1/admin/training/video/getList', data, 'GET',{},true)
export const delVideo = (id) => ajax(TEST_URL+'/v1/admin/training/video/del?id='+id, {}, 'POST')
export const addVideo = (data) => ajax(TEST_URL+'/v1/admin/training/video/add', data, 'POST',formHeader)
export const editVideo = (data) => ajax(TEST_URL+'/v1/admin/training/video/edit', data, 'POST',formHeader)
export const getVideoCreator = (id) => ajax(TEST_URL+'/v1/admin/training/video/view/'+id, {}, 'GET', {},true)
export const getArticalShare = (id) => ajax(TEST_URL+'/v1/training/article/'+id, {}, 'GET', {},true)
export const getVideoShare = (id) => ajax(TEST_URL+'/v1/training/video/'+id, {}, 'GET', {},true)


//上传视频
export const uploadVideo = (data) => ajax(TEST_URL+'/v1/ajax/file/uploadVideo',data,'POST',{},true)
//分类答题接口
//列表
export const queryExamList = (data) => ajax(TEST_URL+'/v1/admin/training/exam/queryExamList', data, 'GET')
//查看详情
export const queryExamQuestionList = (data) => ajax(TEST_URL+'/v1/admin/training/exam/queryExamQuestionList', data, 'GET')
//删除
export const delExamQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/exam/delExamQuestion', data, 'POST', formHeader)
//新增
export const addExamQuestion = (data) => ajax(TEST_URL+'/v1/admin/training/exam/addExamQuestion', data, 'POST', formHeader)
//生成
export const reCreateExam = (data) => ajax(TEST_URL+'/v1/admin/training/exam/reCreateExam', data, 'POST', formHeader)
//排序
export const saveExamOptionIndex = (data) => ajax(TEST_URL+'/v1/admin/training/exam/saveExamOptionIndex', data, 'POST', formHeader)
//新增专项
export const addSpecialExam = (data) => ajax(TEST_URL+'/v1/admin/training/exam/addSpecialExam', data, 'POST', formHeader)
//保存名称
export const updateExam = (data) => ajax(TEST_URL+'/v1/admin/training/exam/updateExam', data, 'POST', formHeader)
//试题选择上传
export const updateExamQuestionList = (data) => ajax(TEST_URL+'/v1/admin/training/exam/updateExamQuestionList', data, 'POST', formHeader)
//删除试卷
export const delExam = (data) => ajax(TEST_URL+'/v1/admin/training/exam/delExam', data, 'POST', formHeader)
//上架
export const upExam = (data) => ajax(TEST_URL+'/v1/admin/training/exam/upExam', data, 'POST', formHeader)
//专项详情
export const queryExam = (data) => ajax(TEST_URL+'/v1/admin/training/exam/queryExam', data, 'GET')

//权限接口
export const addRole = (data) => ajax(TEST_URL+'/v1/admin/sys/jurisdiction/addRole', data, 'POST',formHeader)
export const getRoleList = (data) => ajax(TEST_URL+'/v1/admin/sys/jurisdiction/getRoleList', data, 'GET')
export const getRoleMenuList = (data) => ajax(TEST_URL+'/v1/admin/sys/jurisdiction/getRoleMenuList', data, 'GET')
export const getRoleMenuList2 = (data) => ajax(TEST_URL+'/v1/admin/sys/jurisdiction/getRoleMenuList2', data, 'GET')
export const saveRoleMenuList = (data) => ajax(TEST_URL+'/v1/admin/sys/jurisdiction/saveRoleMenuList', data, 'POST')
