import React, { Component } from 'react'
import { Upload,Button,Icon,message } from 'antd'
import sparkMd5 from 'spark-md5'
import {postUploadFile,deleteFile,showUploadFile} from './../../../Api'
import {connect} from 'react-redux'

let idflage2=true
class UploadFail extends Component {
    constructor(props){
        super(props);
        this.state={
            jdFiles: ''
        }
    }
    state={
        record:[]
    }
    componentWillReceiveProps(nextProps){
        // let record = []
        // if(!!nextProps.record){
        //     showUploadFile({fileIds:nextProps.record}).then(res=>{
        //         console.log(res)
        //         if(res.data.state.value === 0){
        //             res.data.result&&res.data.result.length!==0&&res.data.result.map(item=>{
        //                 record.push({
        //                     uid: item.id,
        //                     name: item.fileName,
        //                     status: 'done',
        //                     url: 'http:'+item.fullOssurl,
        //                   })
        //             })
        //             this.setState({
        //                 record:record
        //             })
        //         }
                
        //     })
        // }
    }
    componentWillUpdate(){
    }
    render() {
        let _this = this
        //定义上传控件
        const uploadReadProps = {
            name: 'files',
            accept:'application/msword,application/vnd.ms-powerpoint,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            beforeUpload(file){
                console.log(file);
                let reader = new FileReader();
                reader.onload = function(e){
                    let params = new FormData()
                    params.append('file',file)
                    params.append('filename',file.name)
                    params.append('type','0')
                    params.append('filehash',sparkMd5.hashBinary(e.target.result))
                    postUploadFile(params).then(res=>{
                        console.log(res)
                        if(res.status === 200 && res.data.state.value ===0){
                            _this.setState({
                                jdUrl:res.data.result.fullOssurl?res.data.result.fullOssurl:'',
                                jdName:res.data.result.fileName?res.data.result.fileName:'',
                                jdId:res.data.result.id?res.data.result.id:'',
                                jdFiles:res.data.result,
                            })
                            idflage2 = false
                        }
                    })
                }
                // 传入一个参数对象即可得到基于该参数对象的文本内容
                reader.readAsBinaryString(file)
                //阻止antd自带的上传
                return false
            },
            //移除文件时清空url
            onRemove(file){
                
                if(_this.props.type == 'editLegalForm'){
                    deleteFile({id:file.uid}).then(res=>{
                        if(res.data.state.value == 0){
                            _this.state.jdId = 'del'
                            _this.state.jdName = ''
                            _this.state.jdUrl = ''
                            idflage2 = false
                        }else{
                            message.warning('删除文件失败！')
                        }
                    })
                }else{
                    _this.state.jdId = 'del'
                    _this.state.jdName = ''
                    _this.state.jdUrl = ''
                    idflage2 = false
                }
            }
        };
        console.log(this.state)
        return (
            <Upload {...uploadReadProps}>  
                <Button>
                    <Icon type="upload"/> 上传文件
                </Button>
            </Upload>
        )
    }
}

// const mapDispatchToProps = (dispatch) => {
//     console.log(dispatch);
//     // return {
//     //     reqLogin(data, callback){
//     //         const action = getUserDataAction(data, callback);
//     //         dispatch(action);
//     //     }
//     // }
// }

export default UploadFail;
// export default connect(null, mapDispatchToProps)(UploadFail);