import {Select} from "antd";
import React from "react";

export default{
    'RISK_STATUS':{
        2:'一般',
        1:'较重',
        0:'严重'
    },
    'LEAGAL_MANAGE_STATUS':{
        0:'现行',
        1:'即将实施'
    },
    'IS_ADMIN_ADD':{
        0:'',
        1:'内部人员'
    },
    'LAW_STATUS':{
        1:'现行',
        2:'作废',
        3:'即将实施',
        4:'废止',
    },
    'HOME_APPLICATION_STATUS':{
        // 1:'待审核',
        // 2:'已审核',
        // 3:'不通过',
        // 4:'试用结束',
        1:'申请中',
        2:'试用中',
        3:'已忽略',
        4:'已关闭'
    },
    'HOME_SUGGEATION_STATUS':{
        0:'草稿',
        1:'待反馈',
        2:'已反馈'   //暂时
    },
    'HOME_LEGAL_STATUS':{
        0:'待解决',
        1:'已解决',
        2:'已忽略'   //暂时
    },
   'LEGAL_SOURCE':{
       1:'平台个人账号',
       2:'平台企业账号'
   },
    'TRAIN_TYPE':{
        1:'职业卫生综合',
        2:'职业危害防护',
        3:'职业健康监护',
        4:'职业危害因素',
        5:'职业卫生三同时',
        6:'职业病',
        7:'放射卫生'
    },
    'VIDEO_STATUS':{
        1:'未上架',
        2:'已上架',
        3:'已下架'
    }
}