import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Select, Input, DatePicker,Radio  } from 'antd'
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
/**
 * Expose-Form组件
 * 内容：Form
 * 功能：对接体检一键设置功能
 * 父组件传入属性：
 *      handelPost       提交的方法
 */
class FormModal extends Component {
    state = {
        formItem: [{
            name: "time",
                type: 'rangePicker',
                placeholder: '时间范围',
                label:"时间范围"
        },{
            name: "isExpose",
                type: 'radio',
                label:"是否公开",
                list:[
                    {
                        key:"公开",
                        val:true
                    },
                    {
                        key:"不公开",
                        val:false
                    }
                ],
        }]
    }
    //Modal-Form提交事件
    submit = () => {
        this.props.form.validateFields((err, value) => {
            //调用父组件传入的提交Api方法
            if(!err){
            this.props.handelPost({...value})
            }
        })
    }
    
    //清空表单
    reset = () => {
        this.props.form.resetFields();
    }
    render() {
        const { getFieldDecorator } = this.props.form
        //Modal展示内容
        let formItemList = []
        //通过父组件传入的配置  渲染Modal内容
        this.state.formItem.map((item, index) => {
            switch (item.type) {
                case 'rangePicker':
                    let rangePickerForm = <FormItem key={item.name + index} label={item.label}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: [
                                {required:true,message:"该项必填"}
                            ]
                        })(
                            <RangePicker placeholder={item.placeholder} />
                        )
                        }
                    </FormItem>
                    formItemList.push(rangePickerForm)
                    break
                case  'radio':
                    let radioForm = <FormItem key={item.name+index} label={item.label}>
                     {getFieldDecorator(`${item.name}`, {
                            rules: [
                                {required:true,message:"该项必填"}
                            ]
                        })(
                            <Radio.Group onChange={this.onChange}>
                                {item.list.map((itemkid,i)=>{
                                
                                    return <Radio value={itemkid.val} key={itemkid.val+itemkid.key+i}>{itemkid.key}</Radio>
                                })}
                            </Radio.Group>
                        )
                        }
                    </FormItem>
                        formItemList.push(radioForm)

                    break
                default:
                    break

            }
        })
        return (
            <div className="expose">
                <Form layout="inline" className="search-box">
                    {formItemList}
                </Form>
            </div>
        )
    }
}

FormModal = Form.create({})(FormModal);


export default FormModal;