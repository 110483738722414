import React, { Component } from 'react'
import { Form, Input } from 'antd'

// const { TextArea } = Input;

class PictureTopOne extends Component {
    // getItemsValue = ()=>{ 
    //     const valus= this.props.form.getFieldsValue();
    //     return valus;
    // }
    // resetValue = () =>{
    //     this.props.form.resetFields()
    // }
    render() {
        // console.log(this.props.record);
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" className="SoftWareList SoftWareWidth">
                <Form.Item label="订单号" className="widtHalf">
                    {getFieldDecorator('id',{
                        initialValue:this.props.record.id
                    })(
                        <Input disabled placeholder="订单号"/>
                    )}
                </Form.Item>
                <Form.Item label="创建时间" className="widtHalf">
                    {getFieldDecorator('createDate',{
                        initialValue:this.props.record.createDate
                    })(
                        <Input disabled placeholder="创建时间"/>
                    )}
                </Form.Item>
                <Form.Item label="申请人" className="widtHalf">
                    {getFieldDecorator('fullname',{
                        initialValue:this.props.record.fullname
                    })(
                        <Input disabled placeholder="申请人"/>
                    )}
                </Form.Item>
                <Form.Item label="订单类型" className="widtHalf">
                    {getFieldDecorator('orderTypeName',{
                        initialValue:this.props.record.orderTypeName
                    })(
                        <Input disabled placeholder="订单类型"/>
                    )}
                </Form.Item>
                <Form.Item label="服务医生" className="widtHalf">
                    {getFieldDecorator('userDoctorName',{
                        initialValue:this.props.record.userDoctorName
                    })(
                        <Input disabled placeholder="服务医生"/>
                    )}
                </Form.Item>
                <Form.Item label="订单金额" className="widtHalf">
                    {getFieldDecorator('orderMoney',{
                        initialValue:this.props.record.orderMoney
                    })(
                        <Input disabled placeholder="订单金额"/>
                    )}
                </Form.Item>
            </Form>
        )
    }
}

const PictureTopForm = Form.create()(PictureTopOne);

export default PictureTopForm;