/**
 * @file 组件：提成比例
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Input } from 'antd'

/**
 * 组件：提成比例
 * @class
 * @extends React.Component
 */
class Draw extends React.Component {

    /**
     * 最小值变化
     * @type {Function}
     * @param {Event} e 值改变事件
     */
    handleMinChange = e => this.triggerChange({ paymentRows: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value) })

    /**
     * 最大值变化
     * @type {Function}
     * @param {Event} e 值改变事件
     */
    handleMaxChange = e => this.triggerChange({ paymentOffset: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value) })

    /**
     * 提成变化
     * @type {Function}
     * @param {Event} e 值改变事件
     */
    handlePercentChange = e => this.triggerChange({ paymentScale: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value) })

    /**
     * 触发值变化
     * @type {Function}
     * @param {Object} changedValue 变化的值
     */
    triggerChange = changedValue => {
        const { onChange, value } = this.props;
        if (onChange) {
            onChange({
                ...value,
                ...changedValue,
            });
        }
    };

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render() {
        const { paymentRows, paymentOffset, paymentScale } = this.props.value ? this.props.value : {}
        return (
            <span>
                <Input value={paymentRows} style={{ width: '30%' }} type="text" onChange={this.handleMinChange} /><span className="ml5 mr5">-</span>
                <Input value={paymentOffset} style={{ width: '30%' }} type="text" onChange={this.handleMaxChange} /><span className="ml5 mr5">:</span>
                <Input value={paymentScale} style={{ width: '30%' }} type="text" onChange={this.handlePercentChange} /><span className="ml5 mr5">%</span>
            </span>
        );
    }
}
export default Draw