import React, { Component } from 'react'
import { Route, Switch, Redirect } from "react-router-dom";

//对应页面
import ActivityManagement from './ActivityManagement'
import ActivityManagementAdd from './ActivityManagementAdd'
import ActivityManagementEdit from './ActivityManagementEdit'


class ActivityRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/activity/management/edit" component={ActivityManagementEdit}/>
                    <Route path="/activity/management/add" component={ActivityManagementAdd} />
                    <Route path="/activity/management" component={ActivityManagement} />
                    {/* <Route path="/activity/add" Component={ActivityAdd} /> */}
                    <Redirect exact from="/activity" to="/activity/management" />
                </Switch>
            </div>
        )
    }
}

export default ActivityRouter;