/*
 * @file: 医生用户擅长增加组件
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import { Button, Form, Checkbox, Input, Row, Col } from 'antd'
import { doctorGoodAt } from './../../../../Api/index'
/**
 * @classdesc: 医生用户擅长增加组件
 */
class ColoseOrder extends Component {
    /**
     * @description: state自定义
     * @param {object} Subjectdata 科别数据
     * @param {object} occupationaldata 职业病数据
     * @param {boolean} elseSubjectdata 其他科别
     */
    state = {
        Subjectdata: [],
        occupationaldata: [],
        elseSubjectdata: [],

    }
    // 生命周期
    componentWillMount() {
        this.requestTableList()
    }
    /**
     * @description: 获取数据
     * @function: requestTableList
     */
    requestTableList = () => {
        let _this=this
        let Subjectdata = this.state.Subjectdata
        let occupationaldata = this.state.occupationaldata
        doctorGoodAt().then(res => {
            if (res.data.state.name === '操作成功' && res.data.state.value === 0) {
                let disease = res.data.result.doctorDiseaseGoodAtList
                let goodAt = res.data.result.doctorGoodAtList
                console.log(disease, goodAt)
                for (var i = 0; i < disease.length; i++) {
                    occupationaldata.push({label:disease[i].paramValue,value:[disease[i].id,disease[i].paramValue]})
                }
                for (var i = 0; i < goodAt.length; i++) {
                    Subjectdata.push({label:goodAt[i].paramValue,value:[goodAt[i].id,goodAt[i].paramValue]})
                }
                _this.setState({
                    occupationaldata,
                    Subjectdata,
                })
            }
        })
       
    }
    /**
     * @description: 将数据返回父组件
     * @function: getItemsValue
     */
    getItemsValue = () => {
        const valus = this.props.form.getFieldsValue();
        return valus;
    }
    /**
     * @description: 清除子组件内容
     * @function: resetValue
     */
    resetValue = () => {
        this.props.form.resetFields()
    }
    /**
     * @description: 添加新的标签
     * @function: elseSubjectAdd
     */
    elseSubjectAdd = () => {
        var addinput = this.refs.addInput.state.value
        if (addinput) {
            let elseSubjectdata = this.state.elseSubjectdata
            if (elseSubjectdata.length > 0) {
                var bol = true;
                for (var i = 0; i < elseSubjectdata.length; i++) {
                    if (elseSubjectdata[i].value == addinput) {
                        bol = false;
                    }
                }
                if (bol) {
                    elseSubjectdata.push({ label: addinput, value: addinput })
                }
            } else {
                elseSubjectdata.push({ label: addinput, value: addinput })
            }
            this.setState({
                elseSubjectdata
            })
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div className='GoodAtAdd'>
                <Row className="text-align-center" style={{ display: 'flex' }}>
                    {/* 科别 */}
                    <Col span={4} className="leftTitle border">
                        <span className="width-100">科别</span>
                    </Col>
                    <Col span={20} className='rightBody border'>
                        <Form className="width-100 text-align-left plr25" >
                            <Form.Item style={{ margin: '10px 0' }}>
                                {
                                    getFieldDecorator('Subject', {

                                    })(
                                        <Checkbox.Group options={this.state.Subjectdata} />
                                    )
                                }
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                {/* 职业病 */}
                <Row className="text-align-center GoodAtAdd" style={{ display: 'flex' }}>

                    <Col span={4} className="leftTitle border">
                        <span className="width-100">职业病</span>
                    </Col>
                    <Col span={20} className='rightBody border'>
                        <Form  className="width-100 text-align-left plr25">
                            <Form.Item style={{ margin: '10px 0' }}>
                                {
                                    getFieldDecorator('occupational', {

                                    })(
                                        <Checkbox.Group options={this.state.occupationaldata} />
                                    )
                                }
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                {/* 其他科别 */}
                <Row className="text-align-center GoodAtAdd" style={{ display: 'flex' }}>

                    <Col span={4} className="leftTitle border">
                        <span className="width-100">其他科别</span>

                    </Col>
                    <Col span={20} className='rightBody border'>
                        <Form className="width-100">
                            <Form.Item style={{ margin: '10px 0' }} className="width-100">
                                <Input placeholder="输入其他科别"
                                    style={{ width: '80%', margin: '0 10px' }}
                                    ref='addInput'
                                />
                                <Button type='primary'
                                    onClick={this.elseSubjectAdd}
                                >+</Button>
                                {
                                    getFieldDecorator('elseSubject', {
                                    })(
                                        <Checkbox.Group options={this.state.elseSubjectdata} />
                                    )
                                }
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }
}

const ColoseOrderForm = Form.create()(ColoseOrder);

export default ColoseOrderForm;
