import React, { Component } from 'react'
import { Route, Switch, Redirect} from "react-router-dom";
import Articlemanage from './Article'
import AddNote from './Article/AddNote'
import LookNote from './Article/lookNote'
import Videomanage from './Video'
import Videoadd from './Video/addVideo'
import Videoview from './Video/viewVideo'
import Questionsmanage from './Questions'
import Pointmanage from './Point'
import Categorymanage from './Category'
import CategorymanageEdit from './Category/editWeekPro'
import CategorymanageEditSpecial from './Category/editSpecial'
import CategorymanageSeeSpecial from './Category/seeSpecial'
//对应页面
class dataRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/training/article" exact component={Articlemanage} />
                    <Route path="/training/article/addNote" component={AddNote} />
                    <Route path="/training/article/editNote" component={AddNote} />
                    <Route path="/training/article/lookNote" component={LookNote} />
                    <Route path="/training/video" exact component={Videomanage} />
                    <Route path="/training/video/add" component={Videoadd} />
                    <Route path="/training/video/view" component={Videoview} />
                    <Route path="/training/video/edit" component={Videoadd} />
                    <Route path="/training/questions" component={Questionsmanage} />
                    <Route path="/training/category/edit" component={CategorymanageEdit} />
                    <Route path="/training/category/editTwo" component={CategorymanageEditSpecial} />
                    <Route path="/training/category/see" component={CategorymanageSeeSpecial} />
                    <Route path="/training/category" component={Categorymanage} />
                    <Route path="/training/point" component={Pointmanage} />
                    <Redirect exact from="/training" to="/training/article" />
                </Switch>
            </div>
        )
    }
}

export default dataRouter;