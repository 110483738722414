import { getOSS, uploadOSS,getFile,getFileUrl,upload} from './../../Api'
import React from 'react'
import { Select } from 'antd'
import { message } from 'antd'
const Option = Select.Option;
export default {
    //表格分页-未完成
    pagination(data,pagefn,sizefn,pageSize){
        let page = {
            onChange:(current,size)=>{
                pagefn(current,size)
            },
            current:data.result.page?Number(data.result.page):Number(data.result.nowPageNO),
            total:data.result.records?Number(data.result.records):Number(data.result.total),
            showSizeChanger:true,
            pageSizeOptions:['10','20','30','40'],
            onShowSizeChange:(current, size)=>{
                sizefn(current,size)
            },
            showTotal:()=>{
                return `共${data.result.records?data.result.records:data.result.total}条数据`
            },

            // 页脚跳转
            showQuickJumper:true
        }
        // console.log(page,"page")
        return page
    },
    // 表格数据处理(根据页码自增key，可用于表格自增序号)
    tableListKey(list,page,pageSize){
        return list.forEach((item, index) => {
            item["key"] = (page-1)*pageSize+index + 1
        })
    },
    //文件上传
    upLoad(file){
        return new Promise((resolve,reject)=>{
            //获取OSS信息
            getOSS()
            .then(res=>{
                //定义唯一文件名
                let reName = new Date().getTime() + this.randomStr(20) + '.' + file.name.split('.')[1]
                //组装数据
                const ossUpload = {
                    'Filename':`${reName}`,
                    'key':`${res.data.dir}${reName}`,
                    'policy': res.data.policy,
                    'OSSAccessKeyId': res.data.accessid,
                    'success_action_status' : '200', //让服务端返回200，不设置则默认返回204
                    'signature': res.data.signature,
                    file:file
                }
                if(ossUpload){
                    //fileForm：新的Form对象
                    let fileForm = new FormData()
                    for(let field_name in ossUpload){
                        fileForm.append(field_name,ossUpload[field_name]);
                    }
                    //设置请求头
                    let headers ={
                        'headers':{'Content-Type':'multipart/form-data'}
                    }
                    //上传文件
                    uploadOSS(fileForm,headers).then(res=>{
                        if(res.status === 200){
                            //返回文件路径
                            // message.success('上传成功！')
                            resolve({
                                'url':'http://yanfa-oss-file1.oss-cn-hangzhou.aliyuncs.com/'+ossUpload.key,
                                'name':file.name,
                                'Filename':reName,
                                'contentUrl':ossUpload.key
                            })     
                        }else{
                            // message.warning('上传错误，请重新上传！')
                            reject()
                        }
                    })
                }
            })
        }) 
    },
    //取随机字符串
    randomStr(len=10){
        let str = '';
        let list = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_';
        for (let i = 0; i < len; i++) {
            let word = list.charAt(Math.floor(Math.random() * 63))
            if(word){
            str += word;
            }
        }
        return str;
    },
    //手机号校验
    regPhoneNum(rule, value, callback) {
        var regu = "^1[0-9]{10}$";//手机号码验证regEx:第一位数字必须是1，11位数字
        var re = new RegExp(regu);
        if ( re.test(value)) {
            callback();
        }else {
            callback('请正确输入手机号！');
        }
    },
    //得到单选选项
    getOptionList(data){
        if(!data){
            return [];
        }
        let options = [] //[<Option value="0" key="all_key">全部</Option>];
        data.map((item)=>{
            options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
        })
        return options;
    },
    downLoad(fileId){
        return new Promise((resolve,reject)=>{
        const formdata=new FormData()
        formdata.append('fileId',fileId)
        let url
        getFileUrl(formdata).then(res=>{
                 if(res.data.state.value == 0&&res.status == 200){
                       message.success('下载成功')
                       url=res.data.result
                       resolve(url)
                        }
                        else{
                            message.success('下载失败') 
                            reject()
                        }
                  })
          return url
             })
        },
    //表格分页-直入版
    paginationSimple(data,pagefn,sizefn,pageSize,pageNo,pageNum,total){
        let page = {
            onChange:(current,size)=>{
                pagefn(current,size)
            },
            current:pageNo,
            total:total,
            showSizeChanger:true,
            pageSizeOptions:['10','20','30','40'],
            onShowSizeChange:(current, size)=>{
                sizefn(current,size)
            },
            showTotal:()=>{
                return `共${total}条数据`
            },

            // 页脚跳转
            showQuickJumper:true
        }
        // console.log(page,"page")
        return page
    },
}