import React, { Component } from 'react'
import {BrowserRouter as Router} from "react-router-dom"
import {Icon, Layout} from 'antd'
import Headers from './../Header/Header'
import NavLeft from './../Aside/Left'
const {Header, Content, Footer, Sider} = Layout;

class LayOut extends Component {
    onCollapse = collapsed => {
        this.setState({collapsed});
    };
    state = {
        collapsed: false
    }
    render() {
        return (
            <div>
                <Router>
                    <Layout className="vh100">
                        <Sider
                            collapsible
                            collapsed={this.state.collapsed}
                            onCollapse={this.onCollapse}
                            trigger={
								<div>
									<Icon
                                        className="topiconfixed"
										type={this.state.collapsed
										? 'menu-unfold'
										: 'menu-fold'}
										style={{
										fontSize: 28
									}}>
									</Icon>
								</div>}>
                            <div className="logo">
                                <span>
                                   {this.state.collapsed
                                    ? '后台'
                                    : '运营后台管理系统'}</span>
                            </div>
                            <NavLeft></NavLeft>
                        </Sider>
                        <Layout>
                            <Header className="topfixed">
                                <Headers></Headers>
                            </Header>
                            <Content className="container">
                                {this.props.children}
                            </Content>
                            <Footer
                                style={{
                                textAlign: 'center'
                            }}>
                              <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备16005120号-2</a>
                            </Footer>
                        </Layout>
                    </Layout>
                </Router>
            </div>

        )
    }
}

export default LayOut;