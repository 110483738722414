import React, { Component } from 'react'
import { Route, Switch, Redirect} from "react-router-dom";

//对应页面
import UserListUnit from './UserListUnit'//企业用户列表
import UserUnitBasic from './UserUnitBasic'//企业用户基本信息

import User from './User'//所有用户列表
import UserList2 from './UserList2'//平台用户列表
import UserList3 from './UserList3'//健康顾问用户列表
import UserList4 from './UserList4'//医生用户列表


import UserBasicP from './UserBasicP'//平台用户详情
import UserBasicJ from './UserBasicJ'//健康用户详情
import UserBasicY from './UserBasicY'//医生详情Y

import docEditor from './docEditor'//医生编辑/新增





class UserRouter extends Component {
    render() {
        // const id = this.props.location.search;
        // const _id = id.slice(5)
        // console.log(_id);
        return (
            <div>
                <Switch>
                    {/* 企业 */}
                    <Route path="/user/unit" component={UserListUnit} />
                    {/* path={`${match.url}/detail/:id`} */}
                    <Route path="/user/basic/:id" component={UserUnitBasic} />
                    <Redirect exact from="/user" to="/user/unit" />
                    <Redirect exact from="/user/all" to="/user/all/list2" />
                    {/* 个人列表 */}
                    <Route path="/user/list1"  component={User} />
                    <Route path="/user/all/list2" exact component={UserList2} />
                    <Route path="/user/all/list3" exact component={UserList3} />
                    <Route path="/user/all/list4" exact component={UserList4} />
                    {/* 个人详情 */}
                    <Route path="/user/all/list2/basicp" component={UserBasicP} />
                    <Route path="/user/all/list3/basicp" component={UserBasicP} />
                    <Route path="/user/all/list4/basicp" component={UserBasicP} />
                    <Route path="/user/all/list3/basicj" component={UserBasicJ} />
                    <Route path="/user/all/list4/basicy" component={UserBasicY} />
                    {/* 新增，编辑 */}
                    <Route path="/user/all/list4/editDoceditor" component={docEditor} />
                    <Route path="/user/all/list4/addDoceditor" component={docEditor} />
                </Switch>
            </div>
        )
    }
}

export default UserRouter;