/**
 * @description 试题列表通用表格
 * @author yzx yangzhixin177@163.com
 */
import React from 'react'
import {Table,Form} from 'antd';
/**
 * @description 通用表格（试题列表显示）
 * @class SocialProForm
 * @extends {React.Component}
 */
class SocialProForm extends React.Component{
    render(){
        const columns = [
            {
                title: '序号',
                key:'key',
                dataIndex: 'key',
                width:50,
                render:(text,record,index)=>`${index+1}`
            }, {
                title: '试题类型',
                key: 'questionType',
                dataIndex: 'questionType',
                width:100,
                render(text) {
                  if(text==1){
                      return '单选题'
                  }
                  if(text==2){
                      return '多选题'
                  }
                  else{
                      return '填空题'
                  }
              },
            },{
                title: '题干',
                key: 'stem',
                width:300,
                dataIndex: 'stem',
            },{
                title: '答案',
                key: 'result',
                width:300,
                dataIndex: 'result',
                render(text,record) {
                  if(record.questionType==1){
                      return <div>
                      <div className={record.result=='A'?'show':'none'}>{record.optionA}</div>
                      <div className={record.result=='B'?'show':'none'}>{record.optionB}</div>
                      <div className={record.result=='C'?'show':'none'}>{record.optionC}</div>
                      <div className={record.result=='D'?'show':'none'}>{record.optionD}</div>
                      </div>
                  }
                  if(record.questionType==2){
                      let showA,showB,showC,showD
                      for(var i=0;i<record.result.length;i++){
                          if(record.result[i]=='A'){
                              showA=true
                          }
                          if(record.result[i]=='B'){
                              showB=true
                          }
                          if(record.result[i]=='C'){
                              showC=true
                          }
                          if(record.result[i]=='D'){
                              showD=true
                          }
                      }
                      return <div>
                      <div className={showA==true?'show':'none'}>{record.optionA}</div>
                      <div className={showB==true?'show':'none'}>{record.optionB}</div>
                      <div className={showC==true?'show':'none'}>{record.optionC}</div>
                      <div className={showD==true?'show':'none'}>{record.optionD}</div>
                      </div>
                  }
                  else if(record.questionType==3){
                      return <span>{record.fillResult}</span>
                  }
              },
            }
        ]; 
        return (
            <Form>
            <Table
                bordered
                columns={columns}
                dataSource={this.props.problemData}
                rowSelection={this.props.rowCheckSelection()}
                rowKey={(record,index)=> index}
                scroll={{ x: 900, y: 450 }}
                pagination={ false }
             /> 
            </Form>  
        );
    }
  }
  SocialProForm = Form.create({})(SocialProForm);
  export default SocialProForm