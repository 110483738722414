/*
 * @file: 新增编辑文章和法规的组件
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import { Icon, Form, Input, Button, Select, message, Modal, Upload } from 'antd'
import BraftEditor from 'braft-editor'
import { artivleAdd, artivleEdit, artivleView, getFile } from '../../../Api'
const FormItem = Form.Item
const { Option } = Select
/**
 * @classdesc 新增编辑文章和法规的父组件
*/
class AddNote extends Component {
    constructor(props) {
        super(props);
        /**
         * @description: state自定义
         * @param {object} content 具体内容
         */
        this.state = {
            content: props.location.state ? props.location.state.data : null
        };
    }
    componentWillMount() {
        if (this.state.content !== 'add') {
            this.lookAlldata()
        }
    }
    /**
     * 编辑时请求数据
     * @function lookAlldata
     */
    lookAlldata = () => {
        let _this = this
        artivleView(this.state.content.id).then(res => {
            if (res.data.state.value === 0) {
                let data = res.data.result
                getFile({ fileId: data.screenshot }).then(res => {
                    if (res.status === 200 && res.statusText === "OK") {
                        data.textImage = res.data.result
                        _this.setState({
                            data,
                            faData: data.law
                        })
                    }
                })
            }
        })
    }
    /**
     * 富文本编辑器图片上传
     * @param {object} param 
     * @function myUploadFn
     */
    myUploadFn = (param) => {
        const serverURL = '/v1/ajax/file/upload'
        const xhr = new XMLHttpRequest()
        const fd = new FormData()
        const successFn = (response) => {
            var obj = JSON.parse(xhr.responseText);
            param.success({
                url: obj.result.fullOssurl,
            })
        }
        const errorFn = (response) => {
            param.error({
                msg: 'unable to upload.'
            })
        }
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.send(fd)
    }
    /**
     * 返回列表
     * @function back
     */
    back = () => {
        this.props.history.push({ pathname: `/training/article` })
    }
    /**
     * 保存上传到后端
     * @param {object} item 接收的数据
     * @function editOrAddText
     */
    editOrAddText = (item) => {
        let _this = this
        if (this.state.content !== 'add') {
            // 编辑
            item.id = this.state.content.id
            artivleEdit(item).then(res => {
                if (res.data.state.name === '操作成功' && res.data.state.value === 0) {
                    message.success('操作成功')
                    _this.back()
                }
            })
        } else {
            //新增
            artivleAdd(item).then(res => {
                if (res.data.state.name === '操作成功' && res.data.state.value === 0) {
                    message.success('操作成功')
                    _this.back()
                }
            })
        }
    }
    render() {
        const data = this.state.data
        return (
            <div className="home">

                {
                    (() => {
                        if (this.state.content == 'add') {
                            return (
                                <EditTextForm content={this.state.data} back={this.back} upItem={this.editOrAddText.bind(this)} ></EditTextForm>
                            )
                        } else {
                            return (
                                data ? data.lawId ?
                                    // 法规编辑
                                    <EditFaForm content={this.state.data} back={this.back} upItem={this.editOrAddText.bind(this)} faData={this.state.faData}></EditFaForm>
                                    :
                                    // 文章编辑，新增
                                    <EditTextForm content={this.state.data} back={this.back} upItem={this.editOrAddText.bind(this)} ></EditTextForm>
                                    : null
                            )
                        }
                    })()
                }
            </div>
        )
    }
}
export default AddNote;

/**
 * @classdesc 文章的新增和编辑
 */
class EditText extends Component {
    constructor(props) {
        super(props)
        /**
         * @description: state自定义
         * @param {object} loading 等待标志
         * @param {object} textImage 上传的图片
         * @param {boolean} errbol 错误提示判断值
         * @param {boolean} errbol2 错误提示判断值
         * @param {object} controls 富文本内容
         */
        this.state = {
            loading: null,
            textImage: props.content ? props.content.textImage : null,
            errbol: false,
            errbol2: false,
            controls: BraftEditor.defaultProps.controls
        }
    }
    componentDidMount() {
        if (this.props.content) {
            // 异步设置编辑器内容
            setTimeout(() => {
                this.props.form.setFieldsValue({
                    content: BraftEditor.createEditorState(this.props.content.content)
                })
            }, 1000)
        }

    }
    /**
* 富文本编辑器图片上传
* @param {object} param 
* @function myUploadFn
*/
    myUploadFn = (param) => {
        const serverURL = '/v1/ajax/file/upload'
        const xhr = new XMLHttpRequest()
        const fd = new FormData()
        const successFn = (response) => {
            var obj = JSON.parse(xhr.responseText);
            param.success({
                url: obj.result.fullOssurl,
            })
        }
        const errorFn = (response) => {
            param.error({
                msg: 'unable to upload.'
            })
        }
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.send(fd)
    }
    /**
     * @name: 保存上传给父组件数据
     * @param {boolean} bol  上传-true  保存草稿-false
     * @function handUp
     */
    handUp = (bol) => {
        let _this = this
        let value = this.props.form.getFieldsValue()
        value.content = value.content ? value.content.toHTML() !== '<p></p>' ? value.content.toHTML() : '' : ''
        if (this.state.textImage) {
            value.screenshot = this.state.textImage.id
        }
        console.log(value)

        if (!bol) {
            value.status = '1'
            _this.props.upItem(value)
        } else {
            this.props.form.validateFields().then(() => {
                if (!value.content || !value.screenshot) {
                    this.setState({
                        errbol: !value.content ? true : false,
                        errbol2: !value.screenshot ? true : false
                    })
                    message.error('请将带 * 号的项目填写完整');
                    return ;

                }
                value.status = '2'
                Modal.confirm({
                    title: '提示',
                    content: '确认要上架吗？',
                    width: 300,
                    onOk() {
                        _this.props.upItem(value)
                    },
                });
            }).catch(() => {
                message.error('请将带 * 号的项目填写完整');
                if (!value.content) {
                    this.setState({
                        errbol: true
                    })
                }
                if (!value.screenshot) {
                    this.setState({
                        errbol2: true
                    })
                }
            })
        }
    }

    /**
     * @description: 编辑文章缩略图
     * @param {object} info 图片信息 
     * @function: handleChange
     */
    handleChange = info => {
        let _this = this
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.getBase64(info.file.originFileObj, () => {
                _this.setState({
                    textImage: info.file.response.result,
                    loading: false,
                    errbol2:false
                })
            });
        }
    };
    /**
     * @description: 图片上传
     * @function: getBase64
     */
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader));
        reader.readAsDataURL(img);
    }
    /**
        * 文件上传后的回调事件
        * @function normFile
        * @param {Event} e - 提交事件
        * @return {Array} - 处理后的已上传文件列表
        */
    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    /**
     * @description: 富文本改变回调
     * @param {object} e 富文本数据
     */
    handerrbol = (e) => {
        if (e.toHTML() && e.toHTML() !== '<p></p>' && this.state.errbol) {
            this.setState({
                errbol: false
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const data = this.props.content
        const textImageButton = (
            <div className="buttonPic">
                {!this.state.loading ? <span className="f12">暂未上传</span> : <Icon type='loading' />}
            </div>
        );
        return (
            <div>
                <table
                    className='text-align-center width-100 article-addNote mb10'
                >
                    <tbody>
                        <tr>
                            <td>
                                <span style={{ color: 'red' }} className='mr5'>*</span>
                                <span>文章名称</span>
                            </td>
                            <td>
                                <FormItem style={{ margin: 0 }}>

                                    {
                                        getFieldDecorator('name', {
                                            initialValue: data ? data.name + '' : '',
                                            rules: [
                                                { required: true, message: '不能为空' },
                                            ],
                                        })(
                                            <Input className='fl' />
                                        )
                                    }
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: 'red' }} className='mr5'>*</span>
                                <span>类别</span>
                            </td>
                            <td>
                                <FormItem style={{ margin: 0 }}>
                                    {
                                        getFieldDecorator('type', {
                                            initialValue: data ? data.type + '' : '',
                                        })(
                                            <Select style={{ width: '300px' }} className='fl'>
                                                <Option value='1'>职业卫生综合</Option>
                                                <Option value='2'>职业危害防护</Option>
                                                <Option value='3'>职业健康监护</Option>
                                                <Option value='4'>职业危害因素</Option>
                                                <Option value='5'>职业卫生三同时</Option>
                                                <Option value='6'>职业病</Option>
                                                <Option value='7'>放射卫生</Option>
                                            </Select>
                                        )
                                    }
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>来源</span>
                            </td>
                            <td>
                                {
                                    getFieldDecorator('source', {
                                        initialValue: data ? data.source : null,
                                    })(
                                        <Input style={{ width: '300px' }} className='fl' />
                                    )
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: 'red' }} className='mr5'>*</span>
                                <span>文章内容</span>
                                <div style={{ fontSize: '10px', marginTop: '10px' }}>
                                    <p>
                                        标题：字号24，字体颜色#003ba5，行高1；
                                    </p>
                                    <p>
                                        来源：字号14，字体颜色#003ba5，行高2；
                                    </p>
                                    <p>
                                        内容：字号16，字体颜色#333333，行高2；
                                    </p>
                                    <p>两端对齐</p>
                                </div>
                            </td>
                            <td className='family-inherit-text'>
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator('content', {

                                    })(
                                        <BraftEditor
                                            className="my-editor"
                                            style={{ border: '1px solid #e8e8e8' }}
                                            placeholder="请输入正文内容"
                                            media={{
                                                uploadFn: this.myUploadFn,

                                            }}
                                            onChange={this.handerrbol}
                                            imageControls={[]}
                                            excludeControls={'emoji'}
                                            maxLength={20000}
                                            imageResizable={false}
                                            controls={
                                                BraftEditor.defaultProps.controls.map(item => {
                                                    return item === 'media' ? {
                                                        key: 'media', // 使用key来指定控件类型
                                                        title: '上传图片', // 自定义控件title
                                                        text: <Icon type="picture" theme="filled" />, // 使用自定义文案来代替默认图标(B)，此处也可传入jsx
                                                    } : item
                                                })
                                            }
                                        />
                                    )}
                                    {
                                        this.state.errbol ?
                                            <span style={{ color: 'red', textAlign: 'center' }}>此为上架必填项</span>
                                            : null
                                    }
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: 'red' }} className='mr5'>*</span>
                                <span>文章缩略图</span>
                            </td>
                            <td style={{ padding: '8px 0 0 8px' }} className='Article-FromItem-Image'>
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator('screenshot')(
                                        <Upload
                                            name='file'
                                            listType="picture-card"
                                            showUploadList={false}
                                            action='/v1/ajax/file/upload'
                                            onChange={this.handleChange}
                                            style={{ margin: 0 }}
                                        >
                                            {
                                                this.state.textImage ?
                                                    <img src={this.state.textImage.fullOssurl} style={{ height: 90 }} alt="" />
                                                    : textImageButton
                                            }
                                        </Upload>
                                    )}
                                    {
                                        this.state.errbol2 ?
                                            <span style={{ color: 'red', textAlign: 'center',display:'block' }}>此为上架必填项</span>
                                            : null
                                    }
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: 'red' }} className='mr5'>*</span>
                                <span>文章摘要</span>
                            </td>
                            <td>
                                <FormItem style={{ margin: 0 }}>
                                    {
                                        getFieldDecorator('summary', {
                                            initialValue: data ? data.summary : null,
                                            rules: [
                                                { required: true, message: '此为上架必填项' },
                                            ],
                                        })(
                                            <Input.TextArea maxLength={120} style={{ height: '70px' }} className='fl' placeholder='限120字' />
                                        )
                                    }
                                </FormItem>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='width-100 text-align-center mt10'>
                    {
                        !data || data.status == '1' ?
                            <Button type='primary' className='margin10' onClick={() => this.handUp(false)}>保 存</Button>
                            : null
                    }
                    <Button type='primary' className='margin10' onClick={() => this.handUp(true)}>上 架</Button>
                    <Button type='primary' className='margin10' onClick={this.props.back} >取 消</Button>
                </div>
            </div >
        )
    }
}
const EditTextForm = Form.create()(EditText);

/**
 * @classdesc 法规的编辑
 */
class EditFa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPDFShow: false,
            textImage: props.content.textImage
        }

    }
    /**
     * @name: 保存上传给父组件数据
     * @param {boolean} bol  上传-true  保存草稿-false
     * @function handUp
     */
    handUp = (bol) => {
        let _this = this
        let value = this.props.form.getFieldsValue()

        if (this.state.textImage) {
            value.screenshot = this.state.textImage.id
        }
        if (!bol) {
            value.status = '1'
            _this.props.upItem(value)
        } else {
            this.props.form.validateFields().then(() => {
                if ( !value.screenshot) {
                    this.setState({
                        errbol2: !value.screenshot ? true : false
                    })
                    message.error('请将带 * 号的项目填写完整');
                    return ;
    
                }
                value.status = '2'
                Modal.confirm({
                    title: '提示',
                    content: '确认要上架吗？',
                    width: 300,
                    onOk() {
                        _this.props.upItem(value)
                    },
                });
            }).catch(() => {
                message.error('请将带 * 号的项目填写完整');
                if (!value.screenshot) {
                    this.setState({
                        errbol2: true
                    })
                }
            })
        }
    
    }
    /**
     * 查看pdf文件
     * @param {string} url 
     * @function lookPdf
     */
    lookPdf = (url) => {
        let _this = this
        if (url) {
            let urlList = url.split('.')
            if (urlList[urlList.length - 1] == 'pdf') {
                _this.setState({
                    isPDFShow: true,
                    PDFUrl: url
                })
            } else {
                _this.setState({
                    isPDFShow: true,
                    PDFUrl: "https://view.officeapps.live.com/op/view.aspx?src=" + url
                })
            }
        } else {
            message.warning('暂无相关PDF可查看')
        }
    }
    /**
     * @description: 编辑文章缩略图
     * @param {object} info 图片信息 
     * @function: handleChange
     */
    handleChange = info => {
        let _this = this
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.getBase64(info.file.originFileObj, () => {
                _this.setState({
                    textImage: info.file.response.result,
                    loading: false,
                    errbol2:false
                })
            });
        }
    };
    /**
     * @description: 图片上传
     * @function: getBase64
     */
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader));
        reader.readAsDataURL(img);
    }
    /**
    * 文件上传后的回调事件
    * @function normFile
    * @param {Event} e - 提交事件
    * @return {Array} - 处理后的已上传文件列表
    */
    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const data = this.props.content
        const faData = this.props.faData ? this.props.faData : []
        const textImageButton = (
            <div className="buttonPic">
                {!this.state.loading ? <span className="f12">暂未上传</span> : <Icon type='loading' />}
            </div>
        );
        return (
            <div>
                <table
                    className='text-align-center width-100 article-addNote mb10'
                >
                    <tbody>
                        <tr>
                            <td>
                                <span>法规标准名称</span>
                            </td>
                            <td style={{ width: '30%', paddingLeft: '10px' }} className="text-align-left pl10">
                                <span>{faData.lawName}</span>
                            </td>
                            <td>
                                <span>文号</span>
                            </td>
                            <td className="text-align-left" style={{ paddingLeft: '10px' }}>
                                <span>{faData.lawNo}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>状态</span>
                            </td>
                            <td className="text-align-left" style={{ paddingLeft: '10px' }}>
                                <span>{faData.status}</span>
                            </td>
                            <td>
                                <span>法律效力</span>
                            </td>
                            <td className="text-align-left" style={{ paddingLeft: '10px' }}>
                                <span>{faData.lawClassName}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>法规标准附件</span>
                            </td>
                            <td colSpan={3} className="text-align-left" style={{ paddingLeft: '10px' }}>
                                <a onClick={() => this.lookPdf(faData.ossFullUrl)}>{faData.lawName + '.pdf'}</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: 'red' }} className='mr5'>*</span>
                                <span>类别</span>
                            </td>
                            <td colSpan={3}>
                                <FormItem style={{ margin: 0 }}>
                                    {
                                        getFieldDecorator('type', {
                                            initialValue: this.props.content ? this.props.content.type + '' : '',
                                            rules: [
                                                { required: true, message: '此为上架必填项' },
                                            ],
                                        })(
                                            <Select style={{ width: '300px' }} className='fl'>
                                                <Option value='1'>职业卫生综合</Option>
                                                <Option value='2'>职业危害防护</Option>
                                                <Option value='3'>职业健康监护</Option>
                                                <Option value='4'>职业危害因素</Option>
                                                <Option value='5'>职业卫生三同时</Option>
                                                <Option value='6'>职业病</Option>
                                                <Option value='7'>放射卫生</Option>
                                            </Select>
                                        )
                                    }
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>来源</span>
                            </td>
                            <td colSpan={3}>
                                {
                                    getFieldDecorator('source', {
                                        initialValue: this.props.content ? this.props.content.source : null,
                                    })(
                                        <Input style={{ width: '300px' }} className='fl' />
                                    )
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: 'red' }} className='mr5'>*</span>
                                <span>文章缩略图</span>
                            </td>
                            <td colSpan={3} className='Article-FromItem-Image'>
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator('screenshot')(
                                        <Upload
                                            name='file'
                                            listType="picture-card"
                                            showUploadList={false}
                                            action='/v1/ajax/file/upload'
                                            onChange={this.handleChange}
                                        >
                                            {
                                                this.state.textImage ?
                                                    <img src={this.state.textImage.fullOssurl} style={{ width: 300, height: 170 }} alt="" />
                                                    : textImageButton
                                            }
                                        </Upload>
                                    )}
                                    {
                                        this.state.errbol2 ?
                                            <span style={{ color: 'red', textAlign: 'center',display:'block' }}>此为上架必填项</span>
                                            : null
                                    }
                                </FormItem>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: 'red' }} className='mr5'>*</span>
                                <span>文章摘要</span>
                            </td>
                            <td colSpan={3}>
                                <FormItem style={{ margin: 0 }}>
                                    {
                                        getFieldDecorator('summary', {
                                            initialValue: data ? data.summary : null,
                                            rules: [
                                                { required: true, message: '此为上架必填项' },
                                            ],
                                        })(
                                            <Input.TextArea maxLength={120} style={{ height: '70px' }} className='fl' placeholder='限120字' />
                                        )
                                    }
                                </FormItem>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='width-100 text-align-center mt10'>
                    {
                        !data || data.status == '1' ?
                            <Button type='primary' className='margin10' onClick={() => this.handUp(false)}>保 存</Button>
                            : null
                    }

                    <Button type='primary' className='margin10' onClick={() => this.handUp(true)}>上 架</Button>
                    <Button type='primary' className='margin10' onClick={this.props.back} >取 消</Button>
                </div>
                <Modal
                    visible={this.state.isPDFShow}
                    title="预览文件"
                    footer={null}
                    onCancel={() => {
                        this.setState({
                            isPDFShow: false
                        })
                    }}
                    centered
                    width={800}
                >
                    <iframe width={750} height={600} src={this.state.PDFUrl} title="PDFperview"></iframe>
                </Modal>
            </div>
        )
    }
}
const EditFaForm = Form.create()(EditFa);

