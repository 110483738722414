/**
 * @description 专项答题查看
 * @author yzx yangzhixin177@163.com
 */
import React, { Component } from 'react'
import { Tabs, Radio,Card,Icon,Table,Modal,Form,message,Button} from 'antd';
import {queryExamQuestionList,queryExam} from '../../../Api'
import './../../../Common/css/path.css'
/**
 * @description 组件-查看专项答题试卷
 * @class CategorymanageSee
 * @extends {Component}
 */
class CategorymanageSee extends Component {

    /**
     * @description 组件内数据
     * @param {Object} data 每周答题/专项答题题目列表
     * @memberof CategorymanageSee
     */
    state={
        data:[],
        detail:[]
    }
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof CategorymanageEdit
     */
    renderModalSimple = (type,title,content) =>{
      return Modal[type?type:'info']({ 
          title:title?title:'警告',
          content:content?content:''
      })
  }
    /**
     * @description react周期函数，请求试题列表接口
     * @function componentDidMount
     * @memberof CategorymanageEdit
     */
    componentDidMount() {
        const id = this.props.location.pathname ? this.props.location.pathname.split('/').reverse()[0] : 'edit'
        // console.log(this.props.location.state.name)
          if (id !== 'edit') {
            queryExamQuestionList({examId:id}).then((res)=>{
              // console.log(res.data)
              this.setState({
                  data:res.data.result,
                  transId:id
              })
          })
          }
      }
      componentWillMount(){
        const id = this.props.location.pathname ? this.props.location.pathname.split('/').reverse()[0] : 'edit'
        // console.log(id)
        // console.log(this.props.location.state.name)
          if (id !== 'edit') {
            queryExam({examId:id}).then((res)=>{
              // console.log(res.data.result.name)
              this.setState({
                  detail:res.data.result,
                  name:res.data.result.name
              })
          })
          }
      }
      /**
       * @description 日期转换
       * @function formatDate
       * @memberof CategorymanageEdit
       * @param {string} now 时间戳
       * @return {string} year + "-" + month + "-" + date 格式转化后的时间
       */
      formatDate = (now) => {
        var nowDate = new Date(now)
        var year = nowDate.getFullYear();  //取得4位数的年份
        var month = nowDate.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
        var date = nowDate.getDate();      //返回日期月份中的天数（1到31）
        return year + "-" + month + "-" + date;
    }
      render() {
          let _this=this
          let time=_this.state.detail.shelvesTime?_this.state.detail.shelvesTime:''
        const columns = [
            {
                title: '序号',
                key:'key',
                dataIndex: 'key',
                render:(text,record,index)=>`${index+1}`
            }, {
                title: '试题类型',
                key: 'questionType',
                dataIndex: 'questionType',
                render(text) {
                    if(text==1){
                        return '单选题'
                    }
                    if(text==2){
                        return '多选题'
                    }
                    else{
                        return '填空题'
                    }
                },
            },{
                title: '题干',
                key: 'stem',
                dataIndex: 'stem',
            },{
                title: '答案',
                key: 'result',
                dataIndex: 'result',
                render(text,record) {
                    if(record.questionType==1){
                        return <div>
                        <div className={record.result=='A'?'show':'none'}>{record.optionA}</div>
                        <div className={record.result=='B'?'show':'none'}>{record.optionB}</div>
                        <div className={record.result=='C'?'show':'none'}>{record.optionC}</div>
                        <div className={record.result=='D'?'show':'none'}>{record.optionD}</div>
                        </div>
                    }
                    if(record.questionType==2){
                        let showA,showB,showC,showD
                        for(var i=0;i<record.result.length;i++){
                            if(record.result[i]=='A'){
                                showA=true
                            }
                            if(record.result[i]=='B'){
                                showB=true
                            }
                            if(record.result[i]=='C'){
                                showC=true
                            }
                            if(record.result[i]=='D'){
                                showD=true
                            }
                        }
                        return <div>
                        <div className={showA==true?'show':'none'}>{record.optionA}</div>
                        <div className={showB==true?'show':'none'}>{record.optionB}</div>
                        <div className={showC==true?'show':'none'}>{record.optionC}</div>
                        <div className={showD==true?'show':'none'}>{record.optionD}</div>
                        </div>
                    }
                    else if(record.questionType==3){
                        return <span>{record.fillResult}</span>
                    }
                },
            }
        ]; 
        return (
          <Card>
                <div>
                    
                <div className='weekTitle1'><span style={{color:'red'}}>*</span>专项名称</div>
                <div className='weekTitle2'><span></span>{this.props.location.state.name}</div>
                <table className='seeTable'>
                 <tbody>
                    <tr>
                        <td>编制人</td>
                        <td style={{textAlign:"left"}}>{_this.state.detail.createByStr}</td>
                        <td>创建时间</td>
                        <td style={{textAlign:"left"}}>{_this.formatDate(_this.state.detail.createDate)}</td>
                    </tr>
                    <tr>
                        <td>答题人数</td>
                        <td style={{textAlign:"left"}}>{_this.state.detail.totalUser}</td>
                        <td>上架时间</td>
                        <td style={{textAlign:"left"}}>{time==''?'':_this.formatDate(time)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
                <Table className='move'
                        bordered
                        columns={columns}
                        dataSource={this.state.data}
                        rowKey={(record,index)=> index}
                        pagination={false}
                    /> 
            
          </Card>
        );
      }
} 
export default CategorymanageSee