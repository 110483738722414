import React, { Component } from 'react'
// import {connect} from 'react-redux'
import {Table,Icon,Modal,message} from 'antd'
// import {getCorpAdminListDataAction} from './../../../../Store/actionCreators'
import {getCorpAdminListData,getCorpAdminForbidden,getCorpAddCorpAdmin,getCorpEditCorpAdmin} from './../../../../Api/index'
import utils from './../../../../Common/js/utils'

import AddAccount from './../Common/AddAccount'//添加账号
import EditAccount from './../Common/EditAccount'//编辑账号

class AccountManage extends Component {
    //弹出初始状态
    state = {
        cid:this.props.record,//获取页面cid
        loading: false,
        addVisible:false, //新增账号
        editVisible:false, //编辑账号
        disableVisible:false, //禁用账号
        pagination:11,
        cropadmin:[],
        pageRequest:{
            page:1,
            pageSize:10
        },
    };

    componentWillMount() {
        this.requestTableList()
    }
    //列表数据接口请求
    requestTableList = () => {
        //console.log(this.state.cid)
        getCorpAdminListData(this.state.cid).then((res)=>{
            //console.log(res)
            if(res.status === 200){
                utils.tableListKey(res.data.result,this.state.pageRequest.page,this.state.pageRequest.pageSize);
                this.setState({
                    cropadmin:res.data.result,
                })
            }else{
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    ///////////////////////////////////// 新增账号
    //新增账号 弹出内容 显示状态
    addshowModal = () => {
        this.setState({
            addVisible: true
        });
    };
    //新增账号 弹出框 确定按钮 操作
    addAccountOk = () => {
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        //this.setState({ loading: false, addVisible: false });
        let formdata = this.formRef.getItemsValue();
        if(
            formdata.name !== '' && formdata.name !== undefined && 
            formdata.username !== '' && formdata.username !== undefined
        ){
            this.setState({ loading: false, addVisible: false });
            this.addCorpAdminRequest(formdata);
            this.formRef.resetValue(); 
        }else{
            this.setState({ loading: true, addVisible: true });
        }
        // this.addCorpAdminRequest(formdata);
        // this.formRef.resetValue(); 
    };
    //新增账号 弹出框 取消按钮 操作
    addAccountCancel = () => {
        this.setState({ addVisible: false });
        this.formRef.resetValue(); 
    };
    //新增账号 请求接口
    addCorpAdminRequest = (formdata) => {
        // console.log(formdata);
        // console.log("55555555555555555555555555");
        // console.log(this.state.cid);
        getCorpAddCorpAdmin({
            cid:this.state.cid,
            name:formdata.name,
            username:formdata.username,
            mobile:formdata.mobile
        }).then((res)=>{
            //console.log(res);
            if(res.status === 200){
                if(res.data.state.value == 0){
                    this.requestTableList();
                    this.formRef.resetValue();
                }else if(res.data.state.value == 1){
                    message.error(res.data.content);
                    this.formRef.resetValue();
                }else if(res.data.state.value == 2){
                    message.warning();
                    this.formRef.resetValue();
                }
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    ///////////////////////////////////// 编辑账号
    //编辑账号 弹出内容 显示状态
    editshowModal = (record) => {
        this.setState({
            editVisible: true,
            record:record,
        });
    };
    //编辑账号 弹出框 确定按钮 操作
    editAccountOk = () => {
        //console.log(this.formRef.getItemsValue());//通过formRef属性可以获取getItemsValue对象
        let formdata = this.formRef.getItemsValue();
        if(
            formdata.name !== '' && formdata.name !== undefined && 
            formdata.username !== '' && formdata.username !== undefined
        ){
            this.setState({ loading: false, editVisible: false });
            this.EditCorpAdminRequest(formdata);
        }else{
            this.setState({ loading: true, editVisible: true });
        }
        // this.setState({ loading: false, editVisible: false });
        //this.EditCorpAdminRequest(formdata);
    };
    //编辑账号 弹出框 取消按钮 操作
    editAccountCancel = () => {
        this.setState({ editVisible: false });
        this.formRef.resetValue(); 
    };
    //编辑账号 请求接口
    EditCorpAdminRequest = (formdata) => {
        // console.log(formdata);
        // console.log("ffffffffffffffff");
        // console.log(this.state.cid);
        // console.log(this.state.record.id);
        getCorpEditCorpAdmin({
            cid:this.state.cid,
            id:this.state.record.id,
            name:formdata.name,
            username:formdata.username,
            mobile:formdata.mobile
        }).then((res)=>{
            //console.log(res);
            if(res.status === 200){
                //this.requestTableList();
                if(res.data.state.value == 0){
                    this.requestTableList();
                    this.formRef.resetValue();
                }else if(res.data.state.value == 1){
                    message.error(res.data.content);
                    this.formRef.resetValue();
                }else if(res.data.state.value == 2){
                    message.warning();
                    this.formRef.resetValue();
                }
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    ///////////////////////////////////// 禁用账号
    //禁用账号 弹出内容 显示状态
    disableshowModal = (record,isForbidden) => {
        this.setState({
            disableVisible: true,
            id:record,
            isForbidden:isForbidden
        });
    };
    //禁用账号 弹出框 确定按钮 操作
    disableOk = () => {
        this.setState({ loading: false, disableVisible: false });
        //console.log(this.state.id,this.state.isForbidden);
        this.requestIsForbidden(this.state.id,this.state.isForbidden);
    };
    //禁用账号 弹出框 取消按钮 操作
    disableCancel = () => {
        this.setState({ disableVisible: false });
    };

    ///////////////////////////////////// 启用账号
    //启用账号 弹出内容 显示状态
    ableshowModal = (record,isForbidden) => {
        this.requestIsForbidden(record,isForbidden);
    }
    //启用账号/禁用账号 请求接口
    requestIsForbidden = (record,isForbidden) => {
        getCorpAdminForbidden({id:record,isForbidden:isForbidden}).then((res)=>{
            //console.log(res);
            if(res.status === 200){
                this.requestTableList()
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    render() {
        //表格 表头
        const columns = [
            {title: '姓名',dataIndex: 'name',align:'center'},
            {title: '账号',dataIndex: 'username',align:'center'},
            {title: '手机号',dataIndex: 'mobile',align:'center'},
            {title: '创建时间',dataIndex: 'createDate',align:'center'},
            {title: '操作',dataIndex: 'operation',align:'center',
                render: (text,record) => {
                    if(record.isForbidden === true) {
                        return (
                            <div>
                                <a className="green mlr5" onClick={() => this.ableshowModal(record.id,false)}>启用</a>
                                <a className="blue mlr5" onClick={() => this.editshowModal(record)}>编辑</a>
                            </div>
                        )
                    }else if(record.isForbidden === false){
                        return (
                            <div>
                                <a className="red mlr5" onClick={() => this.disableshowModal(record.id,true)}>禁用</a>
                                <a className="blue mlr5" onClick={() => this.editshowModal(record)}>编辑</a>
                            </div>
                        )
                    }
                }
            }
        ];
        //表格底部操作按钮
        const footer = () => {
            return (
                <div>
                    <a onClick={() => this.addshowModal()}>
                        <Icon type="plus-circle" theme="filled" title="新增" className="f20"></Icon>
                    </a>
                </div>
            )
        };
        return (
            <div>
                <Table bordered columns={columns} dataSource={this.state.cropadmin} footer={footer} pagination={false} />
                {/* <Pagination {...this.state.pagination}
                    className="fr mt20"
                /> */}
                
                {/* 新增账号 */}
                <Modal
                width={620}
                visible={this.state.addVisible}
                loading={this.state.loading}
                title="新增登陆账号"
                onOk={this.addAccountOk}
                onCancel={this.addAccountCancel}
                okText="创建账号"
                cancelText="取消"
                >
                    <AddAccount
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 编辑账号 */}
                <Modal
                width={620}
                visible={this.state.editVisible}
                loading={this.state.loading}
                title="编辑登陆账号"
                onOk={this.editAccountOk}
                onCancel={this.editAccountCancel}
                okText="保存"
                cancelText="取消"
                >
                    <EditAccount record={this.state.record}
                    wrappedComponentRef={(form) => this.formRef = form}//使用wrappedComponentRef 拿到子组件传递过来的ref
                    />
                </Modal>

                {/* 禁用账号 */}
                <Modal
                width={360}
                visible={this.state.disableVisible}
                loading={this.state.loading}
                title="禁用账号"
                onOk={this.disableOk}
                onCancel={this.disableCancel}
                okText="是"
                cancelText="否"
                >
                    禁用后该账号将不能登录平台，是否确认禁用？
                </Modal>
            </div>
        )
    }
}

export default AccountManage;

// const mapStateToProps = (state)=>{
//     return {
//         cropadmin: state.cropadmin
//     }
// };

// const mapDispatchToProps = (dispatch)=>{
//     return {
//        reqStudentList(){
//            const action = getCorpAdminListDataAction();
//            dispatch(action)
//        }
//     }
// };

// export default connect(mapStateToProps, mapDispatchToProps)(AccountManage);
