import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {getArticalShare} from './../../../Api'
import {
    Row,
    Col
} from 'antd'
import './index.css'
import moment from 'moment'
/**
 * @description 视频列表
 * @class Video
 * @extends {Component}
 * */
class Video extends Component {
    state = {
        content:"",
            type:"",
            name:"",
            date:"",
            isPdf:false,
        block: false,
    }
    componentWillMount() {
        let id = this.props.match.params.id
        let title = "文章详情"
        getArticalShare(id).then(res => {
            if (res.data.result) {
                if (res.data.result.content&&(res.data.result.content.indexOf("oss-cn-hangzhou.aliyuncs.com") < 0)) {
                    this.setState({isPdf: false});
                } else {
                    //pdf地址-用iframe显示
                    this.setState({isPdf: true});
                }
                if(res.data.result.content&&res.data.result.content){
                    res.data.result.content =res.data.result.content.replace('<img ','<img style="width:100%"')
                }
                this.setState({
                    block: true,
                    name: res.data.result.name,
                    date: moment(res.data.result.updateDate).format("YYYY-MM-DD HH:MM:SS"),
                    content: res.data.result.content
                });
                title = res.data.result.name
            }
            document.title = title
            var i = document.createElement('iframe');
            i.src = '/favicon.ico';
            i.style.display = 'none';
            i.onload = function() {
              setTimeout(function(){
                i.remove();
              }, 20)
            }
            document.body.appendChild(i);
        })
    }

    render() {
        let {
            content,
            name,
            block,
            isPdf
        } = this.state
        return (
            <div className="artical-share">
                {block
                    ? <div>
                            <Row type="flex" justify="start">
                                <Col span={24}>
                                {isPdf
                                ?<iframe
                                            title={name}
                                            src={content}
                                            width="100%"
                                            height="500"></iframe>
                                :<div dangerouslySetInnerHTML = {{ __html: content}}></div>
                                }
                                    
                                </Col>
                            </Row>
                        </div>
                    : <div className="miss-block">
                        请 稍 后 再 试 ...
                    </div>
            }
            </div>
        )
    }
}
export default withRouter(Video)