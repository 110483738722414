/**
 * @file 页面：业务员管理
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import { Col, Row, Table, Icon, Tree } from 'antd'
import { getAgentBusinessList, getAgentBusinessListData, addAgentBusiness, delAgentBusiness, saveAgentBusiness } from '../../Api'
import { statusMessage, pagination } from '../Training/Questions/features'
import utils from '../../Common/js/utils'
import TextModal from './components/TextModal'
import Edit from './components/EditForm'
import Increase from './components/AddForm'

const { TreeNode } = Tree

/**
 * @class
 * @extends React.Component
 */
class SalesmanList extends React.Component {
    /**
     * 树的子元素
     * @typedef {Object} treeChild
     * @property {String} title 树的子元素的标题
     * @property {String} key 树的子元素的key 
     */
    /**
     * 状态
     * @typedef {Object} stateObject
     * @property {Array.<treeChild>} treeData 左边的树的数据
     * @property {Number} pageNum 右边的列表的页码
     * @property {Number} pageSize 右边的列表一页最多记录数
     * @property {String} user 当前用户名
     * @property {Number} total 右边的列表的总记录数
     * @property {Array} agentSalesmanListData 记录数据
     */
    /**
     * 本页面的状态
     * @type {stateObject}
     */
    state = {
        treeData: [
            { title: '智慧科技', key: 'tree-0' },
        ],
        pageNum:1,
        pageSize:30,
        user: JSON.parse(localStorage.getItem('userData')).username,
    }
    /**
     * 处理当前数据行表格列数据的并最终返回渲染值的函数
     * @typedef {Function} tableColumnRenderFunction
     * @param {String} text 当前数据行对应的该表格列的值
     * @param {Object} record 当前数据行
     * @return {Number | String | HTMLDocument | HTMLCollection}
     */
    /**
     * 表格列
     * @typedef {Object} tableColumn
     * @property {String} title 表头标题
     * @property {String} dataIndex 表头取的字段名称
     * @property {String} key 表头的key值 
     * @property {tableColumnRenderFunction} render 
     */
    /**
     * 右边的表格列数据
     * @type {Array.<tableColumn>}
     */
    columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '职务',
            dataIndex: 'typeName',
            key: 'typeName'
        },
        {
            title: '联系方式',
            dataIndex: 'tel',
            key: 'tel'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <div style={{fontSize:'1.2em'}}>
                    <TextModal iconType={"edit"} className="mr10" theme={'twoTone'} iconColor={"#1890ff"} content={<Edit wrappedComponentRef={(inst)=>this.editRef=inst} record={record}/>} 
                               title="编辑" onOk={() => new Promise((resolve, reject) => {
                                    this.editRef.onSubmit().then(value => {
                                        this.EditItem({...value, id: record.id}).then(res => {
                                            resolve()
                                        }, reason => {
                                            reject(reason)
                                        })
                                    }, reason => {
                                        reject(reason)
                                    })
                                })} postOk={() => {window.location.reload()}}/>
                    <TextModal iconType={"delete"} theme={'twoTone'} iconColor={"#f5222d"} content={"是否确认要删除？"} 
                               title="删除" onOk={() => new Promise((resolve, reject) => {
                                this.deleteFromTableList(record.id).then(value => {
                                    resolve(value)
                                }, reason => {
                                    reject(reason)
                                })
                            })} postOk={() => {window.location.reload()}}/>
                </div>
            )
        }
    ]

    /**
     * 组件载入函数
     * @type {Function}
     */
    componentDidMount() {
        this.requestTableList()
    }

    /**
     * 左边的树
     * @type {Function}
     * @param {Object} 节点
     * @return {Promise} 一个异步获取子元素的Promise，成功后resolve()
     */
    onLoadData = treeNode =>
        new Promise(resolve => {
            if (treeNode.props.children) {
                resolve();
                return;
            }
            // 通过请求获取子元素
            getAgentBusinessList({ pid: treeNode.props.eventKey.split('-').slice(-1)[0] }).then((res) => {
                console.log(res, 'tree')
                if(res.status === 200 && res.data.state.value == 0) {
                    treeNode.props.dataRef.children = res.data.result.map(item => {
                        return {
                            key: `${treeNode.props.eventKey}-${item.id}`,
                            title: item.name,
                            type: item.type
                        }
                    })
                    console.log(this.state.treeData)
                    this.setState({
                        treeData: [...this.state.treeData],
                    })
                    resolve()
                }
                // console.log(this.state.treeData)
            })
        });

    /**
     * 渲染节点
     * @param {Array} 节点集合
     * @return {Array.<HTMLElement>} 元素集合
     */
    renderTreeNodes = data =>
        data.map(item => {
            let icon
            switch (item.type) {
                case 1:
                    icon = <Icon type="team" />
                    break
                case 2: 
                    icon = <Icon type="user" />
                    break
                default:
                    icon = <Icon type="dingding" />
                    break
            }
            if (item.children) {
                return (
                    <TreeNode icon={icon} title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                )
            }
            return <TreeNode icon={icon} key={item.key} {...item} dataRef={item} />;
        });

    /**
     * 请求表格数据
     * @param {Number} pageNum 页码
     * @param {Number} pageSize 每页记录数
     */
    requestTableList = (pageNum, pageSize) => {
        pageNum = pageNum ? pageNum : this.state.pageNum
        pageSize = pageSize ? pageSize : this.state.pageSize
        getAgentBusinessListData({ pageNum, pageSize }).then((res)=>{
            console.log(res,"get");
            if(res.status === 200 && res.data.state.value == 0) {
                utils.tableListKey(res.data.result.rows,this.state.pageNum,this.state.pageSize)
                this.setState({
                    total: Number(res.data.result.records),
                    pageNum,
                    pageSize,
                    agentSalesmanListData: res.data.result.rows
                })
            }
        }, reason => {
            statusMessage(reason)
        })
    }
    /**
     * 删除操作
     * @param {String} agentBusinessId 删除目标对应的id
     * @return {Promise}
     */
    deleteFromTableList = (agentBusinessId) => new Promise((resolve, reject) => {
            delAgentBusiness({agentBusinessId}).then((res)=>{
            console.log(res, "delete")
            if(res.status === 200 && res.data.state.value == 0) {
                resolve()
            }
        }, reason => {
            reject(reason)
        })
    })

    /**
     * 编辑操作
     * @param {Object} values 编辑的值
     * @return {Promise}
     */
    EditItem = (values) => new Promise((resolve, reject) => {
        saveAgentBusiness(values).then((res)=>{
            console.log(res, "edit")
            if(res.status === 200 && res.data.state.value == 0) {
                resolve()
            }
        }, reason => {
            reject(reason)
        })
    })

    /**
     * 增加操作
     * @param {Object} values 新增业务员的值
     * @return {Promise}
     */
    AddItem = (values) => new Promise((resolve, reject) => {
        addAgentBusiness(values).then((res)=>{
            console.log(res, "add")
            if (res.status === 200 && res.data.state.value == 0) {
                resolve()
            }
        }, reason => {
            reject(reason)
        })
    })

    /**
     * 组件渲染值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => (
            <Row>
                <Col span={3}>
                    <Tree showLine showIcon loadData={this.onLoadData} defaultExpandedKeys={['tree-0']}>{this.renderTreeNodes(this.state.treeData)}</Tree>
                </Col>
                <Col span={21}>
                <Table
                    dataSource={this.state.agentSalesmanListData}
                    pagination={pagination(this.state.pageSize, this.state.pageNum, this.state.total, (page, size) => {this.requestTableList(page, size)})}
                    bordered
                    columns={this.columns}
                    footer={()=>{
                        return (
                        <TextModal iconType={"plus-circle"} theme={"filled"} content={<Increase wrappedComponentRef={(inst)=>this.addRef=inst}/>} 
                            title="新增" className='f20' iconColor={'#1890ff'} onOk={() => new Promise((resolve, reject) => {
                                this.addRef.onSubmit().then(value => {
                                    this.AddItem(value).then(res => {
                                        resolve()
                                    }, reason => {
                                        reject(reason)
                                    })
                                }, reason => {
                                    reject(reason)
                                })
                            })
                        }  postOk={()=>{this.addRef.emptyValues(); window.location.reload()}}/>
                        )
                    }}>
                </Table>
                </Col>
            </Row>
        )
}

export default SalesmanList;