import React, { Component } from 'react'

import imgSrc from './../../../Common/images/01.png'

class PictureCenter extends Component {
    render() {
        console.log(this.props.record.problemDetails);
        //const record = this.props.record;
        return (
            <div className="askQuestion">
                <div className="piCenTitle">咨询问题</div>
                <div className="piCenNeirp">{this.props.record.problemDetails}</div>
                <div className="piCenPicture">
                    <img alt="01.png" src={imgSrc} />
                </div>
            </div>
        )
    }
}

// const PictureTopForm = Form.create()(PictureCenter);

export default PictureCenter;