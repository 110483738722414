import React, { Component } from 'react'
import {Tabs} from 'antd'
import Check from './Check'
import Uncheck from './Uncheck'
const TabPane = Tabs.TabPane
//首页-法律法规
export default class Legal extends Component {
    callback = (e)=>{
        console.log(e)
    }
    render() {
        return (
            <div className="home">
                <Tabs defaultActiveKey="1" onChange={this.callback}>
                    <TabPane tab="待处理" key="1">
                        <Uncheck />
                    </TabPane>
                    <TabPane tab="已处理" key="2">
                        <Check />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
