/**
 * @description 危害因素限制查询
 * @author yzx yangzhixin177@163.com
 */import React, { Component } from 'react'
import {Form,Table,Modal, Button, message,Input,Icon, Select,Upload,Card,Radio} from 'antd'
import {addRiskFactors,delRiskFactors,getRiskFactorsListData,saveRiskFactors,getRiskFactors,saveIsShowRiskFactors} from '../../Api'
import './../../Common/css/path.css'
import moment from 'moment'
import utils from './../../Common/js/utils'
const FormItem = Form.Item;
const Option = Select.Option;

/**
 * @description 组件-危害因素限制查询
 * @class DangerSearch
 * @extends {React.Component}
 */
class DangerSearch extends React.Component{
    /**
     * @description 组件内数据
     * @param {Object} params 页码，每页大小
     * @param {Array} selectedIds 表格选中的记录id数组
     * @memberof DangerSearch
     */
    state = {
        params:{
            pageNum:1,
            pageSize:30
        },
      selectedIds:[],
      }

    /**
     * @description 重置弹框表单内容
     * @function handleReset
     * @memberof DangerSearch
     */
    handleReset = ()=>{
        this.props.form.resetFields();
    }
    /**
     * @description react生命周期，在渲染前调用
     * @function componentWillMount
     * @memberof DangerSearch
     */
    componentWillMount=()=>{
        this.requestList();
    }
    /**
     * @description 点击事件--新增
     * @function handleAdd
     * @param {string} type 类型
     * @memberof DangerSearch
     */
    handleAdd=(type)=>{
        this.setState({
            title:'新增',
            isVisible:true,
            type
        })
    }
    /**
     * @description 表格内删除操作
     * @function handleTableDelete
     * @param {Object} record 当前行对象
     * @memberof DangerSearch
     */
    handleTableDelete=(record)=>{
        // console.log(record)
        let _this = this
        Modal.confirm({
            title:'删除提示',
            content: `您确定要删除这条数据吗？`,
            onOk:()=>{
                delRiskFactors({id:record.id}).then((res)=>{
                    if(res.data.state.value == 0){
                        _this.setState({
                            isVisible:false
                        })
                        message.success('删除成功');
                        _this.requestList();
                    }
                    else{
                        this.renderModalSimple('warning','错误','删除失败，请重新删除!')
                    }
                })
            }
        })
    }
    /**
     * @description 表格内编辑
     * @function handleEditChart
     * @param {string} type 类型
     * @param {Object} record 当前行对象
     * @memberof DangerSearch
     */
    handleEditChart=(type,record)=>{
        // console.log(record)
        this.setState({
            title:'编辑',
            isVisible:true,
            dangerInfo:record,
            type
        })
    }
    /**
     * @description 出框后按确定事件
     * @function handleSubmit
     * @memberof DangerSearch
     */
    handleSubmit = ()=>{
        let data = this.DangerForm.props.form.getFieldsValue();
        let type = this.state.type;
        let dangerInfo=this.state.dangerInfo;
        this.DangerForm.props.form.validateFields((err)=>{
            if(!err){
        if(type=='edit'){
            saveRiskFactors({id:dangerInfo.id,
                type:data.type,
                nameCh:data.nameCh,
                paramCode:data.paramCode,
                belongCatalog:data.belongCatalog,
                catalogName:data.catalogName,
                belongGbz22007:data.belongGbz22007,
                gbz22007Name:data.gbz22007Name,
                belongGbz188:data.belongGbz188,
                gbz1882014:data.gbz1882014,
                belongGbz22019:data.belongGbz22019,
                gbz22019Name:data.gbz22019Name,
                alias:data.alias,
                casno:data.casno,
                remarks:data.remarks,
                occupationalContraindication:data.occupationalContraindication,
                occupationalDisease:data.occupationalDisease,
                basisOccupationalHealth:data.basisOccupationalHealth,
                warningSignsRecommend:data.warningSignsRecommend,
                resource:1
            }).then((res)=>{
            if(res.data.state.value == 0){
                this.setState({
                    isVisible:false
                })     
                message.success('编辑成功');
                this.requestList();
                this.DangerForm.resetValue()
            }else{
                this.renderModalSimple('warning','错误','编辑失败')
            }
        })
    }
        if(type=='create'){
            addRiskFactors({type:data.type,
                nameCh:data.nameCh,
                paramCode:data.paramCode,
                belongCatalog:data.belongCatalog,
                catalogName:data.catalogName,
                belongGbz22007:data.belongGbz22007,
                gbz22007Name:data.gbz22007Name,
                belongGbz188:data.belongGbz188,
                gbz1882014:data.gbz1882014,
                belongGbz22019:data.belongGbz22019,
                gbz22019Name:data.gbz22019Name,
                alias:data.alias,
                casno:data.casno,
                remarks:data.remarks,
                occupationalContraindication:data.occupationalContraindication,
                occupationalDisease:data.occupationalDisease,
                basisOccupationalHealth:data.basisOccupationalHealth,
                warningSignsRecommend:data.warningSignsRecommend,
                resource:1
            }).then((res)=>{
            if(res.data.state.value == 0){
                this.setState({
                    isVisible:false
                })
                message.success('新增成功');
                this.requestList();
                this.DangerForm.resetValue()
            }else{
                this.renderModalSimple('warning','错误','新增失败')
            }
        })
    }
}
  }).catch((e) => {})
}
    /**
     * @description 清空表格选中
     * @function resetCheckedList
     * @memberof DangerSearch
     */
    resetCheckedList = ()=>{
        this.setState({
            selectedRowKeys:[],
            selectedRows:null,
            selectedIds:[],
            selectItem:{},
        })
    }
    /**
     * @description 请求危害因素数据列表
     * @function requestList
     * @memberof DangerSearch
     */
    requestList = ()=>{
        let _this = this
        getRiskFactorsListData({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize }).then((res)=>{
            // console.log(res,"list");
            if(res.status === 200){
                // console.log(res.data.result.rows)
                // console.log(res.data.result.total)
                utils.tableListKey(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        // console.log(size,"size")
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList()
                    }, (current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.requestList()
                    }, res.data.result.pageSize)
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    /**
     * @description react生命周期，在渲染前调用
     * @function componentWillMount
     * @memberof DangerSearch
     */
    componentWillMount =()=>{
        this.requestList()
    }
    /**
     * @description 点击行事件
     * @function onRowClick
     * @param {Object} record 当前行对象 
     * @param {Number} index 当前行下标
     * @memberof DangerSearch
     */
    onRowClick = (record,index)=>{
        let selectKey = [index];
        this.setState({
            selectedRowKeys:selectKey,
            selectedItem: record,
            selectedIds:[0]
        })
    }   
    /**
     * @description 渲染警告内容的弹窗
     * @function renderModalSimple
     * @param {string} type 类型
     * @param {string} title 头文字
     * @param {string} content 内容
     * @returns {Object} Modal 返回一个弹框对象
     * @memberof DangerSearch
     */
    renderModalSimple = (type,title,content) =>{
        return Modal[type?type:'info']({ 
            title:title?title:'警告',
            content:content?content:''
        })
    }
    /**
     * @description 危害因素搜索
     * @function handleSearch
     * @memberof DangerSearch
     */
    handleSearch = ()=>{
        // console.log(params)
        let _this = this
        let formData = this.searchRef.handleSearch()
        getRiskFactorsListData({pageNum: _this.state.params.pageNum, pageSize: _this.state.params.pageSize,keyword:formData.riskNameORaliasORCASNo,type:formData.type,resource:formData.resource,isShow:formData.isShow}).then((res)=>{
            //  console.log(res,"search")
            if(res.status === 200){
                utils.tableListKey(res.data.result.rows,this.state.params.pageNum,this.state.params.pageSize)
                this.setState({
                    total: res.data.result.total,
                    dataSource: res.data.result.rows,
                    page: res.data.result.page,
                    pagination: utils.pagination(res.data,(current,size)=>{
                        // console.log(size,"size")
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.handleSearch()
                    }, (current,size)=>{
                        _this.state.params.pageNum=current
                        _this.state.params.pageSize=size
                        this.handleSearch()
                    }, res.data.result.pageSize)
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
    /**
     * @description 点击事件--查看
     * @function handleDetail
     * @memberof DangerSearch
     * @param {Object} record 当前行对象 
     */
    handleDetail=(record)=>{
        this.setState({
            title:'查看',
            isDetailVisible:true,
            detailInfo:record,
        })
    }
    /**
     * @description 点击事件--显示，隐藏切换
     * @function handleShow
     * @param {Object} record 当前行对象 
     * @memberof DangerSearch
     */
    handleShow=(record)=>{
        let _this=this
        const Show=!record.isShow
        saveIsShowRiskFactors({id:record.id,isShow:Show}).then((res)=>{
            if(res.data.state.value == 0){
                _this.requestList();
                message.success(res.data.content);
            }else{
                this.renderModalSimple(res.data.content)
            }
        })
    }
    render() {
        let _this=this
        const columns=[
            {
                title:'序号',
                key:'key',
                dataIndex:'key'
            },
            {
                title:'类型',
                key:'type',
                dataIndex:'type'
            },
            {
                title:'危害因素名称',
                key:'nameCh',
                dataIndex:'nameCh',
                render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title:'编码',
                key:'paramCode',
                dataIndex:'paramCode'
            },
            {
                title:'来源',
                key:'resourceStr',
                dataIndex:'resourceStr',
                render(text){
                    return <span title={text}>{text}</span>},
            },
            {
                title:'别名',
                key:'alias',
                dataIndex:'alias',
                render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title:'CAS号',
                key:'casno',
                dataIndex:'casno',
                render(text) {
                    if(text){
                        return <span>{text}</span>
                    }
                    else{
                        return '-'
                    }
                },
            },
            {
                title:'显示',
                key:'isShow',
                dataIndex:'isShow',
                render(text) {
                    if(text==true){
                        return <span>是</span>
                    }
                    else{
                        return '否'
                    }
                },
            },
            {
                title:'更新时间',
                key:'updateDate',
                dataIndex:'updateDate',
                render(text) {
                    if(text){
                        return <span title={moment(text).format('YYYY-MM-DD')}>{moment(text).format('YYYY-MM-DD')}</span>
                    }else{
                        return '-'
                    }
                },
            },
            {
                title:'操作',
                render: (type,record) => {
                    if(record.isShow==true){
                    return<div>
                    <a title="查看" onClick={()=>this.handleDetail(record)}> <Icon type="eye"style={{paddingRight:7,fontSize:20}}/></a>
                    <a title="编辑" onClick={()=>this.handleEditChart('edit',record)}> <Icon type="edit" style={{marginRight:8,fontSize:20,color:"#52c41a"}}/></a> 
                    <a title="隐藏" onClick={()=>this.handleShow(record)}> <Icon type="minus"style={{paddingRight:7,fontSize:20,color:"#8D14FA"}}/></a>
                    <a title="删除" onClick={()=>this.handleTableDelete(record)}><Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{fontSize:20}}/></a> 
                    </div>}
                    else{
                        return<div>
                        <a title="查看" onClick={()=>this.handleDetail(record)}> <Icon type="eye"style={{paddingRight:7,fontSize:20}}/></a>
                        <a title="编辑" onClick={()=>this.handleEditChart('edit',record)}> <Icon type="edit" style={{marginRight:8,fontSize:20,color:"#52c41a"}}/></a> 
                        <a title="显示" onClick={()=>this.handleShow(record)}> <Icon type="plus"style={{paddingRight:7,fontSize:20,color:"#8D14FA"}}/></a>
                        <a title="删除" onClick={()=>this.handleTableDelete(record)}><Icon type="delete" theme="twoTone" twoToneColor="#dd715a" style={{fontSize:20}}/></a> 
                        </div>}
                }
            },
        ]
        const props = {
            name: 'file',
            action: '/v1/ajax/file/upload',
            headers: {
              authorization: 'authorization-text',
            }
        }
        const title = () => {
            return (
                <div>
                    <div className="fr">
                    {/* <Button type="primary" onClick={this.handleDelete}>
                                    批量删除
                            </Button> */}
                            <Button type="primary" onClick={()=>this.handleAdd('create')} style={{ marginLeft: 10 }}>
                                    新增
                            </Button>
                    </div>
                    <div className="clear"></div>
                </div>
            )
        };
        return(
                <Card>
                    <SearchForm handleSearch={this.handleSearch} wrappedComponentRef={(inst) => this.searchRef = inst}/>
                    
                    <Table className="mt20 dangertable" bordered title={title}
                        onRow={(record,index) => {
                            return {
                                onClick:()=>{
                                    this.onRowClick(record,index);
                                }
                            };
                        }}
                        columns={columns}
                        dataSource={this.state.dataSource}
                        rowKey={(record,index)=> index}
                        pagination={{ ...this.state.pagination, pageSize: this.state.params.pageSize }}
                    /> 
                    <Modal className='newtable1'
                    title={this.state.title}
                    visible={this.state.isVisible}
                    onOk={()=>this.handleSubmit()}
                    width={500}
                    onCancel={()=>{
                        this.DangerForm.props.form.resetFields();
                        this.setState({
                            isVisible:false,
                            dangerInfo:''
                        })
                    }}
                >
                    <DangerForm dangerInfo={this.state.dangerInfo} type={this.state.type} wrappedComponentRef={(inst) => this.DangerForm = inst }/>
                    </Modal>
                    <Modal className='newtable'
                    title={this.state.title}
                    visible={this.state.isDetailVisible}
                    onOk={()=>{
                        this.setState({
                            isDetailVisible:false,
                        })
                    }}
                    onCancel={()=>{
                        this.setState({
                            isDetailVisible:false,
                        })
                    }}
                >
                    <DetailForm detailInfo={this.state.detailInfo} wrappedComponentRef={(inst) => this.DetailForm = inst }/>
                </Modal>  
                </Card>
        );
    }
}

export default DangerSearch;
/**
 * @description 组件-危害因素编辑弹框
 * @class DangerForm
 * @extends {React.Component}
 */
class DangerForm extends React.Component{
    resetValue = ()=>{
        this.props.form.resetFields()
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 10},
            wrapperCol: {span: 12}
        };
        const dangerInfo = this.props.dangerInfo || {};
        const type = this.props.type;
        return (
            <Form layout="horizontal">
                <FormItem label="类型" {...formItemLayout}>
                    {
                        getFieldDecorator('type',{
                            initialValue:type=='edit'?dangerInfo.type:'粉尘',
                            rules: [{ required: true, message: '请选择类型'},]
                          })(
                        <Select>
                            <Option value={'粉尘'}>粉尘</Option>
                            <Option value={'化学因素'}>化学因素</Option>
                            <Option value={'物理因素'}>物理因素</Option>
                            <Option value={'生物因素'}>生物因素</Option>
                            <Option value={'放射因素'}>放射因素</Option>
                            <Option value={'其他因素'}>其他因素</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="危害因素名称"{...formItemLayout}>
                    {
                        getFieldDecorator('nameCh',{
                            initialValue:type=='edit'?dangerInfo.nameCh:[],
                            rules: [{ required: true, message: '请输入危害因素名称'},{
                                pattern: /^[^\s]*$/,
                                message: '禁止输入空格',
                              }]
                          })(
                            <Input type='text' id='error'/>
                    )}
                </FormItem>
                <FormItem label="编码"{...formItemLayout}>
                    {
                        getFieldDecorator('paramCode',{
                            initialValue:type=='edit'?dangerInfo.paramCode:[],
                            rules: [{ required: true, message: '请输入编码'}]
                          })(
                            <Input type='text'/>
                    )}
                </FormItem>
                <FormItem label="是否属于目录" {...formItemLayout}>
                    {
                        getFieldDecorator('belongCatalog',{
                            initialValue:type=='edit'?dangerInfo.belongCatalog:1
                        })(
                            <Radio.Group>
                             <Radio value={1}>是</Radio>
                             <Radio value={0}>否</Radio>
                            </Radio.Group>
                        )
                        }
                </FormItem>
                <FormItem label="目录名称" {...formItemLayout}>
                    {
                        getFieldDecorator('catalogName',{
                            initialValue:type=='edit'?dangerInfo.catalogName:''
                        })(
                            <Input type='text'/>
                        )
                        }
                </FormItem>
                <FormItem label="是否属于GBZ2-2007" {...formItemLayout}>
                    {
                        getFieldDecorator('belongGbz22007',{
                            initialValue:type=='edit'?dangerInfo.belongGbz22007:1
                        })(
                            <Radio.Group>
                             <Radio value={1}>是</Radio>
                             <Radio value={0}>否</Radio>
                            </Radio.Group>
                        )
                        }
                </FormItem>
                <FormItem label="GBZ2-2007名称" {...formItemLayout}>
                    {
                        getFieldDecorator('gbz22007Name',{
                            initialValue:type=='edit'?dangerInfo.gbz22007Name:''
                        })(
                            <Input type='text'/>
                        )
                        }
                </FormItem>
                <FormItem label="是否属于GBZ188-2014" {...formItemLayout}>
                    {
                        getFieldDecorator('belongGbz188',{
                            initialValue:type=='edit'?dangerInfo.belongGbz188:1
                        })(
                            <Radio.Group>
                             <Radio value={1}>是</Radio>
                             <Radio value={0}>否</Radio>
                            </Radio.Group>
                        )
                        }
                </FormItem>
                <FormItem label="GBZ188-2014名称" {...formItemLayout}>
                    {
                        getFieldDecorator('gbz1882014',{
                            initialValue:type=='edit'?dangerInfo.gbz1882014:''
                        })(
                            <Input type='text'/>
                        )
                        }
                </FormItem>
                <FormItem label="是否属于GBZ2-2019" {...formItemLayout}>
                    {
                        getFieldDecorator('belongGbz22019',{
                            initialValue:type=='edit'?dangerInfo.belongGbz22019:1
                        })(
                            <Radio.Group>
                             <Radio value={1}>是</Radio>
                             <Radio value={0}>否</Radio>
                            </Radio.Group>
                        )
                        }
                </FormItem>
                <FormItem label="GBZ2-2019名称" {...formItemLayout}>
                    {
                        getFieldDecorator('gbz22019Name',{
                            initialValue:type=='edit'?dangerInfo.gbz22019Name:''
                        })(
                            <Input type='text'/>
                        )
                        }
                </FormItem>
                <FormItem label="别名" {...formItemLayout}>
                    {
                        getFieldDecorator('alias',{
                            initialValue:type=='edit'?dangerInfo.alias:[],
                        })(
                            <Input type='text'/>
                    )}
                </FormItem>
                <FormItem label="CAS号" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('casno',{
                            initialValue:type=='edit'?dangerInfo.casno:[],
                        })(
                            <Input type='text'/>
                    )}
                </FormItem>
                <FormItem label="标记" {...formItemLayout}>
                {
                        
                        getFieldDecorator('remarks',{
                            initialValue:type=='edit'?dangerInfo.remarks:[],
                        })(
                            <Input type='text'/>
                    )}
                </FormItem> 
                <FormItem label="职业禁忌证" {...formItemLayout}>
                { 
                        getFieldDecorator('occupationalContraindication',{
                            initialValue:type=='edit'?dangerInfo.occupationalContraindication:[],
                        })(
                            <Input type='text'/>
                    )}
                </FormItem> 
                <FormItem label="职业病" {...formItemLayout}>
                { 
                        getFieldDecorator('occupationalDisease',{
                            initialValue:type=='edit'?dangerInfo.occupationalDisease:[],
                        })(
                            <Input type='text'/>
                    )}
                </FormItem> 
                <FormItem label="职业禁忌证和职业病的依据" {...formItemLayout}>
                { 
                        getFieldDecorator('basisOccupationalHealth',{
                            initialValue:type=='edit'?dangerInfo.basisOccupationalHealth:[],
                        })(
                            <Input type='text'/>
                    )}
                </FormItem> 
                <FormItem label="推荐的职业病危害警示标识" {...formItemLayout}>
                { 
                        getFieldDecorator('warningSignsRecommend',{
                            initialValue:type=='edit'?dangerInfo.warningSignsRecommend:[],
                        })(
                            <Input type='text'/>
                    )}
                </FormItem> 
            </Form>
        );
    }
}
DangerForm = Form.create({})(DangerForm);

/**
 * @description 组件-危害因素搜索
 * @class SearchForm
 * @extends {React.Component}
 */
class SearchForm extends React.Component{
    handleSearch=()=>{ //调用父组件方法
        return this.props.form.getFieldsValue()
    }
      handleReset = ()=>{
        this.props.form.resetFields();
      }
    
    render(){
        const {getFieldDecorator} = this.props.form;
        return (
            <Form layout="inline"> 
            <FormItem>
                {getFieldDecorator('riskNameORaliasORCASNo')(<Input
                placeholder="请输入危害因素名称/CAS号/别名" prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                style={{ width: 264 }}
                />)}
                </FormItem>
            <FormItem>
            {getFieldDecorator('type',{initialValue:''})( <Select
                        style={{width:123}}
                        placeholder="全部类型">
                        <Option value="">全部类型</Option>
                        <Option value="粉尘">粉尘</Option>
                        <Option value="化学因素">化学因素</Option>
                        <Option value="物理因素">物理因素</Option>
                        <Option value="生物因素">生物因素</Option>
                        <Option value="放射因素">放射因素</Option>
                        <Option value="其他因素">其他因素</Option>
                        </Select>)}
                    </FormItem>
            <FormItem>
                {getFieldDecorator('resource',{initialValue:''})(<Select
                    style={{width:123}}
                    placeholder="全部来源">
                    <Option value="">全部来源</Option>
                    <Option value="1">目录</Option>
                    <Option value="2">GBZ2-2007</Option>
                    <Option value="3">GBZ188-2014</Option>
                    <Option value="4">GBZ2-2019</Option>
                </Select>)
                }
                </FormItem>
            <FormItem>
            {getFieldDecorator('isShow',{initialValue:''})(<Select
                style={{width:123}}
                placeholder="全部显示">
                <Option value="">全部显示</Option>
                <Option value="true">是</Option>
                <Option value="false">否</Option>
            </Select>)
            }
            </FormItem>
            <FormItem>
                <Button type="primary" onClick={this.props.handleSearch}>
                        搜索
                </Button>
                <Button onClick={this.handleReset} style={{marginLeft:10}}>
                        重置
                </Button>
            </FormItem>
            </Form>
        );
    }
  }
SearchForm = Form.create({})(SearchForm);

/**
 * @description 组件-危害因素详情弹框
 * @class DetailForm
 * @extends {React.Component}
 */
class DetailForm extends React.Component{
    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 12},
            wrapperCol: {span: 10}
        };
        const detailInfo = this.props.detailInfo || {};
        return (
            <Form layout="horizontal">
                <FormItem label="类型" {...formItemLayout}>
                    {
                          detailInfo.type?detailInfo.type:'-'
                          }
                </FormItem>
                <FormItem label="编码" {...formItemLayout}>
                    {
                          detailInfo.paramCode?detailInfo.paramCode:'-'
                          }
                </FormItem>
                <FormItem label="名称" {...formItemLayout}>
                    {
                          detailInfo.nameCh?detailInfo.nameCh:'-'
                          }
                </FormItem>  
                <FormItem label="是否属于目录" {...formItemLayout}>
                    {
                         detailInfo.belongCatalog==1?'是':'否'
                        }
                </FormItem>
                <FormItem label="目录名称" {...formItemLayout}>
                    {
                        detailInfo.catalogName?detailInfo.catalogName:'-'
                        }
                </FormItem>
                <FormItem label="是否属于GBZ2-2007" {...formItemLayout}>
                    {
                        detailInfo.belongGbz22007==1?'是':'否'
                        }
                </FormItem>
                <FormItem label="GBZ2-2007名称" {...formItemLayout}>
                    {
                        detailInfo.gbz22007Name?detailInfo.gbz22007Name:'-'
                        }
                </FormItem>
                <FormItem label="是否属于GBZ188-2014" {...formItemLayout}>
                    {
                        detailInfo.belongGbz188==1?'是':'否'
                        }
                </FormItem>
                <FormItem label="GBZ188-2014名称" {...formItemLayout}>
                    {
                        detailInfo.gbz1882014?detailInfo.gbz1882014:'-'
                        }
                </FormItem>
                <FormItem label="是否属于GBZ2-2019" {...formItemLayout}>
                    {
                            detailInfo.belongGbz22019==1?'是':'否'
                        }
                </FormItem>
                <FormItem label="GBZ2-2019名称" {...formItemLayout}>
                    {
                            detailInfo.gbz22019Name?detailInfo.gbz22019Name:'-'
                        }
                </FormItem> 
                <FormItem label="别名" {...formItemLayout}>
                    {
                        detailInfo.alias?detailInfo.alias:'-'
                        }
                </FormItem> 
                <FormItem label="CAS号" {...formItemLayout}>
                    {
                        detailInfo.casno?detailInfo.casno:'-'
                        }
                </FormItem> 
                <FormItem label="标记" {...formItemLayout}>
                    {
                        detailInfo.remarks?detailInfo.remarks:'-'
                            }
                </FormItem> 
                <FormItem label="职业禁忌证" {...formItemLayout}>
                    {
                        detailInfo.occupationalContraindication?detailInfo.occupationalContraindication:'-'
                          }
                </FormItem> 
                <FormItem label="职业病" {...formItemLayout}>
                    {
                        detailInfo.occupationalDisease?detailInfo.occupationalDisease:'-'
                          }
                </FormItem> 
                <FormItem label="导致职业禁忌证和职业病的依据" {...formItemLayout}>
                    {
                        detailInfo.basisOccupationalHealth?detailInfo.basisOccupationalHealth:'-'
                          }
                </FormItem> 
                <FormItem label="推荐的职业病危害警示标识" {...formItemLayout}>
                    {
                        detailInfo.warningSignsRecommend?detailInfo.warningSignsRecommend:'-'
                          }
                </FormItem> 
            </Form>
        );
    }
}
DetailForm = Form.create({})(DetailForm);