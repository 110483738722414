import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form, Select, Input, Radio, DatePicker } from 'antd'
import moment from 'moment'
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea
/**
 * Modal-Form组件
 * 内容：Form
 * 功能：可查看、可编辑、可新增
 * 父组件传入属性：
 *      footer（bol）                默认有“确定”和“取消”按钮，true-只有取消按钮，false-默认   
 *      formSubmitApi（fn）          提交表单的方法，需返回Promise对象，且返回false\true（true代表操作成功需清空表单，false不做任何处理）
 *      handleVisibleChange（fn）    更改visible属性的方法
 *      visibleCheckModal（bol）     控制Modal的visible值     
 *      formList{title:'',list:[]}   配置Modal的对象(title为标题，List为Form内容)
 *      width（int）                 默认为Modal默认宽度，传参即变
 *      formItemLayout{}             Modal布局，传参即变，不传则默认
 */
class CheckModal extends Component {
    state = {
        formItemList: [],
        visibleCheckModal: false,
        isEditTime: false
    }
    //Modal-Form提交事件
    submit = () => {
        //验证表单数据
        this.props.form.validateFields((err, value) => {
            //调用父组件传入的提交Api方法（需返回Promise对象，且返回false\true）
            this.props.formSubmitApi({ ...value }).then(res => {
                //res=true  操作成功，清空表单
                if (res) {
                    this.reset()
                }
            })
            // this.props.homeSubmit(value,this.props.formSubmitApi({ ...value }))
        })
    }
    //清空表单+清空修改时间状态
    reset = () => {
        this.props.form.resetFields();
        this.setState({
            isEditTime: false
        })
    }
    //修改时间的切换（若没有修改时间选项则置空该值）
    editTime = (record) => {
        if (this.state.isEditTime) {
            this.props.form.setFieldsValue({ [record.name]: undefined });
        }
        this.setState({
            isEditTime: !this.state.isEditTime
        })
    }
    render() {
        //title（str）  Modal标题
        const { title } = this.props.formList
        //width(int)    Modal宽度
        let width = 520
        width = this.props.width ? this.props.width : width
        //formItemLayout{}   Modal布局
        let formItemLayout = {
            labelCol: {
                xs: 24,
                sm: 6
            },
            wrapperCol: {
                xs: 24,
                sm: 18
            }
        }
        formItemLayout = this.props.formItemLayout ? this.props.formItemLayout : formItemLayout
        //获取父组件的Modal-visible，控制Modal-visible的方法，Modal-footer配置
        let { visibleCheckModal, handleVisibleChange, footer } = this.props
        //表单获取属性
        const { getFieldDecorator } = this.props.form
        //Modal展示内容
        let formItemList = []
        //通过父组件传入的配置  渲染Modal内容
        this.props.formList.list.map((item, index) => {
            switch (item.type) {
                case 'input':
                    let inputForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: item.rules?item.rules:[],
                        })(
                            <Input placeholder={item.placeholder} style={{ width: 205 }} />
                        )
                        }
                    </FormItem>
                    formItemList.push(inputForm)
                    break;
                case 'select':
                    let selectForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: item.rules?item.rules:[],
                        })(
                            <Select style={{ width: 150 }} placeholder={item.placeholder}>
                                {item.optionList.map(kid => {
                                    return <Option value={kid.value} key={kid.label + kid.value}>{kid.label}</Option>
                                })}
                            </Select>
                        )
                        }
                    </FormItem>
                    formItemList.push(selectForm)
                    break
                case 'identiyPic':
                    let identiyPicForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        <div>
                            {
                                item.value?
                                item.value.map((kid, index) => {
                                    if(!kid)return
                                    return <img alt={item.name} src={kid} key={'pic_' + index} width="100" style={{ margin: 5 }} />
                                }):'暂无'
                            }
                        </div>

                    </FormItem>
                    formItemList.push(identiyPicForm)
                    break
                case 'rangePicker':
                    let rangePickerForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: item.rules?item.rules:[]
                        })(
                            <RangePicker placeholder={item.placeholder} />
                        )
                        }
                    </FormItem>
                    formItemList.push(rangePickerForm)
                    break
                case 'datePicker':
                    let datePickerForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: item.rules?item.rules:[],
                            initialValue: item.initial ? item.initial : null
                        })(
                            <DatePicker placeholder={item.placeholder} />
                        )
                        }
                    </FormItem>
                    formItemList.push(datePickerForm)
                    break
                case 'textArea':
                    let textAreaForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: item.rules?item.rules:[],
                            initialValue: item.initial ? item.initial : ''
                        })(
                            <TextArea placeholder={item.placeholder} rows={4}/>
                        )
                        }
                    </FormItem>
                    formItemList.push(textAreaForm)
                    break
                case 'original':
                    let originalForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        <span className={item.line ? 'span-line' : ''}>{item.value}</span>
                    </FormItem>
                    formItemList.push(originalForm)
                    break
                case 'editTime':
                    let editTimeForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        <div>
                            <span>{item.value}</span>
                            {getFieldDecorator(`${item.name}`, {
                                rules: []
                            })(
                                <DatePicker className={this.state.isEditTime ? 'ml5' : 'ml5 dn'} placeholder={item.placeholder} />
                            )
                            }
                            <a className="ml10" onClick={() => this.editTime(item)}>{this.state.isEditTime ? '返回' : '修改时间'}</a>

                        </div>
                    </FormItem>
                    formItemList.push(editTimeForm)
                    break
                case 'radio':
                    let radioForm = <FormItem label={item.label} key={item.name + index} {...formItemLayout}>
                        {getFieldDecorator(`${item.name}`, {
                            rules: item.rules?item.rules:[],
                            initialValue:item.initial ? item.initial : ''
                        })(
                            <Radio.Group name={item.name}>
                                {
                                    item.radioList.map(kid => {
                                        return <Radio value={kid.value} key={kid.value + item.name}>{kid.label}</Radio>
                                    })
                                }
                            </Radio.Group>
                        )
                        }
                    </FormItem>
                    formItemList.push(radioForm)
                    break
                default:
                    break

            }
        })
        return (
            <Modal
                className="home"
                title={title}
                width={width}
                visible={visibleCheckModal}
                onOk={this.submit}
                onCancel={() => {
                    this.reset()
                    handleVisibleChange()
                }}
                //自定义footer
                footer={footer == true ? [
                    <Button key="back" onClick={() => {
                        this.reset()
                        handleVisibleChange()
                    }}>取消</Button>
                ] : [
                        <Button key="back" onClick={() => {
                            this.reset()
                            handleVisibleChange()
                        }}>取消</Button>,
                        <Button key="submit" type="primary" onClick={this.submit}>
                            确定
                        </Button>
                    ]}
            >
                <Form className="form-item-mb0">
                    {formItemList}
                </Form>
            </Modal>

        )
    }
}

CheckModal = Form.create({})(CheckModal);
export default connect(null, null)(CheckModal);