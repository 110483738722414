/**
 * @description 权限管理
 * @author yzx yangzhixin177@163.com
 */
import React from 'react'
import {Card, Button, Form, Input, Select, Modal, message, Table, Icon} from 'antd'
import {Link} from 'react-router-dom'
import {getRoleList,addRole} from '../../Api'
import menuConfig from './../../Common/js/menuConfig'
import utils from './../../Common/js/utils'
const FormItem = Form.Item;
/**
 * @description 组件-权限管理
 * @export
 * @class PermissManage
 * @extends {React.Component}
 */
export default class PermissManage extends React.Component{
    state = {
        data:[]
    }
    componentWillMount =()=>{
        this.requestList()
    }
    requestList=()=>{
        getRoleList().then((res)=>{
            // console.log(res.data.result)
            if(res.data.state.value == 0){
              this.setState({
                  data:res.data.result
              })
    }
    })
    }
    /**
     * @description 点击行事件
     * @function onRowClick
     * @param {Object} record 当前行对象 
     * @param {Number} index 当前行下标
     * @memberof PermissManage
     */
    onRowClick = (record,index)=>{
        let selectKey = [index];
        this.setState({
            selectedRowKeys:selectKey,
            selectedItem: record
        })
    } 
    handleAdd = ()=>{
        this.setState({
            title:"新增",
            isAddVisible:true
        })
    }
    handleSubmit = ()=>{
        let data = this.PermissAddForm.props.form.getFieldsValue();
        this.PermissAddForm.props.form.validateFields((err)=>{
            if(!err){
        addRole({roleName:data.roleName,remarks:data.remarks,roleNameEn:data.roleNameEn}).then((res)=>{
                if(res.data.state.value==0){
                    this.setState({
                        isAddVisible:false
                    })
                    message.success('新增成功')
                    this.requestList()
                    this.PermissAddForm.resetValue()
                }else{
                    this.renderModalSimple('warning','错误','新增失败！') 
                }
            })
        }
        }).catch((e) => {})
    }
    render(){
        const title = () => {
            return (
                <div>
                    <div className="fr">
                    <Button type="primary" onClick={()=>this.handleAdd()}>新增</Button>   
                    </div>
                    <div className="clear"></div>
                </div>
            )
        };
        const columns = [
            {
                title: '',
                key: 'id',
                dataIndex: 'id',
                render:(text,record,index)=>`${index+1}`
            },
            {
                title: '名称',
                key: 'roleName',
                dataIndex: 'roleName'
            },{
                title: '类型',
                key: 'dataScopeStr',
                dataIndex: 'dataScopeStr',
            },{
                title: '描述',
                key: 'remarks',
                dataIndex: 'remarks',
            },{
                title: '操作',
                dataIndex: 'opera', render: (type,record) => {
                        return<div>
                        <Link to={{pathname: `/system/list1/edit/${record.id}`,state:{name:record.roleName,remarks:record.remarks}}} title='编辑'><Icon type="edit"style={{fontSize:20}}/></Link>
                       </div>
            }
        }
        ];
     
        return (     
                <Card>
                    <Table
                        title={title}
                        bordered
                        onRow={(record,index) => {
                            return {
                                onClick:()=>{
                                    this.onRowClick(record,index);
                                }
                            };
                        }}
                        columns={columns}
                        dataSource={this.state.data}
                        rowKey={record=>record.id-1}
                        pagination={false}
                    /> 
                    <Modal className='add'
                        title={this.state.title}
                        visible={this.state.isAddVisible}  
                        onOk={()=>{this.handleSubmit()}}      
                        onCancel={()=>{
                            this.setState({
                                isAddVisible:false
                            })
                        }}
                    >
                        <PermissAddForm wrappedComponentRef={(inst) => this.PermissAddForm = inst }/>
                    </Modal>
                </Card>
        );
    }
}
class PermissAddForm extends React.Component{
    //重置
    resetValue = ()=>{
        this.props.form.resetFields()
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        return (
            <Form layout="horizontal">
                <FormItem label="角色名称" {...formItemLayout}>
                    {
                        getFieldDecorator('roleName',{
                            initialValue:'',rules: [{ required: true, message: '请输入角色名称'},]
                        })(
                            <Input type="text"/>
                        )
                    }
                </FormItem>
                <FormItem label="英文名称" {...formItemLayout}>
                    {
                        getFieldDecorator('roleNameEn',{
                            initialValue:'',rules: [{ required: true, message: '请输入英文名称'},]
                        })(
                            <Input type="text"/>
                        )
                    }
                </FormItem>
                <FormItem label="备注信息" {...formItemLayout}>
                    {
                        getFieldDecorator('remarks',{
                            initialValue:'',rules: [{ required: true, message: '请输入备注信息'},]
                        })(
                            <Input type="text"/>
                        )
                    }
                </FormItem>
            </Form>
        );
    }
}
PermissAddForm = Form.create({})(PermissAddForm);