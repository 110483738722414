/*
 * @file: 医生服务时间组件
 * @author:  xyq <qiuxiang_zhiyi@163.com>
 */
import React, { Component } from 'react'
import {
    Radio, message, Form,
    Button, Table, InputNumber,
} from 'antd'
import RadioGroup from 'antd/lib/radio/group';
/**
 * @classdesc: 医生服务时间组件
 */
class ServicesDate extends Component {
    /**
     * @description: 子传父
     * @function: Submit
     */
    Submit = () => {
        let updata = ['3']
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                updata.push(this.props.form.getFieldsValue())
                this.props.parentDelete(updata);
            } else {
                message.error('请将服务时间信息填写完整')
            }
        });
    }
    /**
     * @description: 返回上一步
     * @function: back
     */
    back() {
        let updata = ["2"]
        this.props.parentDelete(updata);
    }

    render() {
        const data = this.props.content
        const servicesDatelist = [
            {
                title: "服务方式", dataIndex: 'servicesType', align: 'center', width: 100, render(text, row, index) {
                    if (index == 0) {
                        return {
                            children: <span title={text}>{text}</span>,
                            props: { rowSpan: 3 }
                        }
                    } else if (index == 3) {
                        return {
                            children: <span title={text}>{text}</span>,
                            props: { rowSpan: 3 }
                        }
                    } else {
                        return {
                            children: <span title={text}>{text}</span>,
                            props: { colSpan: 0 }
                        }
                    }
                },
            },
            {
                title: '服务时间', dataIndex: 'servicesDate', align: 'center', width: 100, render(text) {
                    return <span title={text}>{text}</span>
                },
            },
            {
                title: '咨询费用(次)', dataIndex: 'cash', align: 'center', width: 100, render(text, row, index) {
                    return (
                        <Form.Item style={{ margin: 0 }}>
                            {
                                getFieldDecorator('cash' + index, {
                                    initialValue: data[index].cash,
                                    rules: [
                                        { required: true, message: '不能为空' },
                                    ],
                                })(
                                    <InputNumber min={0} max={200} size='small'
                                        style={{ width: '80%', margin: 'auto' }} />
                                )
                            }
                        </Form.Item>
                    )
                },
            },
            {
                title: '是否开通', dataIndex: 'open', align: 'center', width: 100, render(text, row, index) {
                    return (
                        <Form.Item style={{ margin: 0 }}>
                            {
                                getFieldDecorator('open' + index, {
                                    initialValue: data[index].open ? '1' : '0',
                                    rules: [
                                        { required: true, message: '不能为空' },
                                    ],
                                })(
                                    <RadioGroup>
                                        <Radio value="1">是</Radio>
                                        <Radio value="0">否</Radio>
                                    </RadioGroup>
                                )
                            }
                        </Form.Item>
                    )
                },
            },
            {
                title: '分成比例(%)', dataIndex: 'fProportion', align: 'center', width: 100, render(text, row, index) {
                    if (index == 0 || index == 3) {
                        return {
                            children: (
                                <Form.Item style={{ margin: 0 }}>
                                    {
                                        getFieldDecorator('fProportion' + index, {
                                            initialValue: data[index].fProportion,
                                            rules: [
                                                { required: true, message: '不能为空' },
                                            ],
                                        })(
                                            <InputNumber min={0} max={100} size='small' style={{ width: '80%', margin: 'auto' }} />,
                                        )
                                    }
                                </Form.Item>

                            ),
                            props: { rowSpan: 3 }
                        }
                    } else {
                        return {
                            children: <span title={text}>{text}</span>,
                            props: { colSpan: 0 }
                        }
                    }
                },
            },
            {
                title: '建康券分成(%)', dataIndex: 'qProportion', align: 'center', width: 100, render(text, row, index) {
                    if (index == 0 || index == 3) {
                        return {
                            children: (
                                <Form.Item style={{ margin: 0 }}>
                                    {
                                        getFieldDecorator('qProportion' + index, {
                                            initialValue: data[index].qProportion,
                                            rules: [
                                                { required: true, message: '不能为空' },
                                            ],
                                        })(
                                            <InputNumber min={0} max={100} size='small' style={{ width: '80%', margin: 'auto' }} />,
                                        )
                                    }
                                </Form.Item>

                            ),
                            props: { rowSpan: 3 }
                        }
                    } else {
                        return {
                            children: <span title={text}>{text}</span>,
                            props: { colSpan: 0 }
                        }
                    }
                },
            },
        ]
        const { getFieldDecorator } = this.props.form
        return (
            <div>
                {/* 服务时间 */}
                <Form>
                    <Table
                        dataSource={data}
                        columns={servicesDatelist}
                        bordered
                        pagination={false}
                    />
                </Form>
                {/* 提交按钮 */}
                <div style={{ textAlign: 'center', width: '100%' }} className="mt20">
                    <Button type='primary' className="mlr10" onClick={this.back.bind(this)}>上一步</Button>
                    <Button type='primary' className="mlr10" onClick={this.Submit.bind(this)}>提交</Button>
                </div>
            </div>

        )
    }
}
const ServicesDateForm = Form.create()(ServicesDate);
export default ServicesDateForm;
