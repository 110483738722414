/**
 * @file 组件：编辑器
 * @author Sim <sim@snorl.ax>
 */
import React from 'react'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

/**
 * 组件：编辑器
 * @class
 * @extends React.Component
 */
export default class Editor extends React.Component {
    /**
     * 组件状态值存储区域
     * @type {Object}
     */
    state = {
        editorState: null
    }

    /**
     * 处理编辑器中值的变化
     * @type {Function}
     * @param {object} editorState 编辑器状态
     */
    handleChange = editorState => {
        this.setState({ editorState });
        this.triggerChange({ editorState });
    };

    /**
     * 编辑器值变化触发
     * @type {Function}
     * @param {HTMLElement} changedValue 变化的值
     */
    triggerChange = changedValue => {
        // Should provide an event to pass value to Form.
        const { onChange } = this.props;
        if (onChange && changedValue.editorState.toHTML() !== '<p></p>') {
            changedValue.editorState = changedValue.editorState.toHTML()
            onChange(changedValue.editorState);
        }
    };

    /**
     * 提交内容后的事件
     * @type {Function}
     * @async
     */
    submitContent = async () => {
        // 在编辑器获得焦点时按下ctrl+s会执行此方法
        // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
        const htmlContent = this.state.editorState.toHTML()
        // const result = await saveEditorContent(htmlContent)
        console.log(htmlContent, "submit")
    }

    /**
     * 渲染函数
     * @type {Function}
     * @param {Object} props 组件的属性
     * @return {HTMLElement}
     */
    renderThings = (props) => <BraftEditor
            value={props.value||this.state.editorState}
            className="border"
            onChange={this.handleChange}
            onSave={this.submitContent}
        />

    /**
     * 渲染最终值
     * @type {Function}
     * @return {HTMLElement}
     */
    render = () => this.renderThings(this.props)
}